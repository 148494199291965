'use strict';
angular.module('ChicoApp.CC-Dashboard-V3', [])
    .config(function($stateProvider) {

        $stateProvider.state('cc-dashboard-v3', {
            url: '/cc-dashboard-v3',
            controller: 'CC-DashboardV3Controller',
            templateUrl: 'templates/childcontrol/cc_dashboard_v3/templates/dashboard-v3.html'

        })
    });
