'use strict';
angular.module('ChicoApp.Shared').controller('SCG-Modal-Feedback-Controller', function(
    $scope,
    $modalInstance,
    $http,
    $window,
    $translate,
    AuthFactory,
    API) {

  $scope.cancel = function() {
    $modalInstance.dismiss('cancel');
  };

  $scope.data = {};
  $scope.data.feedbackText = '';
  $scope.data.feedbackEmail = '';
  $scope.data.feedbackLang = '';
  $scope.data.feedbackPrg = '';

  $window.ga('send', 'pageview', {page:'/modal-feedback'});

  $scope.ok = function(return_val) {
    $modalInstance.close(return_val);

    //Some consts
    $scope.data.feedbackLang = $translate.use();
    if (AuthFactory.activeContext == 'cb') {
      $scope.data.feedbackPrg = 'ChicoBrowser';
    }
    if (AuthFactory.activeContext == 'cc') {
      $scope.data.feedbackPrg = 'Child Control';
    }

    var req = {
      method: 'POST',
      url: API.url + 'service/feedback',
      data: $scope.data
    };

    return $http(req);
  };

});
