angular.module('ChicoApp').factory('parentsFactory', function(
    $http,
    API) {

    var factory = {}; //Dont forget to init, this is also final return value!

    factory.listAll = function(todayOnly) {

        var myurl = API.url + 'parents/devicelist';
        if (todayOnly===true) myurl = API.url + 'parents/devicelisttoday';

        var req = {
            method: 'GET',
            url: myurl
        };
        return $http(req);
    };


    factory.listToday = function() {
        var req = {
            method: 'GET',
            url: API.url + 'parents/devicelisttoday'
        };
        return $http(req);
    };

    return factory;

});
