'use strict';
angular.module('ChicoApp.CC-Internet')

.controller('CC-InternetControllerBonus', function(
  $scope,
  $state,
  $translate,
  ccListsFactory,
  DTOptionsBuilder,
  DTColumnDefBuilder,
  scgDataTableFactory) {

  var modalStartKind = ccListsFactory.listKinds.list_kind_bonus_internet;

  $scope.tableHeaders = [$translate.instant('HEADER.DOMAINURLKEYWORD')];

  $scope.data = {};
  $scope.data.table_api_urls = [];
  $scope.data.table_api_keywords = [];

  scgDataTableFactory.prepareScopeForTable($scope, modalStartKind);
  $scope.dtOptions = scgDataTableFactory.getDefaultDtOptions($scope);

  $scope.dtColumnDefs = scgDataTableFactory.getDefaultDtColumnDefs3Cols();

  $scope.reload = function() {
    $state.go($state.current, {}, {reload: true});
  };

  var loadList = function() {
    ccListsFactory.getByListFilter(modalStartKind)
            .then(function success(response) {
              $scope.fillInnerListFromApiList(response.data);
              $scope.data.table_api_urls = response.data;
            });
  };

  loadList();

});
