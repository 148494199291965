'use strict';
angular.module('ChicoApp.CB-Settings')

    .controller('CB-TeststuffController', function(
      $rootScope,
      $scope,
      $translate,
      $state,
      $http,
      $timeout,
      $log,
      DeviceFactory,
      AuthFactory,
      cbListsFactory,
      DTOptionsBuilder,
      DTColumnDefBuilder,
      scgDataTableFactory,
      modalFactory,
      Upload,
      API,
      moment,
      apiFunctions) {

      $scope.mymodalstring = '';
      $scope.mymodalbool = false;

      var modalStartKind = 99; //teststuff

      $scope.tableHeaders = [$translate.instant('HEADER.DOMAIN')];
      $scope.myvariable = 'blubb';

      $scope.data = {};
      $scope.data.table_api_urls = [];
      $scope.data.table_api_keywords = [];

      $scope.backgroundRadio = 1
      $scope.backgroundColor = "#33B5E5";

      $scope.backgroundCounter = moment().format('X');


      // BACKGROUND PICTURE STUFF ------------------------------------------------------------------

        $scope.getBackgroundUrl = function() {
            var sessionPath = AuthFactory.sessionId + '/' + AuthFactory.activeDevice.deviceId;
            return 'https://portal.salfeld.net/files/background/' + sessionPath+'?nocache='+$scope.backgroundCounter;
        }


        $scope.deleteBackground = function() {
            var sessionPath = AuthFactory.sessionId + '/' + AuthFactory.activeDevice.deviceId;
            $log.info('DELETE BACKGROUND sess=' + sessionPath);

            $http({
                method: 'DELETE',
                url: API.rooturl + 'files/background/' + sessionPath
            }).then(function(result){
                $state.reload();
            });


        }


        $scope.uploadBackground = function(file, errFiles) {

            $scope.f = file;
            $log.info('Upload entry');
            $scope.errFile = errFiles && errFiles[0];

            if (file) {
                Metronic.blockUI({animate: true});
                var sessionPath = AuthFactory.sessionId + '/' + AuthFactory.activeDevice.deviceId;

                file.upload = Upload.upload({
                    url: API.rooturl + 'files/background/' + sessionPath,
                    method: 'POST',
                    headers: {
                        'Content-Type': 'multipart/form-data;'
                    },
                    data: {file:file}
                });

                file.upload.then(function(response) {
                    $timeout(function() {
                        file.result = response.data;
                        $log.info('Upload status A' + response.data);
                        Metronic.unblockUI();
                        $scope.backgroundCounter = moment().format('X');
                        modalFactory.okModal(1, $translate.instant('MSG.IMPORTSUCCESS'));
                    });
                }, function(response) {
                    if (response.status > 0) {
                        $scope.errorMsg = response.status + ': ' + response.data;
                    }
                    Metronic.unblockUI();
                    $log.info('Upload status B');
                    modalFactory.okModal(3, $translate.instant('MSG.IMPORTFAILED'));
                }, function(evt) {
                    file.progress = Math.min(100, parseInt(100.0 *
                        evt.loaded / evt.total));
                });
            }
        };
        // EOF BACKGROUND PICTURE STUFF ------------------------------------------------------------------










      scgDataTableFactory.prepareScopeForTable($scope, modalStartKind);
      $scope.dtOptions = scgDataTableFactory.getDefaultDtOptions($scope);

      $scope.dtColumnDefs = scgDataTableFactory.getDefaultDtColumnDefs3Cols();

      $scope.reload = function() {
        $state.go($state.current, {}, {reload: true});
      };

      var loadList = function() {
        cbListsFactory.getByListFilter(modalStartKind)
                .then(function success(response) {
                  $scope.fillInnerListFromApiList(response.data);
                  $scope.data.table_api_urls = response.data;
                });
      };

      loadList();
















       /*
       DeviceFactory.getDeviceById(AuthFactory.activeDevice.Id, function(device)
       {
       //on return with device
       $log.info('YEAH we got device:' , device);
       }
       */

      $scope.myReload = function() {
        $state.go($state.current, {}, {reload: true});
        $log.info('reloaded!');
      };

      $scope.showModal = function() {
        $log.info('showModal fired');
        angular.element('#myModal3').modal();
      };

      $scope.machDE = function() {
        $translate.use('de_DE');
      };

      $scope.machEN = function() {
        $log.info('machEN fired!');
        $translate.use('en_US');
      };

      $scope.myAction = function(/*mystring*/) {
        var postData = []; //array for the new records we will later post
        var record = {};

        record.kind = 99;
        record.entry = $scope.mymodalstring;

        postData.push(record);
        $log.info('postData = ', postData);

        cbListsFactory.postRecords(postData)
                .then(function success(/*response*/) {
                  //if(cb){cb();}
                  $log.info('Done with postData ', postData);
                  $state.go($state.current, {}, {reload: true});
                  //
                });
      };

      $scope.openErrorModal = function() {
        $log.info('Open Error Modal dummy');

        var req = {
          method: 'GET',
          url: apiFunctions.get_cc_base_url() + 'ccsettingsfail'
        };
        $http(req);

        setTimeout(function() {

          var req = {
            method: 'GET',
            url: apiFunctions.get_cc_base_url() + 'ccsettingsfail'
          };
          $http(req);

        }, 5000);

        //refresh local scope array on change of global error array
        //  $rootScope.$on('errors:changed', function(event,data) {
        //      $log.info("errors changed");
        //      $scope.errors = errorDisplayFactory.getErrorArray();
        //      if($scope.errors.length > 0){
        //          //display modal
        //      }
        // });

        // modalFactory.errorModal.open({
        //     modal_settings:{
        //         refreshStateAfterClose: true
        //     },
        //     callbacks:{
        //         ok: function(){},
        //         cancel: function(){}
        //     }
        // });

      };

      $scope.okModal = function(mymessage) {
        modalFactory.okModal(1, mymessage);
      };

      $scope.openInfoModal = function() {
        $log.info('opening info modal');

        modalFactory.infoModal.open({
          modal_settings: {
            title: 'Title for the modal',
            message: 'Message for the modal',
            butOkLabel: 'OK',
            butCanelLabel: 'Cancel',
            refreshStateAfterClose: true
          },
          callbacks: {
            ok: function() {
              $log.info('modal closed with ok -> callback');
            },
            cancel: function() {
              $log.info('modal closed with cancel -> callback');
            }
          }
        });

      };

      (function(window, angular) {'use strict';
        var agl = angular || {};
        var ua  = navigator.userAgent;

        agl.ISFF     = ua.indexOf('Firefox') != -1;
        agl.ISOPERA  = ua.indexOf('Opera') != -1;
        agl.ISCHROME = ua.indexOf('Chrome') != -1;
        agl.ISSAFARI = ua.indexOf('Safari') != -1 && !agl.ISCHROME;
        agl.ISWEBKIT = ua.indexOf('WebKit') != -1;

        agl.ISIE   = ua.indexOf('Trident') > 0 || navigator.userAgent.indexOf('MSIE') > 0;
        agl.ISIE6  = ua.indexOf('MSIE 6') > 0;
        agl.ISIE7  = ua.indexOf('MSIE 7') > 0;
        agl.ISIE8  = ua.indexOf('MSIE 8') > 0;
        agl.ISIE9  = ua.indexOf('MSIE 9') > 0;
        agl.ISIE10 = ua.indexOf('MSIE 10') > 0;
        agl.ISOLD  = agl.ISIE6 || agl.ISIE7 || agl.ISIE8; // MUST be here

        agl.ISIE11UP = ua.indexOf('MSIE') == -1 && ua.indexOf('Trident') > 0;
        agl.ISIE10UP = agl.ISIE10 || agl.ISIE11UP;
        agl.ISIE9UP  = agl.ISIE9 || agl.ISIE10UP;

      })(window, window.angular);

      $scope.sayHello = function() {
        if (angular.ISCHROME) {
          alert('is chrome');
        }
        if (angular.ISFF) {
          alert('is Firefox');
        }
      };

    });

