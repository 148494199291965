'use strict';
angular.module('ChicoApp.Shared').directive('scgLoginlockedModal', function() {
    return {
        restrict: 'E',
        templateUrl: 'templates/shared/globalModals/globalLoginLockedModal/templates/modal-loginlocked-content.html',
        controller: function() {

        }
    };
});
