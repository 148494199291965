angular.module('ChicoApp').factory('ccWizardFactory', function(
    $http,
    API,
    apiFunctions,
    $log) {

    var factory = {};

    var apifields_timelimits = [
        'isTimeLimitActive',
        'timeLimitDay',
        'timeLimitWeek',
        'timeLimitMonth',
        'isWeekdayLimitActive',
        'timeLimitMonday',
        'timeLimitTuesday',
        'timeLimitWednesday',
        'timeLimitThursday',
        'timeLimitFriday',
        'timeLimitSaturday',
        'timeLimitSunday',
        'isForcedBreak',
        'forceBreakAfter',
        'forceBreakDuration'
    ];

    var apifields_blocktime = [
        'blockTimesPC',
        'noExtensionInBlockTime'
    ];

    var apifields_user = [
        'blockTimesPC',
        'extension',
        'noExtensionInBlockTime',
        'isInternetTimeLimitActive',
        'internetTimeLimitDay',
        'internetTimeLimitWeek',
        'internetTimeLimitMonth',
        'isInternetWeekdaysLimitActive',
        'internetTimeLimitMonday',
        'internetTimeLimitTuesday',
        'internetTimeLimitWednesday',
        'internetTimeLimitThursday',
        'internetTimeLimitFriday',
        'internetTimeLimitSaturday',
        'internetTimeLimitSunday'

    ];

    var apifields_allusers = [
        'generalPCLimitLevel',
        'generalForcedBreaksLevel'
    ];

    var apifields_general = [
        'generalPCLimitLevel'
    ];

    var apifields_user_param  = apiFunctions.obj_to_url_params(apifields_user);
    var apifields_timelimits_param  = apiFunctions.obj_to_url_params(apifields_timelimits);
    var apifields_allusers_param = apiFunctions.obj_to_url_params(apifields_allusers);

        factory.getUserSettings = function() {
        return factory.getSettingsByParams(apifields_user_param);
    };


    factory.getTimelimits = function() {
        return factory.getSettingsByParams(apifields_timelimits_param);
    };

    factory.getGeneralSettings = function() {
        return factory.getAllUsersSettingsByParams(apifields_allusers_param);
    };

    factory.getSettingsByParams = function(params) {
        var req = {
            method: 'GET',
            url: apiFunctions.get_cc_base_url() + 'ccsettings' + params
        };
        return $http(req);
    };

    factory.getAllUsersSettingsByParams = function(params) {
        $log.info('Allusers param' , params);
        var req = {
            method: 'GET',
            url: API.url + 'allusers/ccsettings' + params
        };
        return $http(req);
    };

    factory.saveSettings = function(settings_scope) {
        factory.setSettings(settings_scope);
    };



    factory.setSetting = function(obj) {
        var req = {
            method: 'POST',
            url: apiFunctions.get_cc_base_url() + 'ccsettings',
            data: obj
        };
        return $http(req);
    };


    factory.setSettings = function(keyvalue_arr) {
        var req = {
            method: 'POST',
            url: apiFunctions.get_cc_base_url() + 'ccsettings',
            data: keyvalue_arr
        };
        return $http(req);
    };


    factory.setTimeLimits = function(obj) {
        $log.info('setTimeLimits obj=', obj);
        //CAUTION: We cannot simply post the new object, because it contains calc values (e.g. pcWD, pcWE etc.)
        if (obj.pcWD) {
            factory.setSetting({'isTimeLimitActive' : false});
            factory.setSetting({'isWeekdayLimitActive' : true});
            factory.setSetting({'timeLimitSunday': obj.pcWD});
            factory.setSetting({'timeLimitSunday': obj.pcWD});
            factory.setSetting({'timeLimitMonday': obj.pcWD});
            factory.setSetting({'timeLimitTuesday': obj.pcWD});
            factory.setSetting({'timeLimitWednesday': obj.pcWD});
            factory.setSetting({'timeLimitThursday': obj.pcWD});
        }
        if (obj.pcWE) {
            factory.setSetting({'isTimeLimitActive' : false});
            factory.setSetting({'isWeekdayLimitActive' : true});
            factory.setSetting({'timeLimitFriday': obj.pcWE});
            factory.setSetting({'timeLimitSaturday': obj.pcWE});
        }
        if (obj.forceBreakAfter) {
            factory.setSetting(obj);
        }


            //EXAMPLE TO POST AS ARRAY -> DOES NOT WORK FOR BACKEND (ERROR 500!?)
            /*var newobj =[{
                'timeLimitMonday': obj.pcWD
            }, {
                'timeLimitTuesday':obj.pcWD
            }, {
                'timeLimitWednesday':obj.pcWD
            }, {
                'timeLimitThursday':obj.pcWD
            }, {
                'timeLimitFriday':obj.pcWD
            }];
                factory.setSettings(newobj);
*/


    }
    //==============================================



    factory.setAllUsersSetting = function(obj) {
        $log.info('setAllUsersSetting DATA', obj);
        var req = {
            method: 'POST',
            url: API.url + 'allusers/ccsettings',
            data: obj
        };
        return $http(req);
    };



    factory.setLists = function(kind,entries) {
        var postData =[];

        entries.forEach(function(entry){
           postData.push({kind:kind,entry:entry});
        });

        var req = {
            method: 'POST',
            url: apiFunctions.get_cc_base_url() + 'cclists',
            data: postData
        };
        return $http(req);
    };


    return factory;
});
