'use strict';
angular.module('ChicoApp.Info')

    .controller('AppInfoController', function(
        $rootScope,
        $scope,
        $translate,
        $state,
        $http,
        $window,
        $cookies,
        $timeout,
        DeviceFactory,
        AuthFactory,
        modalFactory,
        $log,
        API,
        apiFunctions,
        infoFactory) {


        $scope.logEntries = {};
        $scope.noLogbook = true;
        $scope.currentContext = AuthFactory.activeContext; //will be cc or cb as String

        $scope.langId = 'de'; //default
        if ($translate.use() == 'en_US') $scope.langId='en';
        $log.info('langID = ', $scope.langId);


        $scope.doStuff = function(entry) {
            return "Something done with " + entry;
        }


    });
