'use strict';
angular.module('ChicoApp.CC-Wizard')

    .controller('CC-Wizard-2-Controller', function(
        $rootScope,
        $scope,
        $state,
        $http,
        $timeout,
        $translate,
        $log,
        ccWizardFactory
        ) {


        $scope.data = {};

        $scope.active = true;

        $scope.pcActive = true;
        $scope.pcWD = "01:30";
        $scope.pcWE = "02:30";
        $scope.inetWD = "01:00";
        $scope.inetWE = "02:00";





        $scope.isWizDebug = true;       //TODO: remove later

        // setup watcher for data object
        $scope.$watch(function(){
            return $scope.data;
        },function(newData){
            ccWizardFactory.setTimeLimits(newData);
        },true);



         ccWizardFactory.getTimelimits()
         .then(function success(response) {
         $log.info('RESPONSE getTimel-imits ARRIVED' , response);
         //mappings pc timelimit
         $scope.data = response.data;
         $scope.data.pcWD = response.data.timeLimitMonday;
         $scope.data.pcWE = response.data.timeLimitSaturday;
         if (response.data.isTimeLimitActive==true || response.data.isWeekdayLimitActive==true) {
             $scope.data.pcActive=true;
         } else {
             $scope.data.pcActive=false;
         }


         });




        $scope.goNext = function() {
            $log.info('goNext triggered');
                $state.go('cc-wizard.3');
            }

        $scope.goBack = function() {
            $log.info('goBack triggered');
            $state.go('cc-wizard.1');
            }

    });
