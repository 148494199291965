'use strict';
angular.module('ChicoApp.CC-TimeLimits')

.controller('CC-TimeLimitsControllerInternet', function(
  $rootScope,
  $scope,
  $http,
  $timeout,
  ccListsFactory,
  modalFactory,
  $translate,
  scgSharedFactory,
  ccTimeLimitsFactory,
  $log,
  _
  ) {
  $scope.$on('$viewContentLoaded', function() {

    $scope.data = {};
    $scope.dontShowInetNotice = true;

    $scope.uiflags = {};
    $scope.uiflags.loadingModalData = false;

    ccTimeLimitsFactory.getInternetSettings()
            .then(function success(response) {

              $scope.data = response.data;

            });

    $scope.openEditModalClick = function() {

      $scope.uiflags.loadingModalData = true;

      //get records for group internet
      ccListsFactory.getByListFilter(ccListsFactory.listKinds.list_kind_group_internet)
          .then(function success(response) {

              $scope.uiflags.loadingModalData = false;

              $log.info('res', response.data);
              var editRecords = [];
              _.each(response.data, function(v) {
                  editRecords.push({data: {entry: v}});
                });

              $log.info('edit rec', editRecords);

              if (editRecords && editRecords.length < 1) {
                modalFactory.infoModal.open({
                    modal_settings: {
                        title: $translate.instant('HEADER.INFO'),
                        message: $translate.instant('MSG.NO_INETPROGRAMS'),
                        butOkLabel: $translate.instant('BUTTON.OK'),
                        butCancelLabel: null,
                        iconNo: 3,
                        refreshStateAfterClose: true
                      },
                    callbacks: {
                        ok: function() {
                            $log.info('modal closed with ok -> callback');
                          }
                      }
                  });
              }else {
                modalFactory.editModal.open({
                    modal_settings: {
                        mode: 'edit',
                        context: 'prg',
                        title: $translate.instant('MODAL_EDIT_RECORD.TITLE_EDIT'),
                        newRecord: null,
                        editRecords: editRecords,
                        refreshStateAfterClose: true
                      },
                    callbacks: {
                        ok: function() {},
                        cancel: function() {}
                      }
                  });
              }

            });

    };

  });

});
