'use strict';
angular.module('ChicoApp.CC-Wizard', [])
    .config(function($stateProvider) {
        $stateProvider.state('cc-wizard', {
            redirectTo: 'cc-wizard.1',
            url: '/cc-wizard',
            template: '<ui-view/>'
        })
            .state('cc-wizard.1', {
                url: '/1',
                templateUrl: 'templates/childcontrol/cc_wizard/templates/wizard-1.html',
                controller: 'CC-Wizard-1-Controller',
            })
            .state('cc-wizard.2', {
                url: '/2',
                templateUrl: 'templates/childcontrol/cc_wizard/templates/wizard-2.html',
                controller: 'CC-Wizard-2-Controller',
            })
            .state('cc-wizard.3', {
                url: '/3',
                templateUrl: 'templates/childcontrol/cc_wizard/templates/wizard-3.html',
                controller: 'CC-Wizard-3-Controller',
            })
            .state('cc-wizard.4', {
                url: '/4',
                templateUrl: 'templates/childcontrol/cc_wizard/templates/wizard-4.html',
                controller: 'CC-Wizard-4-Controller',
            })
            .state('cc-wizard.5', {
                url: '/5',
                templateUrl: 'templates/childcontrol/cc_wizard/templates/wizard-5.html',
                controller: 'CC-Wizard-5-Controller',
            })
            .state('cc-wizard.6', {
                url: '/6',
                templateUrl: 'templates/childcontrol/cc_wizard/templates/wizard-6.html',
                controller: 'CC-Wizard-6-Controller',
            });


    })
    .run(function() {});
