angular.module('ChicoApp.Shared').factory('timetableFactory', function(
  $rootScope,
  $filter,
  _
) {

  var timetableFactory = {};
  var dayStartHour = 0;
  var dayEndHour = 23;
  var browserLimitMap = {
    0: [0,0,0,0],
    2: [0,0,0,1],
    3: [0,0,1,0],
    4: [0,0,1,1],
    5: [0,1,0,0],
    6: [0,1,0,1],
    7: [0,1,1,0],
    8: [0,1,1,1],
    9: [1,0,0,0],
    A: [1,0,0,1],
    B: [1,0,1,0],
    C: [1,0,1,1],
    D: [1,1,0,0],
    E: [1,1,0,1],
    F: [1,1,1,0],
    1: [1,1,1,1]
  };

  //hours per day
  //hours are calculated during init with dayStartHour and dayEndHour
  timetableFactory.hoursPerDay = [];

  //days for timetable
  timetableFactory.days = [{
    displayName: 'TIMES.SHORT_MON',
    name: 'monday',
    position: 1,
    positionInRaw: 2,
    hours: []
  }, {
    displayName: 'TIMES.SHORT_TUE',
    name: 'tuesday',
    position: 2,
    positionInRaw: 3,
    hours: []
  }, {
    displayName: 'TIMES.SHORT_WED',
    name: 'wednesday',
    position: 3,
    positionInRaw: 4,
    hours: []
  }, {
    displayName: 'TIMES.SHORT_THU',
    name: 'thursday',
    position: 4,
    positionInRaw: 5,
    hours: []
  }, {
    displayName: 'TIMES.SHORT_FRI',
    name: 'friday',
    position: 5,
    positionInRaw: 6,
    hours: []
  }, {
    displayName: 'TIMES.SHORT_SAT',
    name: 'saturday',
    position: 6,
    positionInRaw: 7,
    hours: []
  }, {
    displayName: 'TIMES.SHORT_SUN',
    name: 'sunday',
    position: 7,
    positionInRaw: 1,
    hours: []
  }];

  //blocked times
  timetableFactory.blockedtimes = '';

  //functions

  timetableFactory.setBlockedtimes = function(raw) {
    //set blockedtimes to raw
    timetableFactory.blockedtimes = raw;
    //convert blockedtimes to datamodel
    var rawArray = raw.split('');

    var sortedDays = _.sortBy(timetableFactory.days, 'positionInRaw');

    var char_position = 0;
    _.each(sortedDays, function(day) {
      //per day
      _.each(day.hours, function(hour) {
        //per hour
        var quarterArray = browserLimitMap[rawArray[char_position]];

        for (var i = 0; i < quarterArray.length; i++) {

          if (quarterArray[i] == 1) {
            hour.quarters[i].blocked = true;
          }else {
            hour.quarters[i].blocked = false;
          }

        }

        char_position++;
      });
    });

  };

  timetableFactory.convertDaysToRaw = function() {
    //convert timetableFactory.days to raw
    var converted = [];
    var sortedDays = _.sortBy(timetableFactory.days, 'positionInRaw');
    sortedDays.forEach(function(day) {
      day.hours.forEach(function(hour) {
        var quarters = [];
        hour.quarters.forEach(function(quarter) {
          if (quarter.blocked) {
            quarters.push(1);
          }else {
            quarters.push(0);
          }
        });
        converted.push(_.findKey(browserLimitMap,_.findWhere(browserLimitMap,quarters)));
      });
    });

    converted = converted.join('');

    timetableFactory.blockedtimes = converted;

  };

  //watches
  $rootScope.$watch(function() {
    return timetableFactory.days;
  },function watchCallback(newValue) {
    timetableFactory.convertDaysToRaw(newValue);
  },true);

  //init
  function initTimetableFactory() {
    timetableFactory.days.forEach(function(day) {
      for (var i = dayStartHour; i <= dayEndHour; i++) {
        day.hours.push({
          hour: i,
          quarters: [
                        {label: '15',blocked: false,position: 2},
          {label: '30',blocked: false,position: 4},
          {label: '45',blocked: false,position: 3},
          {label: '60',blocked: false,position: 1}
          ]
        });
      }
    });

    for (var i = dayStartHour; i <= dayEndHour; i++) {
      timetableFactory.hoursPerDay.push(i);
    }

  }

  initTimetableFactory();

  return timetableFactory;
});
