/*thisisaplaceholderforAPI-Factories*/
angular.module('ChicoApp').factory('scgValidationFactory', function(
    $translate,
    moment
    ) {
  var f = {};

  /*
  * Validate Time Inputs aginst each other
  */
  f.validateTimelimits = function(data, addErrCallback, removeErrCallback) {

    //get the int vars for calculations
    var limitDayInt = moment.duration(data.timeLimitDay, 'minutes');
    var timeLimitWeek = moment.duration(data.timeLimitWeek, 'minutes');
    var timeLimitMonth = moment.duration(data.timeLimitMonth, 'minutes');

    if (limitDayInt * 7 > timeLimitWeek) {
      addErrCallback({
        message: $translate.instant('ERROR.DAY_LIMIT_SUM_BIGGER_THAN_WEEK_LIMIT'),
        id: 'WeekLimitHigherThanDayLimits'
      });
    } else {
      removeErrCallback('WeekLimitHigherThanDayLimits');
    }

    if (timeLimitWeek * 4 > timeLimitMonth) {
      addErrCallback({
        message: $translate.instant('ERROR.WEEK_LIMIT_SUM_BIGGER_THAN_MONTH_LIMIT'),
        id: 'MonthLimitHigherThanWeekLimits'
      });
    } else {
      removeErrCallback('MonthLimitHigherThanWeekLimits');
    }

  };

  f.validateTag = function(text) {
    // Dierk too stupid for RegEx :-)
    if (text.indexOf(' ')!=-1) return false;
    if (text.indexOf('!')!=-1) return false;
    if (text.indexOf(',')!=-1) return false;
    if (text.indexOf('/')!=-1) return false;
    if (text.indexOf('\\')!=-1) return false;
    if (text.indexOf('?')!=-1) return false;
    if (text.indexOf('#')!=-1) return false;
    if (text.indexOf('\'')!=-1) return false;
    if (text.indexOf('"')!=-1) return false;
    if (text.indexOf('$')!=-1) return false;
    if (text.indexOf('*')!=-1) return false;
    if (text.indexOf(':')!=-1) return false;
    return true;
  }

  f.validateExe = function(text) {
    text = text.toLowerCase();
    if (f.validateTag(text)===false) return false;
    if (text.indexOf('.exe')!=-1) return true;
    if (text.indexOf('.bin')!=-1) return true;
    return false;
  }

  f.validateDomain = function(text) {
    text = text.toLowerCase();
    if (f.validateTag(text)===false) return false;
    if (text.indexOf('.exe')!=-1) return false;   //no *.exe as domain
    if (text.indexOf('.')!=-1) return true;
    return false;
  }


  return f;
});
