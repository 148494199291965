(function() {
  'use strict';

  angular
      .module('ChicoApp.Childcontrol', [
        'ChicoApp.CC-TimeLimits',
        'ChicoApp.CC-Internet',
        'ChicoApp.CC-Dashboard',
        'ChicoApp.CC-Dashboard-V3',
        'ChicoApp.CC-Settings',
        'ChicoApp.CC-Security',
        'ChicoApp.CC-Programs',
        'ChicoApp.CC-Programs-V2',
        'ChicoApp.CC-Programs-V3',
        'ChicoApp.CC-Users',
        'ChicoApp.CC-Emailreport',
        'ChicoApp.CC-Usage',
        'ChicoApp.CC-SYNC',
        'ChicoApp.CC-Screenshots',
        'ChicoApp.CC-Wizard',
        'ChicoApp.Login',
        'ChicoApp.Internal',
        'ChicoApp.Licence',
        'ChicoApp.Parents'
      ]);
})();
