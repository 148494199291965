'use strict';
angular.module('ChicoApp.Shared').controller('SCG-Modal-Edit-Group-Controller', function (
    $scope,
    $log,
    $modalInstance,
    $translate,
    modal_settings,
    scgGlobalSpinnerFactory,
    AuthFactory,
    scgSettingsFactory,
    _) {

    $scope.data = {};
    $scope.data.groupName = modal_settings.groupItem.name;
    $scope.data.groups = modal_settings.groups;

    $scope.save = function () {

        //get index of edited item to later insert the new one exactly there
        var origIndex = _.findIndex($scope.data.groups, function (group) {
            return group.kind == modal_settings.groupItem.kind;
        });

        //create an array with all the groups that have not been edited
        var notChangedGoups = _.filter($scope.data.groups, function (group) {
            return group.kind != modal_settings.groupItem.kind;
        });

        var changedGoup = _.find($scope.data.groups, function (group) {
            return group.kind == modal_settings.groupItem.kind;
        });

        //Dierk TODO: make save group name work with a single group name

        //change name of the group we edited
        changedGoup.name = $scope.trimGroupname($scope.data.groupName);
        $log.info('changedGroup:', changedGoup);

        //add the edited group to the array
        notChangedGoups.splice(origIndex, 0, changedGoup);

        scgSettingsFactory.setGroups(notChangedGoups)
            .then(function success( /*response*/ ) {
                $modalInstance.close();
            });

    }


    $scope.replaceAll =function(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
    }


    //these strings are internal, we have to remove before posting to group entries
    $scope.trimGroupname = function(s)
    {
        var ret = s;
        ret = $scope.replaceAll(ret,'#1','');
        ret = $scope.replaceAll(ret,'#2','');
        ret = $scope.replaceAll(ret,'#3','');
        ret = $scope.replaceAll(ret,'#4','');
        ret = $scope.replaceAll(ret,'#5','');
        ret = $scope.replaceAll(ret,',','');
        return ret;
    }


    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };

    $scope.ok = function (return_val) {
        $modalInstance.close(return_val);
    };

});