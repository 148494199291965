'use strict';
angular.module('ChicoApp.Internal')

    .controller('LizenzenController', function(
        $rootScope,
        $scope,
        $translate,
        $state,
        $http,
        $window,
        $timeout,
        scgSharedFactory,
        licenceFactory,
        scgLicenceFactory,
        $log) {



        $log.info('INTERNAL - Lizenzen Controller');
        scgSharedFactory.needsSync = false;


         //eof controller functions
    });
