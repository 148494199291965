'use strict';
angular.module('ChicoApp.Login')
    .controller('DevLoginController', function(
        $scope,
        $state,
        $cookies,
        AuthFactory,
        $log,
        $translate,
        $stateParams) {

      $log.info('DevLoginController running');

      $scope.login = function(username, password) {

        AuthFactory.login(username, password)
                .then(function success(successResponse) {
                  $log.info('autologin success resp:', successResponse);
                }, function error(errorResponse) {
                  $log.info('autologin error resp:', errorResponse);
                });

      };

      if ($stateParams.username && $stateParams.password) {
        $log.info('Autologin with username:', $stateParams.username);
        $log.info('Autologin with password:', $stateParams.password);
        $scope.login($stateParams.username, $stateParams.password);
      }

    });
