'use strict';
angular.module('ChicoApp.CC-Security')

    .controller('CC-Security-Windows', function(
        $scope,
        ccSecurityFactory,
        $log,
        _,
        scgGlobalSpinnerFactory,
        scgSharedFactory) {

      $log.info('CC-Security-Windows');

      $scope.data = {};
      $scope.data_arr = {};
      $scope.hintHide = true;

      scgGlobalSpinnerFactory.blockUI();

      $scope.myConvert = function(idx) {
          if ($scope.data == undefined || $scope.data.DrvLock == undefined) {
            return false; } else {

            return $scope.data.DrvLock[idx] == '0' ? false : true;
          }
        };

      ccSecurityFactory.getSecuritySettings()
            .then(function success(response) {

              $log.info('CCSecurity returned SUCCESS');
              $scope.data = response.data;

              //$scope.data.DrvLock = '00000000001111111111111111'; //Dierk: Unbedfingt wieder raus - Baustelle
              $log.info('DrvLock is:' + $scope.data.DrvLock);
              if ($scope.data.DrvLock.length != 26) {
                $scope.data.DrvLock = '00000000000000000000000000';
                $log.info('DrvLock has been corrected!');
              } //must have exactly 26 digits

              if ($scope.data != undefined || $scope.data.DrvLock != undefined) {
                $scope.DrvLock_arr = $scope.data.DrvLock.split('');
              }

              $scope.$watch('DrvLock_arr', function(new_val, old_val) {
                if (new_val != old_val && old_val != undefined) {
                  $scope.data.DrvLock = $scope.DrvLock_arr.join('');
                }
              }, true);

              //add watchers
              //Dierk: 2-way databinding l�uft hier, funktion ist in der factory!
              _.each($scope.data, function(v, k) {
                $scope.$watch('data.' + k, function(new_val, old_val) {
                  if (new_val != old_val) {
                    var changed_object = {};
                    changed_object[k] = new_val;
                    if (k == 'isDisableTimeDate') {
                      $scope.data.needRestart = true;
                    }
                    $log.info('security changed settings!',k);
                    scgSharedFactory.needsSync = true;
                    ccSecurityFactory.setSetting(changed_object);
                  }
                }, true);
              });

              scgGlobalSpinnerFactory.unblockUI();
            });

      ccSecurityFactory.getAllUsersSecuritySettings()
            .then(function success(response) {

              $log.info('getall returned:' + response);
              $scope.data_all = response.data;

              //add watchers for allUsers
              _.each($scope.data_all, function(v, k) {
                $scope.$watch('data_all.' + k, function(new_val, old_val) {
                  if (new_val != old_val) {
                    var changed_object = {};
                    changed_object[k] = new_val;
                    $log.info('security AllUsers changed settings!');
                    ccSecurityFactory.setAllUsersSetting(changed_object);
                  }
                }, true);
              });
            });

      //Dierk Stuss Test, raus damit
      /*$scope.foo = 3;
      //$log.info('now the stuss');
      //var y = ccSecurityFactory.getStuss();
      //$scope.foo = ccSecurityFactory.getStuss();
      $log.info('stuss is:' + $scope.foo);*/

      $scope.$on('$viewContentLoaded', function() {

      }); //eof scope.on

      //Hint stuff, Add $scope.hintHide at top of page
      //--------------------------------------------------------------------------------
      scgSharedFactory.getHint(50).                                //adjust hintID here
            then(function success(response) {
              if (response.data.hint50) {                         //adjust hintID here
                $scope.hintHide = response.data.hint50;}        //adjust hintID here
              else {
                //not assgined, set to false!
                $scope.hintHide = false;}
            }
        );
      $scope.hintDone = function() {
        $scope.hintHide = true;
        scgSharedFactory.setHint(50).then(function success(/*response*/) {
          $log.info('HINT DONE SUCCESS');
        }
      );};
      //--------------------------------------------------------------------------------

    }); //eof controller
