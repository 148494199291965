'use strict';
angular.module('ChicoApp.CC-Usage')

    .controller('CC-Usage-Unblock-V2', function(
        $scope,
        $cookies,
        $state,
        $window,
        $translate,
        $log,
        _,
        moment,
        sseFactory,
        ccUsageFactory,
        scgSharedFactory,
        modalFactory,
        scgGlobalSpinnerFactory) {

        $scope.data = {};
        $scope.blockedData = {};
        $scope.showBlocked = false;
        $scope.contentLoading = false;
        $scope.currentUser = '';


        $scope.reload = function() {
            scgGlobalSpinnerFactory.unblockUI;
            $state.go($state.current, {}, {reload: true});
        };


        $scope.loadEntries = function() {
            $scope.contentLoading = true;
            $log.info('load entries');
            $scope.currentUser = $cookies.get('user');
            ccUsageFactory.getUnlockReqs()
                .then(function success(response) {
                    $scope.data = response.data;
                    $scope.hasNoData = false;
                    $scope.data = response.data;
                    $scope.contentLoading = false;
                });
        };


        $scope.unblockItem = function(username, entry) {
            $log.info('UNBLOCKV2 -  for username=' + username + '|entry=' + entry) ;
            scgGlobalSpinnerFactory.blockUI;
            scgSharedFactory.needsSync = true;
            ccUsageFactory.doUnlock(username,entry)
                .then(function success() {
                    $log.info('UNBLOCKV2 - doUnlock DONE');


                    sseFactory.isOnline()
                        .then(function success() {
                            $log.info('UNBLOCKV2 - YES WE ARE ALSO ONLINE');
                            sseFactory.sync()
                                .then(function success() {
                                    $log.info('UNBLOCKV2 - YES DO SYNC ALSO SUCCEEDED');
                                });
                        });


                    $scope.data = _.filter($scope.data, function(row) {
                        return row.entry !== entry;
                    });
                    //Send immediate sync request to device
                    $scope.reload();
                });

        };

        $scope.deleteItem = function(entry) {
            $log.info('deleteItem for entry=' + entry);
            scgGlobalSpinnerFactory.blockUI;
            ccUsageFactory.deleteUnlockReq(entry)
                .then(function success() {
                    $log.info('deleteItem DONE');
                    $scope.data = _.filter($scope.data, function(row) {
                        return row.entry !== entry;
                    });
                    $scope.reload();
                });
        };


        $scope.showBlockedItems = function() {
            //$log.info("showBlockedItems, current user is:" + $cookies.user);
            $scope.showBlocked = true;
            scgGlobalSpinnerFactory.blockUI();

            //last three days
            $scope.startDate = moment().add(-5, 'd').format('YYYY-MM-DD');
            $scope.stopDate = moment().format('YYYY-MM-DD');
            $log.info('STARTDATE:' + $scope.startDate + '|STOPDATE:' + $scope.stopDate);
            $scope.howMany = 50;

            ccUsageFactory.getUsageByDates($scope.startDate,$scope.stopDate,$scope.howMany)
                .then(function success(response) {
                    $scope.blockedData = response.data;
                    $log.info('USAGE ARRIVED', response.data);
                    scgGlobalSpinnerFactory.unblockUI();
                });

        };


        $scope.loadEntries();


    });
