'use strict';
angular.module('ChicoApp.CC-Programs-V3', [])
    .config(function($stateProvider) {
        $stateProvider.state('cc-programs-v3', {
            url: '/cc-programs-v3',
            controller: 'CC-ProgramsController-V3',
            templateUrl: 'templates/childcontrol/cc_programs_v3/templates/programs-v3.html',
        });
    })
    .run(function() {});
