'use strict';
angular.module('ChicoApp').config(function($stateProvider) {
    $stateProvider.state('cc-programs-v4', {
        url: '/cc-programs-v4',
        views: {
            "@": {
                component: 'programsV4CC'
            }
        },
        params: {
            // Current page in webList
            appListPage: 1
        }
    });
});