'use strict';
angular.module('ChicoApp.CB-Appfilter-V2', [])
    .config(function ($stateProvider) {

        $stateProvider

            .state('cb-appfilter-v2', {
                url: '/cb-appfilter-v2',
                controller: 'CB-AppfilterController-V2',
                redirectTo: 'cb-appfilter-v2.allowed',
                templateUrl: 'templates/chicobrowser/cb_appfilter_v2/templates/appfilter.html'
            })

            .state('cb-appfilter-v2.allowed', {
                url: '/allowed',
                controller: 'CB-Appfilter-Allowed-Controller-V2',
                templateUrl: 'templates/chicobrowser/cb_appfilter_v2/templates/appfilter-generic-list.html'
            })

            .state('cb-appfilter-v2.blocked', {
                url: '/blocked',
                controller: 'CB-Appfilter-Blocked-Controller-V2',
                templateUrl: 'templates/chicobrowser/cb_appfilter_v2/templates/appfilter-generic-list.html',
            })

            .state('cb-appfilter-v2.timelimit', {
                url: '/timelimit',
                controller: 'CB-Appfilter-Timelimit-Controller-V2',
                templateUrl: 'templates/chicobrowser/cb_appfilter_v2/templates/appfilter-generic-list.html',
            })

            .state('cb-appfilter-v2.group', {
                url: '/group',
                controller: 'CB-Appfilter-Group-Controller-V2',
                templateUrl: 'templates/chicobrowser/cb_appfilter_v2/templates/appfilter-group.html',
            })

        ;

    })
    .run(function () {
    });
