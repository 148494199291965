'use strict';
angular.module('ChicoApp.CB-Dashboard', [])
    .config(function($stateProvider) {
      $stateProvider.state('cb-dashboard', {
        url: '/cb-dashboard',
        controller: 'CB-DashboardController',
        templateUrl: 'templates/chicobrowser/cb_dashboard/templates/dashboardv2.html'
      })

    })
    .run(function() {});
