'use strict';
angular.module('ChicoApp.CC-Internet')

.controller('CC-InternetControllerByAge', function(
        $scope,
        $state,
        $translate,
        scgGlobalSpinnerFactory,
        scgSharedFactory,
        ccInternetByAgeFactory,
        ccListsFactory,
        DTOptionsBuilder,
        DTColumnDefBuilder,
        scgDataTableFactory,
        _,
        $log) {

  $scope.contentLoading = true;

  ccInternetByAgeFactory.getSettings().then(function success(response) {

    $scope.data = response.data;
    $scope.contentLoading = false;

    _.each($scope.data, function(v, k) {
      $scope.$watch('data.' + k, function(new_val, old_val) {

        if (new_val > 1 && k == 'webFilterAge' && typeof new_val != 'undefined') {
          $scope.isAgeFilterActive = true;
        } else if (k == 'webFilterAge' && typeof new_val != 'undefined') {
          $scope.isAgeFilterActive = false;
        }
        if (new_val != old_val) {
          var changed_object = {};
          changed_object[k] = parseInt(new_val);
          $log.info('changed_object: ', changed_object);
          scgSharedFactory.needsSync = true;
          ccInternetByAgeFactory.setSetting(changed_object);
        }

      }, true);
    });

    scgGlobalSpinnerFactory.unblockUI();

  });

});
