'use strict';
angular.module('ChicoApp.CC-SYNC', [])
    .config(function($stateProvider) {
      $stateProvider.state('cc-sync', {
        url: '/cc-sync',
        controller: 'CC-SyncController',
        templateUrl: 'templates/childcontrol/cc_sync/templates/sync.html'
      });
    })
    .run(function() {});
