'use strict';
angular.module('ChicoApp.CC-TimeLimits')
    .controller('CC-TimeLimitsControllerSpecial', function(
        $rootScope,
        $translate,
        $state,
        $window,
        $scope,
        $http,
        $timeout,
        $log,
        moment,
        _,
        ccDashboardFactory,
        ccTimeLimitsFactory,
        scgGlobalSpinnerFactory,
        scgSharedFactory) {

      $scope.data = {};
      $scope.hasNewExtLogic = false;
      $scope.is2019 = false;


      // $scope.dateformat = 'yyyy-MM-dd HH:mm';
      // $window.moment.locale('de', {
      //   longDateFormat: {
      //     LT: 'HH:mm',
      //     LTS: 'hh:mm:ss',
      //     L: 'MM/DD/YYYY',
      //     LL: 'MMMM Do YYYY',
      //     LLL: 'MMMM Do YYYY LT',
      //     LLLL: 'dddd, MMMM Do YYYY LT'
      //   }
      // });

      // $scope.data.lockDate = moment(new Date()).format('YYYY-MM-DD');
      // $scope.data.unlockDate = moment(new Date()).format('YYYY-MM-DD');

      var apiDateFormat = 'YYYY-MM-DD HH:mm:SS';

      $scope.onSetLockOrUnlockDate = function() {
        /* if($scope.data.OneTimeLockLevel==2){
             console.log("change unlock");
             $scope.data.unlockDate = moment(newDate).toDate();
         }else if( $scope.data.OneTimeLockLevel==3 ){
             console.log("change lock");
             $scope.data.lockDate = moment(newDate).toDate();
         }*/
      };

      /*Ende Datetimepicker*/
      var refreshData = function(withSpinner) {
        if (withSpinner) {
          scgGlobalSpinnerFactory.blockUI();
        }
        ccTimeLimitsFactory.getSpecialSettings()
                .then(function success(response) {

                if (!response.data.lockDate || response.data.lockDate == '') {
                    response.data.lockDate = moment().add(3, 'days').toDate();
                }else {
                    response.data.lockDate = moment(response.data.lockDate).toDate();
                }

                if (!response.data.unlockDate || response.data.unlockDate == '') {
                        response.data.unlockDate = moment().add(3, 'days').toDate();
                 }else {
                        response.data.unlockDate = moment(response.data.unlockDate).toDate();
                 }



                    $scope.data = response.data;
                  //add watchers
                  _.each($scope.data, function(v, k) {
                    $scope.$watch('data.' + k, function(new_val, old_val) {
                      if (new_val != old_val) {
                        var changed_object = {};
                        if (k == 'lockDate' || k == 'unlockDate') {
                          changed_object[k] = moment(new_val).format(apiDateFormat);
                        }else {
                          changed_object[k] = new_val;
                        }
                        scgSharedFactory.needsSync = true;
                        ccTimeLimitsFactory.setSetting(changed_object);
                      }
                    }, true);
                  });
                  if (withSpinner) {
                    scgGlobalSpinnerFactory.unblockUI();
                  }
                });
      };
      $scope.$on('$viewContentLoaded', function() {
        refreshData(true);
      });

      $scope.resetCountdownTime = function() {
        ccTimeLimitsFactory.resetCountdownTime()
                .then(function success() {
                  refreshData(false);
                });
      };

      ccDashboardFactory.getTodayTotal(moment(new Date()).format('YYYY-MM-DD'))
            .then(function success(response) {
              $scope.todayTotal = $translate.instant('USAGE.TODAYTOTAL') + ': ' + response.data;
            }
        );

       $scope.openSettingsWithHighlight = function(highlight) {
            $state.go('cc-settings.general', {highlight: highlight});
        };


        scgSharedFactory.getCCVersionNumber().then(function success(version) {
            $log.info('SPECIAL LIMIT CCVersion number is:', version);
            if (version.major >= 16 && version.minor >= 2182) {
                $scope.hasNewExtLogic = true;
            }
        });

        ccTimeLimitsFactory.getAllUsersSettings()
            .then(function success(response) {
                $scope.afterExpire = response.data.generalForcedBreaksLevel;
                $log.info('dierk after expire', $scope.afterExpire);
            }
        );

        window.scrollTo(0,0);


    });
