angular.module('ChicoApp').component('programsV4CC', {
    templateUrl: 'templates/childcontrol/cc_programs_v4/prgFilterV4.component.html',
    controller: ProgramsV4Controller
});

function ProgramsV4Controller(
    $translate,
    $state,
    $location,
    $anchorScroll,
    $timeout,
    $window,
    _,
    moment,
    $log,
    $http,
    $modal,
    API,
    AuthFactory,
    appListsFactory,
    scgGlobalSpinnerFactory,
    modalFactory,
    ccProgramsFactory,
    scgSettingsFactory,
    scgSharedFactory,
    appInfoFactory,
    apiFunctions,
    cbWebFilterFactory,
    cbInternetCategoryFactory,
    chicoEnums
) {


    var ctrl = this;

    ctrl.chicoEnums = chicoEnums;
    ctrl.data = {};

    ctrl.loadingApps = true;
    ctrl.loadingData = false;

    // If availableKinds is initialized with [] instead of null, strange things happen at kind-select-dropdown directive:
    // Checkboxes are replaced with radio buttons
    ctrl.availableKinds = null;
    ctrl.selectedKinds = {};

    ctrl.groups = [];
    ctrl.applist = [];
    ctrl.selectedListRows = [];
    ctrl.currentContext = AuthFactory.activeContext;
    ctrl.headerEntries = [];
    ctrl.spinnerSettings = true; //must be initalised with true because of switch disabled state

    ctrl.appListPage = 1;

    ctrl.data.defaultAppKind=3;

    ctrl.appKind = chicoEnums.AppTypeEnum.WINDOWS_APP;

    ctrl.$onInit = function() {
        // Prepare headers for applist
        $translate(['HEADER.ENTRY', 'HEADER.INSTALLDATE']).then(function(translations) {
            ctrl.headerEntries = [
                {
                    name: translations['HEADER.ENTRY'],
                    key: 'entry',
                    secondKey: 'details',
                    mainColumn: true,
                    allowSorting: true,
                    sortAscFn: function(a, b) {
                        return a.entry.localeCompare(b.entry);
                    },
                    sortDescFn: function(a, b) {
                        return a.entry.localeCompare(b.entry) * -1;
                    }
                },
                {
                    name: translations['HEADER.INSTALLDATE'],
                    key: 'installDateFormatted',
                    hideXs: true,
                    allowSorting: true,
                    sortAscFn: function(a, b) {
                        return moment(a.installDate).isAfter(moment(b.installDate)) ? 1 : -1;
                    },
                    sortDescFn: function(a, b) {
                        return moment(a.installDate).isBefore(moment(b.installDate)) ? 1 : -1;
                    }
                }
            ];
        });

        // Prepare kinds and groups for filter
        scgSettingsFactory.getGroups(false).then(function success(response) {
            ctrl.groups = response.data;

            if (ctrl.groups.length == 5) {
                ctrl.group1name = ctrl.groups[0].name;
                ctrl.group2name = ctrl.groups[1].name;
                ctrl.group3name = ctrl.groups[2].name;
                ctrl.group4name = ctrl.groups[3].name;
                ctrl.group5name = ctrl.groups[4].name;
            }

            ctrl.prepareKinds();
        });


        ctrl.loadList();

        ctrl.appListPage = $state.params.appListPage;
    };

    ctrl.turnOn = function()
    {
        ctrl.data.isPrgFilterActive=true;
        ctrl.onModelChange();
    }

    ctrl.onMainSwitchChange = function() {
        $log.info('onMainSwitchChange', ctrl.data);
    }


    ctrl.$postLink = function() {
        // Attach event to bootstrap switch to detect when switch is toggled (not possible in html template when using AngularJS 1.5; must be done here)
        angular.element('#prg-filter-active-switch').on('switchChange.bootstrapSwitch', ctrl.onModelChange);

        var hash = $location.hash();

        // Clear URL fragment to prevent scrolling again on page reload etc.
        delete $state.params['#'];
        $location.hash('');

        // Scroll to position given in the URL fragment
        if (hash !== '') {
            $timeout(function() {
                $log.info('Scrolling to ' + hash);
                $anchorScroll(hash);
            }, 1000);
        }
    };


    ctrl.editSelectedRows = function () {

        var selectedAppIds = ctrl.selectedListRows.map(function(entry) {
            return entry.entry;
        });

        $state.go('detail-view', { appIds: selectedAppIds, appType: chicoEnums.AppTypeEnum.WINDOWS_APP, allowAdd: false, prevStateName: $state.current.name });
    };

    ctrl.onAppListRowClick = function (row) {
        $log.info('applistRowClick entry=', row.entry);
        $state.go('detail-view', { appIds: [row.entry], appType: chicoEnums.AppTypeEnum.WINDOWS_APP, allowAdd: false, prevStateName: $state.current.name });
    };

    ctrl.searchFilterFunc = function(listEntries, searchInput) {
        return _.filter(listEntries, function (object) {
            if (object.entry.toLowerCase().includes(searchInput.toLowerCase())
                || (object.details !== undefined && object.details !== null && object.details.toLowerCase().includes(searchInput.toLowerCase()))
            ) {
                return true;
            }
        });
    };

    ctrl.filterFunc = function(listEntries, filterParams) {
        // Only filter if there are any selectedKinds to check
        // Workaround because kind-select-dropdown is not fast enough in intialization...
        if (filterParams.selectedKinds && Object.keys(filterParams.selectedKinds).length > 0) {
            var filtered = _.filter(listEntries, function (object) {
                if (!filterParams.selectedKinds[object.kind]) {
                    return false; // What to do if we have no filter information for that kind, true=show, false=hide
                }
                return filterParams.selectedKinds[object.kind] == true;
            });
            return filtered;
        } else {
            return listEntries;
        }
    };


    ctrl.onPageChange = function(newPage) {
        ctrl.appListPage = newPage;
        $state.params.appListPage = newPage;
    };

    ctrl.onModelChange = function() {
        $log.info('onModelChange=', ctrl.data);
        scgSharedFactory.needsSync = true;
        ccProgramsFactory.setSetting(ctrl.data);
    };

    ctrl.loadingData = true;
    ccProgramsFactory.getSettings().then(function success(response) {
        ctrl.data = response.data;
        ctrl.loadingData = false;
    });

    ctrl.loadList = function() {
        ctrl.loadingApps = true;
        appListsFactory.getAppListCC().then(function success(response) {
            ctrl.applist = response.data;

            $translate.onReady(function() {
                for (var i = 0; i < ctrl.applist.length; i++) {
                    ctrl.applist[i].iconHref                = ctrl.getIcon(ctrl.applist[i]);
                    ctrl.applist[i].installDateFormatted    = scgSharedFactory.formatDate(ctrl.applist[i].installDate);
                }
            });

            // Clear selectedListRows because it is not cleared automatically when data changes
            ctrl.selectedListRows = [];
            ctrl.loadingApps = false;
        });
    };

    ctrl.getIcon = function(app) {
        return appInfoFactory.getExeIconUrl(app.entry);
    };

    ctrl.prepareKinds = function() {
        var availableKindsPrep = [{
            kind: chicoEnums.KindEnum.ALLOWED_BASIC,
            name: chicoEnums.kindToText(chicoEnums.KindEnum.ALLOWED_BASIC),
            selected: true,
            editable: false,
            addCircleClass: 'scg-multiselect-badge-green',
            circleContent: '<i class="fa fa-binoculars"></i>'
        }, {
            kind: chicoEnums.KindEnum.ALLOWED_OPTIONS,
            name: chicoEnums.kindToText(chicoEnums.KindEnum.ALLOWED_OPTIONS),
            selected: true,
            editable: false,
            addCircleClass: 'scg-multiselect-badge-green',
            circleContent: '<i class="fa fa-binoculars"></i>'
        }, {
            kind: chicoEnums.KindEnum.TIMELIMITED,
            name: chicoEnums.kindToText(chicoEnums.KindEnum.TIMELIMITED),
            selected: true,
            editable: false,
            addCircleClass: 'scg-multiselect-badge-red',
            circleContent: '<i class="fa fa-binoculars"></i>'
        }, {
            kind: chicoEnums.KindEnum.BLOCKED,
            name: chicoEnums.kindToText(chicoEnums.KindEnum.BLOCKED),
            selected: true,
            editable: false,
            addCircleClass: 'scg-multiselect-badge-yellow',
            circleContent: '<i class="fa fa-binoculars"></i>'
        }, {
            kind: chicoEnums.KindEnum.GROUP_SYSTEM,
            name: chicoEnums.kindToText(chicoEnums.KindEnum.GROUP_SYSTEM),
            selected: false,
            editable: false,
            addCircleClass: 'scg-multiselect-badge-grey',
            circleContent: '<i class="fa fa-binoculars"></i>'
        }, {
            kind: chicoEnums.KindEnum.GROUP_WINAPPS,
            name: chicoEnums.kindToText(chicoEnums.KindEnum.GROUP_WINAPPS),
            selected: false,
            editable: false,
            addCircleClass: 'scg-multiselect-badge-darkgreen',
            circleContent: '<i class="fa fa-binoculars"></i>'
        }, {
            kind: chicoEnums.KindEnum.GROUP_INTERNET,
            name: chicoEnums.kindToText(chicoEnums.KindEnum.GROUP_INTERNET),
            selected: true,
            editable: false,
            addCircleClass: 'scg-multiselect-badge-yellow',
            circleContent: '<i class="fa fa-binoculars"></i>'
        }];

        _.each(ctrl.groups, function (group /*, index*/ ) {

            var circleContent = group.kind - 10;

            availableKindsPrep.push({
                kind: group.kind,
                name: "#" + circleContent + " " + group.name,
                selected: true,
                editable: true,
                addCircleClass: 'scg-multiselect-badge-yellow',
                circleContent: circleContent
            });
        });

        ctrl.availableKinds = availableKindsPrep;
    };

    ctrl.renameKindDropDownItem = function(kind, newName){
        var itemToChange = _.filter(ctrl.availableKinds, {kind: kind});
        itemToChange.name = newName;
        scgSettingsFactory.changeGroupName({groupkind: kind, groupName: newName});
    };

    ctrl.setAge = function (age) {
        $log.info('set age=' + age);
        ctrl.data.programFilterAge = age;
        ctrl.onModelChange();
    }


    // BUTTON HANDLING
    // -----------------------------------------------------------------------------

    ctrl.onBtnAdd = function() {
        $log.info("onBtnAdd");
        $state.go('detail-view', {appIds: [], appType: chicoEnums.AppTypeEnum.WINDOWS_APP, allowAdd: true,prevStateName: $state.current.name });
    };

    ctrl.onBtnEdit = function() {
        $state.go('detail-view', {appIds: ctrl.toArray(ctrl.selectedListRows), appType: chicoEnums.AppTypeEnum.WINDOWS_APP, allowAdd: false,prevStateName: $state.current.name });
    }

    ctrl.onBtnDelete = function() {
        appListsFactory.deleteAppsCC(ctrl.toArray(ctrl.selectedListRows)).then(function success(response){
            $state.go($state.current, {}, {reload: true}); });
    }

    ctrl.onBtnTest = function() {
        $log.info('onBtnTest');
        $state.go('detail-view', {appIds: ['test.exe'], appType: chicoEnums.AppTypeEnum.WINDOWS_APP, allowAdd: false,prevStateName: $state.current.name});
    }

    ctrl.toArray = function(selectedListRows)   {
        var arr = [];
        _.each(selectedListRows, function (item) {
            $log.info('makeArray item=', item);
            arr.push(item.entry);
        });
        return arr;
    }




}
