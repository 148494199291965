angular.module('ChicoApp.Shared').directive('scgTooltip', function(
    $compile
) {
    return {
        restrict: 'A',
        controller: TooltipController,
        scope: {
            /**
             * Position of the tooltip.
             * @type {"top"|"right"|"bottom"|"left"}
             */
            tooltipPosition: '@tooltipPosition',
            /**
             * Text of the tooltip
             * @type {string}
             */
            tooltipText: '<tooltipText',
            /**
             * Set to true to disable the tooltip
             * @type {boolean} True to disable tooltip (optional, default false)
             */
            tooltipDisabled: '<?tooltipDisabled'
        },
        link: function(scope, element, attr) {
            var tooltipTemplate = '<scg-tooltip-component '
                + 'ng-if="showTooltip" '
                + 'ng-style="{left: coords.left, top: coords.top, transform: tooltipTranslate}" '
                + 'position="tooltipPosition" text="tooltipText" '
                + 'style="position: absolute; z-index: 20000;">'
                + '</scg-tooltip-component>';
            
            var tooltipElement = angular.element(tooltipTemplate);
            var tooltipScope = scope.$new(true);
            tooltipScope.showTooltip = false;
            tooltipScope.tooltipText = scope.tooltipText;
            tooltipScope.tooltipPosition = scope.tooltipPosition;
            tooltipScope.coords = {
                left: '0',
                top: '0'
            };
            tooltipScope.tooltipTranslate = '';
            scope.tooltipScope = tooltipScope;


            var compiledTooltip = $compile(tooltipElement)(tooltipScope);
            element.append(compiledTooltip);

            element.on('mouseenter', scope.onMouseEnter);
            element.on('mouseleave', scope.onMouseLeave);

            scope.$watch('tooltipText', function() {
                tooltipScope.tooltipText = scope.tooltipText;
            });
        }
    };
});

function TooltipController(
    $scope,
    $element
) {
    if (typeof $scope.tooltipDisabled === 'undefined') {
        $scope.tooltipDisabled = false;
    }

    /**
     * Show tooltip
     */
    $scope.onMouseEnter = function() {
        if ($scope.tooltipDisabled === false) {
            // Add watcher to update tooltip position (only if no watcher exists already)
            if (typeof $scope.unregisterWatchPosition === 'undefined') {
                $scope.unregisterWatchPosition = $scope.$watchCollection(function() {
                    return {
                        left: $element.position().left,
                        top: $element.position().top,
                        width: $element.width(),
                        height: $element.height()
                    };
                }, $scope.updateTooltipCoords);
            }

            $scope.tooltipScope.showTooltip = true;

            // Call apply to update tooltip visibility immediately
            $scope.tooltipScope.$apply();
        }
    };

    /**
     * Hide tooltip
     */
    $scope.onMouseLeave = function() {
        if ($scope.tooltipDisabled === false ||$scope.tooltipScope.showTooltip === true) {
            $scope.tooltipScope.showTooltip = false;

            // Call apply to update tooltip visibility immediately
            $scope.tooltipScope.$apply();

            // Remove watcher if tooltip is not visible (for performance reasons)
            // Also delete unregister function to allow checks that determine if the watcher still exists
            if (typeof $scope.unregisterWatchPosition !== 'undefined') {
                $scope.unregisterWatchPosition();
                delete $scope.unregisterWatchPosition;
            }   
        }
    };

    /**
     * Update position of the tooltip.
     * Position update is required if position or size of $element changes
     */
    $scope.updateTooltipCoords = function() {
        $scope.tooltipScope.coords.left = $element.position().left + parseInt($element.css('margin-left'));
        $scope.tooltipScope.coords.top = $element.position().top + parseInt($element.css('margin-top'));

        var translateX = '0';
        var translateY = '0';

        if ($scope.tooltipPosition === 'top' || $scope.tooltipPosition === 'bottom') {
            // -50% + $element.width() / 2
            translateX = 'calc(-50% + ' + ($element.width() / 2) + 'px)';
        } else if ($scope.tooltipPosition === 'left' || $scope.tooltipPosition === 'right') {
            // -50% + $element.height() / 2
            translateY = 'calc(-50% + ' + ($element.height() / 2) + 'px)';
        }

        switch($scope.tooltipPosition) {
            case 'top':
                translateY = 'calc(-100% - 5px)';
                break;
            case 'right':
                translateX = $element.outerWidth() + 5 + 'px';
                break;
            case 'bottom':
                translateY = $element.outerHeight() + 5 + 'px';
                break;
            case 'left':
                translateX = 'calc(-100% - 5px)';
                break;
        }

        $scope.tooltipScope.tooltipTranslate = 'translate(' + translateX + ',' + translateY + ')';
    };
} 
