angular.module('ChicoApp.Shared').factory('menuFactory', function ($rootScope,
                                                                   $state,
                                                                   $log,
                                                                   $timeout,
                                                                   $cookies,
                                                                   $location,
                                                                   AuthFactory) {
    var menu = {};

    //Menu Salfeld Browser
    var sbMenu = {
        name: 'Salfeld Browser Menu',
        menuItems: [{
            title: 'CC_DASH.START',
            icon: 'fa-home',
            state: 'sb-dashboard',
            visibleForDeviceTypes: [50],
            visibleFromMinAppVersion: 100000,
            visibleToMaxAppVersion: 1110041010
        }, {
            title: 'CB_DASH.WEBFILTER',
            icon: 'fa-globe',
            state: 'cb-webfilter',
            visibleForDeviceTypes: [50],
            visibleFromMinAppVersion: 100000,
            visibleToMaxAppVersion: 900000
        }, {
            title: 'CC_DASH.USAGE',
            icon: 'fa-bar-chart',
            state: 'sb-usage',
            visibleForDeviceTypes: [50],
            visibleFromMinAppVersion: 100000,
            visibleToMaxAppVersion: 900000
        }, {
            title: 'CC_DASH.SETTINGS',
            icon: 'fa-cog',
            state: 'sb-settings',
            visibleForDeviceTypes: [50],
            visibleFromMinAppVersion: 100000,
            visibleToMaxAppVersion: 900000
        }]
    };

    //Menu Salfeld Start Control
    var scMenu = {
        name: 'Start Control Menu',
        menuItems: [{
            title: 'MISC.START_CONTROL',
            icon: 'fa-rocket',
            state: 'sc-dashboard',
            visibleForDeviceTypes: [40],
            visibleFromMinAppVersion: 0,
            visibleToMaxAppVersion: 90000000
        }]
    };

    //Menu Child Control
    var ccMenu = {
        name: 'Childcontrol Menu',
        menuItems: [{
            title: 'CC_DASH.START',
            icon: 'fa-home',
            state: 'cc-dashboard-v3',
            visibleForDeviceTypes: [1, 2, 10, 11],
            visibleFromMinAppVersion: 17000000,
            visibleToMaxAppVersion: 90000000
        }, {
            /* THIS IS COMBINED MENU FOR CC BUT DOES NOT WORK
            * WAS; ISSUE BLOCKTIMES IN INTERNET AFFECTS ALSO PC BLOCKTIMES !!!
            * DO NOT USE (visibleFromAppVersion 300000) */
            title: 'HEADER.DEVICELIMIT',
            icon: 'fa-clock-o',
            state: 'cc-timelimits-all',
            visibleForDeviceTypes: [1, 2, 10, 11],
            visibleFromMinAppVersion: 30000000,
            visibleToMaxAppVersion: 90000000
        },
            {
            title: 'HEADER.DEVICELIMIT',
            icon: 'fa-desktop',
            state: 'cc-timelimits',
            visibleForDeviceTypes: [1, 2, 10, 11],
            visibleFromMinAppVersion: 17000000,
            visibleToMaxAppVersion: 90000000,
                submenu: [{
                title: 'CC_DASH.PCTIMELIMIT',
                icon: 'fa-desktop',
                state: 'cc-timelimits.pc',
                visibleForDeviceTypes: [1, 2, 10, 11],
                visibleToMaxAppVersion: 90000000
            }, {
                title: 'CC_DASH.INETTIMELIMIT',
                icon: 'fa-globe',
                state: 'cc-timelimits.internet',
                visibleForDeviceTypes: [1, 2, 10, 11],
                visibleToMaxAppVersion: 90000000
            }, {
                title: 'CC_DASH.SPECIALTIMELIMIT',
                icon: 'fa-wrench',
                state: 'cc-timelimits.special',
                visibleForDeviceTypes: [1, 2, 10, 11],
                visibleToMaxAppVersion: 90000000
            }, {
                title: 'CC_DASH.ROAMING',
                icon: 'fa-files-o',
                state: 'cc-timelimits.roaming',
                visibleForDeviceTypes: [1, 2, 10, 11],
                visibleFromMinAppVersion: 16002253,
                visibleToMaxAppVersion: 17002799
            }]
        }, {
            title: 'CB_DASH.WEBFILTER',
            icon: 'fa-globe',
            state: 'cc-webfilter-v2',
            visibleForDeviceTypes: [1, 2, 10, 11],
            visibleFromMinAppVersion: 17000000,
            visibleToMaxAppVersion: 90000000
        }, {
            title: 'USAGE.PROGRAMS',
            icon: 'fa-laptop',
            state: 'cc-programs-v4',
            visibleForDeviceTypes: [1, 2, 10, 11],
            visibleFromMinAppVersion: 17000000,
            visibleToMaxAppVersion: 90000000
        }, {
            title: 'HEADER.SCREENSHOTS',
            icon: 'fa-camera',
            state: 'cc-screenshots',
            visibleForDeviceTypes: [1, 2,10, 11],
            visibleFromMinAppVersion: 17000000,
            visibleToMaxAppVersion: 90000000
        }, {
            title: 'CC_DASH.SECURITY',
            icon: 'fa-shield',
            state: 'cc-security',
            visibleForDeviceTypes: [1, 2, 10, 11],
            visibleFromMinAppVersion: 17000000,
            visibleToMaxAppVersion: 90000000,
                submenu: [{
                title: 'CC_DASH.WINSECURITY',
                icon: 'fa-windows',
                state: 'cc-security.windows',
                visibleForDeviceTypes: [1, 2, 10, 11],
                visibleFromMinAppVersion: 17000000,
                visibleToMaxAppVersion: 90000000
            },]
        }, {
            title: 'CC_DASH.USAGE',
            icon: 'fa-bar-chart',
            state: 'cc-usage.reportsv2',
            visibleForDeviceTypes: [1, 2, 10, 11],
            visibleFromMinAppVersion: 17000000,
            visibleToMaxAppVersion: 90000000
        }, {
            title: 'CC_DASH.EMAILREPORT',
            icon: 'fa-envelope',
            state: 'cc-emailreport',
            visibleForDeviceTypes: [1, 2,10, 11],
            visibleFromMinAppVersion: 17000000,
            visibleToMaxAppVersion: 90000000
        }, {
            title: 'CC_DASH.SETTINGS',
            icon: 'fa-cog',
            state: 'cc-settings',
            visibleForDeviceTypes: [1, 2, 10, 11],
            visibleFromMinAppVersion: 17000000,
            visibleToMaxAppVersion: 90000000
        }, {
            icon: 'fa-star',
            title: 'CC_DASH.TANS',
            state: 'cc-tans',
            visibleForDeviceTypes: [1, 2,10, 11],
            visibleFromMinAppVersion: 17000000,
            visibleToMaxAppVersion: 90000000
        }, {
            title: 'SIDEBAR.SYNC',
            icon: 'fa-exchange',
            state: 'cc-sync',
            visibleForDeviceTypes: [1, 2, 10, 11],
            visibleFromMinAppVersion: 17000000,
            visibleToMaxAppVersion: 90000000
        }]
    };

    // *****************************************************************************************************************
    // *****************************************************************************************************************
    // *****************************************************************************************************************

    var cbMenu = {
        name: 'Chicobrowser Menu',
        menuItems: [{
            title: 'CB_DASH.START',
            icon: 'fa-home',
            state: 'cb-dashboard-v3',
            visibleForDeviceTypes: [30],
            visibleFromMinAppVersion: 200000,
            visibleToMaxAppVersion: 900000
        }, {
            title: 'CB_DASH.START',
            icon: 'fa-home',
            state: 'cb-dashboard',
            visibleForDeviceTypes: [10,11],
            visibleFromMinAppVersion: 200000,
            visibleToMaxAppVersion: 900000
        }, {
            title: 'HEADER.DEVICELIMIT',
            icon: 'fa-clock-o',
            state: 'cb-timelimits',
            visibleForDeviceTypes: [30],
            visibleFromMinAppVersion: 200000,
            visibleToMaxAppVersion: 900000
        }, {
            title: 'CB_DASH.TIMELIMITS',
            icon: 'fa-clock-o',
            state: 'cbtime',
            visibleForDeviceTypes: [10,11],
            visibleFromMinAppVersion: 200000,
            visibleToMaxAppVersion: 900000,
            submenu: [{
                icon: 'fa-clock-o',
                title: 'HEADER.DEVICELIMIT',
                state: 'cbtime.devicetime',
                visibleForDeviceTypes: [10,30],
                visibleFromMinAppVersion: 211040 //"2.1.1040"
            }, {
                icon: 'fa-clock-o',
                title: 'Chico Browser Limit',
                state: 'cbtime.browsertime',
                visibleForDeviceTypes: [10],
                visibleFromMinAppVersion: 200000,
                visibleToMaxAppVersion: 900000

            }, {
                icon: 'fa-files-o',
                title: 'CC_DASH.ROAMING',
                state: 'cbtime.roaming',
                visibleForDeviceTypes: [10,30],
                visibleFromMinAppVersion: 211055,
                visibleToMaxAppVersion: 311200
            }]
        }, {
            title: 'CC_DASH.WEBFILTER',
            icon: 'fa-globe',
            state: 'cb-webfilter-v2',
            visibleForDeviceTypes: [10,11,30,50],
            visibleFromMinAppVersion: 100000,
            visibleToMaxAppVersion: 900000
        }, {
            title: 'CB_DASH.APPS',
            icon: 'fa-tablet',
            state: 'cb-appfilter-v4',
            visibleForDeviceTypes: [10,30],
            visibleFromMinAppVersion: 200000,
            visibleToMaxAppVersion: 900000
        }, {
            title: 'EMAIL.EMAILREPORT',
            icon: 'fa-envelope',
            state: 'cb-emailreport',
            visibleForDeviceTypes: [10,11,30],
            visibleFromMinAppVersion: 200000,
            visibleToMaxAppVersion: 900000
        }, {
            title: 'CB_DASH.USAGE',
            icon: 'fa-bar-chart',
            state: 'cb-usage.reportsv2',
            visibleForDeviceTypes: [10,11,30,50],
            visibleFromMinAppVersion: 100000,
            visibleToMaxAppVersion: 900000
        }, {
            title: 'CB_DASH.GPS',
            icon: 'fa-map-marker',
            state: 'cb-gps',
            visibleForDeviceTypes: [10,11,30],
            visibleFromMinAppVersion: 200000,
            visibleToMaxAppVersion: 900000
        }, {
            title: 'HEADER.SCREENSHOTS',
            icon: 'fa-camera',
            state: 'cb-screenshots',
            visibleForDeviceTypes: [10,11,30],
            visibleFromMinAppVersion: 311265,
            visibleToMaxAppVersion: 90000000
        }, {
            title: 'CB_DASH.SETTINGS',
            icon: 'fa-cog',
            state: 'cb-settings',
            visibleForDeviceTypes: [10,11,30],
            visibleFromMinAppVersion: 200000,
            visibleToMaxAppVersion: 900000
        },{
            title: 'CC_DASH.TANS',
            icon: 'fa-star',
            state: 'cb-tans',
            visibleForDeviceTypes: [30],
            visibleFromMinAppVersion: 200000,
            visibleToMaxAppVersion: 900000
        },
            {
            title: 'SIDEBAR.SYNC',
            icon: 'fa-exchange',
            state: 'cb-sync',
            visibleForDeviceTypes: [10,11,30],
            visibleFromMinAppVersion: 200000,
            visibleToMaxAppVersion: 900000
        }

        ]
    };

    var internMenu = {
        name: 'Internal Menu',
        menuItems: [{
            title: 'KISIKAT',
            icon: 'fa-home',
            state: 'internal.kisikat',
            visibleForDeviceTypes: [1, 2, 10, 11,30]
        }, {
            title: 'LIZENZEN',
            icon: 'fa-home',
            state: 'internal.lizenzen',
            visibleForDeviceTypes: [1, 2, 10, 11,30]
        },{
            title: 'SIDEBAR.SYNC',
            icon: 'fa-home',
            state: 'cc-sync',
            visibleForDeviceTypes: [1, 2, 10, 11,30]
        }]
    };


    var defaultMenu = {
        name: 'Default Menu',
        menuItems: []
    };

    menu.activeMenu = cbMenu;
    menu.defaultMenu = defaultMenu;

    $rootScope.$watch(function () {
        return AuthFactory.activeContext;
    }, function (context) {
        $rootScope.setMenuForContext(context);
    });

    $rootScope.$watch(function () {
        return AuthFactory.activeLicence;
    }, function (activeLicence) {
         $rootScope.setMenuForContext(AuthFactory.activeContext);
    });

    $rootScope.setMenuForContext = function (context) {
        if (context) {
            $log.info('MENU SetMenuForContext', context);

            //Special Case intern menu
            if ($cookies.get('pcId') && $cookies.get('pcId') == '10:bf:48:d0:37:ad') {
                $log.info('MENU INTERN!');
                menu.activeMenu = internMenu;
                return;
            }

            if (AuthFactory.activeLicence.RemainingDays <= 0 || AuthFactory.activeLicence.showSideBar === false) {
                $log.info('MENU disabled!');
                menu.activeMenu = defaultMenu;
                return;
            }

            if (context == 'cc') {
                menu.activeMenu = ccMenu;
            } else if (context == 'cb') {
                menu.activeMenu = cbMenu;
            } else if (context == 'sb') {
                menu.activeMenu = sbMenu;
            }else if (context == 'sc') {
                menu.activeMenu = scMenu;
                $log.info('We are sc menu!');
            }
        }
        else {
            $log.info('MENU NO context!');
        }
    };

    $rootScope.$watch(AuthFactory.activeLicence.showSideBar, function (nV, oV) {
        if (nV && nV != oV) {
            $log.info('showSide Change!', nV);
        }
    });

    menu.hideSidebar = function () {
        $log.info('MENU disabled!');
        menu.activeMenu = defaultMenu;
    };

    menu.showSidebar = function () {
        $log.info('show sidebar, activeContext=' + AuthFactory.activeContext);
        if (AuthFactory.activeContext == 'cc') {
            menu.activeMenu = ccMenu;
        }
        if (AuthFactory.activeContext == 'cb') {
            menu.activeMenu = cbMenu;
        }
        if (AuthFactory.activeContext == 'sb') {
            menu.activeMenu = sbMenu;
        }
        if (AuthFactory.activeContext == 'sc') {
            menu.activeMenu = scMenu;
        }
    };

    return menu;
});
