angular.module('ChicoApp.Shared').component('kkView', {
    templateUrl: 'templates/shared/kkView/kkView.component.html',
    controller: kkViewController,
    bindings:   {
    /**
     * @type {string}, use "@"
     */
    suggestmode : '<',
    entry : '<',
    apptype : '<'

    }
});

function kkViewController(
    _,
    $log,
    $state,
    $http,
    $translate,
    API,
    chicoEnums,
    scgSharedFactory,
    appInfoFactory


) {

    var ctrl = this;

    ctrl.chicoEnums = chicoEnums;
    ctrl.loadingAppInfo = true;
    ctrl.singleAppEntry = "";
    ctrl.singleAppLink = "";
    ctrl.appInfo = {};


    ctrl.$onInit = function() {

        if (ctrl.entry !== null) {
            $log.info('welcome kkView entry=' + ctrl.entry + '|age=' + ctrl.age + '|catid=' + ctrl.catid);
        }

    };

    ctrl.$postLink = function() {
        $log.info('kk getAppInfo POSTLINK entry=' + ctrl.entry);

    };

    ctrl.$onChanges = function(changes) {
        if (changes.entry && ctrl.entry) {
            $log.info('kk getAppInfo CHANGES entry=' + ctrl.entry);
            // Make sure, app info (especially description) is not loaded before it is clear which language is used
            $translate.onReady().then(function() {
                $log.info('kkview 2 getAppInfo TRANSLATE READY entry=' + ctrl.entry);
                ctrl.getAppInfo();
                ctrl.setAppLink();
            });
        }
    };



    ctrl.getAppInfo = function() {

        // App info is only displayed if multiView is disabled and if only app is displayed
        $log.info('kkView appInfo Init, entry=' + ctrl.entry + '|apptype=' + ctrl.apptype);

        if (!ctrl.entry) {
            $log.info('kkView appInfo Init, entry=' + ctrl.entry + '|EXIT');
            ctrl.loadingAppInfo = true;
            return;
        }

            // We must differe here if it is a Website, App or Exe
            // ----------------------------------------------------------
            if (ctrl.apptype === chicoEnums.AppTypeEnum.APP) {
                appInfoFactory.getAppDetails(ctrl.entry).then(function (response) {
                    ctrl.appInfo = response.data;
                    ctrl.loadingAppInfo = false;
                });
            } else if (ctrl.apptype === chicoEnums.AppTypeEnum.WEBSITE) {
                appInfoFactory.getWebDetails(ctrl.entry).then(function (response) {
                    ctrl.appInfo = response.data;
                    $log.info('kkView appinfo data=', ctrl.appInfo);

                    if (ctrl.appInfo.catID>10 && ctrl.appInfo.catName==null) {
                        var tname ='CC_INTERNET.CATEGORIES.WEBBLOCKEDCATEGORY' + ctrl.appInfo.catID;
                        ctrl.appInfo.catName = $translate.instant(tname);
                        $log.info('kkView tname=' + tname + '|catName=' +ctrl.appInfo.catName );
                    }
                    ctrl.loadingAppInfo = false;
                });
            } else if (ctrl.apptype === chicoEnums.AppTypeEnum.WINDOWS_APP) {
                appInfoFactory.getPrgDetails(ctrl.entry).then(function (response) {
                    ctrl.appInfo = response.data;
                    ctrl.loadingAppInfo = false;
                });
            }

    };


    ctrl.toggleSuggestMode = function() {
        ctrl.suggestmode=!ctrl.suggestmode;
    }

    ctrl.suggest = function() {
        ctrl.sendSuggest();
        ctrl.suggestmode=false;
    }

    ctrl.setAppLink = function() {
        if (ctrl.apptype  === chicoEnums.AppTypeEnum.WEBSITE) {
            ctrl.singleAppLink="https://" + ctrl.entry + "/";
        }
        if (ctrl.apptype  === chicoEnums.AppTypeEnum.APP) {
            ctrl.singleAppLink= ctrl.getPlaystoreLnk();
        }
        if (ctrl.apptype  === chicoEnums.AppTypeEnum.WINDOWS_APP) {
            ctrl.singleAppLink=""; //no link for windows exe
        }
        $log.info('setAppLink done=' + ctrl.singleAppLink);
    }


    ctrl.sendSuggest = function() {

        //Map to kkitem
        $log.info('kkView sendsuggest appInfo=', ctrl.appInfo);

        //Remap to entry in case of Exe
        if (ctrl.apptype  === chicoEnums.AppTypeEnum.WINDOWS_APP) {
            ctrl.appInfo.URL = ctrl.entry;
        }

        if (ctrl.appInfo.catID==-1 && ctrl.appInfo.age==-1) {
            $log.info('nothing is selected');
            return;
        }

        if ($translate.use()== 'en_US') {
            ctrl.appInfo.lang = "en";
        } else {
            ctrl.appInfo.lang = "de";
        }

        var req = {
            method: 'POST',
            url: API.url + 'action/suggestkisikat',
            data: ctrl.appInfo
        };
        return $http(req)
            .then(function success(response) {
                $log.info('http success respose=', response);
                ctrl.suggestmode=false;
                $state.reload();
            }, function error(err) {
                $log.info('error while HTTP');
                ctrl.suggestmode=false;
                $state.reload();
            });
    };



    ctrl.getPlaystoreLnk = function()   {

        var req = {
                method: 'POST',
                url: API.url + 'action/getpkgname',
                data: { 'appName' : ctrl.entry}
            };

            $http(req).then(function success(response) {
                if (response.data.pkgName) {
                    if ($translate.use() == 'de_DE') {
                        ctrl.singleAppLink =  "https://play.google.com/store/apps/details?id=" + response.data.pkgName + "&hl=de";
                    } else {
                        ctrl.singleAppLink = "https://play.google.com/store/apps/details?id=" + response.data.pkgName;
                    }
                }

            }).catch(function(error){
                $log.info("getPlayStoreLnk ERROR:" , error);
                ctrl.singleAppLink = "";

            });
    }





}
