'use strict';
angular.module('ChicoApp.CC-Programs-V2')
    .controller('CC-Programs-TimeLimited-Controller-V2', function(
        $scope,
        $state,
        $translate,
        ccListsFactory,
        DTOptionsBuilder,
        DTColumnDefBuilder,
        scgDataTableFactory
        ) {

      var modalStartKind = ccListsFactory.listKinds.list_kind_timelimited_prg;

      $scope.tableHeaders = [
        $translate.instant('HEADER.ENTRY'),
        $translate.instant('HEADER.DETAILS'),
        $translate.instant('HEADER.INSTALLDATE')
        ];

      $scope.kind = modalStartKind;
      $scope.data = {};
      $scope.data.table_api = [];


      scgDataTableFactory.prepareScopeForTable($scope, modalStartKind);

        $scope.dtOptions = scgDataTableFactory.getDefaultDtOptions($scope).withOption('order', [[2, 'asc']]);
        $scope.dtColumnDefs = scgDataTableFactory.getDefaultColumnsIconTable();


        $scope.reload = function() {
        $state.go($state.current, {}, {reload: true});
      };

      var loadList = function() {
        $scope.contentLoading = true;
        ccListsFactory.getDetailedByListFilter(modalStartKind)
                .then(function success(response) {
                  $scope.fillInnerListFromApiList(response.data);
                  $scope.data.table_api = response.data;
                  $scope.contentLoading = false;
                });
      };

        $scope.getImgName = function(entryName){
            var ret = (entryName || "").replace(".exe", ".png");
            return ret;
        }

      loadList();

    });
