'use strict';
angular.module('ChicoApp.CC-Users', [])
    .config(function($stateProvider) {
      $stateProvider.state('cc-users', {
        url: '/programms',
        templateUrl: 'templates/childcontrol/cc_users/templates/users.html',
        controller: 'CC-Users',
        data: {
          pageTitle: 'Pagetitle',
          subTitle: 'Subtitle ...'
        }
      });
    })
    .run(function() {});
