'use strict';
angular.module('ChicoApp.Login', [])
    .config(function($stateProvider) {
      //declare states

      $stateProvider

          .state('login-no-devices', {
            Authorization: false,
            url: '/login/nodevices',
            controller: 'LoginNoDevicesController',
            templateUrl: 'templates/login/templates/login-no-devices.html'
          })

          .state('devicenotfound', {
              Authorization: false,
              url: '/login/devicenotfound',
              controller: 'DeviceNotFoundController',
              templateUrl: 'templates/login/templates/devicenotfound.html'
          })

          .state('login-logoff-success', {
              Authorization: false,
              url: '/login/logoff-success',
              controller: 'LogoutController',        //no need for special controller here
              templateUrl: 'templates/login/templates/login-logoff-success.html'
          })


          .state('report-notready', {
              Authorization: false,
              url: '/login/report-notready',
              controller: 'LoginController',
              templateUrl: 'templates/login/templates/report-notready.html'
          })



          .state('login', {
            Authorization: false,
            url: '/login/:lang?prefilluser',
            params: {
              lang: '',
              fullpagereload: false
            },
            controller: 'LoginController',
            templateUrl: 'templates/login/templates/login.html',
            //params: {fullpagereload: false}
          })

          .state('loginv2', {
            Authorization: false,
            params: {
              lang: '',
              fullpagereload: false
            },
            url: '/loginv2/:lang?prefilluser',
            controller: 'LoginController',
            templateUrl: 'templates/login/templates/login.html'
          })

          .state('tokenlogin', {
            Authorization: false,
            url: '/tokenlogin/:lang/:logintoken',
            controller: 'LoginController',
            templateUrl: 'templates/login/templates/login.html'
          })

          .state('parentstokenlogin', {
              Authorization: false,
              url: '/parentstokenlogin/:lang/:parentstoken',
              controller: 'LoginController',
              templateUrl: 'templates/login/templates/login.html'
          })

          .state('reporttokenlogin', {
              Authorization: false,
              url: '/mylogin/:lang/:reportlogintoken',
              controller: 'LoginController',
              templateUrl: 'templates/login/templates/login.html'
          })

          .state('devlogin', {
            Authorization: false,
            url: '/devlogin/:username/:password',
            controller: 'DevLoginController',
            templateUrl: 'templates/login/templates/devlogin.html'
          })

          .state('jumplogin', {
            Authorization: false,
            url: '/login/:lang/jmp/?prefilluser&jmpid&deviceid&winuser',
            controller: 'LoginController',
            templateUrl: 'templates/login/templates/login.html'
          })

          .state('pwrequest', {
            Authorization: false,
            url: '/pwrequest/:lang?prefilluser',
            params: {
              lang: ''
            },
            controller: 'PWRequestController',
            templateUrl: 'templates/login/templates/pwrequest.html'
          })

          .state('licenceinfo', {
            Authorization: false,
            url: '/licenceinfo/:lang',
            controller: 'PWRequestController',
            templateUrl: 'templates/login/templates/licenceinfo.html'
          })

          .state('pwchange', {
            Authorization: false,
            url: '/pwchange/:lang/:token',
            controller: 'PWChangeController',
            templateUrl: 'templates/login/templates/pwchange.html'
          });
    })

    .run(function($rootScope, $state, $log, $cookies) {

      $rootScope.$on('$stateChangeStart', function(event, toState, toParams) {
        $log.info('toparams',toParams);

        if ($cookies.get('sessionId') && toState.name == 'login') {

          event.preventDefault();
          // User is logged in and want's to access the login--> redirect to dashboard
          if ($cookies.get('user')) {
            $state.go('cc-dashboard-v3');
          } else {
              if ($cookies.get('cb2')=="true") {
                  $state.go('cb-dashboard'); }
              else {
                  $state.go('cb-dashboard-v3');
              }
          }

        }
      });
    })
;
