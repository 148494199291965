angular.module('ChicoApp').factory('scgSharedFactory', function(
  $http,
  $log,
  $translate,
  $cacheFactory,
  $interval,
  $cookies,
  apiFunctions,
  API,
  FEATURES,
  FEATURESCC,
  moment,
  $q,
  AuthFactory
) {

  var factory = {};

  // TODO: If this variables are the same across the whole app they are constants and should be
  // declared via angular.module('xxx').constant...

  //unused
  // var apifields_security = [
  //     'isDisableCMD',
  //     'isDisableRegedit',
  //     'isDisableControlPanel',
  //     'isDisableUserAccounts',
  //     'isDisableStartRun',
  //     'isDisableStartSearch',
  //     'isDisableTaskmanager',
  //     'isDisableTimeDate',
  //     'isDisableLockStation',
  //     'isDisableSafeBoot',
  //     'isDisableSysRestore',
  //     'isDisableFastUserSwitching',
  //     'DrvLock',
  //     'SecLevel'
  // ];
  var allusers_apifields_security = [
      'isDisableSafeBoot',
      'isDisableSysRestore',
      'isDisableFastUserSwitching'
  ];
  var syncTime;
  var checkChangeCancelPromise = $q.defer();
  AuthFactory.scgSharedFactoryCallbacks.onDeviceChanged = checkChanged;

  //  unused  //  var apifields_security_param = apiFunctions.obj_to_url_params(apifields_security);
  var allusers_apifields_security_param = apiFunctions.obj_to_url_params(allusers_apifields_security);

  factory.jumpId = 0;
  factory.lastLicenceChecked = '';
  factory.licenceReloadDone = false;
  factory.syncDone = false;
  factory.syncDoneCount = 0;
  factory.syncProbShown = false;
  //factory.needsSync = false;
  //factory.isPCOnline = false;

  //Functions
  factory.blockOff = blockOff;
  factory.checkChanged = checkChanged;
  factory.checkHasChangedTimer = checkHasChangedTimer;
  factory.checkPCOnline = checkPCOnline;
  factory.formatDateTime = formatDateTime;
  factory.formatDateTimeShort = formatDateTimeShort;
  factory.formatDate = formatDate;
  factory.formatDateTimeShortAlt = formatDateTimeShortAlt;
  factory.formatExtensionDate = formatExtensionDate;
  factory.formatWeekdayDate = formatWeekdayDate;
  factory.getAllUsersSecuritySettings = getAllUsersSecuritySettings;
  factory.getAllUsersSettingsByParams = getAllUsersSettingsByParams;
  factory.getCCVersionNumber = getCCVersionNumber;
  factory.getServerTimeDiff = getServerTimeDiff;
  factory.getHint = getHint;
  factory.getLastSync = getLastSync;
  factory.getLocalizedDateString = getLocalizedDateString;
  factory.getSecuritySettings = getSecuritySettings;
  factory.getSettingsByParams = getSettingsByParams;
  factory.getTLD = getTLD;
  factory.hasChanged = hasChanged;
  factory.isCBSecurity = isCBSecurity;
  factory.saveSettings = saveSettings;
  factory.setAllUsersSetting = setAllUsersSetting;
  factory.setAllUsersSettings = setAllUsersSettings;
  factory.setHint = setHint;
  factory.setSetting = setSetting;
  factory.setSettings = setSettings;
  factory.syncCBSilent = syncCBSilent;
  factory.syncPC = syncPC;
  factory.logMeCC = logMeCC;
  factory.logMeCB = logMeCB;
  factory.getLogbookTeaser = getLogbookTeaser;
  factory.getLogbookTeaserV2 = getLogbookTeaserV2;
  factory.mastersync = mastersync;
  factory.mastersyncCB = mastersyncCB;
  factory.hasFeature = hasFeature;
  factory.getRoamingDevices = getRoamingDevices;
  factory.getAgesByEntries = getAgesByEntries;
  factory.maxLen = maxLen;
  factory.getImgPrg = getImgPrg;
  factory.getImgApp = getImgApp;
  factory.getImgWeb = getImgWeb;
  factory.hasCBFeature = hasCBFeature;
  factory.hasCCFeature = hasCCFeature;
  factory.formatSecs = formatSecs;
  factory.checkForUpdateNotice = checkForUpdateNotice;
  factory.hourToMin = hourToMin;
  factory.minToHour = minToHour;
  factory.isGerman = isGerman;
  factory.isMobile = isMobile;
  factory.getLicenceDetails = getLicenceDetails;
  factory.getTotalTimeCaption = getTotalTimeCaption;
  factory.getDevicename = getDevicename;
  factory.setDevicename = setDevicename;
  factory.resetDevicename = resetDevicename;

  function getAllUsersSecuritySettings() {
    return factory.getAllUsersSettingsByParams(allusers_apifields_security_param);
  }

  function hasCBFeature(featureId)
  {
      // -----------------------------------------------------
      //Features are defined in constants.js
    // -----------------------------------------------------
    var fromVersion = (FEATURES[featureId] || {}).fromVersion || '0.0.0';

    var deviceVersion = null;
    if(AuthFactory.activeDevice){
        deviceVersion = AuthFactory.activeDevice.versionInfo || $cookies.get('version');
    }else {
        deviceVersion = $cookies.get('version');
    }

    //$log.info('hasCBFeature id=' + featureId + '|deviceVersion=' + deviceVersion + '|fromVersion=' + fromVersion + '|versioninfo=' + versionInfo);

    var fromVersionInt = parseInt(fromVersion.replace(/\./g, ''));
    var deviceVersionInt = parseInt((deviceVersion||'').replace(/\./g, ''));

      // $log.info('hasCBFeature id=' + featureId + '|deviceVersion=' + deviceVersionInt + '|fromVersion=' + fromVersionInt);

    var retVal = false;
    if (fromVersionInt >= 0 && deviceVersionInt > 0 && deviceVersionInt>=fromVersionInt){
        // $log.info('hasCBFeature true');
      retVal = true;
    }
    return retVal;
  }

    function hasCCFeature(featureId,deviceVersion)
    {
        // -----------------------------------------------------
        //Features are defined in constants.js
        // -----------------------------------------------------

        var i = 0;
        if (deviceVersion) {
          deviceVersion.indexOf('|');
        }
        if (i>0) {
          deviceVersion = deviceVersion.substring(0,i);
        }


        var fromVersion = (FEATURESCC[featureId] || {}).fromVersion || '0.0.0.0';
        //var deviceVersion = $cookies.get('version');

        $log.info('hasCCFeature id=' + featureId + '|deviceVersion=' + deviceVersion + '|fromVersion=' + fromVersion);

        var fromVersionInt = parseInt(fromVersion.replace(/\./g, ''));
        var deviceVersionInt = parseInt((deviceVersion||'').replace(/\./g, ''));

        $log.info('hasCCFeature id=' + featureId + '|deviceVersion=' + deviceVersionInt + '|fromVersion=' + fromVersionInt);

        var retVal = false;
        if (fromVersionInt >= 0 && deviceVersionInt > 0 && deviceVersionInt>=fromVersionInt){
            $log.info('hasCCFeature true');
            retVal = true;
        }
        return retVal;
    }



    function getServerTimeDiff() {
    //returns diff in hours to local browsertime
    //return 33;
    var req = {
      method: 'GET',
      url: API.url + 'info/getservertime'
    };
    return $http(req)
        .then(function success(response) {
          $log.info("getservertime arrived:", response);
          var nowServer = moment(response.data);
          var nowBrowser = moment();
          var ms = nowBrowser.diff(nowServer);
          var d = moment.duration(ms);
          var days = d.asDays();
          //var hours = d.asHours();
          var hours = Math.floor(d.asHours()); //to display more than 24 hours!
          $log.info("servertime momentdiff hours=", hours);
          $log.info("servertime momentdiff days=", days);
        return hours;
        })
        .catch(function error(error) {
          $log.error('failed to load getServerTime',error);
          return 0;
        });
  }



  function mastersync() {
    var req = {
      method: 'GET',
      url: API.url + 'ccsync/mastersync'
    };
    return $http(req);
  }


    function mastersyncCB() {
        var req = {
            method: 'GET',
            url: API.url + 'cbsync/mastersync'
        };
        return $http(req);
    }







    function getCCVersionNumber() {
    var req = {
      method: 'GET',
      url: API.url + 'info/ccversionnumber'
    };
    return $http(req)
        .then(function success(response) {
          return response.data;
        })
        .catch(function error(error) {
          $log.error('failed to load CCVersionNumber',error);
          return error;
        });
  }



  function hasFeature(sfeat) {

    var deferred = $q.defer();
    $log.info('scgSharedFactory hasFeature=' + sfeat + '|ActiveContext=' + AuthFactory.activeContext);

    if( sfeat == "devicelimit" && AuthFactory.activeContext == 'cb' ) {
       //get version number first
        getCCVersionNumber().then(function success(version) {
            $log.info('hasFeature CCVersion number is:', version);
            deferred.resolve(true);
            if (version.major >= 16 && version.minor > 2156) {
                $log.info('hasFeature yeah latest!');
            }
        });
      }

        if( sfeat == "roaming2018" && AuthFactory.activeContext == 'cc' ){
            //get version number first
            getCCVersionNumber().then(function success(version) {
                $log.info('hasFeature CCVersion number is:', version);
                deferred.resolve(true);
                if (version.major >= 16 && version.minor > 2600) {
                    $log.info('hasFeature yeah latest!');
                }
            });
        }

    return deferred.promise;

  }


  // TODO: looks like util functions that could be moved to own util module
  function getTLD(url) {
    url = url.replace('http://', '');
    url = url.replace('https://', '');
    url = url.replace('www.','');
    var posi = 0;
    posi = url.indexOf('/');
    if (posi > 0) {
      url = url.substring(0,posi);
    }
    return url;
  }
  // TODO: looks like util functions that could be moved to own util module
  function blockOff() {
    return '0000000000000000000000000000000000000000000000' +
    '00000000000000000000000000000000000000000000000000000' +
    '00000000000000000000000000000000000000000000000000000' +
    '0000000000000000';
  }

  function getAllUsersSettingsByParams(params) {
    var req = {
      method: 'GET',
      url: API.url + 'allusers/ccsettings/' + params
    };
    return $http(req);
  }

  function setAllUsersSetting(obj) {
    var req = {
      method: 'POST',
      url: API.url + 'allusers/ccsettings',
      data: obj
    };
    return $http(req);
  }

  function setAllUsersSettings(keyvalue_arr) {
    var req = {
      method: 'POST',
      url: API.url + 'allusers/ccsettings',
      data: keyvalue_arr
    };
    return $http(req);
  }

  //------------------------ SINGLE USER ---------------------------------------------

  function getSecuritySettings(apifields) {
    var apifields_security_param = apiFunctions.obj_to_url_params(apifields);
    return factory.getSettingsByParams(apifields_security_param);
  }

  function getSettingsByParams(params) {
    var req = {
      method: 'GET',
      url: apiFunctions.get_cc_base_url() + 'ccsettings' + params
    };
    return $http(req);
  }

  function saveSettings(settings_scope) {
    setSettings(settings_scope);
  }

  function getAgesByEntries(entriesArr) {
      var req = {
          method: 'POST',
          url: API.url + 'action/lookupkk',
          data: entriesArr
      };
      return $http(req);
  }

    function maxLen(entry,maxLen) {

      var len=entry.length;
      if (len>maxLen)
      {
        return entry.substring(0,maxLen) + "...";
      }
      else {
        return entry;
      }
    }


    function getImgPrg(exename) {

    //var session = $cookies.get('sessionId');
    //new provide a static string to support caching on the clients for icons!
    var session = "sess887783995a9d401681c6567398227aac";

        if (exename=="checked") {
            return "https://portal.salfeld.net/files/icon/apps/" + session + "/checked.png";
        }

      var exepng = (exename || "").replace(".exe", ".png");
      var fullpath ="https://portal.salfeld.net/files/icon/" + session + "/" + exepng;

      return fullpath;
    }


    function getImgApp(pkgname) {

      //var session = $cookies.get('sessionId');
      //new provide a static string to support caching on the clients for icons!
      var session = "sess887783995a9d401681c6567398227aac";
      if (pkgname=="checked") {
            return "https://portal.salfeld.net/files/icon/apps/" + session + "/checked.png";
      }
      return "https://portal.salfeld.net/files/icon/apps/" + session + "/" + pkgname + ".png";
    }


    function getImgWeb(url) {
        var session = "sess887783995a9d401681c6567398227aac";
        return "https://portal.salfeld.net/files/icon/web/" + session + "/" + url + ".png";
    }

    function setSetting(obj) {
    var req = {
      method: 'POST',
      url: apiFunctions.get_cc_base_url() + 'ccsettings',
      data: obj
    };
    return $http(req);
  }

  function setSettings(keyvalue_arr) {
    var req = {
      method: 'POST',
      url: apiFunctions.get_cc_base_url() + 'ccsettings',
      data: keyvalue_arr
    };
    return $http(req);
  }

  function getHint(hintID) {

    var reqUrl = AuthFactory.activeContext == 'cb' ? API.url + 'cbsettings/hint' + hintID : API.url + 'allusers/ccsettings/hint' + hintID;
    var req = {
        method: 'GET',
        url: reqUrl
      };
    return $http(req);
  }

  function setHint(hintID) {
    var reqUrl = AuthFactory.activeContext == 'cb' ? API.url + 'cbsettings' : API.url + 'allusers/ccsettings';
    var obj = {};
    obj['hint' + hintID] = true;//means: WAS seen
    var req = {
      method: 'POST',
      url: reqUrl,
      data: obj
    };
    return $http(req);
  }

  function getLastSync() {

    //Neu: Zieht LastSync aus AAMAster an, verwendet dabei BrowserOffset für aktuelle Zeit!
    var offset = new Date().getTimezoneOffset() * -1;
    var url = API.url + 'info/getlastsync/' + offset;

    var syncRequest = function() {
      return $http({
        method: 'GET',
        url: url,
        cache: true
      }).then(function success(response) {
        syncTime = Date.now();
        return response.data;
      }).catch(function error(error) {
        $log.error('Failed to sync',error);
      }
      );
    };

    if (syncTime + 1 < Date.now()) {
      //clear cache every second
      var httpCache = $cacheFactory.get('$http');
      httpCache.remove(url);
    }

    return syncRequest();
  }

  function isCBSecurity() {
    var url = API.url + 'service/CBSecurity';
    var req = {
      method: 'GET',
      url: url
    };

    return $http(req)
      .then(function success(response) {
        return response.data;
      })
      .catch(function error(error) {
        $log.error('failed to check CBSecurity',error);
        return error;
      });
  }

  function getLocalizedDateString(input) {
    var apiDate = moment(input).isValid() ? input : '2010-01-01';
    parseInt(moment(apiDate).format('X')) > parseInt(moment('2010-01-01').format('X')) ? apiDate = input : apiDate = '2010-01-01';

    if ($translate.use() == 'de_DE') {
      return moment(apiDate).format('DD.MM.YY');
    }else {
      return moment(apiDate).format('DD. MMM YYYY');
    }
  }

  function formatDateTime(apidate) {
    if (apidate && apidate.length != 19) {
      return apidate;
    }else {
      //return moment(apidate).format('LLL');
        return moment(apidate).format('DD.MM.YY HH:mm');
    }
  }

    function formatDateTimeShort(apidate) {
      if (apidate==null)
      {
        return "???";
      }

      if (apidate && apidate.length != 19) {
            return apidate;
        }else {
            return moment(apidate).format('DD.MMM  HH:mm');
        }
    }


    function formatDateTimeShortAlt(apidate) {
        if (apidate==null)
        {
            return "???";
        }

        if (apidate && apidate.length != 19) {
            return apidate;
        }else {
            return moment(apidate).format('DD.MM.  HH:mm');
        }
    }




    function formatDate(apidate) {


      if (!apidate) {
          //return standard date 01.01.2010
          apidate="2010-01-01";
      }
      if (apidate && apidate.length != 10) {
          return apidate;
    }else {
      var fulldate = moment(apidate).format('LLL');
      //we have to delete hh:mm:ss from fulldate
      var datestring = fulldate.toString();
      var timepart = datestring.substr(datestring.length-8,8);
      datestring = datestring.replace(timepart,'');
      return datestring;

    }
  }

    //converts a localtimestamp (azdatetime) to azdate
    function formatExtensionDate(apidate) {

        if (apidate && apidate.length != 19) {
            return apidate;
        }else {
            return getLocalizedDateString(apidate);
        }
    }

    function formatWeekdayDate(apidate) {

      if (apidate && apidate.length != 19) {
            return '';
        }else {
            var tmpDate = new Date(apidate);
            var tmpDay = tmpDate.getDay();
            if (isNaN(tmpDay)==true) return '';
            var weekday = '';
            if (tmpDay==0) {tmpDay=$translate.instant('TIMES.SHORT_SUN');}
            if (tmpDay==1) {tmpDay=$translate.instant('TIMES.SHORT_MON');}
            if (tmpDay==2) {tmpDay=$translate.instant('TIMES.SHORT_TUE');}
            if (tmpDay==3) {tmpDay=$translate.instant('TIMES.SHORT_WED');}
            if (tmpDay==4) {tmpDay=$translate.instant('TIMES.SHORT_THU');}
            if (tmpDay==5) {tmpDay=$translate.instant('TIMES.SHORT_FRI');}
            if (tmpDay==6) {tmpDay=$translate.instant('TIMES.SHORT_SAT');}
            if (tmpDate.getDay()>=0) {tmpDay=tmpDay+", ";}
            return tmpDay;
        }
    }


  function hasChanged(options) {
    $log.info('hasChanged called, Context is: ', AuthFactory.activeContext);
    var reqUrl = AuthFactory.activeContext == 'cb' ?  API.url + '/cbsettings?key=hasChanged' : API.url + '/allusers/ccsettings?key=hasChanged';
    var req = {
      method: 'GET',
      url: reqUrl,
    };
    if (options && options.timeout) {
      req.timeout = options.timeout;
    }
    return $http(req);
  }

  function syncCBSilent() {
    var req = {
      method: 'GET',
      url: API.url + 'action/SyncNowAsync'
    };
    return $http(req).then(function success(response) {
      if (response.data.success == true) {
        factory.needsSync = false;
        factory.syncDone = true;
      }
    });
  }

  function syncPC(options) {
    var req = {
      method: 'GET',
      timeout: 50000,
      url: API.urlSSE + 'sse/sync'
    };

    if (options && options.timeout) {
      req.timeout = options.timeout;
    }

    return $http(req).then(function success(response) {
                    if (response.data.success == true) {
                      factory.needsSync = false;
                      factory.syncDone = true;
                    }
                  });
  }


  function getLogbookTeaser() {
    var myurl=API.url + 'service/logbook/teaser/' + AuthFactory.activeContext + '/de'; //default
    if ($cookies.get('language')=='en_US') { myurl=API.url + 'service/logbook/teaser/' + AuthFactory.activeContext + '/en';}
    $log.info('getLogbookTeaser url=' + myurl + '|lang=' + $cookies.get('language'));
    var req = {
      method: 'GET',
      url: myurl

    };
    return $http(req);
  }


    function hourToMin(s)
    {

        var posi = s.indexOf(":");
        if (posi==-1)
        {
            return 0;
        }
        var hoursStr = String(s.substring(0,posi));
        var minutesStr = String(s.substring(posi+1,s.length));

        var hours = parseInt(hoursStr);
        var minutes = parseInt(minutesStr);
        var totalsecs = ((hours*60)+minutes)*60;

        return totalsecs;
    }

    function minToHour(num) {
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        var sminutes = rminutes.toString();
        if (sminutes.length==1) sminutes='0' + sminutes;
        var shours = rhours.toString();
        if (shours.length==1) shours='0' + shours;
        return shours + ":" + sminutes;
    }


    function getLogbookTeaserV2() {
        var myurl=API.url + 'service/logbook/teaserv2/' + AuthFactory.activeContext + '/de'; //default
        if ($cookies.get('language')=='en_US') { myurl=API.url + 'service/logbook/teaserv2/' + AuthFactory.activeContext + '/en';}
        $log.info('getLogbookTeaserV2 url=' + myurl + '|lang=' + $cookies.get('language'));
        var req = {
            method: 'GET',
            url: myurl

        };
        return $http(req);
    }


    function checkPCOnline() {
    var req = {
      method: 'GET',
      timeout: 50000,
      //url: API.url + 'sse/isonline'
        //url: API.urlSSE + 'sse/' + $cookies.get('sessionId') + '/' + $cookies.get('pcId') + '/isonline'
        url: API.urlSSE + 'sse/isonline'

    };
    return $http(req).then(function success(response) {
      if (response.data.success === true) {
        factory.isPCOnline = true;
        return true;
      } else {
        factory.isPCOnline = false;
        return false;
      }
    });
  }

   function getRoamingDevices() {
        var req = {
            method: 'GET',
            url: API.url + 'info/getroamingdevices'
        };
        return $http(req);
    }




    function logMeCC(msg)
  {
    var reqUrl = apiFunctions.get_cc_base_url() + 'ccusage/cclogmsg';
    var obj = {};
    obj['msg'] = msg;
    obj['browsertime'] =  moment().format('YYYY-MM-DD HH:mm:ss');
    var req = {
      method: 'POST',
      url: reqUrl,
      data: obj
    };
    return $http(req);

  }

    function logMeCB(entry)
    {
        var reqUrl = apiFunctions.get_cc_base_url() + 'cbusage/cblogmsg';
        var obj = {};
        obj['entry'] = entry;
        obj['browsertimestamp'] =  moment().format('YYYY-MM-DD HH:mm:ss');
        var req = {
            method: 'POST',
            url: reqUrl,
            data: obj
        };
        return $http(req);

    }


  //Enables needSync if "hasChanged"=true for specific device, called from multiple controllers
  //CURRENTLY NOT USED
  function checkChanged() {
    checkChangeCancelPromise.resolve();
    checkChangeCancelPromise = $q.defer();
    hasChanged({timeout: checkChangeCancelPromise}).then(function success(response) {
        $log.info('needsSync true E1 response=', response);
        if (response.data.hasChanged === true) {
        // Timer check changed is true
          $log.info('needsSync true E2');
          factory.needsSync = true;
      } else {
        factory.needsSync = false;
      }
      factory.syncDone = false;
    });
  }



  function checkHasChangedTimer() {

    if( AuthFactory.userRole == "report" ){
      return;
    }
    if (AuthFactory.activeContext === 'cc') {
      checkPCOnline();
    } else {
      factory.isPCOnline = false;
    }

    if (factory.syncDone === true) {
      factory.syncDoneCount++;
      $log.info('TMR SYNCDONECOUNT:' , factory.syncDoneCount);
      if (factory.syncDoneCount >= 3) {
        factory.syncDone = false;
        factory.syncDoneCount = 0;
      }
    }
    var syncCancelPromise = $q.defer();
    if (factory.needsSync === true) {

      hasChanged().then(function success(response) {

        //if (response.data.hasChanged === false || response.data.hasChanged === undefined) {
        if (response.data.hasChanged === false) {
          if (factory.needsSync === true) {
            factory.syncDone = true;
          }
          factory.needsSync = false;
        }
        if (response.data.hasChanged === true) {
            $log.info('needsSync true F');
          factory.needsSync = true;
          factory.syncDone = false;
          //TRY A SYNC IF THE PC IS ONLINE
          if (AuthFactory.activeContext == 'cc' && factory.isPCOnline === true) {
            syncCancelPromise.resolve();
            syncCancelPromise = $q.defer();
            syncPC({timeout: syncCancelPromise});
          }
          if (AuthFactory.activeContext === 'cb') {
            factory.syncDone = true;
            syncCBSilent();
          }

        }
      });
    }

  }



    function formatSecs(secs,shortDisplay) {

        var hours;
        var minutes;

        if (isNaN(secs)) {
            return "---";
        }

        if (secs==0)
        {
          if (shortDisplay) {return "0:00" }
          return "0 min";
        }

        if (secs<60)
        {
            if (shortDisplay) {return "0:00" }
                return "<1 min";
        }

        hours = Math.floor(secs / 3600);
        secs %= 3600;
        minutes = Math.floor(secs / 60);
        //seconds = secs % 60;
        var minString =  $translate.instant('ALL.MINUTES'); //default plural


        if (minutes==1) {
            minString = $translate.instant('ALL.MINUTE'); //singular!
        }

        //MinStr only used for shortDisplay
        var minStr = "";
        if (minutes<10) {minStr="0" + minutes;} else {minStr=minutes}

        if (hours>0) {
            if (minutes>0) {
                if (shortDisplay) {
                  return hours+":"+minStr;
                }
                if (shortDisplay) return "1:00";
                return hours + " h " + minutes + " min";
            } else {
              if (shortDisplay) { return "1:00"}
              return hours + " h ";
            }
        } else {
            if (shortDisplay) { return "0:"+ minStr}
            return minutes + " " + minString;
        }
    }




    function checkForUpdateNotice(thisVersion) {

        if (thisVersion==null || thisVersion.indexOf('|')==-1) {
            return;
        }
        var posi = thisVersion.indexOf('|');
        var version = thisVersion.substring(0,posi);

        var req = {
            method: 'GET',
            url: API.url + 'webapi/cbversion'
        };

        $http(req).success(function(response) {
            var latestVersionInt = parseInt(response.replace(/\./g, ''));
            var deviceVersionInt = parseInt((version||'').replace(/\./g, ''));
            if (latestVersionInt >= 0 && deviceVersionInt > 0 && deviceVersionInt<latestVersionInt) {
                $log.info('checkForUpdateNotice thisVersion=' + deviceVersionInt + '|remote-version=' + latestVersionInt);
                factory.showCB3UpdateNotice = true;
            } else {
                $log.info('checkForUpdateNotice thisVersion=' + deviceVersionInt + '|remote-version=' + latestVersionInt);
                factory.showCB3UpdateNotice = false;
            }
        });
    }


    function isGerman() {
        if ($translate.use() == 'de_DE') { return true;
        } else {
            return false;}
    }


    function isMobile() {
      $log.info('isMobile userAgent=', navigator.userAgent);
      if( navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)
        ){
            return true;
        }
        else {
            return false;
        }
    }

    function getLicenceDetails() {
        var req = {
            method: 'GET',
            timeout: 30000,
            url: API.url + '/licence/licencecheck'
        };
        return $http(req);
    }

    function getDevicename() {
        var req = {
            method: 'GET',
            timeout: 30000,
            url: API.url + '/action/getdevicename'
        };
        return $http(req);
    }

    function setDevicename(devicename) {
        var req = {
            method: 'POST',
            timeout: 30000,
            data: {'name' : devicename},
            url: API.url + '/action/setdevicename'
        };
        return $http(req);
    }

    function resetDevicename() {
        var req = {
            method: 'GET',
            timeout: 30000,
            url: API.url + '/action/resetdevicename'
        };
        return $http(req);
    }



    function getTotalTimeCaption(fromDate,toDate) {
        var s = ''
        if (fromDate!=toDate) s = moment(fromDate, 'YYYY-MM-DD').format('Do. MMM') + ' - ' + moment(toDate, 'YYYY-MM-DD').format('Do. MMM');
        if (fromDate===toDate) s = moment(fromDate, 'YYYY-MM-DD').format('Do. MMM YYYY');
        if (fromDate===toDate && fromDate===moment().add(-2, 'days').format('YYYY-MM-DD')) s=$translate.instant('USAGE.LAST_EREYESTERDAY');
        if (fromDate===toDate && fromDate===moment().add(-1, 'days').format('YYYY-MM-DD')) s=$translate.instant('USAGE.LAST_YESTERDAY');
        if (fromDate===toDate && fromDate===moment().format('YYYY-MM-DD')) s=$translate.instant('USAGE.LAST_TODAY');
        return s;
    }



    $interval(checkHasChangedTimer, 5 * 1000);


  return factory;
});
