'use strict';
angular.module('ChicoApp.CB-V3-Dashboard', [])
    .config(function($stateProvider) {

        $stateProvider.state('cb-dashboard-v3', {
            url: '/cb-dashboard-v3',
            controller: 'CB-DashboardV3Controller',
            templateUrl: 'templates/chicobrowser/cb_dashboard_v3/templates/dashboardv3.html'

        })
    });
