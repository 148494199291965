angular.module('ChicoApp.Shared').directive('durationPicker', function ($window,
                                                                        $document,
                                                                        $log,
                                                                        moment) {

    return {

        templateUrl: 'templates/shared/durationPicker/durationPicker.html',
        scope: {
            timelimit: '=ngModel',
            scgApiType: '@',
            updateOnChange: '@',
            ngDisabled: '=ngDisabled',
            ngMaxValue: '<',
            ngMinValue: '<',
            ngStepValue: '<',
            ngInputDisabled: '<',
            onChange: '&?'
        },

        controller: function ($scope, $element) {

            var regExp_hours = '^[1-9]?[0-9]{1,2}\:[0-9]{2}$';
            var regExp_minutes = '^[1-9]?[0-9]{1,2}$';

            $scope.displayedTimeValue = '00:00'; //will store the displayed value (always sting)
            $scope.scgApiType = $scope.scgApiType || 'string'; //will store the data type in parent scope

            $scope.$watch('timelimit', function (new_val) {

                if ($scope.scgApiType == 'string') {
                    $scope.displayedTimeValue = new_val;
                } else if ($scope.scgApiType == 'number') {
                    $scope.displayedTimeValue = $scope.addLeadingZeroToString(
                        moment.duration(new_val, 'minutes').format('hh:mm')
                    );
                }

            });

            $scope.$watch('ngDisabled', function(newVal) {
                $element.find('button, input')
                    .attr('disabled', newVal);
                if (newVal == true) {
                    $element.find('button, input')
                        .addClass('disabled-text-color');
                }else {
                    $element.find('button, input')
                        .removeClass('disabled-text-color');
                }
            });

            $scope.addLeadingZeroToString = function (str) {
                if (String(str).length == 2) {
                    return '00:' + str;
                } else if (String(str).length == 1) {
                    return '00:0' + str;
                }
                return str;
            };

            $scope.getApiFormattedTime = function (val) {

                var ret = null;

                val = $scope.addLeadingZeroToString(val);

                if ($scope.scgApiType == 'string') {

                    var t1 = val;
                    if ($scope.inputIsHours(val)) {
                        ret = val;
                    } else if (typeof val === 'number' || $scope.inputIsMinutes(val)) {
                        if (moment.duration(val, 'minutes') > 59) {
                            t1 = moment.duration(parseInt(val), 'minutes').format('hh:mm');
                        }
                        ret = $scope.addLeadingZeroToString(t1);
                    }

                } else if ($scope.scgApiType == 'number') {

                    if ($scope.inputIsHours(val)) {
                        ret = moment.duration(val).asMinutes();
                    } else if ($scope.inputIsMinutes(val)) {
                        ret = val;
                    }

                }
                $log.info('durpicker getApiFormattedTime', ret);
                return ret;
            };

            $scope.getDisplayformattedTime = function (val) {

                var ret = '00:00';
                if ($scope.inputIsHours(val)) {
                    ret = val;
                } else if (typeof val === 'number' || $scope.inputIsMinutes(val)) {
                    if (val > 59) {
                        ret = moment.duration(parseInt(val), 'minutes').format('hh:mm');
                    } else if (val > 0) {
                        ret = $scope.addLeadingZeroToString(val);
                    }
                }
                $log.info('durpicker getDisplayformattedTime', ret);
                return ret;
            };

            $scope.setTimeValue = function (newValue) {

                $log.info('durpicker set new time value', newValue);

                var newValDur;

                if($scope.ngMinValue){
                    var minValDur = moment.duration($scope.ngMinValue, 'minutes').asMinutes();
                    newValDur = moment.duration($scope.getDisplayformattedTime(newValue), 'minutes').asMinutes();
                    $log.info('compare min', {
                        newValue: newValue,
                        minValDur: minValDur,
                        newValDur: newValDur
                    })
                    if(minValDur>newValDur){
                        newValue = $scope.ngMinValue
                    }
                }

                if($scope.ngMaxValue){
                    var maxValDur = moment.duration($scope.ngMaxValue, 'minutes').asMinutes();
                    newValDur = moment.duration($scope.getDisplayformattedTime(newValue), 'minutes').asMinutes();
                    $log.info('compare max', {
                        newValue: newValue,
                        maxValDur: maxValDur,
                        newValDur: newValDur
                    })
                    if(maxValDur<newValDur){
                        newValue = $scope.ngMaxValue
                    }
                }

                //format and set inner value
                $scope.displayedTimeValue = $scope.getApiFormattedTime(newValue);

                //format and set display value
                $scope.timelimit = $scope.getDisplayformattedTime(newValue);

                if (typeof $scope.onChange === 'function') {
                    $scope.onChange({timelimit: $scope.timelimit});
                }

                //$scope.$apply();

            };

            $scope.inputIsValid = function (val) {
                var ret = false;
                if ($scope.inputIsHours(val) === true || $scope.inputIsMinutes(val) === true) {
                    ret = true;
                }
                return ret;
            };

            $scope.inputIsHours = function (val) {
                var patt = new RegExp(regExp_hours);
                return patt.test(val);
            };

            $scope.inputIsMinutes = function (val) {
                var patt = new RegExp(regExp_minutes);
                return patt.test(val);
            };

            /*
            * get the inner value in the original data type to return to upper scope
            */
            $scope.getOriginalTypeValue = function () {
                var ret;
                if ($scope.scgApiType == 'number') {
                    ret = moment.duration($scope.displayedTimeValue).asMinutes();
                } else if ($scope.scgApiType == 'string') {
                    ret = moment.duration($scope.displayedTimeValue).format('hh:mm');
                }
                $log.info('durpicker getOriginalTypeValue', ret);
                return ret;
            };

            $scope.getStepValue = function(){
                var step = 15;

                if($scope.ngStepValue){
                    step = moment.duration($scope.ngStepValue).asMinutes();
                }

                return step;
            }

            $scope.lessTime = function () {
                if ($scope.displayedTimeValue == '00:00') {
                    return;
                }

                var reduction = $scope.getStepValue();

                var newTime;
                if(moment.duration($scope.displayedTimeValue).asMinutes() >= reduction){
                    newTime = moment.duration($scope.displayedTimeValue).subtract(reduction, 'm').asMinutes();
                }else {
                    newTime = 0;
                }
                $scope.setTimeValue(newTime);
            };

            $scope.moreTime = function () {
                var addition = $scope.getStepValue();

                var newTime = moment.duration($scope.displayedTimeValue).add(addition, 'm').format('hh:mm');
                $scope.setTimeValue(newTime);
            };

        },

        link: function ($scope, $element) {

            var input = $element.find('input');
            // $log.info('$scope.ngInputDisabled=' + $scope.ngInputDisabled);

            if( $scope.ngInputDisabled && $scope.ngDisabled != true ){
                // $log.info('$scope.ngInputDisabled inside!');
                $element.find('input')
                    .css('cursor', 'not-allowed')
                    .prop('readonly', true)
                    .addClass('scg-input-readonly');
            }else {
                // $log.info('$scope.ngInputDisabled NOT DISABLED inside!');
                input.bind('blur', function () {
                    var inputVal = input.val();
                    if (!$scope.inputIsValid(inputVal)) {
                        $log.info('Input invalid, resetting.');
                        input.val('00:00'); //just reset the input
                        return;
                    }

                    $scope.setTimeValue(inputVal);
                    $scope.$apply();

                });

            }

            if ($scope.updateOnChange) {
                input.bind('change', function () {

                    var inputVal = input.val();

                    if (!$scope.inputIsValid(inputVal)) {
                        $log.info('Input invalid, resetting.');
                        input.val('00:00'); //just reset the input
                        return;
                    }

                    $scope.setTimeValue(inputVal);
                    $scope.$apply();

                });
            }

        }
    };

});

