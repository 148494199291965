angular.module('ChicoApp').factory('cbDashboardFactory', function(
  $http,
  apiFunctions,
  API) {

  var factory = {};

  var apifields = [
      'isTimeActive',
      'infotancount',
      'blockTimesBrowser',
      'isTimeLimitActive',
      'isWeekDayLimitActive',
      'isAppFilterActive',
      'isAppFilterNewBlocked',
      'isWebFilterActive',
      'isNewWebsitesBlocked',
      'webFilterAge',
      'isEmailReportActive',
      'infoLastSync',
      'currentextensionmin',
      'isTimeLimitActiveDevice',
      'isWeekDayLimitActiveDevice',
      'blockTimesDevice',
      'giveExt',
      'isActive',
      'hascb2installed',
      'untilAction',
      'untilDateTime',
      'noExtensionInBlockTime',
      'infoblocktimesdevice',
      'roaming',
      'isHackScreen',
      'isLatestVersion',
      'datetimehack',
      'isForcedBreak',
      'screenshotRightSuccess',
      'neverBlockAlwaysAllowed'
  ];



  var apifields_param = apiFunctions.obj_to_url_params(apifields);


  factory.getCBSettings = function() {
    return factory.getCBSettingsByParams(apifields_param);
  };

  //Wird von o.g. Funktion aufgerufen!
  factory.getCBSettingsByParams = function(params) {
    var req = {
      method: 'GET',
      url: API.url + 'cbsettings/' + params
    };
    return $http(req);
  };

  //is called by watcher from controller
  //---------------------------------------------------------------
  factory.setSetting = function(obj) {
    var req = {
      method: 'POST',
      url: API.url + 'cbsettings/',
      data: obj
    };
    return $http(req);
  };

  factory.getUnlockRequests = function() {
          var req = {
      method: 'GET',
      timeout: 50000,
      url: API.url + 'cbusage/unlockrequests'
    };
    return $http(req);
  };

  factory.getTodayTotal = function(todaystr) {
    var req = {
      method: 'GET',
      url: API.url + 'cbusage/todaytotal/' + todaystr
    };
    return $http(req);
  };

  factory.delExt = function() {
    var req = {
      method: 'GET',
      url: API.url + 'action/delext'
    };
    return $http(req);
  }


  factory.syncNow = function() {
    var req = {
      method: 'GET',
      url: API.url + 'action/SyncNowAsync'
    };
    return $http(req);
  }


  factory.getStatus = function() {
      var req = {
          method: 'GET',
          url: API.url + 'cbsync/status'
      };
      return $http(req);
  }

    factory.getParentsInfo = function(deviceid) {
        var req = {
            method: 'GET',
            url: API.url + 'parentsapp/deviceinfo/'+deviceid
        };
        return $http(req);
    }

    factory.getStatusInfo = function() {
        var req = {
            method: 'GET',
            url: API.url + 'cbsync/statusinfo'
        };
        return $http(req);
    }

    factory.getExtension = function() {
        var req = {
            method: 'GET',
            url: API.url + 'cbsync/allusers/extension'
        };
        return $http(req);
    }

    factory.setExtension = function(extTimeMins) {
        var req = {
            method: 'POST',
            data: '{"extTime":' + extTimeMins + '}',
            url: API.url + 'cbsync/allusers/extension'
        };
        return $http(req);
    }


    factory.sendMsg = function(msg) {
        var mydata = '{"action":"7", "msg":"' + msg + '"}';
        var req = {
            method: 'POST',
            data: mydata,
            url: API.url + 'cbsync/sendfcm'
        };
        return $http(req);
    }


    factory.deleteDevicetimeWarning = function() {
        var req = {
            method: 'DELETE',
            url: API.url + 'cbsync/devicetimewarning'
        };
        return $http(req);
    }


    factory.resetManipulation = function() {
        var req = {
            method: 'GET',
            url: API.url + 'cbsync/resethackscreen'
        };
        return $http(req);
    }


    factory.resetDateHack = function() {
        var req = {
            method: 'GET',
            url: API.url + 'action/cleardatehack'
        };
        return $http(req);
    }

    factory.getTopActivity = function(fromDate, toDate, howMany) {
        var req = {
            method: 'GET',
            url: API.url + 'cbusage/activity/' + fromDate + '/' + toDate + '/' + howMany
        };
        return $http(req);
    }


    factory.getBodyMaxErr= function() {
        var req = {
            method: 'GET',
            url: API.url + 'cbsettings/errbodymax'
        };
        return $http(req);
    }



    return factory;
});
