angular.module('ChicoApp').factory('ccUsageFactory', function(
    $http,
    API,
    $log,
    apiFunctions) {

  var factory = {};

  var apifields_usage = [
      'isUsageDisabled',
      'isWebFilterActive',
      'isPrgFilterActive',
      'hasRoaming',
      'hideUsageAny',
      'hideUsageRuntimePrograms',
      'hideUsageRuntimeWebsites',
      'hideUsageHistoryPrograms',
      'hideUsageHistoryWebsites',
      'hideUsageHistoryBlocked',
      'hideUsageHistorySystem',
      'roaming'
  ];

  var apikeys_usage = apiFunctions.obj_to_url_params(apifields_usage);


   //Get 50 entries per category
  factory.getUsageLong = function(params) {
    var req = {
      method: 'GET',
      timeout: 30000,
      url: apiFunctions.get_cc_base_url() + 'ccusage/' + params + '/50'
    };
    return $http(req);
  };

  factory.getUsageByDates = function(startDate, stopDate, howMany) {
    $log.info('getUsageByDatesURL : ' +  apiFunctions.get_cc_base_url() +  'ccusage/bydatesall/' + startDate + '/' + stopDate + '/' + howMany);
    var req = {
      method: 'GET',
      timeout: 30000,
      url: apiFunctions.get_cc_base_url() +  'ccusage/bydatesall/' + startDate + '/' + stopDate + '/' + howMany
    };
    return $http(req);
  };

   factory.getUsageByHour = function(thisDate, thisHour) {
        var req = {
            method: 'GET',
            timeout: 30000,
            url: apiFunctions.get_cc_base_url() +  'ccusage/byhour/' + thisDate + '/' + thisHour
        };
        return $http(req);
    };

  factory.getRoamingTimes = function(startDate,stopDate,roamingGroup)
  {
      var req = {
          method: 'GET',
          timeout: 30000,
          url: apiFunctions.get_cc_base_url() +  'ccusage/timesroaming/' + startDate + '/' + stopDate + '/' + roamingGroup
      };
      return $http(req);
  }

    factory.getRoamingGroupTimesUsageByDates = function(user, startDate, stopDate) {
        var req = {
            method: 'GET',
            timeout: 30000,
            url: API.url + 'action/roaminggrouptimes/' + user + "/" + startDate + '/' + stopDate
        };
        return $http(req);
    };

    factory.getRoamingGroupTimes = function(user, startDate, stopDate, group) {
        var req = {
            method: 'GET',
            timeout: 30000,
            url: apiFunctions.get_cc_base_url() +  'ccusage/roaminggrouptimes/' + startDate + '/' + stopDate + '/' + group
        };
        return $http(req);
    };

    factory.getGroupDetails = function(startDate, stopDate, group) {
        var req = {
            method: 'GET',
            timeout: 30000,
            url: apiFunctions.get_cc_base_url() +  'ccusage/groupdetails/' + startDate + '/' + stopDate + '/' + group
        };
        return $http(req);
    }

    factory.getDeviceDetails = function(startDate, stopDate) {
        var req = {
            method: 'GET',
            timeout: 30000,
            url: apiFunctions.get_cc_base_url() +  'ccusage/devicedetails/' + startDate + '/' + stopDate
        };
        return $http(req);
    }

  factory.deleteUsage = function()   {
    $log.info('delete usage entered');
    var req = {
      method: 'DELETE',
        timeout: 60000,
      url: apiFunctions.get_cc_base_url() +  'ccusage/delete'
    };
    return $http(req);
  };

  factory.getUnlockReqs = function() {
    $log.info('factory.getUnlockReqs entered');
    var req = {
      method: 'GET',
      timeout: 50000,
      url: API.url + 'ccsync/unlockreq'
    };
    return $http(req);
  };

  factory.deleteUnlockReq = function(_entry) {
    var obj = {entry: _entry};
    $log.info('factory.DeleteUnlockReq entered with obj=' , obj);
    var req = {
      method: 'DELETE',
      imeout: 50000,
      url: API.url + 'ccsync/unlockreq',
      data: obj
    };
    return $http(req);
  };

  factory.doUnlock = function(_username, _entry) {
    var obj = {username: _username,entry: _entry};
    $log.info('factory.doUnlock entered with obj=' , obj);
    var req = {
      method: 'POST',
      timeout: 50000,
      url: apiFunctions.get_cc_base_url() + 'ccusage/unlockreq',
      data: obj
    };
    return $http(req);
  };

  //Get 10 entries per category (this is default call)
  factory.getUsageShort = function(params) {
    var req = {
      method: 'GET',
      timeout: 30000,
      url: apiFunctions.get_cc_base_url() + 'ccusage/' + params + '/10'
    };
    return $http(req);
  };

  factory.getUsageSettings = function() {
    var params = apiFunctions.obj_to_url_params(apifields_usage);
    $log.info('url is:' + apiFunctions.get_cc_base_url() + 'ccsettings/' + params);
    var req = {
      method: 'GET',
        timeout: 30000,
      url: apiFunctions.get_cc_base_url() + 'ccsettings/' + params
    };
    $log.info('return val=' + $http(req));
    return $http(req);
  };

  factory.setUsageSettings = function(keyvalue_arr) {
    var req = {
      method: 'POST',
        timeout: 30000,
      url: apiFunctions.get_cc_base_url() + 'ccsettings',
      data: keyvalue_arr
    };
    return $http(req);
  };


    //Retrieve if some entries have time limits
    factory.getLimitEntriesInfo = function(entriesArr){
        var req = {
            method: 'POST',
            timeout: 30000,
            url: apiFunctions.get_cc_base_url() + 'ccusage/limitentries',
            data: entriesArr
        };
        return $http(req);
    }

    //Retrieve a single limited entry
    factory.getLimitinfo = function(entry) {
        var req = {
            method: 'GET',
            timeout: 30000,
            url: apiFunctions.get_cc_base_url() + 'ccusage/limitinfo/' + entry
        };
        return $http(req);
    }

    //Retrieve a information for a group (10-15)
    factory.getLimitinfoByKind = function(kind) {
        var req = {
            method: 'GET',
            timeout: 30000,
            url: apiFunctions.get_cc_base_url() + 'ccusage/limitinfobykind/' + kind
        };
        return $http(req);
    }



    factory.getWeekStats = function(period) {
        var req = {
            method: 'GET',
            timeout: 30000,
            url: apiFunctions.get_cc_base_url() + 'ccusage/weekstats/' + period
        };
        return $http(req);
    }

    factory.getChartDayApi = function(thisDay) {
        var req = {
            method: 'GET',
            url: apiFunctions.get_cc_base_url() + 'ccusage/chartday/' + thisDay
        };
        return $http(req);
    };

    factory.getChartDayRoamingApi = function(thisDay,roaminggroup) {
        var req = {
            method: 'GET',
            url: apiFunctions.get_cc_base_url() + 'ccusage/chartdayroaming/' + thisDay + '/' + roaminggroup
        };
        return $http(req);
    };

    factory.getChartStatsApi = function(fromDate,toDate) {
        var req = {
            method: 'GET',
            url: apiFunctions.get_cc_base_url() + 'ccusage/chartstats/' + fromDate + '/' + toDate
        };
        return $http(req);
    };

    factory.getChartStatsRoamingApi = function(fromDate,toDate,roaminggroup) {
        var req = {
            method: 'GET',
            url: apiFunctions.get_cc_base_url() + 'ccusage/chartstatsroaming/' + fromDate + '/' + toDate + '/' + roaminggroup
        };
        return $http(req);
    };

   factory.getRoamingMembers = function(roamingGroup) {
       roamingGroup = roamingGroup.replace('F','');
        var req = {
        method: 'GET',
        url: API.url + '/info/getroamingusersforgroup/' + roamingGroup
    };
    return $http(req);
    };

    factory.getAllGroupEntries = function(group) {
        var req = {
            method: 'GET',
            url: apiFunctions.get_cc_base_url() + 'ccusage/getallgroupentries/' + group
        };
        return $http(req);
    };

    factory.getAllRoamingGroupEntries = function(group) {
        var req = {
            method: 'GET',
            url: apiFunctions.get_cc_base_url() + 'ccusage/getallroaminggroupentries/' + group
        };
        return $http(req);
    };

    factory.getTotalTimesDay = function(todayDate) {
        var req = {
            method: 'GET',
            url: apiFunctions.get_cc_base_url() + 'ccusage/totaltimesday/' + todayDate
        };
        return $http(req);
    }

    factory.getTotalTimesPeriod = function(fromDate, toDate) {
        var req = {
            method: 'GET',
            url: apiFunctions.get_cc_base_url() + 'ccusage/totaltimesperiod/' + fromDate + '/' + toDate
        };
        return $http(req);
    }

return factory;

});
