angular.module('ChicoApp').factory('scDashboardFactory', function ($http,
                                                                   API) {
    var factory = {};

    factory.getAppList = function(profileId) {
        var req = {
            method: 'GET',
            url: API.url + 'sc/' + profileId +'/applist'
        };
        return $http(req);
    };

    factory.getProfileNames = function() {
        var req = {
            method: 'GET',
            url: API.url + 'sc/profiles'
        };
        return $http(req);
    }

    factory.setProfileNames = function(obj) {
        var req = {
            method: 'POST',
            url: API.url + 'sc/profiles',
            data:obj
        };
        return $http(req);
    }


    factory.setAppItem = function (payload, profileId) {
        var req = {
            method: 'POST',
            url: API.url + 'sc/' + profileId + '/appitem',
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        };
        return $http(req);
    };


    factory.getSettings = function() {
        var req = {
            method: 'GET',
            url: API.url + 'sc/settings'
        };
        return $http(req);
    }


    factory.setSettings = function (obj) {
        var req = {
            method: 'POST',
            url: API.url + 'sc/settings',
            data: obj
        };
        return $http(req);
    };


    return factory;
});
