'use strict';
angular.module('ChicoApp.Shared').directive('scgUntilModal', function() {
    return {
        restrict: 'E',
        templateUrl: 'templates/shared/globalModals/globalUntilModal/templates/modal-until-content.html',
        controller: function() {

        }
    };
});
