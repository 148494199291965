'use strict';
angular.module('ChicoApp.CC-Usage')

    .controller('CB-Usage-Unblock-V2', function(
        $scope,
        $cookies,
        $state,
        $window,
        $translate,
        $http,
        $log,
        _,
        API,
        moment,
        chicoEnums,
        ccUsageFactory,
        scgSharedFactory,
        modalFactory,
        scgGlobalSpinnerFactory) {

        $scope.data = {};
        $scope.scgSharedFactory = scgSharedFactory;
        $scope.contentLoading = false;


        $scope.reload = function() {
            scgGlobalSpinnerFactory.unblockUI;
            $state.go($state.current, {}, {reload: true});
        };


        $scope.loadList = function() {
            $scope.contentLoading=true;
            $http.get(API.url + 'cbusage/v2/unlockrequests' , {timeout:10000})
                .then(function success(response) {
                    $log.info('v2 http response=', response.data);
                    $scope.data = response.data;
                    $scope.contentLoading=false;
                });
        }


        $scope.deleteItem = function(entry,pkg) {
            $log.info('deleteItem entry=' + entry + '|pkg='+pkg);
            //convert back to string array, bcause we use "old" endpoint
            scgGlobalSpinnerFactory.blockUI;
            var push_arr = [];
            push_arr.push(entry);
            var req = {
                method: 'DELETE',
                data: push_arr,
                url: API.url + 'cbusage/unlockrequests',
            };
            $http(req)
                .then(function success() {
                    $scope.reload();
                }, function error() {
                    $scope.reload();
                });
        }


        $scope.unblockItem = function(entry,pkg) {
            $log.info('unblockItem entry=' + entry + '|pkg='+pkg);
            scgGlobalSpinnerFactory.blockUI;
            var push_arr = [];
            push_arr.push(entry);
            var req = {
                method: 'POST',
                data: push_arr,
                url: API.url + 'cbusage/unlock',
            };
            $http(req)
                .then(function success() {
                    $log.info('Allowed after response');
                    $scope.syncNow();
                    $scope.reload();
                }, function error() {
                    $scope.reload();
                });
        }

        $scope.lookupApp = function(pkg,appname) {
            $state.go('detail-view', {appIds: [appname], appType: chicoEnums.AppTypeEnum.APP, allowAdd: false,prevStateName: $state.current.name});
        }

        $scope.lookupWeb = function(url) {
            $state.go('detail-view', {appIds: [url], appType: chicoEnums.AppTypeEnum.WEBSITE, allowAdd: false,prevStateName: $state.current.name});
        }

        $scope.syncNow = function() {
            var req = {
                method: 'GET',
                url: API.url + 'action/SyncNowAsync'
            };
            return $http(req)
                .then(function success(response) {
                    $log.info('syncNow inside unblock');
                });
        }


        $scope.loadList();


    });
