'use strict';
angular.module('ChicoApp.CC-TimeLimits')
    .controller('CC-TimeLimitsControllerRoaming', function(
        $rootScope,
        $translate,
        $state,
        $window,
        $scope,
        $http,
        $timeout,
        $log,
        moment,
        _,
        AuthFactory,
        ccDashboardFactory,
        ccTimeLimitsFactory,
        scgGlobalSpinnerFactory,
        scgSharedFactory) {

        $scope.data = {};
        $scope.hasNewExtLogic = false;
        $scope.contentLoading = false;

        $log.info('Hi I am roaming controller');

        //redirect if device app version is not high enough
        if( (AuthFactory.activeDevice.versionInfo || "0").split("|")[0].replace(/\./g, "") < 16002253 ){
            $state.go('cc-dashboard-v3');
        }

        scgSharedFactory.getCCVersionNumber().then(function success(version) {
            $log.info('SPECIAL LIMIT CCVersion number is:', version);
            if (version.major >= 16 && version.minor >= 2182) {
                $scope.hasNewExtLogic = true;
            }
        });

        $scope.showRoamingDevices = function() {
            $log.info('show Roaming devices');
            $scope.contentLoading=true;
            scgSharedFactory.getRoamingDevices().
            then(function success(response) {
                $log.info('show roaming response:', response);
                $scope.roamingDevices = response.data;
                $scope.contentLoading=false;
            });
        }

        ccTimeLimitsFactory.getRoaming()
            .then(function success(response) {
                $log.info('roaming response:', response);
                $scope.data = response.data;
                //add watchers
                _.each($scope.data, function(v, k) {
                    $scope.$watch('data.' + k, function(new_val, old_val) {
                        if (new_val != old_val) {
                            var changed_object = {};
                            changed_object[k] = new_val;
                            scgSharedFactory.needsSync = true;
                            ccTimeLimitsFactory.setSetting(changed_object);
                        }
                    }, true);
                });
                });




        window.scrollTo(0,0);

    });
