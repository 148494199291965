'use strict';
angular.module('ChicoApp.CC-Wizard')

    .controller('CC-Wizard-6-Controller', function(
        $rootScope,
        $scope,
        $state,
        $http,
        $timeout,
        $translate) {


        $scope.introtext = $translate.instant('WIZARD.EXTRO');


        $scope.goNext = function() {
            $state.go('cc-wizard.1');
        }

        $scope.goBack = function() {
            $state.go('cc-wizard.5');
        }



    });
