'use strict';
angular.module('ChicoApp.CC-Internet')

.controller('CC-Internet-Blocked-Controller', function(
  $scope,
  $state,
  $translate,
  ccListsFactory,
  DTOptionsBuilder,
  _,
  DTColumnDefBuilder,
  scgDataTableFactory) {

  var modalStartKind = ccListsFactory.listKinds.list_kind_blocked_url;

  $scope.tableHeaders = [$translate.instant('HEADER.DOMAINURLKEYWORD')];

  $scope.contentLoading = true;
  $scope.bonusColumnEnabled = false;


  $scope.data = {};

  scgDataTableFactory.prepareScopeForTable($scope, modalStartKind);
  $scope.dtOptions = scgDataTableFactory.getDefaultDtOptions($scope);

  $scope.dtColumnDefs = scgDataTableFactory.getDefaultDtColumnDefs3Cols();

  $scope.reload = function() {
    $state.go($state.current, {}, {reload: true});
  };

  var loadList = function() {
    $scope.contentLoading = true;
    ccListsFactory.getByListFilter(ccListsFactory.listKinds.list_kind_blocked_url)
            .then(function success(response) {
              $scope.fillInnerListFromApiList(response.data);

            })

            .then(function() {
              ccListsFactory.getDetailedByListFilter(ccListsFactory.listKinds.list_kind_blocked_kw)
                    .then(function success(response) {

                      $scope.fillInnerListFromApiList(response.data);

                      $scope.data.table_inner = _.sortBy($scope.data.table_inner,
                        function(entry) {
                          return entry.data.entry;
                        }
                      );

                      $scope.contentLoading = false;

                    });
            });
  };

  loadList();

});
