/**
 * @typedef {Object} BonusOptions
 * @property {chicoEnums.AppBonusType|chicoEnums.PrgBonusType} bonusType
 * @property {string} bonusInterval     Interval after which a bonus is granted         (only used for bonusType ALWAYS_ALLOWED_WITH_BONUS)
 * @property {string} bonusPerInterval  Amount of time that is granted per interval     (only used for bonusType ALWAYS_ALLOWED_WITH_BONUS)
 * @property {string} maxDailyBonus     Maximum amount of bonus time that is granted    (only used for bonusType ALWAYS_ALLOWED_WITH_BONUS)
 */

angular.module('ChicoApp.Shared').component('detailViewAllowOptions', {
    templateUrl: 'templates/shared/detailView/detailViewAllowOptions/detailViewAllowOptions.component.html',
    controller: AppDetailsAllowedOptionsController,
    bindings: {
        /**
         * If appType is WINDOWS_APP or APP, the current context should be accordingly cc or cb to make sure compatibility checks are correct
         * @type {chicoEnums.AppTypeEnum}
         */
        appType: '<',
        /**
         * @type {BonusOptions} Options that are controlled by this component
         */
        bonus: '<',
        /**
         * @type {function} Invoked if any change was made to the options
         * @param {BonusOptions} bonus New bonus options
         */
        bonusChange: '&?'
    }
});

function AppDetailsAllowedOptionsController(
    scgSharedFactory,
    AuthFactory,
    chicoEnums
) {
    var ctrl = this;

    ctrl.scgSharedFactory = scgSharedFactory;

    // Allows using one enum regardless of the context
    ctrl.ContextBonusTypeEnum = chicoEnums.AppBonusTypeEnum;

    ctrl.$onChanges = function(changes) {
        if (changes.bonus) {
            ctrl.validateBonusSettings();
        }

        if (changes.appType && typeof ctrl.appType !== 'undefined') {
            if (ctrl.appType === chicoEnums.AppTypeEnum.APP
                || (ctrl.appType === chicoEnums.AppTypeEnum.WEBSITE && AuthFactory.activeContext === chicoEnums.ContextEnum.CB)
            ) {
                ctrl.ContextBonusTypeEnum = chicoEnums.AppBonusTypeEnum;
            } else if (ctrl.appType === chicoEnums.AppTypeEnum.WINDOWS_APP
                || (ctrl.appType === chicoEnums.AppTypeEnum.WEBSITE && AuthFactory.activeContext === chicoEnums.ContextEnum.CC)    
            ) {
                ctrl.ContextBonusTypeEnum = chicoEnums.PrgBonusTypeEnum;
            }
        }
     };

    ctrl.onChange = function() {
        ctrl.validateBonusSettings();

        if (typeof ctrl.bonusChange === 'function') {
            ctrl.bonusChange({ bonus: ctrl.bonus });
        }
    };

    /**
     * Validate bonus object
     */
    ctrl.validateBonusSettings = function() {
        // If bonus types are not supported but configured, set bonusType to a default value (to prevent that an invisible setting is configured)
        if (!ctrl.bonusTypesSupported() && ctrl.bonus.bonusType === ctrl.ContextBonusTypeEnum.ALWAYS_ALLOWED_WITH_BONUS) {
            ctrl.bonus.bonusType = ctrl.ContextBonusTypeEnum.NO_BONUS;
        }
    };

    /**
     * @returns {boolean} True if other other bonus types than 0 (none) are supported
     */
    ctrl.bonusTypesSupported = function() {
        // First check if allowed plus is avaliable for websites in CB
        if (scgSharedFactory.hasCBFeature('alwaysAllowedWeb') && ctrl.appType === chicoEnums.AppTypeEnum.WEBSITE && AuthFactory.activeContext === chicoEnums.ContextEnum.CB) {
            return true;
        }
        // Continue with other checks
        if ((!scgSharedFactory.hasCBFeature('bonus') && ctrl.appType === chicoEnums.AppTypeEnum.APP)
            || (ctrl.appType === chicoEnums.AppTypeEnum.WEBSITE && AuthFactory.activeContext === chicoEnums.ContextEnum.CB)
        ) {
            return false;
        } else {
            return true;
        }
    };

    /**
     * @returns {boolean} True if bonusMax is supported
     */
    ctrl.bonusMaxSupported = function() {
        if ((!scgSharedFactory.hasCBFeature('bonusMax') && ctrl.appType === chicoEnums.AppTypeEnum.APP)
                || (ctrl.appType === chicoEnums.AppTypeEnum.WEBSITE && AuthFactory.activeContext === chicoEnums.ContextEnum.CC)
                || ctrl.appType === chicoEnums.AppTypeEnum.WINDOWS_APP
        ) {
            return false;
        } else {
            return true;
        }
    }
}
