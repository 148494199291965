'use strict';
angular.module('ChicoApp')

    .constant('API', {
        'url': 'https://portal.salfeld.net/api/',
        'urlSSE': 'https://sse.salfeld.net/api/',
        'rooturl': 'https://portal.salfeld.net/',
        'filesurl': 'https://files.salfeld.net/',
        'add-dev-headers': true
    })

