'use strict';
angular.module('ChicoApp.CC-Settings')

    .controller('CC-Settings-Exclusion', function(
        $rootScope,
        $scope,
        $translate,
        $state,
        $log,
        $http,
        $timeout,
        DeviceFactory,
        AuthFactory,
        ccListsAllFactory,
        DTOptionsBuilder,
        DTColumnDefBuilder,
        scgDataTableFactory,
        scgSharedFactory) {

      var modalStartKind = 29; //29=exclusions, beachte "allusers"!

      $scope.tableHeaders = [$translate.instant('HEADER.ENTRY')];
      $scope.exclusionName = '';
      $scope.hintHide = true;
      $scope.data = {};
      $scope.data.table_api_urls = [];
      $scope.data.table_api_keywords = [];

      scgDataTableFactory.prepareScopeForTable($scope, modalStartKind);
      $scope.dtOptions = scgDataTableFactory.getDefaultDtOptions($scope);
      $scope.dtColumnDefs = scgDataTableFactory.getDefaultDtColumnDefs3Cols();

      $scope.reload = function() {
        $state.go($state.current, {}, {reload: true});
      };

      var loadList = function() {
        ccListsAllFactory.getByListFilter(modalStartKind)
                .then(function success(response) {
                  $scope.fillInnerListFromApiList(response.data);
                  $scope.data.table_api_urls = response.data;
                });
      };

      loadList();

      $scope.addExclusion = function(myExlusion) {
        var postData = []; //array for the new records we will later post
        var record = {};

        record.kind = 29;
        record.entry = myExlusion;

        postData.push(record);
        $log.info('postData = ', postData);
        scgSharedFactory.needsSync = true;
        ccListsAllFactory.postRecords(postData)
                .then(function success() {
                  //if(cb){cb();}
                  $log.info('Done with postData ', postData);
                  $state.go($state.current, {}, {reload: true});
                  //
                });
      };

      //Hint stuff, Add $scope.hintHide at top of page
      //--------------------------------------------------------------------------------
      scgSharedFactory.getHint(94).                                //adjust hintID here
            then(function success(response) {
              if (response.data.hint94) {                         //adjust hintID here
                $scope.hintHide = response.data.hint94;}        //adjust hintID here
              else {
                //not assgined, set to false!
                $scope.hintHide = false;}
            }
        );
      $scope.hintDone = function() {
        $scope.hintHide = true;
        scgSharedFactory.setHint(94).then(function success() {
          $log.info('HINT DONE SUCCESS');
        }
      );};
      //--------------------------------------------------------------------------------

    }); //eof controller

