angular.module('ChicoApp.Shared').factory('scgGlobalSpinnerFactory', function(
    $http,
    API,
    Metronic
  ) {

  var factory = {};

  factory.isLoading = false;

  factory.blockUI = function() {
    if (factory.isLoading === true) {
      return; //no double loading neccessary
    }

    factory.isLoading = true;

    Metronic.blockUI({animate: true});

  };

  factory.unblockUI = function() {

    Metronic.unblockUI();

    factory.isLoading = false;
  };

  return factory;
});
