/**
 * Data for a header column in the list
 * @typedef {Object} HeaderEntry
 * @property {string}       name            Text in the list header
 * @property {string}       key             Name of the key whose value is displayed in each data row.
 *                                          This key must refer to a member of the objects in listEntries array.
 * @property {?string}      secondKey       Name of a second key whose value is displayed in each data row.
 *                                          This key must refer to a member of the objects in listEntries array.
 *                                          Not visible in XS. (optional)
 * @property {?boolean}     mainColumn      Set to true if the column is the main column (controls default sorting column and where the edit icon is shown if list rows are clickable).
 *                                          Only one main column should exist. Also uses the remaining horizontal space in the table
 * @property {?boolean}     hideXs          Set to true if the column should be hidden in XS viewport (optional, default false)
 * @property {boolean}      allowSorting    Set to true if column may be used to sort the list. If set to false on mainCloumn, no sorting will happen by intially
 * @property {?function}    sortAscFn       Function to sort ascending (optional)
 * @property {?function}    sortDescFn      Function to sort descending (optional)
 */

 /**
  * Transclusion slot:
  *     <filter />: Provides space for a filter element (e.g. a dropdown).
  *                 Funcionality provided using the atttibutes filterFunc and filterParams.
  *                 (optional)
  */
angular.module('ChicoApp.Shared').component('scgAppListV2', {
    templateUrl: 'templates/shared/scgAppList_v2/scgAppListV2.component.html',
    controller: ScgAppListController,
    transclude: {
        appListFilter: '?appListFilter'
    },
    bindings: {
        /**
         * @type {boolean} Set this to true if the intial list entries are loaded.
         * This prevents defaulting to page 1 before any enries are contained in the list.
         */
        initialized: '<',
        /**
         * @type {HeaderEntry[]}
        */
        headerEntries: '<',
        /**
         * @type {Object[]} Rows of the list
         */
        listEntries: '<',
        /**
         * Set to true if an icon should be shown for each entry.
         * If set to true, iconHrefKey must be set.
         * @type {boolean}
         */
        showIcon: '<',
        /**
         * listEntries objects: Name of the key that stores the href to an icon  Key name of a listEntries object whose value is an href to an icon.
         * Only required if showIcon is true.
         * @type {?string}
         */
        iconHrefKey: '@?',
        /**
         * Set to true if a search field should be shown. If set to true, a searchFilterFunc function must be provided.
         * @type {boolean}
         */
        showSearch: '<',
        /**
         * @type {function} Function to filter the current listEntries using the keyword from search (only required if showSearch is true)
         * @param {Object[]} listEntries
         * @param {string} searchInput
         * @returns {Object[]} Filtered listEntries
         */
        searchFilterFunc: '<?',
        /**
         * @type {function} Function to filter the current listEntries using the current filterParams that were set using a filter (optional)
         * @param {Object[]} listEntries
         * @param {Object} filterParams
         * @returns {Object[]} Filtered listEntries
         */
        filterFunc: '<?',
        /**
         * @type {Object} Custom object whose params can be chosen by the filter (only reqiured if filterFunc is set)
         */
        filterParams: '<?',
        /**
         * @type {boolean} Set tot true if kind icons/badges should be shown.
         */
        showKindBadge: '<',
        /**
         * @type {string} listEntries objects: Name of the key that stores the kind of an app/... (only required if showKindBadge is true)
         */
        kindKey: '@?',
        /**
         * @type {string} listEntries objects: Name of the key that stores the bonusType of an app/... (only required if showKindBadge is true)
         */
        bonusTypeKey: '@?',
        /** @type {?ContextEnum} Context (only required if showKindBadge is true and badges dependingf on bonusType should be displayed)
        */
        context: '<?',
        onItemClick: '&?',
        selectedEntries: '=?',
        /**
         * @type {?number} Current page which can be modified. If given page number is too high, the highest possible page will be chosen. (optional)
         */
        page: '<?',
        /**
         * @type {function} Invoked if page was changed (optional)
         * @param {number} newPage New page
         */
        onPageChange: '&?',
        /**
         * @type {boolean} set appType for default/error-icon
         */
        appType: '<'
    }
});

function ScgAppListController(
    _,
    $log,
    scgSharedFactory,
    chicoEnums
) {
    var ctrl = this;

    ctrl.SortDirectionEnum = Object.freeze({
        ASC: 1,
        DESC: 2
    });

    // Options for page select
    ctrl.entriesPerPageOptions = [
        {id: 15, name: '15'},
        {id: 30, name: '30'},
        {id: 50, name: '50'},
        {id: 100, name: '100'}
    ];

    ctrl.scgSharedFactory = scgSharedFactory;
    ctrl.chicoEnums = chicoEnums;
    ctrl.selectAllChecked = false;

    ctrl.checkedIconHref = scgSharedFactory.getImgPrg('checked');
    ctrl.entriesPerPage = {id: 15, name: '15'};
    ctrl.pages = [];
    ctrl.currentPage = 1;

    /**
     * @type {Object[]} Contains sorted/filtered listEntries
     */
    ctrl.filteredEntries = []

    /**
     * @type {Object[]} Contains listEntries for the current page
     */
    ctrl.currentPageEntries = [];

    /**
     * @type {HeaderEntry}
     */
    ctrl.mainColumn = null;

    ctrl.sortHeader = null;
    ctrl.sortDirection = ctrl.SortDirectionEnum.ASC;

    ctrl.searchInput = '';

    ctrl.oldFilterParams = {};

    ctrl.$onChanges = function(changes) {
        // Update list if intial entries were passed
        if (changes.initialized && ctrl.initialized === true) {
            ctrl.updatePaginatedList();
        }

        $log.info('welcome appV2 appType=' + ctrl.appType);
        // Update listEntries (if list is uninitialized, we wait for intialized becoming true instead of updating directly)
        if (changes.listEntries && Array.isArray(ctrl.listEntries) && ctrl.initialized) {
            ctrl.updatePaginatedList();
        }

        if (changes.headerEntries && Array.isArray(ctrl.headerEntries)) {
            // Also search for the current header used for sorting to set a default one if required
            var foundSortHeader = false;

            for (var i = 0; i < ctrl.headerEntries.length; i++) {
                if (ctrl.headerEntries[i].mainColumn === undefined || ctrl.headerEntries[i].mainColumn === null) {
                    ctrl.headerEntries[i].mainColumn = false;
                } else if (ctrl.headerEntries[i].mainColumn) {
                    ctrl.mainColumn = ctrl.headerEntries[i];
                }

                if (ctrl.headerEntries[i].hideXs === undefined) {
                    ctrl.headerEntries[i].hideXs = false;
                }

                if (ctrl.sortHeader !== null && ctrl.headerEntries[i].name === ctrl.sortHeader.name) {
                    foundSortHeader = true;
                }
            }

            // Select default header for sorting if no header is used currently
            if (!ctrl.foundSortHeader && ctrl.mainColumn !== null) {
                ctrl.sortHeader = ctrl.mainColumn;
            }

            ctrl.updatePaginatedList();
        }

        if (changes.page && typeof ctrl.page === 'number') {
            ctrl.setCurrentPage(ctrl.page);
        }
    };

    ctrl.$doCheck = function() {
        // Check for filterParam changes
        // Not the best solution because the object content must be checked in $doCheck instead of $onChanges
        if (typeof ctrl.filterFunc === 'function' && typeof ctrl.filterParams !== 'undefined' && ctrl.filterParams !== null && !angular.equals(ctrl.filterParams, ctrl.oldFilterParams)) {
            ctrl.oldFilterParams = angular.copy(ctrl.filterParams);
            ctrl.updatePaginatedList();
        }
    };

    ctrl.onSelect = function(entry) {
        // Inverted logic (i.e. call selectEntry() if it is already selected) because ng-model changed entry.selected before the call of onSelect()
        if (entry.selected) {
            ctrl.selectEntry(entry);
            ctrl.updateSelectAllChecked();
        } else {
            ctrl.deselectEntry(entry);
            ctrl.selectAllChecked = false;
        }
    };

    ctrl.onSelectAllChanged = function() {
        if(ctrl.selectAllChecked) {
            for (var i = 0; i < ctrl.currentPageEntries.length; i++) {
                ctrl.selectEntry(ctrl.currentPageEntries[i]);
            }
        } else {
            for (var j = 0; j < ctrl.currentPageEntries.length; j++) {
                ctrl.deselectEntry(ctrl.currentPageEntries[j]);
            }
        }
    };

    ctrl.onRowClick = function(row, event) {
        if ($(event.target).closest('.scg-applist .scg-icon-cell').length > 0) {
            var isIconClick = $(event.target).closest('.ngapplist-row-icon').length > 0;

            if (isIconClick) {
                if (!row.selected) {
                    ctrl.selectEntry(row);
                    ctrl.updateSelectAllChecked();
                } else {
                    ctrl.deselectEntry(row);
                    ctrl.selectAllChecked = false;
                }
            }
            // Else ignore click in icon column

        } else if ($(event.target).closest('.scg-applist .scg-checkbox-cell').length > 0) {
            // Ignore
        } else {
            if (typeof ctrl.onItemClick !== 'undefined') {
                ctrl.onItemClick({ row: row });
            }
        }

    };

    ctrl.onSearchChange = function() {
        ctrl.updatePaginatedList();
    };

    ctrl.onSetSorting = function(header) {
        if (header.allowSorting) {
            // Change sort direction or set new sortheader ascending
            if (ctrl.sortHeader.name === header.name) {
                if (ctrl.sortDirection === ctrl.SortDirectionEnum.ASC) {
                    ctrl.sortDirection = ctrl.SortDirectionEnum.DESC
                } else {
                    ctrl.sortDirection = ctrl.SortDirectionEnum.ASC
                }
            } else {
                ctrl.sortHeader = header;
                ctrl.sortDirection = ctrl.SortDirectionEnum.ASC;
            }

            ctrl.updatePaginatedList();
        }
    };

    ctrl.onEntriesPerPageChange = function() {
        ctrl.applyPagination();
    }

    ctrl.updatePaginatedList = function() {
        ctrl.applyFilters();
        ctrl.sortEntries();
        ctrl.applyPagination();
    };

    ctrl.sortEntries = function() {
        if (ctrl.sortHeader !== null && ctrl.sortHeader.allowSorting) {
            if (ctrl.sortDirection === ctrl.SortDirectionEnum.ASC) {
                if (ctrl.sortHeader.hasOwnProperty('sortAscFn') && typeof ctrl.sortHeader.sortAscFn === 'function') {
                    ctrl.filteredEntries = ctrl.filteredEntries.sort(ctrl.sortHeader.sortAscFn)
                } else {
                    ctrl.filteredEntries = ctrl.filteredEntries.sort(function(a, b){
                        return a.entry > b.entry ? 1 : -1;
                    });
                }
            } else {
                if (ctrl.sortHeader.hasOwnProperty('sortDescFn') && typeof ctrl.sortHeader.sortDescFn === 'function') {
                    ctrl.filteredEntries = ctrl.filteredEntries.sort(ctrl.sortHeader.sortDescFn)
                } else {
                    ctrl.filteredEntries = ctrl.filteredEntries.sort(function(a, b){
                        return a.entry < b.entry ? 1 : -1;
                    });
                }
            }
        }
    };

    ctrl.applyFilters = function() {
        var filteredEntries = ctrl.listEntries;

        if (ctrl.showSearch && typeof ctrl.searchFilterFunc === 'function') {
            filteredEntries = ctrl.searchFilterFunc(filteredEntries, ctrl.searchInput);
        }

        if (typeof ctrl.filterFunc === 'function' && typeof ctrl.filterParams !== 'undefined' && ctrl.filterParams !== null) {
            filteredEntries = ctrl.filterFunc(filteredEntries, ctrl.filterParams);
        }

        ctrl.filteredEntries = filteredEntries;

        // Deselect all selected entries that are not included in the filtered entries anymore
        var invisibleEntries = ctrl.selectedEntries.filter(function(entry) {
            return ctrl.filteredEntries.indexOf(entry) < 0;
        });

        for (var i = 0; i < invisibleEntries.length; i++) {
            ctrl.deselectEntry(invisibleEntries[i])
        }
    };

    ctrl.applyPagination = function() {
        // Build array of pages that can be used by the loop in the HTML template
        ctrl.pages = [];
        for (var i = 1; i <= Math.ceil(ctrl.filteredEntries.length / ctrl.entriesPerPage.id); i++) {
            ctrl.pages.push(i);
        }

        // Show at least one page, even if there are no items
        if (ctrl.pages.length === 0) {
            ctrl.pages.push(1);
        }

        // Determine if current page is out of bounds (only reset too high page if list is already initialized)
        if (ctrl.currentPage > ctrl.pages.length && ctrl.initialized) {
            ctrl.currentPage = ctrl.pages.length;

            // Invoke page change event
            if (typeof ctrl.onPageChange === 'function') {
                ctrl.onPageChange({ newPage: ctrl.currentPage });
            }
        } else if (ctrl.currentPage < 1) {
            ctrl.currentPage = 1;
            
            // Invoke page change event
            if (typeof ctrl.onPageChange === 'function') {
                ctrl.onPageChange({ newPage: ctrl.currentPage });
            }
        }
        
        // Fill current page with content
        var startItem = (ctrl.currentPage - 1) * ctrl.entriesPerPage.id;

        if (ctrl.filteredEntries.length > 0) {
            ctrl.currentPageEntries = ctrl.filteredEntries.slice(startItem, startItem + ctrl.entriesPerPage.id);
        } else {
            ctrl.currentPageEntries = [];
        }        
        
        ctrl.updateSelectAllChecked();
    };

    /**
     * Sets current page to given page
     * @param {number} newPage New page
     */
    ctrl.setCurrentPage = function(newPage) {
        ctrl.currentPage = newPage;

        // Only update pagination, no updatePaginatedList() required
        ctrl.applyPagination();

        // Invoke page change event
        if (typeof ctrl.onPageChange === 'function') {
            ctrl.onPageChange({ newPage: ctrl.currentPage });
        }
    };

    ctrl.prevPage = function() {
        if (ctrl.currentPage > 1) {
            ctrl.setCurrentPage(ctrl.currentPage - 1);
        }
    };

    ctrl.nextPage = function() {
        if (ctrl.currentPage < ctrl.pages.length) {
            ctrl.setCurrentPage(ctrl.currentPage + 1);
        }
    };

    /**
     * Updates selectAllChecked depending on whether all entries on the current page are checked
     */
    ctrl.updateSelectAllChecked = function() {
        var selectedEntries = ctrl.currentPageEntries.filter(function(entry) {
            return entry.selected;
        });
        
        ctrl.selectAllChecked = selectedEntries.length === ctrl.currentPageEntries.length && selectedEntries.length > 0
    };

    ctrl.selectEntry = function(entry) {
        if (ctrl.selectedEntries.indexOf(entry) < 0) {
            ctrl.selectedEntries.push(entry);
        }

        entry.selected = true;
    }

    ctrl.deselectEntry = function(entry) {
        var i = ctrl.selectedEntries.indexOf(entry);

        if (i >= 0) {
            ctrl.selectedEntries.splice(i, 1);
        }

        entry.selected = false;
    }
}
