'use strict';
angular.module('ChicoApp.Login')
    .factory('PwChangeFactory', function(
        $injector,
        $rootScope,
        $log,
        moment,
        $translate,
        API) {

      var factory = {};

      $log.info('PwChangeFactory');

      // localhost:58403/api/action/pwchange

      /*
       public class setNewPassword
       {
       public string password { get; set; }
       public string lang { get; set; }
       public string browsertime  { get; set; }
       }
       */

      factory.checkToken = function(token) {
        $log.info('checktoken called');
        var myurl = API.url + 'login/PWChangeTokenExists/' + token;

        return $injector.get('$http').get(myurl);

      };

      factory.setNewPassword = function(token, new_pwd) {
        $log.info('setNewPassword A');

        var myurl = API.url + 'login/PWChange';
        var body = {
          'token': token,
          'newpw': new_pwd,
          'lang': $translate.use(),
          'browsertime': moment().format('YYYY-MM-DD HH:mm:ss')
        };
        return $injector.get('$http').post(myurl, body);
      };

      factory.changePassword = function(new_pwd) {
        $log.info('setNewPassword B');

        var myurl = API.url + 'action/pwchange';
        var body = {
          'password': new_pwd,
          'lang': $translate.use(),
          'browsertime': moment().format('YYYY-MM-DD HH:mm:ss')
        };
        return $injector.get('$http').post(myurl, body);
      };

      return factory;
    });
