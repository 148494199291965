'use strict';
angular.module('ChicoApp.CC-Programs')
    .controller('CC-ProgramsController', function(
        $scope,
        ccProgramsFactory,
        ccListsFactory,
        $log,
        $state,
        _,
        scgGlobalSpinnerFactory,
        scgSharedFactory) {

      $log.info('CC-ProgramsController goes here');
      scgGlobalSpinnerFactory.blockUI();

      $scope.data = {};
      $scope.data.programFilterLevel = 1;
      $scope.hintHide = true;
      $scope.ageStr = '';

      $scope.listCounters = {};
      $scope.groupTotal = 0;

      ccListsFactory.getListCounters().then(function(response) {
        $log.info('list counters', response);
        $scope.listCounters = response.data;
        if (!isNaN(Number($scope.listCounters['11']))) {
          $scope.groupTotal = Number($scope.groupTotal) + Number($scope.listCounters['11']);
        }
        if (!isNaN(Number($scope.listCounters['12']))) {
          $scope.groupTotal = Number($scope.groupTotal) + Number($scope.listCounters['12']);
        }
        if (!isNaN(Number($scope.listCounters['13']))) {
          $scope.groupTotal = Number($scope.groupTotal) + Number($scope.listCounters['13']);
        }
        if (!isNaN(Number($scope.listCounters['14']))) {
          $scope.groupTotal = Number($scope.groupTotal) + Number($scope.listCounters['14']);
        }
        if (!isNaN(Number($scope.listCounters['15']))) {
          $scope.groupTotal = Number($scope.groupTotal) + Number($scope.listCounters['15']);
        }
        if ($scope.groupTotal === 0 | $scope.groupTotal == '0') {
          $scope.groupTotal = '';
        }
        $log.info('group total:' , $scope.groupTotal);
      });

      ccProgramsFactory.getSettings()
            .then(function success(response) {

              $scope.data = response.data;
              $scope.data.programFilterLevel = 2; //default
              if ($scope.data.isPrgFilterNewBlocked == true) {
                $log.info('YES ITS BLOCKED!');
                $scope.data.programFilterLevel = 3;
              }
              if ($scope.data.programFilterAge != '0' && typeof $scope.data.programFilterAge != 'undefined') {
                $scope.ageStr = $scope.data.programFilterAge + '+';
              } else {
                $scope.ageStr = '';
              }
              $log.info('ccProgramsFactory returned data:' , $scope.data);
              $log.info('ageStr', $scope.ageStr);


            //if ($scope.data.programFilterLevel ==3) $state.go('cc-programs.tabs.allowed');

              //add watchers
              _.each($scope.data, function(v, k) {
                $scope.$watch('data.' + k, function(new_val, old_val) {
                  if (new_val != old_val) {
                    var changed_object = {};
                    changed_object[k] = new_val;
                    $scope.data.isPrgFilterNewBlocked = ($scope.data.programFilterLevel == 3);
                    scgSharedFactory.needsSync = true;
                    $log.info('CHANGED, now data is:', $scope.data);
                    ccProgramsFactory.setSetting(changed_object);
                    //if ($scope.data.programFilterLevel ==3) $state.go('cc-programs.tabs.allowed');
                  }
                }, true);
              });
              scgGlobalSpinnerFactory.unblockUI();
            });

      //Hint stuff, Add $scope.hintHide at top of page
      //--------------------------------------------------------------------------------
      scgSharedFactory.getHint(41)
        .then(function success(response) {
              if (response.data.hint41) {                         //adjust hintID here
                $scope.hintHide = response.data.hint41;
              }        //adjust hintID here
              else {
                //not assgined, set to false!
                $scope.hintHide = false;
              }
            }
        );
      $scope.hintDone = function() {
        $scope.hintHide = true;
        scgSharedFactory.setHint(41).then(function success(/*response*/) {
          $log.info('HINT DONE SUCCESS');
        }
      );};
      //--------------------------------------------------------------------------------

    });
