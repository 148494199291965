'use strict';
angular.module('ChicoApp.SB-Settings')

    .controller('SB-Settings-ResetPw', function(
        $scope,
        $state,
        $translate,
        $log,
        scgGlobalSpinnerFactory,
        scgSharedFactory,
        modalFactory,
        PwChangeFactory) {

        $log.info('sb pw reset controller');

        $scope.pw = '';
        $scope.pwrepeat = '';
        $scope.errStyle = 'background-color:white'; //back to default

        $scope.pwChange = function() {
            if ($scope.pw.length > 3 && $scope.pw == $scope.pwrepeat) {
                scgGlobalSpinnerFactory.blockUI();
                PwChangeFactory.changePassword($scope.pw).then(function() {
                    $scope.pw = '';
                    $scope.pwrepeat = '';
                    $scope.errStyle = 'background-color:white'; //back to default
                    scgGlobalSpinnerFactory.unblockUI();
                    $state.go('sb-settings');       //back to sb settings page

                    modalFactory.infoModal.open({
                        modal_settings: {
                            title: $translate.instant('HEADER.INFO'),
                            message: $translate.instant('LOGIN.SUCCESSPWNEW'),
                            butOkLabel: $translate.instant('BUTTON.OK'),
                            butCancelLabel: null,
                            iconNo: 1,
                            refreshStateAfterClose: false
                        },
                        callbacks: {
                            ok: function() {
                                $state.go('sb-settings');
                                $log.info('modal closed with ok -> callback');
                            }
                        }
                    });

                });
            } else {
                //make fields red!
                $scope.errStyle = 'background-color: #EF4836'; //default
            }
        };

    }); //eof controller

