'use strict';
//Dierk: Controller "ChicoApp.CC-Security" muss in die app.js eingetragen werden
//evtl. gulp dev neustarten!
angular.module('ChicoApp.CC-Settings')

    .controller('CC-SettingsController', function() {

    })

    //Dierk: "ccSecurityFactory" ist in diesem Controller required
    /*

    .controller('CC-Settings-Tans', function($scope, CCSettings) {

      $log.info('CC-Settings-TANs');

      $scope.$on('$viewContentLoaded', function() {

      });

    })

    .controller('CC-Settings-Exclusions', function($scope, CCSettings) {

      $log.info('CC-Settings-Exclusions');

      $scope.$on('$viewContentLoaded', function() {

      });

    })*/;

