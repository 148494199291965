angular.module('ChicoApp').component('devComponent', {
    templateUrl: 'templates/dev/dev.component.html',
    controller: DevComponentController
});

function DevComponentController(
    $translate,
    $state,
    $location,
    $anchorScroll,
    $timeout,
    $window,
    $http,
    API,
    _,
    moment,
    $log
) {

    var ctrl = this;



    ctrl.dateStart = '';
    ctrl.dateEnd = '';
    ctrl.showSubCaptions = false;
    ctrl.dateCaption = '???';


    ctrl.$onInit = function () {

        $translate.onReady().then(function() {

            moment.locale('de');
            moment().isoWeekday(1); //TODO Narkus set first weeekday to Monday (not working yet)
            var browsertime = moment().format('DD. MMM YYYY');
            ctrl.intervalDate = browsertime;
            $log.info('dev-translate-ready');
        });
    };

    var browsertime = moment().format('DD. MMM YYYY');
    ctrl.intervalDate = browsertime;

    ctrl.onIntervalClick = function() {
        $log.info('onIntervalClick!');


    }



    ctrl.onStartDateChange = function(newVal, oldVal) {
        ctrl.dateStart = newVal;
    };

    ctrl.onEndDateChange = function(newVal, oldVal) {
        ctrl.dateEnd = newVal;
    };

    ctrl.onDateChange = function() {
        ctrl.showUsage(ctrl.dateStart, ctrl.dateEnd);
    };

    ctrl.onRangeTypeChange = function(newVal, oldVal) {
        
    }


    ctrl.showUsage = function(fromDate, toDate)
    {
        //Todo Markus: fromDate,toDate should be im format yyyy-MM-dd

        $log.info('dev-showUsage from:' + fromDate);
        $log.info('dev-showUsage to:' + toDate);
        ctrl.dateCaption = ctrl.prettyDate(fromDate);
        ctrl.getChartData(fromDate,toDate);
    }


    ctrl.onBarClick = function(detailtext)
    {
        //Todo Markus: pass detailtext (( linkId from json) of bar here

        $log.info('dev-barClick detailtext:' + detailtext);

    }




    ctrl.timeoutTest = function() {
        $log.info('timeOutTest');

        // ... api/dev/delay/1000
        $http.get(API.url + 'dev/delay/500' , {timeout:1000})
            .then(function success(response) {
                $log.info('timeOutTest response=', response.data);
            },
                function error(err) {
                    $log.info('timeOutTest error', err);
                });

    }


    ctrl.prettyDate = function(azDate)
    {
        var momentObj = moment(azDate, 'YYYY-MM-DD').format('Do. MMMM YYYY');
        $log.info('momentObj=', momentObj);
        return momentObj;
        //return momentObj().format('Do MMMM');

    }







    /* ColumnChart */

    ctrl.getDayDataApi = function() {
        var req = {
            method: 'GET',
            url: API.url + 'test/chartview/day'
          };
          return $http(req);
    };

    ctrl.getWeekDataApi = function() {
        var req = {
            method: 'GET',
            url: API.url + 'test/chartview/week'
        };
        return $http(req);
    };



    ctrl.getChartData = function(fromDate,toDate) {

        var req;
        if (fromDate==toDate) {
            ctrl.showSubCaptions = false;
            req = ctrl.getDayDataApi();
        } else {
            ctrl.showSubCaptions = true;
            req = ctrl.getWeekDataApi();
        }

        req.then(function(res) {
            ctrl.chartData = [];
            ctrl.Captions = [];
            ctrl.subCaptions = [];
            for (var i = 0; i < res.data.length; i++) {
                ctrl.chartData.push(res.data[i].percent);
                ctrl.Captions.push(res.data[i].caption);
                ctrl.subCaptions.push(res.data[i].subCaption);
            }
        });
    };

    
    ctrl.colClick = function(colIndex, colData) {
        // Clicked
        $log.info('colClick indx=' + colIndex + ' data: ' + colData);
    };

    ctrl.setVariante = function(value) {
        ctrl.variante = value;
    }



    //ctrl.getDayData();
    ctrl.getChartData('2020-06-09','2020-06-09');


}
