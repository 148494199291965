    angular.module('ChicoApp.Shared').directive('kindSelectDropdown', function (
    $window,
    $document,
    $log,
    $translate,
    $timeout,
    _
) {
    return {

        templateUrl: 'templates/shared/kindSelectDropdown/kindSelectDropdown.html',
        scope: {
            selectedKinds: '=ngModel',
            availableKinds: '=availableKinds',
            editItem: '&',
            renameItem: '&'
        },

        controller: function ($scope, $element) {
            var initialized = false;
            
            $scope.$watch('availableKinds', function (new_val, old_val) {
                //wait for data before initializing once
                if ((initialized == true && new_val == old_val) || !new_val) {
                    return;
                }
                
                initialized = true;

                //wait for translations and stuff before we contiue
                $timeout(function () {

                    $($element).show();

                    /**
                     * Fill available kinds into dom (will later be rendered so this is pre-render dom structure per option)
                     */
                    var kindCounter = 0;
                    var noOfGroups = 5;
                    _.forEach($scope.availableKinds, function (obj, key) {
                        kindCounter++;
                        var selected = obj.selected ? 'selected' : '';
                        if (kindCounter == $scope.availableKinds.length - (noOfGroups-1)) {
                            $element.find('.filter-multi').append('<option data-role="divider"></option>');                            
                        }
                        $element.find('.filter-multi').append('<option value="' + obj.kind + '" ' + selected + '>'+obj.name+'</option>');
                    });

                    $scope.selectedKinds = {};
                    $scope.availableKinds = $scope.availableKinds || defaultKinds;

                    //fill selectedKinds based on availableKinds settings
                    _.forEach($scope.availableKinds, function (obj, key) {
                        $scope.selectedKinds[obj.kind] = obj.selected;
                    });

                    /**
                     * Init Multiselect Style
                     */
                    $element.find('.filter-multi').multiselect({
                        inheritClass: true,
                        nonSelectedText: $translate.instant('CHECK.AN.OPTION'),
                        includeSelectAllOption: true,
                        selectAllText: $translate.instant('DROPDOWN.SELECT_ALL_NONE'),
                        //nSelectedText: ' - Too many options selected!',
                        //allSelectedText: 'No option left ...',
                        buttonText: function (options, select) {
                            var len = _.filter(options, function(a){
                                return a.dataset.role != "divider";
                            }).length;
                            return $translate.instant('DROPDOWN.FILTER') + ": " + len + " " + $translate.instant('DROPDOWN.FROM') + " "+$scope.availableKinds.length; //" " + $translate.instant('DROPDOWN.SELECTED');
                        },
                        templates: {
                            li: '<li><a tabindex="0"><label class="scg-kdd-label"></label><div><div class="content"></div></div></a></li>',
                            divider: '<li class="multiselect-item divider"></li>',
                        },

                        onChange: function (option, checked, select) {
                            var selectedVal = $(option).val();
                            if (selectedVal) {
                                $log.info('select A');
                                $scope.selectedKinds[selectedVal] = checked;
                            }else if(!option && !select){
                                $log.info('select B');
                                _.forEach($scope.selectedKinds, function (obj, key) {
                                    $scope.selectedKinds[key] = checked;
                                }.bind(this));
                            }
                            $log.info('$scope.selectedKinds', $scope.selectedKinds);
                            $scope.$apply();
                        },
                        onDropdownShow: function (event) {
                            var container = $element.find('.multiselect-container > li > a');
                            $(container).each(function (index, el) {
                                if(index==0){
                                    //skip for select all row
                                    return;
                                }
                                var indexWithoutSelectAll = index -1;
                                var editableHtml = '';
                                if($scope.availableKinds[indexWithoutSelectAll]){
                                    $log.info('kindselect index=' + indexWithoutSelectAll + '|editable=', $scope.availableKinds[indexWithoutSelectAll].editable);
                                    if ($scope.availableKinds[indexWithoutSelectAll].editable == true) {
                                        editableHtml = '<div class="text-right scg-kinddd-editicon"><div class="editItem" ng-click="editItem"><i class="fa fa-edit edit-icon"></i></div></div>';
                                    }
                                    $(el).find('div > div').html(editableHtml);
                                    $(el).find('.editItem').on('click', function (ev) {

                                        var parentsUntilLi = $(this).parentsUntil($('ul'));
                                        var thisLiParent = parentsUntilLi[parentsUntilLi.length-1];

                                        var label = $(thisLiParent).find('label');
                                        var checkInput = label.find('input');
                                        var groupName = label.text().substr(4);
                                        var groupNamePrefix = label.text().substr(0,4);
                                        label.text('');
                                        label.append(checkInput);
                                        label.append('<input class="editGroupNameInput" type="text" value="'+groupName+'">');
                                        $('.editGroupNameInput').focus();

                                        $('.editGroupNameInput').on('click', function (ev) {
                                            ev.preventDefault();
                                            ev.stopPropagation();
                                        });

                                        $('.editGroupNameInput').keyup(function(e){
                                            if(e.keyCode == 13){
                                                var kindVal = checkInput.val();
                                                var nameVal = $(e.target).val();
                                                
                                                $scope.renameItem({kind:kindVal, newName:nameVal});
                                                
                                                $('.editGroupNameInput').remove();
                                                label.text(groupNamePrefix + nameVal);
                                                label.prepend(checkInput);
                                            }
                                        })

                                        $('.editGroupNameInput').on('focusout', function(e){
                                            var kindVal = checkInput.val();
                                            var nameVal = $(e.target).val();
                                            
                                            $scope.renameItem({kind:kindVal, newName:nameVal});
                                            
                                            $('.editGroupNameInput').remove();
                                            label.text(groupNamePrefix + nameVal);
                                            label.prepend(checkInput);
                                        })

                                        
/*
                                        $scope.editItem({
                                            item: $scope.availableKinds[indexWithoutSelectAll]
                                        });
                                        */
                                    });
                                    
                                }

                            });
                        }
                    });

                    $('.scg-kdd-label').on('click', function(event){
                        var clickedKind = $(event.target).find('input').val();
                        var isCheckbox = $(event.target).is(':checkbox');

                        if(isCheckbox || clickedKind == 'multiselect-all'){
                            //default logic, do nothing here
                        }else {
                            //prevent further processing
                            event.preventDefault();

                            //unselect all others, select the clicked one
                            _.forEach($scope.availableKinds, function(availableKind, index){

                                var obj = {};
                                var kind = availableKind.kind;
                                var val = $scope.selectedKinds[kind];

                                if(kind==clickedKind){
                                    val = true;
                                }else{
                                    val = false;
                                }
                                $scope.selectedKinds[kind] = val;
                                $scope.$apply();
                            });

                            //manually fix checkbox checked values (not bound in multislect lib)
                            $($element).find(':checkbox').prop('checked', false);
                            $(event.target).find(':checkbox').prop('checked', true);
                        }
                    });

                    var defaultKinds = [{
                        kind: 1,
                        name: 'DEFAULT',
                        selected: false,
                        addCircleClass: 'scg-multiselect-badge-green',
                        circleContent: '<i class="fa fa-binoculars"></i>'
                    }];

                }, 0);

            }, true);

        },

        link: function ($scope, $element) {
                $log.info('kindselect E');
        }
    };
});
