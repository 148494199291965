angular.module('ChicoApp').factory('appListsFactory', function(
    $http,
    API,
    apiFunctions,
    scgSharedFactory) {

    var factory = {};


    /*
    APP ENDPOINTS (supporting kind=0)
    ------------------------------------------------
    */

    // Get the list of all apps
    factory.getAppListCB = function() {
        var req = {
            method: 'GET',
            url: API.url + 'applist/cb'
        };
        return $http(req);
    };

    // Get the list of all apps CC-CONTEXT (user specific)
    factory.getAppListCC = function() {
        var req = {
            method: 'GET',
            url: apiFunctions.get_cc_base_url() + 'applist/cc'
        };
        return $http(req);
    };

    // Get a single app by appname (e.g. "Chrome" *not* com.adroid.chrome)
    factory.getAppCB = function(entry) {
        var req = {
            method: 'GET',
            url: API.url + 'applist/cbitem/' + entry
        };
        return $http(req);
    };

    factory.saveAppsCB = function(apps) {
        var req = {
            method: 'POST',
            url: API.url + 'applist/cb',
            data: apps
        };

        return $http(req).then(function() {
            scgSharedFactory.needsSync = true;
        });
    };


    factory.getAppCC = function(entry) {
        var req = {
            method: 'GET',
            url: apiFunctions.get_cc_base_url() +  'applist/ccitem/' + entry,
        };
        return $http(req);
    };


    factory.saveAppsCC = function(apps) {
        var req = {
            method: 'POST',
            url: apiFunctions.get_cc_base_url() +  'applist/ccitem',
            data: apps
        };

        return $http(req).then(function() {
            scgSharedFactory.needsSync = true;
        });
    };

    factory.deleteAppsCC = function(apps) {
        var req = {
            method: 'DELETE',
            url: apiFunctions.get_cc_base_url() +  'applist/ccitem',
            data: apps
        };

        return $http(req).then(function() {
            scgSharedFactory.needsSync = true;
        });
    };



    /*
        DOMAIN ENDPOINTS
        ------------------------------------------------
     */
    // Get list of all websites CB
    factory.getWebListCB = function() {
        var req = {
            method: 'GET',
            url: API.url + 'weblist/'
        };
        return $http(req);
    };

    // Get list of all websites CC
    factory.getWebListCC = function() {
        var req = {
            method: 'GET',
            url:  apiFunctions.get_cc_base_url() +  'weblist/',
        };
        return $http(req);
    };

    // Get a single website details
    factory.getWebCB = function(domain) {
        var req = {
            method: 'GET',
            url: API.url + 'weblist/cbitem/' + domain
        };
        return $http(req);
    };

    // Get a single website details
    factory.getWebCC = function(domain) {
        var req = {
            method: 'GET',
            url: apiFunctions.get_cc_base_url()  + 'weblist/' + domain
        };
        return $http(req);
    };

    factory.saveWebCB = function(apps) {
        var req = {
            method: 'POST',
            url: API.url + 'weblist',
            data: apps
        };

        return $http(req).then(function() {
            scgSharedFactory.needsSync = true;
        });
    };

    factory.saveWebCC = function(apps) {
        var req = {
            method: 'POST',
            url: apiFunctions.get_cc_base_url() + 'weblist',
            data: apps
        };

        return $http(req).then(function() {
            scgSharedFactory.needsSync = true;
        });
    };


    factory.deleteWebCB = function(appsAsStringArray) {
        var req = {
            method: 'DELETE',
            url: API.url + 'weblist',
            data: appsAsStringArray
        };

        return $http(req).then(function() {
            scgSharedFactory.needsSync = true;
        });
    };


    factory.deleteWebCC = function(appsAsStringArray) {
        var req = {
            method: 'DELETE',
            url: apiFunctions.get_cc_base_url()  + 'weblist',
            data: appsAsStringArray
        };

        return $http(req).then(function() {
            scgSharedFactory.needsSync = true;
        });
    };



    /*
        KEYWWORD ENDPOINTS *CB*
        ------------------------------------------------
     */

    factory.getWebKeywordsCB = function() {
        var req = {
            method: 'GET',
            url: API.url + 'weblist/keywords'
        };
        return $http(req);
    };


    factory.saveWebKeywordsCB = function(keywords) {
        var req = {
            method: 'POST',
            url: API.url + 'weblist/keywords',
            data: keywords
        };

        return $http(req).then(function() {
            scgSharedFactory.needsSync = true;
        });
    };

    factory.deleteWebKeywordsCB = function(keywords) {
        var req = {
            method: 'DELETE',
            url: API.url + 'weblist/keywords',
            data: keywords
        };

        return $http(req).then(function() {
            scgSharedFactory.needsSync = true;
        });
    };


    /*
          KEYWWORD ENDPOINTS *CC* CHILD CONTROL
          ------------------------------------------------
       */

    factory.getWebKeywordsCC = function() {
        var req = {
            method: 'GET',
            url: apiFunctions.get_cc_base_url() + 'weblist/keywords'
        };
        return $http(req);
    };


    factory.saveWebKeywordsCC = function(keywords) {
        var req = {
            method: 'POST',
            url: apiFunctions.get_cc_base_url() +  'weblist/keywords',
            data: keywords
        };

        return $http(req).then(function() {
            scgSharedFactory.needsSync = true;
        });
    };

    factory.deleteWebKeywordsCC = function(keywords) {
        var req = {
            method: 'DELETE',
            url: apiFunctions.get_cc_base_url() + 'weblist/keywords',
            data: keywords
        };

        return $http(req).then(function() {
            scgSharedFactory.needsSync = true;
        });
    };



    /*
     * ONLY DOKU
     */
    factory.putRecords = function(key_arr, data) {
        //key array like ["one", "two", "three"]
        //data as object {one:a, two:b}
        scgSharedFactory.needsSync = true;
        var paramlist = apiFunctions.obj_to_url_params(key_arr, 'entry');
        var req = {
            method: 'POST',
            url: API.url + 'cblists/' + paramlist,
            data: [data]
        };
        return $http(req);
    };


    return factory;
});
