'use strict';
angular.module('ChicoApp').directive('scgExtensionModal', function() {
    return {
        restrict: 'E',
        templateUrl: 'templates/childcontrol/cc_settings/templates/modal-extension-content.html',
        controller: function(/*$scope, $element, $attrs*/) {
            //nothing special
          }
      };
  });

