angular.module('ChicoApp').factory('ccProgramsByAgeFactory', function(
    $http,
    apiFunctions) {

  var factory = {};

  var apifields = [
      'programFilterAge',
      'programFilterAgeNotifyOpt'
  ];

  var apifields_param = apiFunctions.obj_to_url_params(apifields);

  factory.getSettings = function() {
    var req = {
      method: 'GET',
      url: apiFunctions.get_cc_base_url() + 'ccsettings' + apifields_param
    };
    return $http(req);
  };

  factory.setSetting = function(obj) {
    var req = {
      method: 'POST',
      url: apiFunctions.get_cc_base_url() + 'ccsettings',
      data: obj
    };
    return $http(req);
  };

  return factory;
});
