'use strict';
angular.module('ChicoApp.CC-Settings')

    .controller('SCG-Modal-Export-Controller', function(
      $scope,
      $modalInstance,
      $log,
      modalCallbacks
      ) {

      $log.info('SCG-Modal-Export has just been opened, controller init running now');

      $scope.exportFile = 'export.ccf';       //default
      //if (angular.isDefined($cookies.user)) $scope.exportFile = "export-" + $cookies.user.toLowerCase() + ".ccf";

      $scope.cancelModal = function() {
        $modalInstance.dismiss('cancel');
      };

      $scope.exportFilename = function() {
        modalCallbacks.onClose($scope.exportFile);
        $modalInstance.dismiss('cancel');
      };

    });
