angular.module('ChicoApp').factory('ccListsFactory', function(
  $http,
  $log,
  API,
  apiFunctions) {

  var factory = {};

  //2     Pprograms Timelimited
  //3     Programs Blocked

  // 4    Static Group System
  // 10   Static Group Internet

  // 6    Internet Blocked Keywords
  // 5    Internet Blocked URLs
  // 7    Internet Allowed
  // 8    Internet Time Limited

  // 11   Gruppe 1 (Programme und Internet Einträge möglich)
  // 12   Gruppe 2 ( --''--)
  // 13   Gruppe 3 ( --''--)
  // 14   Gruppe 4 ( --''--)
  // 15   Gruppe 5 ( --''--)

  // 25   Bonus Internet (neu als eigener kind)
  // 26   Bonus Programme (neu als eigener kind)

  factory.listKinds = {
    list_kind_allowed_prg: 1,
    list_kind_timelimited_prg: 2,
    list_kind_blocked_prg: 3,
    list_kind_bonus_prg: 26,

    list_kind_blocked_url: 5,
    list_kind_blocked_kw: 6,
    list_kind_allowed_kw: 7,
    list_kind_timelimited: 8,
    list_kind_group_winapps: 9,
    list_kind_bonus_internet: 25,

    list_kind_group_internet: 10,
    list_kind_group_system: 4,

    list_kind_custom_group_1: 11,
    list_kind_custom_group_2: 12,
    list_kind_custom_group_3: 13,
    list_kind_custom_group_4: 14,
    list_kind_custom_group_5: 15,
  };

  factory.getGroupDefaults = function(groupid) {
    var req = {
      method: 'GET',
      url: apiFunctions.get_cc_base_url() + 'cclists/groupdefaults/' + groupid
    };
    return $http(req);
  };

  factory.getListCounters = function() {
    var req = {
      method: 'GET',
      url: apiFunctions.get_cc_base_url() + 'cclists/counters'
    };
    return $http(req);
  };

  factory.getByListFilter = function(filter_value) {
    var req = {
      method: 'GET',
      url: apiFunctions.get_cc_base_url() + 'cclists/?kind=' + filter_value
    };
    return $http(req);
  };

    factory.getPrgList = function() {
        var req = {
            method: 'GET',
            url: apiFunctions.get_cc_base_url() + 'cclists/prglist'
        };
        return $http(req);
    };

   // V2 return also kind = 0
  factory.getPrgListV2 = function() {
    var req = {
      method: 'GET',
      url: apiFunctions.get_cc_base_url() + 'cclists/prglistv2'
    };
    return $http(req);
  };


    factory.getByListFilterAllUsers = function(filter_value) {
    $log.info('getByListFilterAllUsers URL=' + API.url);
    var req = {
      method: 'GET',
      url: API.url + 'allusers/cclists/?kind=' + filter_value
    };
    return $http(req);
  };

  factory.getDetailedByListFilter = function(filter_value) {
    var req = {
      method: 'GET',
      url: apiFunctions.get_cc_base_url() + 'cclists/detaillist/?kind=' + filter_value
    };
    return $http(req);
  };

  factory.getByListFilter = function(filter_value) {
    var req = {
      method: 'GET',
      url: apiFunctions.get_cc_base_url() + 'cclists/?kind=' + filter_value
    };
    return $http(req);
  };

  factory.getByObjectKey = function(key) {
    var req = {
      method: 'GET',
      url: apiFunctions.get_cc_base_url() + 'cclists/' + key
    };
    return $http(req);
  };

  /*
  * Save single record
  */
  factory.putRecord = function(key, data) {
    var req = {
      method: 'POST',
      url: apiFunctions.get_cc_base_url() + 'cclists/' + key,
      data: data
    };
    return $http(req);
  };

  /*
  * Save multiple records
  */
  factory.putRecords = function(key_arr, data) {
    //key array like ["one", "two", "three"]
    //data as object {one:a, two:b}
    var paramlist = apiFunctions.obj_to_url_params(key_arr, 'entry');
    var req = {
      method: 'POST',
      url: apiFunctions.get_cc_base_url() + 'cclists/' + paramlist,
      data: [data]
    };
    return $http(req);
  };

  /*
  * Create one or more records
  */
  factory.postRecords = function(data) {
    var req = {
      method: 'POST',
      url: apiFunctions.get_cc_base_url() + 'cclists',
      data: data
    };
    return $http(req);
  };

  /*
  * Delete one or more records
  */
  //OLD APPROACH USING URL
  /*factory.deleteRecords = function(key_arr) {
    var paramlist = apiFunctions.obj_to_url_params(key_arr, 'entry');
    var req = {
      method: 'DELETE',
      url: apiFunctions.get_cc_base_url() + 'cclists/' + paramlist
    };
    return $http(req);
  };*/


  //NEW APPROACH USING BODY AS PAYLOAD
  factory.deleteRecords = function(key_arr) {
    var paramlist = apiFunctions.obj_to_url_params(key_arr, 'entry');
    var req = {
      method: 'DELETE',
      url: apiFunctions.get_cc_base_url() + 'cclists/deletemulti',
      data:key_arr
    };
    return $http(req);
  };

  factory.deleteRecord = function(key) {
    return factory.deleteRecords([key]);
  };

  return factory;
});
