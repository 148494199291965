'use strict';
angular.module('ChicoApp.SB-Usage')

    .controller('SB-UsageController', function ($rootScope,
                                                    $scope,
                                                    $translate,
                                                    $state,
                                                    $modal,
                                                    $http,
                                                    $window,
                                                    moment,
                                                    API,
                                                    modalFactory,
                                                    scgGlobalSpinnerFactory,
                                                    scgSharedFactory,
                                                    sbUsageFactory,
                                                    $log,
                                                    DeviceFactory,
                                                    Metronic,
                                                    _
    ) {


        Metronic.unblockUI();
        $scope.data = {};
        $scope.unlockData = {};

        $scope.customStartDate = '2010-01-01';
        $scope.customStopDate = '2012-01-01';
        $scope.perioddropdown = '1';

        $scope.historyUrl = {};
        $scope.totalHistoryUrl = 0;
        $scope.totalUnlockUrl = 0;

        $scope.contentLoading = false;
        $scope.unlockLoading = true;

        $scope.getStartDate = function() {
            var date = new Date();
            var myyear = date.getFullYear();
            var mymonth = date.getMonth() + 1;
            mymonth = mymonth < 10 ? '0' + mymonth : mymonth;
            var myday = date.getDate();
            myday = myday < 10 ? '0' + myday : myday;
            return myyear + '-' + mymonth + '-' + myday;
        };

        $scope.getStopDate = function() {
            var date = new Date();
            var myyear = date.getFullYear();
            var mymonth = date.getMonth() + 1;
            mymonth = mymonth < 10 ? '0' + mymonth : mymonth;
            var myday = date.getDate();
            myday = myday < 10 ? '0' + myday : myday;
            return myyear + '-' + mymonth + '-' + myday;
        };

        $scope.customStartDate = $scope.getStartDate();
        $scope.customStopDate = $scope.getStopDate();


        $scope.calculateStartStopDates = function() {
            //launch custom dates
            //----------------------------------------------------------------
            if ($scope.perioddropdown === '7') {
                $log.info('CUSTOMDATE - Start:' + $scope.customStartDate);
                $log.info('CUSTOMDATE - Stop:' + $scope.customStopDate);
                return;
            }

            //calculate the date, launch intervals from dropdown
            //----------------------------------------------------------------
            var someDate = new Date();
            if ($scope.perioddropdown === '1') {
                someDate.setDate(someDate.getDate()); //today
            }
            if ($scope.perioddropdown === '2') {
                someDate.setDate(someDate.getDate() - 1); //yesterday
            }
            if ($scope.perioddropdown === '3') {
                someDate.setDate(someDate.getDate() - 6);
            }
            if ($scope.perioddropdown === '4') {
                someDate.setDate(someDate.getDate() - 30);
            }
            if ($scope.perioddropdown === '5') {
                someDate = $scope.startOfWeek();
            }

            //Format the bullshit date
            var dd = someDate.getDate();
            if (dd <= 9) {
                dd = '0' + dd;
            }
            if ($scope.perioddropdown === '6') {
                dd = '01';     //start of month
            }
            var mm = someDate.getMonth() + 1;
            if (mm <= 9) {
                mm = '0' + mm;
            }
            var y = someDate.getFullYear();
            $scope.someFormattedDate = y + '-' + mm + '-' + dd;
            $scope.customStartDate = $scope.someFormattedDate;

            if ($scope.perioddropdown === '1' || $scope.perioddropdown === '2') {
                $log.info('createUsage Period=1 OR 2     stopdate=' + $scope.customStopDate);
                $scope.customStopDate = $scope.customStartDate; //only if yesterday
            }

            //Override start for this week using moment
            if ($scope.perioddropdown === '5') {
                $scope.customStartDate = moment().startOf('isoweek').format('YYYY-MM-DD');
            }

            $log.info('createUsage -> perioddropdown=' + $scope.perioddropdown);
            $log.info('createUsage -> startDate=' + $scope.customStartDate);
            $log.info('createUsage -> stopDate=' + $scope.customStopDate);
            $log.info('createUsage -> howMany=' + $scope.howMany);
        }



        $scope.createUsage = function(period) {
            $scope.loadUnlock(); //load also the unlock requests
            $scope.calculateStartStopDates();
            $scope.contentLoading=true;
            sbUsageFactory.getUsageByDates($scope.customStartDate,$scope.customStopDate)
                .then(function success(response) {
                    $scope.data = response.data;
                    $scope.totalHistoryUrl =response.data.length;
                    $log.info('createUsage respose=', $scope.data);
                    $log.info('createUsage totalHistoryUrl=', $scope.totalHistoryUrl);
                    $scope.contentLoading=false;
                });
        }

        $scope.deleteUsage = function() {
            $log.info('delete goes here');
            modalFactory.infoModal.open({
                modal_settings: {
                    title: $translate.instant('MSG.QUESTION'),
                    message: $translate.instant('MSG.SUREDELETE'),
                    butOkLabel: $translate.instant('BUTTON.YES'),
                    butCancelLabel: $translate.instant('BUTTON.NO'),
                    iconNo: 2,
                    refreshStateAfterClose: true
                },
                callbacks: {
                    ok: function() {
                        $log.info('DELPROC yes, ok was click, he/she is sure');
                        $scope.data = {};
                        scgGlobalSpinnerFactory.blockUI();
                        sbUsageFactory.deleteUsage()
                            .then(function success(response) {
                                $log.info('DELPROC HAS DATA 200, response.data=' + response.data);
                                modalFactory.okModal(1, $translate.instant('MSG.DELETEDONE'));
                                Metronic.unblockUI();
                            }, function error() {
                                Metronic.unblockUI();
                                $log.info('ERROR OCCURED');
                                modalFactory.okModal(1, $translate.instant('MSG.ERROR')  + ' ' + $translate.instant('MSG.ERRORHINT2'));
                            });

                    },
                },
                cancel: function() {
                    $log.info('canceled!');
                }
            });
        };


    $scope.loadUnlock = function() {
        $scope.unlockLoading=true;
        $scope.unlockData = {};
            $http.get(API.url + 'cbusage/v2/unlockrequests' , {timeout:10000})
            .then(function success(response) {
                $log.info('v2 http response=', response.data);
                $scope.unlockData = response.data;
                $scope.totalUnlockUrl =response.data.length;
                $scope.unlockLoading=false;
            });
        }

    $scope.deleteItem = function(entry,pkg) {
        $log.info('deleteItem entry=' + entry + '|pkg='+pkg);
        //convert back to string array, bcause we use "old" endpoint
        scgGlobalSpinnerFactory.blockUI;
        var push_arr = [];
        push_arr.push(entry);
        var req = {
            method: 'DELETE',
            data: push_arr,
            url: API.url + 'cbusage/unlockrequests',
        };
        $http(req)
            .then(function success() {
                $scope.loadUnlock();
            }, function error() {
                $scope.loadUnlock();
            });
    }



    $scope.unblockItem = function(entry,pkg) {
        $log.info('unblockItem entry=' + entry + '|pkg='+pkg);
        scgGlobalSpinnerFactory.blockUI;
        var push_arr = [];
        push_arr.push(entry);
        var req = {
            method: 'POST',
            data: push_arr,
            url: API.url + 'cbusage/unlock',
        };
        $http(req)
            .then(function success() {
                $scope.loadUnlock();
            }, function error() {
                $scope.loadUnlock();
            });
    }




        //by default fire for period 1 (=today)
    $scope.createUsage();



    }); //eof controller