angular.module('ChicoApp').factory('ccDashboardFactoryV3', function(
    $http,
    apiFunctions,
    $log,
    API) {

    var factory = {};
    var mydata = {};

    var apifields = [
        'isTimeActive',
        'isTimeLimitActive',
        'isWeekDayLimitActive',
        'blockTimesPC',
        'isforcedbreak',
        'isAppFilterActive',
        'isWebFilterActive',
        'isNewWebsitesBlocked',
        'webFilterAge',
        'isPrgFilterActive',
        'isPrgFilterNewBlocked',
        'programFilterAge',
        'isEmailReportActive',
        'onetimelocklevel',
        'lockdate',
        'unlockdate',
        'hasunlockreq',
        'screenshotactive',
        'isEmailReportActive',
        'infotancount',
        'roaming',
        'isLatestVersion',
        'datetimehack',
        'neverBlockAlwaysAllowed'
    ];

    var apifields_param = apiFunctions.obj_to_url_params(apifields);

    factory.getCCSettings = function() {
        return factory.getCCSettingsByParams(apifields_param);
    };

    //Wird von o.g. Funktion aufgerufen!
    factory.getCCSettingsByParams = function(params) {
        var req = {
            method: 'GET',
            url: apiFunctions.get_cc_base_url() + 'ccsettings' + params
        };
        return $http(req);
    };

    //is called by watcher from controller
    //---------------------------------------------------------------
    factory.setSetting = function(obj) {
        var req = {
            method: 'POST',
            url: API.url + 'cbsettings/',
            data: obj
        };
        return $http(req);
    };

    factory.getUnlockRequests = function() {
        var req = {
            method: 'GET',
            timeout: 50000,
            url: API.url + 'ccusage/unlockrequests'
        };
        return $http(req);
    };

    factory.getSecureBoot = function() {
        var req = {
            method: 'GET',
            url: API.url + 'allusers/ccsettings/secureboot'
        };
        return $http(req);
    };

    factory.getCurrentExtension = function() {
        var req = {
            method: 'GET',
            url: apiFunctions.get_cc_base_url() + 'ccsettings/extension'
        };
        return $http(req);
    };

    factory.getTodayTotal = function(todaystr) {
        $log.info('GetTodayTotal RUL=' + apiFunctions.get_cc_base_url() + 'ccusage/todaytotal/' + todaystr);
        var req = {
            method: 'GET',
            url:   apiFunctions.get_cc_base_url() + 'ccusage/todaytotal/' + todaystr
        };
        return $http(req);
    };

    factory.setExtensionTime = function(extTime) {
        mydata.msg = extTime;
        var req = {
            method: 'POST',
            url: apiFunctions.get_cc_base_url() + 'ccsettings/setextension',
            data: mydata
        };
        return $http(req);
    };

    factory.setOneTimeLockLevel = function(obj) {
        $log.info('TURN OFF HTTP: OBJ=', obj);
        var req = {
            method: 'POST',
            url: apiFunctions.get_cc_base_url() + 'ccsettings',
            data: obj
        };
        return $http(req);
    };

    factory.getStatusInfo = function() {
        var req = {
            method: 'GET',
            url: apiFunctions.get_cc_base_url() + 'ccusage/statusinfo'
        };
        return $http(req);
    };

    factory.delayTest = function(ms) {
        var req = {
            method: 'GET',
            timeout:2500,
            url: API.url + 'dev/delay/' + ms
        };
        return $http(req);
    };

    factory.resetDateHack = function() {
        var req = {
            method: 'GET',
            url: API.url + 'action/cleardatehack'
        };
        return $http(req);
    }

    factory.getTopActivity = function(fromDate, toDate, howMany) {
        var req = {
            method: 'GET',
            url: apiFunctions.get_cc_base_url()  + 'ccusage/activity/' + fromDate + '/' + toDate + '/' + howMany
        };
        return $http(req);
    }

    return factory;
});
