/**
 * OLD
 * @typedef {Object} App
 * @property {Object} data          App info
 * @property {string} data.iconHref URL to app icon
 */

angular.module('ChicoApp.Shared').component('detailView', {
    templateUrl: 'templates/shared/detailView/detailView.component.html',
    controller: AppDetailsController,
    bindings: {
        /**
         * @type {?string} Name of the state which was active before the current state (page that was opened before)
         */
        prevStateName: "<",
        /**
         * Params of the previous state
         */
        prevStateParams: '<',
        /**
         * @type {string[]} Array of IDs from the apps/programs/domains that shall be displayed.
         * If multiple apps are passed and their settings differ, the settings from the lastly passed app are displayed.
         */
        appIds: "<",
        /**
         * @type {chicoEnums.AppTypeEnum} Type of app which is displayed using detailView
         */
        appType: "<",
        /**
         * Set to true if it should be possible to add items using the tag-list (only supported if appType is WEBSITE or WINDOWS_APP)
         * @type {boolean}
         */
        allowAdd: '<'
    }
});

function AppDetailsController(
    $state,
    $log,
    $q,
    $translate,
    AuthFactory,
    appDetailsService,
    scgSharedFactory,
    scgValidationFactory,
    cbListsFactory,
    ccListsFactory,
    appListsFactory,
    appInfoFactory,
    chicoEnums,
    _
) {
    var ctrl = this;
    ctrl.chicoEnums = chicoEnums;


    ctrl.loadingEditor = true;


    ctrl.apps = [];

    /**
     * @type {appDetailsService.LimitOptions}
     * Limit options that are displayed and saved.
     * Represents the limit options of all apps that are passed to this view.
     * If the settings from the apps differ, the settings from the app that was passed last are used.
     */
    ctrl.limitOptions = {};
    
    /**
     * @type {detailViewTimelimitOptions.TimeLimitOptions}
     * Time limit options for the group to which the apps are assigned to.
     * If limitOptions.kind is not a group, this object is empty.
     */
    ctrl.groupTimeLimitOptions = {};

    /**
     * @type {string[]}
     */
    ctrl.iconUrls = [];

    // Displays or hide age and cat suggestions
    ctrl.suggestMode = false;
    ctrl.singleAppLink = "";

    // Display errors in case input validation fails
    ctrl.showErrorWeb = false;
    ctrl.showErrorExe = false;
    ctrl.showErrorTimelimit = false;
    ctrl.showErrorAllowedPlus = false;
    ctrl.showErrorNoEntries = false;


    ctrl.$onInit = function() {

        if (ctrl.appIds !== null) {

            //Edge-Case: Reload without entries (Add-Case in CC-APP, CC-WEB, CB-WEB)
            if (ctrl.appIds[0] === null) {
                ctrl.goPreviousState();
                return;
            }

            $log.info('dv onInit has appIds', ctrl.appIds);
            $log.info('dv onInit has appType',ctrl.appType);

            //Check if we have kind=0 (coming from URL lookup)


            ctrl.getApps(ctrl.appIds);

            // If appType is not APP, the icons are loaded here (not dependent on getApps() response)
            if (ctrl.appType !== chicoEnums.AppTypeEnum.APP) {
                ctrl.getIcons();
            }

            // Make sure, app info (especially description) is not loaded before it is clear which language is used
            $translate.onReady().then(function() {
                if (ctrl.appIds.length === 1 && !ctrl.useMultiView()) {
                    ctrl.singleAppEntry = ctrl.appIds[0];

                    //Set some defaults if we have no kind (e.g. from usage logs or lookup url)
                    if (!ctrl.limitOptions.kind && ctrl.appType === chicoEnums.AppTypeEnum.WEBSITE) ctrl.limitOptions.kind=71;
                    if (!ctrl.limitOptions.kind && ctrl.appType === chicoEnums.AppTypeEnum.APP) ctrl.limitOptions.kind=1;


                }
            });
        } else {
            // Go to previous state or dashboard if page was loaded without passing any apps
            $log.info('No apps were passed to detailView. Forwarding to previous state or dashboard...');
            ctrl.goPreviousState();
        }
    };


    ctrl.validateAllowedPlus = function() {
        if (ctrl.limitOptions.kind!=1 && ctrl.limitOptions.kind!=7) return true;
        if (ctrl.limitOptions.bonus.bonusType!=0) return true;
        ctrl.showErrorAllowedPlus = true;
        $log.info('VALIDATE_ALWAYS FAILED');
        return false;
    }

    ctrl.validateTimelimits = function() {
        $log.info('VALIDATE_TIME= ', ctrl.limitOptions);

        //not interested in other than timelimited
        if (ctrl.limitOptions.kind!=8 && ctrl.limitOptions.kind!=2) return true;
        //exit criterias if limit is active here:
        if (ctrl.limitOptions.timeLimit.isTimeLimitActive===true) return true;
        if (ctrl.limitOptions.timeLimit.isWeekdayTimeLimitActive===true) return true;
        if (ctrl.limitOptions.timeLimit.blockedTimes!=scgSharedFactory.blockOff()) return true;

        //no activated limit found
        $log.info('VALIDATE_TIME NO ACTIVE LIMIT FOUND');
        ctrl.showErrorTimelimit = true;
        return false;
    }

    ctrl.validateEntries = function() {
        if (ctrl.apps && ctrl.apps.length>0) return true;
        ctrl.showErrorNoEntries=true;
        return false;
    }

    /**
     * Returns to last view
     * @param {boolean} save Set to true if changed settings should be saved
     */
    ctrl.closeDetailView = function(save) {
        if (save) {

            $log.info('SAVE CLICK');

            //First, do some validation before save
            if (ctrl.validateEntries()===false) return;
            $log.info('SAVE CLICK 1');
            if (ctrl.validateTimelimits()===false) return;
            $log.info('SAVE CLICK 2');
            if (ctrl.validateAllowedPlus()===false) return;
            $log.info('SAVE CLICK 3');

            appDetailsService.saveAppsWithLimitOptions(ctrl.apps, ctrl.limitOptions, ctrl.groupTimeLimitOptions, ctrl.appType).then(function() {
                ctrl.goPreviousState();
            });
        } else {
            ctrl.goPreviousState();
        }
    };

    ctrl.resetErrors = function() {
        ctrl.showErrorTimelimit = false;
        ctrl.showErrorAllowedPlus = false;
        ctrl.showErrorNoEntries = false;
    }


    ctrl.onKindChange = function(value) {
        $log.info('onkindChange value=', value);
        ctrl.limitOptions.kind = value;
        ctrl.resetErrors();

        if (!ctrl.getGroupOptions()) {
            ctrl.groupTimeLimitOptions = {};
        }
    };

    ctrl.onTagAdding = function(tag) {

        if (tag.entry) tag.entry=tag.entry.toLowerCase();

        // Validation adding domain
        if (ctrl.appType === chicoEnums.AppTypeEnum.WEBSITE) {
            var result = scgValidationFactory.validateDomain(tag.entry);
            $log.info('dv onTagAdding IS WEBSITE result=' + result);
            ctrl.showErrorWeb = !result;
            if (result == true) ctrl.showErrorNoEntries = false;
            return result;
        } else if (ctrl.appType === chicoEnums.AppTypeEnum.WINDOWS_APP) {
            // Validation adding *.exe
            var ccResult=scgValidationFactory.validateExe(tag.entry);
            $log.info('dv onTagAdding IS WEBSITE ccResult=' + ccResult);
            ctrl.showErrorExe = !ccResult;
            if (ccResult==true) ctrl.showErrorNoEntries=false;
            return ccResult;
        }

        return true;
    };

    ctrl.onTagAdded = function(tag) {
        if (ctrl.appType === chicoEnums.AppTypeEnum.WEBSITE) {
            tag.entry = scgSharedFactory.getTLD(tag.entry);
        }

        ctrl.appIds.push(tag.entry);

        // Show at max 5 icons
        if (ctrl.iconUrls.length < 5) {
            ctrl.iconUrls.push(ctrl.getIconUrl(tag.entry));
        }
    };

    ctrl.onTagRemoved = function(tag) {
        ctrl.appIds.splice(ctrl.appIds.indexOf(tag.entry), 1);

        var iconIndex = ctrl.iconUrls.indexOf(ctrl.getIconUrl(tag.entry));
        if (iconIndex >= 0) {
            ctrl.iconUrls.splice(iconIndex, 1);
        }
    };

    /**
     * Determines whether the view to display a single app or a view to display multiple apps is used.
     * View for multiple apps is used if multiple apps should be displayed or adding new apps is possible.
     * @returns {boolean} True if view for multiple apps should be used
     */
    ctrl.useMultiView = function() {
        if (ctrl.appIds.length > 1 || ctrl.allowAdd === true) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Returns placeholder for tags-input depending on appType.
     * Only WINDOWS_APP and WEBSITE supported if allowAdd is true.
     * If no placeholder is available for the current appType, ' ' is returned to prevent the default placeholder of tags-input.
     * @returns {string}
     */
    ctrl.getTagsInputPlaceholder = function() {
        if (ctrl.appType === chicoEnums.AppTypeEnum.WINDOWS_APP) {
            return $translate.instant('MSG.ADD_PRG_PLACEHOLDER');
        } else if (ctrl.appType === chicoEnums.AppTypeEnum.WEBSITE) {
            return $translate.instant('MSG.ADD_WEB_PLACEHOLDER');
        } else {
            $log.error('No tags-input placeholder found for given appType, using default.');
            return ' ';
        }
    }

    /**
     * @param {string[]} appIds Array of appIds
     * @returns {Object[]} Array of apps in the same order as the given array of appIds
     */
    ctrl.getApps = function(appIds) {
        ctrl.apps = [];


        // Only continue if there is any data
        if (appIds.length === 0) {
            ctrl.limitOptions = appDetailsService.limitOptionsFromApp(null, ctrl.appType);

            if (ctrl.appType === chicoEnums.AppTypeEnum.APP || ctrl.appType === chicoEnums.AppTypeEnum.WINDOWS_APP) {
                ctrl.limitOptions.kind = chicoEnums.KindEnum.ALLOWED_BASIC;
            } else if (ctrl.appType === chicoEnums.AppTypeEnum.WEBSITE) {
                ctrl.limitOptions.kind = chicoEnums.KindEnum.ALLOWED_DOMAIN_BASIC;
            }

            ctrl.loadingEditor = false;
            return;
        }


        var queries = [];

        function queryApp(appId) {
            if (ctrl.appType === chicoEnums.AppTypeEnum.APP) {
                return appListsFactory.getAppCB(appId);
            } else if (ctrl.appType === chicoEnums.AppTypeEnum.WINDOWS_APP) {
                return appListsFactory.getAppCC(appId);
            } else if (ctrl.appType === chicoEnums.AppTypeEnum.WEBSITE && AuthFactory.activeContext === chicoEnums.ContextEnum.CB) {
                return appListsFactory.getWebCB(appId);
            } else if (ctrl.appType === chicoEnums.AppTypeEnum.WEBSITE && AuthFactory.activeContext === chicoEnums.ContextEnum.CC) {
                return appListsFactory.getWebCC(appId);
            }
        }

        for (var i = 0; i < appIds.length; i++) {
            queries.push(queryApp(appIds[i]));
        }

        $q.all(queries).then(function(appResponses) {
            for (var i = 0; i < appResponses.length; i++) {
                ctrl.apps.push(appResponses[i].data);
            }
            
            ctrl.limitOptions = appDetailsService.limitOptionsFromApp(ctrl.apps[ctrl.apps.length - 1], ctrl.appType);
            ctrl.loadingEditor = false;

            ctrl.getGroupOptions();

            // If appType is APP, the icons need to be loaded here
            if (ctrl.appType === chicoEnums.AppTypeEnum.APP) {
                ctrl.getIcons();
            }
        });
    };

    /**
     * Gets group options if a group is selected using limitOptions.kind
     * @returns {boolean} True if group options were requested or false if no group is selected and a request is not required
     */
    ctrl.getGroupOptions = function() {
        if (chicoEnums.kindIsCustomGroup(ctrl.limitOptions.kind) || ctrl.limitOptions.kind === chicoEnums.KindEnum.GROUP_INTERNET) {
            if (ctrl.appType === chicoEnums.AppTypeEnum.APP) {
                cbListsFactory.getGroupDefaults(ctrl.limitOptions.kind - 10).then(function(response) {
                    // Because groups only use timeLimitOptions, this function can be used to convert the response
                    ctrl.groupTimeLimitOptions = appDetailsService.timeLimitOptionsFromApp(response.data, ctrl.appType);
                });
            } else if (ctrl.appType === chicoEnums.AppTypeEnum.WINDOWS_APP) {
                ccListsFactory.getGroupDefaults(ctrl.limitOptions.kind - 10).then(function(response) {
                    // Because groups only use timeLimitOptions, this function can be used to convert the response
                    ctrl.groupTimeLimitOptions = appDetailsService.timeLimitOptionsFromApp(response.data, ctrl.appType);
                });
            } else if (ctrl.appType === chicoEnums.AppTypeEnum.WEBSITE && scgSharedFactory.hasCBFeature('alwaysAllowedWeb')) {
                cbListsFactory.getGroupDefaults(ctrl.limitOptions.kind - 10).then(function(response) {
                    // Because groups only use timeLimitOptions, this function can be used to convert the response
                    ctrl.groupTimeLimitOptions = appDetailsService.timeLimitOptionsFromApp(response.data, ctrl.appType);
                });
            }

            return true;
        } else {
            return false;
        }
    };

    /**
     * Gets URLs of all icons that will be displayed
     */
    ctrl.getIcons = function() {
        if (!ctrl.useMultiView()) {
            ctrl.iconUrls.push(ctrl.getIconUrl(ctrl.appIds[0]));
        } else if (ctrl.useMultiView()) {
            for (var i = 0; i < ctrl.appIds.length; i++) {
                // Show at max 5 icons
                if (i > 4) {
                    break;
                }

                ctrl.iconUrls.push(ctrl.getIconUrl(ctrl.appIds[i]));
            }
        }
    };

    /**
     * Returns icon URL corresponding to the given id.
     * If appType is APP, this function depends on the apps array which needs to be loaded first.
     * @param {string} appId Id of the app for which the icon url should be retrieved
     * @returns {string} URL to the icon or empty string if no icon is available for current appType
     */
    ctrl.getIconUrl = function(appId) {
        if (ctrl.appType === chicoEnums.AppTypeEnum.WINDOWS_APP) {
            return appInfoFactory.getExeIconUrl(appId);
        } else if (ctrl.appType === chicoEnums.AppTypeEnum.WEBSITE) {
            return appInfoFactory.getWebIconUrl(appId);
        } else if (ctrl.appType === chicoEnums.AppTypeEnum.APP) {
            // The following line depends on the same order of the appIds and the apps arrays
            return appInfoFactory.getAppIconUrl(ctrl.apps[ctrl.appIds.indexOf(appId)].description);
        } else {
            return '';
        }
    };

    ctrl.goPreviousState = function() {
        // Check if there is a previous state
        if (ctrl.prevStateName && ctrl.prevStateName !== '') {
            $log.info('Forwarding from detailView to previous state...');
            $state.go(ctrl.prevStateName, ctrl.prevStateParams);
        } else {
            // If not, go to dashboard by default
            ctrl.goDashboard();
        }
    };

    /**
     * Go to dashboard view. Select dashboard (cb or cc depending on current context)
     */
    ctrl.goDashboard = function() {
        $log.info('Forwarding from detailView to dashboard...');
        if (AuthFactory.activeContext === chicoEnums.ContextEnum.CB) {
            $state.go('cb-dashboard-v3');
        } else {
            $state.go('cc-dashboard-v3');
        }
    };

    ctrl.toggleSuggestMode = function()
    {
        ctrl.suggestMode = !ctrl.suggestMode;
    }
}
