'use strict';
angular.module('ChicoApp.CB-SYNC', [])
    .config(function($stateProvider) {
      $stateProvider.state('cb-sync', {
        url: '/cb-sync',
        controller: 'CB-SyncController',
        templateUrl: 'templates/chicobrowser/cb_sync/templates/sync.html'
      });
    })
    .run(function() {});
