'use strict';
angular.module('ChicoApp.CC-Settings')

    .controller('CC-Settings-General', function(
        $scope,
        $state,
        $http,
        $window,
        $timeout,
        $translate,
        $log,
        $stateParams,
        $modal,
        Metronic,
        API,
        apiFunctions,
        AuthFactory,
        CCSettings,
        modalFactory,
        Upload,
        scgGlobalSpinnerFactory,
        scgSharedFactory) {

      $log.info('CC-Settings-General');


      //initially set language to scope to have it in the template
      $scope.hasNewExtLogic = false;
      $scope.hasFeatureChangeWarning = false;
      $scope.hasFeatureNoAudioStop = false;
      $scope.hasFeatureCloneMode = false;
      $scope.hasFeatures2019 = false;
      $scope.hasRoamingV2 = false;
      $scope.hasLoginLock = false;
      $scope.userAgent = ''; //only for dev purposes, click on Selbstkontrolle
      $scope.currentLanguage = $translate.use();
      $scope.scgSharedFactory = scgSharedFactory;
      $scope.isMobileDevice = scgSharedFactory.isMobile();


      //watch and implement lang changes
      $scope.$watch('currentLanguage', function(nV, oV) {
        if (nV && nV != oV) {
          $translate.use(nV);
        }
      });

      $scope.data = {};

      //Gather general info details (async!)
      $scope.ccversion = {};
        AuthFactory.getVersionInfo()
      .then(function(version) {
        $scope.ccversion = version;
      });

      //Has Feature changeWarning?
        // TODO Dierk: Move these feature checks to central hasFeature method!
      scgSharedFactory.getCCVersionNumber().then(function success(version) {
          $log.info('CCVersion number is:', version);
          if (version.major >= 18 &&
              version.minor > 3000) {
              $scope.hasFeatures2019 = true;
          }
          if (version.major >= 16 &&
              version.minor > 2023) {
            $scope.hasFeatureChangeWarning = true;
          }
          if (version.major >= 16 &&
              version.minor > 2799) {
              $scope.hasRoamingV2 = true;
          }
          if (version.major >= 16 &&
              version.minor > 2850) {
              $scope.hasLoginLock = true;
          }
          if (version.major >= 16 &&
              version.minor > 3167) {
              $scope.hasFeatureNoAudioStop = true;

          }
          if (version.major >= 16 &&
              version.minor > 3167) {
              $scope.hasFeatureCloneMode = true;
          }

          $log.info('hasFeatureChangeWarning:' , $scope.hasFeatureChangeWarning);
        });

      //Gather lastsync date (async!)
      $scope.lastSync = '   ' + $translate.instant('MISC.RESOLVING');
      scgSharedFactory.getLastSync().then(function success(lastSync) {
        $scope.lastSync = scgSharedFactory.formatDateTime(lastSync);
      });

      $scope.baustelle = function() {
        modalFactory.okModal(1, $translate.instant('MSG.TODO'));
      };

      $scope.showUserAgent = function() {
          $log.info('showUserAgent');
          $scope.userAgent = $window.navigator.userAgent;
      }

      $scope.changePW = function() {
        $state.go('cc-settings.pwreset');
      };

      $scope.gotoExclusion = function() {
        $state.go('cc-settings.exclusions');
      };

      $scope.gotoTAN = function() {
        $state.go('cc-tans');
      };

      $scope.gotoExtended = function() {
        $state.go('cc-settings.extended');
      };


        $scope.handleCloneMode = function() {
            var changed_object = {};
            if ($scope.cloneMode===true) {
                $log.info('HandleCloneMode now true');
                changed_object['cloneMode'] = 'auto';
                //$scope.data.cloneMode='auto';
                //ccSettings.
            } else {
                $log.info('HandleCloneMode now false');
                changed_object['cloneMode'] = 'off';
                //$scope.data.cloneMode='off';
            }

            scgSharedFactory.needsSync = true;
            CCSettings.setSetting(changed_object);




        }

      $scope.updateBackground = function() {
        if ($scope.data.imgBackgroundURL != null && $scope.data.imgBackgroundURL != "") {
            $log.info('custom background cc');
            $scope.hasCustomBackground=true;
            var sessionPath = AuthFactory.sessionId + '/' + AuthFactory.activeDevice.deviceId;
            $scope.imgBack= API.rooturl + 'files/background/' + sessionPath+'?nocache='+$scope.backgroundCounter;
        } else {
            $log.info('standard background cc, data=', $scope.data);
            $scope.hasCustomBackground = false;
            if ($scope.data.standardIconNo == 2) {
                $scope.imgBack = '../images/cc-logo-2.png';
            } else {
                $scope.imgBack = '../images/cc-logo.png';
            }
        }
        }


        // upload on file select or drop
      $scope.importCC = function(file, errFiles) {
        $log.info('Upload INIT');
        $scope.f = file;
        $log.info('Upload entry');
        $scope.errFile = errFiles && errFiles[0];
        if (file) {
          Metronic.blockUI({animate: true});
          file.upload = Upload.upload({
            url: apiFunctions.get_cc_base_url() +  'service/import/ccboth',
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            data: {file: file}
          });

          file.upload.then(function(response) {
            $timeout(function() {
              file.result = response.data;
              $log.info('Upload status A' + response.data);
              Metronic.unblockUI();
              modalFactory.okModal(1, $translate.instant('MSG.IMPORTSUCCESS'));
            });
          }, function(response) {
            if (response.status > 0) {
              $scope.errorMsg = response.status + ': ' + response.data;
            }
            Metronic.unblockUI();
            $log.info('Upload status B');
            modalFactory.okModal(3, $translate.instant('MSG.IMPORTFAILED'));
          }, function(evt) {
            file.progress = Math.min(100, parseInt(100.0 *
                evt.loaded / evt.total));
          });
        }
      };

      $scope.exportModal = function() {

        var modaloptions = {
          onClose: exportSettings
        };
        $modal.open({
          animation: false,
          template: '<scg-export-modal></scg-export-modal>', //scgAddtanModal
          windowTemplateUrl: 'templates/childcontrol/cc_settings/templates/modal-export-window.html',
          controller: 'SCG-Modal-Export-Controller',
          resolve: {
            modalCallbacks: function() {
              return modaloptions;
            }
          }
        });

      };

      function exportSettings(filename) {
        //Exit on ios (because it displays JSON as text)
        var ua = 'User-agent header sent: ' + navigator.userAgent;
        if (ua && ua.match('iPhone|iPad')) {
          modalFactory.okModal(1, $translate.instant('MSG.NOTONIOS'));
          return;
        }
        var req = {
          method: 'GET',
          url: apiFunctions.get_cc_base_url() + 'service/export/cc'
        };
        $http(req)
                .then(function success(response) {
                  $log.info('http success has been returned');
                  $log.info('response=', response);
                  var data = response.data;
                  if (typeof data === 'object') {
                    data = JSON.stringify(data, undefined, 2);
                  }
                  var blob = new Blob([data], {
                    type: 'text/json'
                  });

                  if (window.navigator.msSaveBlob) {
                    window.navigator.msSaveBlob(blob, filename);
                  } else {
                    var e = document.createEvent('MouseEvents');
                    var a = document.createElement('a');
                    a.download = filename;
                    a.href = window.URL.createObjectURL(blob);
                    a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
                    e.initMouseEvent('click', true, false, window,
                        0, 0, 0, 0, 0, false, false, false, false, 0, null);
                    a.dispatchEvent(e);
                  }

                  modalFactory.okModal(1,$translate.instant('MSG.DOWNLOADCC') + '<br><br>' + $translate.instant('MSG.DOWNLOADSUCCESS'));

                }, function error() {
                  $log.info('error jas been returned');
                  modalFactory.okModal(1, $translate.instant('MSG.IMPORTFAILED')); //importfailed msg ok here
                });
      }

      $scope.deleteData = function() {
        modalFactory.infoModal.open({
          modal_settings: {
            title: $translate.instant('MSG.SURE'),
            message: $translate.instant('MSG.DELETEDATA') + '<br>' + $translate.instant('MSG.SURE'),
            butOkLabel: $translate.instant('BUTTON.YES'),
            butCancelLabel: $translate.instant('BUTTON.NO'),
            iconNo: 3,
            refreshStateAfterClose: false //Dierk: bei true wurde controller gleich neu geladen werden
          },
          callbacks: {
            ok: function() {
              $log.info('modal closed with ok -> callback');
              Metronic.blockUI({animate: true});

              var myurl = API.url + 'action/deletedevice';
              var req = {
                method: 'GET',
                url: myurl
              };
              $http(req)
                            .then(function success() {
                              $log.info('Done with HTTP 200!');
                              Metronic.unblockUI();
                              AuthFactory.logout(true);
                            });
            },
          },
          cancel: function() {
            $log.info('canceled!');
          }
        });
      };


      scgSharedFactory.getCCVersionNumber().then(function success(version) {
        $log.info('SPECIAL LIMIT CCVersion number is:', version);
        if (version.major >= 16 && version.minor >= 2182) {
          $scope.hasNewExtLogic = true;
        }
      });


        $scope.roamingSettings = function() {
            $log.info("roamingSettings called ");
            modalFactory.roamingModal.open({
                modal_settings: {
                    refreshStateAfterClose: false
                },callbacks: {
                    ok: function () {
                        $log.info('modal-until ok callback');
                        $state.reload();
                    }
                }
            });
        }


        $scope.loginlockSettings = function() {
            $log.info("loginlockSettings called ");
            modalFactory.loginLockedModal.open({
                modal_settings: {
                    refreshStateAfterClose: false
                },callbacks: {
                    ok: function () {
                        //nothing to do here
                    }
                }
            });
        }

        $scope.setDevicename = function(newname) {
            //we have to pass newname here because we have two different scopes in cc-settings
            scgSharedFactory.setDevicename(newname).then(function sucess(response){
                $window.location.reload();  //force full page reload (instead of state.reload)
            })
        }

        $scope.resetDevicename = function() {
            scgSharedFactory.resetDevicename().then(function sucess(response){
                $window.location.reload();  //force full page reload (instead of state.reload)
            })
        }

        $scope.customImgMsg = function() {
            $log.info('customImgMsg called');
            modalFactory.okModal(1, $translate.instant('MSG.CUSTOMIMG_CC'));
        }

        $scope.deleteBackground = function() {
            var sessionPath = AuthFactory.sessionId + '/' + AuthFactory.activeDevice.deviceId;
            $log.info('DELETE BACKGROUND sess=' + sessionPath);

            $http({
                method: 'DELETE',
                url: API.rooturl + 'files/background-cc/' + sessionPath
            }).then(function(result){
                $scope.data.imgBackgroundColor="#33B5E5";
                $scope.data.imgBackgroundURL="";
                scgSharedFactory.needsSync = true;
                $scope.updateBackground();
                $state.reload();
            });
        }

        $scope.uploadBackground = function(file, errFiles) {
            $scope.f = file;
            if ($scope.f != null && $scope.f.size != null) {
                $log.info('Upload the entry with file size=', $scope.f.size);
            }
            $scope.errFile = errFiles && errFiles[0];

            /* DEPRECIATED because Backend now uses TinyPNG to compress
            if ($scope.f==null || $scope.f.size==null || $scope.f.size>999999)
            {
                modalFactory.okModal(1, $translate.instant('MSG.CUSTOMIMG_TOO_LARGE'));
                return;
            }*/

            if (file) {
                Metronic.blockUI({animate: true});
                var sessionPath = AuthFactory.sessionId + '/' + AuthFactory.activeDevice.deviceId;

                file.upload = Upload.upload({
                    url: API.rooturl + 'files/background-cc/' + sessionPath,
                    method: 'POST',
                    headers: {
                        'Content-Type': 'multipart/form-data;'
                    },
                    data: {file:file}
                });

                file.upload.then(function(response) {
                    $timeout(function() {
                        file.result = response.data;
                        $log.info('Upload status A' + response.data);
                        Metronic.unblockUI();
                        $scope.backgroundCounter = moment().format('X');
                        scgSharedFactory.needsSync = true;
                        $scope.updateBackground();
                        $state.reload();
                    });
                }, function(response) {
                    if (response.status > 0) {
                        $scope.errorMsg = response.status + ': ' + response.data;
                    }
                    Metronic.unblockUI();
                    $log.info('Upload status B');
                    modalFactory.okModal(3, $translate.instant('MSG.IMPORTFAILED'));
                }, function(evt) {
                    file.progress = Math.min(100, parseInt(100.0 *
                        evt.loaded / evt.total));
                });
            }
        };
        // EOF BACKGROUND PICTURE STUFF ------------------------------------------------------------------



        $log.info('before updateBackground, data=', $scope.data);
        $scope.updateBackground();


        //watcher for version (important for roamingv2)
        //------------------------------------------------------------------------
        $scope.$watch(function() {
            return AuthFactory.activeDevice.versionInfo;
        }, function(newVal) {
            if (AuthFactory.activeDevice.versionInfo) {
                //$scope.hasRoamingV2 = $scope.hasCCFeature('roamingv2',AuthFactory.activeDevice.versionInfo);
                $log.info('watch version activeDevice=', AuthFactory.activeDevice.versionInfo);
            }
        });



        //watcher for deviceType change (CBE!)
      $scope.$watch(function() {
        return AuthFactory.activeDevice + AuthFactory.activeUser;
      }, function(newVal) {
        if( $scope.deviceType != AuthFactory.activeDevice.deviceType ){
          $scope.deviceType = AuthFactory.activeDevice.deviceType;
        }
      });

    });

