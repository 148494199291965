'use strict';
angular.module('ChicoApp.Shared').controller('SCG-Modal-LoginLocked-Controller', function(
    $scope,
    $log,
    $http,
    $translate,
    $modalInstance,
    $window,
    API,
    AuthFactory,
    modalFactory,
    scgSharedFactory
) {

    $scope.agreeMissing = false;
    $scope.contentLoading = false;
    $scope.lockedDays = 1;
    $scope.agreeText = "";


    $window.ga('send', 'pageview', {page:'/modal-loginlocked'});

    $scope.ok = function(return_val) {
        $log.info('ok clicked, agreeText=' + $scope.agreeText);


        if ($scope.lockedDays<1) {
            $modalInstance.close(return_val);
            return;
        }

        if ($scope.lockedDays>90) {
            $scope.lockedDays=90;
        }


            if ($scope.agreeText==null || $scope.agreeText=='' || ($scope.agreeText.toLowerCase()!='agree' && $scope.agreeText.toLowerCase()!='verstanden'))  {
            $scope.agreeMissing=true;
            return;
        }



        $scope.contentLoading=true;
            $http.get(API.url + 'action/lockdays/' + $scope.lockedDays)
                .then(function success(response) {
                    $log.info('lockdays http response=', response.data);
                    var destDate = "???";
                    var msg = "???";
                    if (response.data!=null && response.data.date != null && response.data.date!="") {
                        destDate = response.data.date;
                    }
                    $log.info('lockdays destdate=', destDate);
                    msg = $translate.instant('LOGINLOCK.SUCCESS') + ' ' + scgSharedFactory.formatDate(destDate);
                    $scope.contentLoading=false;
                    modalFactory.infoModal.open({
                        modal_settings: {
                            title: $translate.instant('HEADER.INFO'),
                            message: msg,
                            butOkLabel: $translate.instant('BUTTON.OK'),
                            butCancelLabel: null,
                            iconNo: 1,
                            refreshStateAfterClose: true
                        },
                        callbacks: {
                            ok: function() {
                                AuthFactory.logout(true);
                            }
                        }
                    })
                });
    };

});
