'use strict';
angular.module('ChicoApp.CC-Internet', [])
    .config(function($stateProvider) {
      $stateProvider
        .state('cc-internet', {
          url: '/internet',
          redirectTo: 'cc-internet.tabs',
          controller: 'CC-InternetController',
          templateUrl: 'templates/childcontrol/cc_internet/templates/internet.html'
        })
        .state('cc-internet.categories', {
          url: '/categories',
          templateUrl: 'templates/childcontrol/cc_internet/templates/internet-categories.html',
          controller: 'CC-InternetController-Categories',
        })

        .state('cc-internet.byage', {
          url: '/byage',
          templateUrl: 'templates/childcontrol/cc_internet/templates/internet-byage.html',
          controller: 'CC-InternetControllerByAge',
        })

        .state('cc-internet.tabs', {
          url: '/tabs',
          redirectTo: 'cc-internet.tabs.blocked',
          templateUrl: 'templates/childcontrol/cc_internet/templates/internet-tabs.html',
          controller: 'CC-Internet-WebsitesController',
        })

        .state('cc-internet.tabs.blocked', {
          url: '/blocked',
          templateUrl: 'templates/childcontrol/cc_internet/templates/internet-blocking-generic-list.html',
          controller: 'CC-Internet-Blocked-Controller',
        })

        .state('cc-internet.tabs.allowed', {
          url: '/allowed',
          templateUrl: 'templates/childcontrol/cc_internet/templates/internet-blocking-generic-list.html',
          controller: 'CC-Internet-Allowed-Controller',
        })

        .state('cc-internet.tabs.timelimited', {
          url: '/timelimited',
          templateUrl: 'templates/childcontrol/cc_internet/templates/internet-blocking-generic-list.html',
          controller: 'CC-InternetControllerBlocking-TimeLimits',
        })

        .state('cc-internet.tabs.group', {
          url: '/groups',
          templateUrl: 'templates/childcontrol/cc_internet/templates/internet-blocking-groups.html',
          controller: 'CC-InternetControllerBlocking-Groups',
        })

          .state('cc-internet.tabs.byage', {
            url: '/age',
            templateUrl: 'templates/childcontrol/cc_internet/templates/internet-byage.html',
            controller: 'CC-InternetControllerByAge',
          })

          .state('cc-internet.tabs.categories', {
            url: '/categories',
            templateUrl: 'templates/childcontrol/cc_internet/templates/internet-categories.html',
            controller: 'CC-InternetController-Categories',
          })

        .state('cc-internet.bonus', {
          url: '/bonus',
          templateUrl: 'templates/childcontrol/cc_internet/templates/internet-blocking-generic-list.html',
          controller: 'CC-InternetControllerBonus',
        })

        .state('cc-internet.misc', {
          url: '/misc',
          templateUrl: 'templates/childcontrol/cc_internet/templates/internet-misc.html',
          controller: 'CC-InternetControllerMisc'
        });
    })

    .run(function() {});
