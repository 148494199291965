'use strict';
angular.module('ChicoApp.Shared').directive('scgFaqModal', function() {
  return {
    restrict: 'E',
    templateUrl: 'templates/shared/globalModals/globalFaqModal/templates/modal-faq-content.html',
    controller: function() {

    }
  };
});

