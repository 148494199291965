'use strict';
angular.module('ChicoApp.CB-Appfilter')

    .controller('CB-Appfilter-Group-Controller', function(
        $scope,
        $state,
        $translate,
        cbListsFactory,
        DTOptionsBuilder,
        DTColumnDefBuilder,
        scgSettingsFactory,
        modalFactory,
        scgSharedFactory,
        _,
        $log
         ) {

      var modalContext = 'prg';

      $scope.data = {};
      $scope.currentSelectedGroup = null;
      $scope.currentSelectedIndex = null;
      $scope.scgSharedFactory = scgSharedFactory;    //possible to call directly in HTML!

      $scope.contentLoading = true;
      scgSettingsFactory.getGroups(false)
                .then(function success(response) {

                  $scope.data.groups = response.data;
                  $scope.contentLoading = false;

                  // $log.info('$scope.data.groups', $scope.data.groups);

                  _.each($scope.data.groups, function(group, index) {

                    $scope.$watch('data.groups[' + index + ']', function(newVal, oldVal) {
                      if (newVal && newVal != oldVal && newVal.name != oldVal.name) {
                        $scope.renameGroup(newVal);
                      }
                    },true);

                  });
                  $scope.contentLoading = false;
                });

      $scope.getSubItemsForGroup = function(group, index) {
        cbListsFactory.getDetailedByListFilter(group.kind)
        //cbListsFactory.getByListFilter(group.kind)
                    .then(function success(response) {
                      $scope.data.groups[index].subItems = response.data;
                      $log.info('Get SUBGROUP ITEMS DONE');
                    });
      };

      $scope.renameGroup = function(/*newGroup*/) {
        scgSettingsFactory.setGroups($scope.data.groups)
                    .then(function success(/*response*/) {
                      $log.info('groups saved');
                    });
      };

      $scope.editCurrentGroup = function() {

        //prepare list for modal required data structure
        var editRecords = [];
        _.each($scope.currentSelectedGroup.subItems, function(v/*, k*/) {
          $log.info('V is:' , v);
          var obj = {data: {entry: v.entry}};
          editRecords.push(obj);
        });

        var modalMode;
        var newRecord = null;
        if (editRecords.length < 1) {
          newRecord = {
            kind: $scope.currentSelectedGroup.kind
          };
          modalMode = 'add';
          editRecords = null;
        }else {
          modalMode = 'edit';
        }

        modalFactory.editModal.open({
          modal_settings: {
            mode: modalMode,
            context: modalContext,
            title: $translate.instant('MODAL_EDIT_RECORD.TITLE_EDIT'),
            newRecord: newRecord,
            editRecords: editRecords,
            refreshStateAfterClose: true
          },
          callbacks: {
            ok: function() {},
            cancel: function() {}
          }
        });

      };

      $scope.toggleSubitems = function(group, index) {

        $scope.getSubItemsForGroup(group, index);

        if (group == $scope.currentSelectedGroup) {
          $scope.currentSelectedGroup = null;
          $scope.currentSelectedIndex = null;

          $scope.data.groups.forEach(function(item) {
            item.subitemVisible = false;
          });
        }else {

          $scope.currentSelectedGroup = group;
          $scope.currentSelectedIndex = index;

          $scope.data.groups.forEach(function(item) {
            if (item.no.no != group.no) {
              item.subitemVisible = false;
            }

          });

          group.subitemVisible = !group.subitemVisible;

        }

      };

      $scope.deleteEntryFromGroup = function(item) {

        cbListsFactory.getByObjectKey(item.entry)
            .then(function success(response) {

              var fullEntry = response.data;
              fullEntry.kind = cbListsFactory.listKinds.list_kind_allowed_prg;

              cbListsFactory.postRecords([fullEntry])
                           .then(function success() {
                            $scope.getSubItemsForGroup($scope.currentSelectedGroup, $scope.currentSelectedIndex);
                          });

            });

      };

    });

