'use strict';
angular.module('ChicoApp.SB-Dashboard')

    .controller('SB-DashboardController', function ($rootScope,
                                                    $scope,
                                                    $translate,
                                                    $state,
                                                    $modal,
                                                    $http,
                                                    $window,
                                                    moment,
                                                    API,
                                                    scgSharedFactory,
                                                    AuthFactory,
                                                    $log,
                                                    DeviceFactory,
                                                    sbDashboardFactory,
                                                    Metronic,
                                                    _
                                                    ) {



        $scope.lasturlData = {};
        $scope.webfilterData = {};
        $scope.totalHistoryUrl =0;
        $scope.lasturlLoading=false;
        $scope.noticeText = "";
        $scope.scgSharedFactory = scgSharedFactory;

        Metronic.unblockUI();
        $log.info('Welcome to SB Dashboard controller');


        $scope.loadHowMany = function(howmany) {
            $scope.lasturlLoading=true;
            sbDashboardFactory.getUsageHowMany(howmany)
                .then(function success(response) {
                    $scope.lasturlData = response.data;
                    $scope.totalHistoryUrl =response.data.length;
                    $scope.lasturlLoading=false;
                });
        }

        sbDashboardFactory.getWebfilterDetails()
            .then(function success(response) {
                $scope.webfilterData = response.data;
                $log.info('webfilter details=', $scope.webfilterData);
            });

        scgSharedFactory.getLicenceDetails()
            .then(function success(response) {
                $scope.licenceData = response.data;
                $scope.isTrial = $scope.licenceData.isTrial;
                var remaining = $scope.licenceData.expireDays;
                if (remaining < 0) {
                    remaining = 0;
                }
                if ($scope.isTrial) {
                    $scope.noticeText = $translate.instant('MISC.TRIALVERSION') + ': ' + $translate.instant('MISC.REMAINING').replace('XXX', remaining);
                }
                if (!$scope.isTrial && remaining<31) {
                    $scope.noticeText = $translate.instant('MISC.FULLVERSION') + ': ' + $translate.instant('MISC.REMAINING').replace('XXX', remaining);
                }
            });




        $scope.loadHowMany(5);












    }); //eof controller