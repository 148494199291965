'use strict';
angular.module('ChicoApp.Licence', [])
    .config(function($stateProvider) {
      $stateProvider.state('licence', {
        url: '/licence',
        controller: 'LicenceController',
        templateUrl: 'templates/licence/templates/licence.html',
      })

        .state('mylicences', {
          url: '/mylicences',
          controller: 'LicenceController',
          templateUrl: 'templates/licence/templates/mylicences.html',
        })

        .state('mydata', {
          url: '/mydata',
          controller: 'MydataController',
          templateUrl: 'templates/licence/templates/mydata.html',
        });

    })
    .run(function() {});
