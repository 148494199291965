'use strict';
angular.module('ChicoApp.Login')

    .controller('PWRequestController', function(
        $scope,
        $stateParams,
        $http,
        $translate,
        API,
        $cookies,
        modalFactory,
        scgSharedFactory,
        Metronic,
        $log,
        AuthFactory) {

      $scope.reqemail = '';

      //Handle language
      var cookieLang = $cookies.get('language') != undefined ? $cookies.get('language').substring(0,2) : undefined;
      $scope.pwLang =  $stateParams.lang  || cookieLang || 'de';
      $log.info('lang=' + $scope.pwLang);
      if ($scope.pwLang) {
        if ($scope.pwLang === 'de') {
          $translate.use('de_DE');
        }else {
          $translate.use('en_US');
        }
      }

      scgSharedFactory.needsSync = false;

      $scope.pwRequest = function() {
        $scope.data = {};
        $scope.data.username = $scope.reqemail;
        $scope.data.lang = $translate.use().substring(0,2);  //"wir brauchen "de" oder "en"
        Metronic.blockUI({animate: true});

        $log.info('Trigger Mail' + $scope.data.lang + '|' + $scope.data.username);
        var req = {
          method: 'POST',
          data: $scope.data,
          url: API.url + 'login/PWChangeRequestGetToken',
        };
        $http(req)
                .then(function success(/*response*/) {
                  $log.info('mail sent');
                  Metronic.unblockUI();
                  $scope.showSuccess();
                }, function error(/*a, b*/) {
                  //$scope.reload();
                  Metronic.unblockUI();
                  $log.info('mail sent FAILED');
                });

      };

      $scope.showSuccess = function() {
        modalFactory.infoModal.open({
          modal_settings: {
            title: $translate.instant('HEADER.INFO'),
            message: $translate.instant('MSG.PWMAILSENT'),
            butOkLabel: $translate.instant('BUTTON.OK'),
            butCancelLabel: null,
            iconNo: 1,
            refreshStateAfterClose: false
          },
          callbacks: {
            ok: function() {
              AuthFactory.logout(true);
            }
          }
        });
      };

      $scope.showLicenceRequestMsg = function(msg) {
        modalFactory.infoModal.open({
          modal_settings: {
            title: $translate.instant('HEADER.INFO'),
            message: msg,
            butOkLabel: $translate.instant('BUTTON.OK'),
            butCancelLabel: null,
            iconNo: 1,
            refreshStateAfterClose: false
          },
          callbacks: {
            ok: function() {
              AuthFactory.logout(true);
            }
          }
        });
      };

      $log.info('statparams', $stateParams);
      //WTF WHY NOT WORKING?
      if ($stateParams.prefilluser) {
        $scope.reqemail = $stateParams.prefilluser;
      }

      $scope.licenceRequest = function() {
        $log.info('LICENCE-REQUEST for:' + $scope.reqemail);
        $scope.data = {};
        $scope.data.username = $scope.reqemail;
        $scope.data.lang = $translate.use().substring(0,2);  //"wir brauchen "de" oder "en"
        Metronic.blockUI({animate: true});
        var req = {
          method: 'POST',
          data: $scope.data,
          url: API.url + 'login/getlicencesmail',
        };
        $http(req)
                .then(function success(/*response*/) {
                  $log.info('mail sent');
                  Metronic.unblockUI();
                  $scope.showLicenceRequestMsg($translate.instant('MSG.LICMAILSENT'));
                }, function error(/*a, b*/) {
                  //$scope.reload();
                  Metronic.unblockUI();
                  $scope.showLicenceRequestMsg($translate.instant('MSG.ERROR_CONTACT'));
                  $log.info('mail sent FAILED');
                });

      };

    });
