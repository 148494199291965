'use strict';
angular.module('ChicoApp.CC-Security', [])
    .config(function($stateProvider) {

      $stateProvider
        .state('cc-security', {
          url: '/security',
          template: '<ui-view/>',
          redirectTo: 'cc-security.windows'
        })
          .state('cc-security.windows', {
            url: '/windows-security',
            templateUrl: 'templates/childcontrol/cc_security/templates/security-windows.html',
            controller: 'CC-Security-Windows'
          })
          .state('cc-security.folders', {
            url: '/folders',
            templateUrl: 'templates/childcontrol/cc_security/templates/security-folders.html',
            controller: 'CC-Security-Folders'
          });
    })
    .run(function() {});
