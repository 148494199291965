'use strict';
angular.module('ChicoApp.SB-Usage', [])
    .config(function($stateProvider) {
        $stateProvider.state('sb-usage', {
            url: '/sb-usage',
            controller: 'SB-UsageController',
            templateUrl: 'templates/browser/sb_usage/templates/reports.html'
        })
    })
    .run(function() {});
