'use strict';
angular.module('ChicoApp.SC-Dashboard')
    .controller('SC-DashboardController', function(
        $rootScope,
        $scope,
        $state,
        $http,
        $modal,
        $translate,
        $timeout,
        Metronic,
        moment,
        $window,
        $log,
        $cookies,
        API,
        modalFactory,
        AuthFactory,
        Upload,
        scgSharedFactory,
        scgDataTableFactory,
        DTColumnDefBuilder,
        _,
        scDashboardFactory) {

        var watchers = [];

        var apiDateFormat = 'YYYY-MM-DD HH:mm:SS';

        $scope.tableHeaders =  [$translate.instant('HEADER.APPLIST')];

        $scope.data = {};
        $scope.profileId = "1";
        $scope.profile1 = "Profile 1";
        $scope.profile2 = "Profile 2";
        $scope.profile3 = "Profile 3";
        $scope.profile4 = "Profile 4";
        $scope.profile5 = "Profile 5";
        $scope.imgBack = '../images/start-control.png';
        $scope.backgroundCounter = moment().format('X'); //random value (caching!)
        $scope.hasCustomBackground = false;
        $scope.renameMode = false;
        $scope.loadingSettings = false;

        $scope.dataSettings = {};
        $scope.dataSettings.title="Start Control";

        scgSharedFactory.needsSync = false;

        $log.info('welcome to start Control Controller!');
        Metronic.unblockUI();

        scgDataTableFactory.prepareScopeForTable($scope, 'sc');
        $scope.dtOptions = scgDataTableFactory.getDefaultDtOptions($scope);

        $scope.dtColumnDefs = [
            DTColumnDefBuilder.newColumnDef(0).notSortable().withOption('sWidth','10px'),
            DTColumnDefBuilder.newColumnDef(1).notSortable().withOption('sWidth','15px'),
            DTColumnDefBuilder.newColumnDef(2)
        ];

        $scope.fillInnerListFromApiList = function(api_list) {

            $scope.data.table_inner = [];

            _.each(api_list, function(v) {

                var obj;
                if (v.appName) { //if this is an object
                    obj = {selected: false, data: v};
                }else {
                    obj = {selected: false, data: {entry: v}};
                }

                obj.checked = v.status === 1 ? true : false;
                $scope.data.table_inner.push(obj);
            });
        };


        $scope.renameCurrentProfile = function() {
            $scope.renameMode = true;
            if ($scope.profileId==1) {$scope.newProfileName=$scope.profile1;}
            if ($scope.profileId==2) {$scope.newProfileName=$scope.profile2;}
            if ($scope.profileId==3) {$scope.newProfileName=$scope.profile3;}
            if ($scope.profileId==4) {$scope.newProfileName=$scope.profile4;}
            if ($scope.profileId==5) {$scope.newProfileName=$scope.profile5;}
        }

        $scope.renameCurrentProfileConfirm = function() {
            if ($scope.profileId==1) {$scope.profile1=$scope.newProfileName;}
            if ($scope.profileId==2) {$scope.profile2=$scope.newProfileName;}
            if ($scope.profileId==3) {$scope.profile3=$scope.newProfileName;}
            if ($scope.profileId==4) {$scope.profile4=$scope.newProfileName;}
            if ($scope.profileId==5) {$scope.profile5=$scope.newProfileName;}
            var obj = {profile1:$scope.profile1, profile2:$scope.profile2,profile3:$scope.profile3,profile4:$scope.profile4,profile5:$scope.profile5};
            scDashboardFactory.setProfileNames(obj);
            $scope.renameMode = false;
        }

        $scope.changeChecked = function(changedRow){
            var myStatus = 0; //default
            if (changedRow.checked===true) {
                myStatus = 1;
            }
            scDashboardFactory.setAppItem({
                profileId : changedRow.data.profileId,
                pkgName: changedRow.data.pkgName,
                status: myStatus
            },$scope.profileId);
        }


        $scope.loadList = function() {
            $scope.contentLoading = true;
            scDashboardFactory.getAppList($scope.profileId)
                .then(function success(response) {
                    $scope.fillInnerListFromApiList(response.data);
                    $scope.data.table_api = response.data;
                    $scope.contentLoading = false;
                });
        };


        $scope.getProfileNames = function() {
            $scope.contentLoading = true;
            scDashboardFactory.getProfileNames()
                .then(function success(response) {
                    $log.info('profiles:', response.data)
                    $scope.profile1 = response.data.profile1;
                    $scope.profile2 = response.data.profile2;
                    $scope.profile3 = response.data.profile3;
                    $scope.profile4 = response.data.profile4;
                    $scope.profile5 = response.data.profile5;
                    $scope.contentLoading = false;
                });
        };

        $scope.getSettings = function() {
            $scope.loadingSettings=true;
            scDashboardFactory.getSettings()
                .then(function success(response) {
                    $scope.dataSettings = response.data;
                    $scope.updateBackground();
                    $log.info('dataSettings:', response.data);
                    $scope.loadingSettings=false;
                });
        }

        $scope.getProfileNames();
        $scope.loadList();
        $scope.getSettings();


        $scope.updateSettings = function() {
            scDashboardFactory.setSettings($scope.dataSettings)
                .then(function success(response) {
                    $log.info('updateSettings successful!');
                });
        }


        //-- BACKGROUND STUFF -----------------------

        $scope.customImgMsg = function() {
            $log.info('customImgMsg called');
            modalFactory.okModal(1, $translate.instant('MSG.CUSTOMIMG_CC'));
        }


        $scope.updateBackground = function() {
            if ($scope.dataSettings.hasCustomImage==true) {
                $log.info('custom background');
                var sessionPath = AuthFactory.sessionId + '/' + AuthFactory.activeDevice.deviceId;
                $scope.imgBack= API.rooturl + 'files/background/' + sessionPath+'?nocache='+$scope.backgroundCounter;
            } else {
                $log.info('standard background');
                $scope.imgBack = '../images/start-control.png';}
        }


        $scope.deleteBackground = function() {
            var sessionPath = AuthFactory.sessionId + '/' + AuthFactory.activeDevice.deviceId;
            $log.info('DELETE BACKGROUND sess=' + sessionPath);

            $http({
                method: 'DELETE',
                url: API.rooturl + 'files/background-sc/' + sessionPath
            }).then(function(result){
                $scope.dataSettings.hasCustomImage=false;
                $scope.updateSettings();
                $scope.updateBackground();
                $state.reload();
            });
        }

        $scope.uploadBackground = function(file, errFiles) {
            $scope.f = file;
            if ($scope.f != null && $scope.f.size != null) {
                $log.info('Upload background the entry with file size=', $scope.f.size);
            }
            $scope.errFile = errFiles && errFiles[0];

            if ($scope.f==null || $scope.f.size==null || $scope.f.size>999999)
            {
                modalFactory.okModal(1, $translate.instant('MSG.CUSTOMIMG_TOO_LARGE'));
                return;
            }

            if (file) {
                Metronic.blockUI({animate: true});
                var sessionPath = AuthFactory.sessionId + '/' + AuthFactory.activeDevice.deviceId;

                file.upload = Upload.upload({
                    url: API.rooturl + 'files/background-sc/' + sessionPath,
                    method: 'POST',
                    headers: {
                        'Content-Type': 'multipart/form-data;'
                    },
                    data: {file:file}
                });

                file.upload.then(function(response) {
                    $timeout(function() {
                        file.result = response.data;
                        $log.info('Upload status SC_A' + response.data);
                        $scope.dataSettings.hasCustomImage = true;
                        $scope.updateSettings();
                        $scope.imgBack = API.rooturl + 'files/background-sc/' + sessionPath;
                        Metronic.unblockUI();
                        $scope.backgroundCounter = moment().format('X');
                        $scope.updateBackground();
                        //$state.reload();
                    });
                }, function(response) {
                    if (response.status > 0) {
                        $scope.dataSettings.hasCustomImage = false;
                        $scope.updateSettings();
                        $scope.errorMsg = response.status + ': ' + response.data;
                    }
                    Metronic.unblockUI();
                    $log.info('Upload status SC_B');
                    modalFactory.okModal(3, $translate.instant('MSG.IMPORTFAILED'));
                }, function(evt) {
                    file.progress = Math.min(100, parseInt(100.0 *
                        evt.loaded / evt.total));
                });
            }
        };
        // EOF BACKGROUND PICTURE STUFF ------------------------------------------------------------------


        $scope.$watch('profileId', function(nv) {
            $log.info('new profileId', nv);
            $scope.loadList();
        });

    });