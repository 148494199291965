angular.module('ChicoApp').factory('cbUsageFactory', function(
  $http,
  apiFunctions,
  $log,
  API) {

  var factory = {};

    var apifields_usage = [
        'isUsageDisabled',
        'isWebFilterActive',
        'isAppFilterActive',
        'hideUsageAny',
        'hideUsageRuntimePrograms',
        'hideUsageRuntimeWebsites',
        'hideUsageHistoryPrograms',
        'hideUsageHistoryWebsites',
        'hideUsageHistoryBlocked',
        'hideUsageHistorySystem',
        'roaming',
        'showAlwaysEntries'
    ];

    factory.getLimitEntriesInfo = function(entriesArr){
        var req = {
            method: 'POST',
            url: API.url + 'cbusage/limitentries',
            data: entriesArr
        };
        return $http(req);
    }

    //Get variable
  factory.getUsage = function(params, howMany) {
    var req = {
      method: 'GET',
      url: API.url + 'cbusage/' + params + '/' + howMany
    };
    return $http(req);
  };

  factory.getUsageByDates = function(startDate, stopDate, howMany) {
    var req = {
      method: 'GET',
      timeout: 30000,
      url: API.url + 'cbusage/bydatesall/' + startDate + '/' + stopDate + '/' + howMany
    };
    return $http(req);
  };

    factory.getTimesUsageByDates = function(startDate, stopDate, howMany) {
        var req = {
            method: 'GET',
            timeout: 30000,
            url: API.url + 'cbusage/timesbydatesall/' + startDate + '/' + stopDate + '/' + howMany
        };
        return $http(req);
    };

    factory.getRoamingGroupTimesUsageByDates = function(startDate, stopDate) {
        var req = {
            method: 'GET',
            timeout: 30000,
            url: API.url + 'action/roaminggrouptimes/allusers/' + startDate + '/' + stopDate
        };
        return $http(req);
    };

    // Das hier aktuelle Version für Roaming Gruppenmitglieder!
    factory.getRoamingGroupTimes = function(startDate, stopDate, group) {
        var req = {
            method: 'GET',
            timeout: 30000,
            url: API.url + 'cbusage/roaminggrouptimes/' + startDate + '/' + stopDate + '/' + group
        };
        return $http(req);
    }

    factory.getGroupDetails = function(startDate, stopDate, group) {
        var req = {
            method: 'GET',
            timeout: 30000,
            url: API.url + 'cbusage/groupdetails/' + startDate + '/' + stopDate + '/' + group
        };
        return $http(req);
    }

    factory.getDeviceDetails = function(startDate, stopDate) {
        var req = {
            method: 'GET',
            timeout: 30000,
            url: API.url + 'cbusage/devicedetails/' + startDate + '/' + stopDate
        };
        return $http(req);
    }


    factory.getHistoryUsageByDates = function(startDate, stopDate, howMany) {
        var req = {
            method: 'GET',
            timeout: 30000,
            url: API.url + 'cbusage/historybydatesall/' + startDate + '/' + stopDate + '/' + howMany
        };
        return $http(req);
    };

    factory.deleteHistory = function() {
        var req = {
            method: 'DELETE',
            timeout: 60000,
            url: API.url + 'cbusage/history'
        };
        return $http(req);
    }


    factory.getUsageTimes = function(startDate, stopDate) {
        var req = {
            method: 'GET',
            timeout: 30000,
            url: API.url + 'cbusage/times/' + startDate + '/' + stopDate
        };
        return $http(req);
    };

    factory.getRoamingTimes = function(startDate,stopDate,roamingGroup)
    {
        var req = {
            method: 'GET',
            url: apiFunctions.get_cc_base_url() +  'cbusage/timesroaming/' + startDate + '/' + stopDate + '/' + roamingGroup
        };
        return $http(req);
    }


    factory.getUsageHistory = function(startDate, stopDate, howMany) {
        var req = {
            method: 'GET',
            url: API.url + 'cbusage/history/' + startDate + '/' + stopDate + '/' + howMany
        };
        return $http(req);
    };




    factory.delUsageAction = function() {
        var req = {
            method: 'GET',
            url: API.url + 'action/delusage'
        };
        return $http(req);
    }

  factory.getUsageByDatesPDF = function(startDate, stopDate, howMany) {
    var req = {
      method: 'GET',
      timeout: 30000,
      url: API.url + 'cbusage/bydatesall/' + startDate + '/' + stopDate + '/' + howMany + "?format=pdf"
    };
    return $http(req);
  };

    factory.hasRoaming = function()   {
        $log.info('gasRoaming entered');
        var req = {
            method: 'GET',
            url: API.url + 'cbsettings/hasRoaming'
        };
        return $http(req);
    };


  factory.deleteUsage = function()   {
    $log.info('delete usage entered');
    var req = {
      method: 'DELETE',
      url: API.url + 'cbusage/delete'
    };
    return $http(req);
  };

  factory.getAgeDetail = function(myentry) {
    $log.info('getAgeDetail with entry=' + myentry + ' with url=' + API.url + 'ccusage/agedetail/' + myentry + '/de');
    var req = {
      method: 'GET',
      url: API.url + 'ccusage/agedetail/' + myentry + '/de'
    };
    $log.info('getAgeDetail return=' , $http(req));
    return $http(req);
  };


    factory.getUsageSettings = function() {
        var params = apiFunctions.obj_to_url_params(apifields_usage);
        $log.info('url is:' + apiFunctions.get_cc_base_url() + 'cbsettings/' + params);
        var req = {
            method: 'GET',
            url: API.url + 'cbsettings/' + params
        };
        $log.info('return val=' + $http(req));
        return $http(req);
    };

    factory.setUsageSettings = function(keyvalue_arr) {
        var req = {
            method: 'POST',
            url: API.url + 'cbsettings',
            data: keyvalue_arr
        };
        return $http(req);
    };


    factory.getLimitinfo = function(entry) {
        var req = {
            method: 'GET',
            url: API.url + 'cbusage/limitinfo/' + entry
        };
        return $http(req);
    }


    factory.getLimitinfoByKind = function(entry) {
        var req = {
            method: 'GET',
            url: API.url + 'cbusage/limitinfokind/' + entry
        };
        return $http(req);
    }


    factory.getWeekStats = function(period) {
        var req = {
            method: 'GET',
            url: API.url + 'cbusage/weekstats/' + period
        };
        return $http(req);
    }

    // NEW STUFF -----------------------------------------------------

    factory.getChartDayApi = function(thisDay) {
        var req = {
            method: 'GET',
            url: API.url + 'cbusage/chartday/' + thisDay
        };
        return $http(req);
    };

    factory.getChartDayRoamingApi = function(thisDay,roaminggroup) {
        var req = {
            method: 'GET',
            url: API.url + 'cbusage/chartdayroaming/' + thisDay + '/' + roaminggroup
        };
        return $http(req);
    };

    factory.getChartStatsApi = function(fromDate,toDate) {
        var req = {
            method: 'GET',
            url: API.url + 'cbusage/chartstats/' + fromDate + '/' + toDate
        };
        return $http(req);
    };

    factory.getChartStatsRoamingApi = function(fromDate,toDate,roaminggroup) {
        var req = {
            method: 'GET',
            url: API.url + 'cbusage/chartstatsroaming/' + fromDate + '/' + toDate + '/' + roaminggroup
        };
        return $http(req);
    };

    factory.getTimesUsageByHour = function(thisDate, thisHour) {
        var req = {
            method: 'GET',
            timeout: 30000,
            url: API.url + 'cbusage/timesbyhour/' + thisDate+ '/' + thisHour
        };
        return $http(req);
    };

    factory.getHistoryUsageByHour = function(thisDate, thisHour) {
        var req = {
            method: 'GET',
            timeout: 30000,
            url: API.url + 'cbusage/historybyhour/' + thisDate+ '/' + thisHour
        };
        return $http(req);
    };

    factory.getRoamingMembers = function(roamingGroup) {
        roamingGroup = roamingGroup.replace('F','');
        var req = {
            method: 'GET',
            url: API.url + '/info/getroamingusersforgroup/' + roamingGroup
        };
        return $http(req);
    };

    factory.getAllGroupEntries = function(group) {
        var req = {
            method: 'GET',
            url: API.url + '/cbusage/getallgroupentries/' + group
        };
        return $http(req);
    };

    factory.getAllRoamingGroupEntries = function(group) {
        var req = {
            method: 'GET',
            url: API.url + '/cbusage/getallroaminggroupentries/' + group
        };
        return $http(req);
    };

    factory.getTotalTimesDay = function(todayDate) {
        var req = {
            method: 'GET',
            url: API.url + 'cbusage/totaltimesday/' + todayDate
        };
        return $http(req);
    }

    factory.getTotalTimesPeriod = function(fromDate, toDate) {
        var req = {
            method: 'GET',
            url: API.url + 'cbusage/totaltimesperiod/' + fromDate + '/' + toDate
        };
        return $http(req);
    }

    return factory;

});
