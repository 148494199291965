'use strict';
angular.module('ChicoApp.Info', [])
    .config(function($stateProvider) {
        $stateProvider.state('logbook', {
            url: '/logbook',
            controller: 'LogbookController',
            templateUrl: 'templates/info/templates/logbook.html',
        })

        .state('appinfo', {
                url: '/appinfo',
                controller: 'AppInfoController',
                templateUrl: 'templates/info/templates/appinfo.html',
            });


           //USE THIS SCHEMA TO ADD OTHER STUFF
           /* .state('mylicences', {
                url: '/mylicences',
                controller: 'LicenceController',
                templateUrl: 'templates/licence/templates/mylicences.html',
            })*/


    })
    .run(function() {});
