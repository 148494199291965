'use strict';
angular.module('ChicoApp.CB-Timelimits', [])
    .config(function($stateProvider) {
        $stateProvider.state('cb-timelimits', {
            url: '/cb-timelimits',
            controller: 'CB-TimelimitsController',
            templateUrl: 'templates/chicobrowser/cb_timelimits/templates/timelimits.html'
        });
    })
    .run(function() {});
