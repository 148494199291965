'use strict';
angular.module('ChicoApp').config(function($stateProvider) {
    $stateProvider.state('cb-appfilter-v4', {
        url: '/cb-appfilter-v4',
        views: {
            "@": {
                component: 'appFilterV4'
            }
        },
        params: {
            // Current page in appList
            appListPage: 1
        }
    });
});