'use strict';
angular.module('ChicoApp.Internal')

    .controller('KisikatController', function(
        $rootScope,
        $scope,
        $translate,
        $state,
        $http,
        $window,
        $timeout,
        DeviceFactory,
        AuthFactory,
        cbListsFactory,
        DTOptionsBuilder,
        DTColumnDefBuilder,
        scgDataTableFactory,
        modalFactory,
        API,
        apiFunctions,
        licenceFactory,
        scgLicenceFactory,
        $log) {


        $log.info('INTERNAL - Kisikat Controller');

        $scope.description = "";
        $scope.remark = "";
        $scope.catId = -1;
        $scope.ageId = -1;
        $scope.webshotUrl = "";

        $scope.ageModel = "";
        $scope.ageVals = [{ name: '0+', id: 0 }, { name: '6+', id: 6 },{ name: '12+', id: 12 },{ name: '16+', id: 16 },{ name: '18+', id: 18 },{ name: '12', id: 77 } ];

        $scope.catModel = "";
        $scope.catVals = [
            { id: 11, name: "Chat/Dating" },
            { id: 12, name: "E-Mail/Webmail"},
            { id: 13, name: "Sex/Pornografie"},
            { id: 14, name: "Gewalt/Waffen"},
            { id: 15, name: "Proxy/Spyware/Hacking"},
            { id: 16, name: "Spiele"},
            { id: 17, name: "Dubiose Seite/Abzocke"},
            { id: 18, name: "Drogen/Alkohol"},
            { id: 19, name: "AdServer (Werbung)"},
            { id: 20, name: "Firma/Institution"},
            { id: 21, name: "Suchmaschine"},
            { id: 22, name: "Lernen/Nachschlagen"},
            { id: 23, name: "Video/Fotos/Bilder"},
            { id: 24, name: "Musik/Audio/Radio"},
            { id: 25, name: "Shopping/Reisen"},
            { id: 26, name: "Nachrichten/Magazine"},
            { id: 27, name: "Social Media"},
            { id: 28, name: "Office/Finanzen"},
            { id: 29, name: "Sport/Unterhaltung"},
            { id: 30, name: "Verkehr/Wetter"},
            { id: 31, name: "System/Treiber"},
            { id: 35, name: "Software/Downloads"},
            { id: 44, name: "URLWhitelist Kinder"},
            { id: 45, name: "URLWhitelist CDNs"},
            { id: 46, name: "URL Whitelist"},
            { id: 47, name: "Sonstige"},
            { id: 48, name: "Fehlalarme Domains"}
            ];





        $scope.getEntry = function(entry) {

            $log.info('KISIKAT GET ENTRY='+ entry);
            $scope.webshotUrl = ""; //first clear webshot

            var obj = '{"url" : "' + entry + '"}';
            var req = {
                method: 'POST',
                data: obj,
                url: 'http://dev.salfeld.net/api/internal/kisikat/lookup'
            };
            //$http(req);
            $log.info('KISIKAT=' , $http(req));

            $http(req).success(function(data) {
                $scope.description = data.description;
                $scope.remark = data.source;
                $scope.url = data.URL;
                $scope.selectedAge = data.age;
                $scope.selectedCat = data.catID;

                //display the screenshot
                $scope.webshotUrl = "http://api.screenshotmachine.com/?key=b46889&dimension=800x600&format=png&url=http://" + $scope.url;

                $log.info('KISIKAT success =', data);
            });
        }       //eof getEntry




        $scope.checkList = function() {
            $log.info('KISIKAT checklist' , $scope.urllist);

            var textarea = $scope.urllist;
            var obj = textarea.split(/\n|\r/);
            var req = {
                method: 'POST',
                data: obj,
                url: 'http://dev.salfeld.net/api/internal/kisikat/checkisnotinlist'
            };

            $http(req).success(function(data) {
                    $scope.urllist = data.join("\n");
            });
        } //eof checkList








        //eof of all controller functions
        ;
    });


