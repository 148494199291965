angular.module('ChicoApp').factory('ccTimeLimitsFactory', function(
    $http,
    API,
    apiFunctions,
    $log) {

  var factory = {};

  var apifields_pc = [
      'isTimeLimitActive',
      'timeLimitDay',
      'timeLimitWeek',
      'timeLimitMonth',
      'isWeekdayLimitActive',
      'timeLimitMonday',
      'timeLimitTuesday',
      'timeLimitWednesday',
      'timeLimitThursday',
      'timeLimitFriday',
      'timeLimitSaturday',
      'timeLimitSunday',
      'blockTimesPC',
      'extension',
      'noExtensionInBlockTime',
      'infotancount',
      'forceBreakAfter',
      'forceBreakDuration',
      'isForcedBreak',
      'noExtensionInPause',
      'lockDate',
      'unlockDate',
      'OneTimeLockLevel'
  ];

  var apifields_internet = [
      'isInternetTimeLimitActive',
      'internetTimeLimitDay',
      'internetTimeLimitWeek',
      'internetTimeLimitMonth',
      'isInternetWeekdaysLimitActive',
      'internetTimeLimitMonday',
      'internetTimeLimitTuesday',
      'internetTimeLimitWednesday',
      'internetTimeLimitThursday',
      'internetTimeLimitFriday',
      'internetTimeLimitSaturday',
      'internetTimeLimitSunday',
      'blockTimesInternetPC',
      'blockedTimesInternet'

  ];

  var apifields_special = [
      'isForcedBreak',
      'forceBreakAfter',
      'forceBreakDuration',
      'lockDate',
      'unlockDate',
      'OneTimeLockLevel'
  ];




    var apifields_allusers = [
      'generalPCLimitLevel',
      'generalForcedBreaksLevel'
  ];

  var apifields_pc_param       = apiFunctions.obj_to_url_params(apifields_pc);
  var apifields_internet_param = apiFunctions.obj_to_url_params(apifields_internet);
  var apifields_special_param  = apiFunctions.obj_to_url_params(apifields_special);
  var apifields_allusers_param = apiFunctions.obj_to_url_params(apifields_allusers);

  factory.getPcSettings = function() {
    return factory.getSettingsByParams(apifields_pc_param);
  };

  factory.getInternetSettings = function() {
    return factory.getSettingsByParams(apifields_internet_param);
  };

  factory.getSpecialSettings = function() {
    return factory.getSettingsByParams(apifields_special_param);
  };

  factory.getAllUsersSettings = function() {
    return factory.getAllUsersSettingsByParams(apifields_allusers_param);
  };

  factory.getSettingsByParams = function(params) {
    var req = {
      method: 'GET',
      url: apiFunctions.get_cc_base_url() + 'ccsettings' + params
    };
    return $http(req);
  };

  factory.getAllUsersSettingsByParams = function(params) {
    $log.info('Allusers param' , params);
    var req = {
      method: 'GET',
      url: API.url + 'allusers/ccsettings' + params
    };
    return $http(req);
  };

  factory.getRoaming = function()
  {
      var req = {
          method: 'GET',
          url: apiFunctions.get_cc_base_url() + 'ccsettings/hasRoaming'
      };
      return $http(req);
  }


  factory.saveSettings = function(settings_scope) {
    factory.setSettings(settings_scope);
  };

  factory.setSetting = function(obj) {
    var req = {
      method: 'POST',
      url: apiFunctions.get_cc_base_url() + 'ccsettings',
      data: obj
    };
    return $http(req);
  };

  factory.setAllUsersSetting = function(obj) {
    $log.info('setAllUsersSetting DATA', obj);
    var req = {
      method: 'POST',
      url: API.url + 'allusers/ccsettings',
      data: obj
    };
    return $http(req);
  };

  factory.setSettings = function(keyvalue_arr) {
    var req = {
      method: 'POST',
      url: apiFunctions.get_cc_base_url() + 'ccsettings',
      data: keyvalue_arr
    };
    return $http(req);
  };

  factory.resetCountdownTime = function() {
    var req = {
      method: 'POST',
      url: apiFunctions.get_cc_action_url() + 'ccsettings/countdowntime/reset',
      data: {}
    };
    return $http(req);
  };

  return factory;
});
