/**
 * Dropdown menu to select the user or device.
 * Consists of two dropdown menus: The default one and a native select-element for mobile users
 */
angular.module('ChicoApp.Shared').component('deviceDropdown', {
  templateUrl: 'templates/shared/header/deviceDropdown/deviceDropdown.component.html',
  controller: DeviceDropdownController
})

function DeviceDropdownController(
    $scope,
    $element,
    $state,
    $http,
    $cookies,
    $translate,
    $log,
    API,
    apiFunctions,
    AuthFactory,
    sseFactory,
    DeviceFactory,
    scgSharedFactory) {


  $scope.mobileDropdownTopPos = 0;
  $scope.mobileDropdownHeight = 0;

  $scope.isTouchDevice = false;
  var hasTouchscreen = 'ontouchstart' in window;

  if (hasTouchscreen === true) {
    $scope.isTouchDevice = true;
  }

  // Media query to match all non-mobile-devices
  var mediaQueryResult = window.matchMedia('(min-width: 415px) and (orientation: portrait), (min-height: 415px) and (orientation: landscape)').matches;

  // var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  // Uncomment line above and add the following to disable mobile dropdown on iOS: && !iOS

  // Set to false to completely disable the mobile dropdown
  $scope.useMobileDropdown = (!mediaQueryResult && $scope.isTouchDevice);
  

  /**
   * Stores items which are displayed in mobile dropdown
   */
  $scope.mobileDropdownItems = [];

  // Watch nav that contains the cc-deviceselector element
  // If the "cc-deviceselector a" element was added to the DOM, set the size of the mobile dropdown
  $scope.$watch(function () {
      return document.querySelector('.nav').childNodes.length;
    }, function (newVal) {
      // Determine position and size of mobile dropdown if required (goal is to overlap the default dropdown)
      if ($scope.useMobileDropdown) {
      var deviceSelector = document.querySelector('#cc-deviceselector a');

      if (deviceSelector !== null) {
        $scope.mobileDropdownTopPos = function () { return document.querySelector('#cc-deviceselector a').getBoundingClientRect().top };
        $scope.mobileDropdownHeight = function () { return document.querySelector('#cc-deviceselector a').getBoundingClientRect().height };
      }
    }
  });

  this.$onInit = function () {
    $scope.checkForRoaming();
  }

  $scope.runSync = function() {
    $scope.syncRunning = true;
    sseFactory.sync()
        .then(function success(response) {
          if (response.data.success == true) {
            $log.info('SYNC SUCCESS');
            scgSharedFactory.needsSync = false;
            $scope.syncRunning = false;
            $scope.syncInfoModal($translate.instant('MSG.SYNCSUCCESS'));
          } else {
            $log.info('SYNC FAILED');
            $log.info('needsSync true G');
            scgSharedFactory.needsSync = true;
            $scope.syncRunning = false;
            $scope.syncInfoModal($translate.instant('MSG.SYNCFAILURE'));
          }
        });
  };

  /**
   * Fills mobileDropdownItems with items.
   * Converts available devices and users to a structure that is required for the mobile dropdown
   */
  $scope.buildMobileDropdownList = function () {
    /*
    * Object documentation
    * mobileDropdownItems contains items that correspond to a device or user.
    * General structure of the object:
    *   displayName:  Name as it should be displayed in dropdown
    *   id:           deviceId or username
    *   device:       Actual device object this device or user corresponds to
    *   type:         0 if device or 1 if user
    *   user:         Actual user object this user correspondes to (only available if object represents a user (type = 1))
    */
    $scope.mobileDropdownItems = [];

    // Add device if no user exists or add all users
    for (var i = 0; i < $scope.devices.length; i++) {
      if (!$scope.devices[i].hasOwnProperty('users') || $scope.devices[i].users.length === 0) {
        var deviceObj = {
          displayName: $scope.devices[i].deviceName,
          id: $scope.devices[i].deviceId,
          device: $scope.devices[i],
          type: 0
        };
  
        $scope.mobileDropdownItems.push(deviceObj);
      } else {
        for (var j = 0; j < $scope.devices[i].users.length; j++) {
          var userObj = {
            displayName: $scope.devices[i].users[j].username,
            id: $scope.devices[i].users[j].username,
            device: $scope.devices[i],
            user: $scope.devices[i].users[j],
            type: 1
          };

          $scope.mobileDropdownItems.push(userObj);
        }
      }
    }
  }

  /**
   * Called by ng-options to determine to which optgroup the given item belongs to.
   */
  $scope.groupMobileDropdownItems = function (item) {
    if (item.type === 1) {
      return item.device.deviceName;
    }
    // Else return nothing -> no group will be created
  }

  // Watch device list
  $scope.$watch(function () {
    return DeviceFactory.deviceList;
  }, function (newVal) {

    $scope.devices = newVal;
    $scope.buildMobileDropdownList();
    $scope.updateMobileDropdownSelection();
    $log.info('devices=', $scope.devices);
  });

  $scope.$watch(function() {
    return AuthFactory.userRole;
  }, function(newVal) {
      $scope.userRole = AuthFactory.userRole;
  });

  // Watch active device
  $scope.$watch(function () {
    return AuthFactory.activeDevice;
  }, function (newVal) {
    $scope.activeDevice = newVal;

    $scope.updateMobileDropdownSelection();
  });

  $scope.updateMobileDropdownSelection = function () {
    // Can only select item if an active device exists
    if ($scope.activeDevice === undefined) {
      return;
    }

    var selectedItem = $scope.mobileDropdownSelection;
    // If device is selected: Check if selected device is equal to active device
    // If user is selected: Check device of selected user is active device
    if (selectedItem === undefined || selectedItem === null || selectedItem.device !== $scope.activeDevice) {
      if ($scope.activeDevice.hasOwnProperty('users') && $scope.activeDevice.users.length > 0) {
        if ($scope.activeUser !== null) {
          // Find and select active user in mobileDropdownItems
          for (var i = 0; i < $scope.mobileDropdownItems.length; i++) {
            if ($scope.mobileDropdownItems[i].type === 1 && $scope.mobileDropdownItems[i].id === $scope.activeUser.username) {
              $scope.mobileDropdownSelection = $scope.mobileDropdownItems[i];
              break;
            }
          }
        }
      } else {
        // Find active device in mobileDropdownItems
        for (var j = 0; j < $scope.mobileDropdownItems.length; j++) {
          if ($scope.mobileDropdownItems[j].type === 0 && $scope.mobileDropdownItems[j].id === $scope.activeDevice.deviceId) {
            $scope.mobileDropdownSelection = $scope.mobileDropdownItems[j];
            break;
          }
        }
      }
    }
  }

  // Watch active device roaming
  $scope.$watch('activeDevice.deviceId', function (newVal, oldVal) {
    if (newVal != oldVal) {
      $scope.checkForRoaming();
      if ($scope.activeDevice.versionInfo != null) {
        scgSharedFactory.checkForUpdateNotice($scope.activeDevice.versionInfo);
      }
      $log.info('HEADWATCHER - new deviceId:', $scope.activeDevice);
    }
  });

  // Watch active user
  $scope.$watch(function() {
    return AuthFactory.activeUser;
  }, function(newVal) {
    $scope.activeUser = newVal;
  });

  // Watch for activeUser roaming
  $scope.$watch('activeUser.username', function (newVal, oldVal) {
    if (newVal != oldVal) {
      $scope.checkForRoaming();
    }
  });

  /**
   * Called if selection in mobile dropdown was changed.
   * Switches device or user
   */
  $scope.mobileDropdownChanged = function () {
    if ($scope.mobileDropdownSelection !== null) {
      var selectedItem = $scope.mobileDropdownSelection;
      var device = selectedItem.device;

      // selectedItem is device
      if (selectedItem.type === 0) {
        if (AuthFactory.activeDevice === null || AuthFactory.activeDevice.deviceId !== device.deviceId) {
          $scope.selectActiveDevice(device);
        }
      } else {
        // selectedItem is user
        var user = selectedItem.user;
        if (AuthFactory.activeUser === undefined || AuthFactory.activeUser === null
            || AuthFactory.activeUser.username !== user.username
            || AuthFactory.activeDevice.deviceId !== device.deviceId) {
          
          if ($scope.mobileUserDropdownSelection !== null) {
            $scope.selectActiveUser(user, device);
          }
        }
      }
    }
  }

  $scope.selectActiveDevice = function (device) {

    //skip if we are changing into cc mode
    if (device.users && device.users.length > 0) {
      return;
    }

    var prevDevice = AuthFactory.activeDevice;

    AuthFactory.setActiveDevice(device, null);

    if (device.deviceType === 40) {
      // if we are changing into sc mode (from cb mode)
      $state.go('sc-dashboard');
    } else if (!$cookies.get('user')) {
      // if this is not a reload but just a user triggered change
      if (prevDevice.deviceType !== 30 && device.deviceType === 30) {
        // if we are changing into cb mode, coming from sc mode
        goToCorrectCbDashboard();
      } else {
        goToCorrectCbDashboard();
      }
    } else {
      // if this is some mixup or reload (user from cc still in cookie but handing cb stuff here)
      goToCorrectCbDashboard();
    }
  };

  $scope.selectActiveUser = function (user, device) {
      AuthFactory.setActiveDevice(device, user);
      $state.go('cc-dashboard-v3'); //reload state
  };

  function goToCorrectCbDashboard() {
    if ($cookies.get('cb2') == "true") {
      $state.go('cb-dashboard');
    }
    else {
      $state.go('cb-dashboard-v3');
    }
  }

  $scope.checkForRoaming = function() {
    var thisUrl = API.url + 'cbsettings/roaming';

    if (AuthFactory.activeContext == 'cc') {
        thisUrl = apiFunctions.get_cc_base_url() +  'ccsettings?key=roaming';
        $log.info('HEADER checkForRoaming WE ARE CC');
    }

      var req = {
          method: 'GET',
          url: thisUrl
      };

      $http(req).success(function(data) {
        $log.info('HEADER checkForRoaming', data);
        if (data.roaming!=null && data.roaming != '' && data.romaing !='undefined') {
            scgSharedFactory.hasRoaming = true;
        } else {
            scgSharedFactory.hasRoaming = false;
        }
      });
  }
}
