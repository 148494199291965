'use strict';
angular.module('ChicoApp.Internal', [])
    .config(function($stateProvider) {

        $stateProvider
            .state('internal', {
                url: '/internal',
                template: '<ui-view/>',
                redirectTo: 'internal.kisikat'
            })
            .state('internal.kisikat', {
                url: '/internal-kisikat',
                templateUrl: 'templates/internal/templates/kisikat.html',
                controller: 'KisikatController'
            })

            .state('internal.lizenzen', {
                url: '/internal-lizenzen',
                templateUrl: 'templates/internal/templates/lizenzen.html',
                controller: 'LizenzenController'
            })


            .state('internal.test', {
                url: '/internal-test',
                templateUrl: 'templates/internal/internal.html',
                controller: 'InternalController'
            });
    })
    .run(function() {});

