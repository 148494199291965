'use strict';
angular.module('ChicoApp.Shared').controller('SCG-Modal-Faq-Controller', function(
    $scope,
    $modalInstance,
    $window,
    $translate,
    modal_settings) {

  $scope.modal_settings = modal_settings; //transfer settings to scope for use in template
  $window.ga('send', 'pageview', {page:'/modal-faq'});

  $scope.cancel = function() {
    $modalInstance.dismiss('cancel');
  };

  $scope.ok = function(return_val) {
    $modalInstance.close(return_val);
  };

});

