'use strict';
angular.module('ChicoApp.CC-Dashboard', [])
    .config(function($stateProvider) {
      $stateProvider.state('cc-dashboard', {
        url: '/cc-dashboard',
        controller: 'CC-DashboardController',
        templateUrl: 'templates/childcontrol/cc_dashboard/templates/dashboard.html',
        data: {
          //pageTitle: 'CC_HEADLINE.CHILDCONTROL',
          subTitle: ''
        }
      }).state('wizard', {
        url: '/cc-dashboard/wizard',
        controller: function() {},
        template: '<h1 ui-sref="wizard.step1">Wizard</h1> <div ui-view></div>',
        data: {
            //pageTitle: 'CC_HEADLINE.CHILDCONTROL',
            subTitle: ''
          }
      }).state('wizard.step1', {
        url: '/cc-dashboard/wizard/step1',
        controller: function() {},
        //Dierk: u.g. template wird in parent als "ui-view" reingerendert
        template: '<h1 ui-sref="wizard.step2">Wizard Step1</h1>',
        data: {
            //pageTitle: 'CC_HEADLINE.CHILDCONTROL',
            subTitle: ''
          }
      }).state('wizard.step2', {
        url: '/cc-dashboard/wizard/step2',
        controller: function() {},
        template: '<h1>Wizard foo Step2</h1>',
        data: {
            //pageTitle: 'CC_HEADLINE.CHILDCONTROL',
            subTitle: ''
          }
      }).state('wizard.step3', {
        url: '/cc-dashboard/wizard/step2',
        controller: function() {},
        template: '<h1>Wizard Step3</h1>',
        data: {
            //pageTitle: 'CC_HEADLINE.CHILDCONTROL',
            subTitle: ''
          }
      });
    });
