'use strict';
(function() {
    'use strict';

    angular
        .module('ChicoApp')
        .component('stepper', {
            bindings: {
                value: '=',
                min: '=',
                max: '=',
                step: '=?'
              },
            controller: ComponentController,
            template: '<form name="stepper" class="input-group">' +
                        '<div class="spinner-buttons input-group-btn">' +
                            '<button type="button" ' +
                                    'class="btn spinner-down grey" ' +
                                    'ng-click="$ctrl.decrementValue()" ' +
                                    'tabindex="-1">' +
                                '<i class="fa fa-minus"></i>' +
                            '</button>' +
                        '</div>' +
                        '<input type="number"' +
                               'name="value"' +
                               'ng-model="$ctrl.value" ' +
                               'ng-blur="$ctrl.validateInput(stepper.value.$viewValue)"' +
                               'required ' +
                               'ng-model-options="{ updateOn: \'default blur\',debounce: {\'blur\': 0 }}"' +
                               //'min="{{$ctrl.min}}" ' +
                               'max="{{$ctrl.max}}" ' +
                               //'step="{{$ctrl.step}}" ' +
                               'class="spinner-input form-control" />' +
                        '<div class="spinner-buttons input-group-btn">' +
                            '<button type="button" ' +
                                    'class="btn spinner-up grey" ' +
                                    'ng-click="$ctrl.incrementValue()" ' +
                                    'tabindex="-1">' +
                                '<i class="fa fa-plus"></i>' +
                            '</button>' +
                        '</div>' +
                    '</form>'
          });

    /* @ngInject */
    function ComponentController() {
      var $ctrl = this;
      $ctrl.$onInit = function() {
        if (angular.isUndefined($ctrl.step)) {
          $ctrl.step = 1;
        }
      };

      $ctrl.incrementValue = function() {
        var value = $ctrl.value;
        value += $ctrl.step;
        if (value <= $ctrl.max) {
          $ctrl.value = value;
        }else {
          $ctrl.value = $ctrl.max;
        }

      };

      $ctrl.decrementValue = function() {
        var value = $ctrl.value;
        value -= $ctrl.step;
        if (value >= $ctrl.min) {
          $ctrl.value = value;
        }else {
          $ctrl.value = $ctrl.min;
        }

      };

      $ctrl.validateInput = function(value) {
        if (value > $ctrl.max) {
          $ctrl.value = $ctrl.max;
        }
        if (value < $ctrl.min) {
         $ctrl.value = $ctrl.min;
        }
      };
    }

  })();
