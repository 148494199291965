/*thisisaplaceholderforAPI-Factories*/
angular.module('ChicoApp').factory('CCSettings', function(
    $http,
    $cookies,
    $log,
    API,
    _
  ) {

  var settings = {};

  var apikeys = [
      'generalWarn1',
      'generalWarn2',
      'generalWarn3',
      'generalWarn5',
      'generalWarn3Value',
      'generalWarn5Value',
      'imgBackgroundURL',
      'imgBackgroundColor',
      'isGeneralNoDeleteExtensionAuto',
      'isGeneralSyncInternetDateTime',
      'isGeneralStealthMode',
      'isGeneralAutoHideWarnings',
      'isGeneralBeepForWarnings',
      'generalPCLimitLevel',
      'generalInternetLimitLevel',
      'generalPauseTimersLevel',
      'generalVacationLevel',
      'generalDeleteLogsLevel',
      'generalCountdownLevel',
      'mydropdown',
      'SecLevel',
      'isSecDisableSafeBoot',
      'isSecDisableSysRestore',
      'isSecDisableFastUserSwitching',
      'generalPCLimitLevel',
      'generalInternetLimitLevel',
      'generalForcedBreaksLevel',
      'generalPauseTimersLevel',
      'generalVacationLevel',
      'generalDeleteLogsLevel',
      'standardIconNo',
      'isGeneralNoNewUsers',
      'noAudioStop',
      'cloneMode'
  ];

  var apikeys_param = '';

  _.each(apikeys, function(key, index) {
    if (index === 0) {
      apikeys_param += '?';
    }else {
      apikeys_param += '&';
    }
    apikeys_param += 'key=' + key;
  });

  settings.getSettings = function() {
    $log.info('INSIDE GET SETTINGS');
    var req = {
      method: 'GET',
      url: API.url + 'allusers/ccsettings' + apikeys_param
    };
    return $http(req)
            .then(function success(response) {
              return response;
            }, function error(err) {
              $log.info('error while getting setting');
              return err;
            });
  };

  settings.setSetting = function(obj) {
    var req = {
      method: 'POST',
      url: API.url + 'allusers/ccsettings/',
      data: obj
    };
    return $http(req);
  };

  settings.setSettingOld = function(key, value) {
    var req = {
      method: 'POST',
      url: API.url + 'allusers/ccsettings/',
      data: [{
        'key': key,
        'value': value
      }]
    };
    return $http(req)
            .then(function success(response) {
              return response;
            }, function error(err) {
              return err;
            });
  };

  settings.setSettings = function(keyvalue_arr) {
    var req = {
      method: 'POST',
      url: API.url + 'user123/ccsettings/',
      data: keyvalue_arr
    };
    return $http(req)
            .then(function success(response) {
              return response;
            }, function error(err) {
              return err;
            });
  };

  return settings;
});
