'use strict';
angular.module('ChicoApp').config(function($stateProvider) {
    $stateProvider.state('web-import-export', {
        url: '/web-import-export',
        views: {
            "@": {
                component: 'webImportExport'
            }
        },
        params: {
            selectModel : null
        },
        resolve: {
            selectModel: function($transition$) {
                return $transition$.params().selectModel;
            }
        }
    });
});
