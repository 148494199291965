angular.module('ChicoApp').factory('appInfoFactory', function (
    $http,
    $log,
    $translate,
    scgSharedFactory,
    API) {

    var factory = {};

    factory.dummySession = "sess887783995a9d401681c6567398227aac";


    factory.getWebIconUrl = function (domain) {
        return API.rooturl + "files/icon/web/" + factory.dummySession + "/" + domain + ".png";
    };

    factory.getAppIconUrl = function (pkgname) {
        return API.rooturl + "files/icon/apps/" + factory.dummySession + "/" + pkgname + ".png";
    };

    factory.getExeIconUrl = function (exename) {
        if (exename == "checked") {
            return API.rooturl + "files/icon/apps/" + factory.dummySession + "/checked.png";
        }
        var exepng = (exename || "").replace(".exe", ".png");
        return API.rooturl + "files/icon/" + factory.dummySession + "/" + exepng;
    };

    // AppDetails is kind 102 in backend and only for Android Apps
    factory.getAppDetails = function (entry) {
        var lang = "en"; //default
        if ($translate.use() == 'de_DE') lang = "de";
        var obj = {"url": entry, "lang": lang};
        var req = {
            method: 'POST',
            url: API.url + 'action/lookupkkapp',
            data: obj
        };
        return $http(req);
    };

    // WebDetails is for URLs only
    factory.getWebDetails = function (entry) {
        var lang = "en"; //default
        $log.info('dv getWebDetails translate.use=', $translate.use());
        var isGerman = scgSharedFactory.isGerman();
        $log.info('dv getWebDetails isGerman=', isGerman);


        if ($translate.use() == 'de_DE') lang = "de";
        var obj = {"url": entry, "lang": lang};
        var req = {
            method: 'POST',
            url: API.url + 'action/lookupkkurl',
            data: obj
        };
        return $http(req);
    };

    // PrgDetails is for EXE only
    factory.getPrgDetails = function (entry) {
        var lang = "en"; //default
        if ($translate.use() == 'de_DE') lang = "de";
        var obj = {"url": entry, "lang": lang};
        var req = {
            method: 'POST',
            url: API.url + 'action/lookupkkexe',
            data: obj
        };
        return $http(req);
    };




    return factory;
});
