'use strict';
angular.module('ChicoApp.CC-Screenshots')
    .controller('CC-ScreenshotController', function(
        $rootScope,
        $scope,
        $state,
        $modal,
        $http,
        $timeout,
        $translate,
        $cookies,
        $log,
        _,
        API,
        modalFactory,
        sseFactory,
        AuthFactory,
        ccScreenShotFactory,
        scgGlobalSpinnerFactory,
        scgSharedFactory) {

      $scope.data = {};
      $scope.dataSettings = {};
      $scope.data.filenames = [];
      $scope.shotInterval = '10';
      $scope.data.paginatedFilenames = [];
      $scope.screenShotActive = false;
      $scope.pageNo = 1;
      $scope.hasData = 0;         //0= init status, 1=no data, 2=active and has screenshots, 3=active, but no screenshots (yet)
      $scope.screensLoading = true;
      $scope.imageList = null; //TODO
      $scope.tempLang = 'de';

      var pageLength = 9;
      var listLength = 0;
      var pageCount = 0;
      var listStartIndex = 0;
      var listStopIndex = listStartIndex + pageLength;

      $scope.pagination = {start: 0};

      $scope.getBaseUrl = function() {
        return 'https://portal.salfeld.net/api/screenshotsstorage/' + AuthFactory.sessionId + '/' + AuthFactory.activeDevice.deviceId;
      };

      //for screenshot not active JPG Template
      if ($cookies.get('language') == 'de_DE') {
        $scope.tempLang = 'de';
      } else {
        $scope.tempLang = 'en';
      }

      function setPaginationInfo() {
        listStartIndex = ($scope.pageNo - 1) * pageLength;
        listStopIndex = listStartIndex + pageLength;
        $scope.listStartIndex = listStartIndex;
        $scope.listStopIndex = listStopIndex;

        var paginatedArr = [];
        _.each($scope.data.filenames, function(filename, index) {
          $log.info('idx test', index);
          if (index >= listStartIndex && index < listStopIndex) {
            paginatedArr.push(filename);
          }
        });
        $scope.data.paginatedFilenames = paginatedArr;
      }

      $scope.goToPage = function(pageNo) {
        $scope.pageNo = pageNo;
        setPaginationInfo();
      };

      $scope.goToPrevPage = function() {
        $scope.pageNo = $scope.pageNo - 1;
        setPaginationInfo();
      };

      $scope.goToNextPage = function() {
        $scope.pageNo = $scope.pageNo + 1;
        setPaginationInfo();
      };

      $scope.showScreenshot = function(filename) {
        $log.info('open screenshot', filename);
        $scope.uriScreenshot = $scope.getBaseUrl() + '/' + filename;
        //$scope.$apply();
        $modal.open({
          animation: false,
          templateUrl: 'templates/childcontrol/cc_dashboard/templates/dashboard-modal-screenshot.html',
          windowTemplateUrl: 'templates/childcontrol/cc_dashboard/templates/dashboard-modal-window.html',
          scope: $scope
        });
      };

      // ------------------------------------------------------- DS PART ----------------------------------------------------
      // ------------------------------------------------------- ------------------------------------------------------------

      $scope.refresh = function() {
        $log.info('Refresh triggered');
        $scope.screensLoading = true;
        $timeout(function() {
          $scope.screensLoading = false;
          $state.reload();
        }, 12000);
        sseFactory.screenshotUpdate().then(function(response) {
          $log.info('screenshot update fired response=', response);
        });
      };

      $scope.processSettings = function() {
        //Is active for the selected user?
        if ($scope.dataSettings.screenshotactive === true) {
          $log.info('YES, active');
          $scope.screensLoading = true;
          ccScreenShotFactory.getScreenshotList().then(function(response) {
            var filenameArr = response.data;
            $scope.data.filenames = filenameArr;
            //calculate pages for pagination
            pageLength = 9;
            listLength = filenameArr.length;
            if (listLength == 0) {
              $scope.hasData = 3; //is active, but has no data yet
            }
            if (listLength > 0) {
              $scope.hasData = 2;
            }
            pageCount = Math.ceil(listLength / pageLength);
            var pages = [];
            for (var i = 1; i <= pageCount; i++) {
              pages.push(i);
            }
            $scope.data.pages = pages;
            setPaginationInfo();
            $scope.screensLoading = false;
          });
        } else {
          $scope.hasData = 1; //has no data (display not active screenshots)
        }
      };

      ccScreenShotFactory.getSettings()
            .then(function success(response) {
              $log.info('CC-ScreensHotFactory returned SUCCESS:', response.data);
              $scope.dataSettings = response.data;

              $scope.screensLoading = false;

              $scope.processSettings();

              //add watchers
              _.each($scope.dataSettings, function(v, k) {
                if (k === 'screenshotactive') {
                  return;
                }
                $scope.$watch('dataSettings.' + k, function(new_val, old_val) {
                  if (new_val != old_val) {
                    var changed_object = {};
                    changed_object[k] = new_val;
                    $log.info('Howdy, CC-screenshot cahnged object:',changed_object[v]);
                    ccScreenShotFactory.setSetting(changed_object);
                  }
                }, true);
              });

              //when screenshot switch has been changed from on to off
              $scope.$watch(function() {
                return $scope.dataSettings.screenshotactive;
              }, function(nV, oV) {
                if (angular.isDefined(nV) && nV != oV) {
                  if (nV == false && oV == true) {
                    $scope.displayDeleteMsg();
                  }else if (nV == true && oV == false) {
                    var changed_object = {screenshotactive: true};
                    //here screenshot switch was changed from on to off
                    $scope.screensLoading = true;
                    ccScreenShotFactory.setSetting(changed_object).then(function() {
                      if ($scope.isPCOnline != true) {
                        $log.info('current PC is NOT ONLINE');
                        $state.reload();
                        return;
                      }
                      $timeout(function() {
                        $scope.screensLoading = false;
                        $state.reload();
                      }, 10000);
                    });
                  }
                }
              });
            });

      $scope.formatShotDate = function(instr) {
        var r = instr;
        var i = instr.length;
        if (i == 23) {
          r = scgSharedFactory.formatDateTime(instr.substr(0,10) + ' ' + instr.substr(11,2) + ':'  + instr.substr(14,2) + ':'  + instr.substr(17,2));
        }
        return r;
      };

      $scope.saveShotInterval = function() {
        ccScreenShotFactory.setSetting;
      };

      $scope.lessMinutes = function() {
        var diff = 5;
        if ($scope.dataSettings.screenshotinterval >= diff + 5) {
          $scope.dataSettings.screenshotinterval =  parseInt($scope.dataSettings.screenshotinterval) - diff;
        }
      };

      $scope.moreMinutes = function() {
        var diff = 5;
        if ($scope.dataSettings.screenshotinterval <= 55) {
          $scope.dataSettings.screenshotinterval =  parseInt($scope.dataSettings.screenshotinterval) + diff;
        }
      };

      $scope.displayDeleteMsg = function() {
        $log.info('reset times goes here');
        modalFactory.infoModal.open({
          modal_settings: {
            title: $translate.instant('MSG.QUESTION'),
            message: $translate.instant('MSG.DELETE_SCREENSHOTS') + '<br>' + $translate.instant('MSG.SURE'),
            butOkLabel: $translate.instant('BUTTON.YES'),
            butCancelLabel: $translate.instant('BUTTON.NO'),
            iconNo: 2,
            refreshStateAfterClose: true //Dierk: bei true wurde controller gleich neu geladen werden
          },
          callbacks: {
            ok: function() {
              $log.info('yes, ok was click, he/she is sure');
              $scope.hasData = 0;
              ccScreenShotFactory.deleteScreenshots();
              var changed_object = {screenshotactive: false};
              ccScreenShotFactory.setSetting(changed_object).then(function() {
                $scope.dataSettings.screenshotactive = false;
              });
              $log.info('Ok, all done');
            },
            cancel: function() {
              $log.info('canceled!');
              $scope.dataSettings.screenshotactive = true;
            }
          }
        });
      };

      $scope.isPCOnline = false; //default
      var checkOnlineStatus = function() {
        sseFactory.isOnline()
                .then(function success(response) {
                  if (response.data.success == true) {
                    $scope.isPCOnline = true;
                    $log.info('SSE factory isonline returned:',$scope.isPCOnline);
                  }
                });
      };
      //initial api call
      checkOnlineStatus();

    });
