'use strict';
angular.module('ChicoApp.Shared').controller('CC-Modal-Error-Controller', function(
  $scope,
  $modalInstance,
  $translate,
  modal_settings,
  scgGlobalSpinnerFactory,
  AuthFactory,
  errorDisplayFactory) {

  $scope.errors = errorDisplayFactory.getErrorArray();

  $scope.removeNotification = function(error) {
    errorDisplayFactory.removeError(error);
    $scope.errors = errorDisplayFactory.getErrorArray();
    $scope.$apply();
  };

  $scope.removeNotifications = function() {
    errorDisplayFactory.clearErrors();
  };

  $scope.cancel = function() {
    $scope.removeNotifications();
      $modalInstance.dismiss('cancel');

    scgGlobalSpinnerFactory.blockUI();


    //logout user
      AuthFactory.logout();
  };

});
