angular.module('ChicoApp').factory('cbTimelimitsFactory', function(
    $http,
    API,
    apiFunctions,
    $log) {

    var factory = {};

    var apifields_browser = [
        'isTimeLimitActiveDevice',
        'timeLimitDayDevice',
        'timeLimitWeekDevice',
        'timeLimitMonthDevice',
        'isWeekDayLimitActiveDevice',
        'timeLimitMondayDevice',
        'timeLimitTuesdayDevice',
        'timeLimitWednesdayDevice',
        'timeLimitThursdayDevice',
        'timeLimitFridayDevice',
        'timeLimitSaturdayDevice',
        'timeLimitSundayDevice',
        'blockTimesDevice',
        'noExtensionInBlockTime',
        'hasRoaming',
        'infotancount',
        'noAppsInBlockTime',
        'isForcedBreak',
        'forceBreakAfter',
        'forceBreakDuration',
        'noExtensionInPause'


    ];

    var apifields_browser_param = apiFunctions.obj_to_url_params(apifields_browser);

    factory.getBrowserSettings = function() {
        return factory.getSettingsByParams(apifields_browser_param);
    };

    factory.getSettingsByParams = function(params) {
        var req = {
            method: 'GET',
            url: API.url + 'cbsettings' + params
        };
        return $http(req);
    };

    factory.saveSettings = function(settings_scope) {
        factory.setSettings(settings_scope);
    };

    factory.setSetting = function(obj) {
        $log.info('needsSync = TRUE!');
        var req = {
            method: 'POST',
            url: API.url + 'cbsettings',
            data: obj
        };
        return $http(req);
    };

    factory.setSettings = function(keyvalue_arr) {
        $log.info('needsSync = TRUE!');
        var req = {
            method: 'POST',
            url: API.url + 'cbsettings',
            data: keyvalue_arr
        };
        return $http(req);
    };

    factory.getAlwaysAllowedApps = function(obj) {
        var req = {
            method: 'GET',
            url: API.url + 'cbsync/allusers/alwaysallowedapps',
            data: obj
        };
        return $http(req);
    };

    // necessary for getting extension
    factory.getStatusInfo = function() {
        var req = {
            method: 'GET',
            url: API.url + 'cbsync/statusinfo'
        };
        return $http(req);
    }

    return factory;
});
