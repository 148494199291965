angular.module('ChicoApp').config(function(
    $stateProvider
) {
    /**
     * State for detailView.
     * If detailView is closed (cancel and save), the previous state is opened again including all stateParams
     * (stateParams of the previous state are not saved if the detailView page is reloaded).
     * 
     * Because the appIds and other state information is stored in the URL, there is a limit of appIds that can be passed.
     * The URL should not be longer than 2047 chars (for IE). This results in a limit of ~100 apps that can be passed simultaneously.
     * 
     * Usage to open detailView for an app or multiple apps:
     * $state.go('detail-view', { appIds: <appIds>, appType: <chicoEnums.AppTypeEnum>, allowAdd <boolean>, prevStateName: $state.current.name });
     */
    $stateProvider.state('detail-view', {
        url: '/detail-view/{appType:[0-9]+}?prevStateName&allowAdd&appIds', // cb-details cc-details
        views: {
            "@": {
                component: 'detailView'
            }
        },
        params: {
            /**
             * @type {string} Name of the state from which the app is coming from (previously opened state)
             */
            prevStateName: null,
            /**
             * @type {Object} Params of the previous state
             */
            prevStateParams: null,
            /**
             * @type {string[]} Array of IDs from the apps/programs/domains
             */
            appIds: null,
            /**
             * @type {chicoEnums.AppTypeEnum} Type of app which is displayed using detailView
             */
            appType: null,
            /**
             * Set to true if it should be possible to add items using the tags-input (only supported if appType is WEBSITE or WINDOWS_APP)
             * @type {boolean}
             */
            allowAdd: null
        },
        resolve: {
            prevStateName: function($state, $transition$) {
                return $transition$.params().prevStateName;
            },
            prevStateParams: function($state) {
                return angular.copy($state.params);
            },
            appIds: function($transition$) {
                // If only one pageId is passed, it is of type string instead of array
                if (!Array.isArray($transition$.params().appIds)) {
                    return [$transition$.params().appIds];
                } else {
                    return $transition$.params().appIds
                }
            },
            appType: function($transition$) {
                return parseInt($transition$.params().appType);
            },
            allowAdd: function($transition$) {
                if ($transition$.params().allowAdd === 'false' || $transition$.params().allowAdd === '0') {
                    return false;
                } else {
                    return true;
                }
            }
        }
    });
});