'use strict';
angular.module('ChicoApp.CC-Programs-V2', [])
    .config(function($stateProvider) {
      $stateProvider
        .state('cc-programs-v2', {
          url: '/cc-programs-v2',
          redirectTo: 'cc-programs-v2.tabs.allowed',
          controller: 'CC-ProgramsController-V2',
          templateUrl: 'templates/childcontrol/cc_programs_v2/templates/programs.html'
        })

          .state('cc-programs-v2.tabs', {
              url: '/tabs',
              templateUrl: 'templates/childcontrol/cc_programs_v2/templates/programs-tabs.html',
          })

          .state('cc-programs-v2.tabs.allowed', {
              url: '/allowed',
              controller: 'CC-Programs-Allowed-Controller-V2',
              templateUrl: 'templates/childcontrol/cc_programs_v2/templates/programs-allowed.html',
          })

          .state('cc-programs-v2.tabs.blocked', {
              url: '/blocked',
              controller: 'CC-Programs-Blocked-Controller-V2',
              templateUrl: 'templates/childcontrol/cc_programs_v2/templates/programs-generic-list.html',
          })

          .state('cc-programs-v2.tabs.timelimited', {
              url: '/timelimited',
              controller: 'CC-Programs-TimeLimited-Controller-V2',
              templateUrl: 'templates/childcontrol/cc_programs_v2/templates/programs-generic-list.html',
          })


          .state('cc-programs-v2.tabs.groups-v2', {
              url: '/groups-v2',
              controller: 'CC-Programs-Groups-V2-Controller-V2',
              templateUrl: 'templates/childcontrol/cc_programs_v2/templates/programs-groups-v2.html',
          })


          .state('cc-programs-v2.tabs.byage', {
              url: '/byage',
              controller: 'CC-Programs-ByAge-Controller',
              templateUrl: 'templates/childcontrol/cc_programs/templates/programs-byage.html',
          })


        /*

          .state('cc-programs.tabs.groups', {
            url: '/groups',
            controller: 'CC-Programs-Groups-Controller',
            templateUrl: 'templates/childcontrol/cc_programs_v2/templates/programs-groups.html',
          })

            .state('cc-programs.tabs.groups-v2', {
              url: '/groups-v2',
              controller: 'CC-Programs-Groups-V2-Controller',
              templateUrl: 'templates/childcontrol/cc_programs/templates/programs-groups-v2.html',
            })

          .state('cc-programs.tabs.byage', {
            url: '/byage',
            controller: 'CC-Programs-ByAge-Controller',
            templateUrl: 'templates/childcontrol/cc_programs_v2/templates/programs-byage.html',
          });
            */

    ;
    })
    .run(function() {});
