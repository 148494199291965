'use strict';
angular.module('ChicoApp.CB-Screenshots', [])
    .config(function($stateProvider) {
        $stateProvider.state('cb-screenshots', {
            url: '/cb-screenshots',
            controller: 'CB-ScreenshotController',
            templateUrl: 'templates/chicobrowser/cb_screenshots/templates/screenshots.html'
        });
    })
    .run(function() {});
