'use strict';
angular.module('ChicoApp.CB-Settings')

    .controller('CB-TANS', function(
        $scope,
        $state,
        $translate,
        $http,
        $modal,
        modalFactory,
        API,
        $timeout,
        $log,
        $window,
        _,
        cbListsFactory,
        scgGlobalSpinnerFactory,
        DTOptionsBuilder,
        DTColumnDefBuilder,
        scgSharedFactory
    ) {

            // Scope Variables

            $scope.data = {};
            $scope.noExtensionInBlockTime = false;
            $scope.hintHide = true;
            $scope.tableAllSelected = false;
            $scope.tanlist = [];
            $scope.printmode = false;


            // Functions on this Scope

            $scope.addTans = addTans;
            $scope.exportTan = exportTan;
            $scope.deleteSelectedTans = deleteSelectedTans;
            $scope.hintDone = hintDone;
            $scope.selectAllTans = selectAllTans;
            $scope.deleteTan =  deleteTan;
            $scope.togglePrintView = togglePrintView;
            $scope.print = print;

            // Options and Columns Definitions for Datatable

            $scope.dtOptions = DTOptionsBuilder.newOptions()
              .withDisplayLength(15)
              .withOption('bAutoWidth', false)
              .withOption('bFilter', false)  //Disable Search Input
              .withOption('bInfo', false)
              .withOption('pagingType', 'simple_numbers')
              .withOption('order', [[1, 'asc']])
              .withOption('responsive', true)
              .withLanguage({
                'emptyTable': $translate.instant('MISC.NODATA'),
                'oPaginate': {
                  'sNext':     '»',
                  'sPrevious': '«'
                }
              })
              .withBootstrap();

            $scope.dtColumnDefs = [
              DTColumnDefBuilder.newColumnDef(0).notSortable().withOption('sWidth','30px'),
              DTColumnDefBuilder.newColumnDef(1),
              DTColumnDefBuilder.newColumnDef(2),
              DTColumnDefBuilder.newColumnDef(3),
              DTColumnDefBuilder.newColumnDef(4).notSortable(),
            ];

            // Call init Function when Controller is initialized

            onInit();

            function onInit() {
              scgGlobalSpinnerFactory.blockUI();
              getSetting();
              cbListsFactory.getDetailedTAN()
             .then(function success(response) {
                $log.info('fetched ' + response.data.length + ' TANs from API');
                _.each(response.data, function(tan) {
                  if (tan.entry) { //if this is an object
                    $scope.tanlist.push({
                      selected: false,
                      data: tan
                    });
                  }else {
                    $scope.tanlist.push({
                      selected: false,
                      data: {
                        entry: tan
                      }
                    });
                  }

                });
                scgGlobalSpinnerFactory.unblockUI();
              });

              //Hint stuff, Add $scope.hintHide at top of page
              scgSharedFactory.getHint(93)
                              .then(function success(response) { //adjust hintID here
                                if (response.data.hint93) {                         //adjust hintID here
                                  $scope.hintHide = response.data.hint93;}        //adjust hintID here
                                else { //not assgined, set to false!
                                  $scope.hintHide = false;}
                              });

            }

            function togglePrintView() {

              $scope.printmode = !$scope.printmode;

              if ($scope.printmode) {
                // Hide columns that arent needed in printmode
                $scope.dtOptions.withDisplayLength(10000)
                                .withOption('paging',false);
                $scope.dtColumnDefs[0].visible = false;
                $scope.dtColumnDefs[4].visible = false;

              }else {
                // restore datatable default settings
                $scope.dtOptions.withDisplayLength(15)
                                .withOption('paging',true);
                $scope.dtColumnDefs[0].visible = true;
                $scope.dtColumnDefs[4].visible = true;
              }

            }

            function print() {
              $window.print();
            }

            function addTans() {
              $log.info('addTans in CB Context');
                $modal.open({
                  animation: false,
                  windowClass: 'cc-modal',
                  templateUrl: 'templates/chicobrowser/cb_settings/templates/modal-addTans.html',
                  controller: 'SCG-Modal-AddTans-CB-Controller',
                });
            }

            function exportTan() {
              var req = {
                  method: 'GET',
                  url: API.url + 'service/cb/exporttans'
                };

              $http(req).then(function success(response) {
                      var parsedTans = ['Tan;Minutes;User;Created;\r\n'];
                      var tans = response.data;
                      tans.forEach(function(tan) {
                          parsedTans.push('' + tan.TANNo + ';' + tan.Minutes + ';' + tan.User + ';' + tan.Created + ';\r\n');
                        });
                      var blob = new Blob(parsedTans, {
                          type: 'text/plain'
                        });
                      var filename = 'tan-export.txt';
                      if (window.navigator.msSaveBlob) {
                        window.navigator.msSaveBlob(blob, filename);
                      } else {
                        var e = document.createEvent('MouseEvents');
                        var a = document.createElement('a');
                        a.download = filename;
                        a.href = window.URL.createObjectURL(blob);
                        a.dataset.downloadurl = ['text/plain', a.download, a.href].join(':');
                        e.initMouseEvent('click', true, false, window,
                            0, 0, 0, 0, 0, false, false, false, false, 0, null);
                        a.dispatchEvent(e);
                      }

                      modalFactory.okModal(1,$translate.instant('MSG.TAN_EXPORT_DONE'));

                    });
            }

            function deleteTan($index, tanToDelete) {
              cbListsFactory.deleteTAN(tanToDelete.data.entry)
                               .then(function success() {
                                  // No need to reload state here, we can simply remove the tan from the
                                  // List if the request is successful
                                  $scope.tanlist = _.filter($scope.tanlist, function(tan) {
                                    return tanToDelete.data.entry != tan.data.entry;
                                  });
                                });
            }

            function deleteSelectedTans() {
              var tansToDelete = [];

              scgGlobalSpinnerFactory.blockUI();
              _.each($scope.tanlist, function(tan) {
                if (tan.selected == true) {
                  tansToDelete.push(tan);
                }
              });
              if (tansToDelete.length > 0) {
                var reqToDo = tansToDelete.length;
                scgSharedFactory.needsSync = true;
                var reqDone = 0;
                _.each(tansToDelete, function(tan) {
                  cbListsFactory.deleteTAN(tan.data.entry)
                              .then(function success() {
                                reqDone ++;
                                if (reqToDo == reqDone) {
                                  $state.go($state.current, {}, {reload: true});
                                }
                              });
                });

                scgGlobalSpinnerFactory.unblockUI();
              }
            }

            function selectAllTans(selectionStatus) {
              _.each($scope.tanlist, function(tan) {
                tan.selected = selectionStatus;
              });
            }



        function getSetting () {
            var req = {
                method: 'GET',
                url: API.url + 'cbsettings?key=noExtensionInBlockTime'
            };
            return $http(req)
                .then(function success(response) {
                        $scope.noExtensionInBlockTime=response.data.noExtensionInBlockTime;
                }, function error(err) {
                    $log.info('error while getting setting');
                    return err;
                });
        }


        function setSetting(obj) {
            var req = {
                method: 'POST',
                url: API.url + 'cbsettings/',
                data: obj
            };
            return $http(req);
        }





                function hintDone() {
              $scope.hintHide = true;
              scgSharedFactory.setHint(93);/*.then(function success() {
                $log.info('HINT DONE SUCCESS');
              });*/
            }


        $scope.$watch('noExtensionInBlockTime', function(new_val, old_val) {
            if (new_val != old_val) {
                var changed_object = {};
                changed_object['noExtensionInBlockTime'] = new_val;
                scgSharedFactory.needsSync = true;
                setSetting(changed_object);
            }
        }, true);



          });
