'use strict';
angular.module('ChicoApp.CC-Internet')

.controller('CC-InternetControllerBlocking-TimeLimits', function(
    $scope,
    $state,
    $translate,
    ccListsFactory,
    DTOptionsBuilder,
    DTColumnDefBuilder,
    scgDataTableFactory) {

  var modalStartKind = ccListsFactory.listKinds.list_kind_timelimited;

  $scope.tableHeaders = [$translate.instant('HEADER.DOMAINURLKEYWORD')];

  $scope.data = {};
  $scope.data.table_api_urls = [];
  $scope.data.table_api_keywords = [];

  $scope.bonusColumnEnabled = false;

  scgDataTableFactory.prepareScopeForTable($scope, modalStartKind);
  $scope.dtOptions = scgDataTableFactory.getDefaultDtOptions($scope);

  $scope.dtColumnDefs = scgDataTableFactory.getDefaultDtColumnDefs3Cols();

  $scope.reload = function() {
    $state.go($state.current, {}, {reload: true});
  };

  var loadList = function() {
    $scope.contentLoading = true;
    ccListsFactory.getDetailedByListFilter(modalStartKind)
            .then(function success(response) {
              $scope.fillInnerListFromApiList(response.data);
              $scope.data.table_api_urls = response.data;
              $scope.contentLoading = false;
            });
  };

  loadList();

});
