'use strict';
angular.module('ChicoApp.CC-Internet')
    .controller('CC-InternetController-Categories', function(
      $scope,
      ccListsFactory,
      ccInternetCategoryFactory,
      _,
      scgSharedFactory) {
      $scope.data = {};
      $scope.contentLoading = true;

      ccInternetCategoryFactory.getCategories()
            .then(function success(response) {
              var obj_arr = [];
              _.each(response.data, function(val, key) {
                var o = {};
                o.key = key;
                o.val = val;
                obj_arr.push(o);
              });
              var obj_arr_sorted = _.sortBy(obj_arr, function(o) {
                return o.key;
              });
              $scope.data = obj_arr_sorted;
              $scope.contentLoading = false;

              _.each($scope.data, function(obj, index) {
                $scope.$watch('data[' + index + ']', function(new_val, old_val) {
                  if (new_val != old_val) {
                    var changed_object = {};
                    changed_object[obj.key] = new_val.val;
                    scgSharedFactory.needsSync = true;
                    ccInternetCategoryFactory.setSetting(changed_object);
                  }
                }, true);
              });
            });

      $scope.setCategoryField = function(category) {
        // All categories unless the clicked category
        var filteredCategories = _.filter($scope.data, function(e) {
          if (e.key != category.key) {
            return e;
          }
        });

        // Push the new clicked category
        var temp = {$$hashKey: category.$$hashKey, key: category.key, val: !category.val};
        filteredCategories.push(temp);

        var obj_arr_sorted = _.sortBy(filteredCategories, function(o) {
          return o.key;
        });

        $scope.data = obj_arr_sorted;
      };

    });
