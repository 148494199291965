'use strict';
angular.module('ChicoApp.CC-Programs')
    .controller('CC-Programs-Internet-Controller', function(
        $scope,
        $state,
        $translate,
        ccListsFactory,
        _,
        DTOptionsBuilder,
        DTColumnDefBuilder,
        scgDataTableFactory,
        modalFactory) {

      var modalStartKind = ccListsFactory.listKinds.list_kind_group_internet;

      $scope.tableHeaders = [
          $translate.instant('HEADER.ENTRY'),
          $translate.instant('HEADER.DETAILS'),
          $translate.instant('HEADER.INSTALLDATE')
      ];

      $scope.data = {};
      $scope.data.table_api = [];

      $scope.reload = function() {
        $state.go($state.current, {}, {reload: true});
      };

      var loadList = function() {
        $scope.contentLoading = true;
        ccListsFactory.getDetailedByListFilter(modalStartKind)
                .then(function success(response) {
                  $scope.data.entries = response.data;
                  $scope.contentLoading = false;
                });
      };

      $scope.editEntries = function() {

        var  editRecords = [];
        _.each($scope.data.entries, function(v) {
          var obj = {data: {entry: v.entry}};
          editRecords.push(obj);
        });

        modalFactory.editModal.open({
          modal_settings: {
            mode: 'edit',
            context: 'prg',
            title: $translate.instant('HEADER.LIMITENTRIES'),
            subtitle: $translate.instant('MODAL_EDIT_RECORD.SUBTITLE'),
            newRecord: null,
            editRecords: editRecords,
            refreshStateAfterClose: true
          },
          callbacks: {
            ok: function() {},
            cancel: function() {}
          }
        });
      };

      loadList();

    });
