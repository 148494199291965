(function() {
    'use strict';

    angular
        .module('ChicoApp.Browser', [
            'ChicoApp.SB-Dashboard',
            'ChicoApp.SB-Usage',
            'ChicoApp.SB-Settings',
            'ChicoApp.Login',
            'ChicoApp.Internal',
            'ChicoApp.Licence'
        ]);
})();
