'use strict';
angular.module('ChicoApp.CB-Appfilter')
    .controller('CB-AppfilterController-V2', function(
        $rootScope,
        $scope,
        $http,
        $timeout,
        $translate,
        $templateCache,
        $state,
        _,
        Metronic,
        $log,
        AuthFactory,
        cbListsFactory,
        cbAppFilterFactory,
        scgSettingsFactory,
        scgSharedFactory) {

      $scope.data = {};
      $scope.hintHide = true;
      $scope.listCounters = {};
      $scope.groupTotal = 0;
      $scope.group1name = $translate.instant('TABS.GROUPS') + " 1";
      $scope.group2name = $translate.instant('TABS.GROUPS') + " 2";
      $scope.group3name = $translate.instant('TABS.GROUPS') + " 3";
      $scope.group4name = $translate.instant('TABS.GROUPS') + " 4";
      $scope.group5name = $translate.instant('TABS.GROUPS') + " 5";
      $scope.isCB3 = undefined;

      //preload timetable template
      //
      //templates are already loaded in the templatecache during the new buildprocess
      // $http.get('templates/timetable/timetable.html', {cache: $templateCache});
      // $http.get('templates/globalModals/globalEditModal/templates/modal-edit-content.html', {cache: $templateCache});
      // $http.get('templates/globalModals/globalEditModal/templates/modal-edit-window.html', {cache: $templateCache});
      // $http.get('templates/globalModals/globalEditModal/templates/modal-edit-content-timelimited.html', {cache: $templateCache});
      // $http.get('templates/globalModals/globalEditModal/templates/modal-edit-content-bonus.html', {cache: $templateCache});
      // $http.get('templates/durationPicker/durationPicker.html', {cache: $templateCache});


      cbAppFilterFactory.getSettings()
            .then(function success(response) {

              $scope.data = response.data;
                if (AuthFactory.activeDevice.deviceType==30) {$scope.isCB3=true;
                } else {
                    $scope.isCB3=false;
                }

                $scope.data.filterLevel = 2;  //Dieser Wert wird berrechnet
              if ($scope.data.isAppFilterNewBlocked == true) {
                $scope.data.filterLevel = 3;
              }
              $log.info('cbAppfilterFactory returned data:' , $scope.data);

              if ($scope.data.filterLevel ==3) $state.go('cb-appfilter-v2.allowed');

              //add watchers
              _.each($scope.data, function(v, k) {
                $scope.$watch('data.' + k, function(new_val, old_val) {
                  if (new_val != old_val) {
                    var changed_object = {};
                    changed_object[k] = new_val;
                    if ($scope.data.filterLevel == 2) {
                      $scope.data.isAppFilterNewBlocked = false;
                    }
                    if ($scope.data.filterLevel == 3) {
                      $scope.data.isAppFilterNewBlocked = true;
                    }
                    $log.info('Changed, defaultAppKind= ',$scope.data.defaultAppKind);
                    scgSharedFactory.needsSync = true;
                    cbAppFilterFactory.setSetting(changed_object);
                    if ($scope.data.filterLevel ==3) $state.go('cb-appfilter-v2.allowed');
                  }
                }, true);
              });
                $scope.contentLoading = false;
                Metronic.unblockUI();
            });

      cbListsFactory.getListCounters().then(function(response) {
        $log.info('list counters', response);
        $scope.listCounters = response.data;
        if (!isNaN(Number($scope.listCounters['11']))) {
          $scope.groupTotal = Number($scope.groupTotal) + Number($scope.listCounters['11']);
        }
        if (!isNaN(Number($scope.listCounters['12']))) {
          $scope.groupTotal = Number($scope.groupTotal) + Number($scope.listCounters['12']);
        }
        if (!isNaN(Number($scope.listCounters['13']))) {
          $scope.groupTotal = Number($scope.groupTotal) + Number($scope.listCounters['13']);
        }
        if (!isNaN(Number($scope.listCounters['14']))) {
          $scope.groupTotal = Number($scope.groupTotal) + Number($scope.listCounters['14']);
        }
        if (!isNaN(Number($scope.listCounters['15']))) {
          $scope.groupTotal = Number($scope.groupTotal) + Number($scope.listCounters['15']);
        }
        if ($scope.groupTotal === 0 | $scope.groupTotal == '0') {
          $scope.groupTotal = '';
        }
        $log.info('group total:' , $scope.groupTotal);
      });



        scgSettingsFactory.getGroups(false)
            .then(function success(response) {
                $scope.data.groups = response.data;
                 $log.info('$scope.data.groups', $scope.data.groups);
                if ($scope.data.groups.length==5) {
                    $scope.group1name = $scope.data.groups[0].name;
                    $scope.group2name = $scope.data.groups[1].name;
                    $scope.group3name = $scope.data.groups[2].name;
                    $scope.group4name = $scope.data.groups[3].name;
                    $scope.group5name = $scope.data.groups[4].name;
                }
            });





      window.scrollTo(0,0);

      //Hint stuff, Add $scope.hintHide at top of page
      //--------------------------------------------------------------------------------
      scgSharedFactory.getHint(2040).//adjust hintID here, leading 20 means CB!
            then(function success(response) {
              if (response.data.hint2040) {                         //adjust hintID here
                $scope.hintHide = response.data.hint2040;}        //adjust hintID here
              else {
                //not assgined, set to false!
                $scope.hintHide = false;}
            }
        );
      $scope.hintDone = function() {
        $scope.hintHide = true;
        scgSharedFactory.setHint(2040).then(function success() {
          $log.info('HINT DONE SUCCESS');
        }
      );};
      //--------------------------------------------------------------------------------

    });
