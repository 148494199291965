angular.module('ChicoApp.Shared').directive('errorNotification', function() {

  return {

    templateUrl: 'templates/shared/errorDisplay/errorNotification.html',

    scope: {
      error: '=ngModel',
      removeFunction: '&'
    },

    controller: function($scope, $element) {

      var fadeTimer = 350;

      setTimeout(function() {
        $element.addClass('in');
      }, fadeTimer);

      $scope.removeNotification = function(err) {

        var e = err;

        $element.removeClass('in').addClass('out');

        setTimeout(function() {
          $scope.removeFunction(e);
        }, fadeTimer);

      };

      $scope.showDetails = function() {
        angular.element('.err-details-div', $element).toggle();
      };
    }

  };

});

