'use strict';
angular.module('ChicoApp.CB-GPS')
    .controller('CB-GPSController', function(
      $rootScope,
      $scope,
      $http,
      $timeout,
      $log,
      google,
      gpsFactory,
      scgSharedFactory,
      scgGlobalSpinnerFactory) {

      //Dierk TODO: Wieder raus
      scgSharedFactory.needsSync = false;

      $scope.gpsdata = {};
      $scope.pollGPSStatusInterval = null; //just to be declaged globally
      $scope.pollGPSStatusIntervalActive = false;
      $scope.pollPGPSStatusTimeout = null;
      $scope.GPSInProgress = false;
      $scope.gpsFailed = false;
      //$scope.failuremsg = "";

      // ******************************************************************************
      // GPS THE ASYNC way
      // ******************************************************************************
      $scope.gpsrequestAsync = function() {
        $scope.gpsFailed = false;
        $log.info('GPS ASYNC entry');
        $scope.GPSInProgress = true;

        //First of all reset to 0|0
        gpsFactory.requestAsync().then(function success() {

          gpsFactory.getAsync().then(function success(response) {
              $scope.pollGPSStatusTimeout = $timeout(function() {
                $log.info('TIMEOUT reached');
                $scope.GPSInProgress = false;
                $scope.gpsFailed = true;
                if ($scope.pollGPSStatusIntervalActive == true) {
                  clearInterval($scope.pollGPSStatusInterval);
                  $scope.pollGPSStatusIntervalActive = false;
                  $scope.gpsFailed = true;
                  $scope.GPSInProgress = false;
                  $log.info('GPS FAILED (TIMEOUT)!');
                }
              }, 15 * 1000);

              //sync async started, now waiting for result via poll
              $scope.pollGPSStatusIntervalActive = true;
              $scope.pollGPSStatusInterval = setInterval(function() {

                //get last sync via interval
                gpsFactory.getAsync().then(function(response) {
                  $log.info('RESPONSE newLatLong:', response.data.gpsposition);
                  if (response.data.gpsposition != '0|0') {

                    $timeout.cancel($scope.pollGPSStatusTimeout);
                    //This is success case -------------------------------------------------------
                    $log.info('LATLONG ARRIVED SUCCESS');
                    clearInterval($scope.pollGPSStatusInterval);
                    $scope.GPSInProgress = false;
                    $scope.pollGPSStatusIntervalActive = false;
                    $scope.mylatlong = response.data;
                    if ($scope.mylatlong.gpsposition != undefined) {
                      $scope.mylatlong_arr = $scope.mylatlong.gpsposition.split('|');
                      $scope.mylat = parseFloat($scope.mylatlong_arr[0]); //Convert to number
                      $scope.mylng = parseFloat($scope.mylatlong_arr[1]); //Convert to number
                    }
                    $scope.latlong = {lat: $scope.mylat, lng: $scope.mylng};
                    var map = new google.maps.Map(document.getElementById('map'), {
                        zoom: 15,
                        center: $scope.latlong
                      });
                    /*eslint-disable*/
                    var marker = new google.maps.Marker({
                        position: $scope.latlong,
                        map: map,
                        title: 'Position'
                      });
                    /*eslint-enable*/
                    //eof success case ---------------------------------------------------------
                  }
                });
              }, 1500);   //5000 = 5 secs interval

              return response;

            }, function error(err) {
              $log.info('GPS error');
              $scope.GPSInProgress = false;
              $scope.pollGPSStatusIntervalActive = false;
              return err;
            });
        });
      };
      // eof getAsync ------------------------------------------------------------
      // -------------------------------------------------------------------------

      //Dierk: gpsrequest wird vom ng-click im Template aufgerufen
      $scope.gpsrequest = function() {

        scgGlobalSpinnerFactory.blockUI();
        $log.info('gpsrequest called');
        $scope.mylatlong = {};

        gpsFactory.get()
                .then(function success(response) {
                  $log.info('CB GPS returned SUCCESS');
                  $scope.mylatlong = response.data;

                  if ($scope.mylatlong.gpsposition != undefined) {
                    $scope.mylatlong_arr = $scope.mylatlong.gpsposition.split('|');
                    $scope.mylat = parseFloat($scope.mylatlong_arr[0]); //Convert to number
                    $scope.mylng = parseFloat($scope.mylatlong_arr[1]); //Convert to number
                  }

                  //Dierk: Zweiter Promise wegen Caching Problemen erstmal raus (ist dann alles in einem IF BLOCK)
                  //}).then(function(){

                  scgGlobalSpinnerFactory.unblockUI();

                  if ($scope.mylng != 0 && $scope.mylat != 0) {

                    $log.info('PASSED TYPE IS: ' + typeof($scope.mylng));
                    //Assign to google latlong:
                    $scope.latlong = {lat: $scope.mylat, lng: $scope.mylng};

                    var map = new google.maps.Map(document.getElementById('map'), {
                      zoom: 15,
                      center: $scope.latlong
                    });
                    /*eslint-disable*/
                    var marker = new google.maps.Marker({
                      position: $scope.latlong,
                      map: map,
                      title: 'Position'
                    });
                    /*eslint-enable*/

                  } else {
                    document.getElementById('gpsfailure').style.display = 'block';
                    $log.info('0|0 was returned!');
                    //$scope.failuremsg = 'Sorry, device did not respond!';
                  }

                });
      };

      window.scrollTo(0,0);
    });
