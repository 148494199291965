angular.module('ChicoApp.Shared').directive('scgAppList', function (
    $window,
    $document,
    $log,
    $translate,
    $timeout,
    modalFactory,
    _,
    scgSharedFactory
) {

    return {

        templateUrl: 'templates/shared/scgAppList/scgAppList.html',
        scope: {
            listContent: '=ngModel',
            selectedRows: '=selectedRows',
            scgContext: '=scgContext',
            onItemClick: '&',
            onSetSortKey: '&',
            sortOptions: '=sortOptions'
        },

        controller: function ($scope, $element) {

            $scope.scgSharedFactory = scgSharedFactory;

            $scope.getIcon = function(param){

                if(param === 'checked'){
                    return scgSharedFactory.getImgPrg(param);
                }

                if($scope.scgContext == 'cc'){
                    return scgSharedFactory.getImgPrg(param).toLowerCase() + ".png";
                }else {
                    return scgSharedFactory.getImgApp(param);
                }
            }

            $scope.getIconWinApps = function(param){

                if(param === 'checked'){
                    return scgSharedFactory.getImgPrg(param);
                }

                if($scope.scgContext == 'cc'){
                    return scgSharedFactory.getImgPrg(param).toLowerCase();
                }else {
                    return scgSharedFactory.getImgApp(param);
                }
            }

            $scope.onSelectAllChanged = function(){
                var isAllChecked = $scope.selectAllChecked;
                
                if(isAllChecked == true){
                    $scope.selectedRows = $scope.listContent;
                }else {
                    $scope.selectedRows = [];
                }

                _.forEach($scope.listContent, function(listRow){
                    listRow.selected = isAllChecked;
                    listRow.isChecked = isAllChecked;
                });

            }

            $scope.onRowClick = function(row, event){

                $scope.selectAllChecked = "false"; //reset select all

                var isIconClick = $(event.target).hasClass('ngapplist-row-icon');
                var isCheckBoxClick = $(event.target).is(":checkbox");

                if(isCheckBoxClick || isIconClick ){

                    var included = _.filter($scope.selectedRows, {entry:row.entry});
                    var alreadyChecked = included.length > 0 ? true : false;
                    
                    //manually manage checkbox on incon clicks
                    if(isIconClick){
                        row.isChecked = !alreadyChecked;
                    }

                    if(!alreadyChecked){
                        $scope.selectedRows.push(row);
                    }else {
                        $scope.selectedRows = _.filter($scope.selectedRows, function(obj){
                            return obj.entry != row.entry;
                        });
                    }

                    //mark or unmark row selected
                    row.selected = !row.selected;
                    
                } else {
                    $scope.onItemClick({row:row});
                }

            };

            $scope.onSetSorting = function(sortKey, event) {
                $scope.onSetSortKey()(sortKey);
            };

            $scope.LookupAppOrPrg = function(pkg,entry) {
                $log.info('LookupAppOrPrg pkg=' + pkg + '|entry=' + entry);
                var kkKind = 102; //default is app
                if (pkg==entry) {
                    kkKind=103; //override, we are an exe
                }
                modalFactory.kisikatModal.open({
                    modal_settings: {
                        entry : pkg,
                        title: entry,
                        kkKind : kkKind,               //101=Website, 102=App, 103=Windows Exe, 104=Windows App
                        username: null,
                        refreshStateAfterClose: true
                    },callbacks: {
                        ok: function () {
                            $log.info('modal-kisikat ok callback');
                        }
                    }
                });
            }

            $scope.$watch('listContent', function(newVal, oldVal){
                if(newVal && newVal != oldVal){
                    $scope.selectAllChecked = false;
                }
            });

        },

        link: function ($scope, $element) {
            // nothing so far
        }
    };

});