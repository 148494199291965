'use strict';
angular.module('ChicoApp.Licence')

    .controller('LicenceController', function(
        $rootScope,
        $scope,
        $translate,
        $state,
        $http,
        $window,
        $cookies,
        $stateParams,
        $timeout,
        DeviceFactory,
        AuthFactory,
        modalFactory,
        $log,
        API,
        apiFunctions,
        licenceFactory,
        scgLicenceFactory,
        menuFactory,
        scgGlobalSpinnerFactory,
        scgSharedFactory) {

      $scope.licInfo = {};

      //Defaults
      $scope.hintVisible = false;
      $scope.busyButton = false;
      $scope.loginEmail = '';
      $scope.serialNoArr = {};
      $scope.emailLicencesFor = '';

      $scope.failNoticeMsg = '';
      $scope.currentRadio = 1;
      $scope.contentLoading = false;
      $scope.listDone = false;
      $scope.serialSuggest = false;
      $scope.resetVisible = false;
      $scope.visibleMoveNotice = false;
      $scope.serialInUse = false;
      $scope.forceSerialRadio = false;

      $log.info('LICENCE-ctl active context' , AuthFactory.activeContext);
      $log.info('LICENCE-ctl rootscope enterSerial=' + $rootScope.enterSerial);
      $log.info("LICENCE-ctl stateparams=" + $stateParams.showSerial);

      //scgGlobalSpinnerFactory.unblockUI();
      AuthFactory.activeLicence.showSideBar = false;
      menuFactory.hideSidebar();

      $scope.licInfo = AuthFactory.activeLicence;
      $scope.licInfo.remainingDays = AuthFactory.activeLicence.expireDays; //needed for if days<5
      $scope.licInfo.expireDate = AuthFactory.activeLicence.expireDate;
      $scope.licInfo.serial = AuthFactory.activeLicence.serial;
      $scope.licInfo.remaining = $translate.instant('LICENCE.SUB_TRIAL').replace('%%', AuthFactory.activeLicence.expireDays);
      $scope.licInfo.remaining_short = $translate.instant('LICENCE.SUB_TRIAL_SHORT').replace('%%', AuthFactory.activeLicence.expireDays);
      $scope.licInfo.radio1Text = $translate.instant('LICENCE.HEAD_TRIAL');  //default
      if ($scope.licInfo.serial != '') {
        $scope.licInfo.radio1Text = $translate.instant('LICENCE.HEAD_MODAL');
      } //overwrite radio1 if there is serial

      $scope.orderURL = $translate.instant('URL.ORDER_CC'); //CC is default
      if (AuthFactory.activeContext == 'cb') {
        $scope.orderURL = $translate.instant('URL.ORDER_CB');
      }

      $scope.licInfo.expireText = '';
      if ($scope.licInfo.remainingDays < 1) {
        if ($scope.licInfo.serial == '') {
          $scope.licInfo.expireText = $translate.instant('LICENCE.EXPIRED_TRIAL');
        } else {
            $scope.licInfo.expireText = $translate.instant('LICENCE.EXPIRED_FULL');
        }
        $scope.licInfo.expireText = $scope.licInfo.expireText.replace('%%', scgSharedFactory.getLocalizedDateString($scope.licInfo.expireDate));
        if ($scope.licInfo.blockedSerial!='') {

           //$scope.licInfo.expireText = $translate.instant('LICENCE.BLOCKED') + ":" +$scope.licInfo.blockedSerial ;
           $scope.licInfo.expireText = "BLOCKED!";
       }
      }

      //calls endpoint daysofserial!
      scgLicenceFactory.getSerialInfo()
            .then(function success(response) {

              $log.info('editSerial Success', response);
              $scope.licInfo = response.data;
              $scope.licInfo.remainingDays = response.data.expireDays; //needed for if days<5
              $scope.licInfo.expireDate = response.data.expireDate;
              $scope.licInfo.serial = response.data.serial;
              $scope.licInfo.isTrial = response.data.isTrial;
              $scope.licInfo.suggestedSerial = response.data.suggestedSerial;

                    $log.info('suggestedSerial', response.data.suggestedSerial);


                    $scope.licInfo.remaining = $translate.instant('LICENCE.SUB_TRIAL').replace('%%', response.data.expireDays);
              $scope.licInfo.remaining_short = $translate.instant('LICENCE.SUB_TRIAL_SHORT').replace('%%', response.data.expireDays);
              $scope.licInfo.radio1Text = $translate.instant('LICENCE.HEAD_TRIAL');  //default
              if ($scope.licInfo.serial != '') {
                $scope.licInfo.radio1Text = $translate.instant('LICENCE.HEAD_MODAL');
                $scope.orderURL =$translate.instant('URL.UPGRADE') + $scope.licInfo.serial;
              } //overwrite radio1 if there is serial

              $scope.licInfo.expireText = '';
              if ($scope.licInfo.remainingDays < 1) {
                if ($scope.licInfo.serial == '') {
                  $scope.licInfo.expireText = $translate.instant('LICENCE.EXPIRED_TRIAL');
                } else {
                  $scope.licInfo.expireText = $translate.instant('LICENCE.EXPIRED_FULL');
                }
                $scope.licInfo.expireText = $scope.licInfo.expireText.replace('%%', scgSharedFactory.getLocalizedDateString($scope.licInfo.expireDate));
              }
              $log.info('editSerial DONE');

                    //Change noticeText if expireDate is in future (in this case REDIS licence is still somehow set to "f"
                    if (AuthFactory.activeLicence.expireDate && (moment().diff(AuthFactory.activeLicence.expireDate,'days')*-1)>1) {
                        $scope.licInfo.expireText = $translate.instant('MISC.LICENCEISSUE');
                        $log.info("EXPIRE IN FUTURE!!!");
                    }


                    //Do we have a suggested serial?
                if ($scope.licInfo.remainingDays <= 0 && $scope.licInfo.suggestedSerial) {
                    $log.info("suggest serial, isTrial=" + $scope.licInfo.isTrial);
                    if ($scope.licInfo.isTrial===false) {
                        $log.info("suggest serial, isTrial is FALSE!");
                        $scope.forceSerialRadio = false;
                        $scope.currentRadio=1;
                    } else {
                        $scope.serialSuggest = true;  //Sets the expired message from red to grey
                        $scope.licInfo.serial = $scope.licInfo.suggestedSerial;
                        $scope.forceSerialRadio = true;
                        $scope.currentRadio=0;

                    }


                }


              scgGlobalSpinnerFactory.unblockUI();
              $scope.checkForEnteredSerial();
            },
            function error() {
              $log.info('editSerial ERROR');
            });


      $scope.radioClick = function() {
            $log.info("radio click!");
          $scope.currentRadio = 0;
      };

      $scope.showHint = function() {
        $log.info('showHint called!');
        if ($scope.listDone!=true) {
            $scope.contentLoading=true;
        }
        $scope.hintVisible = true;
      };

      $scope.gotoLicenceInfo = function() {
        //$state.go('licenceinfo');
      };


      $scope.deleteDevice = function()
      {
        $log.info('deleteDevice goes here');
        modalFactory.infoModal.open({
          modal_settings: {
            title: $translate.instant('MSG.SURE'),
            message: $translate.instant('MSG.DELETEDATA') + '<br>' + $translate.instant('MSG.SURE'),
            butOkLabel: $translate.instant('BUTTON.YES'),
            butCancelLabel: $translate.instant('BUTTON.NO'),
            iconNo: 3,
            refreshStateAfterClose: false //Dierk: bei true wurde controller gleich neu geladen werden
          },
          callbacks: {
            ok: function() {
              $log.info('modal closed with ok -> callback');
              Metronic.blockUI({animate: true});

              var myurl = API.url + 'action/deletedevice';
              var req = {
                method: 'GET',
                url: myurl
              };
              $http(req)
                  .then(function success() {
                    $log.info('Done with HTTP 200!');
                    Metronic.unblockUI();
                    AuthFactory.logout(true);
                  });
            },
          },
          cancel: function() {
            $log.info('canceled!');
          }
        });
      }

      $scope.showSerialRadio = function() {
          $log.info("forceSerialRadio");
          $scope.forceSerialRadio = true;
          $scope.currentRadio = 0;
      }

      $scope.ok = function() {

        $rootScope.enterSerial='';  //clear our tmpserial
        menuFactory.showSidebar();

        if ($scope.currentRadio === 1) {
          $log.info('Current radio=1, licInfo:' , $scope.licInfo);
          //Trial or licence
          if ($scope.licInfo.remainingDays <= 0) {
            AuthFactory.logout(true);
            return;
          }
          $scope.gotoDash();
          return;
        }

        $log.info('Entered serial was:' + $scope.licInfo.serial + ' lang:' + $translate.use());
        $log.info('Entered serial context was:' + AuthFactory.activeContext);

        if ($scope.licInfo.serial === undefined || $scope.licInfo.serial == '' || $scope.licInfo.serial.length < 5) {
          $scope.failNoticeMsg = $translate.instant('LICENCE.SERIAL_WRONG');
          return;
        }
        var language = 'de'; //default
        if ($translate.use() == 'en_US') {
          language = 'en';
        }

        var req = {serial: $scope.licInfo.serial, lang: language, context: AuthFactory.activeContext};
        $scope.busyButton = true;
        scgLicenceFactory.enterSerial(req)
                .then(function success(response) {
                  $log.info('LISTSERIAL Success', response);
                   $scope.serialInUse = false; //set default
                  if (response.data.success === true) {
                    $scope.busyButton = false;
                    $scope.showSuccess(response.data.msg);
                    $scope.gotoDash();
                  } else {
                    $scope.busyButton = false;
                    $scope.failNoticeMsg = response.data.msg;
                    if ($scope.failNoticeMsg.includes('Seriennummer wird bereits verwendet')) $scope.serialInUse = true;
                    if ($scope.failNoticeMsg.includes('Licence is already in use')) $scope.serialInUse = true;
                  }
                },
                function error() {
                  $log.info('LISTSERIAL ERROR');
                  $scope.busyButton = false;
                  $scope.failNoticeMsg = $translate.instant('ERROR.UNKNOWN');
                });

        return;

      };


      $scope.showSuccess = function(licToDate) {
        modalFactory.infoModal.open({
          modal_settings: {
            title: $translate.instant('HEADER.INFO'),
            message: $translate.instant('LICENCE.SUCCESS').replace('%%',scgSharedFactory.getLocalizedDateString(licToDate)), // + $translate.instant('LOGIN.RELOGIN'),
            butOkLabel: $translate.instant('BUTTON.OK'),
            butCancelLabel: null,
            iconNo: 1,
            refreshStateAfterClose: true
          },
          callbacks: {
            ok: function() {
              $log.info('LICENCE modal closed with ok -> callback');
              scgGlobalSpinnerFactory.unblockUI();
                //NEW APPROACH from 04/2020 to avoid logout
                //Important to call checkCurrentDeviceLicence again
               scgLicenceFactory.getSerialInfo()
                    .then(function success(response) {
                        $log.info('LICENCE editSerial called again to be safe', response);
                        $log.info('LICENCE activeContext=', AuthFactory.activeContext);
                        $log.info('LICENCE state.get=', $state.get());
                        if (AuthFactory.activeContext == 'cc') {
                            AuthFactory.checkCurrentDeviceLicenceCC();
                        } else if (AuthFactory.activeContext == 'cb') {
                            AuthFactory.checkCurrentDeviceLicenceCB();
                        }
                        $scope.gotoDash();
                    });

              //OLD APPROACH (with logout)
              //AuthFactory.logout(true); //do logout instead of gotoDash
              //$scope.gotoDash();
            }
          }
        });
      };


      $scope.gotoDash = function() {
        scgGlobalSpinnerFactory.unblockUI();
        AuthFactory.activeLicence.showSideBar = true;
        $log.info('AUTH, current context is=' + AuthFactory.activeContext);
          if (AuthFactory.activeContext == 'sb') {
              $state.go('sb-dashboard');
          } else
        if (AuthFactory.activeContext == 'sc') {
              $state.go('sc-dashboard');
          } else
        if (AuthFactory.activeContext == 'cc') {
            $state.go('cc-dashboard-v3');
        } else {
            $log.info('cb-dash-v3 in licence');
            if ($cookies.get('cb2')=="true") {
                $state.go('cb-dashboard'); }
            else {
                $state.go('cb-dashboard-v3');
            }
        }
      };


      $scope.resetSerial = function(serial) {
          $log.info('reset licence serial=' + serial);
          modalFactory.infoModal.open({
              modal_settings: {
                  title: $translate.instant('MSG.SURE'),
                  message: serial + ": " + $translate.instant('MSG.RESET_LICENCE'),
                  butOkLabel: $translate.instant('BUTTON.YES'),
                  butCancelLabel: $translate.instant('BUTTON.NO'),
                  iconNo: 2,
                  refreshStateAfterClose: false
              },
              callbacks: {
                  ok: function() {
                      $log.info('modal closed with ok -> callback');
                      Metronic.blockUI({animate: true});

                      var myurl = API.url + 'licence/deleteuseddevice/' + serial;
                      var req = {
                          method: 'GET',
                          url: myurl
                      };
                      $http(req)
                          .then(function success() {
                              //reload page!
                              Metronic.unblockUI();
                              $state.go($state.current, {}, {reload: true});
                          });
                  },
              },
              cancel: function() {
                  $log.info('canceled!');
              }
          });
      }


        $scope.resetDialog = function()   {
            modalFactory.infoModal.open({
                modal_settings: {
                    title: $translate.instant('HEADER.INFO'),
                    message: $translate.instant('LICENCE.RESET_INFO') + '<br><br>' + $translate.instant('MSG.CONTINUE_QUESTION'),
                    butOkLabel: $translate.instant('BUTTON.OK'),
                    butCancelLabel: $translate.instant('BUTTON.CANCEL'),
                    iconNo: 1,
                    refreshStateAfterClose: true
                },
                callbacks: {
                    ok: function() {
                        $log.info('licenceReset modal reset OK');
                        scgGlobalSpinnerFactory.blockUI();
                        var language = 'de'; //default
                        if ($translate.use() == 'en_US') {
                            language = 'en';
                        }
                        var req = {serial: $scope.licInfo.serial, lang: language, context: AuthFactory.activeContext};
                        scgLicenceFactory.licenceReset(req)
                            .then(function success(response) {
                                    $log.info('licenceReset Success', response);
                                    scgGlobalSpinnerFactory.unblockUI();
                                    if (response.data.success === true) {
                                        $scope.busyButton = false;
                                        $scope.showSuccess(response.data.msg);
                                        $scope.gotoDash();
                                    } else {
                                        $scope.busyButton = false;
                                        $scope.failNoticeMsg = response.data.msg;
                                    }
                                },
                                function error() {
                                    $log.info('LISTSERIAL ERROR');
                                    $scope.busyButton = false;
                                    $scope.failNoticeMsg = $translate.instant('ERROR.UNKNOWN');
                                });
                    },
                    cancel : function() {
                        $log.info('modal reset CANCEL');
                    }
                }
            });
        };

        $scope.showMoveNotice = function () {
            $scope.visibleMoveNotice=true;
        }

        $scope.checkForEnteredSerial = function() {
            //called from multiple locations here, fired if it was passed from mydata page
            if (typeof $rootScope.enterSerial !== 'undefined' && $rootScope.enterSerial!='') {
                if ($rootScope.enterSerial.length>10) {
                    $scope.licInfo.serial = $rootScope.enterSerial;
                    $scope.currentRadio=0;
                    }
                }

            }




    });
