angular.module('ChicoApp').factory('scgLicenceFactory', function(
    $http,
    $translate,
    $cacheFactory,
    $location,
    $cookies,
    $log,
    apiFunctions,
    API)  {

  var factory = {};

  factory.getSerialInfo = function() {
    var req = {
      method: 'GET',
      url: API.url + 'licence/daysofserial'
    };
    return $http(req);
  };

  factory.enterSerial = function(obj) {
    var req = {
      method: 'POST',
      url: API.url + 'licence/enterserial',
      data: obj
    };
    return $http(req);
  };

    factory.licenceReset = function(obj) {
        var req = {
            method: 'POST',
            url: API.url + 'licence/licencereset',
            data: obj
        };
        return $http(req);
    };


    factory.listserial = function() {
    var req = {
      method: 'GET',
      url: API.url + 'licence/listserial'
    };
    return $http(req);
  };

  factory.listserialemail = function() {

    var email = "";
    if (angular.isDefined($cookies.scgcookie)) email=$cookies.scgcookie;
    $log.info('listserialemail triggered email=' + email)

    var req = {
      method: 'GET',
      url: API.url + 'licence/listserial/' + email
    };
    return $http(req);
  };

  factory.setAllUsersSetting = function(obj) {
    var req = {
      method: 'POST',
      url: API.url + 'allusers/ccsettings',
      data: obj
    };
    return $http(req);
  };

  // TODO: remove this Block, seems to be unused

  // factory.isCBSecurity = function(callback) {
  //     //Dierk: Callback kriegt funktion, die ausgeführt wird, wenn der res.then successful war
  //     callback = callback || function() {}; //wenn nicht definiert, "leere" function, callback kann ab hier nicht mehr null od. undefined sein

  //     var url = API.url + 'service/CBSecurity';
  //     var req = {
  //         method: 'GET',
  //         url: url
  //     };

  //     var res = $http(req);
  //     res.then(function success(res) {
  //         callback(res.data);
  //     });
  // };

  return factory;
});
