//Bootstrapswitch directive
angular.module('ChicoApp.Shared').directive('bootstrapSwitch', function(/*$parse*/) {
  return {
    restrict: 'A',
    require: '?ngModel',
    scope: {
      ngEnabledWhen: '@', //angular expression, if this is true the switch will be set to ON in the ui
      ngDisabledWhen: '@', //angular expression, if this is true the switch will be set to OFF in the ui
      ngTrueValue: '=', //value the switch sets to ng-model when set to ON
      ngFalseValue: '=', //value the switch sets to ng-model when set to OFF
      ngModel: '=' //link to parent scope
    },
    link: function($scope, element/*, attrs, ngModel*/) {
      element.bootstrapSwitch();
      element.attr('tabindex', -1);
      element.on('switchChange.bootstrapSwitch', function(event, state) {
        if ($scope.ngModel !== undefined) {
          $scope.$apply(function() {
            if ($scope.ngTrueValue !== undefined || $scope.ngFalseValue !== undefined) {
              if (state === true) {
                $scope.ngModel = $scope.ngTrueValue;
              } else if (state === false) {
                $scope.ngModel = $scope.ngFalseValue;
              }
            }
            // ngModel.$setViewValue(state);
          });
        }
      });
    },
    controller: function($scope, $element/*, $attrs*/) {
      $scope.$watch('ngModel', function(newValue/*, oldValue*/) {
        if (newValue === undefined) {
          return;
        }
        var isEnabled = $scope.ngEnabledWhen;
        var isDisabled = $scope.ngDisabledWhen;
        if (isEnabled !== undefined) {
          var bool = false;
          if (isEnabled == 'true') {
            bool = true;
          }
          $element.bootstrapSwitch('state', bool, true);
        } else if (isDisabled !== undefined) {
          $element.bootstrapSwitch('state', isDisabled, true);
        } else if (newValue) {
          $element.bootstrapSwitch('state', true, true);
        } else {
          $element.bootstrapSwitch('state', false, true);
        }
      });
    }
  };
});
