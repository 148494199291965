angular.module('ChicoApp').factory('licenceFactory', function(
    $http,
    API) {

  var factory = {}; //Dont forget to init, this is also final return value!

  factory.listEmail = function() {
    var req = {
      method: 'GET',
      url: API.url + 'licence/listemail'
    };
    return $http(req);
  };

  factory.listSingleSerials = function() {
    var req = {
      method: 'GET',
      url: API.url + 'licence/listsingleserials'
    };
    return $http(req);
  };

  factory.listSingleDevices = function() {
    var req = {
      method: 'GET',
      url: API.url + 'licence/listsingledevices'
    };
    return $http(req);
  };

  factory.deleteCustomer = function() {
    var req = {
      method: 'GET',
      url: API.url + 'licence/deletecustomer'
    };
    return $http(req);
  };




  return factory;

});
