'use strict';
angular.module('ChicoApp').config(function($stateProvider) {
    $stateProvider.state('cb-webfilter-v2', {
        url: '/cb-webfilter-v2',
        views: {
            "@": {
                component: 'webFilterV2'
            }
        },
        params: {
            // Current page in webList
            appListPage: 1
        }
    });
});
