'use strict';
angular.module('ChicoApp.CB-Emailreport', [])

    .config(function($stateProvider) {
      $stateProvider.state('cb-emailreport', {
        url: '/cb-emailreport',
        controller: 'CB-EmailReportController',
        templateUrl: 'templates/chicobrowser/cb_emailreport/templates/emailreport.html',
      });
    })
    .run(function() {});
