'use strict';
angular.module('ChicoApp.SB-Settings', [])
    .config(function($stateProvider) {

        $stateProvider.state('sb-settings', {
            url: '/sb-settings',
            controller: 'SB-SettingsController',
            templateUrl: 'templates/browser/sb_settings/templates/settings.html'
        }),

        $stateProvider.state('sb-pwreset', {
            url: '/sb-pwreset',
            controller: 'SB-Settings-ResetPw',
            templateUrl: 'templates/browser/sb_settings/templates/settings-resetpw.html'
        })

    })
    .run(function() {});
