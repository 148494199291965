angular.module('ChicoApp.Shared').directive('integerPicker', function(
    $window,
    $document,
    $log,
    moment,
    _
) {

    return {

        templateUrl: 'templates/shared/integerPicker/integerPicker.html',
        scope: {
            timelimit: '=ngModel',
            scgApiType: '@',
            updateOnChange: '@',
            ngDisabled: '=ngDisabled'
        },

        controller: function($scope, $element) {

            $scope.displayedTimeValue = '0'; //will store the displayed value (always sting)
            $scope.timeValue = 0;

            $scope.$watch('timelimit', function(new_val) {

                // $scope.timelimit = $scope.timeValue;
                if( $scope.timelimit != $scope.timeValue ){
                    $scope.setTimeValue(new_val);
                }

            });

            $scope.$watch('ngDisabled', function(newVal) {
                $element.find('button, input')
                    .attr('disabled', newVal);
                if (newVal == true) {
                    $element.find('button, input')
                        .addClass('disabled-text-color');
                }else {
                    $element.find('button, input')
                        .removeClass('disabled-text-color');
                }
            });

            $scope.inputIsValid = function(val) {
                if( isNaN(val) == false && val < 99 ){
                    return true;
                }
                return false;
            };

            $scope.setTimeValue = function(val){
                if( $scope.inputIsValid(val) ){
                    $scope.timeValue = val;
                }else {
                    $scope.timeValue = 0;
                }
                $scope.displayedTimeValue = parseInt($scope.timeValue);
                $scope.timelimit = $scope.timeValue;
                $log.info('displayedTimeValue now=' + $scope.displayedTimeValue);
            };

            $scope.lessTime = function() {
                if ($scope.timeValue>=1) {
                    $scope.setTimeValue($scope.timeValue-1);
                }
            };

            $scope.moreTime = function() {
                $scope.setTimeValue($scope.timeValue+1);
            };

        },

        link: function($scope, $element) {

            var input = $element.find('input');

            input.bind('blur', function() {

                var inputVal = input.val();

                if (!$scope.inputIsValid(inputVal)) {
                    input.val('0'); //just reset the input
                    return;
                }

                $scope.setTimeValue(inputVal);

            });

            if ($scope.updateOnChange) {
                input.bind('change', function() {

                    var inputVal = input.val();

                    if (!$scope.inputIsValid(inputVal)) {
                        input.val('0'); //just reset the input
                        return;
                    }

                    $scope.setTimeValue(inputVal);

                });
            }

        }
    };

});

