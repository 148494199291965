'use strict';
angular.module('ChicoApp.CC-Settings')

    .controller('SCG-Modal-AddMultiTan-Controller', function(
        $rootScope,
        $scope,
        $http,
        $log,
        $timeout,
        $translate,
        $modal,
        $state,
        $modalInstance,
        _,
        AuthFactory,
        DeviceFactory,
        ccListsAllFactory,
        scgSharedFactory) {

        $log.info('modal for multiTAN has just been opened, controller init running now');

        //handle tan input and okay button in modal
        $scope.tanMinutes = '30';
        $scope.tanQty = '1';
        $scope.username = $translate.instant('BUTTON.ALL');


        $log.info(DeviceFactory.deviceList);
        $log.info(AuthFactory.activeDevice);


        _.forEach (DeviceFactory.deviceList, function(device) {
            $log.info(device.deviceId + "|||" + AuthFactory.activeDevice.deviceId);
            if (device.deviceId == AuthFactory.activeDevice.deviceId) {
                $scope.usernames = [];
                $scope.usernames.push($translate.instant('BUTTON.ALL'));
                _.forEach (device.users,function(user){
                    $scope.usernames.push(user.username)
                });

                $log.info("USERNAMES:" , $scope.usernames);


            }
        });


        $scope.lessMinutes = function() {
            var diff = 5;
            if ($scope.tanMinutes >= diff) {
                $scope.tanMinutes =  parseInt($scope.tanMinutes) - diff;
            }
        };

        $scope.lessQty = function() {
            var diff = 1;
            if ($scope.tanQty >= diff) {
                $scope.tanQty =  parseInt($scope.tanQty) - diff;
            }
        };

        $scope.moreQty = function() {
            var diff = 1;
            if ($scope.tanQty <= 19) {
                $scope.tanQty =  parseInt($scope.tanQty) + diff;
            }
        };


        $scope.myCancel = function() {
            $log.info('MyCancel CLICK!');

        };

        $scope.moreMinutes = function() {
            var diff = 5;
            if ($scope.tanMinutes <= 600) {
                $scope.tanMinutes =  parseInt($scope.tanMinutes) + diff;
            }
        };

        $scope.tanMinutesInvalid = false;
        $scope.$watch('tanMinutes', function(newVal, oldVal) {
            if (newVal != oldVal) {
                $scope.tanMinutesInvalid = false;
                if (isNaN(parseInt(newVal)) || newVal < 0 || newVal >= 600) {
                    // if(newVal < 0 || newVal >= 600){
                    $scope.tanMinutesInvalid = true;
                }else {
                    $scope.tanMinutesInvalid = false;
                }
            }
        });

        $scope.dismissModal = function() {
            $modalInstance.dismiss('cancel');
        };

        $scope.addMultiTAN = function() {

            $log.info('addMultiTAN SAVE called');
            var records = [];
            var i = 0;
            if ($scope.tanQty>20) {
                $scope.tanQty=20;
            }

            for(i=0;i<$scope.tanQty;i++)
            {
                $log.info("IM FOR:" + $scope.username);
                var rnd = Math.floor(Math.random() * (999999 - 100001)) + 100001;
                records.push({
                    kind:28,
                    entry:rnd,
                    description:$scope.tanMinutes.toString()
                })
            }

            $log.info("MATHS DONE");

            $scope.URIUsername = $scope.username;
            if ($scope.username==$translate.instant('BUTTON.ALL')) $scope.URIUsername="allusers";
            var req = {
                method: 'POST',
                url: "https://portal.salfeld.net/api/" + $scope.URIUsername.toLowerCase() +  '/cclists',
                data: records
            };
            $http(req).then(function success() {
                    scgSharedFactory.needsSync = true;
                    $state.go($state.current, {}, {reload: true});
                    $modalInstance.dismiss('cancel');
                }).catch(function(error){$log.info("ERROR:" , error)});
        };

    });
