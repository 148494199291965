angular.module('ChicoApp.Shared').component('parentsCard', {
    templateUrl: 'templates/shared/parentsCard/parentsCard.component.html',
    controller: parentsCardController,
    bindings:   {
        /**
         * Name of the device context (cb or cc)
         * @type {string}
         */
        context : '<?',
        /**
         * Name of the device
         * @type {string}
         */
        devicename : '<',
        /**
         * Name of user (if CC context)
         * @type {string}
         */
        deviceuser : '<',
        /**
         * Total Today time in seconds
         * @type {number}
         */
        totalSecondsToday : '<',
        /**
         * Extension time in minutes
         * @type {number}
         */
        extTime : '<',
        /**
         * Last Sync Date
         * @type {string}
         */
        lastAzDateTime : '<',
        /**
         * Last App or Programname (readable name, eg. Wordpad)
         * @type {string}
         */
        lastAppName : '<?',
        /**
         * Last package or exe name (com.salfeld.cb3, wordpad.exe)
         * @type {string}
         */
        lastPkgName : '<?',
        /**
         * Remaining time today in seconds
         * @type {number}
         */
        totalDayRemaining : '<?',
        /**
         * Remaining reason
         * @type {number}
         */
        totalDayRemainingReasonId : '<?',
        /**
         * Until Action kind (0=normal, 1= xxx, 2=xxxx)
         * @type {number}
         */
        untilAction : '<',
        /**
         * Until Action DateTime (can be null)
         * @type {string}
         */
        untilDateTime : '<?',

    }
});

function parentsCardController(
    _,
    $log,
    $state,
    $http,
    $translate,
    API,
    chicoEnums,
    scgSharedFactory,
    moment


) {

    var ctrl = this;

    ctrl.scgSharedFactory = scgSharedFactory;
    ctrl.chicoEnums = chicoEnums;
    ctrl.loadingAppInfo = true;
    ctrl.singleAppEntry = "";
    ctrl.lastTimeFormated = "";
    ctrl.lastSyncDetail = "";
    ctrl.lastSyncDaysAgo = 0;
    ctrl.isToday = true;


    ctrl.$onInit = function() {
        ctrl.checkForToday();
        $log.info('parentsCard onInit, lastAzDate=' + ctrl.lastAzDateTime + '|devicename=' + ctrl.devicename + '|entry=' + ctrl.entry);
    };

    ctrl.$postLink = function() {
        $log.info('parentsCard postLink');

    };

    ctrl.$onChanges = function(changes) {
        $log.info('parentsCard onChanges=', changes)
    };


    ctrl.toggleSuggestMode = function() {
        ctrl.suggestmode=!ctrl.suggestmode;
    }

    ctrl.checkForToday = function() {
        if (ctrl.lastAzDateTime != null) {
            var statustime = ctrl.lastAzDateTime.substring(0, 10);
            ctrl.lastTimeFormated = moment(ctrl.lastAzDateTime).format('HH:mm');
        }
        var browsertime =  moment().format('YYYY-MM-DD');
        var a = moment(statustime);
        var b = moment(browsertime);
        var timeDiff = b.diff(a, 'days');
        ctrl.isToday = (timeDiff==0);
        if (timeDiff<0) timeDiff=timeDiff*-1;
        ctrl.lastSyncDaysAgo = timeDiff;

        if (statustime!=null && browsertime!=null) {
            if (statustime == browsertime) {
                ctrl.lastSyncDetail = $translate.instant('USAGE.LAST_TODAY') + ", " + ctrl.lastTimeFormated
            }
            if (timeDiff == 1) {
                ctrl.lastSyncDetail = $translate.instant('USAGE.LAST_YESTERDAY') + ", " + ctrl.lastTimeFormated
            }
            if (timeDiff == 2) {
                ctrl.lastSyncDetail = $translate.instant('USAGE.LAST_EREYESTERDAY') + ", " + ctrl.lastTimeFormated
            }
            if (timeDiff > 2) {
                if ($translate.use() == 'de_DE') {
                    ctrl.lastSyncDetail = "Vor " + timeDiff + " Tagen, " + ctrl.lastTimeFormated;
                } else {
                    ctrl.lastSyncDetail = timeDiff + " days ago, " + ctrl.lastTimeFormated;
                }
            }
            if (timeDiff > 30) {
                if ($translate.use() == 'de_DE') {
                    ctrl.lastSyncDetail = "Vor mehr als 30 Tagen...";
                } else {
                    ctrl.lastSyncDetail = "More than 30 days ago...";
                }
            }
        }


    }


}
