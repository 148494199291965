angular.module('ChicoApp').factory('ccEmailReportFactory', function(
  $http,
  $cookies,
  API,
  apiFunctions,
  scgSharedFactory) {

  var factory = {}; //Dont forget to init, this is also final return value!

  //all ng-models from specific sub-template must be listed here
  //---------------------------------------------------------------
  var apikeys = [
      'isEmailReportActive',
      'emailReportRecipient',
      'emailReportCC',
      'emailReportSubject',
      'isEmailReportDay',
      'isEmailReportWeek',
      'isEmailReportStart',
      'isEmailReportStop',
      'isEmailReportUnlockMsg',
      'isEmailReportSystemMsg',
      'blcheck',
      'isUsageDisabled',
      'hideUsageAny'
  ];
  var apikeys_param = apiFunctions.obj_to_url_params(apikeys);

  //main function called by controller
  //---------------------------------------------------------------
  factory.getSettings = function() {
    return factory.getByParams(apikeys_param);
  };

  //only helper func, is called from above func
  //---------------------------------------------------------------
  factory.getByParams = function(params) {
    var req = {
      method: 'GET',
      url: apiFunctions.get_cc_base_url() + 'ccsettings' + params
    };
    return $http(req);



  };

  //is called by watcher from controller
  //---------------------------------------------------------------
  factory.setSetting = function(obj) {
    scgSharedFactory.needsSync = true;
    var req = {
      method: 'POST',
      url: apiFunctions.get_cc_base_url() + 'ccsettings',
      data: obj
    };
    return $http(req);
  };

  return factory;

});

