'use strict';
angular.module('ChicoApp.Shared').controller('SCG-Modal-Kisikat-Controller', function(
    $scope,
    $http,
    $translate,
    $log,
    $window,
    $cookies,
    $location,
    $modalInstance,
    API,
    apiFunctions,
    modalFactory,
    scgSharedFactory,
    scgGlobalSpinnerFactory,
    AuthFactory,
    modal_settings)
{

    $scope.foo = "foo";

    $scope.editmode = false;
    $scope.oldkkitem = {};
    $scope.kkitem = {};
    $scope.kkdetail = {}; //from API
    $scope.contentLoading = true;
    $scope.noSuggest=false;
    $scope.scgSharedFactory = scgSharedFactory;


    $scope.modal_settings = modal_settings; //transfer settings to scope for use in template
    $scope.entry = $scope.modal_settings.entry;
    $scope.title = $scope.modal_settings.title;
    $scope.username = $scope.modal_settings.username;
    $scope.url = $scope.entry;
    $scope.kkKind = $scope.modal_settings.kkKind;
    $scope.reqLang = "de"; //default


    $window.ga('send', 'pageview', {page:'/modal-kisikat'});

    $scope.suggest = function() {
        if ($scope.kkitem.noSuggest == true)
        {
            $scope.noSuggest=true;
            $log.info('No suggests!');
            return;
        }

        angular.copy($scope.kkitem,$scope.oldkkitem);
        $scope.editmode=true;
    }


    $scope.lim = function() {

        scgGlobalSpinnerFactory.blockUI();
        $log.info('limit goes here!');

        $log.info('goto limits,kkKind=' + $scope.kkKind + '|entry=' + $scope.entry + '|username=' + $scope.username+ '|title=' + $scope.title);

        //We have to check for an existing kind first (check cc or cb if there is a username or not!)
        var url = "";


        if ($scope.username) {
            url = apiFunctions.get_cc_base_url() + 'cclists/' + $scope.entry;
        } else {
            if ($scope.kkKind==101) {
                url = API.url + 'cblists/lookupweb/' + $scope.entry + '/';
            }
            if ($scope.kkKind==102) {
                url = API.url + 'cblists/lookupapp/' + $scope.title + '/' + $scope.entry + '/';
            }
        }

        $log.info('limits, url=' + url);

        //Gather an existing kind!
        $http.get(url)
            .then(function success(response) {
                var kind = 1;
                var mode = 'add';
                var context = '';

                //set default for the kind
                if ($scope.kkKind==101) {
                    kind=7; //web always allowed
                    context = 'net';
                }
                if ($scope.kkKind==102 || $scope.kkKind==103) {
                    kind=1; //app or exe always allowed
                    context = 'prg';
                }
                if (response.data.kind && response.data.kind!=1) {
                    kind =response.data.kind;
                    mode='edit';
                }

                var modal_settings = {
                    title: $translate.instant('MODAL_EDIT_RECORD.TITLE_EDIT'),
                    refreshStateAfterClose: true
                };


                var entry = $scope.entry; //default is entry
                if ($scope.kkKind==102) {
                    entry = $scope.title; //in case of app, its title!
                    mode='edit'; //important to override in case of app!
                }

                if (mode=='edit')
                {
                    var editRecords = [];
                    var obj = {data: {'entry': entry}};
                    editRecords.push(obj);
                    modal_settings.editRecords = editRecords;
                } else {
                    modal_settings.editRecords = null;
                    modal_settings.newRecord = {
                        kind: kind,
                        texts: [entry]
                    };
                }
                modal_settings.context = context;
                modal_settings.mode = mode;
                scgGlobalSpinnerFactory.unblockUI();
                $log.info('limits var kind=' + kind + '|mode='+ mode + '|entry=' + entry);
                openEditModal(modal_settings);
            }, function error(response){
                $log.info('error');
            });
    }



    $scope.ok = function() {
        $log.info('savesuggest fired v2');
        $log.info('current:' , $scope.kkitem);
        $log.info('old:',$scope.oldkkitem);

        var haschanged = false;
        if ($scope.editmode && $scope.kkitem.age!=-1 && $scope.kkitem.age!=$scope.oldkkitem.age) {
            haschanged = true;
        }
        if ($scope.editmode && $scope.kkitem.catID!=-1 && $scope.kkitem.catID!=$scope.oldkkitem.catID) {
            haschanged = true;
        }
        if ($scope.editmode && $scope.kkitem.description.length>10 && $scope.kkitem.description!=$scope.oldkkitem.description) {
            haschanged = true;
        }
        $log.info('savesuggest ok fired, haschanged=' + haschanged);
        if (haschanged==true) {

            if ($scope.kkitem.entry=="salfeld.com" || $scope.kkitem.entry=="salfeld.de" || $scope.kkitem.entry=="salfeld.net") {
                modalFactory.infoModal.open({
                    modal_settings: {
                        title: $translate.instant('HEADER.INFO'),
                        message: $translate.instant('AGEMODAL.CHANGE_DENIED'),
                        butOkLabel: $translate.instant('BUTTON.OK'),
                        butCancelLabel: null,
                        iconNo: 3,
                        refreshStateAfterClose: true
                    },
                    callbacks: {
                        ok: function () {
                            $log.info('modal closed with ok -> callback');
                        }
                    }
                });
                return;
            }

            $scope.sendSuggest();
        }
    }

    $scope.sendSuggest = function() {

        if ($translate.use()== 'en_US') {
            $scope.kkitem.lang = "en";
        } else {
            $scope.kkitem.lang = "de";
        }


        var req = {
            method: 'POST',
            url: API.url + 'action/suggestkisikat',
            data: $scope.kkitem
        };
        return $http(req)
            .then(function success(response) {
                $log.info('http success respose=', response);
                return response;
            }, function error(err) {
                $log.info('error while HTTP');
                return err;
            });
    };


    var reqUrl = API.url + 'action/lookupkkurl'; //default kind=101 (Website)
    if ($scope.kkKind==102) {
        reqUrl = API.url + 'action/lookupkkapp';
    }
    if ($scope.kkKind==103) {
        reqUrl = API.url + 'action/lookupkkexe';
    }

    $log.info("kkKind=" + $scope.kkKind + "|title=" + $scope.title + '|url=' + $scope.url);


    if ($translate.use()== 'en_US') {
        $scope.reqLang = "en";
    }

    var req = {
        method: 'POST',
        url: reqUrl,
        data: '{ "url" : "' + $scope.url + '", "lang" : "' + $scope.reqLang + '"}'
    };

    return $http(req)
        .then(function success(response) {
            $log.info('http success modal respose=', response);

            $scope.kkitem = response.data;
            $scope.kkitem.entry = response.data.URL;
            $scope.kkitem.kkKind = $scope.kkKind;


            $log.info('http success noSuggest=', $scope.kkitem.noSuggest);

            if (!response.data.description) {
                $scope.kkitem.description = $translate.instant('AGEMODAL.NO_DESCRIPTION');
            } else {
                $scope.kkitem.description = response.data.description;
            }
            //Assign the title
            if ($scope.kkKind==101){
                $scope.kkitem.title = $scope.entry; //website case (101)
                $scope.kkitem.icourl = "http://" + response.data.URL + "/favicon.ico";
            }
            if ($scope.kkKind==102){
                $scope.kkitem.title = $scope.title; //app case (102)
                $scope.kkitem.icourl = scgSharedFactory.getImgApp($scope.url);
            }
            if ($scope.kkKind==103){
                $scope.kkitem.title = $scope.title; //exe case (103)
                $scope.kkitem.icourl = scgSharedFactory.getImgPrg($scope.url);
            }

            $scope.contentLoading=false;
        });




    function openEditModal(modalSettings){
        //open edit modal for this entry
        modalFactory.editModal.open({
            modal_settings: modalSettings,
            callbacks: {
                ok: function() {},
                cancel: function() {}
            }
        });
    }



});
