angular.module('ChicoApp').factory('sbSettingsFactory', function(
    $http,
    apiFunctions,
    $log,
    API) {

    var factory = {};


    factory.deleteDevice = function() {
        var req = {
            method: 'GET',
            timeout: 30000,
            url: API.url + 'action/deletedevice'
        };
        return $http(req);
    };


    return factory;

});
