angular.module('ChicoApp.Shared').directive('switchablePortlet', function(
    $parse,
    $rootScope) {

  return {

    scope: {
      ngEnabledWhen: '@',
      ngDisabledWhen: '@',
      ngModel: '=ngModel'
    },

    controller: function($scope, $element) {

      $scope.$watch('ngModel', function() {
        valueChanged();
      });

      //when a datatable is redrawn. rerun dom manupulation
      $rootScope.$on('DTfnDrawCallback', function() {
        valueChanged();
      });

      var valueChanged = function() {

        var isEnabled = $scope.ngModel;
        if ($scope.ngEnabledWhen != undefined) {
          isEnabled = $parse($scope.ngEnabledWhen)($scope);
        }

        var isDisabled = !$scope.ngModel;
        if ($scope.ngDisabledWhen != undefined) {
          isDisabled = $parse($scope.ngDisabledWhen)($scope);
        }

        if (isEnabled !== undefined) {
          setEnabled(isEnabled);
        }else if (isDisabled !== undefined) {
          setEnabled(isDisabled);
        }

      };

      var setEnabled = function(isDisabled) {

        var panel_body = $element.find('.panel-body');

        panel_body.find('button, input, .btn').attr('disabled', !isDisabled);

        if (!isDisabled) {
          panel_body.find('.nav-tabs li').addClass('disabled');
            panel_body.find('img').addClass('scg-opacity');
            panel_body.find('select').attr('disabled',true);
            panel_body.find('button').addClass('btn-default');
          panel_body.find('button').removeClass('btn-primary');
          panel_body.find('.radio-list, .checkbox-list span, tr, span, label, h4, input, button, a')
                    .addClass('disabled-text-color');
        }else {
          panel_body.find('.nav-tabs li')
                    .removeClass('disabled');
          panel_body.find('img').removeClass('scg-opacity');

            panel_body.find('select').attr('disabled',false);
            panel_body.find('button').removeClass('btn-default');
          panel_body.find('button').addClass('btn-primary');
          panel_body.find('.radio-list, .checkbox-list span, tr, span, label, h4, input, button, a')
                    .removeClass('disabled-text-color');
        }

      };

    }
  };

});

