
var ua = 'User-agent header sent: ' + navigator.userAgent;
window.isOldIE = false;

if (ua && ua.match('MSIE 8|MSIE 7|MSIE 6')) {
  window.isOldIE = true;
  alert('Neuer Internet Explorer benötigt!');
  document.body.innerHTML = '<h1>Wichtig / Important</h1><br>' +
  'Diese Anwendung ben&ouml;tigt Internet Explorer 9 oder h&ouml;her<br>' +
  'This application needs at least Internet Explorer 9 or higher<br><br>' +
  '<a href=\'http://windows.microsoft.com/ie/\'>Link: Download Internet Explorer</a></b>';
}
