angular.module('ChicoApp.Shared').component('columnChart', {
    templateUrl: 'templates/shared/columnChart/columnChart.component.html',
    controller: ColumnChartController,
    bindings: {
        /**
         * Height of the chart in pixels
         * @type {number}
         */
        height: '<',
        /**
         * CSS color of the chart bars
         * @type {string}
         */
        barColor: '@',
        /**
         * CSS color of the second chart bars if datasecondary > 0
         * @type {string}
         */
        barColorsecondary: '@',
        /**
         * CSS color of a chart bar when hovering above it.
         * Optional - only required if onColumnClick is defined.
         * @type {string}
         */
        barColorHover: '@?',
        /**
         * Each item of the array represents the value of one bar.
         * A value should be a percentage (0-100).
         * @type {number[]}
         */
        data: '<',
        /**
         * Each item of the array represents the secondary value of one bar (roaming time)
         * @type {number[]}
         */
        datasecondary: '<',
        /**
         * Set to true if you do not want to show roamingBadge as info above the chart
         * @type {boolean}
         */
        hideBadge: '<?',
        /**
         * Each item of the array represents the foreground color of one bar.
         * @type {string[]}
         */
        hideTooltips: '<?',
        /**
         * Set to true if captions should not be displayed below the chart (they're still displayed in the tooltip)
         * @type {?boolean} True if captions should not be displayed (optional, default false)
         */
        hideCaptions: '<?',
        /**
         * Caption for each bar defined in the data array.
         * A Caption is the category of a bar (e.g. Monday in a chart where each bar represents a weekday).
         * @type {string[]}
         */
        captions: '<',
        /**
         * Set to true if subCaptions should not be displayed below the chart (they're still displayed in the tooltip)
         * @type {?boolean} True if subCaptions should not be displayed (optional, default false)
         */
        hideSubCaptions: '<?',
        /**
         * SubCaption for each bar defined in the data array.
         * A subCaption is a label of a bar. It contains the data value of the bar in a human readable format
         * (e.g. 6h or 25% if 100% of the bar are 24 hours and the data value is 0.25).
         * A subCaption should contain the data value in a format that can be displayed to the user (e.g. 50% instead of 0.5).
         * @type {string[]}
         */
        subCaptions: '<',
        /**
         * Set to true if a horizontal scrollbar should be shown if content overflows
         * @type {?boolean} True if horizontal scrollbar should be shown on overflow (optional, default false)
         */
        showScrollbarOnOverflow: '<',
        /**
         * Function that will be called if a column was clicked
         * @type {Function}
         * @param {number} colIndex Index in the data array of the column which was clicked
         * @param {number} colData  Data value of the column which was clicked
         */
        onColumnClick: '&?',
        /**
         * If any text is given, an overlay will be shown displaying this text (optional)
         * @type {string}
         */
        overlayText: '<?',
        /**
         * An optional URL that is requested if the overlay text is clicked (optional)
         * @type {string}
         */
        overlayUrl: '<?'
    }
});

function ColumnChartController() {
    var ctrl = this;

    /**
     * Contains current color for each bar (the color is changed while hovering above a bar)
     */
    ctrl.actualBarColor = [];
    ctrl.actualBarColorsecondary = [];


    ctrl.$onInit = function() {
        if (typeof ctrl.hideTooltips === 'undefined') {
            ctrl.hideTooltips = true;
        }

        if (typeof ctrl.hideCaptions === 'undefined') {
            ctrl.hideCaptions = false;
        }
        
        if (typeof ctrl.hideSubCaptions === 'undefined') {
            ctrl.hideSubCaptions = false;
        }

        if (typeof ctrl.showScrollbarOnOverflow === 'undefined') {
            ctrl.showScrollbarOnOverflow = false;
        }
    };

    ctrl.$onChanges = function (changes) {
        if ((changes.data) && Array.isArray(ctrl.data)) {
            ctrl.actualBarColor = [];
            ctrl.actualBarColorsecondary = [];
            for (var i = 0; i < ctrl.data.length; i++) {
                ctrl.actualBarColor.push(ctrl.barColor);
                ctrl.actualBarColorsecondary.push(ctrl.barColorsecondary);
            }
        }
    }

    ctrl.toggleBarColor = function(i, isHovering) {
        // Only apply hover style if click event is handled and if hover color is defined
        if (typeof ctrl.onColumnClick === 'undefined' || typeof ctrl.barColorHover === 'undefined') {
            return;
        }
        if (isHovering === true) {
            ctrl.actualBarColor[i] = ctrl.barColorHover;
        } else {
            ctrl.actualBarColor[i] = ctrl.barColor;
        }
    };

    ctrl.columnClick = function(colIndex) {
        if (typeof ctrl.onColumnClick !== 'undefined' && ctrl.onColumnClick !== null) {
            ctrl.onColumnClick({ colIndex: colIndex, colData: ctrl.data[colIndex] });
        }
    };
}
