'use strict';
angular.module('ChicoApp.Parents', [])
    .config(function($stateProvider) {
        $stateProvider.state('parents', {
            url: '/parents',
            controller: 'ParentsController',
            templateUrl: 'templates/parents/templates/parents.html',
        })
    })
    .run(function() {});
