angular.module('ChicoApp.Shared').controller('FooterController', function(
    $state,
    $scope,
    $cookies,
    $translate,
    $http,
    API,
    $log,
    modalFactory,
    scgSharedFactory,
    scgGlobalSpinnerFactory) {

  $scope.scgGlobalSpinnerFactory = scgGlobalSpinnerFactory;
  $scope.$state = $state;

  $scope.changeLang = function(lng) {
    $translate.use(lng);
    $cookies.put('language',lng);
  };

  $scope.gotoSupport = function() {
    window.open($translate.instant('URL.SUPPORT'), '_blank');
  };

  //Show faq only on german site for now
  $scope.showFaq = false;
  if ($cookies.get('language') == 'de_DE') {
    $scope.showFaq = true;
  }

  $scope.whatsNew = function() {
    var req = {
      method: 'GET',
      url: API.url + 'service/whatsnew'
    };
    $http(req)
        .then(function success(response) {
          $log.info('Done with HTTP 200!, resp=' , response);
          var myhtml = ''; //parse it
          angular.forEach(response.data, function(news) {
            if ($translate.use() == 'de_DE') {
              myhtml = myhtml.concat(scgSharedFactory.getLocalizedDateString(news.NewsDate)).concat('  |  ').concat(news.NewsDE).concat('<br>');
            } else {
              myhtml = myhtml.concat(scgSharedFactory.getLocalizedDateString(news.NewsDate)).concat('  |  ').concat(news.NewsEN).concat('<br>');
            }
          });
          modalFactory.infoModal.open({
            modal_settings: {
              title: $translate.instant('MISC.WHATSNEW'),
              message: myhtml,
              butOkLabel: $translate.instant('BUTTON.OK'),
              butCanelLabel: '',
              iconNo: 0,
              refreshStateAfterClose: false
            },
            callbacks: {
              ok: function() {
                $log.info('CALLBACK OK');
              },
              cancel: function() {
                $log.info('CALLBACK CANCEL');
              }

            }
          });

        });
  };

  $scope.openFeedbackModal = function() {
    //Note: strings for captionbs are set inside the feedbckModal
    //appInsights.trackEvent("Modal: Hey Salfeld");
    modalFactory.feedbackModal.open({
      modal_settings: {
        title: '',
        message: '',
        butOkLabel: '',
        butCanelLabel: '',
        refreshStateAfterClose: true
      },
      callbacks: {
        ok: function() {
          $log.info('modal closed with ok -> callback');
          modalFactory.okModal(1, $translate.instant('MSG.DATASENT'));
        },
        cancel: function() {
          $log.info('modal closed with cancel -> callback');
        }
      }
    });
  };

  $scope.gotoFaq = function() {
    $log.info('gotofaq');
    modalFactory.faqModal.open({
      modal_settings: {
        title: '',
        message: '',
        butOkLabel: '',
        butCanelLabel: '',
        refreshStateAfterClose: false
      },
      callbacks: {
        ok: function() {
          $log.info('modal closed with ok -> callback');
          modalFactory.okModal(1, $translate.instant('MSG.DATASENT'));
        },
        cancel: function() {
          $log.info('modal closed with cancel -> callback');
        }
      }
    });
  };

});
