'use strict';
angular.module('ChicoApp').config(function($stateProvider) {
    $stateProvider.state('dev', {
        url: '/dev',
        views: {
            "@": {
                component: 'devComponent'
            }
        }
    });
});