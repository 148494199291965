'use strict';
angular.module('ChicoApp.CC-Wizard')

    .controller('CC-Wizard-5-Controller', function(
        $rootScope,
        $scope,
        $state,
        $http,
        $timeout,
        $translate,
        $log) {


        $scope.emailactive = false;


        $scope.goNext = function() {
            $log.info('goNext triggered');
            $state.go('cc-wizard.6');
        }

        $scope.goBack = function() {
            $log.info('goBack triggered');
            $state.go('cc-wizard.4');
        }




    });
