/**
 * Component for Tooltips. Don't use this component without the tooltipDirective.
 */
angular.module('ChicoApp.Shared').component('scgTooltipComponent', {
    templateUrl: 'templates/shared/tooltip/tooltip.component.html',
    controller: TooltipComponentController,
    bindings: {
        /**
         * Position of the tooltip.
         * @type {"top"|"right"|"bottom"|"left"}
         */
        position: '<',
        text: '<'
    }
});

function TooltipComponentController() {
    var ctrl = this;
}