'use strict';
angular.module('ChicoApp.CC-Internet')

.controller('CC-Internet-Allowed-Controller', function(
  $scope,
  $state,
  $translate,
  ccListsFactory,
  DTOptionsBuilder,
  DTColumnDefBuilder,
  $log,
  scgDataTableFactory) {

  var modalStartKind = ccListsFactory.listKinds.list_kind_allowed_kw;
  $log.info('CC-Internet-Allowed-Controller');

  $scope.tableHeaders = [$translate.instant('HEADER.DOMAIN')];

  $scope.data = {};
  $scope.data.table_api = [];

  scgDataTableFactory.prepareScopeForTable($scope, modalStartKind);
  $scope.dtOptions = scgDataTableFactory.getDefaultDtOptions($scope);

  $scope.dtColumnDefs = scgDataTableFactory.getDefaultDtColumnDefs4Cols();

  $scope.reload = function() {
    $state.go($state.current, {}, {reload: true});
  };

  var loadList = function() {
    $scope.contentLoading = true;
    ccListsFactory.getDetailedByListFilter(modalStartKind)
            .then(function success(response) {
              $scope.fillInnerListFromApiList(response.data);
              $scope.data.table_api = response.data;
              $scope.contentLoading = false;
            });
  };

  loadList();

});
