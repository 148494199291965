angular.module('ChicoApp').factory('cbBrowsertimeFactory', function(
  $http,
  API,
  apiFunctions,
  $log) {

  var factory = {};

  var apifields_browser = [
      'isTimeLimitActive',
      'timeLimitDay',
      'timeLimitWeek',
      'timeLimitMonth',
      'isWeekDayLimitActive',
      'timeLimitMonday',
      'timeLimitTuesday',
      'timeLimitWednesday',
      'timeLimitThursday',
      'timeLimitFriday',
      'timeLimitSaturday',
      'timeLimitSunday',
      'blockTimesBrowser',
      'noExtensionInBlockTime',
      'noAppsInBlockTime'

  ];

  var apifields_browser_param = apiFunctions.obj_to_url_params(apifields_browser);

  factory.getBrowserSettings = function() {
    return factory.getSettingsByParams(apifields_browser_param);
  };

  factory.getSettingsByParams = function(params) {
    var req = {
      method: 'GET',
      url: API.url + 'cbsettings' + params
    };
    return $http(req);
  };

  factory.saveSettings = function(settings_scope) {
    factory.setSettings(settings_scope);
  };

  factory.setSetting = function(obj) {
    $log.info('needsSync = TRUE!');
    var req = {
      method: 'POST',
      url: API.url + 'cbsettings',
      data: obj
    };
    return $http(req);
  };

  factory.setSettings = function(keyvalue_arr) {
    $log.info('needsSync = TRUE!');
    var req = {
      method: 'POST',
      url: API.url + 'cbsettings',
      data: keyvalue_arr
    };
    return $http(req);
  };

  return factory;
});
