angular.module('ChicoApp').factory('gpsFactory', function(
  $http,
  $log,
  $cookies,
  API) {

  var gps = {};

  gps.get = function() {
    var req = {
      method: 'GET',
      //url: API.url + 'cbsettings/gpsposition'
      url: API.url + 'action/gps'
    };
    return $http(req)
            .then(function success(response) {
              $log.info('URL was:' + req.url);
              $log.info('response is:' , response);
              return response;
            }, function error(err) {
              $log.info('error while HTTP GET');
              return err;
            });
  };

  gps.getAsync = function() {
    var req = {
      method: 'GET',
      //url: API.url + 'cbsettings/gpsposition'
      url: API.url + 'action/gpsget'
    };
    return $http(req)
            .then(function success(response) {
              $log.info('URL was:' + req.url);
              $log.info('response is:' , response);
              return response;
            }, function error(err) {
              $log.info('error while HTTP GET');
              return err;
            });
  };

  gps.requestAsync = function() {
    //This fires initial GPS (sets in WebAPI pos to 0|0, fire only once!
    var req = {
      method: 'GET',
      url: API.url + 'action/gpsAsync'
    };
    return $http(req)
            .then(function success(response) {
              return response;
            }, function error(err) {
              $log.info('error while HTTP GET');
              return err;
            });
  };

  gps.request = function() {
    // var lat = 0;
    // var long = 0;

    $log.info('Request goes here');
  };

  /*
      gps.reset = function (keyvalue_arr) {
          var req = {
              method: 'POST',
              url: API.url + 'user123/ccsettings/',
              data: keyvalue_arr
          };
          return $http(req)
              .then(function success(response) {
                  return response;
              }, function error(err) {
                  return err;
              });
      };
*/

  return gps;

});
