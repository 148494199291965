/*thisisaplaceholderforAPI-Factories*/
angular.module('ChicoApp').factory('cbSettingsFactory', function(
    $http,
    $cookies,
    $log,
    API,
    _
) {

    var factory = {};

    var apikeys = [
        'generalWarn1',
        'generalWarn2',
        'generalWarn3',
        'generalWarn5',
        'generalWarn3Value',
        'generalWarn5Value',
        'imgBackgroundURL',
        'imgBackgroundColor',
        'protectSettingsLevel',
        'oneSecInterval',
        'hasCrackProtection',
        'noExtensionDeleteAuto',
        'noBonusNotification',
        'noBlockNotification',
        'noWhatsappVideo',
        'forceFastboot',
        'neverBlockAlwaysAllowed',
        'noBlockDualScreen',
        'wakeupDevice',
        'alwaysBlockNotification',
        'noAudioStop',
        'disablePopup'
    ];

    var apikeys_param = '';

    _.each(apikeys, function(key, index) {
        if (index === 0) {
            apikeys_param += '?';
        }else {
            apikeys_param += '&';
        }
        apikeys_param += 'key=' + key;
    });

    factory.getSettings = function() {
        $log.info('INSIDE GET CBSETTINGS');
        var req = {
            method: 'GET',
            url: API.url + 'cbsettings' + apikeys_param
        };
        return $http(req)
            .then(function success(response) {
                return response;
            }, function error(err) {
                $log.info('error while getting setting');
                return err;
            });
    };

    factory.setSetting = function(obj) {
        var req = {
            method: 'POST',
            url: API.url + 'cbsettings/',
            data: obj
        };
        return $http(req);
    };


    factory.setSettings = function(keyvalue_arr) {
        var req = {
            method: 'POST',
            url: API.url + 'cbsettings/',
            data: keyvalue_arr
        };
        return $http(req)
            .then(function success(response) {
                return response;
            }, function error(err) {
                return err;
            });
    };

    return factory;
});
