/*thisisaplaceholderforAPI-Factories*/
angular.module('ChicoApp').factory('apiFunctions', function(
  $http,
  $cookies,
  API,
  _,
  AuthFactory) {
  var f = {};

  /*
  * Convert an api fields list to url params string
  */
  f.obj_to_url_params = function(obj, paramKeyName) {
    var keyName = paramKeyName || 'key';

    var param_string = '';
    _.each(obj, function(key, index) {
      if (index === 0) {
        param_string += '?';
      }else {
        param_string += '&';
      }
      param_string += keyName + '=' + key;
    });
    return param_string;
  };

  /*
  * Get current user for URL creation
  */
  f.get_user_for_url = function() {
    if (AuthFactory.activeUser) {
      return AuthFactory.activeUser.username + '/';
    }else if ($cookies.user) {
      return $cookies.user + '/';
    }
    return '';
  };

  /*
  * Get CC base url with trailing slash
  */
  f.get_cc_base_url = function() {
    return API.url + f.get_user_for_url();
  };

  f.get_cc_action_url = function() {
    return API.url + 'action/' + f.get_user_for_url();
  };

  return f;
});
