angular.module('ChicoApp').factory('cbWebFilterFactory', function(
  $http,
  $cookies,
  API,
  apiFunctions,
  $log,
  scgSharedFactory) {

  var factory = {}; //Dont forget to init, this is also final return value!

  //all ng-models from specific sub-template must be listed here
  //---------------------------------------------------------------
  var apikeys = [
      'isWebFilterActive',
      'isWebLogActive',
      'isNewWebsitesBlocked',
      'webFilterAge',
      'webFilterAgeNotifyOpt'
  ];

  var apikeys_param = apiFunctions.obj_to_url_params(apikeys);

  //main function called by controller
  //---------------------------------------------------------------
  factory.getSettings = function() {
    return factory.getByParams(apikeys_param);
  };

  //only helper func, is called from above func
  //---------------------------------------------------------------
  factory.getByParams = function(params) {
    var req = {
      method: 'GET',
      url: API.url + 'cbsettings' + params
    };
    return $http(req);
  };

  //is called by watcher from controller
  //---------------------------------------------------------------
  factory.setSetting = function(obj) {
    scgSharedFactory.needsSync = true;
    var req = {
      method: 'POST',
      url: API.url + 'cbsettings/',
      data: obj
    };
    return $http(req);
  };


   factory.export = function(kind) {
        var listType = 'blacklist'; //default
        if (kind==2) {
            listType='whitelist';
        }
        var req = {
            method: 'GET',
            url: API.url + 'allusers/service/export/cb/' + listType
        };
        return $http(req);
    };



    return factory;

});
