angular.module('ChicoApp').factory('ccSecurityFactory', function(
    $http,
    apiFunctions,
    $log,
    API,
    scgSharedFactory) {

  var factory = {};

  var apifields_security = [
      'isDisableCMD',
      'isDisableRegedit',
      'isDisableControlPanel',
      'isDisableUserAccounts',
      'isDisableStartRun',
      'isDisableStartSearch',
      'isDisableTaskmanager',
      'isDisableTimeDate',
      'isDisableLockStation',
      'isDisableSafeBoot',
      'isDisableSysRestore',
      'isDisableFastUserSwitching',
      'DrvLock',
      'isDisableIcon',
      'SecLevel'
  ];

  var allusers_apifields_security = [
      'isDisableSafeBoot',
      'isDisableSysRestore',
      'isDisableFastUserSwitching'
  ];

  var apifields_security_param = apiFunctions.obj_to_url_params(apifields_security);
  var allusers_apifields_security_param = apiFunctions.obj_to_url_params(allusers_apifields_security);

  $log.info('ccsecurity factory');

  // -------------------- ALLUSERS ----------------------------------------------

  factory.getAllUsersSecuritySettings = function() {
    $log.info('getAllUsersSecuritySettings');
    return factory.getAllUsersSettingsByParams(allusers_apifields_security_param);
  };

  factory.getAllUsersSettingsByParams = function(params) {
    $log.info('AllUsers url is:' + API.url + 'ccsettings/' + params);
    var req = {
      method: 'GET',
      url: API.url + 'allusers/ccsettings/' + params
    };
    $log.info('return val=' + $http(req));
    return $http(req);
  };

  factory.setAllUsersSetting = function(obj) {
    var req = {
      method: 'POST',
      url: API.url + 'allusers/ccsettings',
      data: obj
    };
    return $http(req);
  };

  factory.setAllUsersSettings = function(keyvalue_arr) {
    var req = {
      method: 'POST',
      url: API.url + 'allusers/ccsettings',
      data: keyvalue_arr
    };
    return $http(req);
  };

  //------------------------ SINGLE USER ---------------------------------------------

  factory.getSecuritySettings = function() {
    $log.info('getSecuritySettings');
    return factory.getSettingsByParams(apifields_security_param);
  };

  factory.getSettingsByParams = function(params) {
    $log.info('url is:' + apiFunctions.get_cc_base_url() + 'ccsettings' + params);
    var req = {
      method: 'GET',
      url: apiFunctions.get_cc_base_url() + 'ccsettings' + params
    };
    $log.info('return val=' + $http(req));
    return $http(req);
  };

  factory.saveSettings = function(settings_scope) {
    factory.setSettings(settings_scope);
  };

  factory.setSetting = function(obj) {
    scgSharedFactory.needsSync = true;
    var req = {
      method: 'POST',
      url: apiFunctions.get_cc_base_url() + 'ccsettings',
      data: obj
    };
    return $http(req);
  };

  factory.setSettings = function(keyvalue_arr) {
    scgSharedFactory.needsSync = true;
    var req = {
      method: 'POST',
      url: apiFunctions.get_cc_base_url() + 'ccsettings',
      data: keyvalue_arr
    };
    return $http(req);
  };

  return factory;
});
