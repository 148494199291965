'use strict';
angular.module('ChicoApp.CC-Internet')

    .controller('CC-InternetController-V2', function(
        $scope,
        $state,
        $modal,
        $window,
        $translate,
        AuthFactory,
        ccListsFactory,
        ccInternetFactory,
        ccInternetMiscFactory,
        scgGlobalSpinnerFactory,
        modalFactory,
        $log,
        _,
        scgSharedFactory) {

        $scope.data = {};



        $scope.data.isWebFilterActive = true;
        $scope.data.internetFilterLevel = 2;

        $scope.data.webBlockedCategory11 = true;
        $scope.data.webBlockedCategory12 = true;
        $scope.data.webBlockedCategory13 = true;
        $scope.data.webBlockedCategory14 = false;


        $log.info("this is internet v2");

    });
