angular.module('ChicoApp.Shared').controller('HeaderController', function(
    $scope,
    $rootScope,
    $state,
    $http,
    $cookies,
    $interval,
    $translate,
    $stateParams,
    $window,
    $log,
    API,
    AuthFactory,
    modalFactory,
    scgGlobalSpinnerFactory) {

 

  $scope.openMobileSidebar = function() {
      $window.scrollTo(0, 0);
      $rootScope.$emit("openMobileSidebar");
  };

  $scope.logout = function(){
    AuthFactory.logout(true);
  }

  //DO NOT WATCH HERE, REPLACED BY hasCHANGED calll
  //DIERK: KEEP AS EXAMPLE FOR A SINGLE VARIABLE WATCHER
  /* $scope.$watch(function(){
   return AuthFactory.activeDevice;
 }, function(nV, oV){
   if(nV && nV != oV){
     console.log('NEEDSSYNC=FALSE 8');
     scgSharedFactory.needsSync = false;
   }
 });*/


  $scope.syncInfoModal = function(msg) {
    scgGlobalSpinnerFactory.unblockUI();
    modalFactory.infoModal.open({
      modal_settings: {
        title: $translate.instant('HEADER.INFO'),
        message: msg,
        butOkLabel: $translate.instant('BUTTON.OK'),
        butCancelLabel: null,
        iconNo: 1,
        refreshStateAfterClose: true
      },
      callbacks: {
        ok: function() {
          $log.info('modal closed with ok -> callback');
        }
      }
    });
  };


    //$scope.userRole = AuthFactory.userRole;

    $scope.$watch(function() {
        return AuthFactory.userRole;
    }, function(newVal) {
        $scope.userRole = AuthFactory.userRole;
    });

  $scope.$watch(function() {
    return AuthFactory.loggedin();
  }, function(newVal) {
    if (newVal == true) {
      $scope.loggedin = true;
    } else {
      $scope.loggedin = false;
    }

  });

  $scope.pagetitle = $stateParams.title;

  $scope.goToDashboard = function() {

      //if we are already locked out (nomore pcId cookie), then do nothing (report role!)
      if ($cookies.get('pcId')) {
       if (AuthFactory.activeContext == 'cc') {
              $log.info('gocc');
              $state.go('cc-dashboard-v3');
          } else {
              $log.info('gocb');
              if ($cookies.get('cb2') == "true") {
                  $state.go('cb-dashboard');
              }
              else {
                  $state.go('cb-dashboard-v3');
              }
          }
      } else {
          $log.info('gotoDashboard no pcId');
      }
  };

  $scope.checkSession = function() {
      var req = {
          method: 'GET',
          url: API.url + 'action/sessioncheck'
      };
      $http(req).then(function success() {
          $log.info('HEADER Sessioncheck we are still online');
      })
          .catch(function error(error) {
              $log.info('HEADER Sessioncheck EXPIRED');
          });
  }

  $scope.gotoManual = function() {
      var myurl='';
      $log.info('goto manual, our context=' + AuthFactory.activeContext);
      if (AuthFactory.activeContext == 'cb') {
          myurl = $translate.instant('URL.MANUAL_CB');
          window.open(myurl, "_blank");
      }
      if (AuthFactory.activeContext == 'cc') {
          myurl = $translate.instant('URL.MANUAL_CC');
          window.open(myurl, "_blank");
      }
  }


  //Recurring Timer, update each 30 Seconds statusInfo!
  var intervalStatusInfo = $interval(function () {
    $scope.checkSession();
    $log.info('HEADER TIMER SESSION CHECK');
  }, 60 * 1000);

});
