angular.module('ChicoApp').component('webImportExport', {
    templateUrl: 'templates/shared/webImportExport/webImportExport.component.html',
    controller: WebImportExportController,
    bindings: {
        selectModel : '<'
    }
});

function WebImportExportController(
    $translate,
    $state,
    $window,
    _,
    moment,
    $log,
    $http,
    AuthFactory,
    cbWebFilterFactory,
    ccInternetMiscFactory,
    scgGlobalSpinnerFactory,
    API,
    apiFunctions,
    chicoEnums
) {

    var ctrl = this;

    ctrl.data = {};
    ctrl.exportlist = "";
    ctrl.importSuccess = false;
    ctrl.importFailed = false;

    //ctrl.selectModel = 2;       //select model coming from state params

    ctrl.onBtnGo = function() {
        $log.info('onBtnGo pressed, context=' + AuthFactory.activeContext + '|selectModel=' + ctrl.selectModel);
        ctrl.exportWeb(AuthFactory.activeContext,ctrl.selectModel);
    }

    ctrl.onBtnSelectChange = function() {
        ctrl.exportlist = "";       //we must clear the list
        ctrl.importSuccess=false;
        ctrl.importFailed=false;
        $log.info('onBtnSelectChange|selectModel=' + ctrl.selectModel);
    }

    ctrl.onBtnBack = function() {
        var context = AuthFactory.activeContext;
        $log.info('onBtnBack context=' + context);
        if (context=='cb') {
            $state.go('cb-webfilter-v2');
        } else {
            $state.go('cc-webfilter-v2');
        }
    }

    ctrl.onBtnImport = function() {
        $log.info('onBtnImport');

        ctrl.importWeb(AuthFactory.activeContext,ctrl.selectModel);
    }




    ctrl.exportWeb = function(context,selectModel) {

        //kind=1 blacklist, kind=2 whitelist, remap from selectModel
        var kind=0;
        if (selectModel==0) kind=2;
        if (selectModel==1) kind=1;

        scgGlobalSpinnerFactory.blockUI();

        if (context=='cb') {
            cbWebFilterFactory.export(kind).then(function success(response) {
                scgGlobalSpinnerFactory.unblockUI();
                var res = String(response.data);
                var list = res.replace(new RegExp(',', 'g'), '\n');
                ctrl.exportlist = list;
            });
        } else if (context=='cc') {
            ccInternetMiscFactory.export(kind).then(function success(response) {
                scgGlobalSpinnerFactory.unblockUI();
                var res = String(response.data);
                var list = res.replace(new RegExp(',', 'g'), '\n');
                ctrl.exportlist = list;
            });
        }
    }

    ctrl.importWeb = function(context,selectModel)
    {
        var importkind = 7; //default
        if (selectModel==2) importkind=7;
        if (selectModel==3) importkind=5;

        if (ctrl.exportlist.length<3)
        {
            ctrl.setImportSuccess(false);
            return;
        }

        $log.info('import selectModel=' , selectModel);
        var postString = ctrl.exportlist.replace(" ", "").replace(";", "");

        $log.info('import poststring=' , postString);

        var urlpath =apiFunctions.get_cc_base_url() + 'ccusage/import/' + importkind;
        if (context=='cb') {urlpath =apiFunctions.get_cc_base_url() + 'cbusage/import/' + importkind;}

        var req = {
            method: 'POST',
            url: urlpath,
            data: '"' + postString + '"'
        };

        return $http(req).then(function success(){
            ctrl.exportlist = "";
            ctrl.setImportSuccess(true);
        }, function error() {
            ctrl.setImportSuccess(false);
        });

    }


    ctrl.setImportSuccess = function(status) {
        if (status) {
            ctrl.importSuccess = true;
            ctrl.importFailed = false;
        } else {
            ctrl.importSuccess = false;
            ctrl.importFailed = true;
        }
    }


    $log.info('welcome importExport selectModel=' + ctrl.selectModel);
    if (ctrl.selectModel==null) ctrl.selectModel=0;

    if (ctrl.selectModel===0 || ctrl.selectModel===1) {
        $log.info('welcome importExport LETS GO');
        ctrl.onBtnGo();
        }



}
