'use strict';
angular.module('ChicoApp.CB-Appfilter')

    .controller('CB-Appfilter-Allowed-Controller', function(
        $scope,
        $state,
        $translate,
        cbListsFactory,
        DTOptionsBuilder,
        DTColumnDefBuilder,
        scgSharedFactory,
        scgDataTableFactory) {

      var modalStartKind = 1;

      $scope.tableHeaders = [
          $translate.instant('HEADER.ENTRY'),
          $translate.instant('HEADER.DETAILS'),
          $translate.instant('HEADER.INSTALLDATE')
      ];

      $scope.data = {};
      $scope.data.table_api = [];

      scgDataTableFactory.prepareScopeForTable($scope, modalStartKind);
      $scope.dtOptions = scgDataTableFactory.getDefaultDtOptions($scope);

      $scope.dtColumnDefs = scgDataTableFactory.getDefaultDtColumnDefs5Cols();

      $scope.reload = function() {
        $state.go($state.current, {}, {reload: true});
      };

      var loadList = function() {
        $scope.contentLoading = true;
        cbListsFactory.getDetailedByListFilter(modalStartKind)
          .then(function success(response) {
            $scope.fillInnerListFromApiList(response.data);
            $scope.data.table_api = response.data;
            $scope.contentLoading = false;
          });
      };

      loadList();

    });
