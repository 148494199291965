'use strict';
angular.module('ChicoApp.CB-Timelimits')
    .controller('CB-TimelimitsController', function ($rootScope,
                                                     $scope,
                                                     $state,
                                                     $http,
                                                     moment,
                                                     $timeout,
                                                     $translate,
                                                     $window,
                                                     $location,
                                                     $document,
                                                     $anchorScroll,
                                                     modalFactory,
                                                     AuthFactory,
                                                     _,
                                                     $log,
                                                     cbTimelimitsFactory,
                                                     cbDashboardFactory,
                                                     scgValidationFactory,
                                                     scgSharedFactory,
                                                     scgGlobalSpinnerFactory) {

        var timeTableSaveQueue;

        $scope.hasCBFeature = scgSharedFactory.hasCBFeature;
        $scope.isTouchDevice = false;
        $scope.data = {};
        $scope.hintHide = true;
        $scope.contentLoading = false;
        $scope.errors = [];
        $scope.roamingDevices = [];
        $scope.alwaysAllowedApps = [];
        $scope.alwaysContentLoading = true;
        $scope.hasFeatureNoAppsInBlockTime = false;
        $scope.hasRoamingV2 = false;
        $scope.hasCBFeature = scgSharedFactory.hasCBFeature;           //needed for hasFeature markup
        $scope.modalFactory = modalFactory;
        $scope.showBlockTimes = false;
        $scope.extensionTime = 0;
        $scope.hintLimitConflictHide = false;

        //init timetable
        $scope.timeTableOptions = {
            reset: true
        };

        //debugger;

        scgGlobalSpinnerFactory.blockUI();
        var hasTouchscreen = 'ontouchstart' in window;
        $log.info('cbtimelimits init hasTouchscreen=', hasTouchscreen);


        if (hasTouchscreen === true) {
            $scope.isTouchDevice = true;
        }

        $scope.timetableController = {};


        $scope.delExtension = function() {
            $log.info('delExtension!');
            cbDashboardFactory.setExtension(0).then(function success(response) {
                $scope.extension = 0;
                $log.info('delExtension sucess');
            });
        }

        $scope.checkLimitConflict = function() {
            var conflict = false;
            if ($scope.data.isTimeLimitActiveDevice != true || $scope.hintLimitConflictHide==true) {
                $scope.limitConflict = false;
                return;
            }
            var mins_day = scgSharedFactory.hourToMin($scope.data.timeLimitDayDevice);
            var mins_week = scgSharedFactory.hourToMin($scope.data.timeLimitWeekDevice);
            var mins_month = scgSharedFactory.hourToMin($scope.data.timeLimitMonthDevice);
            $log.info('CheckConflict day=' + mins_day + '|week=' + mins_week + '|month=' + mins_month);
            if (mins_week<(mins_day*7)) conflict = true;
            if (mins_month<(mins_week*4)) conflict = true;
            $scope.limitConflict = conflict;
        }

        $scope.suggestLimit = function() {
            var mins_day = scgSharedFactory.hourToMin($scope.data.timeLimitDayDevice);
            $scope.data.timeLimitWeekDevice = scgSharedFactory.minToHour(mins_day/60*7);
            $scope.data.timeLimitMonthDevice = scgSharedFactory.minToHour(mins_day/60*31);
        }


        $scope.getExtension = function() {
            cbDashboardFactory.getExtension().then(function success(response) {
                $log.info('getExtension', response.data);
                $scope.extension = response.data.extTime;
                $scope.extensionInfo = $translate.instant('INFO.EXTENSION').replace('%%', $scope.extension);
            });
        }

        $scope.getHintLimitConflict = function() {
            scgSharedFactory.getHint(101).                                //adjust hintID here
            then(function success(response) {
                    if (response.data.hint101) {                         //adjust hintID here
                        $scope.hintLimitConflictHide = response.data.hint101;}        //adjust hintID here
                }
            );
        }


        $scope.contentLoadingStuff = function () {

            $log.info('cbtimelimits contentLoading start');

            //first the hint stuff
            $scope.getHintLimitConflict();

            cbTimelimitsFactory.getBrowserSettings()
                .then(function success(response) {
                    $scope.data = response.data;

                    $scope.checkLimitConflict();
                    $scope.getExtension();

                    //add watchers
                    _.each($scope.data, function (v, k) {
                        $scope.$watch('data.' + k, function (new_val, old_val) {
                            if (new_val != old_val) {
                                var changed_object = {};
                                changed_object[k] = new_val;

                                $scope.checkLimitConflict();

                                //save timetable only with timed queue
                                if (k == 'blockTimesDevice') {
                                    clearTimeout(timeTableSaveQueue);
                                    timeTableSaveQueue = setTimeout(function () {
                                        scgSharedFactory.needsSync = true;
                                        cbTimelimitsFactory.setSetting(changed_object);
                                    }, 1500);
                                } else {
                                    scgSharedFactory.needsSync = true;
                                    cbTimelimitsFactory.setSetting(changed_object);
                                }
                            }
                        }, true);
                    });

                    //Empty object returned (no data in tables!)
                    if (Object.keys(response.data).length === 0) {
                        scgGlobalSpinnerFactory.unblockUI();
                        $scope.timesContentLoading = false;
                        scgSharedFactory.needsSync = false;
                        modalFactory.okModal(3, '<b>' + $translate.instant('ERROR.ERROR').toUpperCase() + ' :-/</b><br><br>' + $translate.instant('ERROR.INITIAL_NO_DATA'));
                        $state.go('cb-dashboard');
                    }

                    $log.info('cbtimelimits contentloading done');
                    $scope.timesContentLoading = false;
                    scgGlobalSpinnerFactory.unblockUI();

                    //Get also allowed apps
                    cbTimelimitsFactory.getAlwaysAllowedApps().then(function success(response) {
                        $scope.alwaysAllowedApps = response.data;
                        $scope.alwaysContentLoading = false;

                        setTimeout(function () {
                            // if an anchor is set, scroll to it! Remark: we are using (setTimeOut(),0) to wait for documentReady
                            // otherwise anchor is at wrong place, because not everything is rendered in DOM yet
                            if ($location.absUrl().indexOf("pauseanchor")!=-1) {
                                $window.scrollTo(0, document.getElementById('pauseanchor').offsetTop);
                                $log.info("PAUSEDEV Yes, we have pauseanchor!");
                            }
                        }, 0);
                    });

                });
        }


        $scope.$on('$viewContentLoaded', function () {
            $scope.contentLoadingStuff();
        });


        $scope.enableBlockTimes = function () {
            $scope.showBlockTimes = true;
        }

        $scope.$watch('data', function (n) {
            if (n.isWeekDayLimitActiveDevice == true) {
                $log.info('n', n);
                var compare = [n.timeLimitMondayDevice, n.timeLimitTuesdayDevice, n.timeLimitWednesdayDevice, n.timeLimitThursdayDevice, n.timeLimitFridayDevice, n.timeLimitSaturdayDevice, n.timeLimitSundayDevice];
                var largestweekday = '02:30';
                _.map(compare, function (value) {
                    if (moment.duration(value) > moment.duration(largestweekday)) {
                        largestweekday = value;
                    }
                });
                moment.duration($scope.data.timeLimitDayDevice) <= moment.duration(largestweekday) ? $scope.data.timeLimitDayDevice = largestweekday : null;
            }
        }, true);

        //watch limit internal logic and create error if necessary
        $scope.$watch('[data.timeLimitDayDevice, data.timeLimitWeekDevice, data.timeLimitMonthDevice, data.isTimeLimitActiveDevice]',
            function () {

                $log.info('data', $scope.data);

                if ($scope.data.timeLimitDayDevice && $scope.data.timeLimitWeekDevice && $scope.data.timeLimitMonthDevice && //all vars are here
                    $scope.data.isTimeLimitActiveDevice === true //time limits are active
                ) {

                    var valdata = {
                        timeLimitDayDevice: $scope.data.timeLimitDayDevice,
                        timeLimitWeekDevice: $scope.data.timeLimitWeekDevice,
                        timeLimitMonthDevice: $scope.data.timeLimitMonthDevice
                    };

                    scgValidationFactory.validateTimelimits(valdata, addUniqueError, removeErrorById);

                } else if ($scope.data.isTimeLimitActiveDevice === false) {
                    removeErrors();
                }

            });

        $scope.showRoamingDevices = function () {
            $log.info('show Roaming devices');
            $scope.contentLoading = true;
            scgSharedFactory.getRoamingDevices().then(function success(response) {
                $log.info('show roaming response:', response);
                $scope.roamingDevices = response.data;
                $scope.contentLoading = false;
            });
        }

        $scope.gotoTAN = function () {
            $log.info("goto tans");
            $state.go('cb-tans');
        }

        $scope.alwaysAllowedAppsInfo = function () {
            modalFactory.infoModal.open({
                modal_settings: {
                    title: $translate.instant('HEADER.INFO'),
                    message: $translate.instant('INFO.ALWAYS_ALLOWED_APPS'),
                    butOkLabel: $translate.instant('BUTTON.OK'),
                    butCancelLabel: null,
                    iconNo: 1,
                    refreshStateAfterClose: true
                },
                callbacks: {
                    ok: function () {
                        $log.info('modal closed with ok -> callback');
                    }
                }
            });
        };


        $scope.doUntilAction = function (defaultRadio) {
            modalFactory.untilModal.open({
                modal_settings: {
                    defaultRadio: defaultRadio,
                    refreshStateAfterClose: true
                }, callbacks: {
                    ok: function () {
                        $log.info('modal-until ok callback');
                        $state.go('cb-dashboard');
                    },
                    cancel: function () {
                        $log.info('modal-until CANCEL callback');
                    }
                }
            });
        }


        $scope.roamingSettings = function () {
            $log.info("roaming settings");
            modalFactory.roamingModal.open({
                modal_settings: {
                    refreshStateAfterClose: false
                }, callbacks: {
                    ok: function () {
                        $log.info('modal-until ok callback');
                        $state.reload();
                    }
                }
            });
        }



        $scope.hintLimitConflictDone = function() {
            $scope.hintLimitConflictHide = true;
            scgSharedFactory.setHint(101).then(function success(/*response*/) {
                    $log.info('HINT DONE SUCCESS');
                }
            );};




        var addUniqueError = function (err) {
            //if there is no such error yet, push it to array, otherwise ignore
            if (_.where($scope.errors, err).length === 0) {
                $scope.errors.push(err);
            }
        };

        var removeErrorById = function (id) {
            $scope.errors = _.filter($scope.errors, function (error) {
                return error.id !== id;
            });
        };

        var removeErrors = function () {
            $scope.errors = [];
        };


    });
