angular.module('ChicoApp').factory('sbDashboardFactory', function(
    $http,
    apiFunctions,
    $log,
    API) {

    var factory = {};

    var apifields = [
        'isNewWebsitesBlocked',
        'isWebFilterActive',
        'webFilterAge',
        'isWebLogActive'
    ];

    factory.getUsageHowMany = function(howmany) {
        var req = {
            method: 'GET',
            timeout: 30000,
            url: API.url + 'sb/usages/' + howmany
        };
        return $http(req);
    };


    factory.getWebfilterDetails = function() {
        var params = apiFunctions.obj_to_url_params(apifields);
        $log.info('url is:' + apiFunctions.get_cc_base_url() + 'cbsettings/' + params);
        var req = {
            method: 'GET',
            url: API.url + 'cbsettings/' + params
        };
        $log.info('return val=' + $http(req));
        return $http(req);
    };



    return factory;

});
