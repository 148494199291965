angular.module('ChicoApp').factory('cbWebFilterCategoryFactory', function(
  $http,
  apiFunctions,
  API,
  scgSharedFactory,
  _) {

  var factory = {};

  var categoryNumberList = [];

  var apikeys = {
    'ChatDating': 11,
    'EMailWebmail': 12,
    'SexAndPorn': 13,
    'ViolenceWeapons': 14,
    'ProxySpywareHacking': 15,
    'GamblingOnlineGames': 16,
    'DubiousSites': 17,
    'DrugsAndAlcohol': 18,
    'AdServer': 19,
    'CompanyInstitution': 20,
    'SearchEngine': 21,     // Nicht vorhanden
    'LearningEducation': 22,
    'VideoAndPictures': 23,
    'MusicAudioRadio': 24,
    'ShoppingTravel': 25,
    'NewsMags': 26,
    'SocialMedia': 27,
    'OfficeFinance': 28,
    'SportEntertainment': 29,
    'TrafficWeather': 30,
    'SystemDrivers': 31,
    'SoftwareDownloads': 35
  };

  var categorieKeys = _.values(apikeys);

  _.each(categorieKeys, function(key/*, index*/) {
    categoryNumberList.push('webBlockedCategory' + key);
  });

  var apifields_internet_categories       = apiFunctions.obj_to_url_params(categoryNumberList);

  factory.categoryNumberList = categoryNumberList;

  factory.getCategories = function() {
    var req = {
      method: 'GET',
      url: API.url + 'cbsettings' + apifields_internet_categories
    };
    return $http(req);
  };

  factory.setSetting = function(obj) {
    scgSharedFactory.needsSync = true;
    var req = {
      method: 'POST',
      url: API.url + 'cbsettings',
      data: obj
    };
    return $http(req);
  };

  return factory;
});

