 /**
  * @typedef {Object} LimitOptions
  * @property {chicoEnums.KindEnum}                         kind        Kind
  * @property {detailViewAllowOptions.BonusOptions}         bonus       Bonus options
  * @property {detailViewTimeLimitOptions.TimeLimitOptions} timeLimit   Time limit options
  */

  /**
   * Extension to appListsFactory. Provides some additional functions used by detailView
   */
angular.module('ChicoApp').service('appDetailsService', function(
    AuthFactory,
    scgSharedFactory,
    appListsFactory,
    chicoEnums,
    _
) {
    var service = this;

    /**
     * Updates limits of the given apps and saves the apps with their updated limits.
     * If limitOptions.kind is a group, the given groupTimeLimitOptions are used.
     * @param {Object[]}                                        apps                    Array of apps to save
     * @param {LimitOptions}                                    limitOptions            New limits for the given apps
     * @param {?detailViewTimelimitOptions.TimeLimitOptions}    groupTimeLimitOptions   New group limits if a group was configured
     * @param {chicoEnums.AppTypeEnum}                          appType                 AppType that should be saved
     * @returns {HttpPromise} HttpPromise
     */
    service.saveAppsWithLimitOptions = function(apps, limitOptions, groupTimeLimitOptions, appType) {
        // If kind is a custom group or GROUP_INTERNET, the time limit settings need to be replaced with the time limit options of the group
        if ((chicoEnums.kindIsCustomGroup(limitOptions.kind) || limitOptions.kind === chicoEnums.KindEnum.GROUP_INTERNET) && groupTimeLimitOptions) {
            limitOptions.timeLimit = groupTimeLimitOptions;
        }

        _.each(apps, function(app) {
            service.updateAppLimits(app, limitOptions, appType);
        });

        if (appType === chicoEnums.AppTypeEnum.APP) {
            return appListsFactory.saveAppsCB(apps);
        } else if (appType === chicoEnums.AppTypeEnum.WINDOWS_APP) {
            return appListsFactory.saveAppsCC(apps);
        } else if (appType === chicoEnums.AppTypeEnum.WEBSITE && AuthFactory.activeContext === chicoEnums.ContextEnum.CB) {
            return appListsFactory.saveWebCB(apps);
        } else if (appType === chicoEnums.AppTypeEnum.WEBSITE && AuthFactory.activeContext === chicoEnums.ContextEnum.CC) {
            return appListsFactory.saveWebCC(apps);
        }
    };

    /**
     * Replaces limits of the given app with the limits defined in the given limitOptions
     * @param {Object}                  app             App whose limits are updated
     * @param {LimitOptions}            limitOptions    Limits that are used for updating
     * @param {chicoEnums.AppTypeEnum}  appType         AppType
     * @returns {Object} App with updated limits
     */
    service.updateAppLimits = function(app, limitOptions, appType) {
        app.kind = limitOptions.kind;

        if (appType === chicoEnums.AppTypeEnum.WINDOWS_APP || (appType === chicoEnums.AppTypeEnum.WEBSITE && AuthFactory.activeContext === chicoEnums.ContextEnum.CC)) {
            app.tagID                   = limitOptions.bonus.bonusType;
            app.tagDetails              = limitOptions.bonus.bonusInterval + '|' + limitOptions.bonus.bonusPerInterval;

            app.blockTimes = limitOptions.timeLimit.blockedTimes;
        } else {
            app.optBonusType    = limitOptions.bonus.bonusType;
            app.optBonusValue   = limitOptions.bonus.bonusPerInterval + '|'
                + limitOptions.bonus.bonusInterval + '|'
                + limitOptions.bonus.maxDailyBonus;

            app.blockedTimesCB = limitOptions.timeLimit.blockedTimes;
        }

        app.isTimeLimitActive       = limitOptions.timeLimit.isTimeLimitActive;
        app.isWeekdayLimitActive    = limitOptions.timeLimit.isWeekdayTimeLimitActive;
        app.isWeekdayLimitActive    = limitOptions.timeLimit.isWeekdayTimeLimitActive;
        app.timeLimitDay            = limitOptions.timeLimit.timeLimitDay;
        app.timeLimitWeek           = limitOptions.timeLimit.timeLimitWeek;
        app.timeLimitMonth          = limitOptions.timeLimit.timeLimitMonth;
        app.timeLimitMonday         = limitOptions.timeLimit.timeLimitWeekdays.monday;
        app.timeLimitTuesday        = limitOptions.timeLimit.timeLimitWeekdays.tuesday;
        app.timeLimitWednesday      = limitOptions.timeLimit.timeLimitWeekdays.wednesday;
        app.timeLimitThursday       = limitOptions.timeLimit.timeLimitWeekdays.thursday;
        app.timeLimitFriday         = limitOptions.timeLimit.timeLimitWeekdays.friday;
        app.timeLimitSaturday       = limitOptions.timeLimit.timeLimitWeekdays.saturday;
        app.timeLimitSunday         = limitOptions.timeLimit.timeLimitWeekdays.sunday;

        return app;
    };

    /**
     * Creates LimitOptions using the app object received from server.
     * Returns default limitOptions if no app object is passed.
     * @param {?Object}                 app     App object that was received from server
     * @param {chicoEnums.AppTypeEnum}  appType AppType
     * @return {LimitOptions}
     */
    service.limitOptionsFromApp = function(app, appType) {
        if (!app) {
            app = {};
        }

        var limitOptions = {
            kind:       app.kind,
            bonus:      service.bonusOptionsFromApp(app, appType),
            timeLimit:  service.timeLimitOptionsFromApp(app, appType)
        };

        return limitOptions;
    };

    /**
     * @param {Object}                  app     App object that was received from server
     * @param {chicoEnums.AppTypeEnum}  appType AppType
     * @return {detailViewAllowedOptions.BonusOptions}
     */
    service.bonusOptionsFromApp = function(app, appType) {
        var bonus = {};

        if (appType === chicoEnums.AppTypeEnum.WINDOWS_APP || (appType === chicoEnums.AppTypeEnum.WEBSITE && AuthFactory.activeContext === chicoEnums.ContextEnum.CC)) {
            bonus = {
                bonusType:          app.tagID !== undefined ? app.tagID : chicoEnums.PrgBonusTypeEnum.NO_BONUS,
                bonusInterval:      '00:05',
                bonusPerInterval:   '00:10',
                maxDailyBonus:      '00:30' // Not supported, just set default value
            };

            if (app.tagDetails !== undefined) {
                var cFirstPipe = app.tagDetails.indexOf('|');

                if (cFirstPipe !== -1 && app.tagDetails.length > cFirstPipe) {
                    bonus.bonusInterval     = app.tagDetails.substring(0, cFirstPipe);
                    bonus.bonusPerInterval  = app.tagDetails.substring(cFirstPipe + 1);
                }
            }
        } else {
            bonus = {
                bonusType:          app.optBonusType !== undefined ? app.optBonusType : chicoEnums.AppBonusTypeEnum.NO_BONUS,
                bonusPerInterval:   '00:05',
                bonusInterval:      '00:10',
                maxDailyBonus:      '00:30'
            };

            if (app.optBonusValue !== undefined) {
                var firstPipe   = app.optBonusValue.indexOf('|');
                var secondPipe  = app.optBonusValue.indexOf('|', firstPipe + 1);
                
                if (firstPipe !== -1 && secondPipe !== -1 && app.optBonusValue.length > secondPipe) {
                    bonus.bonusPerInterval  = app.optBonusValue.substring(0, firstPipe);
                    bonus.bonusInterval     = app.optBonusValue.substring(firstPipe + 1, secondPipe);
                    bonus.maxDailyBonus     = app.optBonusValue.substring(secondPipe + 1);
                }
            }
        }

        return bonus;
    };

    /**
     * Creates TimeLimitLimitOptions using the app object received from server.
     * Also works for response data when group options when group options are requested (getGroupDefaults).
     * @param {Object}                  app     App object that was received from server
     * @param {chicoEnums.AppTypeEnum}  appType AppType
     * @return {detailViewTimeLimitOptions.TimeLimitOptions}
     */
    service.timeLimitOptionsFromApp = function(app, appType) {
        var defaultTimetableValue = '00000000000000000000000000000000000000000000000000' +
            '00000000000000000000000000000000000000000000000000' +
            '00000000000000000000000000000000000000000000000000' +
            '000000000000000000';

        var timeLimit = {
            isTimeLimitActive:          app.isTimeLimitActive       !== undefined ? app.isTimeLimitActive       : false,
            isWeekdayTimeLimitActive:   app.isWeekdayLimitActive    !== undefined ? app.isWeekdayLimitActive    : false,
            timeLimitDay:               app.timeLimitDay            !== undefined ? app.timeLimitDay            : '02:30',
            timeLimitWeek:              app.timeLimitWeek           !== undefined ? app.timeLimitWeek           : '20:00',
            timeLimitMonth:             app.timeLimitMonth          !== undefined ? app.timeLimitMonth          : '80:00',
            timeLimitWeekdays: {
                monday:     app.timeLimitMonday     !== undefined ? app.timeLimitMonday     : '01:30',
                tuesday:    app.timeLimitTuesday    !== undefined ? app.timeLimitTuesday    : '01:30',
                wednesday:  app.timeLimitWednesday  !== undefined ? app.timeLimitWednesday  : '01:30',
                thursday:   app.timeLimitThursday   !== undefined ? app.timeLimitThursday   : '01:30',
                friday:     app.timeLimitFriday     !== undefined ? app.timeLimitFriday     : '02:00',
                saturday:   app.timeLimitSaturday   !== undefined ? app.timeLimitSaturday   : '02:00',
                sunday:     app.timeLimitSunday     !== undefined ? app.timeLimitSunday     : '01:30'
            }
        };

        if (appType === chicoEnums.AppTypeEnum.WINDOWS_APP || (appType === chicoEnums.AppTypeEnum.WEBSITE && AuthFactory.activeContext === chicoEnums.ContextEnum.CC)) {
            timeLimit.blockedTimes = app.blockTimes !== undefined ? app.blockTimes : defaultTimetableValue;
        } else {
            timeLimit.blockedTimes = app.blockedTimesCB !== undefined ? app.blockedTimesCB : defaultTimetableValue;
        }

        return timeLimit;
    };
});
