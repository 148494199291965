angular.module('ChicoApp.Shared').directive('elementLoadingWrapper', function() {

  return {
    restrict: 'E',
    // template: '<ng-transclude></ng-transclude>',
    template: '<div></div>',
    link: link,
    transclude: true,
    scope: {
      ngModel: '='
    }
  };

  function link($scope, $element, $attrs, $ctrl, $transclude) {

    $scope.$watch('ngModel', function(newVal) {
      // console.log('ElementLoadingDirective: loading',newVal);

      if (newVal === true) {
        $element.hide();
        $element.parent().append('<div class="elementLoadingWrapper"' +
            ' style="width: 100%; text-align: center;"><div class="loader">' +
            '<div class="loader-inner ball-pulse"><div></div><div></div>' +
            '<div></div></div></div></div>');
      }else {
        $element.parent().find('.elementLoadingWrapper').remove();
        $element.show();
      }

    });

    $transclude($scope.$parent, function(clone) {
      $element.empty();
      $element.append(clone);
    });

  }

});

