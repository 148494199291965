/**
 * @typedef {Object} TimeLimitOptions
 * @property {boolean}              isTimeLimitActive           True if time limit for Day/Week/Month is active
 * @property {boolean}              isWeekdayTimeLimitActive    True if time limit for weekdays is active
 * @property {string}               timeLimitDay
 * @property {string}               timeLimitWeek
 * @property {string}               timeLimitMonth
 * @property {TimeLimitWeekdays}    timeLimitWeekdays
 * @property {String}               blockedTimes                String that determines exact times in a week in which the app is blocked
 */

/**
 * @typedef {Object} TimeLimitWeekdays Contains time limits for each weekday
 * @property {string} monday
 * @property {string} tuesday
 * @property {string} wednesday
 * @property {string} thursday
 * @property {string} friday
 * @property {string} saturday
 * @property {string} sunday
 */

/* TODO
 * Missing Features:
 * - Show warning if limit of a weekday is larger than timeLimitDay
 * - Show warning if sum of weekday limits is larger than timeLimitWeek
 * - Show warning if timeLimitWeek is larger than timeLimitMonth
 * - Show warning if sum of weekday limits is larger than timeLimitMonth
 * - Show warning if timeLimitDay is larger than timeLimitMonth (not implemented in old version)
 */
angular.module('ChicoApp.Shared').component('detailViewTimelimitOptions', {
    templateUrl: 'templates/shared/detailView/detailViewTimelimitOptions/detailViewTimelimitOptions.component.html',
    controller: DetailViewTimelimitOptionsController,
    bindings: {
        /**
         * @type {TimeLimitOptions}
         */
        timeLimit: '<',
        /**
         * @type {function} Invoked if any change was made to the options
         * @param {TimeLimitOptions} timeLimit New timeLimit options
         */
        timeLimitChange: '&?'
    }
});

function DetailViewTimelimitOptionsController() {
    var ctrl = this;

    ctrl.onChange = function() {
        if (typeof ctrl.timeLimitChange === 'function') {
            ctrl.timeLimitChange({ timeLimit: ctrl.timeLimit });
        }
    };
}