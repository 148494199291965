
angular.module('ChicoApp.Shared').controller('NotificationBarController', function(
  $scope,
  AuthFactory,
  $state,
  $log,
  sseFactory,
  modalFactory,
  scgSharedFactory) {


$scope.scgSharedFactory = scgSharedFactory;

$scope.syncRunning = false;

$scope.gotoSync = function() {
  if (AuthFactory.activeContext === 'cb') {
    $state.go('cb-sync');
  }

  if (AuthFactory.activeContext === 'cc') {
    $log.info('notificationBar SYNC, ENTER CC!');
    //new approach, sync immediately if SSE returns online
    var checkOnlineStatus = function() {
      sseFactory.isOnline()
          .then(function success(response) {
            if (response.data.success == true) {
              $log.info('SYNC, YEPP WE ARE ONLINE!');
              $scope.runSync();
              return;
            }else {
              $log.info('SYNC NOT ONLINE');
              $state.go('cc-sync');
            }
          },function error() {
            $log.info('SYNC NOT ONLINE');
            $state.go('cc-sync');
          }
      );

    };
    checkOnlineStatus();
  }
};

$scope.roamingSettings = function() {
  $log.info("notificationBar roaming settings");
  modalFactory.roamingModal.open({
      modal_settings: {
          refreshStateAfterClose: false
      },callbacks: {
          ok: function () {
              $log.info('modal-until ok callback');
              $state.reload();
          }
      }
  });
}


});
