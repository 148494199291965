'use strict';
angular.module('ChicoApp.Shared').directive('scgFeedbackModal', function() {
  return {
    restrict: 'E',
    templateUrl: 'templates/shared/globalModals/globalFeedbackModal/templates/modal-feedback-content.html',
    controller: function() {

    }
  };
});
