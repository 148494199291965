angular.module('ChicoApp.Shared').directive('loadingButton',function() {
  return {
    link: link,
    restrict: 'A',
    transclude: true,
    scope: {
      loading: '=loadingButton',
    },
    template: '<div ng-show="spinner" class="loader">' +
            '<div class="loader-inner ball-pulse"><div ng-style="pulseStyle"></div><div ng-style="pulseStyle"></div>' +
            '<div ng-style="pulseStyle"></div></div></div><ng-transclude ng-show="!spinner"></ng-transclude>'
  };

  function link(scope, element) {

    scope.pulseStyle = {
        height: '8px',
        width: '8px',
        backgroundColor: '#fff'
      };

    scope.$watch(
        'loading',
          function(newValue) {
            if (newValue) {
              element.attr('disabled', 'disabled');
              scope.spinner = true;
            }else {
              element.removeAttr('disabled');
              scope.spinner = false;
            }
          }
      );
  }

});
