angular.module('ChicoApp.Shared').component('dateRangePicker', {
    templateUrl: 'templates/shared/dateRangePicker/dateRangePicker.component.html',
    controller: DateRangePickerController,
    bindings: {
        /**
         * Start of the date range in format 'YYYY-MM-DD' (end time is after start time, e.g. start = past, end = now)
         * @type {string}
         */
        externalStart: '<start',
        /**
         * Triggered if start time changed
         * @type {?Function}
         * @param {string} newVal New end time
         * @param {string} oldVal Old start time
         */
        startChange: '&?',
        /**
         * End of the date range in format 'YYYY-MM-DD'
         * @type {string}
         */
        externalEnd: '<end',
        /**
         * Triggered if end time changed
         * @type {?Function}
         * @param {string} newVal New end time
         * @param {string} oldVal Old start time
         */
        endChange: '&?',
        /**
         * Triggered if start AND/OR end time changed
         * @type {?Function}
         */
        dateChange: '&?',
        /**
         * Triggered if RangeType changed
         * @type {?Function}
         * @param {number} newVal New RangeType (numbers equal to internal RangeTypeEnum)
         * @param {number} oldVal Old RangeType (numbers equal to internal RangeTypeEnum)
         */
        rangeTypeChange: '&?'
    }
});

function DateRangePickerController(
    $log,
    $translate,
    moment
) {
    var ctrl = this;

    // The date format that is used internally and externally of this component
    var dateFormat = 'YYYY-MM-DD';

    // Index of each Enum type should fit to the index in rangeTypeOptions
    ctrl.RangeTypeEnum = Object.freeze({
        DAY:    0,
        WEEK:   1,
        MONTH:  2,
        CUSTOM: 3
    });

    ctrl.rangeTypeOptions = [
        { id: ctrl.RangeTypeEnum.DAY,       name: $translate.instant('ALL.PER') + ' ' + $translate.instant('ALL.DAY') },
        { id: ctrl.RangeTypeEnum.WEEK,      name: $translate.instant('ALL.PER') + ' ' + $translate.instant('ALL.WEEK') },
        { id: ctrl.RangeTypeEnum.MONTH,     name: $translate.instant('ALL.PER') + ' ' + $translate.instant('ALL.MONTH')  },
        { id: ctrl.RangeTypeEnum.CUSTOM,    name: $translate.instant('USAGE.PERIOD_CUSTOM') },
    ];
    ctrl.rangeType = {};
    ctrl.oldRangeTypeId = 1;

    ctrl.oldStart = '';
    ctrl.oldEnd = '';

    ctrl.start = '';
    ctrl.end = '';

    // Start and end for DatePicker models
    ctrl.customStart = '';
    ctrl.customEnd = '';

    // Start and end formatted for the user
    ctrl.formattedStart = '';
    ctrl.formattedEnd = '';

    ctrl.navPrevDisabled = false;
    ctrl.navNextDisabled = true;
    ctrl.updateButtonDisabled = false;

    ctrl.$onInit = function() {
        $translate.onReady(function() {
            ctrl.rangeTypeOptions[0].name = $translate.instant('ALL.PER') + ' ' + $translate.instant('ALL.DAY');
            ctrl.rangeTypeOptions[1].name = $translate.instant('ALL.PER') + ' ' + $translate.instant('ALL.WEEK');
            ctrl.rangeTypeOptions[2].name = $translate.instant('ALL.PER') + ' ' + $translate.instant('ALL.MONTH');
            ctrl.rangeTypeOptions[3].name = $translate.instant('USAGE.PERIOD_CUSTOM');
        });
    };

    ctrl.$onChanges = function(changes) {
        if (changes.externalStart && typeof ctrl.externalStart !== 'undefined' && ctrl.externalStart !== ctrl.start) {
            ctrl.oldStart = ctrl.start;
            ctrl.start = ctrl.externalStart;
            ctrl.customStart = ctrl.externalStart;
            ctrl.formattedStart = ctrl.formatDate(ctrl.externalStart);

            ctrl.autoRange();
            ctrl.updateNavigationButtonDisabledState();
        }
        
        if (changes.externalEnd && typeof ctrl.externalEnd !== 'undefined' && ctrl.externalEnd !== ctrl.end) {
            ctrl.oldEnd = ctrl.end;
            ctrl.end = ctrl.externalEnd;
            ctrl.customEnd = ctrl.externalEnd;
            ctrl.formattedEnd = ctrl.formatDate(ctrl.externalEnd);

            ctrl.autoRange();
            ctrl.updateNavigationButtonDisabledState();
        }
    };

    ctrl.onRangeTypeChange = function() {
        if (typeof ctrl.rangeType.id !== 'undefined') {
            ctrl.oldRangeTypeId = ctrl.rangeType.id;
        } else {
            ctrl.oldRangeTypeId = undefined;
        }

        ctrl.resetDateRange();

        if (ctrl.rangeType.id === ctrl.RangeTypeEnum.CUSTOM) {
            ctrl.validateCustomRange();
        }
        
        if (typeof ctrl.rangeTypeChange !== 'undefined') {
            ctrl.rangeTypeChange({ newVal: ctrl.rangeType.id, oldVal: ctrl.oldRangeTypeId });
        }
    };

    ctrl.onCustomStartChange = function(newDate, oldDate) {
        ctrl.updateDateRange(moment(newDate).format(dateFormat));
        ctrl.validateCustomRange();
    };

    ctrl.onCustomEndChange = function(newDate, oldDate) {
        ctrl.updateDateRange(undefined, moment(newDate).format(dateFormat));
        ctrl.validateCustomRange();
    };

    ctrl.onPrevClick = function() {
        var newStart = ctrl.start;
        var newEnd = ctrl.end;

        switch(ctrl.rangeType.id) {
            case ctrl.RangeTypeEnum.DAY:
                newStart = moment(newStart, dateFormat).subtract(1, 'd').format(dateFormat);
                newEnd = newStart;
                break;
            case ctrl.RangeTypeEnum.WEEK:
                newStart = moment(newStart, dateFormat).subtract(1, 'w').format(dateFormat);
                newEnd = moment(newStart, dateFormat).endOf('isoWeek').format(dateFormat);
                break;
            case ctrl.RangeTypeEnum.MONTH:
                newStart = moment(newStart, dateFormat).subtract(1, 'M').format(dateFormat);
                newEnd = moment(newStart, dateFormat).endOf('month').format(dateFormat);
                break;
            case ctrl.RangeTypeEnum.CUSTOM:
                // Do nothing
                break;
        }

        ctrl.updateDateRange(newStart, newEnd);
    };

    ctrl.onNextClick = function() {
        var newStart = ctrl.start;
        var newEnd = ctrl.end;

        switch(ctrl.rangeType.id) {
            case ctrl.RangeTypeEnum.DAY:
                newStart = moment(newStart, dateFormat).add(1, 'd').format(dateFormat);
                newEnd = newStart;
                break;
            case ctrl.RangeTypeEnum.WEEK:
                newStart = moment(newStart, dateFormat).add(1, 'w').format(dateFormat);
                newEnd = moment(newStart, dateFormat).endOf('isoWeek').format(dateFormat);
                break;
            case ctrl.RangeTypeEnum.MONTH:
                newStart = moment(newStart, dateFormat).add(1, 'M').format(dateFormat);
                newEnd = moment(newStart, dateFormat).endOf('month').format(dateFormat);
                break;
            case ctrl.RangeTypeEnum.CUSTOM:
                // Do nothing
                break;
        }

        if (moment(newStart, dateFormat).isAfter(moment())) {
            // Don't update
            return;
        }

        ctrl.updateDateRange(newStart, newEnd);
    };

    ctrl.onUpdateCustomRangeClick = function() {
        ctrl.applyDateRange();
    };

    /**
     * Updates start and end dates based on given dates.
     * Also updates formatted start and end dates and invokes external change events.
     * @param {?string} newStart    New start date (only used if rangeType is custom)
     * @param {?string} newEnd      New end date (only used if rangeType is custom)
     */
    ctrl.updateDateRange = function(newStart, newEnd) {
        if (newStart) {
            ctrl.oldStart = ctrl.start;
        } else {
            newStart = ctrl.start;
        }

        if (newEnd) {
            ctrl.oldEnd = ctrl.end;
        } else {
            newEnd = ctrl.end;
        }

        ctrl.start = newStart;
        ctrl.end = newEnd;

        ctrl.customStart = newStart;
        ctrl.customEnd = newEnd;

        ctrl.formattedStart = ctrl.formatDate(newStart);
        ctrl.formattedEnd = ctrl.formatDate(newEnd);

        if (ctrl.rangeType.id !== ctrl.RangeTypeEnum.CUSTOM) {
            ctrl.applyDateRange();
        }

        ctrl.updateNavigationButtonDisabledState();
    };

    /**
     * Apply selected date so that it is visible outside of the component
     */
    ctrl.applyDateRange = function() {
        ctrl.externalStart = ctrl.start;
        ctrl.externalEnd = ctrl.end;

        var anyChange = false;
        if (typeof ctrl.startChange !== 'undefined' && ctrl.start !== ctrl.oldStart) {
            ctrl.startChange({ newVal: ctrl.start, oldVal: ctrl.oldStart });
            anyChange = true;
        }

        if (typeof ctrl.endChange !== 'undefined' && ctrl.end !== ctrl.oldEnd) {
            ctrl.endChange({ newVal: ctrl.end, oldVal: ctrl.oldEnd });
            anyChange = true;
        }

        if (anyChange && typeof ctrl.dateChange !== 'undefined') {
            ctrl.dateChange();
        }
    }

    /**
     * Switches the rangeType to the given type
     * @param {ctrl.RangeTypeEnum} rangeType New rangeType
     * @param {?boolean} resetDateRange If true, startDate and endDate are set to default values that fit the given rangeType (default false)
     */
    ctrl.switchRangeType = function(rangeType, resetDateRange) {
        if (typeof ctrl.rangeType.id !== 'undefined') {
            ctrl.oldRangeTypeId = ctrl.rangeType.id;
        } else {
            ctrl.oldRangeTypeId = undefined;
        }
        
        ctrl.rangeType = ctrl.rangeTypeOptions[rangeType];

        if (resetDateRange) {
            ctrl.resetDateRange();
        }

        if (ctrl.rangeType.id === ctrl.RangeTypeEnum.CUSTOM) {
            ctrl.validateCustomRange();
        }
        
        if (typeof ctrl.rangeTypeChange !== 'undefined') {
            ctrl.rangeTypeChange({ newVal: ctrl.rangeType.id, oldVal: ctrl.oldRangeTypeId });
        }
    }

    /**
     * Resets startDate and endDate to default values that depend on the current rangeType
     */
    ctrl.resetDateRange = function() {
        var newStart = ctrl.start;
        var newEnd = ctrl.end;
        var now = moment().format(dateFormat);

        switch(ctrl.rangeType.id) {
            case ctrl.RangeTypeEnum.DAY:
                newStart = now;
                newEnd = now;
                break;
            case ctrl.RangeTypeEnum.WEEK:
                newStart = moment(now, dateFormat).startOf('isoWeek').format(dateFormat);
                newEnd = moment(now, dateFormat).endOf('isoWeek').format(dateFormat);
                break;
            case ctrl.RangeTypeEnum.MONTH:
                newStart = moment(now, dateFormat).startOf('month').format(dateFormat);
                newEnd = moment(now, dateFormat).endOf('month').format(dateFormat);
                break;
            case ctrl.RangeTypeEnum.CUSTOM:
                // Make sure, end is not after now
                if (moment(ctrl.end, dateFormat).isAfter(moment())) {
                    newEnd = now;
                }
                break;
        }

        ctrl.updateNavigationButtonDisabledState();
        ctrl.updateDateRange(newStart, newEnd);
    }

    /**
     * Switches the rangeType so it fits to the current startDate and endDate.
     
     * E.g. if startDate and endDate are equal, RangeTypeEnum.DAY is chosen.
     */
    ctrl.autoRange = function() {
        if (ctrl.start === ctrl.end) {
            ctrl.switchRangeType(ctrl.RangeTypeEnum.DAY);
        } else if (moment(ctrl.start, dateFormat).startOf('isoWeek').isSame(moment(ctrl.start, dateFormat), 'day')
            && moment(ctrl.end, dateFormat).endOf('isoWeek').isSame(moment(ctrl.end, dateFormat), 'day')
        ) {
            ctrl.switchRangeType(ctrl.RangeTypeEnum.WEEK);
        } else if (moment(ctrl.start, dateFormat).startOf('month').isSame(moment(ctrl.start, dateFormat), 'day')
        && moment(ctrl.end, dateFormat).endOf('month').isSame(moment(ctrl.end, dateFormat), 'day')
        ) {
            ctrl.switchRangeType(ctrl.RangeTypeEnum.MONTH);
        } else {
            ctrl.switchRangeType(ctrl.RangeTypeEnum.CUSTOM);
        }
    }

    /**
     * Validates whether start and end are valid to control disabled state of the Update button
     */
    ctrl.validateCustomRange = function() {
        if (moment(ctrl.start, dateFormat).isAfter(moment())
            || moment(ctrl.end, dateFormat).isAfter(moment())
            || moment(ctrl.start, dateFormat).isAfter(moment(ctrl.end, dateFormat))
        ) {
            ctrl.updateButtonDisabled = true;
        } else {
            ctrl.updateButtonDisabled = false;
        }
    };

    /**
     * Sets next button to disabled if next date is in the future
     */
    ctrl.updateNavigationButtonDisabledState = function() {
        var now = moment().format(dateFormat);
        var disabled = false;

        switch(ctrl.rangeType.id) {
            case ctrl.RangeTypeEnum.DAY:
                if (moment(ctrl.start, dateFormat).add(1, 'd').isAfter(now, 'd')) {
                    disabled = true;
                }
                break;
            case ctrl.RangeTypeEnum.WEEK:
                if (moment(ctrl.start, dateFormat).add(1, 'w').isAfter(now, 'd')) {
                    disabled = true;
                }
                break;
            case ctrl.RangeTypeEnum.MONTH:
                if (moment(ctrl.start, dateFormat).add(1, 'M').isAfter(now, 'd')) {
                    disabled = true;
                }
                break;
        }

        ctrl.navNextDisabled = disabled;
    }

    /**
     * Returns date formatted for displaying to the users
     * @param {string} date Date formatted as dateFormat
     * @returns {string} Date formatted as 'DD. MMM YYY'
     */
    ctrl.formatDate = function(date) {
        return moment(date, dateFormat).format('DD. MMM YYYY');
    };

    ctrl.showStart = function() {
        switch(ctrl.rangeType.id) {
            case ctrl.RangeTypeEnum.DAY:
                return true;
            default:
                return false;
        }
    };

    ctrl.showRange = function() {
        switch(ctrl.rangeType.id) {
            case ctrl.RangeTypeEnum.WEEK:
            case ctrl.RangeTypeEnum.MONTH:
                return true;
            default:
                return false;
        }
    };

    ctrl.showCustom = function() {
        if (ctrl.rangeType.id === ctrl.RangeTypeEnum.CUSTOM) {
            return true;
        } else {
            return false;
        }
    };

    ctrl.showNavButtons = function() {
        if (ctrl.rangeType.id !== ctrl.RangeTypeEnum.CUSTOM) {
            return true;
        } else {
            return false;
        }
    };
}
