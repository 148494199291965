'use strict';
angular.module('ChicoApp.CC-Usage')

    .controller('CC-Usage-Reports', function(
        $scope,
        $state,
        $stateParams,
        $window,
        $translate,
        $http,
        $location,
        $timeout,
        $injector,
        $cookieStore,
        $anchorScroll,
        chicoEnums,
        ccUsageFactory,
        ccListsFactory,
        cbUsageFactory, //necessary for AgeModals!
        scgSharedFactory,
        AuthFactory,
        sseFactory,
        html2canvas,
        jsPDF,
        _,
        Metronic,
        moment,
        $log,
        $cookies,
        modalFactory,
        apiFunctions,

        scgGlobalSpinnerFactory) {

        $scope.hasCCFeature = scgSharedFactory.hasCCFeature;
        $scope.data = {};
        $scope.dataSettings = {};
        $scope.dataSettings.roaming = "";
        $scope.weekstats = {};
        $scope.weekstatsPeriod = 7;
        $scope.loadingWeekStats=false;
        $scope.hideUsageSome= false;
        $scope.serverTimeDiff = 0;
        $scope.hintHide = true;
        $scope.showLong = false;
        $scope.period = 'week'; //default
        $scope.howMany = '250';
        $scope.isCustomPeriod = false;
        $scope.howManyUnlockRequests = -1;
        $scope.scgSharedFactory = scgSharedFactory;
        $scope.hasNoData = false;
        $scope.age = {};
        $scope.isAgeEdit = false;
        $scope.perioddropdown = '1';
        $scope.showDayHistory = false;
        $scope.showUnlockInfo = false;
        $scope.isReport = false;
        $scope.isReport = $cookies.get('role') == 'report' ? true : false;

        $scope.showLimitinfo = false;
        $scope.limitInfoDone = false;

        $scope.formatSecs = scgSharedFactory.formatSecs;

        $scope.preselectedWeekday = $state.params.weekday;
        $scope.loadingUsage = false;

        $scope.totalTime = 0;
        $scope.totalExtension = 0;
        $scope.scgSharedFactory = scgSharedFactory;

        $scope.hasRoamingGroupData = false;
        $scope.roamingData = {};
        $scope.roamingGroup = -1;
        $scope.loadingRoaming = false;
        $scope.dataSettingsDone = false;
        $scope.startDate = '2010-01-01';
        $scope.stopDate = '2010-01-01';

        $scope.defaultCountTimes = 15;
        $scope.defaultCountHistory = 25;

        $scope.timesApp = {};
        $scope.totalTimesApp = 0;
        $scope.rowTimesApp = $scope.defaultCountTimes;

        $scope.timesUrl = {};
        $scope.totalTimesUrl = 0;
        $scope.rowTimesUrl = $scope.defaultCountTimes;

        $scope.historyApp = {};
        $scope.totalHistoryApp = 0;
        $scope.rowHistoryApp = $scope.defaultCountHistory;

        $scope.historyUrl = {};
        $scope.totalHistoryUrl = 0;
        $scope.rowHistoryUrl = $scope.defaultCountHistory;

        $scope.historyBlocked = {};
        $scope.totalHistoryBlocked = 0;
        $scope.rowHistoryBlocked = $scope.defaultCountHistory;

        $scope.historySystem = {};
        $scope.totalHistorySystem = 0;
        $scope.rowHistorySystem = $scope.defaultCountHistory;

        $scope.historyPortal = {};
        $scope.totalHistoryPortal = 0;
        $scope.rowHistoryPortal = $scope.defaultCountHistory;

        $scope.isRoamingView = false;

        //Dierk: use clause for underscore
        $scope._ = _;

        //Dierk Groupmembers
        $scope.hasGroupmembers = false;
        $scope.showgroup = [];
        $scope.showgroupRoaming = [];

        $scope.gpm = [];
        $scope.gpm.length=0; //clear array
        $scope.gpm[10] = [];
        $scope.gpm[11] = [];
        $scope.gpm[12] = [];
        $scope.gpm[13] = [];
        $scope.gpm[14] = [];
        $scope.gpm[15] = [];
        $scope.gpmRoaming = [];
        $scope.gpmRoaming.length=0; //clear array
        $scope.gpmRoaming[10] = [];
        $scope.gpmRoaming[11] = [];
        $scope.gpmRoaming[12] = [];
        $scope.gpmRoaming[13] = [];
        $scope.gpmRoaming[14] = [];
        $scope.gpmRoaming[15] = [];

        $scope.gpmDone=[];
        $scope.gpmDone[111]=false;
        $scope.gpmDone[112]=false;
        $scope.gpmDone[113]=false;
        $scope.gpmDone[114]=false;
        $scope.gpmDone[115]=false;
        $scope.loadingGroupMember = [];
        $scope.loadingGroupMember[111] = false;
        $scope.loadingGroupMember[112] = false;
        $scope.loadingGroupMember[113] = false;
        $scope.loadingGroupMember[114] = false;
        $scope.loadingGroupMember[115] = false;
        $scope.loadingRGTimes = [];
        $scope.loadingRGTimes[10]=false;
        $scope.loadingRGTimes[11]=false;
        $scope.loadingRGTimes[12]=false;
        $scope.loadingRGTimes[13]=false;
        $scope.loadingRGTimes[14]=false;
        $scope.loadingRGTimes[15]=false;

        $scope.isHourView = false;

        $scope.roamingMembers = [];
        $scope.overlayText = '';
        $scope.overlayUrl = '';
        $scope.hasDayViewFeature = true; //default
        $scope.hasRoamingVals=false;

        $log.info('test login welcome to usageV2');
        Metronic.unblockUI();

        //Reload entire page page-reload
        $scope.myreload = function() {
            $state.go($state.current, {}, {reload: true});
        };

        $scope.gotoUnblock = function() {
            $state.go('cc-usage.unblock-v2');
        };


        $scope.myShortDate = function(longdate) {
            var ret = '';
            if (longdate.length > 16) {
                ret = longdate.substr(0,16);
            }else {
                ret = longdate;
            }
            return ret;
        };

        $scope.resetGroupCounter = function() {
            $scope.gpm[10].length=0;
            $scope.gpm[11].length=0;
            $scope.gpm[12].length=0;
            $scope.gpm[13].length=0;
            $scope.gpm[14].length=0;
            $scope.gpm[15].length=0;

            $scope.showgroupRoaming[11]=false;
            $scope.showgroupRoaming[12]=false;
            $scope.showgroupRoaming[13]=false;
            $scope.showgroupRoaming[14]=false;
            $scope.showgroupRoaming[15]=false;
       }




        $scope.showAgeModal = function(entry) {
            $scope.ageEntry = entry;
            $log.info('showAgeModal with entry=' + entry);
            cbUsageFactory.getAgeDetail(entry)
                .then(function success(response) {
                    if (response.data.Category == '') {
                        response.data.Category = $translate.instant('MISC.NODATA');
                    }
                    if (response.data.Description == '') {
                        response.data.Description = $translate.instant('MISC.NODATA');
                    }
                    $scope.age = response.data;
                    $log.info('age.description=' + $scope.age.description);
                    $log.info('SUCCESS getAGEDETAIL CC V2');
                });

        };

        $scope.editAge = function() {
            $log.info('EDIT AGE!');
            $scope.isAgeEdit = true;
        };

        $scope.showAge = function() {
            $scope.isAgeEdit = false;
        };

        $scope.onEditModalReturn = function() {
            $log.info('onEditModalReturn');
        };

        //get records for group internet, used in overview "Internet"
        $scope.loadInetPrgrams = function() {
            $log.info("loadInetPrgrams");
            ccListsFactory.getByListFilter(ccListsFactory.listKinds.list_kind_group_internet)
                .then(function success(response) {
                    var editRecords = [];
                    _.each(response.data, function (v) {
                        editRecords.push({data: {entry: v}});
                    });
                    if (editRecords && editRecords.length < 1) {
                        modalFactory.infoModal.open({
                            modal_settings: {
                                title: $translate.instant('HEADER.INFO'),
                                message: $translate.instant('MSG.NO_INETPROGRAMS'),
                                butOkLabel: $translate.instant('BUTTON.OK'),
                                butCancelLabel: null,
                                iconNo: 3,
                                refreshStateAfterClose: true
                            },
                            callbacks: {
                                ok: function () {
                                    $log.info('modal closed with ok -> callback');
                                }
                            }
                        });
                    } else {
                        modalFactory.editModal.open({
                            modal_settings: {
                                mode: 'edit',
                                context: 'prg',
                                title: $translate.instant('MODAL_EDIT_RECORD.TITLE_EDIT'),
                                newRecord: null,
                                editRecords: editRecords,
                                refreshStateAfterClose: true
                            },
                            callbacks: {
                                ok: function () {
                                },
                                cancel: function () {
                                }
                            }
                        });
                    }
                });
        }



        $scope.makeURL = function(url) {
            return 'https://' + url;
        };

        $scope.filterKind1 = function(item) {
            return item.Kind == 1 || item.Kind== 7;
        };

        $scope.filterKind1only = function(item) {
            return item.Kind == 1;
        };


        $scope.filterRoamingKind1= function(item) {
            return item.Kind == 1;
        };

        $scope.imgDest = function(exename) {
            return 'chrome.png';
        }


        $scope.filterKind2 = function(item) {
            return item.Kind == 2;
        };

        $scope.filterKind4 = function(item) {
            return item.Kind == 4;
        };

        $scope.filterKind5 = function(item) {
            return item.Kind == 5; //extension times
        };

        $scope.filterKind6 = function(item) {
            return item.Kind == 6; //sharedLog
        };

        $scope.filterKind7 = function(item) {
            return item.Kind == 7 || item.Kind == 8;
        };

        $scope.filterKind10 = function(item) {
            return item.Kind == 10; //sharedLog
        };


        $scope.filterKindGroups = function(item) {
            return item.Kind == 10 || item.Kind == 11 || item.Kind == 12  || item.Kind == 13  || item.Kind == 14 || item.Kind == 15;
        };

        $scope.filterKind22 = function(item) {
            return item.Kind == 22;
        };


        $scope.deleteUsage = function() {
            $log.info('delete goes here');
            modalFactory.infoModal.open({
                modal_settings: {
                    title: $translate.instant('MSG.QUESTION'),
                    message: $translate.instant('MSG.SUREDELETEUSER') + '<br><br>' + $translate.instant('MSG.SURE'),
                    butOkLabel: $translate.instant('BUTTON.YES'),
                    butCancelLabel: $translate.instant('BUTTON.NO'),
                    iconNo: 2,
                    refreshStateAfterClose: false //Dierk: bei true wurde controller gleich neu geladen werden
                },
                callbacks: {
                    ok: function() {
                        $log.info('yes, ok was click, he/she is sure');
                        ccUsageFactory.deleteUsage()
                            .then(function success(response) {
                                $log.info('HAS DATA 200, response.data=' + response.data);
                                if (response.data != null) {
                                    sseFactory.isOnline()
                                        .then(function success() {
                                            $log.info('YES WE ARE ALSO ONLINE');
                                            sseFactory.sync()
                                                .then(function success() {
                                                    $log.info('YES DO SYNC ALSO SUCCEEDED');
                                                });
                                        });

                                    modalFactory.okModal(1, $translate.instant('MSG.DELETECCDONE'));
                                    $state.go('cc-dashboard-v3');
                                }

                            }, function error() {

                                modalFactory.okModal(1, $translate.instant('MSG.ERROR') + ' ' + $translate.instant('MSG.ERRORHINT2'));
                            });
                        $log.info('Ok, all done');
                    },
                },
                cancel: function() {
                    $log.info('canceled!');
                }
            });
        };



        $scope.adjustTimes = function() {
            var timesApp = [];
            var timesUrl = [];

            $scope.resetShowMoreCounters();

            _.forEach($scope.data.logs,function(v){
                if(v.Kind==4) {
                    timesApp.push(v);
                    $scope.totalTimesApp=$scope.totalTimesApp+1;
                }
                if(v.Kind==22) {
                    timesUrl.push(v);
                    $scope.totalTimesUrl=$scope.totalTimesUrl+1;
                }
            })
            $scope.timesApp = timesApp;
            $scope.timesUrl = timesUrl;
            $log.info('timesApp=', $scope.timesApp);
            $log.info('timesUrl=', $scope.timesUrl);
        }


        $scope.resetShowMoreCounters = function() {
            $scope.totalTimesApp=0;
            $scope.totalTimesUrl=0;
            $scope.totalHistoryApp=0;
            $scope.totalHistoryPortal=0;
            $scope.totalHistorySystem=0;
            $scope.totalHistoryBlocked=0;
            $scope.totalHistoryUrl=0;
        }

        $scope.adjustHistory = function() {
            var entriesHistory = [];
            var entriesSystem= [];
            var entriesUrl = [];
            var entriesBlocked = [];
            var entriesPortal= [];

            $scope.resetShowMoreCounters();

            _.forEach($scope.data.history,function(v){
                if(v.Kind==4) {
                    entriesHistory.push(v);
                    $scope.totalHistoryApp=$scope.totalHistoryApp+1;
                }
                if(v.Kind==6) {
                    entriesPortal.push(v);
                    $scope.totalHistoryPortal=$scope.totalHistoryPortal+1;
                }
                if(v.Kind==7 || v.Kind==8) {
                    entriesSystem.push(v);
                    $scope.totalHistorySystem=$scope.totalHistorySystem+1;
                }
                if(v.Kind==90) {
                    entriesBlocked.push(v);
                    $scope.totalHistoryBlocked=$scope.totalHistoryBlocked+1;
                }
                if(v.Kind==22) {
                    entriesUrl.push(v);
                    $scope.totalHistoryUrl=$scope.totalHistoryUrl+1;
                }
            })
            $scope.historyApp = entriesHistory;
            $scope.historyUrl = entriesUrl;
            $scope.historyBlocked = entriesBlocked;
            $scope.historySystem = entriesSystem;
            $scope.historyPortal = entriesPortal;
            //$log.info('historyApp', $scope.historyApp);
        }

        $scope.getTimes = function() {
            $scope.totalTime = 0;
            $scope.totalExtension = 0;
            $scope.loadingUsage = true;
            $scope.hasNoData = false;
            $scope.totalTimeLessMinute = false;
            $scope.resetGroupCounter();
            $scope.resetShowMoreCounters();

            $scope.getTimebox($scope.startDate,$scope.stopDate);

            ccUsageFactory.getUsageByDates($scope.startDate,$scope.stopDate,$scope.howMany)
                .then(function success(response) {
                    $scope.data = response.data;
                    $log.info('USAGE ARRIVED', response.data);
                    $scope.hasNoData = false;

                    if (response.data.history.length == 0) {
                        $scope.hasNoData = true;
                    }

                    $log.info('usage response=', response.data);

                    if (response.data.logs.length==0) {
                        $scope.totalTime = 0;
                        $scope.loadingUsage = false;
                        return;
                    }

                    //get the limits
                    if ($scope.isReport===true) $scope.getLimitEntriesInfo(response.data.logs);

                    //string replace internet and bonus
                    _.forEach(response.data.logs, function(logs) {
                        if (logs.Entry === "- Bonus") {
                            logs.Entry = $translate.instant('USAGE.PART_BONUS');
                        }
                        if (logs.Entry === "- Internet") {
                            logs.Entry = $translate.instant('USAGE.PART_INTERNET');
                        }
                        //put group members in array
                        if (logs.Kind==1010 || logs.Kind==1011 || logs.Kind==1012 || logs.Kind==1013 || logs.Kind==1014 || logs.Kind==1015) {
                            var groupId = logs.Kind-1000;
                            $scope.gpm[groupId].push(logs);
                            $scope.hasGroupmembers = true;
                            $log.info("gpm added cc groupId=" + groupId + ":" , logs);
                        }
                    });

                    //Iteration for totalTime
                    _.forEach($scope.data.logs, function(dataTime) {
                        if (dataTime && dataTime.Kind && dataTime.Kind === 1) {
                             $scope.totalTime = $scope.totalTime + $scope.scgSharedFactory.hourToMin(dataTime.Period);
                        }
                        if (dataTime && dataTime.Kind && dataTime.Kind === 5) {
                            $scope.totalExtension = $scope.totalExtension + $scope.scgSharedFactory.hourToMin(dataTime.Period);
                        }
                    });

                    if ($scope.totalTime<1 && $scope.dataTimes.logs.length>0) {
                        //edge case we have less than one minute total (no total entry, but counted apps)
                        $scope.totalTimeLessMinute = true;
                    }

                    $scope.adjustTimes();
                    $scope.adjustHistory();
                    $scope.loadingUsage = false;

                    if ($scope.dataSettings.roaming && $scope.dataSettings.roaming!=''){
                        $scope.updateRoamingTimes();
                    }

                }, function error(err) {
                    $scope.loadingUsage = false;
                    if (err && err.status && err.status==401 && $scope.isReport) {
                        $scope.logout();
                        return;
                    }
                    modalFactory.timeoutModal();
                });
        }


        $scope.getTimesByHour = function(thisHour) {
            $scope.totalTime = 0;
            $scope.totalExtension = 0;
            $scope.loadingUsage = true;
            $scope.hasNoData = false;
            $scope.totalTimeLessMinute = false;
            $scope.resetGroupCounter();
            $scope.resetShowMoreCounters();

            ccUsageFactory.getUsageByHour($scope.startDate,thisHour)
                .then(function success(response) {
                    $scope.data = response.data;
                    $log.info('USAGE ARRIVED', response.data);
                    $scope.hasNoData = false;

                    if (response.data.history.length == 0) {
                        $scope.hasNoData = true;
                        $scope.loadingUsage = false;
                        return;
                    }

                    //string replace internet and bonus
                    _.forEach(response.data.logs, function(logs) {
                        if (logs.Entry === "- Bonus") {
                            logs.Entry = $translate.instant('USAGE.PART_BONUS');
                        }
                        if (logs.Entry === "- Internet") {
                            logs.Entry = $translate.instant('USAGE.PART_INTERNET');
                        }
                        //put group members in array
                        if (logs.Kind==1010 || logs.Kind==1011 || logs.Kind==1012 || logs.Kind==1013 || logs.Kind==1014 || logs.Kind==1015) {
                            var groupId = logs.Kind-1000;
                            $scope.gpm[groupId].push(logs);
                            $scope.hasGroupmembers = true;
                            $log.info("gpm added cc groupId=" + groupId + ":" , logs);
                        }
                    });

                     if ($scope.totalTime<1 && $scope.dataTimes.logs.length>0) {
                        //edge case we have less than one minute total (no total entry, but counted apps)
                        $scope.totalTimeLessMinute = true;
                    }

                    $scope.adjustTimes();
                    $scope.adjustHistory();
                    $scope.loadingUsage = false;


                }, function error(err) {
                    $scope.loadingUsage = false;
                    if ($scope.isReport!=true) modalFactory.timeoutModal();
                });
        }

        $scope.getLimitEntriesInfo = function(logs) {
            var entriesPostArr = [];
            _.forEach(logs, function(val) {
                entriesPostArr.push({entry: val.Entry, kind:val.Kind});
            });
            ccUsageFactory.getLimitEntriesInfo(entriesPostArr)
                .then(function success(response) {
                    var hasLimitEntries = response.data;
                    _.forEach(logs, function(logsEntry) {
                        _.forEach(hasLimitEntries, function(limitEntry) {
                            if (limitEntry.entry == "- Internet") {
                                limitEntry.entry = $translate.instant('USAGE.PART_INTERNET');
                                $log.info('limitInfoDone', $scope.limitInfoDone);
                            }
                            if (limitEntry.entry === logsEntry.Entry) {
                                logsEntry.limitInfo = limitEntry;
                            }
                        });
                    });
                    $scope.limitInfoDone = true;
                });
        }

        $scope.showUnlock = function() {
            $state.go('cc-usage.unblock');
        };

        $scope.hasUnlockReqs = function() {
            $log.info('hasUnlockReqs INIT');
            ccUsageFactory.getUnlockReqs()
                .then(function success(response) {
                    $log.info('response.data=', response.data);
                    $log.info('response.data length=', response.data.length);
                    if (response.data && response.data.length > 0) {
                        $scope.showUnlockInfo = true;
                    }
                });
        };



        $scope.pdfExportServer = function () {
            $log.info('PDF Export from Backend!');
            scgGlobalSpinnerFactory.blockUI();
            var pdfurl = apiFunctions.get_cc_base_url() +  'ccusage/bydatesall/' + $scope.startDate + '/' + $scope.stopDate + '/' + $scope.howMany+ '?format=pdf';
            $log.info('PDF url:' + pdfurl);
            $http({
                url : pdfurl,
                method : 'GET',
                params : {},
                headers : {
                    'Content-type' : 'application/pdf',
                },
                responseType : 'arraybuffer'
            }).success(function(data, status, headers, config) {
                // TODO when WS success
                $log.info('PDF success');
                scgGlobalSpinnerFactory.unblockUI();
                if (navigator.appName == 'Microsoft Internet Explorer' ||  !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv 11/)) || $.browser.msie == 1)
                {
                    var fileIE = new Blob(([data]), { type: 'application/pdf' });
                    if (window.navigator.msSaveOrOpenBlob) {
                        navigator.msSaveBlob(fileIE, 'report.pdf');
                    }
                } else {
                    var file = new Blob([data], {
                        type: 'application/csv'
                    });
                    //trick to download store a file having its URL
                    var fileURL = URL.createObjectURL(file);
                    var a = document.createElement('a');
                    a.href = fileURL;
                    a.target = '_blank';
                    a.download = 'report.pdf';
                    document.body.appendChild(a);
                    a.click();
                }
            }).error(function(data, status, headers, config) {
                //TODO when WS error
                scgGlobalSpinnerFactory.unblockUI();
                $log.info("PDF ERROR");
            });
        }



        $scope.pdfExport = function() {
            $log.info('PDF Export');

            var canvasEl = angular.element('#pdfCanvas');
            var docWidth = 595.28;

            angular.element('body').scrollTop(0);
            canvasEl.width((docWidth * 1.33333) - 80).css('max-width','none');

            html2canvas(canvasEl, {
                onrendered: function(canvas) {
                    var img = canvas.toDataURL('image/png');
                    // jscs:disable
                    var doc = new jsPDF('p','pt',[canvasEl.height() * 0.78,canvasEl.width() * 0.84]);
                    // jscs:enable
                    doc.addImage(img, 'JPEG', 20, 20);
                    /* CANCELED DUE TO BAD DESIGNS AND CRAPPY FONTS
                     doc.addHTML(canvasEl.get(0), {pagesplit: true}, function(){
                     doc.save('test.pdf');
                     canvasEl.width(cache_width);
                     }); */

                    doc.save('report.pdf');
                }
            });

        };

        $scope.showRuntimesBadge = function(kind) {
            var str = '';
            if (kind == 11) {
                str = 'Gr.1';
            }
            if (kind == 12) {
                str = 'Gr.2';
            }
            if (kind == 13) {
                str = 'Gr.3';
            }
            if (kind == 14) {
                str = 'Gr.4';
            }
            if (kind == 15) {
                str = 'Gr.5';
            }
            return str;
        };

        $scope.showBadge = function(listKind) {
            var str = '';
            //$log.info('Badge for listKind:' , listKind);
            if (listKind == 2) {
                str = $translate.instant('TABS.TIMELIMITED');
            }
            if (listKind == 10) {
                str = $translate.instant('TABS.INTERNET');
            }
            if (listKind == 10) {
                str = $translate.instant('TABS.INTERNET');
            }
            if (listKind == 11) {
                str = 'Gr.1';
            }
            if (listKind == 12) {
                str = 'Gr.2';
            }
            if (listKind == 13) {
                str = 'Gr.3';
            }
            if (listKind == 14) {
                str = 'Gr.4';
            }
            if (listKind == 15) {
                str = 'Gr.5';
            }
            return str;
        };


        $scope.calculateHideSome=function() {
            $scope.hideUsageSome= false;
            if ($scope.dataSettings.hideUsageRuntimePrograms==true) {$scope.hideUsageSome=true;}
            if ($scope.dataSettings.hideUsageRuntimeWebsites==true) {$scope.hideUsageSome=true;}
            if ($scope.dataSettings.hideUsageHistoryPrograms==true) {$scope.hideUsageSome=true;}
            if ($scope.dataSettings.hideUsageHistoryWebsites==true) {$scope.hideUsageSome=true;}
            if ($scope.dataSettings.hideUsageHistoryBlocked==true) {$scope.hideUsageSome=true;}
            if ($scope.dataSettings.hideUsageHistorySystem==true) {$scope.hideUsageSome=true;}
            var hideAny_object = {"hideUsageAny":$scope.hideUsageSome};
            if (!$scope.isReport && $scope.hideUsageSome==true) { ccUsageFactory.setUsageSettings(hideAny_object);}
        }


        $scope.updateRoamingTimes = function() {
            $scope.roamingGroup = $scope.dataSettings.roaming.replace("F","");
            $log.info('Roaming YES WE have, our group is=',$scope.roamingGroup);
            $scope.getRoamingTimes($scope.startDate,$scope.stopDate,$scope.roamingGroup);
        }


        $scope.getUsageSettings = function() {
            ccUsageFactory.getUsageSettings()
                .then(function success(response) {
                    $scope.dataSettings = response.data;
                    $log.info('getUsageSettings',$scope.dataSettings);
                    $scope.dataSettingsDone = true;
                    $log.info('usage get settings!', $scope.dataSettings);

                    //do we have roaming? if yes, do the roaming call
                    if ($scope.dataSettings.roaming && $scope.dataSettings.roaming!=''){
                        $scope.updateRoamingTimes();
                    }

                    //add watchers, important context is here dataSettings!
                    //---------------------------------------------------------
                    _.each($scope.dataSettings, function(v, k) {
                        $scope.$watch('dataSettings.' + k, function(new_val, old_val) {
                            if (new_val != old_val) {
                                var changed_object = {};
                                changed_object[k] = new_val;
                                $log.info('usage changed settings!',k);
                                scgSharedFactory.needsSync = true;
                                $scope.calculateHideSome();
                                ccUsageFactory.setUsageSettings(changed_object);
                            }
                        }, true);
                    });
                });
        };

        $scope.limitClickPcLimit = function() {
            $log.info("limitClickPCLimit");
            if ($scope.isReport===true) {
                $scope.tmpLim = {};
                $scope.tmpLim.limitInfo = {};
                $scope.tmpLim.limitInfo.entry="PC";
                $scope.tmpLim.limitInfo.kind=1;
                $scope.limitClick($scope.tmpLim,100);
            }
        }

        $scope.limitClick = function(limitEntry,sectionId) {

            $log.info("limitClick ", limitEntry);
            //sectionId is part of uaage:
            //100 -> RunTime Device
            //110 -> Runtime Apps
            //120 -> RunTime Websites

            var entry = limitEntry.limitInfo.entry;
            var kind = limitEntry.limitInfo.kind;     //this is returned from limitentries response!
            var hasLimit = limitEntry.limitInfo.hasLimit;
            var listKind = limitEntry.listKind;

            //$log.info('LimitClick not implemented yet for kind=' + kind + ' and entry ' + entry);
            if( $scope.isReport === true ){
                $log.info('LimitClickCC REPORT-MODE kind=' + kind + ' and entry=' + entry + ' and hasLimit=' + hasLimit) ;

                if (kind<10 || kind>15) {
                    ccUsageFactory.getLimitinfo(entry)
                        .then(function success(response) {
                                $scope.dataLimitinfo = response.data;
                                $scope.dataLimitinfo.entry = entry;
                                if ($scope.dataLimitinfo.blockedTimes==undefined || $scope.dataLimitinfo.blockedTimes===scgSharedFactory.blockOff())
                                {
                                    $log.info('YES BLOCKEDTIMES UNDEFINED');
                                    $scope.dataLimitinfo.blockedTimes=undefined;
                                }
                                $log.info('success, response is ', $scope.dataLimitinfo);
                                $scope.showLimitinfo=true;
                            },
                            function error(err) {
                                $scope.showLimitinfo=false;
                                if (err && err.status && err.status==401 && $scope.isReport) {
                                    $scope.logout();
                                }
                            }
                        )
                } else {
                    //we have to lookup kind instead of entry
                    ccUsageFactory.getLimitinfoByKind(kind)
                        .then(function success(response) {
                                $scope.dataLimitinfo = response.data;
                                $scope.dataLimitinfo.entry = entry;
                                if ($scope.dataLimitinfo.blockedTimes==undefined || $scope.dataLimitinfo.blockedTimes===scgSharedFactory.blockOff())
                                {
                                    $log.info('YES BLOCKEDTIMES UNDEFINED');
                                    $scope.dataLimitinfo.blockedTimes=undefined;
                                }
                                $log.info('success, response is ', $scope.dataLimitinfo);
                                $scope.showLimitinfo=true;
                            },
                            function error(err) {
                                $scope.showLimitinfo=false;
                                if (err && err.status && err.status==401 && $scope.isReport) {
                                    $scope.logout();
                                }
                            }
                        )
                }


            }else {
                $log.info('LimitClickCC PARENTS-MODE --- kind=' + kind + ' listKind=' + listKind + '|entry=' + entry);
                //---------------------------- PARENT MODE ------------------------------------

                var modal_settings = {
                    mode: 'edit',
                    //context: 'prg' or 'net',
                    title: $translate.instant('MODAL_EDIT_RECORD.TITLE_EDIT'),
                    //newRecord: null,
                    //editRecords: editRecords,
                    refreshStateAfterClose: false
                };

                //fix kind=0 for exe, seems to be a brandnew exe
                if (kind==0 && entry.indexOf(".exe")>0) {
                    modal_settings.editRecords = null;
                    modal_settings.newRecord = {
                        kind: 1,
                        texts: [entry]
                    };
                    modal_settings.context = 'prg';
                    modal_settings.mode = 'add';
                    openEditModal(modal_settings);
                    return;
                }


                if (kind==0) {
                    $state.go('cc-timelimits.pc');
                    return;
                }
                if (kind==10) {
                    $scope.loadInetPrgrams();
                    return;
                }

                //Attention: kind is coming here from limitentries .kind
                if( kind == 5 || kind==6 || kind==7 || kind==8 || kind==22){
                    modal_settings.context = 'net';
                }else {
                    modal_settings.context = 'prg';
                }

                if( (kind >= 1 && kind <= 4) || (hasLimit===true && (listKind < 10 && listKind > 15))){
                    //Normal edit modal
                    $log.info('limitclick normal edit entered!');
                    var editRecords = [];
                    var obj = {data: {'entry': entry}};
                    editRecords.push(obj);

                    modal_settings.newRecord = null;
                    modal_settings.editRecords = editRecords;
                    modal_settings.mode = 'edit';

                    openEditModal(modal_settings);

                }else if((listKind >=10 && listKind <=15) || (kind>=10 && kind<=15)){

                    modal_settings.context = 'prg';
                    if (kind>=10 && kind<=15) listKind=kind;
                    $log.info('limitclick groups entered!');
                    //handle group edit (load multimple entries into edit modal)
                    ccListsFactory.getDetailedByListFilter(listKind)
                        .then(function success(response) {
                            var groupEntries = response.data;

                            var editRecords = [];

                            _.each(groupEntries, function(groupEntry){
                                var obj = {data: {'entry': groupEntry.entry}};
                                editRecords.push(obj);
                            });

                            modal_settings.newRecord = null;
                            modal_settings.editRecords = editRecords;
                            modal_settings.mode = 'edit';

                            openEditModal(modal_settings);
                        });

                } else {

                    //This is new entry ADD case, if it is not kind=7 (web) its context prg
                    modal_settings.editRecords = null;
                    modal_settings.newRecord = {
                        kind: kind,
                        texts: [entry]
                    };
                    modal_settings.mode = 'add';
                    openEditModal(modal_settings);
                }

            }

        }




        function openEditModal(modalSettings){
            //open edit modal for this entry
            modalFactory.editModal.open({
                modal_settings: modalSettings,
                callbacks: {
                    ok: function() {},
                    cancel: function() {}
                }
            });
        }

        $scope.exitLimitinfo = function() {
            $log.info('exitLimitinfo clicked');
            $scope.showLimitinfo=false;
        }


        $scope.logout = function() {
            $state.go('login-logoff-success');
        }

        $scope.scrollToUsageSettings = function() {
            $location.hash('UsageSettings');
            $anchorScroll();
        }

        $scope.usageEdit = function(entry, context) {
            if (context=='prg') {
                $scope.lookupExe(entry);
            }
            if (context=='net') {
                $scope.lookupWeb(entry);
            }
        };

        $scope.showDayByWeekStats= function(azdate) {
            $scope.startDate = azdate;
            $scope.stopDate = azdate;
            $scope.perioddropdown = "7"; //custom period
            $scope.loadingUsage = true;
            ccUsageFactory.getUsageByDates($scope.startDate,$scope.stopDate,$scope.howMany)
                .then(function success(response) {
                    $scope.data = response.data;
                    $scope.hasNoData = false;
                    if (response.data.logs.length == 0 && response.data.history.length == 0) {
                        $scope.hasNoData = true;
                    }
                    $scope.adjustTimes();
                    $scope.adjustHistory();
                    $scope.loadingUsage = false;
                });
        }

        $scope.getPercentageFromStats = function(weekstats){
            var result = [];
            var total = 0;
            var highestVal = 0;
            var singleVal = 0;
            _.forEach(weekstats, function(obj,index){
                total += obj.seconds;
                if (obj.seconds>highestVal) {highestVal=obj.seconds}
            })
            _.forEach(weekstats, function(obj,index){
                singleVal = obj.seconds/highestVal*100;
                if (isNaN(singleVal)) {
                    result.push(0)}
                else {result.push(singleVal);}
            })
            return result;
        }


        $scope.getRoamingTimes = function(startdate,stopdate,roamingGroup) {
            $scope.loadingRoaming = true;
            $scope.hasRoamingGroupData=false;
            ccUsageFactory.getRoamingTimes(startdate,stopdate,roamingGroup)
                .then(function success(response){
                    $scope.roamingData = response.data;
                    $log.info('roamingdata=', $scope.roamingData);
                    //Iteration for hasRoamingGroup
                    _.forEach($scope.roamingData, function(item) {
                        if (item.Kind>=10) {
                            $scope.hasRoamingGroupData=true;
                        }
                        });
                    $scope.loadingRoaming = false;
                });
        }


        scgSharedFactory.getServerTimeDiff().then(function(funcresult) {
            $scope.serverTimeDiff = funcresult;
        });

        $scope.getImgName = function(entryName){
            var ret = (entryName || "").replace(".exe", ".png");
            return ret;
        }


        $scope.lookupExe = function(entry) {
            $log.info('lookupExe with entry=' + entry);
            $state.go('detail-view', {appIds: [entry], appType: chicoEnums.AppTypeEnum.WINDOWS_APP, allowAdd: false,prevStateName: $state.current.name});
        }

        $scope.lookupGroup = function(logentry) {
            var groupMember = [];
            _.forEach($scope.gpm[logentry.Kind],function(v){
                groupMember.push(v.Entry);
            })
            $state.go('detail-view', {appIds: groupMember, appType: chicoEnums.AppTypeEnum.WINDOWS_APP, allowAdd: false,prevStateName: $state.current.name});
        }


        $scope.lookupWeb = function(url) {
            $log.info('lookupWeb for url=' + url + '|username=' + $scope.activeUser.username);
            $state.go('detail-view', {appIds: [url], appType: chicoEnums.AppTypeEnum.WEBSITE, allowAdd: false,prevStateName: $state.current.name});
        }

        $scope.showMoreTimesApp = function() {
            $scope.rowTimesApp = $scope.rowTimesApp + $scope.defaultCountTimes;
        }

        $scope.showMoreTimesUrl = function() {
            $scope.rowTimesUrl = $scope.rowTimesUrl + $scope.defaultCountTimes;
        }

        $scope.showMoreHistoryApp = function() {
            $scope.rowHistoryApp = $scope.rowHistoryApp + $scope.defaultCountHistory;
        }

        $scope.showMoreHistoryUrl = function() {
            $scope.rowHistoryUrl = $scope.rowHistoryUrl + $scope.defaultCountHistory;
        }

        $scope.showMoreHistoryBlocked = function() {
            $scope.rowHistoryBlocked = $scope.rowHistoryBlocked + $scope.defaultCountHistory;
        }

        $scope.showMoreHistorySystem = function() {
            $scope.rowHistorySystem = $scope.rowHistorySystem + $scope.defaultCountHistory;
        }

        $scope.showMoreHistoryPortal = function() {
            $scope.rowHistoryPortal = $scope.rowHistoryPortal + $scope.defaultCountHistory;
        }


        if ($scope.preselectedWeekday!=null)
        {
            $scope.startDate = $scope.preselectedWeekday;
            $scope.stopDate = $scope.preselectedWeekday;
            $scope.perioddropdown = "7"; //custom period

            $scope.loadingUsage = true;
            ccUsageFactory.getUsageByDates($scope.startDate,$scope.stopDate,$scope.howMany)
                .then(function success(response) {
                    $scope.data = response.data;
                    $scope.hasNoData = false;
                    if (response.data.logs.length == 0 && response.data.history.length == 0) {
                        $scope.hasNoData = true;
                    }

                    $scope.loadingUsage = false;
                });
        }


        $scope.showLocalView = function() {
            $log.info("showLocalView");
            $scope.isRoamingView=false;
            $scope.getChartData($scope.startDate,$scope.stopDate); // refresh *after* setting isRoamingView!
        }

        $scope.showRoamingView = function() {
            $log.info("showRoamingView, startDate=" + $scope.startDate);
            $scope.isRoamingView=true;
            $scope.getChartData($scope.startDate,$scope.stopDate); // refresh *after* setting isRoamingView!
        }

        $scope.showDeviceDetails = function() {
            $scope.loadingRoamingMembers=true;
            ccUsageFactory.getDeviceDetails($scope.startDate,$scope.stopDate)
                .then(function success(response) {
                    $log.info('getDeviceDetails api arrived:', response);
                    $scope.roamingMembers = response.data;
                    $scope.loadingRoamingMembers=false;
                });
        }

        $scope.showGroupMembers = function(group) {

            if ($scope.hasGroupmembers==false) {
                return;
            }
            if ($scope.showgroup[group]==true) {
                $scope.showgroup[group]=false;
            } else {
                if ($scope.gpmDone[group]==true) {
                    $scope.showgroup[group] = true;
                    return;
                }
                $scope.loadingGroupMember[group]=true;

                ccUsageFactory.getGroupDetails($scope.startDate, $scope.stopDate,group).then(
                    function success(response) {
                        $log.info('getgroupArrived group=', group);
                        $log.info('getgroupArrived response=', response);
                        $scope.gpm[group] = response.data;
                        $scope.showgroup[group] = true;
                        $scope.showgroup[group]=true;
                        $scope.loadingGroupMember[group]=false;
                    }
                )
            }
        }

        $scope.displayGroupnumber = function(kind) {
            if (kind==10) {
                return "";
            } else {
                return kind-10;
            }
        }

        $scope.displayGroupnumbername = function(kind) {
            if (kind==10) {
                return $translate.instant('TABS.GROUPS');
            } else {
                var groupno = (kind - 10);
                return $translate.instant('TABS.GROUPS') + " " + groupno;
            }
        }

        $scope.displayGroupname = function(name) {
            if (name=="group_10") {
                return "Internet";
            } else {
                return name;
           }
        }

        $scope.showGroupMembersRoaming = function(group) {
            $log.info("showgroupmembersRoaming for group=" + group);
            if ($scope.showgroupRoaming[group]==true) {
                $scope.showgroupRoaming[group]=false;
            } else {
                $scope.showgroupRoaming[group] = true;
                $scope.getGroupTimesRoaming(group);

                //we must add the non listed


            }
        }


        $scope.devSetReportView = function() {
            $scope.isReport=true;
            $log.info("devmode - isReport set to TRUE");
        }




        $scope.getGroupTimesRoaming = function(group) {
            $scope.loadingRGTimes[group] = true;
            ccUsageFactory.getRoamingGroupTimes($cookies.get('user'),$scope.startDate,$scope.stopDate,group-10)
                .then(function success(response) {
                    $scope.rgTimes = response.data;
                    $scope.gpmRoaming[group].length=0; //clear all groupmember array

                    var existingEntries = [];
                    _.forEach($scope.rgTimes, function(rgTime) {
                        if (rgTime.kind == group+1000) {
                            var groupId = rgTime.kind-1000;
                            rgTime.kind=rgTime.kind-1000;
                            $scope.gpmRoaming[groupId].push(rgTime);
                            existingEntries.push(rgTime.entry);
                        }
                    });
                    ccUsageFactory.getAllRoamingGroupEntries(group-10).then(
                        function success(response) {
                            _.forEach(response.data, function(obj){
                                if (existingEntries.indexOf(obj)==-1) {
                                    var addItem = {};
                                    addItem.entry = obj;
                                    addItem.period = "--:--";
                                    $scope.gpmRoaming[group].push(addItem);
                                }
                            })
                            $scope.loadingRGTimes[group] = false;
                        }
                    )
                },  function (err) {
                    $scope.loadingRGTimes[group] = false;
                });
        }


        // BEGIN NEW STUFF
        // ----------------------------------------------------------------------
        this.$onInit = function() {

            $log.info('onInit periodKind=' + $stateParams.periodKind + '|hasChartFeature=' + $scope.hasDayViewFeature);
            if ($stateParams.periodKind==2) {
                $scope.startDate =  moment().startOf('isoweek').format('YYYY-MM-DD');
                $scope.stopDate =  moment().endOf('isoweek').format('YYYY-MM-DD');
                $scope.onDateChange();
            }
            if ($stateParams.periodKind==3) {
                $scope.startDate =  moment().startOf('month').format('YYYY-MM-DD');
                $scope.stopDate =  moment().endOf('month').format('YYYY-MM-DD');
                $scope.onDateChange();
            } else {
                $scope.startDate = $scope.stopDate = moment().format('YYYY-MM-DD');
                $scope.onDateChange();
            }
        }

        $scope.updateCaptions = function() {
            $scope.totalTimeCaption = $scope.scgSharedFactory.getTotalTimeCaption($scope.startDate,$scope.stopDate);
            $scope.chartCaption =$scope.totalTimeCaption;
        }

        $scope.onStartDateChange = function(newVal, oldVal) {
            $scope.startDate = newVal;
        }

        $scope.onEndDateChange = function(newVal, oldVal) {
            $scope.stopDate = newVal;
        }

        $scope.onDateChange = function() {
            $log.info('onDateChange from=' + $scope.startDate + " to " + $scope.stopDate);

            $scope.isHourView = false;
            $scope.showDeviceDetails();
            $scope.updateCaptions();
            $scope.getTimes();

            $scope.getChartData($scope.startDate,$scope.stopDate);
        }

        $scope.onDatePickerClick = function() {
            $log.info('onDatePickerClick startDate=' + $scope.startDate + '|stopDate=' + $scope.stopDate + '|isHourView=' + $scope.isHourView);
            //go back to day view if we are currently in hour view
            if ($scope.isHourView===true && $scope.startDate==$scope.stopDate) {
                $scope.onDateChange();
            }
        }

        $scope.onChartColClick = function(colIndex, colData) {
            $log.info('onChartColClick colIndex=' + colIndex + '|colData=' + colData);
            if ($scope.startDate==$scope.stopDate) {
                $log.info('onChartColClick WE are inside day view');
                if ($scope.isRoamingView) {
                    $log.info('onChartColClick no Action in RoamingView');
                    return;
                }
                $scope.loadingUsage = true;
                $scope.isHourView = true;
                $scope.getTimesByHour(colIndex);
                //Prepare big time box
                var toHour = colIndex +1;
                var secsPerHour = $scope.subCaptions[colIndex].replace('0:','') * 60;
                $scope.totalTimeCaption = $scope.chartCaption + ' ' + colIndex + '-' + toHour + 'h';
                $scope.isRoamingView = false;
                $scope.totalTime = secsPerHour;        //totalTime is formatted with seconds
                $log.info('onChartColClick totalTime=' + $scope.totalTime + '|hasNoDataTimes=' + $scope.hasNoDataTimes);
            } else {
                var x = $scope.azDate[colIndex];
                $log.info('week click x=' + x);
                $scope.isHourView = false;
                $scope.startDate = x;
                $scope.stopDate = x;
                $scope.onDateChange();
            }
        }


        $scope.getChartData = function(fromDate,toDate) {
            var req;
            if (fromDate===toDate) {
                req = ccUsageFactory.getChartDayApi(fromDate);
                if ($scope.isRoamingView) req = ccUsageFactory.getChartDayRoamingApi(fromDate,$scope.roamingGroup);
            } else {
                req = ccUsageFactory.getChartStatsApi(fromDate,toDate);
                if ($scope.isRoamingView) req = ccUsageFactory.getChartStatsRoamingApi(fromDate,toDate,$scope.roamingGroup);
           }
            $scope.loadingChart = true;
            req.then(function(res) {
                $scope.hasRoamingVals=false;
                $scope.azDate = [];
                $scope.chartData = [];
                $scope.chartDatasecondary = [];
                $scope.Captions = [];
                $scope.subCaptions = [];
                var total = res.data.length;
                for (var i = 0; i < total; i++) {
                    $scope.chartData.push(res.data[i].percent);
                    if (res.data[i].percentSecondary) {
                        $scope.hasRoamingVals=true;
                        $scope.chartDatasecondary.push(res.data[i].percentSecondary);
                    } else {
                        $scope.chartDatasecondary.push(0);
                    }
                    var caption = res.data[i].azdate;
                    $scope.azDate.push(res.data[i].azdate);
                    if (fromDate!=toDate && total<=10) caption=moment(res.data[i].azdate, 'YYYY-MM-DD').format('Do. MMM');
                    if (fromDate!=toDate && total>20) caption=moment(res.data[i].azdate, 'YYYY-MM-DD').format('Do.');
                    $scope.Captions.push(caption);
                    var seconds = $scope.formatSecs(res.data[i].seconds,true);
                    $log.info('Chart push i=' + i + '|seconds=' + seconds);
                    $scope.subCaptions.push(seconds);
                }
                $scope.showTooltips = true;
                if (total<=10) {
                    $scope.showCaptions=true;
                    $scope.showSubCaptions=true;
                } else {
                    $scope.showCaptions=true;
                    $scope.showSubCaptions=false;
                }

                if ($scope.hasDayViewFeature==false && fromDate===toDate) {
                    $scope.setDayChartAvailable(false);
                }  else {
                    $scope.setDayChartAvailable(true);
                }

                $scope.loadingChart = false;


            });
        };

        $scope.setDayChartAvailable = function(status) {
            if (status) {
                $scope.overlayText = '';
                $scope.overlayUrl = '';
            } else {
                $translate('INFO.UPDATE_FOR_FEATURE').then(function(translated) {
                    $scope.overlayText = translated;
                });
                $translate('URL.UPDATE_CC').then(function(translated) {
                    $scope.overlayUrl = translated;
                    if ($scope.isReport===true) $scope.overlayUrl='';
                });
            }
        }

        $scope.getTimebox = function(fromDate,toDate) {
            $scope.loadingUsage = true;
            ccUsageFactory.getTotalTimesPeriod(fromDate,toDate).then(function success(response) {
                $scope.timebox = response.data;
                $log.info('timebox returned:', $scope.timebox);
                $scope.loadingUsage=false;
            });
        }

        // EOF NEW STUFF INSIDE CC **************************************




        // MODAL FOR ROAMING MEMBERS
        $scope.roamingSettings = function() {
            $log.info("roamingSettings called ");
            modalFactory.roamingModal.open({
                modal_settings: {
                    refreshStateAfterClose: false
                },callbacks: {
                    ok: function () {
                        $log.info('modal-until ok callback');
                        //we need little time before refresh/reload to save the roaming setttings
                        scgGlobalSpinnerFactory.blockUI();
                        $timeout( function(){
                            $log.info('modal-roaming reload');
                            scgGlobalSpinnerFactory.unblockUI();
                            $state.reload();
                        }, 2000 );
                    }}
            });
        }

        //Default Calls
        //-----------------------------------------------
        $log.info('welcome to cc-usages v2, stateParams.periodKind=' + $stateParams.periodKind);
        $scope.hasUnlockReqs();
        $scope.getUsageSettings();

        $translate.onReady().then(function() {
            $scope.updateCaptions();
        });



        $scope.$watch(function() {
            return AuthFactory.activeDevice.versionInfo;
        }, function(newVal) {
            if (AuthFactory.activeDevice.versionInfo) {
                $scope.hasDayViewFeature = $scope.hasCCFeature('dayChart',AuthFactory.activeDevice.versionInfo);
                $scope.setDayChartAvailable($scope.hasDayViewFeature);
                $log.info('watch hasDayViewFeature='+ $scope.hasDayViewFeature);
            }
        });

        //watch active user
        $scope.$watch(function() {
            return AuthFactory.activeUser;
        }, function(newVal) {
            $scope.activeUser = newVal;
        });

    });

