'use strict';
angular.module('ChicoApp.CC-Programs', [])
    .config(function($stateProvider) {
      $stateProvider
        .state('cc-programs', {
          url: '/cc-programs',
          redirectTo: 'cc-programs.tabs.allowed',
          controller: 'CC-ProgramsController',
          templateUrl: 'templates/childcontrol/cc_programs/templates/programs.html'
        })

        .state('cc-programs.tabs', {
          url: '/tabs',
          templateUrl: 'templates/childcontrol/cc_programs/templates/programs-tabs.html',
        })

        .state('cc-programs.tabs.allowed', {
          url: '/allowed',
          controller: 'CC-Programs-Allowed-Controller',
          templateUrl: 'templates/childcontrol/cc_programs/templates/programs-allowed.html',
        })

        .state('cc-programs.tabs.blocked', {
          url: '/blocked',
          controller: 'CC-Programs-Blocked-Controller',
          templateUrl: 'templates/childcontrol/cc_programs/templates/programs-generic-list.html',
        })

        .state('cc-programs.tabs.timelimited', {
          url: '/timelimited',
          controller: 'CC-Programs-TimeLimited-Controller',
          templateUrl: 'templates/childcontrol/cc_programs/templates/programs-generic-list.html',
        })

        .state('cc-programs.tabs.groups', {
          url: '/groups',
          controller: 'CC-Programs-Groups-Controller',
          templateUrl: 'templates/childcontrol/cc_programs/templates/programs-groups.html',
        })

          .state('cc-programs.tabs.groups-v2', {
            url: '/groups-v2',
            controller: 'CC-Programs-Groups-V2-Controller',
            templateUrl: 'templates/childcontrol/cc_programs/templates/programs-groups-v2.html',
          })

        .state('cc-programs.tabs.byage', {
          url: '/byage',
          controller: 'CC-Programs-ByAge-Controller',
          templateUrl: 'templates/childcontrol/cc_programs/templates/programs-byage.html',
        })

          .state('cc-programs.tabs.internet', {
            url: '/internet',
            controller: 'CC-Programs-Internet-Controller',
            templateUrl: 'templates/childcontrol/cc_programs/templates/programs-internet.html',
          });

    })
    .run(function() {});
