angular.module('ChicoApp').factory('sbUsageFactory', function(
    $http,
    apiFunctions,
    $log,
    API) {

    var factory = {};

    var apifields_usage = [
        'isUsageDisabled'
    ];


    factory.getUsageByDates = function(startDate, stopDate) {
        var req = {
            method: 'GET',
            timeout: 30000,
            url: API.url + 'sb/usages/' + startDate + '/' + stopDate
        };
        return $http(req);
    };


    factory.deleteUsage = function() {
        var req = {
            method: 'DELETE',
            timeout: 30000,
            url: API.url + 'sb/usages'
        };
        return $http(req);
    };


    return factory;

});
