'use strict';
angular.module('ChicoApp.CB-Webfilter')

    .controller('CB-Webfilter-Timelimited-Controller', function(
        $scope,
        $state,
        $translate,
        cbListsFactory,
        DTOptionsBuilder,
        $log,
        DTColumnDefBuilder,
        scgDataTableFactory) {

      var modalStartKind = 8; //time limited
      $log.info('Howdy CB-Webfilter-TimeLimited-Controller');

      $scope.tableHeaders = [$translate.instant('HEADER.DOMAIN')];

      $scope.data = {};
      $scope.data.table_api_urls = [];
      $scope.data.table_api_keywords = [];

      scgDataTableFactory.prepareScopeForTable($scope, modalStartKind);
      $scope.dtOptions = scgDataTableFactory.getDefaultDtOptions($scope);

      $scope.dtColumnDefs = scgDataTableFactory.getDefaultDtColumnDefs3Cols();

      $scope.reload = function() {
        $state.go($state.current, {}, {reload: true});
      };

      var loadList = function() {
        $scope.contentLoading = true;
        cbListsFactory.getByListFilter(modalStartKind)
                .then(function success(response) {
                  $scope.fillInnerListFromApiList(response.data);
                  $scope.data.table_api_urls = response.data;
                  $scope.contentLoading = false;
                });
      };

      loadList();

    });

