(function() {
    'use strict';

    angular
        .module('ChicoApp.StartControl', [
            'ChicoApp.SC-Dashboard',
            'ChicoApp.Login',
            'ChicoApp.Internal',
            'ChicoApp.Licence'
        ]);
})();
