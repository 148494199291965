'use strict';
angular.module('ChicoApp.Login')
    .factory('AuthFactory', function ($injector,
                                      $http,
                                      $state,
                                      $timeout,
                                      $translate,
                                      $stateParams,
                                      $rootScope,
                                      moment,
                                      $log,
                                      $cookies,
                                      $q,
                                      $cookieStore,
                                      $interval,
                                      $window,
                                      $location,
                                      localStorageService,
                                      API) {

        var auth = {};
        auth.licenceOk = null;
        auth.activeDevice = {};
        auth.activeLicence = {};
        auth.checkingLicense = false;
        auth.ccLicence = {};
        auth.activeUser = null;
        auth.activeContext = null; //cc or cbcc-lic
        auth.userRole = null;
        //auth.activeLicence.showSideBar=true;

        auth.scgSharedFactoryCallbacks = {
            //onDeviceChanged: function (call when device changes, but not on login)
        };


        auth.gotoSalfeldLoginPage = function(prefilluser)
        {
            //are we localhost
            var currenturl =  $location.absUrl();
            var urlparameter = "";
            if (prefilluser) {
                urlparameter = "?prefilluser=" + prefilluser;
            }
            $log.info('salfeld login-logout, currenturl=',currenturl);
            if (currenturl.indexOf('localhost:')!=-1 && currenturl.indexOf('loginv2')!=-1) {
                $log.info('salfeld login-logout returning normal state because of localhost!');
                $state.go('login', {fullpagereload: true});
                return;
            }

            if ($translate.use()== 'en_US') {
                $window.location.href = 'https://salfeld.com/en/login/' + urlparameter;
            }
            else {
                $window.location.href = 'https://salfeld.de/login/' + urlparameter;
            }
        }


        //init active user and active device from cookie
        if ($cookies.get('user')) {
            auth.activeUser = {
                username: $cookies.get('user')
            };
            auth.activeContext = 'cc';
        } else {
            auth.activeContext = 'cb';
        }
        if ($cookies.get('pcId')) {
            auth.activeDevice = {
                deviceId: $cookies.get('pcId')
            };
        }
        if ($cookies.get('sessionId')) {
            auth.sessionId = $cookies.get('sessionId');
        }

        //if(auth.activeContext=='cc'){
        //  checkCurrentDeviceLicenceCC();
        //}

        auth.extractVersion = function(versionInfo)
        {
            if (typeof versionInfo != 'string'){
                return '';
            }
                var version = versionInfo;
            var posi = version.indexOf('|');

            if (posi!=-1) {
                version = version.substring(0,posi);
            }
            $log.info('extractVersion inStr=' + versionInfo + '|version=' + version + '|posi=' + posi);
            return version.trim();
        }


        auth.logout = function (goToLoginScreen/*, goToSurvey*/) {

            $log.info('LOGOUT INSIDE AUTH', goToLoginScreen);

           //clear session on server
            var req = {};
            var wasDeviceLogin = false;

            req.session = $cookies.get('sessionId');
            $http.post(API.url + 'login/logout', req).then(
                function(response) {
                    $log.info('loguout response=', response);

                    //wasDeviceLogin check - shows a different logout screen
                    if (response && response.data && response.data==="DEVICE-LOGIN") {
                        $log.info('loguout response WAS FROM DEVICE');
                        wasDeviceLogin = true;
                    }

                    $cookieStore.remove('sessionId');
                    $cookieStore.remove('user');
                    $cookieStore.remove('pcId');
                    $cookieStore.remove('oldPcId');
                    $cookieStore.remove('role');
                    $cookieStore.remove('licenceReloadDone');
                    $cookieStore.remove('scgcookie');
                    $cookieStore.remove('version');
                    $cookieStore.remove('manufacturer');

                    //clear http header auth fields
                    $injector.get('$http').defaults.headers.common['X-pcId'] = null;
                    $injector.get('$http').defaults.headers.common['X-sessionId'] = null;

                    auth.activeDevice = {};
                    auth.activeUser = null;
                    auth.sessionId = null;
                    auth.activeContext = null;

                    //Special case device login
                    if (wasDeviceLogin) {
                        $state.go('login-logoff-success');
                        return;
                    }

                    if (goToLoginScreen) {
                        if (localStorageService.get('username')) {
                            //DOES NOT WORK FOR PORTAL CORDOVA APP!!! -> NO:$state.go('loginv2', {fullpagereload: true});
                            window.location = "https://portal.salfeld.net/app/#/loginv2/de";
                        } else {
                            $log.info('normal logout');
                            auth.gotoSalfeldLoginPage();
                            //$state.go('login', {fullpagereload: true});
                        }
                    }
                }
                );
        };




        auth.loggedin = function () {
            if ($cookies.get('sessionId')) {
                return true;
            } else {
                return false;
            }

        };

        auth.setActiveDevice = function (device, user) {
            $log.info('setActiveDevice device', device);
            $log.info('setActiveDevice user', user);
            auth.activeDevice = device;
            auth.activeUser = user;
            auth.sessionId = $cookies.get('sessionId');
            $cookies.put('pcId', device.deviceId);

            if (auth.activeDevice.users && auth.activeDevice.users.length) {
                auth.activeContext = 'cc';
            } else {
                auth.activeContext = 'cb';
            }

           if (auth.activeDevice.deviceType==10 || auth.activeDevice.deviceType==11) {
                $cookies.put('cb2','true');
            } else {
                $cookieStore.remove('cb2');
            }

            if (user) {
                $cookies.put('user', user.username);
            } else {
                $cookieStore.remove('user');
            }
            if (API['add-dev-headers'] == true) {
                $injector.get('$http')
                    .defaults.headers.common['X-pcId'] = $cookies.get('pcId');
            }

            if (user) {
                $log.info('setActiveDevice CC LIC:' + device.deviceId);
                auth.activeContext = 'cc';  //renew cc context, was bug right after login
                if ($cookies.get('oldPcId') != device.deviceId) {
                    $cookies.put('oldPcId', device.deviceId);
                    $log.info('setActiveDevice checkCurrentDeviceLicenceCC');
                    auth.checkCurrentDeviceLicenceCC();
                }
            }

            //Set another context, if we are SC
            if (auth.activeDevice.deviceType==40) {
                auth.activeContext = 'sc';
            }
            if (auth.activeDevice.deviceType==50) {
                auth.activeContext = 'sb';
            }

                //check license for current device
            if (auth.activeContext == 'cb') {
                auth.checkCurrentDeviceLicenceCB();
            }

            //set the onDeviceChanged device (handles hasChanged backend call)
            $log.info('setActiveDevice DEVICE CHANGE! deviceID=' + device.deviceId + '|context=' + auth.activeContext);
            auth.scgSharedFactoryCallbacks.onDeviceChanged();

        };

        auth.checkCurrentDeviceLicenceCC = function () {

            $log.info("checkCurrentDeviceLicenceCC entry");
            //Caused some issues in live version, maybe timing issue (devicetype was 2 in test case!)
            /*if (auth.activeDevice.deviceType==0) {
                $log.info("checkCurrentDeviceLicenceCC return A");
                return;
            }*/

            if(auth.checkingLicense == true){
                $log.info("checkCurrentDeviceLicenceCC return B");
                return;
            }
            auth.checkingLicense = true;
            $http.get(API.url + '/licence/licencecheck')
                .then(function success(response) {
                    $log.info("checkCurrentDeviceLicenceCC response=", response);
                    auth.checkingLicense = false;
                    auth.activeLicence = response.data;

                    if (response.data.expireDays > 10 || response.data.serial != '') {
                        auth.licenceOk = true;
                        $log.info("state licence=" + $state.is('licence') + "|state cc-dash3=" + $state.is('cc-dashboard-v3'));
                        if ($state.is('licence')) {
                            $state.go('cc-dashboard-v3');
                            return;
                        }
                    }

                    if (response.data.expireDays < 10 || response.data.serial === '') {
                        auth.licenceOk = false;
                        $state.go('licence');
                    } else {
                        auth.licenceOk = true;
                    }
                });
        };

        auth.checkCurrentDeviceLicenceCB = function () {

            //Caused some issues in live version, maybe timing issue (devicetype was 2 in test case!)
            /*if (auth.activeDevice.deviceType==0) {
                return;
            }*/

            if(auth.checkingLicense == true){
                return;
            }
            auth.checkingLicense = true;
            $http.get(API.url + '/licence/licencecheck')
                .then(function success(response) {
                    auth.checkingLicense = false;
                    auth.activeLicence = response.data;
                    if (response.data.expireDays < 10 || response.data.serial === '') {
                        auth.licenceOk = false;
                        $state.go('licence');
                    } else {
                        auth.licenceOk = true;
                    }
                });
        };

        auth.checkCurrentDeviceLicenceCBwithCache = function () {

            if (auth.activeDevice.deviceType==0) {
                return;
            }

            if (!auth.activeLicence.expireDays) {
                auth.checkCurrentDeviceLicenceCB();
            } else {
                if (auth.activeLicence.expireDays <= 0) {
                    auth.licenceOk = false;
                    $state.go('licence');
                }
            }
        };



        auth.login = function (username, password, options) {

            options = options || {};
            $rootScope.username = username;
            var req = {};
            $cookies.put('licenceReloadDone', false);
            $log.info('login in auth.login options=', options);

            if (username && password) {
                req.browsertime = moment().format('YYYY-MM-DD HH:mm:ss'),
                    req.language = $translate.use(),
                    req.username = username;
                req.password = password;

                return $http.post(API.url + 'login/', req).then(
                    success, error);

            } else if (options.logintoken) {
                $log.info('Auth via logintoken');
                return $http.get(API.url + 'login/' + options.logintoken).then(success, error);

            } else if (options.parentstoken) {
                $log.info('Auth via parentstoken');
                return $http.get(API.url + 'login/' + options.parentstoken).then(success, error);

            } else if (options.reportlogintoken) {
                $log.info('login reportlogintoken found, redirecting....');
                return $http.get(API.url + 'login/' + options.reportlogintoken).then(success, reportLoginError);
            }

            function reportLoginError(response) {
                $log.info('Report login failed');

                //if token is not valid, inform user how to initilize
                if( response.status == 401 ){
                    $state.go('report-notready');
                }

                return $q.reject(response.data);
            }

            function error(response) {
                $log.info('Login failed');
                $rootScope.username = '';
                // throw 'next error callback please'; //we neeed this to make angular continue with next error callback in login controller
                return $q.reject(response.data);
            }

            function success(response) {
                //Login success
                $log.info('Login success', response.data);
                $log.info(response.data);

                //clear old pc id from cookie - only relevant on device change, not on inital login
                $cookieStore.remove('oldPcId');

                //clear old role from cookie to maybe later store new one
                $cookieStore.remove('role');

                //if there are no devices, show special info screen
                if (response.data.sessionID == null) {
                    $state.go('devicenotfound');
                    return;
                }

                // Secure attribute causes problems with chromium (not sure what's the cause)
                // $cookies.put('sessionId', response.data.sessionID, { secure: true });

                $cookies.put('sessionId', response.data.sessionID);

                //If there is a session-id in the cookie we attach it to the header for crossplattform purposes (localhost vs salfeld)
                if (API['add-dev-headers'] == true) {
                    $injector.get('$http')
                        .defaults.headers.common['X-sessionId'] = $cookies.get('sessionId');
                }

                if (response.data.user) {
                    auth.setActiveDevice({
                        deviceId: response.data.pcId,
                        deviceType : response.data.deviceType
                    }, {
                        username: response.data.user
                    });
                } else {
                    auth.setActiveDevice({
                        deviceId: response.data.pcId,
                        deviceType: response.data.deviceType
                    });
                }

                //react to special roles
                if(response.data.role) {
                    $cookies.put('role', response.data.role);

                    $log.info('login special user role');
                    //var expiryDate = moment().add(10, "minutes").toDate();;
                    //$cookies.put('role', response.data.role, [{expires:expiryDate}]);

                    if ( response.data.role == "report" ) {

                        $log.info("login USER ROLE:", response.data.role);

                        //if user is "allusers" we know that this is cb context
                        if( response.data.user == "allusers" ){
                            //$state.go('cb-usage.reports');
                            $log.info("userRole", "XXXXX");
                            //$state.go('cb-usage.reports', {fullpagereload: true});
                            $log.info('login cb-usage.reportv2 state.go');
                            $state.go('cb-usage.reportsv2', {}, {reload: true});
                        }else {
                            //auth.activeUser = {username:response.data.user};
                            //auth.setActiveDevice({username:response.data.user},{deviceId:response.data.pcId});
                            $state.go('cc-usage.reportsv2');
                        }

                        return response.data;
                    }
                }

                $log.info('YOUR STATE PARAMS', $stateParams);
                if ($stateParams && $stateParams.deviceid && $stateParams.winuser) {
                    auth.setActiveDevice({
                        deviceId: $stateParams.deviceid
                    }, {
                        username: $stateParams.winuser
                    });
                }

                var DeviceFactory = $injector.get('DeviceFactory');
                DeviceFactory.lastDeviceApiCall = Date.now();
                DeviceFactory.getDevices().then(function success(deviceList) {
                    $log.info('got devices after login', deviceList);

                    //if there are no devices, show special info screen
                    if (deviceList.length < 1) {
                        //OLD: $state.go('login-no-devices');
                        $state.go('devicenotfound');
                        return;
                    }

                    //special case we have only sc devices (type 40)
                    if (deviceList.length>0 && deviceList[0].deviceType==40) {
                        $state.go('sc-dashboard');
                        return response.data;
                    }

                    //special case we have only Salfeld Browser devices (type 50)
                    if (deviceList.length>0 && deviceList[0].deviceType==50) {
                        $state.go('sb-dashboard');
                        return response.data;
                    }

                    if (auth.licenceOk === false) {
                        $state.go('licence');
                    }else {
                        //special case parentstoken
                        if ($stateParams.parentstoken) {
                            $state.go('parents');
                            return;
                        }
                        //tokenlogin, check if we have user and redirect to cb or cc
                        if ($cookies.get('user')) {
                            $log.info('GOTO CC-DASH AFTER LOGIN');
                            $state.go('cc-dashboard-v3');
                        } else {
                            $log.info('GOTO CB-DASH AFTER LOGIN');
                            if ($cookies.get('cb2')=="true") {
                                $state.go('cb-dashboard'); }
                            else {
                                $state.go('cb-dashboard-v3');
                            }
                        }

                    }

                });

                return response.data;
            }

        };

        auth.getVersionInfo = function(){
            $log.info('GET CBVERSION HERE');
            var url = auth.activeContext === 'cb' ? API.url + 'info/cbversion' : API.url + 'info/ccversion';
            var req = {
                method: 'GET',
                url: url
            };

            return $http(req)
                .then(function success(response) {
                    $log.info('GET CBVERSION HERE WITH VERSIONINFO=' ,response);
                    if (response.data && response.data.VersionInfo)
                    {
                        $log.info('GET CBVERSION HERE WITH VERSIONINFO=' + response.data.VersionInfo);
                    }
                    return response.data;
                })
                .catch(function(error) {
                    $log.error('failed to load VersionInfo',error);
                    return error;
                });

        }

        $rootScope.$watch(function () {
            return auth.activeDevice;
        }, function (activeDevice) {
            if (activeDevice && activeDevice.users) {
                if (activeDevice.users.length) {
                    auth.activeContext = 'cc';
                } else {
                    auth.activeContext = 'cb';
                }
                //Overwrite in case we are type 40 (sc)
                if (activeDevice.deviceType==40) {
                    auth.activeContext = 'sc';
                }
                if (activeDevice.deviceType==50) {
                    auth.activeContext = 'sb';
                }
                $log.info('AuthFactory ACTIVE Context:', auth.activeContext);
            }

            //update version info
            auth.getVersionInfo().then(function (version) {
                version = version || {};
                version.VersionInfo = version.VersionInfo || '';

                $log.info("cb-version is:", version);

                var versionForCookie = auth.extractVersion(version.VersionInfo);

                if (typeof versionForCookie==='string') {
                    $cookies.put('version', versionForCookie);
                }
            });

        }, true);

        $rootScope.$watch(function(){
            return $cookies.get("role");
        }, function(newVal){
            $log.info("userRole newVal", newVal);
            auth.userRole = newVal;
        });

        return auth;
    });
