/**
 * Contains helper enums that can be used troughout the application
 */

angular.module('ChicoApp.Shared').factory('chicoEnums', function($q, $translate) {
    return {
        AppBonusTypeEnum: Object.freeze({
            NO_BONUS:                   0,
            ALWAYS_ALLOWED:             3,
            ALWAYS_ALLOWED_NO_TIMER:    2,
            ALWAYS_ALLOWED_WITH_BONUS:  4
        }),
        PrgBonusTypeEnum: Object.freeze({
            NO_BONUS:                   0,
            ALWAYS_ALLOWED:             3,
            ALWAYS_ALLOWED_NO_TIMER:    1,
            ALWAYS_ALLOWED_WITH_BONUS:  2
        }),
        KindEnum: Object.freeze({
            ALLOWED_BASIC:          0,  // Apps/Programs allowed without options (OLD was NOT_CONFIGURED : 0)
            ALLOWED_OPTIONS:        1,  // Apps/Programs allowed with options (OLD was ALLOWED : 1)
            TIMELIMITED:            2,  // Apps/Programs timelimited
            BLOCKED:                3,  // Apps/Programs blocked
            BONUS:                  26, // Bonus programs (new as own kind)

            BLOCKED_KW:             6,  // Internet blocked keyword
            BLOCKED_DOMAIN:         5,  // Internet blocked domain
            ALLOWED_DOMAIN_BASIC :  71, // Internet allowed domain without options
            ALLOWED_DOMAIN_OPTIONS: 7,  // Internet allowed domain with options
            TIMELIMITED_DOMAIN:     8,  // Internet time limited domain

            GROUP_WINAPPS:          9,
            BONUS_INTERNET:         25, // Bonus internet (new as own kind)
            GROUP_INTERNET:         10, // Static Group Internet
            GROUP_SYSTEM:           4,  // Static Group System

            CUSTOM_GROUP_1:         11, // Group 1 (Programs and Internet entries possible)
            CUSTOM_GROUP_2:         12, // Group 2 (Programs and Internet entries possible)
            CUSTOM_GROUP_3:         13, // Group 3 (Programs and Internet entries possible)
            CUSTOM_GROUP_4:         14, // Group 4 (Programs and Internet entries possible)
            CUSTOM_GROUP_5:         15  // Group 5 (Programs and Internet entries possible)
        }),
        // Probably TODO
        ContextEnum: Object.freeze({
            CB: 'cb',
            CC: 'cc'
        }),
        AppTypeEnum: Object.freeze({
            WEBSITE: 101,
            APP: 102,           // Android app
            WINDOWS_APP: 103    // Windows exe and Windows UWP app
        }),

        /**
         * Returns name of the given kind in the current language (e.g. Zugelassen for kind KindEnum.ALLOWED).
         * Because $translate.instant is used, it is not guaranteed that a correct translation is returned.
         * @param {KindEnum} kind Kind
         * @returns {string} Name of the kind in the current language
         */
        kindToText: function(kind) {
            if (kind < 11 || kind > 15) {
                return $translate.instant('MODAL_EDIT_RECORD.KIND_TYPE_NAMES_NEW.' + kind);
            } else {
                return $translate.instant('MODAL_EDIT_RECORD.GROUP_NAME_PREFIX_DROPDOWN') + (kind - 10).toString()
            }
        },
        /**
         * Returns promise for name of the given kind in the current language (e.g. Zugelassen for kind KindEnum.ALLOWED)
         * @param {KindEnum} kind Kind
         * @returns {Promise<string>} Promise which resolves to the name of the kind in the current language
         */
        kindToTextPromise: function(kind) {
            return $q(function(resolve) {
                if (kind < 11 || kind > 15) {
                    $translate('MODAL_EDIT_RECORD.KIND_TYPE_NAMES_NEW.' + kind).then(function(translation) {
                        resolve(translation);
                    });
                } else {
                    $translate('MODAL_EDIT_RECORD.GROUP_NAME_PREFIX_DROPDOWN').then(function(translation) {
                        resolve(translation + (kind - 10).toString());
                    });
                }
            });
        },
        /**
         * Returns a CSS class corresponding to the color of the given kind.
         * If no color was found for the given kind, an empty string is returned.
         * @param {KindEnum} kind 
         * @returns {string} CSS Class
         */
        kindToColor: function(kind) {
            switch (kind) {
                case 0:
                case 1:
                case 7:
                case 71:
                    return 'scg-backgreen';
                case 2:
                case 8:
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                    return 'scg-backyellow';
                case 4:
                case 9:
                    return 'scg-backdefault';
                case 3:
                case 5:
                case 6:
                    return 'scg-backred';
                default:
                    return '';
            }
        },
        /**
         * Returns true if given kind is a group
         * @param {KindEnum} kind Kind to check
         * @returns {boolean} True if kind is a group
         */
        kindIsGroup: function(kind) {
            switch (kind) {
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                case 9:
                case 25:
                case 10:
                case 4:
                    return true;
                default:
                    return false;
            }
        },
        /**
         * Returns true if given kind is a custom group
         * @param {KindEnum} kind Kind to check
         * @returns {boolean} True if kind is a custom group
         */
        kindIsCustomGroup: function(kind) {
            switch (kind) {
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                    return true;
                default:
                    return false;
            }
        }
    }
});
