'use strict';
angular.module('ChicoApp.CB-Browsertime')
    .controller('CB-BrowsertimeController', function(
      $rootScope,
      $scope,
      $state,
      $http,
      moment,
      $timeout,
      $translate,
      modalFactory,
      _,
      $log,
      cbBrowsertimeFactory,
      scgValidationFactory,
      scgSharedFactory,
      scgGlobalSpinnerFactory) {

      var timeTableSaveQueue;

      $scope.data = {};
      $scope.hintHide = true;
      $scope.errors = [];

      //init timetable
      $scope.timeTableOptions = {
        reset: true
      };

      $scope.timetableController = {};
      $scope.scgSharedFactory = scgSharedFactory;

      $scope.$on('$viewContentLoaded', function() {

        scgGlobalSpinnerFactory.blockUI();
        cbBrowsertimeFactory.getBrowserSettings()

          .then(function success(response) {
            $scope.data = response.data;

            //watch for inputs, if they do not end in having content, refresh page
            $timeout(function() {
              var firstInputValue = angular.element(angular.element('input[ng-model=\'displayedTimeValue\']')[0]).val();
              if (!firstInputValue) {
                $state.go($state.current, {}, {reload: true});
              }
            }, 2000);

            //add watchers
            _.each($scope.data, function(v, k) {
              $scope.$watch('data.' + k, function(new_val, old_val) {
                if (new_val != old_val) {
                  var changed_object = {};
                  changed_object[k] = new_val;

                  //save timetable only with timed queue
                  if (k == 'blockTimesBrowser') {
                    clearTimeout(timeTableSaveQueue);
                    timeTableSaveQueue = setTimeout(function() {
                      scgSharedFactory.needsSync = true;
                      cbBrowsertimeFactory.setSetting(changed_object);
                    }, 1500);
                  }else {
                    scgSharedFactory.needsSync = true;
                    cbBrowsertimeFactory.setSetting(changed_object);
                  }

                }
              }, true);
            });

            scgGlobalSpinnerFactory.unblockUI();

            //Empty object returned (no data in tables!)
            if (Object.keys(response.data).length === 0) {
              scgSharedFactory.needsSync = false;
              modalFactory.okModal(3, '<b>' + $translate.instant('ERROR.ERROR').toUpperCase() + ' :-/</b><br><br>' + $translate.instant('ERROR.INITIAL_NO_DATA'));
              $state.go('cb-dashboard');
            }

          });

      });

      $scope.$watch('data',function(n) {
        if (n.isWeekDayLimitActive == true) {
          $log.info('n', n);
          var compare = [n.timeLimitMonday, n.timeLimitTuesday, n.timeLimitWednesday, n.timeLimitThursday, n.timeLimitFriday, n.timeLimitSaturday, n.timeLimitSunday];
          var largestweekday = '02:30';
          _.map(compare,function(value) {
            if (moment.duration(value) > moment.duration(largestweekday)) {
              largestweekday = value;
            }
          });
          moment.duration($scope.data.timeLimitDay) <= moment.duration(largestweekday) ? $scope.data.timeLimitDay = largestweekday : null;
        }
      },true);

      //watch limit internal logic and create error if necessary
      $scope.$watch('[data.timeLimitDay, data.timeLimitWeek, data.timeLimitMonth, data.isTimeLimitActive]',
       function() {

        $log.info('data', $scope.data);

        if ($scope.data.timeLimitDay && $scope.data.timeLimitWeek && $scope.data.timeLimitMonth && //all vars are here
            $scope.data.isTimeLimitActive === true //time limits are active
            ) {

          var valdata = {
            timeLimitDay: $scope.data.timeLimitDay,
            timeLimitWeek: $scope.data.timeLimitWeek,
            timeLimitMonth: $scope.data.timeLimitMonth
          };

          scgValidationFactory.validateTimelimits(valdata, addUniqueError, removeErrorById);

        }else if ($scope.data.isTimeLimitActive === false) {
          removeErrors();
        }

      });

      var addUniqueError = function(err) {
        //if there is no such error yet, push it to array, otherwise ignore
        if (_.where($scope.errors, err).length === 0) {
          $scope.errors.push(err);
        }
      };

      var removeErrorById = function(id) {
        $scope.errors = _.filter($scope.errors, function(error) { return error.id !== id; });
      };

      var removeErrors = function() {
        $scope.errors = [];
      };

      //Hint stuff, Add $scope.hintHide at top of page
      //--------------------------------------------------------------------------------
      scgSharedFactory.getHint(2020).//adjust hintID here, leading 20 means CB!
          then(function success(response) {
            if (response.data.hint2020) {                         //adjust hintID here
              $scope.hintHide = response.data.hint2020;}        //adjust hintID here
            else {
              //not assgined, set to false!
              $scope.hintHide = false;}
          }
      );
      $scope.hintDone = function() {
        $scope.hintHide = true;
        scgSharedFactory.setHint(2020).then(function success() {
              $log.info('HINT DONE SUCCESS');
            }
      );
      };
      //--------------------------------------------------------------------------------

    });
