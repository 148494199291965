'use strict';
angular.module('ChicoApp.Shared').directive('scgRoamingModal', function() {
    return {
        restrict: 'E',
        templateUrl: 'templates/shared/globalModals/globalRoamingModal/templates/modal-roaming-content.html',
        controller: function() {

        }
    };
});
