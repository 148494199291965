'use strict';
angular.module('ChicoApp.Login')
    .controller('DeviceNotFoundController', function(
        $rootScope,
        $scope,
        $log,
        $http,
        $state,
        $cookies,
        $window,
        $location,
        Metronic,
        $cookieStore,
        $translate,
        API,
        localStorageService,
        $stateParams,
        modalFactory,
        licenceFactory,
        scgSharedFactory,
        AuthFactory) {

        Metronic.unblockUI();

        $scope.user = "";

        if ($rootScope.username) {
            $scope.user = $rootScope.username;
        }

        $log.info('DeviceNotFound Controller, rootscope md5=' + $scope.user);
        $scope.isGerman = true;

        $scope.checkUser = function() {
            if ($scope.user == '') {
                if ($cookies.get('sessionId')) {
                    licenceFactory.listEmail().then(function success(response) {
                        $log.info('listemail arrived=', response);
                        $scope.user = response.data.email;
                        $log.info('listemail new user=', $scope.user);
                        $scope.createNotFoundMsg();
                    });
                }
            }
        }

        $scope.createNotFoundMsg = function() {
            $scope.notFoundMsg = "Für den Login " + $scope.user + " wurden keine aktiven Geräte gefunden!";
            if ($cookies.get('language')=='en_US') {
                $scope.isGerman = false;
                $scope.notFoundMsg = "No devices found for your login:" + $rootScope.username + "!";
            }
        }


        $scope.deleteAccount = function() {
            modalFactory.infoModal.open({
                modal_settings: {
                    title: $translate.instant('MSG.SURE'),
                    message: $translate.instant('MSG.DELETECUSTOMER') + ' ' + $translate.instant('MSG.SURE'),
                    butOkLabel: $translate.instant('BUTTON.YES'),
                    butCancelLabel: $translate.instant('BUTTON.NO'),
                    iconNo: 3,
                    refreshStateAfterClose: false
                },
                callbacks: {
                    ok: function() {
                        var req = {
                            method: 'GET',
                            url: API.url + 'login/deleteaccount/' + $scope.user
                        };
                        $http(req)
                            .then(function success(response) {
                                $log.info('URL was:' + req.url);
                                $log.info('response is:' , response);
                                $scope.logout();
                            }, function error(err) {
                                $log.info('error while HTTP GET');
                                modalFactory.okModal(3, $translate.instant('EMAIL.TESTMAIL_ERROR'));        //message: error, try again later
                            });
                    },
                },
                cancel: function() {
                    $log.info('canceled!');
                }
            });
        }

        $scope.logout = function() {
            AuthFactory.logout(true);
        }


        $scope.createNotFoundMsg();
        $scope.checkUser();

    });
