'use strict';
angular.module('ChicoApp.CC-Internet')

.controller('CC-InternetControllerMisc', function(
  $scope,
  $window,
  ccInternetMiscFactory,
  scgGlobalSpinnerFactory,
  modalFactory,
  $modal,
  $log,
  _,
  scgSharedFactory) {

  $scope.data = {};
  $scope.data.webRedirect = false;
  $scope.hasNFSV2 = false;
  $scope.hasNFSV3 = false; // V3 = Redirect logic and disableSSL

  ccInternetMiscFactory.getSettings()
            .then(function success(response) {

              $scope.data = response.data;
              $log.info('MISC DATA ', $scope.data);

              //add watchers
              _.each($scope.data, function(v, k) {
                $scope.$watch('data.' + k, function(new_val, old_val) {
                  if (new_val != old_val) {
                    var changed_object = {};
                    changed_object[k] = new_val;
                    scgSharedFactory.needsSync = true;
                    ccInternetMiscFactory.setSetting(changed_object);
                    $log.info('Changed, DATA', $scope.data);

                  }
                }, true);
              });

            });


      $scope.importWeb = function(kind) {

        //kind=1 blacklist, kind=2 whitelist
      $log.info('IMPORT WEB');

        modalFactory.importModal.open({
          modal_settings: {
            title: 'aaaa',
            message: 'xx',
            butOkLabel: 'xx',
            butCanelLabel: 'xx',
            refreshStateAfterClose: false
          },
          data: {
            kind: kind
          },
          callbacks: {
            ok: function() {
              //$log.info('modal closed with ok -> callback');
              //modalFactory.okModal(1, $translate.instant('MSG.DATASENT'));
            },
            cancel: function() {
              //$log.info('modal closed with cancel -> callback');
            }
          }
        });
      };


    $scope.exportWeb = function(kind) {

        //kind=1 blacklist, kind=2 whitelist
        $log.info('EXPORT WEB kind=' + kind);
        scgGlobalSpinnerFactory.blockUI();
        ccInternetMiscFactory.export(kind).then(function success(response) {
        $log.info('Export kind=' + kind);
        $log.info('export text=', response.data)
        scgGlobalSpinnerFactory.unblockUI();

        var res = String(response.data);
        var list = res.replace(new RegExp(',', 'g'), '\n');
        $scope.exportlist = list;

        var modalInstance = $modal.open({
            animation: false,
            templateUrl: 'templates/childcontrol/cc_internet/templates/internet-modal-export.html',
            windowTemplateUrl: 'templates/childcontrol/cc_internet/templates/internet-modal-window-small.html',
            scope: $scope
        });

        });
    }


    $scope.checkUrl = function() {
    if ($scope.data.webRedirectUrl.indexOf(".")!=-1)
    {
      $scope.data.webRedirectUrl = $scope.data.webRedirectUrl.toLowerCase();
      if ($scope.data.webRedirectUrl.substring(0,4)!='http')      {
       $scope.data.webRedirectUrl = "http://" + $scope.data.webRedirectUrl;     }
    }
    else { //no "." found, clear
      $scope.data.webRedirectUrl = "";
      $scope.data.webRedirect = false;    }
  }


  $scope.testUrl = function() {
    $log.info("TestUrl for URL=" + $scope.data.webRedirectUrl);
    $window.open($scope.data.webRedirectUrl, '_blank');
  }


        scgSharedFactory.getCCVersionNumber().then(function success(version) {
          $log.info('hasnfs CCVersion number is:', version);
          if (version.major >= 16 && version.minor > 2156) {
            $scope.hasNFSV2 = true;
          }
          if (version.major >= 16 && version.minor > 2234) {
            $scope.hasNFSV3 = true;
          }
        });



    });
