'use strict';
angular.module('ChicoApp.Internal')

    .controller('InternalController', function(
        $rootScope,
        $scope,
        $translate,
        $state,
        $http,
        $window,
        $timeout,
        DeviceFactory,
        AuthFactory,
        cbListsFactory,
        DTOptionsBuilder,
        DTColumnDefBuilder,
        scgDataTableFactory,
        modalFactory,
        API,
        apiFunctions,
        licenceFactory,
        scgLicenceFactory,
        $log) {

      $scope.data = {};
      $scope.infoText = '';

      $scope.gotoDashboard = function() {
        if (AuthFactory.activeContext == 'cc') {
          $state.go('cc-dashboard-v3');
        }else {
          $state.go('cb-dashboard');
        }
      };

      //Dierk: Outsource entire stuff below!
      //---------------------------------------------------------------------------------------------
      $scope.licencetest = function(isForced) {
        //first check the licence days (plus means licence entered, minus up to -30 means trial days)

        scgLicenceFactory.getSerialInfo()
                .then(function success(response) {
                  $log.info('LICENCETEST Success', response);
                  if (response.data.serial == '' || response.data.expireDays < 10 || isForced) {
                    $state.go('licence');
                  }
                },
                function error() {
                  $log.info('LICENCETEST ERROR');
                });

        $log.info('licence test!');
        // modalFactory.okModal(1, "TESTS TEST");
      };

      $scope.listserial = function() {
        scgLicenceFactory.listserial()
                .then(function success(response) {
                  $log.info('LISTSERIAL Success', response);
                },
                function error() {
                  $log.info('LISTSERIAL ERROR');
                });
      }

      //eof of all controller functions
      ;
    });
