angular.module('ChicoApp').component('webFilterV2CC', {
    templateUrl: 'templates/childcontrol/cc_webfilter_v2/webFilterV2.component.html',
    controller: WebFilterV2CCController
});

function WebFilterV2CCController(
    $translate,
    $state,
    $location,
    $anchorScroll,
    $timeout,
    $window,
    _,
    moment,
    $log,
    $http,
    $modal,
    API,
    AuthFactory,
    appListsFactory,
    scgGlobalSpinnerFactory,
    cbListsFactory,
    modalFactory,
    ccInternetFactory,
    ccInternetCategoryFactory,
    scgSettingsFactory,
    scgValidationFactory,
    scgSharedFactory,
    appInfoFactory,
    apiFunctions,
    chicoEnums
) {


    var ctrl = this;

    ctrl.chicoEnums = chicoEnums;
    ctrl.data = {};

    ctrl.loadingData = true;
    ctrl.loadingCategories = false;

    // If availableKinds is initialized with [] instead of null, strange things happen at kind-select-dropdown directive:
    // Checkboxes are replaced with radio buttons
    ctrl.availableKinds = null;
    ctrl.selectedKinds = {};

    ctrl.selectModel = 0;
    ctrl.exportlist = [];
    ctrl.lookupurl = "salfeld.de";
    ctrl.showWeblistHint = false;
    ctrl.checkUrlError = false;

    /**
     * @type {Object}
     * @property {number} defaultAppKind
     * @property {boolean} isAppFilterActive
     * @property {boolean} isAppFilterNewBlocked
     */
    ctrl.categories = {};

    ctrl.groups = [];
    ctrl.applist = [];
    ctrl.selectedListRows = [];
    ctrl.groups = null;

    ctrl.currentContext = AuthFactory.activeContext;

    ctrl.headerEntries = [];

    ctrl.appListPage = 1;

    ctrl.keywords = [];

    ctrl.scgSharedFactory = scgSharedFactory;

    ctrl.appKind = chicoEnums.AppTypeEnum.WEBSITE;

    ctrl.$onInit = function () {
        $translate(['HEADER.ENTRY', 'HEADER.LAST_CHANGED']).then(function (translations) {
            ctrl.headerEntries = [
                {
                    name: translations['HEADER.ENTRY'],
                    key: 'entry',
                    mainColumn: true,
                    allowSorting: true,
                    sortAscFn: function(a, b) {
                        return a.entry.localeCompare(b.entry);
                    },
                    sortDescFn: function(a, b) {
                        return a.entry.localeCompare(b.entry) * -1;
                    }
                },
                {
                    name: translations['HEADER.LAST_CHANGED'],
                    key: 'installDateFormatted',
                    hideXs: true,
                    allowSorting: true,
                    sortAscFn: function (a, b) {
                        return moment(a.installDate).isAfter(moment(b.installDate)) ? 1 : -1;
                    },
                    sortDescFn: function (a, b) {
                        return moment(a.installDate).isBefore(moment(b.installDate)) ? 1 : -1;
                    }
                }
            ];
        });

        $translate.onReady().then(function() {
            // Prepare kinds for filter
            ctrl.prepareKinds();
        });

        ctrl.loadList();

        ctrl.appListPage = $state.params.appListPage;
    };

    ctrl.turnOn = function()
    {
        ctrl.data.isWebFilterActive=true;
        ctrl.onModelChange();
    }

    ctrl.loadList = function () {
        ctrl.loadingData = true;
        appListsFactory.getWebListCC().then(function success(response) {

            ctrl.applist = response.data;
            $log.info('loadList done ',response.data);

            $translate.onReady(function() {
                for (var i = 0; i < ctrl.applist.length; i++) {
                    ctrl.applist[i].iconHref                = ctrl.getIcon(ctrl.applist[i]);
                    ctrl.applist[i].installDateFormatted    = scgSharedFactory.formatDate(ctrl.applist[i].installDate);
                }
            });

            // Clear selectedListRows because it is not cleared automatically when data changes
            ctrl.selectedListRows = [];
            ctrl.loadingData = false;
        });
    };

    ctrl.onAppListRowClick = function (row) {
        $state.go('detail-view', {appIds: [row.entry], appType: chicoEnums.AppTypeEnum.WEBSITE, allowAdd: false,prevStateName: $state.current.name});
   };

    ctrl.onPageChange = function (newPage) {
        ctrl.appListPage = newPage;
        $state.params.appListPage = newPage;
    }

    ctrl.onModelChange = function() {
        $log.info('onModelChange=', ctrl.data);
        scgSharedFactory.needsSync = true;
        ccInternetFactory.setSetting(ctrl.data);
    };

    ctrl.$postLink = function() {
        // Attach event to bootstrap switch to detect when switch is toggled (not possible in html template when using AngularJS 1.5; must be done here)
        angular.element('#webccswitch').on('switchChange.bootstrapSwitch', ctrl.onModelChange);

        var hash = $location.hash();

        // Clear URL fragment to prevent scrolling again on page reload etc.
        delete $state.params['#'];
        $location.hash('');

        // Scroll to position given in the URL fragment
        if (hash !== '') {
            $timeout(function() {
                $log.info('Scrolling to ' + hash);
                $anchorScroll(hash);
            }, 1000);
        }
    };

    ctrl.searchFilterFunc = function(listEntries, searchInput) {
        return _.filter(listEntries, function (object) {
            var concatenated = object.entry;
            return concatenated.toLowerCase().includes(searchInput.toLowerCase());
        });
    };

    ctrl.filterFunc = function(listEntries, filterParams) {
        // Only filter if there are any selectedKinds to check
        // Workaround because kind-select-dropdown is not fast enough in intialization...
        if (filterParams.selectedKinds && Object.keys(filterParams.selectedKinds).length > 0) {
            var filtered = _.filter(listEntries, function (object) {
                if (!filterParams.selectedKinds[object.kind] || (filterParams.isNewWebsitesBlocked && object.kind === chicoEnums.KindEnum.BLOCKED_DOMAIN)) {
                    return false; // What to do if we have no filter information for that kind, true=show, false=hide
                }
                return filterParams.selectedKinds[object.kind] == true;
            });
            return filtered;
        } else {
            return listEntries;
        }
    };


    ctrl.getIcon = function (app) {
        $log.info("getIcon for domain=" + app.entry);
        return appInfoFactory.getWebIconUrl(app.entry);
    };

    /**
     * Add available kinds
     */
    ctrl.prepareKinds = function () {
        var availableKindsPrep = [{
            kind: chicoEnums.KindEnum.ALLOWED_DOMAIN_BASIC,
            name: chicoEnums.kindToText(chicoEnums.KindEnum.ALLOWED_DOMAIN_BASIC),
            selected: true,
            editable: false,
            addCircleClass: 'scg-multiselect-badge-green',
            circleContent: '<i class="fa fa-binoculars"></i>'
        }, {
            kind: chicoEnums.KindEnum.ALLOWED_DOMAIN_OPTIONS,
            name: chicoEnums.kindToText(chicoEnums.KindEnum.ALLOWED_DOMAIN_OPTIONS),
            selected: true,
            editable: false,
            addCircleClass: 'scg-multiselect-badge-green',
            circleContent: '<i class="fa fa-binoculars"></i>'
        }, {
            kind: chicoEnums.KindEnum.TIMELIMITED_DOMAIN,
            name: chicoEnums.kindToText(chicoEnums.KindEnum.TIMELIMITED_DOMAIN),
            selected: true,
            editable: false,
            addCircleClass: 'scg-multiselect-badge-red',
            circleContent: '<i class="fa fa-binoculars"></i>'
        }, {
            kind: chicoEnums.KindEnum.BLOCKED_DOMAIN,
            name: chicoEnums.kindToText(chicoEnums.KindEnum.BLOCKED_DOMAIN),
            selected: true,
            editable: false,
            addCircleClass: 'scg-multiselect-badge-yellow',
            circleContent: '<i class="fa fa-binoculars"></i>'
        }
    ];

        ctrl.availableKinds = availableKindsPrep;
    };

    ctrl.renameKindDropDownItem = function (kind, newName) {
        var itemToChange = _.filter(ctrl.availableKinds, {kind: kind});
        itemToChange.name = newName;
        scgSettingsFactory.changeGroupName({groupkind: kind, groupName: newName}).then(function (res) {
            //$state.go($state.current, {}, {reload: true});
        });
    };

    ctrl.testAppInfo = function (entry) {
        $log.info("testApp entry=" + entry);
        appInfoFactory.getAppDetails('explorer.exe').then(
            function success(response) {
                $log.info("testApp response=", response);
            }
        )
    }

    ctrl.getKeywords = function () {
        $log.info("getKeywords");
        appListsFactory.getWebKeywordsCC().then(
            function success(response) {
                $log.info("getKeywords response=", response);
                ctrl.keywords = response.data;
            }
        )
    }


    ctrl.onTagAdding = function (tag) {

        if (tag.text.indexOf('.')!=-1) {
            ctrl.showKeywordMsg = true;
            return false;
        }
        if (scgValidationFactory.validateTag(tag.text)===false){
            $log.info('validateTag FALSE');
            ctrl.showKeywordMsg = true;
            return false;
        }

        ctrl.showKeywordMsg = false;
        $log.info('onTagAdding, tag=', tag);
        var tagArr = [];          //backend needs tag as array
        tagArr.push(tag.text);
        appListsFactory.saveWebKeywordsCC(tagArr).then(
            function success(response) {
                return true;

            },
            function error() {
                return false;
            }
        )
    }



    ctrl.onTagRemoved = function (tag) {
        $log.info('onTagRemoved', tag);
        var tagArr = [];          //backend needs tag as array
        tagArr.push(tag.text);
        appListsFactory.deleteWebKeywordsCC(tagArr).then(
            function success(response) {
                return true;
            },
            function error() {
                return false;
            }
        )
    }


    // CATEGORIY STUFF
    // ------------------------------------------

    ctrl.getCategories = function () {
        ctrl.loadingCategories = true;
        ccInternetCategoryFactory.getCategories()
            .then(function success(response) {
                var obj_arr = [];
                $log.info('Categories arrived:', response);
                _.each(response.data, function (val, key) {
                    var o = {};
                    o.key = key;
                    o.val = val;
                    obj_arr.push(o);
                });

                var obj_arr_sorted = _.sortBy(obj_arr, function (o) {
                    return o.key;
                });
                ctrl.categories = obj_arr_sorted;
                ctrl.loadingCategories = false;
            });
    }

    ctrl.setCategoryField = function (category) {
        category.val = !category.val;
        scgSharedFactory.needsSync = true;
        var obj = {};
        obj[category.key] = category.val;
        ccInternetFactory.setSetting(obj);
    };


    ctrl.getSettings = function () {
        $log.info("getSettings");
        ctrl.loadingData = true;

        ccInternetFactory.getSettings()
            .then(function success(response) {
                ctrl.data = response.data;
                //$scope.data = response.data;
                $log.info('loadAge data arrived=', ctrl.data);
                ctrl.loadingData = false;
            });
    }


    ctrl.setAge = function (age) {
        $log.info('set age=' + age);
        ctrl.data.webFilterAge = age;
        ctrl.onModelChange();
    }

    ctrl.setNotifyOpt = function(value)
    {
        ctrl.data.webFilterAgeNotifyOpt = value;
        ctrl.onModelChange();
    }


    ctrl.importExport = function () {
        $state.go('web-import-export', {selectModel: ctrl.selectModel});
    }


    // BUTTON HANDLING
    // -----------------------------------------------------------------------------

    ctrl.onBtnAdd = function() {
        $log.info("onBtnAdd");
        $state.go('detail-view', {appIds: [], appType: chicoEnums.AppTypeEnum.WEBSITE, allowAdd: true,prevStateName: $state.current.name });
    };

    ctrl.onBtnEdit = function() {
        $state.go('detail-view', {appIds: ctrl.toArray(ctrl.selectedListRows), appType: chicoEnums.AppTypeEnum.WEBSITE, allowAdd: false,prevStateName: $state.current.name });
    }

    ctrl.onBtnDelete = function() {
         appListsFactory.deleteWebCC(ctrl.toArray(ctrl.selectedListRows)).then(function success(response){
             $state.go($state.current, {}, {reload: true}); });
    }

    ctrl.toArray = function(selectedListRows)   {
        var arr = [];
        _.each(selectedListRows, function (item) {
            $log.info('makeArray item=', item);
            arr.push(item.entry);
        });
        return arr;
    }

    ctrl.onBtnLookup = function() {
        $log.info('onBtnLookup url=' + ctrl.lookupurl);
        $state.go('detail-view', {appIds: [ctrl.lookupurl], appType: chicoEnums.AppTypeEnum.WEBSITE, allowAdd: false,prevStateName: $state.current.name});
    }

    ctrl.getWeblistHint = function() {
        $log.info('hint init');
        scgSharedFactory.getHint(72).
        then(function success(response) {
            $log.info('hint response=', response);
            if (response.data.hint72) {
                $log.info('found true value!');
                ctrl.showWeblistHint = response.data.hint72;
                //hint flag found, we must set showHint to false
                if (ctrl.showWeblistHint===true) ctrl.showWeblistHint=false; }
            else {
                //hint flag not found, so set it to true
                ctrl.showWeblistHint = true;}}
        );
    }

    ctrl.setWeblistHint = function() {
        scgSharedFactory.setHint(72).then(function success() {
            $log.info('HINT DONE SUCCESS');
            $state.go($state.current, {}, {reload: true});}
        );
    }

    ctrl.testUrl = function() {
        $log.info("TestUrl for URL=" + ctrl.data.webRedirectUrl);
        $window.open(ctrl.data.webRedirectUrl, '_blank');
    }

    ctrl.checkUrl = function() {
            ctrl.data.webRedirectUrl = ctrl.data.webRedirectUrl.toLowerCase();
            if (ctrl.data.webRedirectUrl.substring(0,7)!='http://' && ctrl.data.webRedirectUrl.substring(0,8)!='https://')   {
                ctrl.checkUrlError = true;
                } else {
                ctrl.checkUrlError = false;
                //update with a 500 ms delay
                setTimeout(function(){
                    ctrl.onModelChange();
                }, 500);
                }
    }




    // Call the initial funcs
    ctrl.getSettings();
    ctrl.getKeywords();
    ctrl.getCategories();
    ctrl.getWeblistHint();



// TESTSTUFF BELOW
// ------------------------------------------
    ctrl.addTest = function () {
        $log.info("addTest");
        $state.go('detail-view', {appIds: [], appType: chicoEnums.AppTypeEnum.WEBSITE, allowAdd: false,prevStateName: $state.current.name});
    }


    ctrl.singleTest = function () {

        $log.info('singleTest');
        $state.go('detail-view', {appIds: ['Chrome'], appType: chicoEnums.AppTypeEnum.APP, allowAdd: false,prevStateName: $state.current.name});
    }


}
