'use strict';
angular.module('ChicoApp').config(function($stateProvider) {
    $stateProvider.state('cc-webfilter-v2', {
        url: '/cc-webfilter-v2',
        views: {
            "@": {
                component: 'webFilterV2CC'
            }
        },
        params: {
            // Current page in webList
            appListPage: 1
        }
    });
});