/*'use strict';
angular.module('ChicoApp.CC-Usage', [])

    .config(function($stateProvider, $translateProvider) {
      $stateProvider.state('cc-usage', {
        url: '/cc-reports',
        templateUrl: 'templates/childcontrol/cc_usage/templates/reports.html',
        controller: 'CC-Usage-Reports'
      });
    })

    .run(function() {});*/

'use strict';
angular.module('ChicoApp.CC-Usage', [])
    .config(function($stateProvider) {
      $stateProvider.state('cc-usage', {
        url: '/cc-reports',
        template: '<ui-view/>',
        redirectTo: 'cc-usage.reportsv2',
        controller: null
      })

            .state('cc-usage.reports', {
              url: '/cc-reports',
              templateUrl: 'templates/childcontrol/cc_usage/templates/reports.html',
              controller: 'CC-Usage-Reports'
            })

          .state('cc-usage.reportsv2', {
              url: '/cc-reports-v2',
              templateUrl: 'templates/childcontrol/cc_usage/templates/reportsv2.html',
              controller: 'CC-Usage-Reports',
              params: {
                  periodKind: 1,
              }
          })

            .state('cc-usage.unblock', {
              url: '/cc-unblock',
              templateUrl: 'templates/childcontrol/cc_usage/templates/unblock.html',
              controller: 'CC-Usage-Unblock'
            })

          .state('cc-usage.unblock-v2', {
              url: '/cc-unblockv2',
              templateUrl: 'templates/childcontrol/cc_usage/templates/unblockv2.html',
              controller: 'CC-Usage-Unblock-V2'
          });

    })
    .run(function() {});

