angular.module('ChicoApp').factory('infoFactory', function(
    $http,
    $translate,
    $log,
    API) {

    var factory = {}; //Dont forget to init, this is also final return value!

    factory.getEntries = function(lang) {
        $log.info('translate is:',lang);
        var req = {
            method: 'GET',
            url: API.url + 'service/logbook/entries/' + lang
        };
        return $http(req);
    };

    return factory;

});
