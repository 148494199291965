'use strict';
angular.module('ChicoApp.Info')

    .controller('LogbookController', function(
        $rootScope,
        $scope,
        $translate,
        $state,
        $http,
        $window,
        $cookies,
        $timeout,
        DeviceFactory,
        AuthFactory,
        modalFactory,
        $log,
        API,
        apiFunctions,
        infoFactory) {


        $scope.logEntries = {};
        $scope.noLogbook = true;
        $scope.currentContext = AuthFactory.activeContext; //will be cc or cb as String

        $scope.langId = 'de'; //default
        if ($translate.use() == 'en_US') $scope.langId='en';
        $log.info('langID = ', $scope.langId);


        $scope.gotoDash = function() {
            $log.info('current Context=',$scope.currentContext);
            if ($scope.currentContext=='cb') {
                $state.go('cb-dashboard'); }
            else {
                $state.go('cc-dashboard-v3');
            }
        }

        infoFactory.getEntries($scope.langId)
            .then(function success(response) {
                $scope.logEntries = response.data;
                $log.info('We have log entries:', response.data);
            },
            function error() {
            });
    });
