'use strict';
angular.module('ChicoApp.CC-Wizard')

    .controller('CC-WizardController', function(
        $rootScope,
        $scope,
        $state,
        $http,
        $timeout,
        $translate,
        $log,
        moment,
        _,
        modalFactory,
        ccWizardFactory,
        ccInternetCategoryFactory,
        scgGlobalSpinnerFactory,
        ccDashboardFactory,
        scgSharedFactory) {


        $scope.data = {};

        //Just vor debugging

        $scope.busyNextButton = false;
        $scope.busyBackButton = false;

        //Main wizard checkbox switches
        $scope.timelimitActive = true;
        $scope.blocktimesActive = false;
        $scope.webfilterActive = true;


        $scope.data.pcActive = true;
        $scope.data.inetActive = true;
        $scope.data.forcedBreakActive = true;
        $scope.data.pcWD = "01:30";
        $scope.data.pcWE = "02:30";
        $scope.data.inetWD = "01:00";
        $scope.data.inetWE = "02:00";
        $scope.data.forcedAfter = "01:00";
        $scope.data.forcedDuration = "00:15";

        $scope.data.webAllowedOnly = false;

        $scope.data.prgFilterAge = 0;
        $scope.data.webFilterAge=0;

        $scope.data.emailReportRecipient = "foo@salfeld.com";
        $scope.data.emailReportSubject = "Kindersicherung Report";

        $scope.data.isEmailReportDay = true;
        $scope.data.isEmailReportWeek = true;
        $scope.data.isEmailReportUnlockMsg = true;
        //eof debug

        $scope.weballowed = ['all1.com','all2.com','all3.com'];
        $scope.webblocked = ['block1.com','block2.com','block3.com'];


        $scope.isWizDebug = true;       //TODO: remove later

        // setup watcher for data object

        /*
        $scope.$watch(function(){
            return $scope.data;
        },function(newData){
            ccWizardFactory.update(newData);
        },true);
    */

        /*
        ccWizardFactory.getUserSettings()
            .then(function success(response) {
            $log.info('RESPONSE ARRIVED' , response);
                //mappings
                //$scope.data.pcActive = response.data.isWeekdayLimitActive;
            });
            */


        $scope.saveLists = function() {
            ccWizardFactory.setLists(8,$scope.weballowed);
            ccWizardFactory.setLists(5,$scope.webblocked);
        }


        $scope.addNewBlockedEntry =function() {

            if($scope.newBlockedEntry){
                $scope.newBlockedEntry = $scope.newBlockedEntry.replace('http://','');
                $scope.newBlockedEntry = $scope.newBlockedEntry.replace('https://','');
                $scope.newBlockedEntry = $scope.newBlockedEntry.replace('www.','');
                $scope.newBlockedEntry =  scgSharedFactory.getTLD($scope.newBlockedEntry);

                $scope.webblocked.push($scope.newBlockedEntry);
                $scope.webblocked.sort();
                $scope.newBlockedEntry = "";
            }

        }

        $scope.goNext = function() {
            $log.info('goNext triggered');

            if ($state.is('cc-wizard.1')==true) {
                $scope.busyNextButton= true;
                $timeout(function(){
                    $scope.busyNextButton= false;
                    $state.go('cc-wizard.2');
                },1000);


            }

            if ($state.is('cc-wizard.2')==true) {
                $state.go('cc-wizard.3');
            }

            if ($state.is('cc-wizard.3')==true) {
                $scope.saveLists();
                $state.go('cc-wizard.4');
            }

            if ($state.is('cc-wizard.4')==true) {
                $state.go('cc-wizard.5');
            }
        }

        $scope.goBack = function() {
            $log.info('goBack triggered');

            if ($state.is('cc-wizard.2')==true) {
                $state.go('cc-wizard.1');
            }
            if ($state.is('cc-wizard.3')==true) {
                $state.go('cc-wizard.2');
            }
            if ($state.is('cc-wizard.4')==true) {
                $state.go('cc-wizard.3');
            }
            if ($state.is('cc-wizard.5')==true) {
                $state.go('cc-wizard.4');
            }
        }

/*
        ccInternetCategoryFactory.getCategories()
            .then(function success(response) {
                var obj_arr = [];
                _.each(response.data, function(val, key) {
                    var o = {};
                    o.key = key;
                    o.val = val;
                    obj_arr.push(o);
                });
                var obj_arr_sorted = _.sortBy(obj_arr, function(o) {
                    return o.key;
                });
                $scope.data = obj_arr_sorted;
                $scope.contentLoading = false;

                _.each($scope.data, function(obj, index) {
                    $scope.$watch('data[' + index + ']', function(new_val, old_val) {
                        if (new_val != old_val) {
                            var changed_object = {};
                            changed_object[obj.key] = new_val.val;
                            scgSharedFactory.needsSync = true;
                            ccInternetCategoryFactory.setSetting(changed_object);
                        }
                    }, true);
                });
            });
*/

});
