/**
 * @typedef {Object} KindDropdownItem
 * @property {chicoEnums.KindEnum} value
 * @property {string} name
 * @property {string} kindColorClass
 * @property {?boolean} isDisabled
 * @property {?boolean} editGroupNameOngoing
 */

angular.module('ChicoApp.Shared').component('kindDropdown', {
    templateUrl: 'templates/shared/kindDropdown/kindDropdown.component.html',
    controller: KindDropdownController,
    bindings: {
        /**
         * Type of app for which the kindDropdown is created.
         * Type of app should fit to the current context. If type and context do not fit (e.g. app with cc context),
         * the wrong groups are loaded.
         * @type {chicoEnums.AppTypeEnum}
         */
        appType: '<',
        /**
         * @type {chicoEnums.KindEnum}
         */
        value: '<',
        /**
         * @type {function} Invoked if value was changed
         * @param {chicoEnums.KindEnum} value New kind which was selected
         */
        valueChange: '&?'
    }
});

function KindDropdownController(
    scgSettingsFactory,
    scgSharedFactory,
    AuthFactory,
    chicoEnums,
    $translate,
    $log,
    _
) {
    var ctrl = this;

    /**
     * @type {boolean} Wait for groupnames to load
     */
    ctrl.loading = true;

    /**
     * @type {KindDropdownItem[]}
     */
    ctrl.items = [];
    /**
     * @type {KindDropdownItem}
     */
    ctrl.selectedKind = {};

    // selectedKind for mobileDropdown (in general, this needs to be equal to selectedKind)
    ctrl.mobileDropdownModel = {};

    // Used to control whether the mobile dropdown should be shown (=native select element)
    ctrl.useMobileDropdown = false;

    ctrl.$onInit = function() {
        // Check if mobile dropdown is required
        var mediaQueryResult = window.matchMedia('(min-width: 415px) and (orientation: portrait), (min-height: 415px) and (orientation: landscape)').matches
        var hasTouchscreen = 'ontouchstart' in window;

        // Set to false to completely disable the mobile dropdown
        ctrl.useMobileDropdown = (!mediaQueryResult && hasTouchscreen);

        ctrl.addDropdownItems();

        // Update selectedKind after all items were loaded
        // If groups are loaded, the correct item may not be selected until all groups were loaded
        ctrl.updateSelectedKind();
    };

    ctrl.$onChanges = function(changes) {
        if (changes.value && typeof ctrl.value !== 'undefined') {
            ctrl.updateSelectedKind();
        }
    };

    ctrl.onSelectionChange = function(item) {
        ctrl.value = item.value;
        ctrl.selectedKind = item;

        if (typeof ctrl.valueChange === 'function') {
            ctrl.valueChange({ value: item.value });
        }
    };

    ctrl.onMobileDropdownChange = function() {
        ctrl.onSelectionChange(ctrl.mobileDropdownModel);
    }

    ctrl.addDropdownItems = function() {
        ctrl.items = [];

        // Kinds for app and program
        if (ctrl.appType === chicoEnums.AppTypeEnum.APP || ctrl.appType === chicoEnums.AppTypeEnum.WINDOWS_APP) {
            ctrl.items.push({
                value: chicoEnums.KindEnum.ALLOWED_BASIC,
                name: '',
                kindColorClass: chicoEnums.kindToColor(chicoEnums.KindEnum.ALLOWED_BASIC)
            });
            ctrl.items.push({
                value: chicoEnums.KindEnum.ALLOWED_OPTIONS,
                name: '',
                kindColorClass: chicoEnums.kindToColor(chicoEnums.KindEnum.ALLOWED_OPTIONS)
            });
            ctrl.items.push({
                value: chicoEnums.KindEnum.BLOCKED,
                name: '',
                kindColorClass: chicoEnums.kindToColor(chicoEnums.KindEnum.BLOCKED)
            });
            ctrl.items.push({
                value: chicoEnums.KindEnum.TIMELIMITED,
                name: '',
                kindColorClass: chicoEnums.kindToColor(chicoEnums.KindEnum.TIMELIMITED)
            });

            // Add Windows specific groups
            if (ctrl.appType === chicoEnums.AppTypeEnum.WINDOWS_APP) {
                // Divider
                ctrl.items.push({
                    value: 1098,
                    name: '',
                    kindColorClass: '',
                    isDisabled: true
                });

                ctrl.items.push({
                    value: chicoEnums.KindEnum.GROUP_INTERNET,
                    name: '',
                    kindColorClass: chicoEnums.kindToColor(chicoEnums.KindEnum.GROUP_INTERNET)
                });
                ctrl.items.push({
                    value: chicoEnums.KindEnum.GROUP_WINAPPS,
                    name: '',
                    kindColorClass: chicoEnums.kindToColor(chicoEnums.KindEnum.GROUP_WINAPPS)
                });
                ctrl.items.push({
                    value: chicoEnums.KindEnum.GROUP_SYSTEM,
                    name: '',
                    kindColorClass: chicoEnums.kindToColor(chicoEnums.KindEnum.GROUP_SYSTEM)
                });
            }
        } else if (ctrl.appType === chicoEnums.AppTypeEnum.WEBSITE) {
            // Kinds for websites
            ctrl.items.push({
                value: chicoEnums.KindEnum.ALLOWED_DOMAIN_BASIC,
                name: '',
                kindColorClass: chicoEnums.kindToColor(chicoEnums.KindEnum.ALLOWED_DOMAIN_BASIC)
            });
            if (AuthFactory.activeContext === chicoEnums.ContextEnum.CC
                || (AuthFactory.activeContext === chicoEnums.ContextEnum.CB && scgSharedFactory.hasCBFeature('alwaysAllowedWeb')===true)
            ) {
                ctrl.items.push({
                    value: chicoEnums.KindEnum.ALLOWED_DOMAIN_OPTIONS,
                    name: '',
                    kindColorClass: chicoEnums.kindToColor(chicoEnums.KindEnum.ALLOWED_DOMAIN_OPTIONS)
                });
            }
            ctrl.items.push({
                value: chicoEnums.KindEnum.BLOCKED_DOMAIN,
                name: '',
                kindColorClass: chicoEnums.kindToColor(chicoEnums.KindEnum.BLOCKED_DOMAIN)
            });
            ctrl.items.push({
                value: chicoEnums.KindEnum.TIMELIMITED_DOMAIN,
                name: '',
                kindColorClass: chicoEnums.kindToColor(chicoEnums.KindEnum.TIMELIMITED_DOMAIN)
            });
        }

        // New: Load groups for cb weblist (if hasFeature)
        if (ctrl.appType === chicoEnums.AppTypeEnum.APP
            || ctrl.appType === chicoEnums.AppTypeEnum.WINDOWS_APP
            || (AuthFactory.activeContext === chicoEnums.ContextEnum.CB && ctrl.appType === chicoEnums.AppTypeEnum.WEBSITE && scgSharedFactory.hasCBFeature('hasWebGroups')===true)
            || (AuthFactory.activeContext === chicoEnums.ContextEnum.CC && ctrl.appType === chicoEnums.AppTypeEnum.WEBSITE && scgSharedFactory.hasCCFeature('hasWebGroups')===true)
        ) {

            // Add groups for programs and apps to dropdown
            // App for programs and apps are distinguished using current context
            // This means, loading groups for apps while in context cc is not possible
            scgSettingsFactory.getGroups()
                .then(function (response) {
                    // First a Divider
                    ctrl.items.push({
                        value: 1099,
                        name: '',
                        kindColorClass: '',
                        isDisabled: true
                    });
                    _.each(response.data, function (group) {
                        ctrl.items.push({
                            value: group.kind,
                            name: group.fullname,
                            // Use any group to get the correct color
                            kindColorClass: chicoEnums.kindToColor(chicoEnums.KindEnum.CUSTOM_GROUP_1)
                        });
                    });
                    ctrl.loading = false;
                    // Update selectedKind after all group items were loaded
                    ctrl.updateSelectedKind();
                });
        }

        ctrl.translateKinds();
    };

    ctrl.translateKinds = function() {
        $translate.onReady(function() {
            for (var i = 0; i < ctrl.items.length; i++) {
                // Don't translate custom group names because they can be modified by the user
                if (!chicoEnums.kindIsCustomGroup(ctrl.items[i].value)) {
                    ctrl.items[i].name = chicoEnums.kindToText(ctrl.items[i].value);
                }
            }
        });

        /*for (var i = 0; i < ctrl.items.length; i++) {
            var index = i;
            // Don't translate custom group names because they can be modified by the user
            if (!chicoEnums.kindIsCustomGroup(ctrl.items[i].value)) {
                chicoEnums.kindToTextPromise(ctrl.items[i].value).then(function(resolve) {
                    ctrl.items[i].name = resolve;
                });
            }
        }*/
    };

    /**
     * Update selectedKind after value was changed.
     * Only works correctly after all dropdown items were loaded.
     */
    ctrl.updateSelectedKind = function() {
        if (ctrl.items.length > 0 && typeof ctrl.value !== 'undefined') {
            var match = ctrl.items.filter(function(item) {
                return item.value === ctrl.value;
            })[0];

            if (match) {
                ctrl.selectedKind = match
                ctrl.mobileDropdownModel = match;
            } else {
                $log.error('kindDropdown does not contain currenty selected kind ' + ctrl.value + '.');
            }
        }
    };

    /* Group editing functions */

    ctrl.editGroupName = function (item, $event) {
        $event.preventDefault();
        $event.stopPropagation();
        item.editGroupNameOngoing = true;
    };

    ctrl.saveGroupName = function (item, $event) {
        $event.preventDefault();
        $event.stopPropagation();

        scgSettingsFactory.changeGroupName({groupkind: item.value, groupName: item.name}).then(function(){
            // If the changed group is currently selected in dropdown, also change its name there
            if(ctrl.value === item.value){
                ctrl.selectedKind.name = item.name;
            }
        });

        item.editGroupNameOngoing = false;
    };

    /**
     * Remove dividers from dropdown items (required for mobile dropdown).
     * 
     * We assume that dividers have a value >= 1000
     * @param {KindDropdownItem} value
     */
    ctrl.filterDividers = function(value, index, array) {
        return value.value < 1000;
    }
}
