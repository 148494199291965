'use strict';
angular.module('ChicoApp.CB-Appfilter', [])
    .config(function($stateProvider) {

      $stateProvider
      .state('cb-appfilter', {
        url: '/cb-appfilter',
        controller: 'CB-AppfilterController', //Name inside controller/xxx-controller.js
        redirectTo: 'cb-appfilter.allowed',
        templateUrl: 'templates/chicobrowser/cb_appfilter/templates/appfilter.html',
        data: {
            //pageTitle: 'CB_HEADLINE.APPFILTER',
            //subTitle: 'CB_SUBLINE.APPFILTER'
        }
      })

        .state('cb-appfilter.allowed', {
          url: '/cb-appfilter-allowed',
          controller: 'CB-Appfilter-Allowed-Controller',
          templateUrl: 'templates/chicobrowser/cb_appfilter/templates/appfilter-generic-list.html',
          data: {
             // pageTitle: 'CB_HEADLINE.APPFILTER',
             // subTitle: 'Here all is allowed'
          }
        })

        .state('cb-appfilter.blocked', {
          url: '/cb-appfilter-blocked',
          controller: 'CB-Appfilter-Blocked-Controller',
          templateUrl: 'templates/chicobrowser/cb_appfilter/templates/appfilter-generic-list.html',
        })

            .state('cb-appfilter.timelimit', {
              url: '/cb-appfilter-timelimit',
              controller: 'CB-Appfilter-Timelimit-Controller',
              templateUrl: 'templates/chicobrowser/cb_appfilter/templates/appfilter-generic-list.html',
            })

            .state('cb-appfilter.group', {
              url: '/cb-appfilter-group',
              controller: 'CB-Appfilter-Group-Controller',
              templateUrl: 'templates/chicobrowser/cb_appfilter/templates/appfilter-group.html',
            })

            .state('cb-appfilter-byage', {
              url: '/cb-app-byage',
              templateUrl: 'templates/chicobrowser/cb_appfilter/templates/appfilter-byage.html',
              controller: 'CB-AppfilterController'
            })

        ;

    })
    .run(function() {});
