'use strict';
angular.module('ChicoApp.SB-Settings')

    .controller('SB-SettingsController', function ($rootScope,
                                                    $scope,
                                                    $translate,
                                                    $state,
                                                    $modal,
                                                    $http,
                                                    $window,
                                                    moment,
                                                    API,
                                                    AuthFactory,
                                                    scgSharedFactory,
                                                    sbSettingsFactory,
                                                    $log,
                                                    modalFactory,
                                                    DeviceFactory,
                                                    Metronic,
                                                    _
                                                    ) {



    $scope.data = {};
    $scope.licenceData = {};
    $scope.cbversion = {};
    $scope.imgBack = '../images/sb-logo.png';
    $scope.remainingDate = "";
    $scope.serialnumber = "";
    $scope.isTrial = true;
    $scope.deviceLoading = true;

      $scope.changePW = function() {
        $state.go('sb-pwreset');
      };

    $scope.deleteData = function() {
              modalFactory.infoModal.open({
                modal_settings: {
                  title: $translate.instant('MSG.SURE'),
                  message: $translate.instant('MSG.DELETEDATA') + '<br>' + $translate.instant('MSG.SURE'),
                  butOkLabel: $translate.instant('BUTTON.YES'),
                  butCancelLabel: $translate.instant('BUTTON.NO'),
                  iconNo: 3,
                  refreshStateAfterClose: false
                },
                callbacks: {
                  ok: function() {
                    $log.info('modal closed with ok -> callback');
                    Metronic.blockUI({animate: true});
                    sbSettingsFactory.deleteDevice()
                        .then(function success() {
                                        $log.info('Done with HTTP 200!');
                                        Metronic.unblockUI();
                                        AuthFactory.logout(true);
                                      });
                  },
                },
                cancel: function() {
                  $log.info('canceled!');
                }
              });
            };


      //Gather general info details (async!)
      AuthFactory.getVersionInfo().then(function(version) {
        $scope.cbversion = version;
      });


    scgSharedFactory.getLicenceDetails()
         .then(function success(response) {
            $scope.licenceData = response.data;
            $scope.remainingDate = scgSharedFactory.getLocalizedDateString($scope.licenceData.expireDate);
            $scope.isTrial = $scope.licenceData.isTrial;
            if ($scope.isTrial===false) {
             $scope.serialnumber=$scope.licenceData.serial;
             } else {
              $scope.serialnumber= $translate.instant('MISC.TRIALVERSION');
             }
             $scope.deviceLoading=false;
            });


    }); //eof controller