angular.module('ChicoApp').factory('cbScreenShotFactory', function(
    $http,
    $cookies,
    API,
    apiFunctions,
    scgSharedFactory) {

    var factory = {}; //Dont forget to init, this is also final return value!

    //all ng-models from specific sub-template must be listed here
    //---------------------------------------------------------------
    var apikeys = [
        'screenshotInterval',
        'screenshotRightSuccess'
    ];
    var apikeys_param = apiFunctions.obj_to_url_params(apikeys);


    //main function called by controller
    //---------------------------------------------------------------
    factory.getSettings = function() {
        return factory.getByParams(apikeys_param);
    };

    //only helper func, is called from above func
    //---------------------------------------------------------------
    factory.getByParams = function(params) {
        var req = {
            method: 'GET',
            url: API.url + 'cbsettings' + params
        };
        return $http(req);
    };


    factory.getScreenshotList = function() {
        var req = {
            method: 'GET',
            url: API.url + 'cbscreenshot/list'
        };
        return $http(req);
    };

    factory.deleteScreenshots = function() {
        var req = {
            method: 'GET',
            url: API.url + 'cbscreenshot/delete'
        };
        return $http(req);
    };


    //is called by watcher from controller
    //---------------------------------------------------------------
    factory.setSetting = function(obj) {
        scgSharedFactory.needsSync = true;
        var req = {
            method: 'POST',
            url: API.url + 'cbsettings',
            data: obj
        };
        return $http(req);
    };

    factory.screenshot = function() {
        var req = {
            method: 'GET',
            timeout: 60000,
            url: API.url + 'cbscreenshot'
        };
        return $http(req);
    };



    return factory;

});

