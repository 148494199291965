'use strict';
angular.module('ChicoApp')
    .constant('PREFEERED_LANGUAGE', 'de_DE');
angular.module('ChicoApp')
    /*
    .constant('API', {
      'url': 'https://portal.salfeld.net/api/',
      'filesurl': 'https://files.salfeld.net/',
      'add-dev-headers': true
    })
    */
    .constant('_',window._)
    .constant('moment',window.moment)
    .constant('html2canvas',window.html2canvas)
    .constant('jsPDF',window.jsPDF)
    .constant('google',window.google)
    .constant('Metronic',window.Metronic)

    .constant('FEATURES', {
        'feature1': {'fromVersion':'1.1.1'},
        'cb3background': {'fromVersion':'3.1.1159'},
        'protectSettings': {'fromVersion':'3.1.1159'},
        'hasFeatureNoAppsInBlockTime' : {'fromVersion':'3.1.1181'},
        'onesecinterval' : {'fromVersion':'3.1.1181'},
        'roamingv2' : {'fromVersion':'3.1.1200'},
        'loginlock' : {'fromVersion':'3.1.1209'},
        'crackProtection' : {'fromVersion':'3.1.1228'},
        'bonus' : {'fromVersion':'3.1.1228'},
        'addBonusTAN' : {'fromVersion':'3.1.1228'},
        'bonusMax' : {'fromVersion':'3.1.1236'},
        'noDeleteExtension' : {'fromVersion':'3.1.1241'},
        'permissionButton' : {'fromVersion':'3.1.1251'},
        'noBonusNotification' : {'fromVersion':'3.1.1254'},
        'pause' : {'fromVersion':'3.1.1261'},
        'screenshot' : {'fromVersion':'3.1.1265'},
        'noBlockNotification' : {'fromVersion':'3.1.1273'},
        'misc2020-1' : {'fromVersion':'3.1.1276'},
        'dayChart' : {'fromVersion':'3.1.1285'},
        'alwaysBlockNotification' : {'fromVersion':'3.1.1289'},
        'noAudioStop': {'fromVersion':'3.1.1291'},
        'noBlockDualScreen' : {'fromVersion':'3.1.1295'},
        'disablePopup': {'fromVersion':'3.1.1291'},
        'customBlockMsg' : {'fromVersion':'3.1.1295'},
        'roamingdevicetime' : {'fromVersion':'3.1.1300'},
        'alwaysAllowedWeb' : {'fromVersion':'3.1.1301'},
        'hasWebGroups' : {'fromVersion':'3.1.1301'}
    })

   .constant('FEATURESCC', {
    'roamingv2': {'fromVersion':'17.0.0.2800'},
    'blockStoreFeature' : {'fromVersion':'18.0.0.2804'},
    'defaultAppKindFeature' : {'fromVersion':'18.0.0.3000'},
    'allowedAppsFeature' :  {'fromVersion':'18.0.0.3000'},
    'is2019' : {'fromVersion':'19.0.0.3060'},
    'dynamicWhitelist' : {'fromVersion':'20.0.0.3128'},
    'keywordsglobal' : {'fromVersion':'20.0.0.3145'},
    'dayChart' : {'fromVersion':'20.0.0.3152'},
    'customBlockMsg' : {'fromVersion':'20.0.0.3171'},
    'hasWebGroups' : {'fromVersion':'30.0.0.9999'}
   });
