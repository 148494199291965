'use strict';
angular.module('ChicoApp.CC-Dashboard-V3')
    .controller('CC-DashboardV3Controller', function(
        $rootScope,
        $scope,
        $state,
        $stateParams,
        $http,
        $modal,
        $translate,
        $timeout,
        Metronic,
        moment,
        $window,
        $log,
        $cookies,
        chicoEnums,
        scgSharedFactory,
        ccDashboardFactory,
        ccUsageFactory,
        ccDashboardFactoryV3,
        scgGlobalSpinnerFactory,
        scgLicenceFactory,
        sseFactory,
        AuthFactory,
        DeviceFactory,
        apiFunctions,
        menuFactory,
        $interval,
        API,
        _,
        modalFactory) {

        var watchers = [];

        var apiDateFormat = 'YYYY-MM-DD HH:mm:SS';

        $scope.data = {};


        //new stuff
        $scope.lim_data = {};
        $scope.lim_data.isBlockTime = null;
        $scope.formatSecs = scgSharedFactory.formatSecs;
        $scope.extensionDuration = "00:15"; //this is the modal default value
        $scope.headlineDevice = "";

        $scope.spinnerDummy=false;

        //warning stuff
        $scope.secureBootWarning = false;
        $scope.showUnlockInfo = false;
        $scope.serverTimeDiff = 0;
        $scope.trialNotice = '';

        $scope.weekstats = {};
        $scope.weekstatsPeriod = 7;
        $scope.isPCOnline = false;
        $scope.timeTop3 = "";


        //limit switches
        $scope.limitTimeTime = false;
        $scope.limitTimeWeekdays = false;
        $scope.limitTimeBlockTime = false;
        $scope.limitTimeBreaks = false;

        $scope.hasMessages = true;
        $scope.hasExtension = true;

        $scope.hasCCFeature = scgSharedFactory.hasCCFeature;           //needed for hasFeature markup
        $scope.showUpdateNotice = false;
        $scope.noUser = false;
        $scope.needUpdate = false;
        $scope.delayms = 3000;
        $scope.dateHack = false;
        $scope.is2019 = false;
        $scope.hasCustomMsg = false;
        $scope.serial = "";

        $scope.activityKind = '1'; //default
        $scope.activityApps = [];

        $scope.hasDayViewFeature = true;
        $scope.overlayText = '';
        $scope.overlayUrl = '';
        $scope.hasRoamingVals = false;

        $scope.timebox = {};
        $scope.hasRoaming = false;
        $scope.isLoadingTotal = true;


        Metronic.unblockUI();

        var modalInstance;

        $log.info('WE ARE CC_DASHBOARD, jumpId is', scgSharedFactory.jumpId);
        $log.info('CC-DASH, activeLicence:' ,AuthFactory.activeLicence);

        if (scgSharedFactory.jumpId && scgSharedFactory.jumpId == 7) {
            scgSharedFactory.jumpId = 0;    //do only once
            $state.go('cc-usage.unblock-v2');
        }

        //Hotfix security issue: Are we in report view? (then immediately logout)
        if ($cookies.get('role') == 'report')
        {
            $state.go('login-logoff-success');
        }

        $scope.hasRoamingV2 = false;


        // Functions (to be called at the end of this file)
        //------------------------------------------------------------------------------------------
        $scope.getSettings = function() {

            if (!AuthFactory.activeUser && !$cookies.user) {
                $log.info('no user for getSettings!');
                $scope.noUser = true;
                return;
            }

            ccDashboardFactoryV3.getCCSettings()
                .then(function success(response) {
                    $scope.noUser = false;
                    $scope.lim_data = response.data;
                    scgGlobalSpinnerFactory.unblockUI();

                    $log.info('getCCSettings=', $scope.lim_data);

                    if ($scope.lim_data.blockTimesPC ==scgSharedFactory.blockOff()) {
                        $scope.lim_data.isBlockTime = false;
                    }  else {
                        $scope.lim_data.isBlockTime = true;
                    }
                    $scope.lim_data.AllTimelimit = false; //default

                    if ($scope.lim_data.isLatestVersion===false) {
                        $scope.needUpdate = true;
                        $log.info('needUpdate=true!');
                    } else {
                        $scope.needUpdate = false;
                    }

                    if ($scope.lim_data.datetimehack===true) {
                        $scope.dateHack = true;
                        $log.info('datehack=true!');
                    }

                    if ($scope.lim_data.roaming != null && $scope.lim_data.roaming != '' && $scope.lim_data.romaing != 'undefined') {
                        $log.info('roaming check set true');
                        scgSharedFactory.hasRoaming = true;
                    } else {
                        scgSharedFactory.hasRoaming = false;
                    }
                    $log.info('roaming check=' + $scope.lim_data.roaming);


                    if ($scope.lim_data.isTimeLimitActive) {$scope.lim_data.AllTimelimit=true;}
                    if ($scope.lim_data.isWeekDayLimitActive) {$scope.lim_data.AllTimelimit=true;}
                    if ($scope.lim_data.isBlockTime) {$scope.lim_data.AllTimelimit=true;}
                    if ($scope.lim_data.isforcedbreak) {$scope.lim_data.AllTimelimit=true;}
                    $log.info('inside getSettings:',$scope.lim_data);
                    $log.info('inside isBlocktime:',$scope.lim_data.isBlockTime);
                    $log.info('inside AllTimelimit:',$scope.lim_data.AllTimelimit);

                    $timeout( function(){
                        $log.info('roaming timer, GUESSFIX lim_data.roaming='+ $scope.lim_data.roaming);
                        if ($scope.lim_data.roaming && $scope.lim_data.roaming!='') scgSharedFactory.hasRoaming=true;
                    }, 1500 );

                });
        }

        $scope.checkOnline = function() {
            scgSharedFactory.checkPCOnline()
                .then(function success(result) {
                    $scope.isPCOnline = result;
                });
        }

        $scope.getIconWeb = function(domain) {
            return "https://portal.salfeld.net/files/icon/web/sess887783995a9d401681c6567398227aac/" + domain + '.png';
        }

        $scope.getIconFile = function (exe) {
            var newexe = exe.replace('.exe','.png');
            return "https://portal.salfeld.net/files/icon/sess887783995a9d401681c6567398227aac/" + newexe;
        }

        //New extension approach without using SSE
        $scope.doExtension = function(extensionTime) {
            modalInstance.dismiss('cancel');
            scgGlobalSpinnerFactory.blockUI();
            $log.info('DoEXTENSION with extensionTime=', extensionTime);
            var extTimeMinutes = moment.duration(extensionTime, 'minutes').format('mm');
            ccDashboardFactory.setExtensionTime(extTimeMinutes)
                .then(function success() {
                    $scope.myInfoModal($translate.instant('MSG.EXTENSION_SET'));
                    scgSharedFactory.logMeCC('Web Portal: ' + $translate.instant('MSG.EXTENSION_SET') + ' (' + extTimeMinutes + ' Min)');
                });
        };

        $scope.setDayChartAvailable = function(status) {
            if (status) {
                $scope.overlayText = '';
                $scope.overlayUrl = '';
            } else {
                $translate('INFO.UPDATE_FOR_FEATURE').then(function(translated) {
                    $scope.overlayText = translated;
                });
                $translate('URL.UPDATE_CC').then(function(translated) {
                    $scope.overlayUrl = translated;
                });
            }
        }

        //Secure Boot warning? (not used for now)
        $scope.getSecureBoot = function() {
            ccDashboardFactory.getSecureBoot().then(function success(response) {
                    if (response.data.secureboot === 1) {
                        $scope.secureBootWarning = true;
                        $log.info('secureboot:', response.data.secureboot);
                    }
                });
        }

        $scope.getUnlockReqs = function() {
            ccUsageFactory.getUnlockReqs().then(function success(response) {
                    $log.info('response.data=', response.data);
                    $log.info('response.data length=', response.data.length);
                    if (response.data && response.data.length > 0) {
                        $scope.showUnlockInfo = true;
                    }
                });
        }

        $scope.getServerTimeDiff = function() {
            scgSharedFactory.getServerTimeDiff().then(function(funcresult) {
                $scope.serverTimeDiff = funcresult;
            });
        }

        $scope.getSerialInfo = function() {
            scgLicenceFactory.getSerialInfo()
                .then(function success(response) {
                        $log.info('LICENCETEST Success', response);
                    if (response.data.serial === '' && response.data.expireDays < 0 && response.data.suggestedSerial) {
                        $log.info('LICENCETEST we have suggested serial');
                        $state.go('licence');
                        return;
                        }
                        if (response.data.serial === '' && response.data.expireDays <= 30) {
                            $scope.trialNotice = $translate.instant('MISC.TRIALVERSION') + ': ' + $translate.instant('MISC.REMAINING').replace('XXX', response.data.expireDays);
                        }
                        if (response.data.serial != '' && response.data.expireDays <= 30) {
                            $scope.trialNotice = $translate.instant('MISC.FULLVERSION') + ': ' + $translate.instant('MISC.REMAINING').replace('XXX', response.data.expireDays);
                            $scope.serial=response.data.serial;
                        }
                    },
                    function error() {
                        $log.info('LICENCETEST ERROR');
                    });
        }



        $scope.turnOn = function() {
            var postData = {'onetimelocklevel': 0};
            ccDashboardFactory.setOneTimeLockLevel(postData)
                .then(function success() {
                    $log.info('TURN ON SUCCESS! is online', $scope.isPCOnline);
                    $scope.instantSync();
                    $state.reload();
                });
        }


        $scope.turnOff = function() {
            modalFactory.infoModal.open({
                modal_settings: {
                    title: $translate.instant('MSG.QUESTION'),
                    message: $translate.instant('MSG.SURE'),
                    butOkLabel: $translate.instant('BUTTON.YES'),
                    butCancelLabel: $translate.instant('BUTTON.NO'),
                    iconNo: 2,
                    refreshStateAfterClose: false //Dierk: bei true wurde controller gleich neu geladen werden
                },
                callbacks: {
                    ok: function() {
                        var postData = {'onetimelocklevel': -1};        //-1 = always off
                        ccDashboardFactory.setOneTimeLockLevel(postData)
                            .then(function success() {
                                $log.info('TURN OFF SUCCESS! is online', $scope.isPCOnline);
                                //If PC is online sync directly, w/o message
                                $scope.instantSync();
                                $state.reload();
                            });
                    },
                },
                cancel: function() {
                    $log.info('turnoff canceled!');
                }
            });


        }


        $scope.setOneTimeLockLevel = function(postData) {
            ccDashboardFactory.setOneTimeLockLevel(postData).then(function success() {
                    $log.info('TURN OFF SUCCESS! is online', $scope.isPCOnline);
                    //If PC is online sync directly, w/o message
                    $scope.instantSync();
                });
        }


        $scope.doUntilAction = function(defaultRadio) {
            modalFactory.untilModal.open({
                modal_settings: {
                    defaultRadio: defaultRadio,
                    hasNeverBlockAlwaysAllowedFeature : $scope.hasCustomMsg,
                    neverBlockAlwaysAllowed : $scope.lim_data.neverBlockAlwaysAllowed,
                    context : 'CC',
                    refreshStateAfterClose: false
                },callbacks: {
                    ok: function () {
                        Metronic.unblockUI();
                        $log.info('modal-until ok callback');
                        $scope.instantSync();
                        $state.reload();
                    }
                }
            });
        }


        $scope.instantSync = function() {
            if ($scope.isPCOnline) {
                sseFactory.sync()
                    .then(function success(response) {
                        if (response.data.success == true) {
                            $log.info('SYNC SUCCESS');
                            scgSharedFactory.needsSync = false;
                            $scope.syncRunning = false;
                        }
                    });
            } else {
                scgSharedFactory.needsSync = true;       //PC is offline, display notice
            }
        };


        $scope.showWeekdayLog = function(weekday)
        {
            debugger;
            $interval.cancel(intervalStatusInfo);
            $state.go("cc-usage.reportsv2", {weekday: weekday});
            $log.info('showWeekdayLog weekday=' + weekday);

        }


        // ***********************************************************************************************************
        //                                     LIVE FUNCTIONS (SSE)
        // ***********************************************************************************************************
        $scope.showExtensionModal = function() {
            modalInstance = $modal.open({
                animation: false,
                templateUrl: 'templates/childcontrol/cc_dashboard_v3/templates/dashboard-v3-modal-extension.html',
                windowTemplateUrl: 'templates/childcontrol/cc_dashboard_v3/templates/dashboard-v3-modal-window-small.html',
                scope: $scope
                });
        };


        $scope.showScreenshot = function() {
            modalInstance = $modal.open({
                animation: false,
                templateUrl: 'templates/childcontrol/cc_dashboard/templates/dashboard-modal-screenshot.html',
                windowTemplateUrl: 'templates/childcontrol/cc_dashboard/templates/dashboard-modal-window.html',
                scope: $scope
                });
        };



        scgSharedFactory.checkHasChangedTimer();

        scgSharedFactory.checkPCOnline()
            .then(function success(result) {
                $scope.isPCOnline = result;

            });

        $scope.delExtension = function() {
            $log.info('THIS IS DEL EXTENSION');
            scgGlobalSpinnerFactory.blockUI();
            ccDashboardFactory.setExtensionTime(0)
                .then(function success() {
                    $log.info('YES, WE DELETED IT');
                    $scope.data.extension = 0;
                    scgGlobalSpinnerFactory.unblockUI();
                    scgSharedFactory.logMeCC('Web Portal: ' + $translate.instant('MSG.EXTENSION_DELETED'));
                    $scope.myExtensionDeleteModal($translate.instant('MSG.EXTENSION_DELETED'));
                    $scope.extensionInfo = $translate.instant('CC_DASH.CURRENT_EXTENSION') + ': '  + $translate.instant('ALL.NONE');
                });
        };

        $scope.myExtensionDeleteModal = function(msg) {
            scgGlobalSpinnerFactory.unblockUI();
            modalFactory.infoModal.open({
                modal_settings: {
                    title: $translate.instant('HEADER.INFO'),
                    message: msg,
                    butOkLabel: $translate.instant('BUTTON.OK'),
                    butCancelLabel: null,
                    iconNo: 1,
                    refreshStateAfterClose: false
                },
                callbacks: {
                    ok: function() {
                        $log.info('modal closed with ok -> callback');
                    }
                }
            });
        };

        //---------------------------------------------------------------------------------------------------------------
        //infomdal for re-use in this scope
        $scope.myInfoModal = function(msg) {
            scgGlobalSpinnerFactory.unblockUI();
            modalFactory.infoModal.open({
                modal_settings: {
                    title: $translate.instant('HEADER.INFO'),
                    message: msg,
                    butOkLabel: $translate.instant('BUTTON.OK'),
                    butCancelLabel: null,
                    iconNo: 1,
                    refreshStateAfterClose: true
                },
                callbacks: {
                    ok: function() {
                        $log.info('modal closed with ok -> callback');
                    }
                }
            });
        };
        //---------------------------------------------------------------------------------------------------------------
        $scope.doSSEExt = function(extensionTime) {
            modalInstance.dismiss('cancel');

            scgGlobalSpinnerFactory.blockUI();
            var extTimeMinutes = moment.duration(extensionTime, 'minutes').format('mm');

            sseFactory.extensionTime(extTimeMinutes)
                .then(function success(response) {
                    if (response.data.success == true) {
                        if (response.data.id !== null && response.data.id == 'NO_SESSION') {
                            $scope.myInfoModal($translate.instant('MSG.NOSESSION'));
                        } else {
                            $scope.myInfoModal($translate.instant('MSG.SSESUCCESS'));
                        }
                    } else {
                        $scope.myInfoModal($translate.instant('MSG.SSEFAILURE'));
                    }
                });
        };

        //---------------------------------------------------------------------------------------------------------------
        $scope.doSSEMsg = function(mymsg) {
            scgGlobalSpinnerFactory.blockUI();
            sseFactory.msg(mymsg)
                .then(function success(response) {
                    if (response.data.success == true) {
                        if (response.data.id !== null && response.data.id == 'NO_SESSION') {
                            $scope.myInfoModal($translate.instant('MSG.NOSESSION'));
                        } else {
                            $scope.myInfoModal($translate.instant('MSG.SSESUCCESS'));
                        }
                    } else {
                        $scope.myInfoModal($translate.instant('MSG.SSEFAILURE'));
                    }
                });
        };

        $scope.fixMessageString = function() {
            $log.info('changed');
            $scope.message = $scope.message.replace('ß','ss');
            $scope.message = $scope.message.replace('ö','oe');
            $scope.message = $scope.message.replace('ü','ue');
            $scope.message = $scope.message.replace('ä','ae');
            $scope.message = $scope.message.replace('Ö','Oe');
            $scope.message = $scope.message.replace('Ü','Ue');
            $scope.message = $scope.message.replace('Ä','Ae');
            $scope.message = $scope.message.replace(/[^a-zA-Z0-9\s\!]+/,'');
        };
        //---------------------------------------------------------------------------------------------------------------
        $scope.doSSESync = function() {
            scgGlobalSpinnerFactory.blockUI();
            sseFactory.sync()
                .then(function success(response) {
                    if (response.data.success == true) {
                        $log.info('SYNC SUCCESS');
                        $scope.myInfoModal($translate.instant('MSG.SYNCSUCCESS'));
                    } else {
                        $log.info('SYNC FAILED');
                        $scope.myInfoModal($translate.instant('MSG.SYNCFAILURE'));
                    }
                });
        };

        //---------------------------------------------------------------------------------------------------------------
        $scope.doSSEShutdown = function() {
            modalFactory.infoModal.open({
                modal_settings: {
                    title: $translate.instant('MSG.QUESTION'),
                    message: $translate.instant('MSG.SURE'),
                    butOkLabel: $translate.instant('BUTTON.YES'),
                    butCancelLabel: $translate.instant('BUTTON.NO'),
                    iconNo: 2,
                    refreshStateAfterClose: false //Dierk: bei true wurde controller gleich neu geladen werden
                },
                callbacks: {
                    ok: function() {
                        $log.info('yes, ok was click, he/she is sure');
                        scgGlobalSpinnerFactory.blockUI();
                        sseFactory.shutdown()
                            .then(function success(response) {
                                if (response.data.success == true) {
                                    $scope.myInfoModal($translate.instant('MSG.SSESUCCESS'));
                                } else {
                                    $scope.myInfoModal($translate.instant('MSG.SSEFAILURE'));
                                }
                            });
                    },
                },
                cancel: function() {
                    $log.info('canceled!');
                }
            });
        };




        $scope.doSSEScreenshot = function() {
            scgGlobalSpinnerFactory.blockUI();
            $scope.pollDone = false;
            sseFactory.screenshot()
                .then(function success(response) {
                    $log.info('screenshot response:' , response.data);
                    if (response.data.success == true) {
                        if (response.data.id  !== null && response.data.id == 'NO_SESSION') {
                            $scope.myInfoModal($translate.instant('MSG.NOSESSION'));
                            return;
                        }
                        $scope.pollimg_interval = $interval(function() {
                            if ($scope.pollDone) {
                                //8a: ADD THE FAILURE
                                $interval.cancel($scope.pollimg_interval);
                                $log.info('We are done, show pic here');
                                scgGlobalSpinnerFactory.unblockUI();
                                var rnd = Math.floor(Math.random() * (999999 - 100001)) + 100001; //login controller because of NO AUTH for uriScreenshot, rnd against caching
                                $scope.uriScreenshot = "http://portal.salfeld.net/api/login/getscreen/" + rnd + "/" + response.data.id;
                                $log.info('uriScreenshot:' + $scope.uriScreenshot); //used by <img src> in modal
                                $scope.showScreenshot();
                            } else {
                                $log.info('interval');
                                $scope.checkScreenshot(response.data.id);
                            }
                        }, 1000, 20);

                    } else {
                        $scope.myInfoModal($translate.instant('MSG.SSEFAILURE'));
                    }
                });
        };

        $scope.checkScreenshot = function(filename) {
            sseFactory.checkScreenshot(filename)
                .then(function success(response) {
                    $log.info('image exists', response);
                    $scope.pollDone = true;
                }, function error(e1) {
                    $log.info('head check error', e1);
                });
        };




        $scope.getStatusInfo = function() {

            if (!AuthFactory.activeUser && !$cookies.user) {
            $log.info('no user for getStatusInfo!');
            $scope.noUser = true;
            $scope.spinnerOverview = false;
            return;
            }

            ccDashboardFactoryV3.getStatusInfo().then(function success(response) {
                if (response.data) {
                    $scope.noUser = false;
                    $log.info("statusinfo arrived," ,response.data);
                    $scope.statusInfo = response.data;
                    $scope.extensionTime = response.data.extTime;

                    if ($scope.statusInfo.lastAzDateTime==null)
                    {
                        $log.info("statusinfo canceled because lastAzDateTime is NULL!");
                        $scope.spinnerOverview = false;
                        return;
                    }

                    var extTime = $translate.instant('ALL.NONE');
                    if (response.data.extTime) {
                        extTime = response.data.extTime + ' Min.';
                    }
                    $scope.extensionInfo = $translate.instant('CC_DASH.CURRENT_EXTENSION') + ': '  + extTime; // + ' ' + $translate.instant('HEADER.EXTENSIONMINUTES');
                    $scope.extensionInfoNote = $translate.instant('INFO.EXTENSION').replace('%%', response.data.extTime);


                    if (response.data.deviceTimeWarning!=null) {
                        $scope.deviceTimeWarning = response.data.deviceTimeWarning;
                    }

                        $log.info("untilaction ACTIVE in statusinfo");
                        $scope.data.untilAction=$scope.statusInfo.untilAction;
                        $scope.data.untilDateTime=$scope.statusInfo.untilDateTime;


                    var statustime = null;
                    var lastTimeFormated = null;

                    if ($scope.statusInfo.lastAzDateTime!=null) {
                        statustime = $scope.statusInfo.lastAzDateTime.substring(0,10);
                        lastTimeFormated = moment($scope.statusInfo.lastAzDateTime).format('HH:mm');
                    }
                    var browsertime =  moment().format('YYYY-MM-DD');
                    var a = moment(statustime);
                    var b = moment(browsertime);
                    var timeDiff = b.diff(a, 'days');

                    $log.info('status browsertime=' + browsertime + "|statustime=" + statustime + "|timeDiff=" + timeDiff + "|formated=" + lastTimeFormated);

                    //New time calculation for caption today, yesterday, two day ago etc.
                    if (statustime!=null && browsertime!=null) {
                        $scope.timeDiff = timeDiff;
                        if (statustime == browsertime) {
                            $scope.timeDiff = 0;
                            $scope.timeTop3 = $translate.instant('USAGE.LAST_TODAY');
                            $scope.timeBoxCaption = $translate.instant('USAGE.LAST_TODAY') + ", " + lastTimeFormated
                        }
                        if (timeDiff == 1) {
                            $scope.timeTop3 = $translate.instant('USAGE.LAST_YESTERDAY');
                            $scope.timeBoxCaption = $translate.instant('USAGE.LAST_YESTERDAY') + ", " + lastTimeFormated
                        }
                        if (timeDiff == 2) {
                            $scope.timeTop3 = $translate.instant('USAGE.LAST_EREYESTERDAY');
                            $scope.timeBoxCaption = $translate.instant('USAGE.LAST_EREYESTERDAY') + ", " + lastTimeFormated
                        }
                        if (timeDiff > 2) {
                            if ($translate.use() == 'de_DE') {
                                $scope.timeTop3 = "Vor " + timeDiff + " Tagen";
                                $scope.timeBoxCaption = "Vor " + timeDiff + " Tagen, " + lastTimeFormated;
                            } else {
                                $scope.timeTop3 = timeDiff + " days ago";
                                $scope.timeBoxCaption = timeDiff + " days ago, " + lastTimeFormated;
                            }
                        }
                        if (timeDiff > 30) {
                            if ($translate.use() == 'de_DE') {
                                $scope.timeTop3 = "Vor mehr als 30 Tagen";
                                $scope.timeBoxCaption = "Vor mehr als 30 Tagen...";
                            } else {
                                $scope.timeTop3 = "More than 30 days ago";
                                $scope.timeBoxCaption = "More than 30 days ago...";
                            }
                        }
                    }

                    if (statustime!=null && browsertime!=null && statustime!==browsertime) {
                        $scope.hasOldTime=true;
                        $scope.totaltime=0;
                    }

                    //special case: UntilAction 2 or 3 but now in past
                    var browserDateTime =  moment().format('YYYY-MM-DD hh:mm:ss');
                    if ($scope.statusInfo!=null && $scope.statusInfo.untilDateTime!=null && browserDateTime!=null && ($scope.statusInfo.untilAction==2 || $scope.statusInfo.untilAction==3)) {
                        var untilDiff = moment(browserDateTime).diff(moment($scope.statusInfo.untilDateTime), 'seconds');
                        $log.info('browserDatetTime=' + browserDateTime + '|untilAction=' + $scope.statusInfo.untilAction + '|untilDateTime=' + $scope.statusInfo.untilDateTime + '|untilDiff=' + untilDiff);
                        if (untilDiff>0) {
                            $scope.statusInfo.untilAction=0;
                        }
                    }



                    //get counted apps
                    var topArr = [];
                    var limit = 3;
                    response.data.apps = _.sortBy(response.data.apps, ['counted']);
                    _.forEach(response.data.apps, function(app, index){
                        if(app.title != 'total' && topArr.length < limit){
                            $scope.hasAppData=true;
                            topArr.push(app);
                        }
                    });
                    var countedSum = topArr[0]?topArr[0].counted:0;
                    $scope.statusInfoApps = {apps:topArr, fullBarSum:countedSum};

                    $log.info('statusinfo debug:',$scope.statusInfo);
                    $scope.spinnerOverview = false;
                }
            });
        }

        $scope.showUsage = function() {
            $state.go("cc-usage.reportsv2", {periodKind: $scope.activityKind});
        }

        //Recurring Timer, update each 30 Seconds statusInfo!
        //---------------------------------------------------
        var intervalStatusInfo = $interval(function () {
            $scope.showActivity();
            $scope.getSettings();
            $scope.getStatusInfo();
            $scope.getUnlockReqs();
        }, 30 * 1000);


        // NEW ACTIVITY STUFF
        $scope.showActivity = function() {
            $log.info('showActivity for kind=' + $scope.activityKind + '|hasDayFeature=' + $scope.hasDayViewFeature);

            var fromDate = moment().format('YYYY-MM-DD');             //set defaults to today
            var toDate = fromDate;

            $scope.getTotalTimesDay(fromDate);

            if ($scope.activityKind=='2') {
                fromDate = moment().startOf('isoWeek').format('YYYY-MM-DD');
                toDate = moment().endOf('isoWeek').format('YYYY-MM-DD');
            }
            if ($scope.activityKind=='3') {
                fromDate = moment().startOf('month').format('YYYY-MM-DD');
                toDate = moment().endOf('month').format('YYYY-MM-DD');
            }

            if ($scope.hasDayViewFeature==false && $scope.activityKind=='1') {
                $scope.setDayChartAvailable(false);
                $log.info('showActivity SET TO FALSE');
            }  else {
                $scope.setDayChartAvailable(true);
            }

            $scope.getChartData(fromDate,toDate);
            $scope.getActivityTopApps(fromDate,toDate);
        }

        $scope.lookupTopApp = function(pkg,appname,kind) {
            $log.info('lookupApp for url=' + appname);
            if (kind==4) {
                $state.go('detail-view', {
                    appIds: [appname],
                    appType: chicoEnums.AppTypeEnum.WINDOWS_APP,
                    allowAdd: false,
                    prevStateName: $state.current.name
                });
            }
            if (kind==22) {
                $state.go('detail-view', {
                    appIds: [appname],
                    appType: chicoEnums.AppTypeEnum.WEBSITE,
                    allowAdd: false,
                    prevStateName: $state.current.name
                });
            }
        }

        $scope.onChartColClick = function(colIndex, colData) {
            $scope.showUsage();
        }

        $scope.getActivityTopApps = function(fromDate,toDate) {
            var howMany = 3;
            ccDashboardFactoryV3.getTopActivity(fromDate,toDate,howMany).then(function(res) {
                $log.info('getActivityApps res.data=', res.data);
                $scope.activityApps = res.data;
            });
        }

        $scope.getTotalTimesDay = function() {
            $scope.isLoadingTotal = true;
            var today = moment().format('YYYY-MM-DD');
            ccUsageFactory.getTotalTimesDay(today).then(function success(response) {
                $scope.timebox = response.data;
                $scope.isLoadingTotal = false;
            });
        }

        $scope.getChartData = function(fromDate,toDate) {
            var req;
            $scope.loadingActivity = true;
            if (fromDate===toDate) {
                req = ccUsageFactory.getChartDayApi(fromDate);
            } else {
                req = ccUsageFactory.getChartStatsApi(fromDate,toDate);
            }

            req.then(function(res) {
                $scope.loadingActivity = false;
                $scope.hasRoamingVals=false;
                $scope.azDate = [];
                $scope.chartData = [];
                $scope.chartDatasecondary = [];
                $scope.Captions = [];
                $scope.subCaptions = [];

                var total = res.data.length;
                for (var i = 0; i < total; i++) {
                    $scope.chartData.push(res.data[i].percent);
                    if (res.data[i].percentSecondary) {
                        $scope.hasRoamingVals=true;
                        $scope.chartDatasecondary.push(res.data[i].percentSecondary);
                    } else {
                        $scope.chartDatasecondary.push(0);     //dierk test
                    }
                    var caption = res.data[i].azdate;
                    $scope.azDate.push(res.data[i].azdate);
                    if (fromDate!=toDate && total<=10) caption=moment(res.data[i].azdate, 'YYYY-MM-DD').format('Do. MMM');
                    if (fromDate!=toDate && total>20) caption=moment(res.data[i].azdate, 'YYYY-MM-DD').format('Do.');
                    $scope.Captions.push(caption);
                    var seconds = scgSharedFactory.formatSecs(res.data[i].seconds,true);
                    $log.info('getChartData index=' + i + '|subCaptions=' + seconds);
                    $scope.subCaptions.push(seconds);
                }
                $scope.showTooltips = true;
                if (total<=10) {
                    $scope.showCaptions=true;
                    $scope.showSubCaptions=true;
                } else {
                    $scope.showCaptions=true;
                    $scope.showSubCaptions=false;
                }
                $scope.loadingChart = false;
            });
        }; // EOF NEW ACTIVITY STUFF




    $scope.getAllSettings = function() {
        $log.info('getAllSettings');
        $scope.spinnerOverview = true;
        $scope.getSettings();                                        //Load LimSettings
        $scope.checkOnline();                                       //only done once here at pageload (for online funcs)
        $scope.getStatusInfo();
        //$scope.getSecureBoot();       //not used for now
        $scope.getUnlockReqs();
        $scope.getServerTimeDiff();
        $scope.getSerialInfo();
        $scope.showActivity();
    }

    // Calls at startup
    //-----------------------------------------------------------------------------------------------------------------
     $scope.getAllSettings();




        $scope.roamingSettings = function() {
            $log.info("roamingSettings called ");
            modalFactory.roamingModal.open({
                modal_settings: {
                    refreshStateAfterClose: false
                },callbacks: {
                    ok: function () {
                        $log.info('modal-until ok callback');
                        //we need little time before refresh/reload to save the roaming setttings
                        scgGlobalSpinnerFactory.blockUI();
                        $timeout( function(){
                            $log.info('modal-roaming reload');
                            scgGlobalSpinnerFactory.unblockUI();
                            $state.reload();
                        }, 2000 );
                    }}
            });
        }


        $scope.lookupExe = function(entry)
        {
            $log.info('lookupExe with entry=' + entry);
            $state.go('detail-view', {appIds: [entry], appType: chicoEnums.AppTypeEnum.WINDOWS_APP, allowAdd: false,prevStateName: $state.current.name});

        }

        //watcher for deviceType change (CBE!)
    //-----------------------------------------------------------------------------------------------------------------
    $scope.$watch(function() {
        return AuthFactory.activeDevice + AuthFactory.activeUser;
    }, function(newVal) {
        if( $scope.deviceType != AuthFactory.activeDevice.deviceType ){
            $scope.deviceType = AuthFactory.activeDevice.deviceType;
        }
    });

       //Watcher for changing user on cc3 dashbaord, we must refresh
       //-----------------------------------------------------------------
        $scope.$watch(function() {
            return AuthFactory.activeUser;
        }, function(newVal) {
            if (AuthFactory.activeUser) {
                $log.info('watch device change user, newVal=', newVal);
                $scope.getAllSettings();
            }
        });


    //watcher for version (important for roamingv2)
    //------------------------------------------------------------------------
    $scope.$watch(function() {
        return AuthFactory.activeDevice.versionInfo;
    }, function(newVal) {

            if (newVal) {
                $scope.hasDayViewFeature = $scope.hasDayViewFeature = $scope.hasCCFeature('dayChart',AuthFactory.activeDevice.versionInfo);
                $log.info('showActivity has feautre=' + $scope.hasDayViewFeature );
                $scope.setDayChartAvailable($scope.hasDayViewFeature);
            }

            if (AuthFactory.activeDevice.versionInfo) {
                $scope.hasRoamingV2 = $scope.hasCCFeature('roamingv2',AuthFactory.activeDevice.versionInfo);
                $scope.is2019 = $scope.hasCCFeature('is2019',AuthFactory.activeDevice.versionInfo);
                $scope.hasCustomMsg = $scope.hasCCFeature('customBlockMsg',AuthFactory.activeDevice.versionInfo);
                $log.info('watch version activeDevice=', AuthFactory.activeDevice.versionInfo);
                var s =AuthFactory.activeDevice.versionInfo;
                if (s.length>11) {
                    $log.info('watch version activeDevice detaied=', s.substring(7,11));
                    if (isNaN(s.substring(7,11))==false && s.substring(7,11)<=2804) {
                        $log.info('watch version activeDevice UPDATE REQUIRED!');
                        $scope.showUpdateNotice = true;
                    }
                }
            }
    });


    $scope.delayTest = function(ms) {
        ccDashboardFactoryV3.delayTest(ms).then(function success(response) {
            if (response.data) {
                $scope.myInfoModal('DELAY DONE!');
                $log.info('delaytest data arrived=',response.data);
            }
    }, function (reject) {
            $log.info('delaytest reject', reject);
            $scope.myInfoModal($translate.instant('ERROR.HTTP'));
        });
    }

        $scope.$on("$destroy", function() {
            $interval.cancel(intervalStatusInfo);

        });

        $scope.resetDateHack = function() {
            $log.info('resetDateHack');
            ccDashboardFactoryV3.resetDateHack().then(function success(response) {
                $scope.dateHack=false;
            });
        }



    });
