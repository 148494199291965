'use strict';
angular.module('ChicoApp.CC-Internet')
    .controller('CC-InternetControllerBlocking-Groups', function(
      $scope,
      $translate,
      ccListsFactory,
      scgSettingsFactory,
      $log,
      _,
      modalFactory) {

      var modalContext = 'net';

      $scope.data = {};
      $scope.currentSelectedGroup = null;
      $scope.currentSelectedIndex = null;

      $scope.contentLoading = true;
      scgSettingsFactory.getGroups(true)
            .then(function success(response) {
              $scope.data.groups = response.data;

              $log.info('$scope.data.groups', $scope.data.groups);
              $scope.contentLoading = false;
              _.each($scope.data.groups, function(group, index) {

                $scope.$watch('data.groups[' + index + ']', function(newVal, oldVal) {
                  if (newVal && newVal != oldVal && newVal.name != oldVal.name) {
                    $scope.renameGroup(newVal);
                  }
                },true);

              });

            });

      $scope.getSubItemsForGroup = function(group, index) {
        ccListsFactory.getByListFilter(group.kind)
                .then(function success(response) {
                  $scope.data.groups[index].subItems = response.data;
                });
      };

      $scope.renameGroup = function() {
        scgSettingsFactory.setGroups($scope.data.groups)
                .then(function success() {
                  $log.info('groups saved');
                });
      };

      $scope.editCurrentGroup = function() {

        //prepare list for modal required data structure
        var editRecords = [];
        _.each($scope.currentSelectedGroup.subItems, function(v) {
          var obj = {data: {entry: v}};
          editRecords.push(obj);
        });

        var modalMode;
        var newRecord = null;
        if (editRecords.length < 1) {
          newRecord = {
            kind: $scope.currentSelectedGroup.kind
          };
          modalMode = 'add';
          editRecords = null;
        }else {
          modalMode = 'edit';
        }

        modalFactory.editModal.open({
          modal_settings: {
            mode: modalMode,
            context: modalContext,
            title: $translate.instant('MODAL_EDIT_RECORD.TITLE_EDIT'),
            newRecord: newRecord,
            editRecords: editRecords,
            refreshStateAfterClose: true
          },
          callbacks: {
            ok: function() {},
            cancel: function() {}
          }
        });

      };

      $scope.toggleSubitems = function(group, index) {

        $scope.getSubItemsForGroup(group, index);

        if (group == $scope.currentSelectedGroup) {
          $scope.currentSelectedGroup = null;
          $scope.currentSelectedIndex = null;

          $scope.data.groups.forEach(function(item) {
            item.subitemVisible = false;
          });
        }else {

          $scope.currentSelectedGroup = group;
          $scope.currentSelectedIndex = index;

          $scope.data.groups.forEach(function(item) {
            if (item.no.no != group.no) {
              item.subitemVisible = false;
            }

          });

          group.subitemVisible = !group.subitemVisible;

        }

      };

      $scope.deleteEntryFromGroup = function(/*item, index*/) {

        // ccListsFactory.postRecords([{entry: item, kind: ccListsFactory.listKinds.list_kind_allowed_prg}])
        //      .then(function success(response) {
        //         $scope.getSubItemsForGroup($scope.currentSelectedGroup, $scope.currentSelectedIndex);
        //      });

      };

    });
