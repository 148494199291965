'use strict';
angular.module('ChicoApp.CB-V3-Dashboard')

    .controller('CB-DashboardV3Controller', function ($rootScope,
                                                    $scope,
                                                    $translate,
                                                    $state,
                                                    $stateParams,
                                                    $modal,
                                                    $http,
                                                    $window,
                                                    $cookies,
                                                    moment,
                                                    API,
                                                    scgSharedFactory,
                                                    cbDashboardFactory,
                                                    cbScreenShotFactory,
                                                    cbUsageFactory,
                                                    AuthFactory,
                                                    $log,
                                                    $timeout,
                                                    $interval,
                                                    chicoEnums,
                                                    DeviceFactory,
                                                    Metronic,
                                                    _,
                                                    modalFactory,
                                                    menuFactory,
                                                    errorDisplayFactory,
                                                    scgGlobalSpinnerFactory) {

        $scope.scgSharedFactory = scgSharedFactory;

        AuthFactory.activeLicence.showSideBar = true;
        menuFactory.showSidebar();

        $scope.data = {};
        $scope.data.isSomeActive=true;
        $scope.status = {};
        $scope.statusInfo = {};
        $scope.statusInfoApps = {};
        $scope.headlineDevice = "";
        $scope.logbookTeaser = {};
        $scope.Android8Notice = false;
        $scope.needUpdate = false;
        $scope.untilPassed = false;

        var modalInstance;


        $timeout(function () {
            $scope.trueDemo = false;
        }, 5000);

        $timeout(function () {
            $scope.trueDemo = true;
        }, 10000);

        $timeout(function () {
            $scope.trueDemo = undefined;
        }, 15000);

        $scope.activeLicence = {};
        $scope.onOrOff = '...';
        $scope.lastSync = '...';
        $scope.lastSyncShort = '...';
        $scope.todayTotal = '...';
        $scope.hasNotice = false;
        $scope.noticeText = '';
        $scope.isCBSecurity = true; //here because of "do not show as default"
        $scope.serverTimeDiff = 0;
        $scope.hasFeatureDeviceLimit = false;
        $scope.extTimeInfo = "..."; //$translate.instant('ALL.NONE');
        $scope.addExtCBMinutes = '00:15'; //default
        $scope.hintHide = false;  //not used yet!
        $scope.deviceWarning = '';
        $scope.deviceWarningURL = '';
        $scope.totaltime = 0;
        $scope.giveExt = 0; //default
        $scope.extensionTime = 0;
        $scope.isActive = true; //main switch true
        $scope.cbMajor = 0;
        $scope.deviceTimeWarning = false;
        $scope.hasOldTime = false;
        $scope.deviceHint = "";

        $scope.offDeviceAdmin = false;
        $scope.offUsageStats = false;
        $scope.offAccessibility = false;
        $scope.offPopupDisplay = false;

        $scope.timeBoxCaption = "";
        $scope.timeDiff = -1;
        $scope.timeTop3 = "";
        $scope.timeBoxTime = "";
        $scope.timeBoxRemaining = "";
        $scope.timeBoxExtension = "";
        $scope.hasAppData = false;
        $scope.hasCBFeature = scgSharedFactory.hasCBFeature;
        $scope.spinnerMain = true;
        $scope.dateHack = false;
        $scope.hasNoticeSerial = "";
        $scope.pollDone = false;
        $scope.imgNotice = '../images/screenshot-prepare-de.jpg';
        $scope.screenshotnotAvailable = false;

        $scope.hasDayViewFeature = true;
        $scope.overlayText = '';
        $scope.overlayUrl = '';

        $scope.activityKind = '1'; //default
        $scope.activityApps = [];
        $scope.errBodyMax = false;
        $scope.hasRoamingVals = false;
        $scope.timebox = {};

        $scope.isLoadingTotal = true;

        //First of all run watcher to check if state should be cc-dashboard or cc-dashboard-v3
        // ------------------------------------------------------------------------------------------
        $rootScope.$watch(function () {
            return AuthFactory.activeDevice;
        }, function (nv, ov) {
            if (AuthFactory.activeDevice.versionInfo && AuthFactory.activeDevice.versionInfo.indexOf('Android 10.0')>0) {
                $scope.screenshotnotAvailable=true;
            }
            if (AuthFactory.activeDevice.versionInfo && AuthFactory.activeDevice.versionInfo.indexOf('Android 10.0')==-1) {
                $scope.screenshotnotAvailable=false;
            }

            if ((nv && !ov) || (nv && ov && nv!=ov)) {
                $log.info('CB-DASH-V3 WATCHER activeDevice=', AuthFactory.activeDevice);

                if (AuthFactory.activeDevice.deviceType==30) {
                    scgGlobalSpinnerFactory.unblockUI();
                    $state.go("cb-dashboard-v3", {reload: true});
                }
                if (AuthFactory.activeDevice.deviceType==10) {
                    scgGlobalSpinnerFactory.unblockUI();
                    $state.go("cb-dashboard", {reload: true});
                }
                if (AuthFactory.activeDevice.deviceType==40) {
                    scgGlobalSpinnerFactory.unblockUI();
                    $state.go("sc-dashboard", {reload: true});
                }
                if (AuthFactory.activeDevice.deviceType==50) {
                    scgGlobalSpinnerFactory.unblockUI();
                    $state.go("sb-dashboard", {reload: true});
                }


            }
        }, true);
        // ------------------------------------------------------------------------------------------


        $log.info('dash-v3 init, activeDevice is', AuthFactory.activeDevice);

        scgSharedFactory.getLastSync().then(function success(lastSync) {
            $scope.lastSync = scgSharedFactory.formatDateTime(lastSync);
            $scope.lastSyncShort = scgSharedFactory.formatDateTimeShort(lastSync);
            $log.info('LASTSYNC ONCE', lastSync);
            $log.info('LASTSYNCSHORT ONCE', $scope.lastSyncShort);

            //Case Sync never successful
            //COMMENT OUT BECAUSE OF REINSTALL ISSUE WITHOUT LICENCE
            /*if (moment(lastSync) < moment('2002-01-01') && scgSharedFactory.syncProbShown == false) {
                $log.info('LASTSYNC PROBLEM !!! SHOW NOTICE', scgSharedFactory.syncProbShown);
                scgSharedFactory.syncProbShown = true;    //Globale Variable innerhalb scgSharedFactory, beachte $scope ganz oben!
                //appInsights.trackEvent("Modal: SyncProb");
                modalFactory.okModal(1, $translate.instant('MSG.SYNCPROB1') + '<br><br>' + $translate.instant('MSG.SYNCPROB2'));
            }*/
            //eof special case
        }); //eof getLastsync()


        $scope.isIOS = false;
        $log.info('CB-DashboardController: Active Device');
        $log.info(AuthFactory.activeDevice);

        DeviceFactory.getDeviceById(AuthFactory.activeDevice.deviceId).then(
            function success(device) {
                $log.info('DEVICENAME=', device.deviceName);
                //Check here for a deviceWarning
                var ourdevice = device.deviceName.toLowerCase();
                if (device.deviceName.toLowerCase().indexOf('huawei') != -1) {
                    $scope.deviceWarning = "Huawei"
                    $scope.deviceWarningURL = $translate.instant('URL.FAQ_HUAWEI');
                }

                $log.info('THIS IS OURDEVICE:', ourdevice);
                if (device.deviceType === 11) {
                    $log.info('CB-DashboardController: IOS Check : is IOS Device');
                    $scope.isIOS = true;
                }
            }
        );

        $scope.syncNow = function () {
            $state.go('cb-sync');
        };

        $scope.showUsage = function () {
            $state.go("cb-usage.reportsv2", {periodKind: $scope.activityKind});
        };

        $scope.showSettings = function () {
            $state.go('cb-settings');
        };

        $scope.turnOff = function () {
            $log.info('TURN OFF!');

            modalFactory.infoModal.open({
                modal_settings: {
                    title: $translate.instant('MSG.QUESTION'),
                    message: $translate.instant('INFO.TURN_OFF_NOTICE') + " " + $translate.instant('MSG.SURE'),
                    butOkLabel: $translate.instant('BUTTON.YES'),
                    butCancelLabel: $translate.instant('BUTTON.NO'),
                    iconNo: 2,
                    refreshStateAfterClose: false //Dierk: bei true wurde controller gleich neu geladen werden
                },
                callbacks: {
                    ok: function () {
                        $log.info('yes, ok was click, he/she is sure');
                        scgSharedFactory.logMeCB('Web Portal: ' + $translate.instant('MSG.OFF_SUCCESS'));
                        $scope.data.blockTimesBrowser = scgSharedFactory.blockOff();
                        $scope.data.isTimeLimitActive = false;
                        $scope.data.isWeekDayLimitActive = false;
                        //aus diesen beiden folgt:
                        $scope.data.isTimeActive = false;
                        $scope.data.isAppFilterActive = false;
                        $scope.data.isWebFilterActive = false;
                        $scope.data.isEmailReportActive = false;
                        $scope.data.isSomeActive = false;

                        //set also new devicelimits to false!
                        $scope.data.isTimeLimitActiveDevice = false;
                        $scope.data.isWeekDayLimitActiveDevice = false;
                        $scope.data.blockTimesDevice = scgSharedFactory.blockOff();
                        $scope.onOrOff = $translate.instant('ALL.OFF');
                    },
                },
                cancel: function () {
                    $log.info('canceled!');
                }
            });
        };

        $scope.setDayChartAvailable = function(status) {
            if (status) {
                $scope.overlayText = '';
                $scope.overlayUrl = '';
            } else {
                $translate('INFO.UPDATE_FOR_FEATURE').then(function(translated) {
                    $scope.overlayText = translated;
                });
                $translate('URL.UPDATE_CB').then(function(translated) {
                    $scope.overlayUrl = translated;
                });
            }
        }

        $scope.turnOn = function () {
            $log.info('TURN ON!');
            $scope.data.isAppFilterActive = true;
            $scope.data.isWebFilterActive = true;
            $scope.isSomethingActive();
            scgSharedFactory.logMeCB('Web Portal: ' + $translate.instant('MSG.ON_SUCCESS'));
            modalFactory.infoModal.open({
                modal_settings: {
                    title: $translate.instant('HEADER.INFO'),
                    message: $translate.instant('MSG.CBTURNEDON'),
                    butOkLabel: $translate.instant('BUTTON.OK'),
                    butCancelLabel: null,
                    iconNo: 1,
                    refreshStateAfterClose: true
                },
                callbacks: {
                    ok: function () {
                        $log.info('modal closed with ok -> callback');
                    }
                }
            });
        };

        $scope.untilOff = function () {
            $log.info("untilOff, untilAction is now="+$scope.data.untilAction);
            $scope.data.untilAction=0;      //watcher should trigger this change
            $scope.statusInfo.untilAction=0;        //necessary to refreash information

            //manual sync
            var changed_object = {'untilAction':0};
            cbDashboardFactory.setSetting(changed_object);
            scgSharedFactory.needsSync = true;
            scgSharedFactory.syncCBSilent();

        };


        $scope.comingSoonMsg = function() {
            modalFactory.infoModal.open({
                modal_settings: {
                    title: $translate.instant('HEADER.INFO'),
                    message: $translate.instant('MSG.COMINGSOON'),
                    butOkLabel: $translate.instant('BUTTON.OK'),
                    butCancelLabel: null,
                    iconNo: 1,
                    refreshStateAfterClose: true
                },
                callbacks: {
                    ok: function () {
                        $log.info('modal closed with ok -> callback');
                    }
                }
            });
        };



        $scope.isSomethingActive = function () {
            //Berrechnet für den TurnON TurnOff Switch
            if ($scope.data.isTimeActive || $scope.data.isWebFilterActive || $scope.data.isAppFilterActive || $scope.data.isEmailReportActive) {
                $scope.data.isSomeActive = true;
            } else {
                $scope.data.isSomeActive = false;
            }
            if ($scope.data.isSomeActive) {
                $scope.onOrOff = $translate.instant('ALL.ON');
            } else {
                $scope.onOrOff = $translate.instant('ALL.OFF');
            }
        };

        $scope.showUsages = function () {
            $state.go('cb-usage.reportsv2');
        };



        $scope.setExtText = function (extTime) {
            $log.info('setExtText, extTime=', extTime);
            if (extTime > 0) {
                $scope.extTimeInfo = $translate.instant('ALL.ACTIVE');
            } else {
                $scope.extTimeInfo = $translate.instant('ALL.NONE');
            }
        }


        $scope.sendMsgV3 = function (msg) {
            $log.info('sendMsg v3 msg=' + msg);
            cbDashboardFactory.sendMsg(msg)
                .then(function success() {
                    $log.info('sendMsg v3 SUCCESS');
                }, function error() {
                    $log.info('sendMsg ERROR');
                });

        };

        scgSharedFactory.isCBSecurity().then(function (returnvalue) {
            $scope.isCBSecurity = returnvalue;
        });


        $scope.getTotalTimesDay = function() {
            $scope.isLoadingTotal = true;
            var today = moment().format('YYYY-MM-DD');
            cbUsageFactory.getTotalTimesDay(today).then(function success(response) {
                $scope.timebox = response.data;
                $scope.isLoadingTotal = false;
            });
        }

        $scope.getCBSettings = function() {
            cbDashboardFactory.getCBSettings()
                .then(function success(response) {
                    $scope.data = response.data;
                    $log.info('ExtDev giveExt=', $scope.data);
                    scgGlobalSpinnerFactory.unblockUI();
                    if (AuthFactory.activeDevice.deviceType == 30) {
                        $scope.cbMajor == 3;
                    }

                    $scope.data.isTimeActive = false;       //Das feld hier wird berrechnet aus den beiden Limits!
                    if ($scope.data.isTimeLimitActive ||
                        $scope.data.isWeekDayLimitActive ||
                        $scope.data.isTimeLimitActiveDevice ||
                        $scope.data.isWeekDayLimitActiveDevice ||
                        $scope.data.blockTimesBrowser != scgSharedFactory.blockOff() ||
                        $scope.data.blockTimesDevice != scgSharedFactory.blockOff()) {
                        $scope.data.isTimeActive = true;
                    }
                    $scope.isSomethingActive();
                    $scope.howManyUnlockRequests = -1;

                    if ($scope.data.blockTimesDevice != scgSharedFactory.blockOff()) {
                        $scope.data.isBlockTimesDevice = true;
                    } else {
                        $scope.data.isBlockTimesDevice = false;
                    }

                    if ($scope.data.isLatestVersion === false) {
                        $scope.needUpdate = true;
                    } else {
                        $scope.needUpdate = false;
                    }
                    if ($scope.data.datetimehack == true) {
                        $scope.dateHack = true;
                    }


                    //Extension handling for CB
                    $scope.giveExt = $scope.data.giveExt;
                    if ($scope.giveExt > 0 || $scope.data.currentextensionmin > 0) {
                        $scope.setExtText(1); //1 is dummy for show extension "Active"
                    } else {
                        $log.info('extSetText A0');
                        $scope.setExtText(0); //0 is dummy for show extension "None"
                    }

                    if ($scope.data.roaming != null && $scope.data.roaming != '' && $scope.data.romaing != 'undefined') {
                        scgSharedFactory.hasRoaming = true;
                    } else {
                        scgSharedFactory.hasRoaming = false;
                    }


                    cbDashboardFactory.getTodayTotal(moment(new Date()).format('YYYY-MM-DD'))
                        .then(function success(response) {
                                $scope.todayTotal = $translate.instant('USAGE.TODAYTOTAL') + ': ' + response.data;
                            }
                        );

                    cbDashboardFactory.getUnlockRequests()
                        .then(function success(response) {
                                $scope.howManyUnlockRequests = response.data.length;
                            }
                        );
                });
        }

            //add watchers
            _.each($scope.data, function (v, k) {
                $scope.$watch('data.' + k, function (new_val, old_val) {
                    if (new_val != old_val) {
                        var changed_object = {};
                        changed_object[k] = new_val;
                        //ORIG
                        cbDashboardFactory.setSetting(changed_object);
                        $log.info('DATA CHANGED and saved');
                        $log.info('needsSync true C untilTmp');
                        scgSharedFactory.needsSync = true;
                    }
                }, true);
            });


        $scope.showUnlock = function () {
            $state.go('cb-usage.unblockv2');
        };


        setTimeout(function () {
            $log.info('Refreash lastsync here');
            scgSharedFactory.getLastSync().then(function (funcresult) {
                //Dierk: Callback! Must be done this way
                $scope.lastSync = scgSharedFactory.formatDateTime(funcresult);
            });
        }, 5000);

        $log.info('WATCHER DASHBOARD licence entry');

        $rootScope.$watch(function () {
            return AuthFactory.activeLicence;
        }, function (nv) {
            if (nv) {
                $log.info('DASHBOARD sb licence watcher running', AuthFactory.activeLicence);
                if (AuthFactory.activeLicence.isTrial | AuthFactory.activeLicence.expireDays < 10) {
                    $log.info('DASHBOARD is trial or remaining<10 is TRUE');
                    $scope.hasNotice = true;
                    $scope.hasNoticeSerial = AuthFactory.activeLicence.serial;
                    if (typeof AuthFactory.activeLicence.isTrial != 'undefined') {
                        var remaining = AuthFactory.activeLicence.expireDays;
                        if (remaining < 0) {
                            remaining = 0;
                        }
                        if (AuthFactory.activeLicence.isTrial) {

                            $log.info("EXPIRE Expiredate: " + AuthFactory.activeLicence.expireDate);
                            $log.info("EXPIRE NowDate: " + moment().format('YYYY-MM-DD'));

                            $scope.noticeText = $translate.instant('MISC.TRIALVERSION') + ': ' + $translate.instant('MISC.REMAINING').replace('XXX', remaining);

                            //Change noticeText if expireDate is in future (in this case REDIS licence is still somehow set to "f"
                            //Dierk Commented out because of not working correctly (why is this displayed for isTrial=true?!)
                            /*if (AuthFactory.activeLicence.expireDate && (moment().diff(AuthFactory.activeLicence.expireDate,'days')*-1)>1) {
                                $scope.noticeText = $translate.instant('MISC.LICENCEISSUE');
                                $log.info("EXPIRE IN FUTURE!!!" + $scope.noticeText);
                            }*/

                        }
                        if (!AuthFactory.activeLicence.isTrial) {
                            $scope.noticeText = $translate.instant('MISC.FULLVERSION') + ': ' + $translate.instant('MISC.REMAINING').replace('XXX', remaining);
                        }
                    } else {
                        $scope.hasNotice = false; //is undefined!
                    }
                } else {
                    $scope.hasNotice = false;
                }
            }
        }, true);

        $scope.testStuff = function () {
            //alert('Test stuff');
            //appInsights.trackEvent("testStuff fired");
            errorDisplayFactory.addError({
                'message': 'MyError',
                'details': 'I am the error detail'
            });

        };

        $scope.delCBExtension = function () {
            cbDashboardFactory.delExt()
                .then(function success(response) {
                        $log.info("CB extensideleted, response=", response.data);
                        $scope.data.giveExt = 0;
                        $scope.giveExt = 0;
                        $log.info('ext delCBExtension');
                        $scope.setExtText($scope.givenExt);
                        cbDashboardFactory.syncNow()
                            .then(function success(response) {
                                $log.info("CB extension syncNow, response=", response.data);
                                $state.go($state.current, {}, {reload: true}); //refresh page
                            })
                    }
                );

        }

        //This is CB2 extension routine
        $scope.setExtCB = function() {
            $log.info('setExtCB triggered!');
            modalInstance = $modal.open({
                animation: false,
                templateUrl: 'templates/chicobrowser/cb_dashboard/templates/dashboard-modal-extension.html',
                windowTemplateUrl: 'templates/chicobrowser/cb_dashboard/templates/dashboard-modal-window-small.html',
                scope: $scope
            });

        }

        //Here extension will be set in database (called by the modal)
        $scope.doExtensionCB = function(extensionTime) {
            modalInstance.dismiss('cancel');
            var extTimeMinutes = 0; //default
            if (typeof(extensionTime) == 'number') {
                $log.info("We are number");
                extTimeMinutes = extensionTime;
            } else {
                $log.info("We are string");
                extTimeMinutes = moment.duration(extensionTime, 'minutes').format('mm');
            }
            $log.info('doExtensionCB with extTimeMinutes=' + extTimeMinutes);
            $scope.data.giveExt = extTimeMinutes; //this for table database binding
            $scope.giveExt = extTimeMinutes; //this for immediate
            $scope.setExtText($scope.giveExt);
            if (extTimeMinutes>0) {
                $log.info('needsSync true B');
                scgSharedFactory.needsSync=true}
        };


        //This is CB3 extension routine
        $scope.setExtension = function () {
            $log.info('setExtension triggered!');
            modalInstance = $modal.open({
                animation: false,
                templateUrl: 'templates/chicobrowser/cb_dashboard/templates/dashboard-modal-extension-cb3.html',
                windowTemplateUrl: 'templates/chicobrowser/cb_dashboard/templates/dashboard-modal-window-small.html',
                scope: $scope
            });
        }

        //This is CB3 extension routine
        $scope.showFAQTimes = function () {
            $log.info('show FAQ Times');
            modalInstance = $modal.open({
                animation: false,
                templateUrl: 'templates/chicobrowser/cb_dashboard/templates/dashboard-modal-faqtimes.html',
                windowTemplateUrl: 'templates/chicobrowser/cb_dashboard/templates/dashboard-modal-window-small.html',
                scope: $scope
            });
        }

        $scope.formatAzTime = function(aztime) {
            //$log.info("aztime init=", aztime);
            var date = new Date(aztime);
            //$log.info("aztime new date=", date);
            return scgSharedFactory.formatDateTimeShort(aztime);
        }

        //Here extension will be set in database (called by the modal)
        $scope.doExtension = function (extensionTime) {
            modalInstance.dismiss('cancel');
            var extTimeMinutes = 0; //default
            if (typeof(extensionTime) == 'number') {
                $log.info("We are number");
                extTimeMinutes = extensionTime;
            } else {
                $log.info("We are string");
                extTimeMinutes = moment.duration(extensionTime, 'minutes').format('mm');
            }
            $log.info('doExtensionCB with extTimeMinutes=' + extTimeMinutes);
            cbDashboardFactory.setExtension(extTimeMinutes)
                .then(function success(response) {
                        //update extension again
                        getStatusInfo();
                    }
                );
        };

        $scope.checkBodyMaxErr = function() {
            cbDashboardFactory.getBodyMaxErr().then(function success(response){
                $scope.errBodyMax = response.data.errbodymax;
            })
        }

        $scope.adjustBlockTimeSettings = function()
        {
            modalInstance.dismiss('cancel');
            $state.go('cbtime.devicetime');
        }


        //do we have DeviceLimit
        AuthFactory.getVersionInfo().then(function (version) {

            var myversion = version.VersionInfo.substring(0, 9);
            myversion = myversion.replace("2.1.", "");
            myversion = myversion.replace("3.1.", "");
            $log.info("version after replace is:", myversion);
            if (myversion > 1043) {
                $scope.hasFeatureDeviceLimit = true;
            }

            //Android 8 issues resolved
            /*if (version.VersionInfo && version.VersionInfo.indexOf('Android 8')!=-1) {
                $scope.Android8Notice=true;
            }*/
        });

        //Logbook Teaser
        scgSharedFactory.getLogbookTeaserV2().then(function (funcresult) {
            $scope.logbookTeaser = funcresult.data;
        });

        //$scope.serverTimeDiff = scgSharedFactory.getServerTimeDiff();
        $log.info("server diff hours=" + $scope.serverTimeDiff);


        scgSharedFactory.getServerTimeDiff().then(function (funcresult) {
            $scope.serverTimeDiff = funcresult;
        });




        //Call StatusInfo directly OnLoad
        getStatusInfo();
        $log.info('getStatusInfo onLoad');

        $scope.getScreenshot = function() {
            $log.info('screenshot');
            $scope.comingSoonMsg();
        };





        $scope.deleteDevicetimeWarning = function() {
            cbDashboardFactory.deleteDevicetimeWarning().then(function success(response) {
                $scope.deviceTimeWarning=false;
            });
        }


        //Recurring Timer, update each 30 Seconds statusInfo!
        //---------------------------------------------------
        var intervalStatusInfo = $interval(function () {
            getStatusInfo();
        }, 30 * 1000);


        function getStatusInfo() {

            cbDashboardFactory.getStatusInfo().then(function success(response) {
                if (response.data) {
                    $scope.statusInfo = response.data;
                    $scope.extensionTime = response.data.extTime;

                    if (response.data.deviceTimeWarning!=null) {
                        $scope.deviceTimeWarning = response.data.deviceTimeWarning;
                    }

                    $log.info("untilaction ACTIVE CB3 in statusinfo" , response.data);
                    if (response.data.lastAzDateTime==null) {
                        $log.info("untilaction CANCELED because of null lastAzDateTime!");
                        $scope.spinnerMain = false;
                        return;
                    }

                    $scope.data.untilAction=$scope.statusInfo.untilAction;
                    $scope.data.untilDateTime=$scope.statusInfo.untilDateTime;

                    var statustime = null;
                    var lastTimeFormated = null;

                    if ($scope.statusInfo.lastAzDateTime!=null) {
                        statustime = $scope.statusInfo.lastAzDateTime.substring(0,10);
                        lastTimeFormated = moment($scope.statusInfo.lastAzDateTime).format('HH:mm');
                    }
                    var browsertime =  moment().format('YYYY-MM-DD');
                    var a = moment(statustime);
                    var b = moment(browsertime);
                    var timeDiff = b.diff(a, 'days');

                    $scope.untilPassed=false;
                    if (moment().isSameOrAfter(moment($scope.data.untilDateTime).format("YYYY-MM-DD HH:mm:ss"))) {
                        $scope.untilPassed=true;
                    }

                    $log.info('status browsertime=' + browsertime + "|statustime=" + statustime + "|timeDiff=" + timeDiff + "|formated=" + lastTimeFormated + 'untilPassed=' + $scope.untilPassed);

                    //New time calculation for caption today, yesterday, two day ago etc.
                    if (statustime!=null && browsertime!=null) {
                        $scope.timeDiff = timeDiff;
                        if (statustime == browsertime) {
                            $scope.timeTop3 = $translate.instant('USAGE.LAST_TODAY');
                            $scope.timeDiff = 0;
                            $scope.timeBoxCaption = $translate.instant('USAGE.LAST_TODAY') + ", " + lastTimeFormated
                        }
                        
                        if (timeDiff == 1) {
                            $scope.timeTop3 = $translate.instant('USAGE.LAST_YESTERDAY');
                            $scope.timeBoxCaption = $translate.instant('USAGE.LAST_YESTERDAY') + ", " + lastTimeFormated
                        }
                        
                        if (timeDiff == 2) {
                            $scope.timeTop3 = $translate.instant('USAGE.LAST_EREYESTERDAY');
                            $scope.timeBoxCaption = $translate.instant('USAGE.LAST_EREYESTERDAY') + ", " + lastTimeFormated
                        }
                        
                        if (timeDiff > 2) {
                            if ($translate.use() == 'de_DE') {
                                $scope.timeTop3 = "Vor " + timeDiff + " Tagen";
                                $scope.timeBoxCaption = "Vor " + timeDiff + " Tagen, " + lastTimeFormated;
                            } else {
                                $scope.timeTop3 = timeDiff + " days ago";
                                $scope.timeBoxCaption = timeDiff + " days ago, " + lastTimeFormated;
                            }
                        }
                        if (timeDiff > 30) {
                            if ($translate.use() == 'de_DE') {
                                $scope.timeTop3 = " vor mehr als 30 Tagen";
                                $scope.timeBoxCaption = "Vor mehr als 30 Tagen...";
                            } else {
                                $scope.timeTop3 = " more than 30 days ago";
                                $scope.timeBoxCaption = "More than 30 days ago...";
                            }
                        }
                    }

                    if (statustime!=null && browsertime!=null && statustime!==browsertime) {
                        $scope.hasOldTime=true;
                        $scope.totaltime=0;
                    }

                    //check deviceStatus warnings
                    if ($scope.statusInfo.deviceStatus)
                    {
                        var s = $scope.statusInfo.deviceStatus;
                        if (s.length>=3 && s.substring(0,1)==="0") $scope.offDeviceAdmin=true;
                        if (s.length>=3 && s.substring(0,1)==="1") $scope.offDeviceAdmin=false;
                        if (s.length>=3 && s.substring(1,2)==="0") $scope.offAccessibility=true;
                        if (s.length>=3 && s.substring(1,2)==="1") $scope.offAccessibility=false;
                        if (s.length>=3 && s.substring(2,3)==="0") $scope.offUsageStats=true;
                        if (s.length>=3 && s.substring(2,3)==="1") $scope.offUsageStats=false;
                        if (s.length>=4 && s.substring(3,4)==="0") $scope.offPopupDisplay=true;
                        if (s.length>=4 && s.substring(3,4)==="1") $scope.offPopupDisplay=false;
                    }

                    $scope.deviceHint = ""; //default
                    $scope.deviceHintUrl = ""; //default
                    if ($translate.use() == 'de_DE' && $scope.statusInfo && $scope.statusInfo.manufacturer) {
                        $log.info('Manufacturer=' + $scope.statusInfo.manufacturer);
                        if ($scope.statusInfo.manufacturer.indexOf('HUAWEI') != -1 || $scope.statusInfo.manufacturer.indexOf('HONOR') != -1) {
                            $log.info('We have HUAWEI!');
                            $scope.deviceHint = "HUAWEI/HONOR";
                            $scope.deviceHintUrl = "https://salfeld.de/faq/android/huawei/";
                        }
                        if ($scope.statusInfo.manufacturer.indexOf('WIKO') != -1) {
                            $scope.deviceHint = "WIKO";
                            $scope.deviceHintUrl = "https://salfeld.de/faq/android/wiko/";
                        }
                        if ($scope.statusInfo.manufacturer.toUpperCase().indexOf('XIAOMI') != -1) {
                            $scope.deviceHint = "Xiaomi";
                            $scope.deviceHintUrl = "https://salfeld.de/faq/android/xiaomi/";
                        }
                        if ($scope.statusInfo.manufacturer.toLowerCase().indexOf('moto g(8') != -1) {
                            $scope.deviceHint = "Motorola";
                            $scope.deviceHintUrl = "https://docs.salfeld.net/faq-android.html#spezial";
                        }
                        if ($scope.statusInfo.manufacturer.toLowerCase().indexOf('blackview') != -1) {
                            $scope.deviceHint = "Blackview";
                            $scope.deviceHintUrl = "https://docs.salfeld.net/faq-android.html#spezial";
                        }
                        if ($scope.statusInfo.manufacturer.toLowerCase().indexOf('ulefone') != -1) {
                            $scope.deviceHint = "Ulefone";
                            $scope.deviceHintUrl = "https://docs.salfeld.net/faq-android.html#spezial";
                        }
                    }

                    //special case: UntilAction 2 or 3 but now in past
                    var browserDateTime =  moment().format('YYYY-MM-DD hh:mm:ss');
                    if ($scope.statusInfo!=null && $scope.statusInfo.untilDateTime!=null && browserDateTime!=null && ($scope.statusInfo.untilAction==2 || $scope.statusInfo.untilAction==3)) {
                        var untilDiff = moment(browserDateTime).diff(moment($scope.statusInfo.untilDateTime), 'seconds');
                        $log.info('browserDatetTime=' + browserDateTime + '|untilAction=' + $scope.statusInfo.untilAction + '|untilDateTime=' + $scope.statusInfo.untilDateTime + '|untilDiff=' + untilDiff);
                        if (untilDiff>0) {
                            $scope.statusInfo.untilAction=0;
                        }
                    }

                    //get counted apps
                    var topArr = [];
                    var limit = 3;
                    response.data.apps = _.sortBy(response.data.apps, ['counted']);
                    _.forEach(response.data.apps, function(app, index){
                        if(app.title != 'total' && topArr.length < limit){
                            $scope.hasAppData=true;
                            topArr.push(app);
                        }
                    });
                    var countedSum = topArr[0]?topArr[0].counted:0;
                    $scope.statusInfoApps = {apps:topArr, fullBarSum:countedSum};
                    $scope.showActivity();  //Refresh also Top3 apps, DEV: this here is timer routine

                    $log.info('statusinfo debug:',$scope.statusInfo);



                    $scope.spinnerMain = false;
                }
            });
        }


        //Watcher for CB3 (necessary if page reload button has been hit)
        $scope.$watch(function() {
            return AuthFactory.activeDevice;
        }, function(activeDevice, prevActiveDevice) {
            $log.info('getParentsInfo In Controller', activeDevice, prevActiveDevice);
            if (activeDevice.deviceType==30) {
                $scope.cbMajor=3;
            } else {
                if( activeDevice.deviceType ){
                    $scope.cbMajor=2;
                }
            }

            if(activeDevice && activeDevice.deviceId && activeDevice.deviceName){
                $log.info('GetParentsInfo In Watcher, deviceName=' + activeDevice.deviceName);
                $scope.headlineDevice = activeDevice.deviceName;
                getStatusInfo();
                $scope.showActivity();
            }

        },true);


        $scope.doUntilAction = function(defaultRadio) {

            $log.info('doUntilAction CB with defaultRadio=' + defaultRadio + '|neverBlockAlwaysAllowed=' + $scope.data.neverBlockAlwaysAllowed);
            modalFactory.untilModal.open({
                modal_settings: {
                    defaultRadio: defaultRadio,
                    hasNeverBlockAlwaysAllowedFeature : scgSharedFactory.hasCBFeature('misc2020-1'),
                    neverBlockAlwaysAllowed : $scope.data.neverBlockAlwaysAllowed,
                    refreshStateAfterClose: false
                },callbacks: {
                    ok: function () {
                        $log.info('modal-until ok callback');
                        $state.reload();
                    }
                }
            });
        }


        $scope.getAppIconFile = function (apk) {
            return "https://portal.salfeld.net/files/icon/apps/sess887783995a9d401681c6567398227aac/" + apk + ".png";
        }


        $scope.showWeekdayLog = function(weekday)
        {
            $state.go("cb-usage.reportsv2", {weekday: weekday});
        }

        $scope.roamingSettings = function() {
            $log.info("roaming settings");
            //modalFactory.okModal(1, $translate.instant('MSG.SYNCPROB1') + '<br><br>' + $translate.instant('MSG.SYNCPROB2'));
            modalFactory.roamingModal.open({
                modal_settings: {
                    refreshStateAfterClose: false
                },callbacks: {
                    ok: function () {
                        $log.info('modal-roaming ok callback');

                        //we need little time before refresh/reload to save the roaming setttings
                        scgGlobalSpinnerFactory.blockUI();
                        $timeout( function(){
                            $log.info('modal-roaming reload');
                            scgGlobalSpinnerFactory.unblockUI();
                            $state.reload();
                        }, 2000 );


                    }
                }
            });
        }

        $scope.sendMsg = function (msg) {
            $log.info('sendMsg msg=' + msg);
            cbDashboardFactory.sendMsg(msg)
                .then(function success() {
                    $log.info('sendMsg SUCCESS');
                    //$scope.reload();
                }, function error() {
                    $log.info('sendMsg ERROR');
                    //$scope.reload();
                });

        };


           $scope.lookupAppV2 = function(pkg,appname) {
            $log.info('lookupAppV2 for url=' + pkg);

        }



        $scope.lookupTopApp = function(pkg,appname,kind) {
            $log.info('lookupApp for url=' + pkg);
            if (kind==4) {
                $state.go('detail-view', {
                    appIds: [appname],
                    appType: chicoEnums.AppTypeEnum.APP,
                    allowAdd: false,
                    prevStateName: $state.current.name
                });
            }
            if (kind==22) {
                $state.go('detail-view', {
                    appIds: [appname],
                    appType: chicoEnums.AppTypeEnum.WEBSITE,
                    allowAdd: false,
                    prevStateName: $state.current.name
                });
            }
        }

        $rootScope.$watch(function () {
            return AuthFactory.activeDevice.versionInfo;
        }, function (nv) {
            if (nv) {
                $log.info('WATCHER FOR xx VERSION=',nv);
                $scope.getTotalTimesDay();
                $scope.getCBSettings();
                $scope.scgSharedFactory.checkForUpdateNotice(nv);
                $scope.hasDayViewFeature=$scope.scgSharedFactory.hasCBFeature('dayChart');
                $scope.setDayChartAvailable($scope.hasDayViewFeature);
                $scope.checkBodyMaxErr();
            }
            });

            $scope.$on("$destroy", function() {
            $interval.cancel(intervalStatusInfo);
        });


        //UARGH, jquery for modal clear
        $(document).ready(function() {

            $("#messageModal").on("hidden.bs.modal", function() {
                $log.info('modal hide');
                $(".msgtext").val("");
            });
        });



        $scope.android8Issues = function() {
            $log.info('Android 8 uissues');
            modalFactory.infoModal.open({
                modal_settings: {
                    title: $translate.instant('ISSUES.ANDROID_O_HEADLINE'),
                    message: $translate.instant('ISSUES.ANDROID_O_MSG'),
                    butOkLabel: $translate.instant('BUTTON.OK'),
                    butCancelLabel: null,
                    iconNo: 1,
                    refreshStateAfterClose: false
                },
                callbacks: {
                    ok: function () {
                        //nothing special
                    }
                }
            });
        }

        $scope.testloginlocked = function() {
            $log.info('test loginlocked');

        }

        $scope.gotoUsage = function() {
            $log.info('gotoUsage!');
            $scope.spinnerMain=true;
            //scgGlobalSpinnerFactory.blockUI();
            $state.go("cb-usage.reportsv2", {reload: true});
        }

        $scope.setExtensionV2 = function() {
            $log.info('setExtensionV2');

        }

        $scope.resetManipulation = function() {
            $log.info('resetManipulation');
            cbDashboardFactory.resetManipulation().then(function success(response) {
                $scope.data.isHackScreen=false;
                modalFactory.okModal(1, $translate.instant('MISC.HACK_RESET_OK') + '<br><br>'+ $translate.instant('MISC.HACK_RESET_PS'));
            });
        }

        $scope.resetDateHack = function() {
            $log.info('resetDateHack');
            cbDashboardFactory.resetDateHack().then(function success(response) {
                $scope.dateHack=false;
            });
        }



        //infomdal for re-use in this scope
        $scope.myInfoModal = function(msg) {
            scgGlobalSpinnerFactory.unblockUI();
            modalFactory.infoModal.open({
                modal_settings: {
                    title: $translate.instant('HEADER.INFO'),
                    message: msg,
                    butOkLabel: $translate.instant('BUTTON.OK'),
                    butCancelLabel: null,
                    iconNo: 1,
                    refreshStateAfterClose: true
                },
                callbacks: {
                    ok: function() {
                        $log.info('modal closed with ok -> callback');
                    }
                }
            });
        };


        //Is called from Live functions
        $scope.doScreenshot = function() {
            $log.info('doScreenshot');
            if ($scope.data.screenshotRightSuccess==false) {
                $scope.showPrepare();
            } else {
                $scope.takeScreenshot();
            }
        }

        //Do not rename, is also called from modal in screenshot-modal-prepare in cbScreenshots controller
        $scope.takeScreenshot = function() {

            $log.info('cbshots proceed');
            scgGlobalSpinnerFactory.blockUI();
            $scope.pollDone = false;
            cbScreenShotFactory.screenshot()
                .then(function success(response) {
                    $log.info('screenshot response:' , response.data);
                    scgGlobalSpinnerFactory.unblockUI();

                    if (response.data.success == true) {
                        if (response.data.id !== null && response.data.id == 'NO_SESSION') {
                            $scope.myInfoModal($translate.instant('MSG.NOSESSION'));
                            return;
                        }
                    }
                    if (response.data.success == false) {
                        if (response.data.id !== null && response.data.id == 'SCREENSHOT_TIMEOUT') {
                            $scope.myInfoModal($translate.instant('SCREENSHOT.TIMEOUT'));
                            return;
                        }
                    }
                    //we have the file, display it
                    var rnd = Math.floor(Math.random() * (999999 - 100001)) + 100001; //login controller because of NO AUTH for uriScreenshot, rnd against caching
                    $scope.uriScreenshot = "http://portal.salfeld.net/api/login/getscreen/" + rnd + "/" + response.data.id;
                    $log.info('uriScreenshot:' + $scope.uriScreenshot); //used by <img src> in modal
                    $scope.data.screenshotRightSuccess=true;
                    $scope.showScreenshot();
                });
        };


        // ------------ SCREENSHOTS ----------

        $scope.showPrepare = function() {
            $log.info('prepare screenshot');
            $modal.open({
                animation: false,
                templateUrl: 'templates/chicobrowser/cb_screenshots/templates/screenshots-modal-prepare.html',
                windowTemplateUrl: 'templates/chicobrowser/cb_screenshots/templates/screenshots-modal-window.html',
                scope: $scope
            });
        };

        $scope.showPrepareFinal = function() {
            $log.info('prepare screenshot');
            $modal.open({
                animation: false,
                templateUrl: 'templates/chicobrowser/cb_screenshots/templates/screenshots-modal-prepare-final.html',
                windowTemplateUrl: 'templates/chicobrowser/cb_screenshots/templates/screenshots-modal-window.html',
                scope: $scope
            });
        };



        $scope.showScreenshot = function() {
            modalInstance = $modal.open({
                animation: false,
                templateUrl: 'templates/chicobrowser/cb_screenshots/templates/screenshots-modal-screenshot.html',
                windowTemplateUrl: 'templates/chicobrowser/cb_screenshots/templates/screenshots-modal-window.html',
                scope: $scope
            });
        };

        // NEW ACTIVITY STUFF
        $scope.showActivity = function() {
            $log.info('showActivity for kind=' + $scope.activityKind);

            //set defaults to today
            var fromDate = moment().format('YYYY-MM-DD');
            var toDate = fromDate;

            if ($scope.activityKind=='2') {
                fromDate = moment().startOf('isoWeek').format('YYYY-MM-DD');
                toDate = moment().endOf('isoWeek').format('YYYY-MM-DD');
            }
            if ($scope.activityKind=='3') {
                fromDate = moment().startOf('month').format('YYYY-MM-DD');
                toDate = moment().endOf('month').format('YYYY-MM-DD');
            }

            if ($scope.hasDayViewFeature==false && $scope.activityKind=='1') {
                $scope.setDayChartAvailable(false);
                $log.info('showActivity SET TO FALSE');
            }  else {
                $scope.setDayChartAvailable(true);
            }

            $scope.getTotalTimesDay(fromDate);
            $scope.getChartData(fromDate,toDate);
            $scope.getActivityTopApps(fromDate,toDate);
        }

        $scope.onChartColClick = function(colIndex, colData) {
            $log.info('onChartColClick');
            $scope.showUsage();
        }

        $scope.getActivityTopApps = function(fromDate,toDate) {
            var howMany = 3;
            cbDashboardFactory.getTopActivity(fromDate,toDate,howMany).then(function(res) {
                $log.info('getActivityApps res.data=', res.data);
                $scope.activityApps = res.data;
            });
        }

        $scope.getChartData = function(fromDate,toDate) {
            var req;
            $scope.loadingActivity = true;
            if (fromDate===toDate) {
                req = cbUsageFactory.getChartDayApi(fromDate);
            } else {
                req = cbUsageFactory.getChartStatsApi(fromDate,toDate);
            }

            req.then(function(res) {
                $scope.loadingActivity = false;
                $scope.hasRoamingVals=false;
                $scope.azDate = [];
                $scope.chartData = [];
                $scope.chartDatasecondary = [];
                $scope.Captions = [];
                $scope.subCaptions = [];

                var total = res.data.length;
                for (var i = 0; i < total; i++) {
                    $scope.chartData.push(res.data[i].percent);
                    if (res.data[i].percentSecondary) {
                        $scope.hasRoamingVals=true;
                        $scope.chartDatasecondary.push(res.data[i].percentSecondary);
                    } else {
                        $scope.chartDatasecondary.push(0);
                    }
                    var caption = res.data[i].azdate;
                    $scope.azDate.push(res.data[i].azdate);
                    if (fromDate!=toDate && total<=10) caption=moment(res.data[i].azdate, 'YYYY-MM-DD').format('Do. MMM');
                    if (fromDate!=toDate && total>20) caption=moment(res.data[i].azdate, 'YYYY-MM-DD').format('Do.');
                    $scope.Captions.push(caption);
                    var seconds = scgSharedFactory.formatSecs(res.data[i].seconds,true);
                    $log.info('getChartData index=' + i + '|subCaptions=' + seconds);
                    $scope.subCaptions.push(seconds);
                }
                $scope.showTooltips = true;
                if (total<=10) {
                    $scope.showCaptions=true;
                    $scope.showSubCaptions=true;
                } else {
                    $scope.showCaptions=true;
                    $scope.showSubCaptions=false;
                }
                $scope.loadingChart = false;
            });
        };

        // EOF NEW ACTIVITY STUFF

        //watch for language (must be done in watcher)
        $scope.$watch(function() {
            return $cookies.get('language');
        }, function(newVal) {
            $log.info('watcher language='+  $cookies.get('language'))
            if ($cookies.get('language') == 'en_US') {
                $scope.tempLang = 'en';
                $scope.imgNotice = '../images/screenshot-prepare-en.jpg';
            }
        });


        //we are Salfeld Browser, relocate
        if (AuthFactory.activeContext=='sb') {
            $state.go('sb-dashboard');
        }

        //run at startup
        window.scrollTo(0, 0);



    });

