'use strict';
angular.module('ChicoApp.CC-Security')

    .controller('CC-Security-Folders', function(
        $rootScope,
        $scope,
        $state,
        $http,
        $timeout,
        DeviceFactory,
        $log,
        AuthFactory,
        ccListsFactory,
        DTOptionsBuilder,
        DTColumnDefBuilder,
        scgDataTableFactory,
        scgSharedFactory) {

      var modalStartKind = 9; //9=blocked folders

      $scope.tableHeaders = ['Locked Folders'];
      $scope.folderName = '';

      $scope.data = {};
      $scope.data.table_api_urls = [];
      $scope.data.table_api_keywords = [];

      scgDataTableFactory.prepareScopeForTable($scope, modalStartKind);
      $scope.dtOptions = scgDataTableFactory.getDefaultDtOptions($scope);
      $scope.dtColumnDefs = scgDataTableFactory.getDefaultDtColumnDefs3Cols();

      $scope.reload = function() {
        $state.go($state.current, {}, {reload: true});
      };

      var loadList = function() {
        ccListsFactory.getByListFilter(modalStartKind)
                .then(function success(response) {
                  $scope.fillInnerListFromApiList(response.data);
                  $scope.data.table_api_urls = response.data;
                });
      };

      loadList();

      $scope.addFolder = function(myFolder) {
        var postData = []; //array for the new records we will later post
        var record = {};

        record.kind = 9;
        record.entry = myFolder;

        postData.push(record);
        $log.info('postData = ', postData);

        ccListsFactory.postRecords(postData)
                .then(function success() {
                  //if(cb){cb();}
                  scgSharedFactory.needsSync = true;
                  $state.go($state.current, {}, {reload: true});
                  //
                });
      };

    }); //eof controller

