angular.module('ChicoApp.Shared').factory('errorDisplayFactory', function(
    $rootScope,
    _,
    $log,
    $injector) {

  var errors = [];
  var errorModalOpened = false;

  var errorModalHidden = function() {
    errorModalOpened = false;
  };

  return {

    getErrorArray: function() {
      return errors;
    },

    addError: function(error) {

      if( error &&
          error.rejection &&
          error.rejection.status &&
          (error.rejection.status == -1 || error.rejection.status == null || error.rejection.status == undefined) ){
        return;
      }

      errors.push(error);
      $rootScope.$broadcast('errors:changed', null);

      if (errors.length > 0 && errorModalOpened == false) {

        errorModalOpened = true;

        $injector.get('modalFactory').errorModal.open({
          modal_settings: {
            refreshStateAfterClose: false
          },
          callbacks: {
            ok: function() {
              errorModalHidden();
            },
            cancel: function() {
              errorModalHidden();
            }
          }
        });

      }
    },

    removeError: function(error) {
      errors = _.without(errors, _.findWhere(errors, error));
      $rootScope.$broadcast('errors:changed', null);
    },

    clearErrors: function() {
      errors = [];
      $log.info('errors cleared', errors);
      $rootScope.$broadcast('errors:changed', null);
    }

  };

});
