'use strict';
angular.module('ChicoApp.Shared').controller('SCG-Modal-Info-Controller', function(
    $scope,
    $modalInstance,
    modal_settings
) {

  $scope.modal_settings = modal_settings; //transfer settings to scope for use in template

  $scope.cancel = function() {
    $modalInstance.dismiss('cancel');
  };

  $scope.ok = function(return_val) {
    $modalInstance.close(return_val);
  };

});
