'use strict';
angular.module('ChicoApp.CB-Appfilter')

    .controller('CB-Appfilter-Blocked-Controller-V2', function(
      $scope,
      $state,
      $translate,
      cbListsFactory,
      DTOptionsBuilder,
      DTColumnDefBuilder,
      scgDataTableFactory) {

      var modalStartKind = 3;
      $scope.kind = modalStartKind;

      $scope.tableHeaders = [
        $translate.instant('HEADER.ENTRY'),
        $translate.instant('HEADER.DETAILS'),
        $translate.instant('HEADER.INSTALLDATE')
      ];

      $scope.data = {};
      $scope.data.table_api = [];

      scgDataTableFactory.prepareScopeForTable($scope, modalStartKind);

        $scope.dtOptions = scgDataTableFactory.getDefaultDtOptions($scope).withOption('order', [[2, 'asc']]);
        $scope.dtColumnDefs = scgDataTableFactory.getDefaultColumnsIconTable();

        $scope.reload = function() {
        $state.go($state.current, {}, {reload: true});
      };

      var loadList = function() {
        $scope.contentLoading = true;
        cbListsFactory.getDetailedByListFilter(modalStartKind)
                .then(function success(response) {
                  $scope.fillInnerListFromApiList(response.data);
                  $scope.data.table_api = response.data;
                  $scope.contentLoading = false;
                });
      };

      loadList();

    });
