'use strict';
angular.module('ChicoApp.Parents')

    .controller('ParentsController', function(
        $rootScope,
        $scope,
        $translate,
        $state,
        $http,
        $window,
        $cookies,
        $stateParams,
        $timeout,
        DeviceFactory,
        AuthFactory,
        modalFactory,
        $log,
        API,
        apiFunctions,
        parentsFactory,
        scgGlobalSpinnerFactory,
        scgSharedFactory) {


        $log.info('Welcome Parents COntroller 1');

        $scope.testDevice = "My-Testdevice";
        $scope.todayOnly = true;
        $scope.devicelist = [];


        $scope.loadDevicelist = function(todayOnly) {

            parentsFactory.listAll(todayOnly).then(function success(response) {
                $log.info('listAll returned', response);
                $scope.devicelist = response.data;
                scgGlobalSpinnerFactory.unblockUI();
            });
        }

        $scope.setReportRole = function(status) {
            if (status===true) {
                $cookies.put('role','parent');
            } else {
                $cookies.remove('role','parent');
            }
        }


        //load the list
        scgGlobalSpinnerFactory.blockUI();
        $scope.loadDevicelist(true);


    });
