'use strict';
angular.module('ChicoApp.Licence')

    .controller('MydataController', function(
        $rootScope,
        $scope,
        $translate,
        $state,
        $http,
        $window,
        $cookies,
        $timeout,
        DeviceFactory,
        AuthFactory,
        modalFactory,
        $log,
        API,
        _,
        apiFunctions,
        licenceFactory,
        scgLicenceFactory,
        scgSharedFactory,
        menuFactory) {


        $scope.singleSerialArr = {};
        $scope.singleDeviceArr = {};
        $scope.noDevices = false;
        $scope.email = "";
        $scope.device = {};
        $scope.tmpSerial = '';
        $scope.hasCurrentDevices = false;

        $scope.loadingDevices = false;
        $scope.loadingSerials = false;
        $scope.loadingEmail = false;

        $log.info('mydata INIT');

        $scope.unsubscribeVisible = false;
        $scope.movelicenceVisible = false;

        //scgGlobalSpinnerFactory.unblockUI();
        AuthFactory.activeLicence.showSideBar = false;
        menuFactory.hideSidebar();


        $scope.gotoDash = function() {
            AuthFactory.activeLicence.showSideBar = true;
            $log.info('AUTH, current context is=' + AuthFactory.activeContext);
            if (AuthFactory.activeContext == 'sb') {
                $state.go('sb-dashboard');
            } else
            if (AuthFactory.activeContext == 'sc') {
                $state.go('sc-dashboard');
            } else
            if (AuthFactory.activeContext == 'cc') {
                $state.go('cc-dashboard-v3');
            } else {
                $log.info('cb-dash-v3 in licence');
                if ($cookies.get('cb2')=="true") {
                    $state.go('cb-dashboard'); }
                else {
                    $state.go('cb-dashboard-v3');
                }
            }
        };

        $scope.setDevice = function(singleDevice) {
            $scope.device.deviceId=singleDevice.pcId;
            $scope.device.deviceName = singleDevice.deviceName;
            $scope.device.deviceType = singleDevice.deviceType;
            $scope.device.user = singleDevice.lastUser;
            AuthFactory.setActiveDevice($scope.device,$scope.device.user);
        }

        $scope.enterLicence = function(singleDevice) {
            $scope.setDevice(singleDevice);
            if ($scope.tmpSerial!='') {
                $rootScope.enterSerial = $scope.tmpSerial;
            } else {
                $rootScope.enterSerial = '';
            }
            $state.go('licence');
        }

        $scope.resetLicence = function(serial) {
            //Attention: Dupkicate Code, also in LicenceController!
            $log.info('reset licence serial=' + serial);
            modalFactory.infoModal.open({
                modal_settings: {
                    title: $translate.instant('MSG.SURE'),
                    message: serial + ": " + $translate.instant('MSG.RESET_LICENCE'),
                    butOkLabel: $translate.instant('BUTTON.YES'),
                    butCancelLabel: $translate.instant('BUTTON.NO'),
                    iconNo: 2,
                    refreshStateAfterClose: false
                },
                callbacks: {
                    ok: function() {
                        $log.info('modal closed with ok -> callback');
                        Metronic.blockUI({animate: true});

                        var myurl = API.url + 'licence/deleteuseddevice/' + serial;
                        var req = {
                            method: 'GET',
                            url: myurl
                        };
                        $http(req)
                            .then(function success() {
                                //reload page!
                                Metronic.unblockUI();
                                $state.go($state.current, {}, {reload: true});
                            });
                    },
                },
                cancel: function() {
                    $log.info('canceled!');
                }
            });
        }


        $scope.listEmail = function() {
            $scope.loadingEmail=true;
            licenceFactory.listEmail().then(function success(response) {
                $scope.email = response.data.email;
                $scope.loadingEmail=false;
            });

        }

        $scope.listSingleSerials = function() {
            $scope.loadingSerials=true;
            licenceFactory.listSingleSerials().then(function success(response) {
                $log.info('mydata Done with HTTP 200!', response);
                $scope.singleSerialArr = response.data;
                if ($scope.singleSerialArr.length>0) {
                    $scope.tmpSerial = $scope.singleSerialArr[0].serial;
                }
                $scope.loadingSerials=false;
            });
        }

        $scope.listSingleDevices = function() {
            $scope.loadingDevices = true;
            licenceFactory.listSingleDevices().then(function success(response) {
            $log.info('mydata singleDevices!', response);
            $scope.singleDeviceArr = response.data;

                _.forEach($scope.singleDeviceArr, function(singleDevice) {
                    if (singleDevice.lastDays>-1 && singleDevice.lastDays<4) {
                        $scope.hasCurrentDevice=true;
                        $log.info('singleDevice.lastDays=' + singleDevice.lastDays);
                    }
                });
                if ($scope.singleDeviceArr && $scope.singleDeviceArr.length===0) {
                    $scope.noDevices = true;
                }
            $scope.loadingDevices = false;
            });
        }


        $scope.deleteDevice = function(singleDevice) {
            $log.info('deleteDevice singleDevice=' , singleDevice);
            $scope.setDevice(singleDevice);
            modalFactory.infoModal.open({
                modal_settings: {
                    title: $translate.instant('MSG.SURE'),
                    message: $translate.instant('MSG.DELETEDATA') + ' ' + $translate.instant('MSG.SURE'),
                    butOkLabel: $translate.instant('BUTTON.YES'),
                    butCancelLabel: $translate.instant('BUTTON.NO'),
                    iconNo: 3,
                    refreshStateAfterClose: false
                },
                callbacks: {
                    ok: function() {
                        $log.info('modal closed with ok -> callback');
                        Metronic.blockUI({animate: true});

                        var myurl = API.url + 'action/deletedevice';
                        var req = {
                            method: 'GET',
                            url: myurl
                        };
                        $http(req)
                            .then(function success() {
                                Metronic.unblockUI();
                                AuthFactory.logout(true);
                            });
                    },
                },
                cancel: function() {
                    $log.info('canceled!');
                }
            });
        }

        $scope.deleteAccount = function() {
            if ($scope.hasCurrentDevice==true) {
                modalFactory.okModal(3, $translate.instant('MYDATA.HAS_CURRENT_HEADLINE') + '<br><br>' + $translate.instant('MYDATA.HAS_CURRENT_DEVICES'));
                return;
            }

            modalFactory.infoModal.open({
                modal_settings: {
                    title: $translate.instant('MSG.SURE'),
                    message: $translate.instant('MSG.DELETECUSTOMER') + ' ' + $translate.instant('MSG.SURE'),
                    butOkLabel: $translate.instant('BUTTON.YES'),
                    butCancelLabel: $translate.instant('BUTTON.NO'),
                    iconNo: 3,
                    refreshStateAfterClose: false
                },
                callbacks: {
                    ok: function() {
                        licenceFactory.deleteCustomer().then(function success() {
                                Metronic.unblockUI();
                                AuthFactory.logout(true);
                            });
                    },
                },
                cancel: function() {
                    $log.info('canceled!');
                }
            });
        }


        $scope.showUnsubscribe = function() {
            $log.info('Show unsubsribe!');
            $scope.unsubscribeVisible=true;
        }

        $scope.showMoveLicence = function() {
            $scope.movelicenceVisible=true;
        }

        $scope.showChangeMailNotice = function() {
            modalFactory.okModal(1, $translate.instant('MYDATA.CHANGE_MAIL_1') + '<br><br>' + $translate.instant('MYDATA.CHANGE_MAIL_2') + '<br><br>' + $translate.instant('MYDATA.CHANGE_MAIL_3'));
        }

        //execute the single functions
        $scope.listEmail();
        $scope.listSingleDevices();
        $scope.listSingleSerials();


    });
