'use strict';
angular.module('ChicoApp.CB-Screenshots')
    .controller('CB-ScreenshotController', function(
        $rootScope,
        $scope,
        $state,
        $modal,
        $http,
        $timeout,
        $translate,
        $cookies,
        $log,
        _,
        API,
        modalFactory,
        sseFactory,
        AuthFactory,
        cbScreenShotFactory,
        scgGlobalSpinnerFactory,
        scgSharedFactory) {

        $scope.data = {};
        $scope.dataSettings = {};
        $scope.data.filenames = [];
        $scope.shotInterval = '10';
        $scope.data.paginatedFilenames = [];
        $scope.pageNo = 1;
        $scope.screensLoading = true;
        $scope.tempLang = 'de';
        $scope.active = false;      //helper for main switch
        $scope.imgNotice = '../images/screenshot-prepare-de.jpg';
        $scope.notAvailable = false;

        var modalInstance;
        var pageLength = 9;
        var listLength = 0;
        var pageCount = 0;
        var listStartIndex = 0;
        var listStopIndex = listStartIndex + pageLength;

        $scope.pagination = {start: 0};

        $scope.getBaseUrl = function() {
            return 'https://portal.salfeld.net/api/screenshotsstorage/' + AuthFactory.sessionId + '/' + AuthFactory.activeDevice.deviceId;
        };


        function setPaginationInfo() {
            listStartIndex = ($scope.pageNo - 1) * pageLength;
            listStopIndex = listStartIndex + pageLength;
            $scope.listStartIndex = listStartIndex;
            $scope.listStopIndex = listStopIndex;

            var paginatedArr = [];
            _.each($scope.data.filenames, function(filename, index) {
                $log.info('idx test', index);
                if (index >= listStartIndex && index < listStopIndex) {
                    paginatedArr.push(filename);
                }
            });
            $scope.data.paginatedFilenames = paginatedArr;
        }

        $scope.goToPage = function(pageNo) {
            $scope.pageNo = pageNo;
            setPaginationInfo();
        };

        $scope.goToPrevPage = function() {
            $scope.pageNo = $scope.pageNo - 1;
            setPaginationInfo();
        };

        $scope.goToNextPage = function() {
            $scope.pageNo = $scope.pageNo + 1;
            setPaginationInfo();
        };

        $scope.showScreenshot = function(filename) {
            $log.info('open screenshot', filename);
            $scope.uriScreenshot = $scope.getBaseUrl() + '/' + filename;
            //$scope.$apply();
            $modal.open({
                animation: false,
                templateUrl: 'templates/chicobrowser/cb_screenshots/templates/screenshots-modal-screenshot.html',
                windowTemplateUrl: 'templates/chicobrowser/cb_screenshots/templates/screenshots-modal-window.html',
                scope: $scope
            });
        };

        $scope.showPrepare = function() {
            $log.info('prepare screenshot');
            $modal.open({
                animation: false,
                templateUrl: 'templates/chicobrowser/cb_screenshots/templates/screenshots-modal-prepare.html',
                windowTemplateUrl: 'templates/chicobrowser/cb_screenshots/templates/screenshots-modal-window.html',
                scope: $scope
            });
        };

        $scope.showPrepareFinal = function() {
            $log.info('prepare screenshot');
            $modal.open({
                animation: false,
                templateUrl: 'templates/chicobrowser/cb_screenshots/templates/screenshots-modal-prepare-final.html',
                windowTemplateUrl: 'templates/chicobrowser/cb_screenshots/templates/screenshots-modal-window.html',
                scope: $scope
            });
        };

        // ------------------------------------------------------- DS PART ----------------------------------------------------
        // ------------------------------------------------------- ------------------------------------------------------------


        $scope.processSettings = function() {
            //Is active for the selected user?
            $log.info('cbshots datasettings=', $scope.dataSettings);
            if ($scope.dataSettings.screenshotInterval>0 && $scope.dataSettings.screenshotRightSuccess==true) {
                $log.info('cbshots YES, active');
                $scope.screensLoading = true;
                $scope.active=true;
                cbScreenShotFactory.getScreenshotList().then(function(response) {
                    var filenameArr = response.data;
                    $scope.data.filenames = filenameArr;
                    //calculate pages for pagination
                    pageLength = 9;
                    listLength = filenameArr.length;
                    $scope.numbersOfScreenshots = listLength;
                    $log.info('cbshot numbersOfScreenshots=' + $scope.numbersOfScreenshots);
                    pageCount = Math.ceil(listLength / pageLength);
                    var pages = [];
                    for (var i = 1; i <= pageCount; i++) {
                        pages.push(i);
                    }
                    $scope.data.pages = pages;
                    setPaginationInfo();
                    $scope.screensLoading = false;
                });
            }
        };

        $scope.displayDeleteMsg = function() {
            $log.info('reset times goes here');
            modalFactory.infoModal.open({
                modal_settings: {
                    title: $translate.instant('MSG.QUESTION'),
                    message: $translate.instant('MSG.DELETE_SCREENSHOTS') + '<br>' + $translate.instant('MSG.SURE'),
                    butOkLabel: $translate.instant('BUTTON.YES'),
                    butCancelLabel: $translate.instant('BUTTON.NO'),
                    iconNo: 2,
                    refreshStateAfterClose: true
                },
                callbacks: {
                    ok: function() {
                        $log.info('yes, ok was click, he/she is sure');

                        var changed_object_off = {screenshotInterval: -1};
                        cbScreenShotFactory.setSetting(changed_object_off).then(function() {
                            $scope.dataSettings.screenshotInterval = -1;
                            $scope.active=false;
                            cbScreenShotFactory.deleteScreenshots();
                            $state.reload();
                        });

                        $log.info('Ok, all done');
                    },
                    cancel: function() {
                        $log.info('canceled!');
                    }
                }
            });
        }


        cbScreenShotFactory.getSettings()
            .then(function success(response) {
                $log.info('CB-ScreensHotFactory returned SUCCESS:', response.data);
                $scope.dataSettings = response.data;
                $scope.screensLoading = false;

                //special case we have no rights, then turn it off always!
                if ($scope.dataSettings.screenshotRightSuccess==false) {
                    $log.info('cbshots rights is false, disable!');
                    if ($scope.dataSettings.screenshotInterval>-1) {
                        $log.info('cbshots disabled but wrong interval! -> FIXING IT');
                        var changed_object_on = {screenshotInterval: -1};
                        cbScreenShotFactory.setSetting(changed_object_on).then(function() {
                            //nothing special
                        });

                    }

                    $scope.active=false;
                }

                $scope.processSettings();

                //when screenshot switch has been changed from on to off
                $scope.$watch(function() {
                    return $scope.active;
                }, function(nV, oV) {
                    $log.info('cbshots watcher active fired! nv=' + nV + "|oV=" + oV);

                    //set interval to -1 anyway if we switched to OFF

                    if (angular.isDefined(nV) && nV != oV) {
                        if (nV == false && oV == true) {
                            if ($scope.notAvailable==false && $scope.dataSettings.screenshotRightSuccess==true) {
                                $log.info('cbshots TURNED OFF!');
                                $scope.displayDeleteMsg();
                                $log.info('cbshots active watch nv=' + nV);
                            }

                        }else if (nV == true && oV == false) {
                            $log.info('cbshots active watch 2 nv=' + nV);

                            //special case android 10
                            if ($scope.notAvailable==true) {
                                $scope.active=false;
                                return;
                            }
                            //screenshot rights are false, show prepare
                            if ($scope.dataSettings.screenshotRightSuccess==false) {
                                $log.info('cbshots rights are false!');
                                $scope.active=false;
                                $scope.showPrepare();
                                return;
                            }
                            $scope.screensLoading = true;
                            var changed_object_on = {screenshotInterval: 5};
                            cbScreenShotFactory.setSetting(changed_object_on).then(function() {
                                      $timeout(function() {
                                    $scope.screensLoading = false;
                                    $state.reload();
                                }, 3000);
                            });
                        }
                    }
                });
            });

        $scope.formatShotDate = function(instr) {
            var r = instr;
            var i = instr.length;
            if (i == 23) {
                r = scgSharedFactory.formatDateTime(instr.substr(0,10) + ' ' + instr.substr(11,2) + ':'  + instr.substr(14,2) + ':'  + instr.substr(17,2));
            }
            return r;
        };

        $scope.saveShotInterval = function(newInterval) {
            var changed_object = {screenshotInterval: newInterval};
            cbScreenShotFactory.setSetting(changed_object).then(function() {
                $log.info('cbshot saved, newInterval=' + newInterval);
            });
        };

        $scope.lessMinutes = function() {
            $log.info('cbshot less');
            var diff = 1;
            if ($scope.dataSettings.screenshotInterval >= diff + 1) {
                $scope.dataSettings.screenshotInterval =  parseInt($scope.dataSettings.screenshotInterval) - diff;
            }
            $scope.saveShotInterval($scope.dataSettings.screenshotInterval); //instead of watcher
        };

        $scope.moreMinutes = function() {
            $log.info('cbshot more');
            var diff = 1;
            if ($scope.dataSettings.screenshotInterval <= 30) {
                $scope.dataSettings.screenshotInterval =  parseInt($scope.dataSettings.screenshotInterval) + diff;
            }
            $scope.saveShotInterval($scope.dataSettings.screenshotInterval); //instead of watcher
        };



        $scope.takeScreenshot = function() {
            $log.info('cbshots takeScreenshot');
            scgGlobalSpinnerFactory.blockUI();
            $scope.pollDone = false;
            cbScreenShotFactory.screenshot()
                .then(function success(response) {
                    $log.info('screenshot response:' , response.data);
                    scgGlobalSpinnerFactory.unblockUI();

                    if (response.data.success == true) {
                        if (response.data.id !== null && response.data.id == 'NO_SESSION') {
                            $scope.myInfoModal($translate.instant('MSG.NOSESSION'));
                            return;
                        }
                    }
                    if (response.data.success == false) {
                        if (response.data.id !== null && response.data.id == 'SCREENSHOT_TIMEOUT') {
                            $scope.myInfoModal($translate.instant('SCREENSHOT.TIMEOUT'));
                            return;
                        }
                    }
                    //we have the file, display it
                    $scope.dataSettings.screenshotRightSuccess=true;
                    $scope.active=true;
                    var rnd = Math.floor(Math.random() * (999999 - 100001)) + 100001; //login controller because of NO AUTH for uriScreenshot, rnd against caching
                    $scope.uriScreenshot = "http://portal.salfeld.net/api/login/getscreen/" + rnd + "/" + response.data.id;
                    $log.info('uriScreenshot:' + $scope.uriScreenshot); //used by <img src> in modal
                    $scope.showScreenshotInstant();
                });
        };

        $scope.showScreenshotInstant = function() {
            modalInstance = $modal.open({
                animation: false,
                templateUrl: 'templates/chicobrowser/cb_screenshots/templates/screenshots-modal-screenshot.html',
                windowTemplateUrl: 'templates/chicobrowser/cb_screenshots/templates/screenshots-modal-window.html',
                scope: $scope
            });
        };


        //infomdal for re-use in this scope
        $scope.myInfoModal = function(msg) {
            scgGlobalSpinnerFactory.unblockUI();
            modalFactory.infoModal.open({
                modal_settings: {
                    title: $translate.instant('HEADER.INFO'),
                    message: msg,
                    butOkLabel: $translate.instant('BUTTON.OK'),
                    butCancelLabel: null,
                    iconNo: 1,
                    refreshStateAfterClose: true
                },
                callbacks: {
                    ok: function() {
                        $log.info('modal closed with ok -> callback');
                    }
                }
            });
        };



        //watch for language (must be done in watcher)
        $scope.$watch(function() {
            return $cookies.get('language');
        }, function(newVal) {
                $log.info('watcher language='+  $cookies.get('language'))
            if ($cookies.get('language') == 'en_US') {
                $scope.tempLang = 'en';
                $scope.imgNotice = '../images/screenshot-prepare-en.jpg';
            }
        });


        //Watch for Android 10 devices
        $rootScope.$watch(function () {
            return AuthFactory.activeDevice;
        }, function (nv, ov) {
            $log.info('watcher new device=', AuthFactory.activeDevice);
            if (AuthFactory.activeDevice.versionInfo && AuthFactory.activeDevice.versionInfo.indexOf('Android 10.0')>0) {
                $scope.notAvailable=true;
            }
            if (AuthFactory.activeDevice.versionInfo && AuthFactory.activeDevice.versionInfo.indexOf('Android 10.0')==-1) {
                $scope.notAvailable=false;
            }
        }, true);


    });
