'use strict';
angular.module('ChicoApp.CB-Webfilter')

    .controller('CB-Webfilter-Blocked-Controller', function(
      $scope,
      $state,
      $translate,
      cbListsFactory,
      scgSharedFactory,
      DTOptionsBuilder,
      DTColumnDefBuilder,
      scgDataTableFactory,
      _
    ) {

      var modalStartKind = 5;
      var tmpURL = 5;
      var tmpKeywords = 6;

      $scope.tableHeaders = [$translate.instant('HEADER.DOMAINURLKEYWORD')];

      $scope.data = {};

      scgDataTableFactory.prepareScopeForTable($scope, modalStartKind);
      $scope.dtOptions = scgDataTableFactory.getDefaultDtOptions($scope);

      $scope.dtColumnDefs = scgDataTableFactory.getDefaultDtColumnDefs3Cols();

      $scope.reload = function() {
        $state.go($state.current, {}, {reload: true});
      };

      var loadList = function() {
        $scope.contentLoading = true;
        cbListsFactory.getByListFilter(tmpURL)
                .then(function success(response) {
                  $scope.fillInnerListFromApiList(response.data);

                })

                .then(function() {
                  cbListsFactory.getByListFilter(tmpKeywords)
                        .then(function success(response) {
                          $scope.fillInnerListFromApiList(response.data);

                          $scope.data.table_inner = _.sortBy($scope.data.table_inner, function(entry) {
                            return entry.data.entry;
                          });
                          $scope.contentLoading = false;
                        });
                });
      };

      loadList();

    });

