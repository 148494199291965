'use strict';
angular.module('ChicoApp').directive('scgExportModal', function() {
  return {
    restrict: 'E',
    templateUrl: 'templates/childcontrol/cc_settings/templates/modal-export-content.html',
    controller: function() {
      //nothing special
    }
  };
});

