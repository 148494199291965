'use strict';
angular.module('ChicoApp.CC-Programs')
    .controller('CC-Programs-TimeLimited-Controller', function(
        $scope,
        $state,
        $translate,
        ccListsFactory,
        DTOptionsBuilder,
        DTColumnDefBuilder,
        scgDataTableFactory
        ) {

      var modalStartKind = ccListsFactory.listKinds.list_kind_timelimited_prg;

      $scope.tableHeaders = [
        $translate.instant('HEADER.ENTRY'),
        $translate.instant('HEADER.DETAILS'),
        $translate.instant('HEADER.INSTALLDATE')
        ];

      $scope.data = {};
      $scope.data.table_api = [];

      scgDataTableFactory.prepareScopeForTable($scope, modalStartKind);
      $scope.dtOptions = scgDataTableFactory.getDefaultDtOptions($scope);

      $scope.dtColumnDefs = scgDataTableFactory.getDefaultDtColumnDefs6Cols();

      $scope.reload = function() {
        $state.go($state.current, {}, {reload: true});
      };

      var loadList = function() {
        $scope.contentLoading = true;
        ccListsFactory.getDetailedByListFilter(modalStartKind)
                .then(function success(response) {
                  $scope.fillInnerListFromApiList(response.data);
                  $scope.data.table_api = response.data;
                  $scope.contentLoading = false;
                });
      };

      loadList();

    });
