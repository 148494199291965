'use strict';
angular.module('ChicoApp.Shared').directive('ccErrorModal', function() {
  return {
    restrict: 'E',
    templateUrl: 'templates/shared/globalModals/globalErrorModal/templates/modal-error-content.html',
    controller: function() {

    }
  };
});
