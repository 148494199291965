'use strict';
angular.module('ChicoApp.CC-Screenshots', [])
    .config(function($stateProvider) {
      $stateProvider.state('cc-screenshots', {
        url: '/cc-screenshots',
        controller: 'CC-ScreenshotController',
        templateUrl: 'templates/childcontrol/cc_screenshots/templates/screenshots.html'
      });
    })
    .run(function() {});
