'use strict';
angular.module('ChicoApp.CB-Webfilter', [])

    .config(function($stateProvider) {
      $stateProvider
        .state('cb-webfilter', {
          //abstract: true,
          url: '/webfilter',
          redirectTo: 'cb-webfilter.tabs',
          controller: 'CB-WebfilterController',
          templateUrl: 'templates/chicobrowser/cb_webfilter/templates/webfilter.html'
        })
        .state('cb-webfilter.tabs', {
          url: '/websites',
          redirectTo: 'cb-webfilter.tabs.blocked',
          templateUrl: 'templates/chicobrowser/cb_webfilter/templates/webfilter-tabs.html',
          controller: 'CB-Webfilter-WebsitesController'
        })

        .state('cb-webfilter.tabs.allowed', {
          url: '/allowed',
          templateUrl: 'templates/chicobrowser/cb_webfilter/templates/webfilter-generic-list.html',
          controller: 'CB-Webfilter-Allowed-Controller',
        })

        .state('cb-webfilter.tabs.blocked', {
          url: '/blocked',
          templateUrl: 'templates/chicobrowser/cb_webfilter/templates/webfilter-generic-list.html',
          controller: 'CB-Webfilter-Blocked-Controller',
        })

        .state('cb-webfilter.tabs.timelimited', {
          url: '/timelimited',
          templateUrl: 'templates/chicobrowser/cb_webfilter/templates/webfilter-generic-list.html',
          controller: 'CB-Webfilter-Timelimited-Controller',
        })

        .state('cb-webfilter.tabs.group', {
          url: '/group',
          templateUrl: 'templates/chicobrowser/cb_webfilter/templates/webfilter-groups.html',
          controller: 'CB-Webfilter-Group-Controller',
        })

          .state('cb-webfilter.tabs.categories', {
            url: '/cb-categories',
            templateUrl: 'templates/chicobrowser/cb_webfilter/templates/webfilter-categories.html',
            controller: 'CB-Webfilter-CategoriesController',
          })

          .state('cb-webfilter.tabs.byage', {
            url: '/cb-age',
            templateUrl: 'templates/chicobrowser/cb_webfilter/templates/webfilter-byage.html',
            controller: 'CB-Webfilter-ByAgeController',
          })

        .state('cb-webfilter.byage', {
          url: '/byage',
          templateUrl: 'templates/chicobrowser/cb_webfilter/templates/webfilter-byage.html',
          controller: 'CB-Webfilter-ByAgeController'
        })

        /*
        .state('cb-webfilter.categories', {
          url: '/cb-categories',
          templateUrl: 'templates/chicobrowser/cb_webfilter/templates/webfilter-categories.html',
          controller: 'CB-Webfilter-CategoriesController',
        })
        */

        ;

    })
    .run(function() {});
