'use strict';



angular.module('ChicoApp.CB-Browsertime', [])
    .config(function($stateProvider) {
        $stateProvider.state('cbtime', {
            url: '/cbtime',
            template: '<ui-view />',
            redirectTo: 'cbtime.devicetime',
            controller: 'CB-DevicetimeController'
        })
            .state('cbtime.devicetime', {
                url: '/cb-devicetime',
                templateUrl: 'templates/chicobrowser/cb_browsertime/templates/devicetime.html',
                controller: 'CB-DevicetimeController',
                params: {
                    highlight: null
                },
            })

            .state('cbtime.browsertime', {
                url: '/cb-browsertime',
                templateUrl: 'templates/chicobrowser/cb_browsertime/templates/browsertime.html',
                controller: 'CB-BrowsertimeController'
            })

            .state('cbtime.roaming', {
                url: '/cb-roaming',
                templateUrl: 'templates/chicobrowser/cb_browsertime/templates/roaming.html',
                controller: 'CB-DevicetimeController'
            });
    })
    .run(function() {});