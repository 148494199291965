(function() {
  'use strict';

  angular
      .module('ChicoApp.ChicoBrowser', [
          'ChicoApp.CB-Dashboard',
          'ChicoApp.CB-V3-Dashboard',
          'ChicoApp.CB-Browsertime',
          'ChicoApp.CB-Webfilter',
          'ChicoApp.CB-Appfilter',
          'ChicoApp.CB-Appfilter-V2',
          'ChicoApp.CB-Appfilter-V3',
          'ChicoApp.CB-Usage',
          'ChicoApp.CB-Emailreport',
          'ChicoApp.CB-GPS',
          'ChicoApp.CB-Settings',
          'ChicoApp.CB-Screenshots',
          'ChicoApp.CB-Timelimits',
          'ChicoApp.CB-SYNC',
          'ChicoApp.Parents'
      ]);
})();
