'use strict';
angular.module('ChicoApp.Shared').controller('SCG-Modal-Until-Controller', function(
    $scope,
    $log,
    $state,
    $http,
    $window,
    $timeout,
    API,
    AuthFactory,
    apiFunctions,
    moment,
    scgSharedFactory,
    scgGlobalSpinnerFactory,
    $modalInstance,
    modal_settings)
{

    $scope.isCB = false;
    $scope.isCC = false;
    $scope.hasCBFeature = scgSharedFactory.hasCBFeature;
    $scope.modal_settings = modal_settings; //transfer settings to scope for use in template

    $window.ga('send', 'pageview', {page:'/modal-untilAction'});

    $scope.modal_settings.untilDays = 0;
    $scope.modal_settings.untilHours = 1;
    $scope.modal_settings.untilMinutes = 15;
    $scope.modal_settings.customBlockMsg = '';
    $scope.modal_settings.wantCustomMsg = false;

    $scope.cancel = function() {
        $log.info('modal CANCEL inside modal');
        $modalInstance.dismiss('cancel');
    }

    $scope.setUntilAction = function(){
        $timeout(function(){
            $scope.setUntilActionDelayed();
        });
    }

    $scope.setUntilActionDelayed = function() {

        /*$log.info('modal-until setUntilAction');
        $log.info('modal-until ok click, selected defaultRadio=' , $scope.modal_settings.defaultRadio);
        $log.info('modal-until ok click, selected contexto=' , $scope.modal_settings.context);
        $log.info('XXCCVVBB modal-until-controller untilMinutes' , $scope.modal_settings.untilMinutes);*/

        var untilDateTime = moment().add($scope.modal_settings.untilDays, 'days').add($scope.modal_settings.untilHours, 'hours').add($scope.modal_settings.untilMinutes, 'minutes').format('YYYY-MM-DD HH:mm:ss');
        var untilAction = $scope.modal_settings.defaultRadio;

        var obj = {"untilAction" : untilAction, "untilDateTime" : untilDateTime, "neverBlockAlwaysAllowed" : $scope.modal_settings.neverBlockAlwaysAllowed, "customBlockMsg" : $scope.modal_settings.customBlockMsg};
        var req = {
            method: 'POST',
            url: API.url + 'cbsettings',
            data: obj
        };

        //Overwrite if we are context CC
        if ($scope.modal_settings.context=="CC") {
            if (untilAction==1) {
                req = {
                    method: 'POST',
                    url: apiFunctions.get_cc_base_url() + 'ccsettings',
                    data: {"onetimelocklevel": 2, "unlockdate": untilDateTime}
            }}
            if (untilAction==2) {
                if (typeof $scope.modal_settings.neverBlockAlwaysAllowed=='undefined') {
                    $scope.modal_settings.neverBlockAlwaysAllowed=false;
                }
                req = {
                    method: 'POST',
                    url: apiFunctions.get_cc_base_url() + 'ccsettings',
                    data: {"onetimelocklevel": 3, "lockdate": untilDateTime, "neverBlockAlwaysAllowed" : $scope.modal_settings.neverBlockAlwaysAllowed, "customBlockMsg" : $scope.modal_settings.customBlockMsg}
            }}
        }

        $log.info('modal-until obj=', obj);
        //scgGlobalSpinnerFactory.blockUI();
        $http(req).then(function success() {
            $log.info("modal-until HTTP SUCCESS");
            scgGlobalSpinnerFactory.unblockUI();
            $modalInstance.close();
        }).catch(function(error){
            $log.info("modal-until HTTP ERROR:" , error);
            scgGlobalSpinnerFactory.unblockUI();
            $modalInstance.close();
        });
    }

    if (AuthFactory.activeContext=='cb') $scope.isCB=true;
    if (AuthFactory.activeContext=='cc') $scope.isCC=true;
    $log.info('untilModal isCB=' + $scope.isCB + '|isCC=' + $scope.isCC);
});

