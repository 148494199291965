angular.module('ChicoApp').component('appFilterV4', {
    templateUrl: 'templates/chicobrowser/cb_appfilter_v4/appFilterV4.component.html',
    controller: AppFilterV4Controller
});

function AppFilterV4Controller(
    $translate,
    $state,
    $window,
    _,
    moment,
    $log,
    AuthFactory,
    appListsFactory,
    modalFactory,
    cbAppFilterFactory,
    scgSettingsFactory,
    scgSharedFactory,
    appInfoFactory,
    chicoEnums
) {

    var ctrl = this;

    ctrl.chicoEnums = chicoEnums;
    
    /**
     * @type {Object}
     * @property {number} defaultAppKind
     * @property {boolean} isAppFilterActive
     * @property {boolean} isAppFilterNewBlocked
     */
    ctrl.data = {};

    ctrl.loadingData = true; //must be initalised with true because of switch disabled state
    ctrl.spinnerApplist = true;

    // If availableKinds is initialized with [] instead of null, strange things happen at kind-select-dropdown directive:
    // Checkboxes are replaced with radio buttons
    ctrl.availableKinds = null;
    ctrl.selectedKinds = {};
    ctrl.groups = [];
    ctrl.applist = [];
    ctrl.selectedListRows = [];

    ctrl.currentContext = AuthFactory.activeContext;

    ctrl.headerEntries = [];

    ctrl.appListPage = 1;

    ctrl.appKind = chicoEnums.AppTypeEnum.APP;

    ctrl.$onInit = function() {
        // Prepare headers for applist
        $translate(['HEADER.ENTRY', 'HEADER.INSTALLDATE']).then(function(translations) {
            ctrl.headerEntries = [
                {
                    name: translations['HEADER.ENTRY'],
                    key: 'entry',
                    mainColumn: true,
                    allowSorting: true,
                    sortAscFn: function(a, b) {
                        return a.entry.localeCompare(b.entry);
                    },
                    sortDescFn: function(a, b) {
                        return a.entry.localeCompare(b.entry) * -1;
                    }
                },
                {
                    name: translations['HEADER.INSTALLDATE'],
                    key: 'installDateFormatted',
                    hideXs: true,
                    allowSorting: true,
                    sortAscFn: function(a, b) {
                        return moment(a.installDate).isAfter(moment(b.installDate)) ? 1 : -1;
                    },
                    sortDescFn: function(a, b) {
                        return moment(a.installDate).isBefore(moment(b.installDate)) ? 1 : -1;
                    }
                }
            ];



        });

        // Prepare kinds and groups for filter
        scgSettingsFactory.getGroups(false).then(function success(response) {
            ctrl.groups = response.data;

            if (ctrl.groups.length == 5) {
                ctrl.group1name = ctrl.groups[0].name;
                ctrl.group2name = ctrl.groups[1].name;
                ctrl.group3name = ctrl.groups[2].name;
                ctrl.group4name = ctrl.groups[3].name;
                ctrl.group5name = ctrl.groups[4].name;
            }

            ctrl.prepareKinds();
        });

        ctrl.loadList();

        ctrl.appListPage = $state.params.appListPage;
    };

    ctrl.turnOn = function()
    {
        ctrl.data.isAppFilterActive=true;
        ctrl.onModelChange();
    }

    ctrl.$postLink = function() {
        // Attach event to bootstrap switch to detect when switch is toggled (not possible in html template when using AngularJS 1.5; must be done here)
        angular.element('#app-filter-active-switch').on('switchChange.bootstrapSwitch', ctrl.onAppFilterActiveChange);
    };

    ctrl.editSelectedRows = function () {

        var selectedAppIds = ctrl.selectedListRows.map(function(entry) {
            return entry.entry;
        });
        
        $state.go('detail-view', { appIds: selectedAppIds, appType: chicoEnums.AppTypeEnum.APP, allowAdd: false, prevStateName: $state.current.name });
    };

    ctrl.onAppListRowClick = function (row) {
        $state.go('detail-view', { appIds: [row.entry], appType: chicoEnums.AppTypeEnum.APP, allowAdd: false, prevStateName: $state.current.name });
    };

    ctrl.searchFilterFunc = function(listEntries, searchInput) {
        return _.filter(listEntries, function (object) {
            var concatenated = object.entry;
            return concatenated.toLowerCase().includes(searchInput.toLowerCase());
        });
    };

    ctrl.filterFunc = function(listEntries, filterParams) {
        // Only filter if there are any selectedKinds to check
        // Workaround because kind-select-dropdown is not fast enough in intialization...
        if (filterParams.selectedKinds && Object.keys(filterParams.selectedKinds).length > 0) {
            var filtered = _.filter(listEntries, function (object) {
                if (!filterParams.selectedKinds[object.kind]) {
                    return false; // What to do if we have no filter information for that kind, true=show, false=hide
                }
                return filterParams.selectedKinds[object.kind] == true;
            });
            return filtered;
        } else {
            return listEntries;
        }
    };

    ctrl.onPageChange = function(newPage) {
        ctrl.appListPage = newPage;
        $state.params.appListPage = newPage;
    };

    ctrl.onAppFilterActiveChange = function() {
        cbAppFilterFactory.setIsAppFilterActive(ctrl.data.isAppFilterActive);
    };

    ctrl.onDefaultAppKindChange = function() {
        cbAppFilterFactory.setDefaultAppKind(ctrl.data.defaultAppKind);
    };

    ctrl.onModelChange = function() {
        $log.info('onModelChange=', ctrl.data);
        scgSharedFactory.needsSync = true;
        cbAppFilterFactory.setSetting(ctrl.data);
    };

    cbAppFilterFactory.getSettings().then(function success(response) {
        ctrl.data = response.data;
        ctrl.loadingData = false;
    });

    ctrl.getIcon = function(app) { 
        return appInfoFactory.getAppIconUrl(app.details);
    };

    ctrl.loadList = function() {     
        ctrl.spinnerApplist = true;
        appListsFactory.getAppListCB().then(function success(response) {
            ctrl.applist = response.data;

            $translate.onReady(function() {
                for (var i = 0; i < ctrl.applist.length; i++) {
                    ctrl.applist[i].iconHref                = ctrl.getIcon(ctrl.applist[i]);
                    ctrl.applist[i].installDateFormatted    = scgSharedFactory.formatDate(ctrl.applist[i].installDate);
                }
            });

            // Clear selectedListRows because it is not cleared automatically when data changes
            ctrl.selectedListRows = [];
            ctrl.spinnerApplist = false;
        });
    };

    /* Functions for filter */

    /**
     * Is this function still used by kind-select-dropdown?
     */
    ctrl.editKindDropDownItem = function(item){

        modalFactory.editGroupModal.open({
            modal_settings: {
                mode: 'edit',
                groupItem: item,
                groups: ctrl.groups,
                title: $translate.instant('HEADER.SOMEHEADER'),
                subtitle: $translate.instant('MODAL_EDIT_RECORD.SOMESUBTITLE'),
                refreshStateAfterClose: true
            },
            callbacks: {
                ok: function () {
                    setTimeout(function(){
                        $window.location.reload();
                    },0);
                },
                cancel: function () {}
            }
        });

    };

    /**
     * Add available kinds.
     * Before this function can be called, groups must be loaded.
     */
    ctrl.prepareKinds = function() {
        var availableKindsPrep = [{
                kind: chicoEnums.KindEnum.ALLOWED_BASIC,
                name: chicoEnums.kindToText(chicoEnums.KindEnum.ALLOWED_BASIC),
                selected: true,
                editable: false,
                addCircleClass: 'scg-multiselect-badge-green',
                circleContent: '<i class="fa fa-binoculars"></i>'
            }, {
                kind: chicoEnums.KindEnum.ALLOWED_OPTIONS,
                name: chicoEnums.kindToText(chicoEnums.KindEnum.ALLOWED_OPTIONS),
                selected: true,
                editable: false,
                addCircleClass: 'scg-multiselect-badge-green',
                circleContent: '<i class="fa fa-binoculars"></i>'
            }, {
                kind: chicoEnums.KindEnum.TIMELIMITED,
                name: chicoEnums.kindToText(chicoEnums.KindEnum.TIMELIMITED),
                selected: true,
                editable: false,
                addCircleClass: 'scg-multiselect-badge-red',
                circleContent: '<i class="fa fa-binoculars"></i>'
            }, {
                kind: chicoEnums.KindEnum.BLOCKED,
                name: chicoEnums.kindToText(chicoEnums.KindEnum.BLOCKED),
                selected: true,
                editable: false,
                addCircleClass: 'scg-multiselect-badge-yellow',
                circleContent: '<i class="fa fa-binoculars"></i>'
            }
        ];

        _.each(ctrl.groups, function (group /*, index*/ ) {

            var circleContent = group.kind - 10;

            availableKindsPrep.push({
                kind: group.kind,
                name: "#" + circleContent + " " + group.name,
                selected: true,
                editable: true,
                addCircleClass: 'scg-multiselect-badge-yellow',
                circleContent: circleContent
            });
        });

        ctrl.availableKinds = availableKindsPrep;
    };

    ctrl.renameKindDropDownItem = function(kind, newName){
        var itemToChange = _.filter(ctrl.availableKinds, {kind: kind});
        itemToChange.name = newName;
        scgSettingsFactory.changeGroupName({groupkind: kind, groupName: newName}).then(function(res){
            //$state.go($state.current, {}, {reload: true});
        });
    };
}
