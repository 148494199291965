'use strict';
angular.module('ChicoApp.CC-Emailreport')

    .controller('CC-EmailReportController', function(
      $scope,
      $state,
      $translate,
      $http,
      API,
      AuthFactory,
      apiFunctions,
      $log,
      ccEmailReportFactory,
      _,
      scgSharedFactory,
      scgGlobalSpinnerFactory) {

      scgGlobalSpinnerFactory.blockUI();

      $scope.data = {};
      $scope.mailResult = '-1';
      $scope.hintHide = true;
      $scope.confirmAddress = '';

      ccEmailReportFactory.getSettings()
            .then(function success(response) {

              $log.info('CC-EmailReportFactory returned SUCCESS');
              $scope.data = response.data;

              //add watchers
              _.each($scope.data, function(v, k) {
                $scope.$watch('data.' + k, function(new_val, old_val) {
                  if (new_val != old_val) {
                    var changed_object = {};
                    changed_object[k] = new_val;
                    $log.info('Howdy, settings Changed!');
                    ccEmailReportFactory.setSetting(changed_object);
                  }
                }, true);
              });
              scgGlobalSpinnerFactory.unblockUI();
            });

      $scope.checkNeedConfirm = function() {
        var req = {
          method: 'GET',
          url: apiFunctions.get_cc_base_url() + 'ccsettings/needmailconfirm'
        };
        return $http(req)
          .then(function success(response) {
            //$scope.confirmAddress = response.data;
            $log.info('RETURN HTTP', response.data);
            if (response.data) {
              $scope.confirmAddress = response.data;
            }
          }, function error(err) {
            $log.info('error while HTTP GET');
            return err;
          });
      };

      $scope.sendConfirmMail = function() {
        var obj = {};
        if ($translate.use() == 'de_DE') {
          obj.lang = 'de';
        } else {
          obj.lang = 'en';
        }
        var req = {
          method: 'POST',
          url: apiFunctions.get_cc_base_url() + 'ccsettings/confirmmail',
          data: obj,
        };
        return $http(req)
            .then(function success(response) {
              $log.info('RETURN HTTP', response.data);
              if (response.data) {
                $scope.mailResult = '1'; //set success case (because we are using here also testmail routine)
                angular.element('#modalTestmail').modal();
              }
            }, function error(err) {
              $scope.mailResult = '0'; //set FAIL case (because we are using here also testmail routine)
              angular.element('#modalTestmail').modal();
              $log.info('error while HTTP GET');
              return err;
            });
      };

      $scope.testMail = function() {
        var obj = {};
        obj.receiver = $scope.data.emailReportRecipient;
        obj.subject = $scope.data.emailReportSubject;
        obj.lang = 'de';
        if ($translate.use() == 'de_DE') {
          obj.lang = 'de';
        } else {
          obj.lang = 'en';
        }
        $log.info('Testmail to:' + $scope.data.emailReportRecipient + ' with obj.lang=' + obj.lang);
        var req = {
          method: 'POST',
          url: API.url + 'action/testmail',
          data: obj
        };
        return $http(req)
                .then(function success(response) {
                  $scope.mailResult = '-1';
                  if (response.data.responsecode == 0) {
                    $scope.mailResult = '0';
                  }
                  if (response.data.responsecode == 1) {
                    $scope.mailResult = '1';
                  }
                  angular.element('#modalTestmail').modal();
                  return response;
                }, function error(err) {
                  $log.info('error while HTTP GET');
                  return err;
                });
      };

      //Hint stuff, Add $scope.hintHide at top of page
      //--------------------------------------------------------------------------------
      scgSharedFactory.getHint(70).                                //adjust hintID here
          then(function success(response) {
            if (response.data.hint70) {                         //adjust hintID here
              $scope.hintHide = response.data.hint70;}        //adjust hintID here
            else {
              //not assgined, set to false!
              $scope.hintHide = false;}
          }
      );
      $scope.hintDone = function() {
        $scope.hintHide = true;
        scgSharedFactory.setHint(70).then(function success() {
              $log.info('HINT DONE SUCCESS');
            }
      );};
      //--------------------------------------------------------------------------------


    $scope.checkReceiver = function (value)
    {
        //simply do a reload to check
        $log.info('checkReciever value=' , value);
        if (value === undefined) return;
        if (value.indexOf("@") != -1) { $state.reload();}
    }


    $scope.unblockEmail = function() {
        $log.info('unblock arrived for:', $scope.data.blcheck);
        var langID = "de";
        if ($translate.use()== 'en_US') {langID='en';}
        var req = {
            method: 'GET',
            url: API.url + 'action/subscribe/' + langID + "/" + $scope.data.blcheck
        };
        $log.info('unblock url:', req.url);
        return $http(req)
            .then(function success(response) {
                $log.info("SUCCESS!");
                angular.element('#modalSubscribe').modal();
            });
    }


      $scope.checkNeedConfirm();

    });

