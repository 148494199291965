'use strict';
angular.module('ChicoApp.CB-SYNC')
    .controller('CB-SyncController', function(
      $rootScope,
      $scope,
      $http,
      $log,
      $timeout,
      $translate,
      API,
      moment,
      AuthFactory,
      scgSharedFactory,
      modalFactory,
      scgGlobalSpinnerFactory) {

      $scope.data = {};
      $scope.sprache = $translate.use();
      $scope.pollSyncStatusInterval = null; //just to be declaged globally
      $scope.pollSyncStatusIntervalActive = false;
      $scope.pollSyncStatusTimeout = null;
      $scope.isCB3 = false;
      $scope.lastSyncDiff = 0;

      //Gather lastsync date (async!)
      $scope.lastSync = '   ' + $translate.instant('MISC.RESOLVING');
      scgSharedFactory.getLastSync().then(function success(lastSync) {

          var lastsynctime = null;
          if (lastSync!=null) {
              lastsynctime = lastSync.substring(0,10);
          }
          var browsertime =  moment().format('YYYY-MM-DD');
          var a = moment(lastsynctime);
          var b = moment(browsertime);
          var timeDiff = b.diff(a, 'days');
          $scope.lastSyncDiff = timeDiff;


        $scope.lastSync = scgSharedFactory.formatDateTime(lastSync);
      });

      $scope.showModal = function(mymessage) {
        $log.info('opening info modal');

        modalFactory.infoModal.open({
          modal_settings: {
            title: $translate.instant('HEADER.INFO'),
            message: mymessage,
            butOkLabel: $translate.instant('BUTTON.OK'),
            iconNo: 1,
            butCancelLabel: null,
            refreshStateAfterClose: true
          },
          callbacks: {
            ok: function() {
              $log.info('modal closed with ok -> callback');
            },
            cancel: function() {
              $log.info('modal closed with cancel -> callback');
            }
          }
        });
      };

      //initially set language to scope to have it in the template
      $log.info('sprache=' + $scope.sprache);

      $scope.cbSyncAsync = function() {

        scgSharedFactory.getLastSync().then(function(previousSync) {
          var req = {
            method: 'GET',
            url: API.url + 'action/SyncNowAsync'
          };

          return $http(req)
              .then(function success(response) {

                $scope.pollSyncStatusTimeout = $timeout(function() {
                  if ($scope.pollSyncStatusIntervalActive == true) {
                    clearInterval($scope.pollSyncStatusInterval);
                    $scope.showModal($translate.instant('MSG.SYNCFAILURE'));
                  }
                }, 75 * 1000);

                //sync async started, now waiting for result via poll
                $scope.pollSyncStatusIntervalActive = true;
                $scope.pollSyncStatusInterval = setInterval(function() {

                  //get last sync via interval
                  scgSharedFactory.getLastSync().then(function(latestSync) {
                    if (moment(previousSync).format('x') < moment(latestSync).format('x')) {
                      clearInterval($scope.pollSyncStatusInterval);
                      $scope.pollSyncStatusIntervalActive = false;
                      $log.info('NEEDSSYNC=FALSE 8');
                      scgSharedFactory.needsSync = false;
                      $scope.showModal($translate.instant('MSG.SYNCSUCCESS'));
                    }
                  });

                }, 4000);

                return response;

              }, function error(err) {

                $log.info('error');

                $scope.pollSyncStatusIntervalActive = false;
                return err;

              });

        });

      };


        //---------------------------------------------------------------------------------------------------------------
        //infomdal for re-use in this scope
        $scope.myInfoModal = function(msg) {
            scgGlobalSpinnerFactory.unblockUI();
            modalFactory.infoModal.open({
                modal_settings: {
                    title: $translate.instant('HEADER.INFO'),
                    message: msg,
                    butOkLabel: $translate.instant('BUTTON.OK'),
                    butCancelLabel: null,
                    iconNo: 1,
                    refreshStateAfterClose: true
                },
                callbacks: {
                    ok: function() {
                        $log.info('modal closed with ok -> callback');
                    }
                }
            });
        };


        //watcher only for isCB3 (watcher)
        $rootScope.$watch(function() {
            return AuthFactory.activeDevice;
        }, function(activeDevice) {
            if (AuthFactory.activeDevice.deviceType==30) {
                $scope.isCB3=true;
            } else {
                $scope.isCB3=false;
            }
        },true);


      //OLD SYNC METHOD
      $scope.cbSync = function() {

        $log.info('cbSync GOES HERE');
        scgGlobalSpinnerFactory.blockUI();

        var req = {
          method: 'GET',
          url: API.url + 'action/SyncNow'
        };

        return $http(req)
                .then(function success(response) {
                  $log.info('URL was:' + req.url);
                  $log.info('response is:' , response);
                  scgGlobalSpinnerFactory.unblockUI();
                  if (response.data == 'SUCCESS') {
                    $log.info('NEEDSSYNC=FALSE 9');
                    scgSharedFactory.needsSync = false;
                    $scope.showModal($translate.instant('MSG.SYNCSUCCESS'));
                  }
                  if (response.data == 'FAILED') {
                    $scope.showModal($translate.instant('MSG.SYNCFAILURE'));
                  }
                  return response;
                }, function error(err) {
                  scgGlobalSpinnerFactory.unblockUI();
                  $log.info('error while HTTP GET');
                  return err;
                });
      };


        $scope.syncReset = function() {
            modalFactory.infoModal.open({
                modal_settings: {
                    title: $translate.instant('MSG.QUESTION'),
                    message: $translate.instant('INFO.SYNC_RESET2') + ' ' + $translate.instant('MSG.SURE'),
                    butOkLabel: $translate.instant('BUTTON.YES'),
                    butCancelLabel: $translate.instant('BUTTON.NO'),
                    iconNo: 2,
                    refreshStateAfterClose: false
                },
                callbacks: {
                    ok: function() {
                        //OK CALLBACK
                        scgGlobalSpinnerFactory.blockUI();
                        scgSharedFactory.mastersyncCB()
                            .then(function success(response) {
                                $log.info('masterSync returned success:',response.data);
                                scgGlobalSpinnerFactory.unblockUI();

                                //Send also FCM_SYNC_RESET to device (so the device transfers all apps again)
                                var req = {
                                    method: 'GET',
                                    url: API.url + 'action/syncnowreset'
                                };
                                return $http(req)
                                    .then(function success(response) {
                                        $log.info('URL was:' + req.url);
                                        $log.info('response is:' , response);
                                        $scope.myInfoModal($translate.instant('MSG.SSESUCCESS'));
                                    }, function error(err) {
                                        $log.info('error while HTTP GET');
                                    });









                            });
                    },
                },
                cancel: function() {
                }
            });
        }




        window.scrollTo(0,0);
    });
