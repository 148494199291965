'use strict';
angular.module('ChicoApp.CC-Emailreport', [])

    .config(function($stateProvider) {
      $stateProvider.state('cc-emailreport', {
        url: '/cc-emailreport',
        controller: 'CC-EmailReportController',
        templateUrl: 'templates/childcontrol/cc_emailreport/templates/emailreport.html',
      });
    })

    .run(function() {});
