angular.module('ChicoApp').factory('ccInternetMiscFactory', function(
  $http,
  apiFunctions) {

  var factory = {};

  //Add stage 2
  //'webCheckAllowedKeyword',
  //'webRedirect',
  //'webRedirectURL',

  var apikeys = [
    'webNoWarning',
    'webNeutralWarning',
    'webNoHttps',
    'webNoIP',
    'webNoDownload',
    'webDisableSSLFilter',
    'webRedirect',
    'webRedirectBlockpage',
    'webRedirectUrl',
    'NFSOff',
    'webDisableSSL',
    'nfsAutoInetPRG'
];

  var apikeys_param = apiFunctions.obj_to_url_params(apikeys);

  //main function called by controller
  //---------------------------------------------------------------
  factory.getSettings = function() {
    return factory.getByParams(apikeys_param);
  };

  //only helper func, is called from above func
  //---------------------------------------------------------------
  factory.getByParams = function(params) {
    var req = {
      method: 'GET',
      url: apiFunctions.get_cc_base_url() + 'ccsettings' + params
    };
    return $http(req);
  };


    //is called by watcher from controller
    //---------------------------------------------------------------
    factory.setSetting = function(obj) {
        var req = {
            method: 'POST',
            url: apiFunctions.get_cc_base_url() + 'ccsettings',
            data: obj
        };
        return $http(req);
    };



  factory.export = function(kind) {
    var listType = 'blacklist'; //default
     if (kind==2) {
        listType='whitelist';
     }
    var req = {
      method: 'GET',
      url: apiFunctions.get_cc_base_url() + 'service/export/cc/' + listType
    };
    return $http(req);
  };




  return factory;

});

