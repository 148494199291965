'use strict';
angular.module('ChicoApp.CB-GPS', [])
    .config(function($stateProvider) {
      $stateProvider.state('cb-gps', {
        url: '/cb-gps',
        controller: 'CB-GPSController',
        templateUrl: 'templates/chicobrowser/cb_gps/templates/gps.html',
      });
    })
    .run(function() {});
