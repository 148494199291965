'use strict';
angular.module('ChicoApp.CC-Programs')

    .controller('CC-Programs-ByAge-Controller', function(
      $rootScope,
      $modal,
      $scope,
      _,
      $log,
      ccProgramsByAgeFactory,
      scgSharedFactory) {

      $scope.data = {};

      ccProgramsByAgeFactory.getSettings()
            .then(function success(response) {

              $scope.data = response.data;
              $log.info('MISC DATA ', $scope.data);

              //add watchers
              _.each($scope.data, function(v, k) {
                $scope.$watch('data.' + k, function(new_val, old_val) {
                  if (new_val != old_val) {
                    var changed_object = {};
                    changed_object[k] = new_val;
                    scgSharedFactory.needsSync = true;
                    ccProgramsByAgeFactory.setSetting(changed_object);
                    $log.info('Changed, DATA', $scope.data);

                  }
                }, true);
              });

            });

    });
