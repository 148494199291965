'use strict';
angular.module('ChicoApp.CC-Settings', [])
    .config(function($stateProvider) {
      $stateProvider.state('cc-settings', {
        url: '/settings',
        template: '<ui-view />',
        redirectTo: 'cc-settings.general',
        controller: 'CC-Settings-General'
      })
          .state('cc-settings.general', {
            url: '/general',
            templateUrl: 'templates/childcontrol/cc_settings/templates/settings-all.html',
            controller: 'CC-Settings-Extended',
            params: {
              highlight: null
            },
          })
            .state('cc-tans', {
              url: '/tans',
              templateUrl: 'templates/childcontrol/cc_settings/templates/settings-tans.html',
              controller: 'CC-Settings-TAN',
            })

              .state('cc-settings.pwreset', {
                url: '/pwreset',
                templateUrl: 'templates/childcontrol/cc_settings/templates/settings-resetpw.html',
                controller: 'CC-Settings-ResetPw',
              })
            .state('cc-settings.exclusions', {
              url: '/exclusions',
              templateUrl: 'templates/childcontrol/cc_settings/templates/settings-exclusions.html',
              controller: 'CC-Settings-Exclusion',
            });
    })
    .run(function() {});
