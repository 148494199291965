/**
 * Displays the corresponding icon/badge depending on the given kind.
 * Differentiates between icon and badge:
 * Icon: E.g. used for allowed apps
 * Badge: E.g. used for Always allowed apps
 */
angular.module('ChicoApp.Shared').component('kindBadge', {
    templateUrl: 'templates/shared/kindBadge/kindBadge.component.html',
    controller: KindBadgeController,
    bindings: {
        /**
         * @type {KindEnum}
         */
        kind: '<',
        /**
         * @type {?chicoEnums.ContextEnum} Context for which the icon/batch is displayed. Only required to resolve the correct BonusType.
         */
        context: '<?',
        /**
         * @type {AppBonusTypeEnum|PrgBonusTypeEnum|undefined} Controls the display of badges that involve the bonus type (if omitted, these badges won't be shown)
         */
        bonusType: '<?',
        /**
         * @type {?boolean} Only show the corresponding icon
         */
        onlyIcons: '<?',
        /**
         * @type {?boolean} Only show the corresponding badge
         */
        onlyBadges: '<?'
    }
});

function KindBadgeController(
    chicoEnums
) {
    var ctrl = this;

    ctrl.chicoEnums = chicoEnums;

    ctrl.showIcons = true;
    ctrl.showBadges = true;

    ctrl.ContextBonusTypeEnum = chicoEnums.AppBonusTypeEnum;

    this.$onChanges = function(changes) {
        if (changes.context && typeof ctrl.context !== 'undefined') {
            if (ctrl.context === chicoEnums.ContextEnum.CB) {
                ctrl.ContextBonusTypeEnum = chicoEnums.AppBonusTypeEnum;
            } else if (ctrl.context === chicoEnums.ContextEnum.CC) {
                ctrl.ContextBonusTypeEnum = chicoEnums.PrgBonusTypeEnum;
            }
        }

        if (changes.onlyIcons) {
            if (ctrl.onlyIcons) {
                ctrl.showBadges = false;
            } else {
                ctrl.showBadges = true;
            }
        }

        if (changes.onlyBadges) {
            if (ctrl.onlyBadges) {
                ctrl.showIcons = false;
            } else {
                ctrl.showIcons = true;
            }
        }
    };
}