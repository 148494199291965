angular.module('ChicoApp.Shared').controller('SidebarController', function(
    $rootScope,
    $cookies,
    $scope,
    $log,
    $timeout,
    _,
    $state,
    $window,
    $http,
    API,
    AuthFactory,
    menuFactory,
    DeviceFactory) {

  $scope.$state = $state;
  $scope._ = _;
  $scope.currentActiveDevice = null;

  $scope.showSidebar = false;
  $scope.showSidebarOverlay = false;
  $scope.sidebarOverlayTransparent = true;

  //show Menu if there is a SessionId
  $scope.$watch(function() {
    return AuthFactory.loggedin();
  }, function(newVal) {
    if (newVal == true) {
      $scope.loggedin = true;
    }else {
      $scope.loggedin = false;
    }
  });

  $scope.$watch(function() {
    return menuFactory.activeMenu;
  }, function(activeMenu) {
    $log.info('MENU active' , activeMenu);
    $scope.menu = activeMenu;
  });

  $scope.logout = function(param) {

    // Dirty trick to hide menu bar by applying report role
    // Needed to center content logoff message in case its a device login
    AuthFactory.userRole = 'report';

    var req = {
      method: 'GET',
      url: API.url + 'action/sendpushall',
    };

    $http(req);
    AuthFactory.logout(param);
  };

  $scope.sync = function() {
    $log.info('SYNC HIT');
  };

  var w = angular.element($window);
  $scope.menutype = $window.matchMedia('(max-width: 991px)').matches ? 'mobil' : 'desktop';

  w.bind('resize', function() {
    if ($window.matchMedia('(max-width: 991px)')
        .matches) {
      $scope.menutype = 'mobil';
    } else {
      $scope.menutype = 'desktop';
    }

    $scope.$apply();
  });

  $rootScope.$watch(function() {
    return AuthFactory.activeDevice;
  }, function(activeDevice) {
    DeviceFactory.getDeviceById(activeDevice.deviceId).then(
            function success(device) {

              if (typeof Object.assign != 'function') {
              //IE bullshit polyfill goes here
                Object.assign = function(target, varArgs) { // .length of function is 2
                  'use strict';
                  if (target == null) { // TypeError if undefined or null
                    throw new TypeError('Cannot convert undefined or null to object');
                  }
                  var to = Object(target);
                  for (var index = 1; index < arguments.length; index++) {
                    var nextSource = arguments[index];
                    if (nextSource != null) { // Skip over if undefined or null
                      for (var nextKey in nextSource) {
                        // Avoid bugs when hasOwnProperty is shadowed
                        if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                          to[nextKey] = nextSource[nextKey];
                        }
                      }
                    }
                  }
                  $scope.currentActiveDevice = to;
                  $log.info('SIDEBAR:', $scope.currentActiveDevice);
                  return to;
                };
              } else {
                //Normal behaviour for Chrome and Firefox
                $scope.currentActiveDevice = Object.assign({}, device);
                $log.info('SIDEBAR:', $scope.currentActiveDevice);
                $scope.currentActiveDevice.appVersion = ($scope.currentActiveDevice || "0.0.000").versionInfo.split("|")[0].replace(/\./g, "");
                $log.info('SIDEBAR deviceType=', $scope.currentActiveDevice.deviceType);
              }
            }
            ,function error(error) {
              $log.info(error);
            }
        );
  },true);


  /* Mobile Sidebar */

  $rootScope.$on("openMobileSidebar", function(event) {
    $scope.showSidebarOverlay = true;
    $scope.showSidebar = true;
    
    // Delay fade animation because overlay needs to be display: block before
    $timeout(function() {
      $scope.sidebarOverlayTransparent = false;
    }, 20)
  });

  $scope.closeSidebar = function() {
    $scope.showSidebar = false;
    $scope.sidebarOverlayTransparent = true;
    
    $timeout(function() {
      // Check if sidebar is opened again before overlay was hidden
      // Prevents case where sidebar is opening again and overlay gets hidden in the meantime
      if (!$scope.showSidebar) {
        $scope.showSidebarOverlay = false;
      }
    }, 200);
  }
});
