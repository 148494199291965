'use strict';
angular.module('ChicoApp.Login')

    .controller('PWChangeController', function(
        $rootScope,
        $scope,
        $stateParams,
        $translate,
        $state,
        $http,
        $timeout,
        $log,
        DeviceFactory,
        AuthFactory,
        PwChangeFactory,
        scgGlobalSpinnerFactory,
        cbListsFactory,
        DTOptionsBuilder,
        DTColumnDefBuilder,
        scgDataTableFactory,
        scgSharedFactory,
        modalFactory) {

      $scope.pw = '';
      $scope.pwrepeat = '';
      $scope.pwToken = $stateParams.token || null;
      $scope.changeSuccess = -1;
      $scope.errMsg = '';

      scgSharedFactory.needsSync = false;
      AuthFactory.logout(false);

      //Handle language
      $scope.pwLang =  $stateParams.lang || 'en';
      $log.info('lang=' + $scope.pwLang);
      if ($scope.pwLang === 'de') {
        $translate.use('de_DE');
      }

      if (!$scope.pwToken) {
        $log.info('NO TOKEN!!!');
        AuthFactory.logout(true);
      }

      $log.info('INIT PWCHANGE!!');
      $scope.pwChange = function() {
        $log.info('pwChange() fired');
        scgGlobalSpinnerFactory.blockUI();
        $log.info('pw=' + $scope.pw + ' | pwrepeat=' + $scope.pwrepeat + ' | pwToken=' + $scope.pwToken);

        if ($scope.pwToken) {
          PwChangeFactory.checkToken($scope.pwToken)
                .then(function success() {
                  $log.info('TOKEN VALID');

                    if ($scope.pw.length < 5) {
                        $scope.errMsg = $translate.instant('ERROR.PW_TOO_SHORT');
                        return;
                    }

                    if ($scope.pw != $scope.pwrepeat) {
                        $scope.errMsg = $translate.instant('ERROR.PW_REPEAT_WRONG');
                        return;
                    }

                  //if passwords match
                  if ($scope.pw == $scope.pwrepeat && $scope.pw.length > 4 && $scope.pw) {
                    $log.info('resetting pw');
                    PwChangeFactory.setNewPassword($scope.pwToken, $scope.pw)
                            .then(function success() {
                              $scope.changeSuccess = 1;
                              scgGlobalSpinnerFactory.unblockUI();
                              $log.info('pw reset success');
                              $scope.showSuccess();
                            }, function error() {
                              $scope.changeSuccess = 0;
                            });
                  } else {
                    $scope.changeSuccess = 0;
                    scgGlobalSpinnerFactory.unblockUI();
                    $log.info('error happened');
                  }
                }, function error() {
                  $scope.changeSuccess = 0;
                  $log.info('error callback for http req');
                });
        }

      };

      $scope.showSuccess = function() {

        modalFactory.infoModal.open({
          modal_settings: {
            title: $translate.instant('HEADER.INFO'),
            message: $translate.instant('LOGIN.SUCCESSPWNEW'),
            butOkLabel: $translate.instant('BUTTON.OK'),
            butCancelLabel: null,
            iconNo: 1,
            refreshStateAfterClose: false
          },
          callbacks: {
            ok: function() {
              $log.info('OK sucess ');
              AuthFactory.logout(true);
            }
          }
        });

      };

    });
