'use strict';
angular.module('ChicoApp.CB-Settings', [])
    .config(function($stateProvider) {
      $stateProvider.state('cb-settings', {
        url: '/cb-settings',
        controller: 'CB-SettingsController',
        templateUrl: 'templates/chicobrowser/cb_settings/templates/settings.html',
      })

          .state('cb-tans', {
              url: '/cb-tans',
              templateUrl: 'templates/chicobrowser/cb_settings/templates/settings-tans.html',
              controller: 'CB-TANS',
          })

            .state('cb-teststuff', {
              url: '/cb-teststuff',
              controller: 'CB-TeststuffController',
              templateUrl: 'templates/chicobrowser/cb_settings/templates/teststuff.html',
            })

          .state('cb-testappdetail', {
              url: '/cb-testappdetail',
              controller: 'CB-TeststuffController',
              templateUrl: 'templates/chicobrowser/cb_settings/templates/testappdetail.html',
          })

          .state('cb-pwreset', {
            url: '/cb-pwreset',
            templateUrl: 'templates/chicobrowser/cb_settings/templates/settings-resetpw.html',
            controller: 'CB-Settings-ResetPw',
          })

        ;
    })
    .run(function() {});
