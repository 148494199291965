angular.module('ChicoApp').factory('DeviceFactory', function(
  $http,
  API,
  _,
  $log,
  AuthFactory,
  $state,
  $cacheFactory,
  $cookies,
  $q
) {

  var devices = {};
  devices.lastDeviceApiCall;

  devices.deviceList = [];

  devices.getDevices = function() {

    var deviceRequest = function() { return $http({
        method: 'GET',
        cache: true,
        url: API.url + 'service/devices'
      }).then(function success(response) {
        // console.log('DeviceFactory: loading devices');
        _.each(response.data, function(device) {
          //set icon info based on device type
          if (device.deviceType == 1 || device.deviceType == 2) {
            device.deviceIcon = 'desktop';
          }else if (device.deviceType == 10 || device.deviceType == 11 || device.deviceType == 30) {
            device.deviceIcon = 'mobile';
          }else if (device.deviceType == 40) {
              device.deviceIcon = 'rocket';
          }else if (device.deviceType == 50) {
              device.deviceIcon = 'globe';
          }
          //find the device that is active based on device id from cookie
          if (AuthFactory.activeDevice && device.deviceId == AuthFactory.activeDevice.deviceId) {
            AuthFactory.activeDevice = device;
          }
        });

        devices.deviceList = response.data;
        devices.lastDeviceApiCall = Date.now();

        return devices.deviceList;

      }, function error(err) {
        // devices.lastDeviceApiCall = Date.now();
        $log.info('DeviceFactory: error while getting devicelist err=' , err);
        if (err.data.Message==='No devices found!') {
          $log.info('DeviceFactory no devices found!');
          //debugger;
          //$state.go('devicenotfound');
          //return;
        }

        return err;

      });
    };

    if (devices.lastDeviceApiCall + 90 < Date.now()) {
      //clear cache
      $log.info('DeviceFactory: clearing cache');
      var httpCache = $cacheFactory.get('$http');
      httpCache.remove(API.url + 'service/devices');
    }

    return deviceRequest();

  };

  devices.getDeviceById = function(deviceId) {
    var deferred = $q.defer();

    // $log.info('DeviceFactory: getting Device by Id');

      if( !$cookies.get('pcId') || !$cookies.get('sessionId') ){
        $log.info('stopping devices call because no session and pcid present');
        deferred.reject('No auth info present yet');
        return deferred.promise;
      }

    devices.getDevices().then(function success() {
        var deviceitems = devices.deviceList;
        for (var i = 0; i < deviceitems.length; i++) {
          if (deviceitems[i].deviceId == deviceId) {
            // console.log('DeviceFactory: getting Device by Id done');
            deferred.resolve(deviceitems[i]);
          }
          if (i == deviceitems.length - 1) {
            deferred.reject('Device not in Devicelist');
          }
        }
      });

    return deferred.promise;

  };

  return devices;
});
