'use strict';
angular.module('ChicoApp.CC-TimeLimits')

.controller('CC-TimeLimitsControllerPC', function(
    $rootScope,
    $scope,
    $state,
    $http,
    $timeout,
    $translate,
    $log,
    moment,
    _,
    modalFactory,
    AuthFactory,
    ccTimeLimitsFactory,
    ccListsFactory,
    scgGlobalSpinnerFactory,
    ccDashboardFactory,
    scgSharedFactory,
    $anchorScroll,
    $location) {

  var timeTableSaveQueue;

  $scope.data = {};
  $scope.isTouchDevice=false;
  $scope.afterExpire = 1;
  $scope.hintHide = false;
  $scope.extensionInfo = '';
  $scope.featureNoExtAvailable = false;
  $scope.hasRoamingV2 = false;
  $scope.hasCCFeature = scgSharedFactory.hasCCFeature;           //needed for hasFeature markup
  $scope.modalFactory = modalFactory;
  $scope.showBlockTimes = false;
  $scope.limitConflict = false;
  $scope.hintLimitConflictHide= false;

  //init timetable
  $scope.timeTableOptions = {
    reset: true
  };
  $scope.resetTimeTable = function() {

  };

    var hasTouchscreen = 'ontouchstart' in window;
    $log.info('cc-timelimit hasTouchscreen=', hasTouchscreen);
    if (hasTouchscreen===true) {
        $scope.isTouchDevice=true;
    }


    $scope.openSettingsWithHighlight = function(highlight) {
    $state.go('cc-settings.general', {highlight: highlight});
  };

  $scope.isNoExtAvailable = function() {
    scgSharedFactory.getCCVersionNumber().then(function success(version) {
      $log.info('CCVersion number is:', version);
      if (version.major >= 16 && version.minor > 1942) {
        $scope.featureNoExtAvailable = true;
      }
    });
  };

  $scope.delExtension = function() {
    $log.info('THIS IS DEL EXTENSION');
    scgGlobalSpinnerFactory.blockUI();
    ccDashboardFactory.setExtensionTime(0)
        .then(function success() {
          $log.info('YES, WE DELETED IT');
          $scope.data.extension = 0;
          scgGlobalSpinnerFactory.unblockUI();
          $scope.myExtensionDeleteModal($translate.instant('MSG.EXTENSION_DELETED'));
        });
  };

  $scope.myExtensionDeleteModal = function(msg) {
    scgGlobalSpinnerFactory.unblockUI();
    modalFactory.infoModal.open({
      modal_settings: {
        title: $translate.instant('HEADER.INFO'),
        message: msg,
        butOkLabel: $translate.instant('BUTTON.OK'),
        butCancelLabel: null,
        iconNo: 1,
        refreshStateAfterClose: false
      },
      callbacks: {
        ok: function() {
          $log.info('modal closed with ok -> callback');
        }
      }
    });
  };

    $scope.roamingSettings = function() {
        $log.info("roamingSettings called ");
        modalFactory.roamingModal.open({
            modal_settings: {
                refreshStateAfterClose: false
            },callbacks: {
                ok: function () {
                    $log.info('modal-until ok callback');
                    $state.reload();
                }
            }
        });
    }

    $scope.getHintLimitConflict = function() {
        scgSharedFactory.getHint(101).                                //adjust hintID here
        then(function success(response) {
                if (response.data.hint101) {                         //adjust hintID here
                    $scope.hintLimitConflictHide = response.data.hint101;}        //adjust hintID here
            }
        );
    }

    $scope.hintLimitConflictDone = function() {
        $log.info('HINT CONFLICT DONE');
        $scope.hintLimitConflictHide = true;
        scgSharedFactory.setHint(101).then(function success(/*response*/) {
                $log.info('HINT DONE SUCCESS');
            }
        );};


    $scope.checkLimitConflict = function() {
        var conflict = false;
        if ($scope.data.isTimeLimitActive != true) {
            $scope.limitConflict = false;
            return;
        }
        var mins_day = scgSharedFactory.hourToMin($scope.data.timeLimitDay);
        var mins_week = scgSharedFactory.hourToMin($scope.data.timeLimitWeek);
        var mins_month = scgSharedFactory.hourToMin($scope.data.timeLimitMonth);
        $log.info('CheckConflict day=' + mins_day + '|week=' + mins_week + '|month=' + mins_month);
        if (mins_week<(mins_day*7)) conflict = true;
        if (mins_month<(mins_week*4)) conflict = true;
        $scope.limitConflict = conflict;
    }

    $scope.suggestLimit = function() {
        var mins_day = scgSharedFactory.hourToMin($scope.data.timeLimitDay);
        $scope.data.timeLimitWeek = scgSharedFactory.minToHour(mins_day/60*7);
        $scope.data.timeLimitMonth = scgSharedFactory.minToHour(mins_day/60*31);

    }


    $scope.$on('$viewContentLoaded', function() {

    scgGlobalSpinnerFactory.blockUI();
    $scope.isNoExtAvailable();

    //First the hint stuff
    $scope.getHintLimitConflict();

    ccTimeLimitsFactory.getPcSettings()

            .then(function success(response) {

              $scope.data = response.data;
              $scope.extensionInfo = $translate.instant('INFO.EXTENSION').replace('%%', response.data.extension);

              $scope.checkLimitConflict();

              $log.info('debug data' , $scope.data);
              $log.info('THIS IS PCDAY=' , $scope.data.pcTimeLimitDay);

              //watch for inputs, if they do not end in having content, refresh page
              $timeout(function() {
                var firstInputValue = angular.element(angular.element('input[ng-model=\'displayedTimeValue\']')[0]).val();
                if (!firstInputValue) {
                  scgSharedFactory.needsSync = false;
                  $state.go($state.current, {}, {reload: true});
                  scgSharedFactory.needsSync = false;
                }
              }, 1200);

              //add watchers
              _.each($scope.data, function(v, k) {
                $scope.$watch('data.' + k, function(new_val, old_val) {
                  if (new_val != old_val) {
                    var changed_object = {};
                    changed_object[k] = new_val;

                    $scope.checkLimitConflict();

                    //save timetable only with timed queue
                    if (k == 'blockedTimesPC') {

                      clearTimeout(timeTableSaveQueue);
                      timeTableSaveQueue = setTimeout(function() {
                        scgSharedFactory.needsSync = true;
                        ccTimeLimitsFactory.setSetting(changed_object);
                      }, 1500);
                    }else {
                      scgSharedFactory.needsSync = true;
                      ccTimeLimitsFactory.setSetting(changed_object);
                    }

                  }
                }, true);
              });

              if (angular.isDefined($scope.data) && angular.isDefined($scope.data.isTimeLimitActive)) {
                scgGlobalSpinnerFactory.unblockUI();

                setTimeout(function(){
                    // if an anchor is set, scroll to it
                    $anchorScroll();
                }, 100);

              }else {
                $timeout(function() {
                  $state.go($state.current, {}, {reload: true});
                }, 5000);
              }
            });

  });


    $scope.enableBlockTimes = function() {
        $scope.showBlockTimes=true;
    }

    //watcher for version (important for roamingv2)
    //------------------------------------------------------------------------
    $scope.$watch(function() {
        return AuthFactory.activeDevice.versionInfo;
    }, function(newVal) {
        if (AuthFactory.activeDevice.versionInfo) {
            $scope.hasRoamingV2 = $scope.hasCCFeature('roamingv2',AuthFactory.activeDevice.versionInfo);
            $log.info('watch version activeDevice=', AuthFactory.activeDevice.versionInfo);
        }
    });


  ccTimeLimitsFactory.getAllUsersSettings()
            .then(function success(response) {
              $scope.data = response.data;
              $scope.afterExpire = response.data.generalPCLimitLevel;
              $log.info('dierk after expire', $scope.afterExpire);
            }
        );

  ccDashboardFactory.getTodayTotal(moment(new Date()).format('YYYY-MM-DD'))
            .then(function success(response) {
              $scope.todayTotal = $translate.instant('USAGE.TODAYTOTAL') + ': ' + response.data;
            }
        );



        $scope.openEditModalClick = function() {

            $scope.uiflags = {};
            $scope.uiflags.loadingModalData = true;

            //get records for group internet
            ccListsFactory.getByListFilter(ccListsFactory.listKinds.list_kind_group_internet)
                .then(function success(response) {

                    $scope.uiflags.loadingModalData = false;

                    $log.info('res', response.data);
                    var editRecords = [];
                    _.each(response.data, function(v) {
                        editRecords.push({data: {entry: v}});
                    });

                    $log.info('edit rec', editRecords);

                    if (editRecords && editRecords.length < 1) {
                        modalFactory.infoModal.open({
                            modal_settings: {
                                title: $translate.instant('HEADER.INFO'),
                                message: $translate.instant('MSG.NO_INETPROGRAMS'),
                                butOkLabel: $translate.instant('BUTTON.OK'),
                                butCancelLabel: null,
                                iconNo: 3,
                                refreshStateAfterClose: true
                            },
                            callbacks: {
                                ok: function() {
                                    $log.info('modal closed with ok -> callback');
                                }
                            }
                        });
                    }else {
                        modalFactory.editModal.open({
                            modal_settings: {
                                mode: 'edit',
                                context: 'prg',
                                title: $translate.instant('MODAL_EDIT_RECORD.TITLE_EDIT'),
                                newRecord: null,
                                editRecords: editRecords,
                                refreshStateAfterClose: true
                            },
                            callbacks: {
                                ok: function() {},
                                cancel: function() {}
                            }
                        });
                    }

                });

        };
});
