'use strict';
angular.module('ChicoApp.SB-Dashboard', [])
    .config(function($stateProvider) {
        $stateProvider.state('sb-dashboard', {
            url: '/sb-dashboard',
            controller: 'SB-DashboardController',
            templateUrl: 'templates/browser/sb_dashboard/templates/dashboard.html'
        })

    })
    .run(function() {});
