'use strict';
angular.module('ChicoApp.Login')
    .controller('LoginNoDevicesController', function(
        $scope,
        Metronic,
        AuthFactory) {

      Metronic.unblockUI();


      $scope.logout = function() {
          AuthFactory.logout(true);
      }


    });
