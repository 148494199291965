'use strict';
angular.module('ChicoApp.CC-Usage')

    .controller('CC-Usage-Unblock', function(
        $scope,
        $cookies,
        $state,
        $window,
        $translate,
        $log,
        _,
        moment,
        ccUsageFactory,
        scgSharedFactory,
        modalFactory,
        scgGlobalSpinnerFactory) {

      $scope.data = {};
      $scope.blockedData = {};
      $scope.hintHide = true;
      $scope.showBlocked = false;
      $scope.currentUser = '';

      $scope.loadEntries = function() {
        scgGlobalSpinnerFactory.blockUI();
        $log.info('load entries');
        $scope.currentUser = $cookies.get('user');
        ccUsageFactory.getUnlockReqs()
            .then(function success(response) {
              $scope.data = response.data;
              $log.info('UNLOCKREQS ARRIVED', response.data);
              $scope.hasNoData = false;
              $scope.data = response.data;
              $log.info('LENGTH SCOPE.DATA', $scope.data.length);
              scgGlobalSpinnerFactory.unblockUI();
            });
      };

      $scope.unblockItem = function(username, entry) {
        $log.info('unblockItem for username=' + username + '|entry=' + entry) ;
        scgSharedFactory.needsSync = true;
        ccUsageFactory.doUnlock(username,entry)
            .then(function success() {
              $log.info('doUnlock DONE');
              $scope.data = _.filter($scope.data, function(row) {
                return row.entry !== entry;
              });
            });

      };

      $scope.deleteItem = function(entry) {
        $log.info('deleteItem for entry=' + entry);
        ccUsageFactory.deleteUnlockReq(entry)
            .then(function success() {
              $log.info('deleteItem DONE');
              $scope.data = _.filter($scope.data, function(row) {
                return row.entry !== entry;
              });
            });

      };

      $scope.showBlockedItems = function() {
        //$log.info("showBlockedItems, current user is:" + $cookies.user);
        $scope.showBlocked = true;
        scgGlobalSpinnerFactory.blockUI();

        //last three days
        $scope.startDate = moment().add(-5, 'd').format('YYYY-MM-DD');
        $scope.stopDate = moment().format('YYYY-MM-DD');
        $log.info('STARTDATE:' + $scope.startDate + '|STOPDATE:' + $scope.stopDate);
        $scope.howMany = 50;

        ccUsageFactory.getUsageByDates($scope.startDate,$scope.stopDate,$scope.howMany)
        .then(function success(response) {
          $scope.blockedData = response.data;
          $log.info('USAGE ARRIVED', response.data);
          scgGlobalSpinnerFactory.unblockUI();
        });

      };

      $scope.usageEdit = function(entry, context) {

        $log.info('usageEdit entry=' + entry);

        var kind = 1;
        if (context == 'net') {
          kind = 7;
        }
        if (kind == 7) {
          entry = scgSharedFactory.getTLD(entry);
        }
        modalFactory.editModal.open({
          modal_settings: {
            mode: 'add',
            context: context,
            title: $translate.instant('HEADER.LIMITENTRY'),
            newRecord: {
              kind: kind,
              texts: [entry]
            },
            editRecords: null,
            refreshStateAfterClose: false
          },
          callbacks: {
            ok: $scope.onEditModalReturn,
            cancel: $scope.onEditModalReturn
          }
        });
      };

      $scope.loadEntries();

      //Hint stuff, Add $scope.hintHide at top of page
      //--------------------------------------------------------------------------------
      scgSharedFactory.getHint(61).                                //adjust hintID here
            then(function success(response) {
              if (response.data.hint60) {                         //adjust hintID here
                $scope.hintHide = response.data.hint61;}        //adjust hintID here
              else {
                //not assgined, set to false!
                $scope.hintHide = false;}
            }
        );
      $scope.hintDone = function() {
        $scope.hintHide = true;
        scgSharedFactory.setHint(61).then(function success() {
          $log.info('HINT DONE SUCCESS');
        }
      );};
      //--------------------------------------------------------------------------------

    });
