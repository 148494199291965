'use strict';
angular.module('ChicoApp.SC-Dashboard', [])
    .config(function($stateProvider) {

        $stateProvider.state('sc-dashboard', {
            url: '/sc-dashboard',
            controller: 'SC-DashboardController',
            templateUrl: 'templates/startcontrol/sc_dashboard/templates/dashboard.html'

        })
    });
