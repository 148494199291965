'use strict';
angular.module('ChicoApp.CC-Programs')
    .controller('CC-Programs-Groups-V2-Controller-V2', function(
        $scope,
        $state,
        $translate,
        $timeout,
        ccListsFactory,
        ccProgramsFactory,
        scgSettingsFactory,
        scgDataTableFactory,
        _,
        modalFactory,
        $log) {

        var modalContext = 'prg';

        var modalStartKind = ccListsFactory.listKinds.list_kind_group_internet;

        $scope.tableHeaders = [
            $translate.instant('HEADER.ENTRY'),
            $translate.instant('HEADER.DETAILS'),
            $translate.instant('HEADER.INSTALLDATE')
        ];

        $scope.data = {};
        $scope.data.table_api = [];
        $scope.canRename = false;

        scgDataTableFactory.prepareScopeForTable($scope, modalStartKind);

        $scope.dtOptions = scgDataTableFactory.getDefaultDtOptions($scope).withOption('order', [[2, 'asc']]);
        $scope.dtColumnDefs = scgDataTableFactory.getDefaultColumnsIconGroupTable();

        $scope.reload = function() {
            $state.go($state.current, {}, {reload: true});
        };

        var loadList = function() {
            $scope.contentLoading = true;
            ccListsFactory.getDetailedByListFilter(modalStartKind)
                .then(function success(response) {
                    $scope.clearInnerList();
                    $scope.fillInnerListFromApiList(response.data);
                    $scope.data.table_api = response.data;
                    $scope.contentLoading = false;
                });
        };



        $scope.currentSelectedGroup = null;
        $scope.data.currentTableData = null;


        $scope.contentLoading = true;
        scgSettingsFactory.getGroups(true)
            .then(function success(response) {
                $scope.data.groups = response.data;
                $scope.contentLoading = false;

                var divider = {
                    name: "--------------------",
                    kind: null,
                    isDisabled: true
                };

                var newGroupOrder = [
                    $scope.data.groups[5],
                    $scope.data.groups[6],
                    $scope.data.groups[7],
                    divider,
                    $scope.data.groups[0],
                    $scope.data.groups[1],
                    $scope.data.groups[2],
                    $scope.data.groups[3],
                    $scope.data.groups[4]
                ];

                $scope.selectedGroup = $scope.data.groups[5];

                $scope.data.groups = newGroupOrder;

                $scope.$watch('selectedGroup', function(newVal, oldVal) {
                    if (newVal && newVal != oldVal) {
                        modalStartKind = newVal.kind;
                        $log.info("modalStartKind=",modalStartKind);
                        loadList();

                        if( _.includes([4,9,10], newVal.kind) ){
                            $scope.canRename = false;
                        }else {
                            $scope.canRename = true;
                        }

                        //set groupname as default value for modal input
                        $scope.newgroupname = newVal.name;
                    }
                },true);

            });



        $scope.editCurrentGroup = function() {

            //prepare list for modal required data structure
            var editRecords = [];
            _.each($scope.data.table_api, function(v) {
                var obj = {data: {entry: v.entry}};
                editRecords.push(obj);
            });

            var modalMode;
            var newRecord = null;
            if (editRecords.length < 1) {
                newRecord = {
                    //kind: $scope.currentSelectedGroup.kind
                    kind: modalStartKind
                };
                modalMode = 'add';
                editRecords = null;
            }else {
                modalMode = 'edit';
            }

            $log.info('editrecords', editRecords);

            modalFactory.editModal.open({
                modal_settings: {
                    mode: modalMode,
                    context: modalContext,
                    title: $translate.instant('MODAL_EDIT_RECORD.TITLE_EDIT'),
                    newRecord: newRecord,
                    editRecords: editRecords,
                    refreshStateAfterClose: false
                },
                callbacks: {
                    ok: function() {
                        onModalSuccess();
                    },
                    cancel: function() {
                        //nothing special
                    }
                }
            });

        };

        function onModalSuccess(){
            loadList();
        }



        $scope.renameCurrentGroup = function(newName) {

            //fired from group rename modal
            //OLD: var groupToEdit = _.findWhere($scope.data.groups, {kind:modalStartKind});
            var mydata = {};
            mydata.groupKind=modalStartKind;
            mydata.groupName=newName;
            ccProgramsFactory.setGroupname(mydata)
                .then(function success() {
                    $log.info('group rename successful');
                    //angular.element('#mymodal').modal('hide');
                    //$state.go('cc-programs.tabs.groups-v2');
                    //loadList();
                    $timeout(function () { $scope.reload(); }, 500);
                });
        }

        $scope.getImgName = function(entryName){
            var ret = (entryName || "").replace(".exe", ".png");
            return ret;
        }


        loadList();
    });
