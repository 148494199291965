'use strict';
angular.module('ChicoApp.CC-Usage')

    .controller('CB-Usage-Reports-V2', function(
        $state,
        $scope,
        $stateParams,
        $translate,
        $location,
        $anchorScroll,
        $http,
        _,
        moment,
        API,
        Metronic,
        $log,
        $q,
        $timeout,
        $cookies,
        chicoEnums,
        cbUsageFactory,
        cbListsFactory,
        DeviceFactory,
        AuthFactory,
        scgSharedFactory,
        modalFactory,
        scgGlobalSpinnerFactory,
        ccUsageFactory, //used for age lookup
        cbDashboardFactory) {

        $scope.data = {};
        $scope.dataTimes = {};
        $scope.rgTimes =  {}; //roamingGroupTimes
        $scope.dataHistory = {};
        $scope.weekstats = {};
        $scope.weekstatsPeriod = 7;
        $scope.loadingWeekStats=false;
        $scope.loadingChart = false;
        $scope.dataLimitinfo = {};
        $scope.dataSettings = {};
        $scope.dataSettings.roaming = "";
        $scope.roamingGroup = -1;
        $scope.myTestVar = "3";
        $scope.serverTimeDiff =0;
        $scope.hintHide = true;
        $scope.showLong = false;
        $scope.period = 'week'; //default
        $scope.howMany = '250';
        $scope.isCustomPeriod = false;
        $scope.customStartDate = '2010-01-01';
        $scope.customStopDate = '2010-01-01';

        $scope.howManyUnlockRequests = -1;
        $scope.scgSharedFactory = scgSharedFactory;
        $scope.hasNoData = false; //no more used
        $scope.hasNoDataTimes = false;
        $scope.hasNoDataHistory = false;
        $scope.age = {};
        $scope.isAgeEdit = false;
        $scope.perioddropdown = '1';
        $scope.hasRoaming = false;
        $scope.isReport = false;
        $scope.isReport = $cookies.get('role') == 'report' ? true : false;

        //Dierk urgent to remove
        //$scope.isReport = true;

        $scope.showLimitinfo = false;
        $scope.limitInfoDone = false;
        $scope.isCB3 = false;

        $scope.totalTime = 0;
        $scope.totalExtension = 0;
        $scope.loadingTimes = false;
        $scope.loadingHistory = false;

        $scope.formatSecs = scgSharedFactory.formatSecs;

        $scope.preselectedWeekday = $state.params.weekday;
        $scope.loadingUsage = false;

        $scope.hasRoamingGroupData = false;
        $scope.roamingData = {};
        $scope.loadingRoaming = false;
        $scope.dataSettingsDone = false;

        $scope.loadingTimes = false;
        $scope.loadingHistory = false;
        $scope.isHistoryIssue = false;
        $scope.hasAlwaysEntries = false;

        $scope.defaultCountTimes = 15;
        $scope.defaultCountHistory = 25;

        $scope.timesApp = {};
        $scope.totalTimesApp = 0;
        $scope.rowTimesApp = $scope.defaultCountTimes;

        $scope.timesUrl = {};
        $scope.totalTimesUrl = 0;
        $scope.rowTimesUrl = $scope.defaultCountTimes;

        $scope.historyApp = {};
        $scope.totalHistoryApp = 0;
        $scope.rowHistoryApp = $scope.defaultCountHistory;

        $scope.historyUrl = {};
        $scope.totalHistoryUrl = 0;
        $scope.rowHistoryUrl = $scope.defaultCountHistory;

        $scope.historyBlocked = {};
        $scope.totalHistoryBlocked = 0;
        $scope.rowHistoryBlocked = $scope.defaultCountHistory;

        $scope.historySystem = {};
        $scope.totalHistorySystem = 0;
        $scope.rowHistorySystem = $scope.defaultCountHistory;

        $scope.historyPortal = {};
        $scope.totalHistoryPortal = 0;
        $scope.rowHistoryPortal = $scope.defaultCountHistory;

        $scope.isRoamingView = false;
        $scope.tmpLim = {};
        $scope.tmpLim.limitInfo = {};


        //Dierk: use clause for underscore
        $scope._ = _;

        //Dierk Groupmembers
        $scope.hasGroupmembers = false;
        $scope.showgroup = [];
        $scope.showgroupRoaming = [];
        $scope.gpm = [];
        $scope.gpm[110] = [];
        $scope.gpm[111] = [];
        $scope.gpm[112] = [];
        $scope.gpm[113] = [];
        $scope.gpm[114] = [];
        $scope.gpm[115] = [];
        $scope.gpmRoaming = [];
        $scope.gpmRoaming[10] = [];
        $scope.gpmRoaming[11] = [];
        $scope.gpmRoaming[12] = [];
        $scope.gpmRoaming[13] = [];
        $scope.gpmRoaming[14] = [];
        $scope.gpmRoaming[15] = [];

        $scope.gpmDone=[];
        $scope.gpmDone[111]=false;
        $scope.gpmDone[112]=false;
        $scope.gpmDone[113]=false;
        $scope.gpmDone[114]=false;
        $scope.gpmDone[115]=false;
        $scope.loadingGroupMember = [];
        $scope.loadingGroupMember[111] = false;
        $scope.loadingGroupMember[112] = false;
        $scope.loadingGroupMember[113] = false;
        $scope.loadingGroupMember[114] = false;
        $scope.loadingGroupMember[115] = false;
        $scope.loadingRGTimes = [];
        $scope.loadingRGTimes[10]=false;
        $scope.loadingRGTimes[11]=false;
        $scope.loadingRGTimes[12]=false;
        $scope.loadingRGTimes[13]=false;
        $scope.loadingRGTimes[14]=false;
        $scope.loadingRGTimes[15]=false;

        $scope.totalTimeCaption = '';
        $scope.chartCaption = '';
        $scope.isHourView = false;

        $scope.overlayText = '';
        $scope.overlayUrl = '';
        $scope.hasDayViewFeature = true;
        $scope.hasRoamingVals=false;

        $scope.hasCBFeature = scgSharedFactory.hasCBFeature;
        $scope.timebox = {};

        $log.info('test login welcome to usageV2');
        Metronic.unblockUI();

        //Set default caption to empty, is triggered by onDateChange

        $translate.onReady().then(function() {
            $scope.updateCaptions();
        });


        // BEGIN NEW STUFF ------------------------------------------------------------

        this.$onInit = function() {
            $log.info('onInit periodKind=' + $state.params.periodKind);
        
            if ($state.params.periodKind === '2') {
                $scope.customStartDate = moment().startOf('isoweek').format('YYYY-MM-DD');
                $scope.customStopDate = moment().endOf('isoweek').format('YYYY-MM-DD');
                $scope.onDateChange();
            } else if ($state.params.periodKind === '3') {
                $scope.customStartDate = moment().startOf('month').format('YYYY-MM-DD');
                $scope.customStopDate = moment().endOf('month').format('YYYY-MM-DD');
                $scope.onDateChange();
            } else {
                $scope.customStartDate = $scope.customStopDate = moment().format('YYYY-MM-DD');
                $scope.onDateChange();
            }
        };

        $scope.updateCaptions = function() {
            $scope.totalTimeCaption = $scope.scgSharedFactory.getTotalTimeCaption($scope.customStartDate,$scope.customStopDate);
            $scope.chartCaption =$scope.totalTimeCaption;
        }

        $scope.onStartDateChange = function(newVal, oldVal) {
            $scope.customStartDate = newVal;
        }

        $scope.onEndDateChange = function(newVal, oldVal) {
            $scope.customStopDate = newVal;
        }

        $scope.onDateChange = function() {
            $scope.isHourView = false;
            $scope.showDeviceDetails();
            $scope.updateCaptions();
            $scope.getTimes();
            $scope.getHistory();
            $scope.getChartData($scope.customStartDate,$scope.customStopDate);
        }

        $scope.onDatePickerClick = function() {
            $log.info('onDatePickerClick startDate=' + $scope.customStartDate + '|stopDate=' + $scope.customStopDate + '|isHourView=' + $scope.isHourView);
            //go back to day view if we are currently in hour view
            if ($scope.isHourView===true && $scope.customStartDate==$scope.customStopDate) {
                $scope.onDateChange();
            }
        }

        $scope.setDayChartAvailable = function(status) {
            if (status) {
                $scope.overlayText = '';
                $scope.overlayUrl = '';
            } else {
                $translate('INFO.UPDATE_FOR_FEATURE').then(function(translated) {
                    $scope.overlayText = translated;
                });
                $translate('URL.UPDATE_CB').then(function(translated) {
                    $scope.overlayUrl = translated;
                    if ($scope.isReport===true) $scope.overlayUrl='';
                });
            }
        }

        $scope.onChartColClick = function(colIndex, colData) {
            $log.info('onChartColClick colIndex=' + colIndex + '|colData=' + colData);
            if ($scope.customStartDate==$scope.customStopDate) {
                $log.info('onChartColClick WE are inside day view');
                if ($scope.isRoamingView) {
                    $log.info('onChartColClick no Action in RoamingView');
                    return;
                }
                $scope.loadingUsage = true;
                $scope.isHourView = true;
                $scope.getTimesByHour(colIndex);
                $scope.getHistoryByHour(colIndex);
                //Prepare big time box
                var toHour = colIndex +1;
                var secsPerHour = $scope.subCaptions[colIndex].replace('0:','') * 60;

                $log.info('subcaption=' + $scope.subCaptions[colIndex] + '|secsPerHour=' + secsPerHour);

                $scope.totalTimeCaption = $scope.chartCaption + ' ' + colIndex + '-' + toHour + 'h';
                $scope.isRoamingView = false;
                $scope.totalTime = secsPerHour;        //totalTime is formatted with seconds
                $log.info('onChartColClick totalTime=' + $scope.totalTime + '|hasNoDataTimes=' + $scope.hasNoDataTimes);
            } else {
                var x = $scope.azDate[colIndex];
                $log.info('week click x=' + x);
                $scope.isHourView = false;
                $scope.customStartDate = x;
                $scope.customStopDate = x;
                $scope.onDateChange();
            }
        }

        $scope.getChartData = function(fromDate,toDate) {
            var req;

            if (fromDate===toDate) {
                req = cbUsageFactory.getChartDayApi(fromDate);
                if ($scope.isRoamingView) req = cbUsageFactory.getChartDayRoamingApi(fromDate,$scope.roamingGroup);
            } else {
                req = cbUsageFactory.getChartStatsApi(fromDate,toDate);
                if ($scope.isRoamingView) req = cbUsageFactory.getChartStatsRoamingApi(fromDate,toDate,$scope.roamingGroup);
            }

            $scope.loadingChart = true;
            req.then(function(res) {
                $scope.hasRoamingVals=false;
                $scope.azDate = [];
                $scope.chartData = [];
                $scope.chartDatasecondary = [];
                $scope.Captions = [];
                $scope.subCaptions = [];

                var total = res.data.length;
                for (var i = 0; i < total; i++) {
                    $scope.chartData.push(res.data[i].percent);
                    if (res.data[i].percentSecondary) {
                        $scope.hasRoamingVals=true;
                        $scope.chartDatasecondary.push(res.data[i].percentSecondary);
                    } else {
                        $scope.chartDatasecondary.push(0);
                    }
                    var caption = res.data[i].azdate;
                    $scope.azDate.push(res.data[i].azdate);
                    if (fromDate!=toDate && total<=10) caption=moment(res.data[i].azdate, 'YYYY-MM-DD').format('Do. MMM');
                    if (fromDate!=toDate && total>20) caption=moment(res.data[i].azdate, 'YYYY-MM-DD').format('Do.');
                    $scope.Captions.push(caption);
                    var seconds = $scope.formatSecs(res.data[i].seconds,true);
                    //$log.info('getChartData index=' + i + '|subCaptions=' + seconds);
                    $scope.subCaptions.push(seconds);
                }
                $scope.showTooltips = true;
                if (total<=10) {
                    $scope.showCaptions=true;
                    $scope.showSubCaptions=true;
                } else {
                    $scope.showCaptions=true;
                    $scope.showSubCaptions=false;
                }

                if ($scope.hasDayViewFeature==false && fromDate===toDate) {
                    $scope.setDayChartAvailable(false);
                }  else {
                    $scope.setDayChartAvailable(true);
                }

                $scope.loadingChart = false;
            });
        };

        $scope.usageEdit = function(entry, context) {
            if (context=='prg') {
                var pkg=$scope.getPkgName(entry); //we have to find out the coreesponding pkgname
                $scope.lookupApp(pkg,entry);
            }
            if (context=='net') {
                $scope.lookupWeb(entry);
            }
        }

        $scope.getPkgName = function(entry) {
            _.forEach($scope.dataTimes.logs,function(v){
                if(v.Entry==entry) {
                    return v.pkgName;
                }})
        }

        $scope.getTimebox = function(fromDate,toDate) {
            $scope.loadingTimes = true;
            cbUsageFactory.getTotalTimesPeriod(fromDate,toDate).then(function success(response) {
                $scope.timebox = response.data;
                $log.info('timebox returned:', $scope.timebox);
                $scope.loadingTimes = false;
            });
        }

        // EOF NEW STUFF -------------------------------------------------


        $scope.adjustTimes = function() {
            var timesApp = [];
            var timesUrl = [];
            $scope.resetShowMoreCounters();

             _.forEach($scope.dataTimes.logs,function(v){
                 if(v.Kind==4 && (($scope.dataSettings.showAlwaysEntries==true) || ($scope.dataSettings.showAlwaysEntries!=true && v.optBonusType==0))) {
                     timesApp.push(v);
                    $scope.totalTimesApp=$scope.totalTimesApp+1;
                }
                if(v.Kind==15) {
                    timesUrl.push(v);
                    $scope.totalTimesUrl=$scope.totalTimesUrl+1;
                }
                if (v.Kind==2) {
                    $log.info("kind2 entry=",v);
                }
            })
            $scope.timesApp = timesApp;
            $scope.timesUrl = timesUrl;
        }


        $scope.getTimes = function() {
            $scope.loadingTimes=true;
            $scope.hasNoDataTimes=false; //default
            $scope.totalTimeLessMinute=false; //default
            $scope.hasAlwaysEntries=false; //default

            $scope.getTimebox($scope.customStartDate,$scope.customStopDate);

            $scope.totalTime = 0;
            cbUsageFactory.getTimesUsageByDates($scope.customStartDate,$scope.customStopDate,$scope.howMany)
                .then(function success(response) {
                    $scope.dataTimes = response.data;
                    $scope.adjustTimes();

                    $log.info('USAGE-TIMES ARRIVED', $scope.dataTimes);

                    //clear all groupmember array
                    $scope.resetGroups();

                    //get the limits
                    if ($scope.isReport===true) $scope.getLimitEntries($scope.dataTimes.logs);

                    //Iteration for totalTime
                    _.forEach($scope.dataTimes.logs, function(dataTime) {
                        if (dataTime && dataTime.optBonusType>0) {
                            $scope.hasAlwaysEntries=true;
                        }
                        if (dataTime && dataTime.Kind && dataTime.Kind === 2) {
                            $scope.totalTime = $scope.totalTime + $scope.scgSharedFactory.hourToMin(dataTime.Period);
                        }
                        if (dataTime && dataTime.Kind && dataTime.Kind === 50) {
                            $scope.totalExtension = $scope.totalExtension + $scope.scgSharedFactory.hourToMin(dataTime.Period);
                        }
                        if (dataTime.Kind == 1010 || dataTime.Kind == 1011 || dataTime.Kind == 1012 || dataTime.Kind == 1013 || dataTime.Kind == 1014 || dataTime.Kind == 1015) {
                            var groupId = dataTime.Kind-1000 + 100;
                            $scope.hasGroupmembers = true;
                            $scope.gpm[groupId].push(dataTime);
                            $log.info("gpm added groupId=" + groupId + ":" , dataTime);
                        }
                    });
                    if ($scope.dataTimes.logs.length == 0) {
                        $scope.hasNoDataTimes = true;
                    }
                    //do we have roaming? if yes, do the roaming call
                    if ($scope.dataSettings.roaming && $scope.dataSettings.roaming!=''){
                        $scope.updateRoamingTimes();
                    }
                    $scope.loadingTimes = false;
                },  function (err) {
                    $scope.loadingTimes = false;
                    if (err && err.status && err.status==401 && $scope.isReport) {
                        $scope.logout();
                    }
                });
        }


        $scope.getTimesByHour = function(thisHour) {
            $scope.loadingTimes=true;
            $scope.hasNoDataTimes=false; //default
            $scope.totalTimeLessMinute=false; //default
            $scope.hasAlwaysEntries=false;  //default

            $scope.totalTime = 0;
            cbUsageFactory.getTimesUsageByHour($scope.customStartDate,thisHour)
                .then(function success(response) {
                    $scope.dataTimes = response.data;
                    $scope.hasNoDataTimes = response.data.noData;
                    $scope.adjustTimes();

                    $log.info('USAGE-TIMES PER HOUR ARRIVED', $scope.dataTimes);

                    //clear all groupmember array
                    $scope.resetGroups();

                    //Iteration for groups (totaltime is calculated differently here)
                    _.forEach($scope.dataTimes.logs, function(dataTime) {

                        if (dataTime.Kind == 1010 || dataTime.Kind == 1011 || dataTime.Kind == 1012 || dataTime.Kind == 1013 || dataTime.Kind == 1014 || dataTime.Kind == 1015) {
                            var groupId = dataTime.Kind-1000 + 100;
                            $scope.hasGroupmembers = true;
                            $scope.gpm[groupId].push(dataTime);
                            $log.info("gpm added groupId=" + groupId + ":" , dataTime);
                        }
                    });

                    if ($scope.totalTime<1 && $scope.dataTimes.logs.length>0) {
                        //edge case we have less than one minute total (no total entry, but counted apps)
                        $scope.totalTimeLessMinute = true;
                    }

                    if ($scope.dataTimes.logs.length == 0) {
                        $scope.hasNoDataTimes = true;
                    }

                    //do we have roaming? if yes, do the roaming call
                    if ($scope.dataSettings.roaming && $scope.dataSettings.roaming!=''){
                        $scope.updateRoamingTimes();
                    }

                    $scope.loadingTimes = false;

                },  function (err) {
                    $scope.loadingTimes = false;
                });
        }


        $scope.getGroupTimesRoaming = function(group) {
            $scope.loadingRGTimes[group] = true;
            cbUsageFactory.getRoamingGroupTimes($scope.customStartDate,$scope.customStopDate,group-10)
                .then(function success(response) {
                    $scope.rgTimes = response.data;
                    $scope.gpmRoaming[group].length=0; //clear all groupmember array

                    var existingEntries=[];
                    _.forEach($scope.rgTimes, function(rgTime) {
                        if (rgTime.kind == group+1000) {
                            var groupId = rgTime.kind-1000;
                            rgTime.kind=rgTime.kind-1000;
                            $scope.gpmRoaming[groupId].push(rgTime);
                            existingEntries.push(rgTime.entry);
                            $log.info("gpm rgTime added groupId=" + groupId + ":::" , rgTime);
                        }
                    });
                    cbUsageFactory.getAllRoamingGroupEntries(group-10).then(
                        function success(response) {
                            _.forEach(response.data, function(obj){
                                $log.info('roam foreach obj=', obj);
                                if (existingEntries.indexOf(obj)==-1) {
                                    var addItem = {};
                                    addItem.entry = obj;
                                    addItem.period = "--:--";
                                    $scope.gpmRoaming[group].push(addItem);
                                }
                            })
                            $scope.loadingRGTimes[group] = false;
                        }
                    )
                },  function (err) {
                    $scope.loadingRGTimes[group] = false;
                });
        }


        //shows the roaming times as the local view (important for report role)
        $scope.getGroupTimesRoamingAsLocal = function(group) {
            $scope.loadingTimes[group] = true;

            //gpm group muss 112 sein
            cbUsageFactory.getRoamingGroupTimes($scope.customStartDate,$scope.customStopDate,group-110)
                .then(function success(response) {
                    $scope.Times = response.data;
                    $scope.gpm[group].length=0; //clear all groupmember array
                    var existingEntries=[];
                    _.forEach($scope.Times, function(rgTime) {
                        //var mygroup = group-100+1000;
                        if (rgTime.kind == group+1000-100) {
                            var groupId = rgTime.kind-1000;
                            rgTime.kind=rgTime.kind-1000;
                            $scope.gpm[groupId].push(rgTime);
                            existingEntries.push(rgTime.entry);
                            $log.info("gpm rgTime added groupId=" + groupId + ":::" , rgTime);
                        }
                    });
                    cbUsageFactory.getAllRoamingGroupEntries(group-10).then(
                        function success(response) {
                            _.forEach(response.data, function(obj){
                                $log.info('roam foreach obj=', obj);
                                if (existingEntries.indexOf(obj)==-1) {
                                    var addItem = {};
                                    addItem.entry = obj;
                                    addItem.period = "--:--";
                                    $scope.gpm[group].push(addItem);
                                }
                            })
                            $scope.loadingTimes[group] = false;
                        }
                    )
                },  function (err) {
                    $scope.loadingTimes[group] = false;
                });
        }



        $scope.adjustHistory = function() {
            var entriesHistory = [];
            var entriesSystem= [];
            var entriesUrl = [];
            var entriesBlocked = [];
            var entriesPortal= [];

            $scope.resetShowMoreCounters();

            _.forEach($scope.dataHistory.history,function(v){
                if(v.Kind==4 && (($scope.dataSettings.showAlwaysEntries==true) || ($scope.dataSettings.showAlwaysEntries!=true && v.optBonusType==0))) {
                    entriesHistory.push(v);
                    $scope.totalHistoryApp=$scope.totalHistoryApp+1;
                }
                if(v.Kind==6) {
                    entriesPortal.push(v);
                    $scope.totalHistoryPortal=$scope.totalHistoryPortal+1;
                }
                if(v.Kind==7 || v.Kind==8) {
                    entriesSystem.push(v);
                    $scope.totalHistorySystem=$scope.totalHistorySystem+1;
                }
                if(v.Kind==13) {
                    entriesBlocked.push(v);
                    $scope.totalHistoryBlocked=$scope.totalHistoryBlocked+1;
                }
                if(v.Kind==15) {
                    entriesUrl.push(v);
                    $scope.totalHistoryUrl=$scope.totalHistoryUrl+1;
                }
            })
            $scope.historyApp = entriesHistory;
            $scope.historyUrl = entriesUrl;
            $scope.historyBlocked = entriesBlocked;
            $scope.historySystem = entriesSystem;
            $scope.historyPortal = entriesPortal;
            //$log.info('historyBlocked', $scope.historyBlocked);
        }


        $scope.getHistory = function() {
            $scope.loadingHistory = true;
            $scope.hasNoDataHistory = false; //default, init value
            $scope.howMany = 1000;
            cbUsageFactory.getHistoryUsageByDates($scope.customStartDate,$scope.customStopDate,$scope.howMany)
                .then(function success(response) {
                        $scope.dataHistory = response.data;
                        $scope.adjustHistory();
                        if ($scope.dataHistory.history.length == 0) {
                            $scope.hasNoDataHistory = true;
                        }
                        $scope.loadingHistory = false;
                    },
                    function (err) {
                        $log.info('loadingHistory error reject', err);
                        $scope.loadingHistory = false;
                        $scope.isHistoryIssue=true;
                    });
        }

        $scope.getHistoryByHour = function(thisHour) {
            $scope.loadingHistory = true;
            $scope.hasNoDataHistory = false; //default, init value
            cbUsageFactory.getHistoryUsageByHour($scope.customStartDate,thisHour)
                .then(function success(response) {
                        $scope.dataHistory = response.data;
                        $log.info('historyByHour:' , $scope.dataHistory);
                        $scope.adjustHistory();
                        $scope.loadingHistory = false;
                        $scope.isHistoryIssue=false;
                    },
                    function (err) {
                        $log.info('loadingHistory error reject', err);
                        $scope.loadingHistory = false;
                    });
        }



        $scope.isIOS = false;
        $log.info('CB-Usage-Reports: Active Device');
        $log.info(AuthFactory.activeDevice);

        DeviceFactory.getDeviceById(AuthFactory.activeDevice.deviceId).then(
            function success(device) {
                $log.info('CB-Usage-Reports: IOS Check');
                $log.info(device);
                if (device.deviceType === 11) {
                    $log.info('CB-Usage-Reports: IOS Check : is IOS Device');
                    $scope.isIOS = true;
                }
            }
        );
        // ---------------------------------------------------------------------------------

        $scope.resetGroups = function() {
            $scope.gpm[110].length=0;
            $scope.gpm[111].length=0;
            $scope.gpm[112].length=0;
            $scope.gpm[113].length=0;
            $scope.gpm[114].length=0;
            $scope.gpm[115].length=0;

            $scope.showgroupRoaming[11]=false;
            $scope.showgroupRoaming[12]=false;
            $scope.showgroupRoaming[13]=false;
            $scope.showgroupRoaming[14]=false;
            $scope.showgroupRoaming[15]=false;
        }

        $scope.resetShowMoreCounters = function() {
            $scope.totalTimesApp=0;
            $scope.totalTimesUrl=0;
            $scope.totalHistoryApp=0;
            $scope.totalHistoryPortal=0;
            $scope.totalHistorySystem=0;
            $scope.totalHistoryBlocked=0;
            $scope.totalHistoryUrl=0;
        }

        $scope.makeURL = function(url) {
            return 'https://' + url;
        };

        $scope.filterKind1 = function(item) {
            return item.Kind == 1 || item.Kind == 10;
        };

        $scope.filterKindDevice = function(item) {
            return item.Kind == 2;
        };

        $scope.filterKindGroups = function(item) {
            return item.Kind == 111 || item.Kind == 112 || item.Kind == 113|| item.Kind == 114|| item.Kind == 115;
        }

        $scope.filterKindGroupsRoaming = function(item) {
            return item.Kind == 11 || item.Kind == 12  || item.Kind == 13  || item.Kind == 14 || item.Kind == 15;
        };

        $scope.filterKind4 = function(item) {
            return item.Kind == 4;
        };

        $scope.filterKind7 = function(item) {
            return item.Kind == 6 ||  item.Kind == 7 || item.Kind == 8;   //6 = sharedLog!
        };

        $scope.filterKind15 = function(item) {
            return item.Kind == 15;
        };

        $scope.filterKind22 = function(item) {
            return item.Kind == 22;
        };

        //Filterkind50 is counted extension for CB3
        $scope.filterKind50 = function(item) {
            return item.Kind == 50;
        };


        $scope.deleteUsage = function() {
            $log.info('delete goes here');
            modalFactory.infoModal.open({
                modal_settings: {
                    title: $translate.instant('MSG.QUESTION'),
                    message: $translate.instant('MSG.SUREDELETE'),
                    butOkLabel: $translate.instant('BUTTON.YES'),
                    butCancelLabel: $translate.instant('BUTTON.NO'),
                    iconNo: 2,
                    refreshStateAfterClose: true
                },
                callbacks: {
                    ok: function() {
                        $log.info('DELPROC yes, ok was click, he/she is sure');
                        $scope.data = {};
                        scgGlobalSpinnerFactory.blockUI();
                        cbUsageFactory.deleteUsage()
                            .then(function success(response) {
                                $log.info('DELPROC HAS DATA 200, response.data=' + response.data);
                                if (response.data != null) {
                                    modalFactory.okModal(1, $translate.instant('MSG.DELETECBDONE'));
                                    cbUsageFactory.delUsageAction();
                                    Metronic.unblockUI();
                                }

                            }, function error() {
                                Metronic.unblockUI();
                                $log.info('ERROR OCCURED');
                                modalFactory.okModal(1, $translate.instant('MSG.ERROR')  + ' ' + $translate.instant('MSG.ERRORHINT2'));
                            });

                    },
                },
                cancel: function() {
                    $log.info('canceled!');
                }
            });
        };


        $scope.getLimitEntries = function(logs) {
            var entriesPostArr = [];
            _.forEach(logs, function(val) {
                entriesPostArr.push({entry: val.Entry, kind:val.Kind});
            });
            cbUsageFactory.getLimitEntriesInfo(entriesPostArr)
                .then(function success(response) {
                    var hasLimitEntries = response.data;
                    _.forEach(logs, function(logsEntry) {
                        _.forEach(hasLimitEntries, function(limitEntry) {
                            if (limitEntry.entry === logsEntry.Entry) {
                                logsEntry.limitInfo = limitEntry;
                            }
                            if (limitEntry.kind==2) {
                                $scope.tmpLim.limitInfo = limitEntry;
                            }
                        });
                    });
                    $scope.limitInfoDone = true;
                });
        }

        $scope.showUnlock = function() {
            $log.info("state go D");
            $state.go('cb-usage.unblockv2');
        };


        $scope.pdfExportServer = function () {
            $log.info('PDF Export from Backend!');
            scgGlobalSpinnerFactory.blockUI();
            var pdfurl = API.url + 'cbusage/bydatesall/' + $scope.customStartDate + '/' + $scope.customStopDate + '/' + $scope.howMany + '?format=pdf';
            $log.info('PDF url:' + pdfurl);
            scgGlobalSpinnerFactory.blockUI();
            $http({
                url : pdfurl,
                method : 'GET',
                params : {},
                headers : {
                    'Content-type' : 'application/pdf',
                },
                responseType : 'arraybuffer'
            }).success(function(data, status, headers, config) {
                // TODO when WS success
                $log.info('PDF success');
                scgGlobalSpinnerFactory.unblockUI();

                if (navigator.appName == 'Microsoft Internet Explorer' ||  !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv 11/)) || $.browser.msie == 1)
                {
                    var fileIE = new Blob(([data]), { type: 'application/pdf' });
                    if (window.navigator.msSaveOrOpenBlob) {
                        navigator.msSaveBlob(fileIE, 'report.pdf');
                    }
                } else {
                    var file = new Blob([data], {
                        type: 'application/csv'
                    });
                    //trick to download store a file having its URL
                    var fileURL = URL.createObjectURL(file);
                    var a = document.createElement('a');
                    a.href = fileURL;
                    a.target = '_blank';
                    a.download = 'report.pdf';
                    document.body.appendChild(a);
                    a.click();
                }
            }).error(function(data, status, headers, config) {
                //TODO when WS error
                scgGlobalSpinnerFactory.unblockUI();
            });
        }



        cbUsageFactory.hasRoaming().
        then(function success(response) {
            $log.info('hasRoaming:',response);
            $scope.hasRoaming = response.data.hasRoaming;
        });



        $scope.showBadge = function(listKind) {
            var str = '';
            //$log.info('Badge for listKind:' , listKind);
            if (listKind == 2) {
                str = $translate.instant('TABS.TIMELIMITED');
            }
            if (listKind == 11) {
                str = 'Gr.1';
            }
            if (listKind == 12) {
                str = 'Gr.2';
            }
            if (listKind == 13) {
                str = 'Gr.3';
            }
            if (listKind == 14) {
                str = 'Gr.4';
            }
            if (listKind == 15) {
                str = 'Gr.5';
            }
            return str;
        };

        $scope.calculateHideSome=function() {
            $scope.hideUsageSome= false;
            if ($scope.dataSettings.hideUsageRuntimePrograms==true) {$scope.hideUsageSome=true;}
            if ($scope.dataSettings.hideUsageRuntimeWebsites==true) {$scope.hideUsageSome=true;}
            if ($scope.dataSettings.hideUsageHistoryPrograms==true) {$scope.hideUsageSome=true;}
            if ($scope.dataSettings.hideUsageHistoryWebsites==true) {$scope.hideUsageSome=true;}
            if ($scope.dataSettings.hideUsageHistoryBlocked==true) {$scope.hideUsageSome=true;}
            if ($scope.dataSettings.hideUsageHistorySystem==true) {$scope.hideUsageSome=true;}
            var hideAny_object = {"hideUsageAny":$scope.hideUsageSome};
            if (!$scope.isReport && $scope.hideUsageSome==true) {cbUsageFactory.setUsageSettings(hideAny_object);}
        }


        $scope.getUsageSettings = function() {
            cbUsageFactory.getUsageSettings()
                .then(function success(response) {
                    $scope.dataSettings = response.data;
                    $scope.dataSettingsDone = true;
                    $scope.adjustHistory();
                    $scope.adjustTimes();
                    $log.info('usage get settings!', $scope.dataSettings);

                    //add watchers, important context is here dataSettings!
                    //---------------------------------------------------------
                    _.each($scope.dataSettings, function(v, k) {
                        $scope.$watch('dataSettings.' + k, function(new_val, old_val) {
                            if (new_val != old_val) {
                                var changed_object = {};
                                changed_object[k] = new_val;
                                $log.info('usage changed settings!',k);
                                scgSharedFactory.needsSync = true;
                                $scope.calculateHideSome();
                                cbUsageFactory.setUsageSettings(changed_object);
                            }
                        }, true);
                    });

                    //do we have roaming? if yes, do the roaming call
                    if ($scope.dataSettings.roaming && $scope.dataSettings.roaming!=''){
                        $scope.updateRoamingTimes();
                    }
                });
        }


        $scope.limitClickDevice = function() {
                $log.info("limitClickDevice tmpLim=" , $scope.tmpLim);
                if ($scope.isReport===true) {
                    //$scope.tmpLim.limitInfo.kind = 2;
                    $scope.limitClick($scope.tmpLim,true);
                }
        }

        $scope.limitClick = function(limitEntry) {

            $log.info('LimitEntry object:', limitEntry);

            //Special case we want the device limit
            if (limitEntry==$scope.customStartDate) {
                cbUsageFactory.getLimitinfo(limitEntry)
                    .then(function success(response) {
                        $scope.dataLimitinfo = response.data;
                        $scope.dataLimitinfo.entry = entry;
                        if ($scope.dataLimitinfo.blockedTimes == undefined || $scope.dataLimitinfo.blockedTimes === scgSharedFactory.blockOff()) {
                            $log.info('YES BLOCKEDTIMES UNDEFINED');
                            $scope.dataLimitinfo.blockedTimes = undefined;
                        }
                        $log.info('success, response is ', $scope.dataLimitinfo);
                        scgGlobalSpinnerFactory.unblockUI();
                        $scope.showLimitinfo = true;
                    });
                return;
            }   //eof sepcial case device limit


            var entry = limitEntry.limitInfo.entry;
            var kind = limitEntry.limitInfo.kind;
            var hasLimit = limitEntry.limitInfo.hasLimit;
            var listKind = limitEntry.listKind;

            if (limitEntry.Kind>110 && limitEntry.Kind<116)
            {
                kind=limitEntry.Kind-100;
            }

            $log.info('LimitClickCB for kind=' + kind + ' and entry ' + entry + ' and hasLimit=' + hasLimit);

            if (kind>110 && kind<116) kind=kind-100;
                scgGlobalSpinnerFactory.blockUI();
                if (kind<10 || kind>15) {
                    cbUsageFactory.getLimitinfo(entry)
                        .then(function success(response) {
                                $scope.dataLimitinfo = response.data;
                                $scope.dataLimitinfo.entry = entry;
                                if ($scope.dataLimitinfo.blockedTimes == undefined || $scope.dataLimitinfo.blockedTimes === scgSharedFactory.blockOff()) {
                                    $log.info('YES BLOCKEDTIMES UNDEFINED');
                                    $scope.dataLimitinfo.blockedTimes = undefined;
                                }
                                $log.info('success, response is ', $scope.dataLimitinfo);
                                scgGlobalSpinnerFactory.unblockUI();
                                $scope.showLimitinfo = true;
                            },
                            function error(err) {
                                scgGlobalSpinnerFactory.unblockUI();
                                $scope.showLimitinfo = false;
                                if (err && err.status && err.status==401 && $scope.isReport) {
                                    $scope.logout();
                                }
                            }
                        )
                } else {
                    //we have to lookup kind instead of entry
                    cbUsageFactory.getLimitinfoByKind(kind)
                        .then(function success(response) {
                                $scope.dataLimitinfo = response.data;
                                $scope.dataLimitinfo.entry = entry;
                                if ($scope.dataLimitinfo.blockedTimes==undefined || $scope.dataLimitinfo.blockedTimes===scgSharedFactory.blockOff())
                                {
                                    $log.info('YES BLOCKEDTIMES UNDEFINED');
                                    $scope.dataLimitinfo.blockedTimes=undefined;
                                }
                                $log.info('success, response is ', $scope.dataLimitinfo);
                                scgGlobalSpinnerFactory.unblockUI();
                                $scope.showLimitinfo=true;
                            },
                            function error(err) {
                                scgGlobalSpinnerFactory.unblockUI();
                                $scope.showLimitinfo=false;
                                if (err && err.status && err.status==401 && $scope.isReport) {
                                    $scope.logout();
                                }
                            }
                        )
                }
        }


        $scope.groupClick = function(limitEntry) {

            //We have to do an other mapping here (kind != Kind)
            var entry = limitEntry.Entry;
            var kind = limitEntry.Kind;
            var listKind = limitEntry.Kind;

            if (limitEntry.Kind>110 && limitEntry.Kind<116)
            {
                kind=limitEntry.Kind-100;
            }
            //fix for cb3 groups (111-115)
            if (kind>110 && kind<116)
            {
                kind=kind-100;
            }


            if( $scope.isReport === true ){
                //no handling for report view -> exit
                return;
             }else {
                $log.info(' -------- This is view for PARENTS --------------------------- ');
                var modal_settings = {
                    mode: 'edit',
                    context: 'prg',
                    title: $translate.instant('MODAL_EDIT_RECORD.TITLE_EDIT'),
                    //newRecord: null,
                    //editRecords: editRecords,
                    refreshStateAfterClose: false
                };

          if(listKind >=10 && listKind <=15){

                    //handle group edit (load multimple entries into edit modal)
                    cbListsFactory.getDetailedByListFilter(listKind)
                        .then(function success(response) {
                            var groupEntries = response.data;

                            var editRecords = [];

                            _.each(groupEntries, function(groupEntry){
                                var obj = {data: {'entry': groupEntry.entry}};
                                editRecords.push(obj);
                            });

                            modal_settings.kind = listKind;
                            modal_settings.newRecord = null;
                            modal_settings.editRecords = editRecords;
                            modal_settings.mode = 'edit';

                            openEditModal(modal_settings);
                        });
                }
            }
        } //eof groupClick


        function openEditModal(modalSettings){
            //open edit modal for this entry
            modalFactory.editModal.open({
                modal_settings: modalSettings,
                callbacks: {
                    ok: function() {},
                    cancel: function() {}
                }
            });
        }

        $scope.logout = function() {
            $log.info("state go E");
            $state.go('login-logoff-success');
        }

        $scope.exitLimitinfo = function() {
            $scope.showLimitinfo=false;
        }



        $scope.scrollToUsageSettings = function() {
            $location.hash('UsageSettings');
            $anchorScroll();
        }


        scgSharedFactory.getServerTimeDiff().then(function(funcresult) {
            $scope.serverTimeDiff = funcresult;
        });


        $scope.listKindToString = function(kind) {
            return "XXX";
        }


        //Watcher for CB3 (necessary if page reload button has been hit)
        $scope.$watch(function() {
            return AuthFactory.activeDevice;
        }, function(activeDevice) {
            //hasCBFeature returns false (incorrectly) for report view for whatever cauase, so exit here in reportView
            if ($scope.isReport!=true) {
                $scope.hasDayViewFeature=$scope.scgSharedFactory.hasCBFeature('dayChart');
                $scope.setDayChartAvailable($scope.hasDayViewFeature);
                $log.info('WATCH DEVICE CB|hasdayChart='+$scope.hasDayViewFeature);
            }
            if (AuthFactory.activeDevice.deviceType==30) {
                $scope.isCB3=true;
            } else {
                $scope.isCB3=false;
            }
        },true);


        $scope.getWeekStats = function(period) {
            $scope.loadingWeekStats=true;
            cbUsageFactory.getWeekStats(period)
                .then(function success(response) {
                    $scope.weekstats = response.data;
                    $scope.weekstatsPeriod = period;
                    $scope.weekstatsPercentage = $scope.getPercentageFromStats(response.data);
                    $log.info('Weekstats=', $scope.weekstats);
                    $scope.loadingWeekStats=false;
                });
            return;
        }


        $scope.updateRoamingTimes = function() {
            $scope.roamingGroup = $scope.dataSettings.roaming.replace("F","");
            $log.info('Roaming YES WE have, our group is=',$scope.roamingGroup);
            $scope.getRoamingTimes($scope.customStartDate,$scope.customStopDate,$scope.roamingGroup);
        }


        $scope.getRoamingTimes = function(startdate,stopdate,roamingGroup) {
            $scope.loadingRoaming = true;
            $scope.hasRoamingGroupData=false;
            cbUsageFactory.getRoamingTimes(startdate,stopdate,roamingGroup)
                .then(function success(response){
                    $scope.roamingData = response.data;
                    $log.info('roamingdata=', $scope.roamingData);
                    //Iteration for hasRoamingGroup
                    _.forEach($scope.roamingData, function(item) {
                        if (item.Kind>=10) {
                            $scope.hasRoamingGroupData=true;
                        }
                    });
                    $scope.loadingRoaming = false;
                });
        }


        $scope.lookupApp = function(pkg,appname) {
            $log.info('lookupApp for appname=' + pkg);
            $state.go('detail-view', {appIds: [appname], appType: chicoEnums.AppTypeEnum.APP, allowAdd: false,prevStateName: $state.current.name});
        }

        $scope.lookupWeb = function(url) {
            $log.info('lookupWeb for url=' + url);
            $state.go('detail-view', {appIds: [url], appType: chicoEnums.AppTypeEnum.WEBSITE, allowAdd: false,prevStateName: $state.current.name});
        }

        $scope.lookupGroup = function(logentry) {
            var groupMember = [];
            _.forEach($scope.gpm[logentry.Kind],function(v){
                groupMember.push(v.Entry);
            })
            $log.info('lookupGroup, members=', groupMember);
            $state.go('detail-view', {appIds: groupMember, appType: chicoEnums.AppTypeEnum.APP, allowAdd: false,prevStateName: $state.current.name});
        }


        $scope.deleteHistoryTable = function() {
            modalFactory.infoModal.open({
                modal_settings: {
                    title: $translate.instant('MSG.QUESTION'),
                    message: $translate.instant('MSG.SURE'),
                    butOkLabel: $translate.instant('BUTTON.YES'),
                    butCancelLabel: $translate.instant('BUTTON.NO'),
                    iconNo: 2,
                    refreshStateAfterClose: false
                },
                callbacks: {
                    ok: function() {
                        $log.info('yes, ok was click, he/she is sure');
                        scgGlobalSpinnerFactory.blockUI();
                        cbUsageFactory.deleteHistory()
                            .then(function success(response) {
                                scgGlobalSpinnerFactory.unblockUI();
                                modalFactory.infoModal.open({
                                    modal_settings: {
                                        title: $translate.instant('HEADER.INFO'),
                                        message: $translate.instant('USAGE.DELETE_HISTORY_SUCCESS'),
                                        butOkLabel: $translate.instant('BUTTON.OK'),
                                        butCancelLabel: null,
                                        iconNo: 1,
                                        refreshStateAfterClose: true
                                    },
                                    callbacks: {
                                        ok: function() {
                                            //nothing special!
                                        }
                                    }
                                });
                            },  function (err) {
                                $log.info('DELETE ERROR');
                            });

                    },
                },
                cancel: function() {
                    $log.info('canceled!');
                }
            });
        }


        $scope.setShowAlways = function(status) {
            $scope.dataSettings.showAlwaysEntries=status;
            $scope.adjustHistory(); //important to re-display!
            $scope.adjustTimes();//important to re-display!
            $log.info('setShowAlways set to status=' + status);
        }


        $scope.showMoreTimesApp = function() {
            $scope.rowTimesApp = $scope.rowTimesApp + $scope.defaultCountTimes;
        }

        $scope.showMoreTimesUrl = function() {
            $scope.rowTimesUrl = $scope.rowTimesUrl + $scope.defaultCountTimes;
        }

        $scope.showMoreHistoryApp = function() {
            $scope.rowHistoryApp = $scope.rowHistoryApp + $scope.defaultCountHistory;
        }

        $scope.showMoreHistoryUrl = function() {
            $scope.rowHistoryUrl = $scope.rowHistoryUrl + $scope.defaultCountHistory;
        }

        $scope.showMoreHistoryBlocked = function() {
            $scope.rowHistoryBlocked = $scope.rowHistoryBlocked + $scope.defaultCountHistory;
        }

        $scope.showMoreHistorySystem = function() {
            $scope.rowHistorySystem = $scope.rowHistorySystem + $scope.defaultCountHistory;
        }

        $scope.showMoreHistoryPortal = function() {
            $scope.rowHistoryPortal = $scope.rowHistoryPortal + $scope.defaultCountHistory;
        }



        $scope.logtest = function(foo) {
            $log.info('logtest=' + foo);
        }


        $scope.showLocalView = function() {
            $log.info("showLocalView");
            $scope.isRoamingView=false;
            $scope.getChartData($scope.customStartDate,$scope.customStopDate); // refresh *after* setting isRoamingView!
        }


        $scope.showRoamingView = function() {
            $log.info("showRoamingView");
            $scope.isRoamingView=true;
            $scope.getChartData($scope.customStartDate,$scope.customStopDate); // refresh *after* setting isRoamingView!
        }

        $scope.showDeviceDetails = function() {
                $scope.loadingRoamingMembers=true;
                cbUsageFactory.getDeviceDetails($scope.customStartDate,$scope.customStopDate)
                    .then(function success(response) {
                        $log.info('showRoamingMembers api arrived:', response);
                        $scope.roamingMembers = response.data;
                        $scope.loadingRoamingMembers=false;
                    });
        }

        $scope.showGroupMembers = function(group) {

            if ($scope.hasGroupmembers==false) {
                return;
            }
            if ($scope.showgroup[group]==true) {
                $scope.showgroup[group]=false;
            } else {
                if ($scope.gpmDone[group]==true) {
                    $scope.showgroup[group] = true;
                    return;
                }
                $scope.loadingGroupMember[group]=true;

                cbUsageFactory.getGroupDetails($scope.customStartDate, $scope.customStopDate,group-100).then(
                    function success(response) {
                        $log.info('getgroupArrived group=', group);
                        $log.info('getgroupArrived response=', response);
                        $scope.gpm[group] = response.data;
                        $scope.showgroup[group] = true;
                        $scope.loadingGroupMember[group]=false;
                    }
                )
            }
        }





        $scope.showGroupMembersRoaming = function(group) {
            $log.info("getgroup xx showgroupmembersRoaming for group=" + group);
            if ($scope.showgroupRoaming[group]==true) {
                $scope.showgroupRoaming[group] = false;
            } else {
                $log.info("getgroup for group=" + group);
                $scope.showgroupRoaming[group] = true;
                $scope.getGroupTimesRoaming(group);
            }
        }

        // MODAL FOR ROAMING MEMBERS
        $scope.roamingSettings = function() {
            $log.info("roamingSettings called ");
            modalFactory.roamingModal.open({
                modal_settings: {
                    refreshStateAfterClose: false
                },callbacks: {
                    ok: function () {
                        $log.info('modal-until ok callback');
                        //we need little time before refresh/reload to save the roaming setttings
                        scgGlobalSpinnerFactory.blockUI();
                        $timeout( function(){
                            $log.info('modal-roaming reload');
                            scgGlobalSpinnerFactory.unblockUI();
                            $state.reload();
                        }, 2000 );
                    }}
            });
        }

        $scope.devSetReportView = function() {
            $scope.isReport=true;
            $log.info("devmode - isReport set to TRUE");
        }


        //Trigger at startup unlock requests
        cbDashboardFactory.getUnlockRequests()
            .then(function success(response) {
                $scope.howManyUnlockRequests = response.data.length;
            });


        // Sample to set index of date-range-picker
        $scope.test = function() {
            $scope.customStartDate = '2020-06-15';      //set same for day
            $scope.customStopDate = '2020-06-21';
            $scope.getChartData($scope.customStartDate,$scope.customStopDate);
            $log.info('start=' + $scope.customStartDate + '|stop=' + $scope.customStopDate);
        }

        //STARTUP - Functions have to be called once

        $scope.getUsageSettings();
    });

