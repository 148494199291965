'use strict';
angular.module('ChicoApp.CC-SYNC')
    .controller('CC-SyncController', function(
        $rootScope,
        $scope,
        $http,
        $timeout,
        $translate,
        API,
        $log,
        modalFactory,
        sseFactory,
        scgGlobalSpinnerFactory,
        scgSharedFactory) {

      $scope.data = {};
      $scope.isPCOnline = false;
      $scope.lastSyncDiff = 0;

      //Gather lastsync date (async!)
      $scope.lastSync = '   ' + $translate.instant('MISC.RESOLVING');
      // scgSharedFactory.getLastSync().then(function(funcresult) {
      //   $scope.lastSync = scgSharedFactory.formatDateTime(funcresult);
      // });
      scgSharedFactory.getLastSync().then(function success(lastSync) {
        $scope.lastSync = scgSharedFactory.formatDateTime(lastSync);

          var lastsynctime = null;
          if (lastSync!=null) {
              lastsynctime = lastSync.substring(0,10);
          }
          var browsertime =  moment().format('YYYY-MM-DD');
          var a = moment(lastsynctime);
          var b = moment(browsertime);
          var timeDiff = b.diff(a, 'days');
          $scope.lastSyncDiff = timeDiff;


      });

      //initially set language to scope to have it in the template
      $scope.sprache = $translate.use();
      $log.info('sprache=' + $scope.sprache);

      $scope.isPCOnline = false; //default
      sseFactory.isOnline()
            .then(function success(response) {
              if (response.data.success == true) {
                $scope.isPCOnline = true;
                $log.info('SSE factory isonline returned:',$scope.isPCOnline);
              }
            });



       $scope.syncReset = function() {
           modalFactory.infoModal.open({
               modal_settings: {
                   title: $translate.instant('MSG.QUESTION'),
                   message: $translate.instant('INFO.SYNC_RESET2') + ' ' + $translate.instant('MSG.SURE'),
                   butOkLabel: $translate.instant('BUTTON.YES'),
                   butCancelLabel: $translate.instant('BUTTON.NO'),
                   iconNo: 2,
                   refreshStateAfterClose: false
               },
               callbacks: {
                   ok: function() {
                       //OK CALLBACK
                       scgGlobalSpinnerFactory.blockUI();
                       scgSharedFactory.mastersync()
                           .then(function success(response) {
                               $log.info('masterSync success:',response.data);
                               $scope.myInfoModal($translate.instant('MSG.SSESUCCESS'));
                               //Do an immediate sync if online
                               sseFactory.isOnline()
                                   .then(function success(response) {
                                       if (response.data.success == true) {
                                           $log.info('SSE factory isonline returned:',$scope.isPCOnline);
                                           scgGlobalSpinnerFactory.unblockUI();
                                           sseFactory.sync()
                                               .then(function success(response) {
                                                   if (response.data.success == true) {
                                                       $log.info('SYNC SUCCESS');
                                                       scgSharedFactory.needsSync = false;
                                                   }
                                               });
                                       } else {
                                           scgGlobalSpinnerFactory.unblockUI();
                                           $log.info('SSE factory isonline OFFLINE');
                                       }
                                   });
                           });
                   },
               },
               cancel: function() {
               }
           });
       }







      //---------------------------------------------------------------------------------------------------------------
      //infomdal for re-use in this scope
      $scope.myInfoModal = function(msg) {
        scgGlobalSpinnerFactory.unblockUI();
        modalFactory.infoModal.open({
          modal_settings: {
            title: $translate.instant('HEADER.INFO'),
            message: msg,
            butOkLabel: $translate.instant('BUTTON.OK'),
            butCancelLabel: null,
            iconNo: 1,
            refreshStateAfterClose: true
          },
          callbacks: {
            ok: function() {
              $log.info('modal closed with ok -> callback');
            }
          }
        });
      };

      //---------------------------------------------------------------------------------------------------------------
      $scope.doSync = function() {
        scgGlobalSpinnerFactory.blockUI();
        sseFactory.sync()
                .then(function success(response) {
                  if (response.data.success == true) {
                    $log.info('SYNC SUCCESS');
                    scgSharedFactory.needsSync = false;
                    $scope.myInfoModal($translate.instant('MSG.SYNCSUCCESS'));
                  } else {
                    $log.info('SYNC FAILED');
                    $scope.myInfoModal($translate.instant('MSG.SYNCFAILURE'));
                  }
                });
      };

      $scope.offlineMsg = function() {
        $scope.myInfoModal($translate.instant('MSG.OFFLINE_SYNC'));
      };

    });
